import React, {useEffect, useState} from 'react'
import DefaultThemeCard from "./DefaultThemeCard";

const DefaultThemeContainer = (props) => {

    const [lastClickedImage, setLastClickedImage] = useState(props.chosenTheme)

    useEffect(() => {
        if (lastClickedImage !== props.chosenTheme) {
            setLastClickedImage(props.chosenTheme)
        }
    })

    const handleClick = (imageFileName) => {
        if (lastClickedImage !== imageFileName) {
            // console.log([imageFileName, 0]);
        }
    }

    const defaultImageCards = props.themeImagesJson.map(
        (imageData, index) => <DefaultThemeCard key={imageData.file}
                                                themeIndex={index}
                                                imageData={imageData}
                                                handleClick={handleClick}
                                                lastClickedImage={lastClickedImage}
                                                changeContainer={props.changeContainer}
                                                updateChosenTheme={props.updateChosenTheme}
        />);
    return (
        <div id={'default-theme-container'} className='default-theme-container'>{defaultImageCards}</div>
    )
}

export default DefaultThemeContainer