import React from 'react'

const ImagesHeader = props => (
    <div className='header' style={{justifyContent: 'flex-end'}} >
        {/*close button*/}
        <div id={'theme-close-button'} className="theme-close-button center" onClick={props.hideMyImages}>
            <img src="images/close_black.svg" alt="images/close_black.svg"/>
        </div>
    </div>
);

export default ImagesHeader
