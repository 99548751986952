import React from "react"
import useQuestionTypeMgr from "../../../app/hooks/useQuestionTypeMgr"
import useFormImportManager from "../../../app/hooks/useFormImportManager"
import {isMobileOnly} from "react-device-detect";

const QuestionsToBeImported = (props) => {
    const {
        getImage,
        getTitle
    } = useQuestionTypeMgr()

    const {
        checkBoxBlack,
        checkBoxBlue,
        handleImportQuestionsClick,
        handleSelectQuestionClick,
        handleDeselectQuestionClick,
        selectText,
    } = useFormImportManager()

    return (
        <div>
            <div className='import-question-list'>
                {/*************************** Body ************************** */}
                {
                    props.availableQuestions.map((q, index) => {
                        return (
                            <div
                                className={'import-question-individual-card'}
                                style={{marginTop: index === 0 ? '4px' : '15px'}}
                                onClick={() => {
                                    if (q.selected) {
                                        handleDeselectQuestionClick(index)
                                    } else {
                                        handleSelectQuestionClick(index, q)
                                    }
                                }}
                                key={index}>
                                <div
                                    className={'import-question-individual-card-row'}>
                                    {`${index + 1}. ${q.title}`}
                                    {
                                        (() => {
                                            if (q.selected) {
                                                return (
                                                    <div
                                                        style={{cursor: 'pointer'}}>
                                                        <img
                                                            src={checkBoxBlue}
                                                            alt={selectText}/>
                                                    </div>
                                                )
                                            } else {
                                                return (
                                                    <div
                                                        style={{cursor: 'pointer'}}>
                                                        <img
                                                            src={checkBoxBlack}
                                                            alt={selectText}/>
                                                    </div>
                                                )
                                            }
                                        })()
                                    }
                                </div>
                                <div style={{
                                    display: 'flex',
                                    color: '#424242',
                                    alignItems: 'center',
                                    marginBottom: '10px'
                                }}>
                                    <img src={getImage(q.type)}
                                         alt={'localized text'}
                                         style={{
                                             height: '18px',
                                             width: '18px'
                                         }}/>
                                    &nbsp;&nbsp;
                                    {getTitle(q.type)}
                                </div>
                                <div style={{display: q.form_title ? 'block' : 'none'}}>
                                    <div style={{
                                        padding: '6px 8px',
                                        backgroundColor: '#E0E0E0',
                                        width: 'fit-content',
                                        borderRadius: '7px'
                                    }}>
                                        {q.form_title}
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
            {/*************************** Footer ***************************/}
            {
                (() => {
                    const selectedQuestionsCount = props.availableQuestions
                        .filter((question) => question.selected)
                        .reduce((count) => count + 1, 0)

                    return (
                        <div
                            key={'import-questions-button-container'}
                            style={{
                                visibility: selectedQuestionsCount === 0 ? 'hidden' : 'visible',
                                display: 'flex',
                                justifyContent: 'center'
                            }}>
                            <div
                                key={'import-questions-button'}
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    backgroundColor: '#004BA0',
                                    color: 'white',
                                    width: '280px',
                                    height: '50px',
                                    alignItems: 'center',
                                    borderRadius: '8px',
                                    cursor: 'pointer',
                                    position: isMobileOnly ? "fixed" : null,
                                    bottom: "1%",
                                    left: "13%"
                                }}
                                onClick={() => handleImportQuestionsClick()}
                            >
                                {`Import Questions (${selectedQuestionsCount})`}
                            </div>
                        </div>
                    )
                })()
            }
        </div>
    )
}

export default QuestionsToBeImported