import React, {useEffect} from 'react'

export default function GoogleAds(props) {
    useEffect(() => {
        (window.adsbygoogle = window.adsbygoogle || []).push({});
    }, [props.adSlot])
    return (
        <div style={{
            width: "100%"
        }}>
            <ins className="adsbygoogle"
                 style={{display: "block"}}
                 data-ad-client={props.adClient}
                 data-ad-slot={props.adSlot}
                 data-ad-format="auto"
                 data-full-width-responsive="true"/>
        </div>
    )
}
