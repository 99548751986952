import React, {useEffect, useState} from "react";
import {InputComponent} from "../InputComponent";
import styles from "../../css/choice-question-other-text.module.css";
import MaterialCheckBox from "../../../shared/components/MaterialCheckBox";
import {getTextFocusInBottomBorderColor, getThemeTextColor, isDarkTheme} from "../../utils/theme-color-helper";
import {useDispatch, useSelector} from "react-redux";
import {FORM_DATA_ACTION_TYPE} from "../../reducers/formDataReducer";
import {ACTION_TYPE} from "../../utils/utils";
import {answeredQuestionsCount, updateResponses} from "../../utils/form-response";
import {SCREENS_ACTION_TYPE} from "../../reducers/screensReducer";

export const CheckboxChoiceQuestion = (props) => {
    const [value, setValue] = useState("")
    const [isClear, setIsClear] = useState(false);
    const formData = useSelector(state => state.formDataReducer.formData)
    const themeColor = useSelector(state => state.themeReducer.themeColor)
    const responses = useSelector(state => state.formDataReducer.formResponses)
    const fileUploadAnsweredCount = useSelector(state => state.answerCountReducer?.fileUploadAnswerCount)
    const dispatch = useDispatch()

    const onChoiceSelectedEvent = (isChecked, choiceIndex) => {
        let choices = []
        dispatch({
            type: FORM_DATA_ACTION_TYPE.SET_FORM_RESPONSES,
            payload: updateResponses(responses, props.questionData, props.questionData.choices[choiceIndex]._id, false, isChecked)
        })
        dispatch({
            type: ACTION_TYPE.SET_ANSWER_COUNT,
            payload: answeredQuestionsCount(responses) + fileUploadAnsweredCount
        })
        setIsClear(false)
        responses.map((r) => {
            if (props.questionData._id === r.question_id) {
                if (r.choices[0]?.length) {
                    choices = r.choices[0]
                }
            }
        })
        props.validateRequired(choices)
    }
    const onOtherSelectedEvent = (otherText) => {
        dispatch({
            type: FORM_DATA_ACTION_TYPE.SET_FORM_RESPONSES,
            payload: updateResponses(responses, props.questionData, otherText, true)
        })
        props.validateRequired(otherText)
        dispatch({
            type: ACTION_TYPE.SET_ANSWER_COUNT,
            payload: answeredQuestionsCount(responses) + fileUploadAnsweredCount
        })
    }
    const handleImageClick = (clickedImage) => {
        const image = {
            image_url: clickedImage
        }
        window.location.hash = "image-viewer"
        dispatch({type: SCREENS_ACTION_TYPE.SET_IMAGE_VIEWER, payload: image})
    }

    useEffect(() => {
        responses.map((response) => {
            if (response.question_id === props.questionData._id) {
                if (response?.choices?.length === 0) {
                    setIsClear(true)
                }
                if (response.others) {
                    setValue(response.others)
                }
            }
        })
    }, [responses])

    const isCellSelected = (choice) => {
        let isSelected = false
        responses.map((response) => {
            if (response.question_id === props.questionData._id) {
                if (response.choices.includes(choice._id)) {
                    isSelected = true
                }
            }
        })
        return isSelected
    }

    return <React.Fragment>
        {React.Children.toArray(props.questionData.choices.map((choice, index) => {
            return (
                <>
                    {choice.image_attachment ? <div style={{
                        textAlign: "center",
                        background: (isDarkTheme(formData.theme)) ? '#000000' : '#f6f6f6'
                    }}>
                        <img src={choice.image_attachment} alt={"option image"}
                             onClick={() => handleImageClick(choice.image_attachment)}
                             style={{width: "50%", height: "175px", margin: "20px", objectFit: "cover"}}/>
                    </div> : null}
                    <MaterialCheckBox
                        name={props.questionData._id}
                        id={choice._id}
                        key={choice._id}
                        background={choice.image_attachment ? ((isDarkTheme(formData.theme)) ? '#000000' : '#f6f6f6') : null}
                        customStyle={{
                            checkedColor: (isDarkTheme(formData.theme)) ? '#212121' : getTextFocusInBottomBorderColor(formData.theme, themeColor),
                            checkboxBackgroundColor: (isDarkTheme(formData.theme)) ? '#212121' : '#ffffff',
                            checkboxBorderColor: getTextFocusInBottomBorderColor(formData.theme, themeColor),
                            checkboxLabelColor: getThemeTextColor(formData.theme)
                        }}
                        showFullLabelText={true}
                        isChecked={isClear ? false : isCellSelected(choice)}
                        onFocus={props.onFocus}
                        onChange={(e) => {
                            onChoiceSelectedEvent(e, index)
                        }}
                        checkboxLabel={choice.label ? choice.label : ' '}
                    />
                </>
            )
        }))}

        {props.questionData.is_others_allowed ?
            <InputComponent
                name={props.questionData._id}
                theme={formData.theme}
                maxLength='100'
                type='text'
                placeHolder='Other'
                value={value}
                customClassName={styles.others_text_input}
                onBlur={(element) => {
                    onOtherSelectedEvent(element.target.value);
                }}
                onInput={(element) => setValue(element.target.value)}
                customStyle={{marginBottom: '5px'}}
            /> : null}
    </React.Fragment>;
}
