import React, {useRef, useState} from "react";
import TabularViewTableRow from "./TabularViewTableRow";
import {isMobileOnly} from "react-device-detect";
import MaterialCheckBox from "../../../shared/components/MaterialCheckBox";
import getPopUpElementPositionStyles from "../../../shared/utils/get-popup-element-positions";
import {QUESTION_TYPE} from "../../../shared/utils/constants";
import {getAllFilteredRows} from "../utils/responses-helper";
import {getRole} from "../../../shared/utils/helper";
import {translationKeys} from "../../../localizations/translationKeys-localization";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";

const responseShownPerPage = 15;
let cellWidth = 240;
const PrepareTable = props => {
    const {t} = useTranslation()
    const selectedLanguage = useSelector(state => state.languageReducer.selectedLanguage)
    const userId = useSelector(state => state.userReducer?.userId)
    let tableBody = [];
    const {
        tableWidth,
        columnQuestionIndexArray,
        selectAllCheckBoxRef,
        setTable,
        filteredArrayRef,
        searchedArrayRef,
        selectedTableRows,
        setSelectedTableRows,
        summaryDataSet,
        quizUserInfo,
        SelectedCountTextRef
    } = props;
    const prevShownFullTextPopUpDataRef = useRef(null);
    let allResponseIds = summaryDataSet.responsesInfo.map(responderInfo => {
        return responderInfo.responseId
    });
    const setCellWidth = () => {
        const numberOfUserDataCells = quizUserInfo ? quizUserInfo.user_info_3 ? 3 : quizUserInfo.user_info_2 ? 2 : 1 : 0;
        const availableWidthWithUserData = (tableWidth - (isMobileOnly ? 304 : 394));//394px is the space for s.no, submittedTime and selector cells.
        const availableWidth = availableWidthWithUserData - (numberOfUserDataCells * (isMobileOnly ? 180 : 288))
        if (columnQuestionIndexArray && columnQuestionIndexArray.length > 0) {
            const individualCellWidth = (availableWidth / columnQuestionIndexArray.length) - 42;      //42px padding and Border Width of the cell
            cellWidth = (availableWidth > (columnQuestionIndexArray.length * (isMobileOnly ? 240 : 280))) ? individualCellWidth : isMobileOnly ? 200 : 240;
        } else {
            const questionsDataLengthWithoutSectionType = (summaryDataSet.questionsData.filter((question) => question.type !== QUESTION_TYPE.SECTION.key)).length
            const individualCellWidth = (availableWidth / questionsDataLengthWithoutSectionType) - 42;      //42px padding and Border Width of the cell
            cellWidth = (availableWidth > (questionsDataLengthWithoutSectionType * (isMobileOnly ? 240 : 280))) ? individualCellWidth : isMobileOnly ? 200 : 240;
        }
    }

    if (summaryDataSet.responsesInfo.length) {
        setCellWidth();
        const questionCells = <GetQuestionCells questionsData={summaryDataSet.questionsData}
                                                cellWidth={getRole(props.collaboratorsData, userId) === 0 || getRole(props.collaboratorsData, userId) === 1 ? cellWidth + 74 : cellWidth}
                                                columnQuestionIndexArray={columnQuestionIndexArray}
                                                prevShownFullTextPopUpDataRef={prevShownFullTextPopUpDataRef}
        />;

        //getting all rows
        if (Array.isArray(filteredArrayRef.current)) {
            if (filteredArrayRef.current.length && filteredArrayRef.current.length > 0) {
                let chunkedResponseIndexes;
                if (Array.isArray(searchedArrayRef.current)) {
                    chunkedResponseIndexes = searchedArrayRef.current.slice((((props.currentPageNumber ? props.currentPageNumber : 1) * responseShownPerPage) - responseShownPerPage), ((((props.currentPageNumber ? props.currentPageNumber : 1) * responseShownPerPage) - responseShownPerPage) + responseShownPerPage));
                } else {
                    chunkedResponseIndexes = filteredArrayRef.current.slice((((props.currentPageNumber ? props.currentPageNumber : 1) * responseShownPerPage) - responseShownPerPage), ((((props.currentPageNumber ? props.currentPageNumber : 1) * responseShownPerPage) - responseShownPerPage) + responseShownPerPage));
                }
                for (let j = 0; j < chunkedResponseIndexes.length; j++) {
                    for (let i = 0; i < summaryDataSet.responsesInfo.length; i++) {
                        if (chunkedResponseIndexes[j] === i) {
                            tableBody.push(<TabularViewTableRow responseIndex={i}
                                                                key={summaryDataSet.responsesInfo[i].responseId}
                                                                role={getRole(props.collaboratorsData, userId)}
                                                                quizUserInfo={quizUserInfo}
                                                                SelectedCountTextRef={SelectedCountTextRef}
                                                                setShowDeleteContainer={props.setShowDeleteBottomContainer}
                                                                isChecked={selectedTableRows && selectedTableRows.length > 0 ?
                                                                    (selectedTableRows.indexOf(summaryDataSet.responsesInfo[i].responseId) !== -1) : false}
                                                                summaryDataSet={summaryDataSet}
                                                                filteredArrayRef={filteredArrayRef}
                                                                HeaderCheckboxAry={props.HeaderCheckboxAry}
                                                                currentPageNumber={props.currentPageNumber}
                                                                searchedArrayRef={searchedArrayRef}
                                                                cellWidth={getRole(props.collaboratorsData, userId) === 0 || getRole(props.collaboratorsData, userId) === 1 ? cellWidth + 74 : cellWidth}
                                                                selectAllCheckBoxRef={selectAllCheckBoxRef}
                                                                columnQuestionIndexArray={columnQuestionIndexArray}
                                                                selectedTableRows={selectedTableRows}
                                                                setSelectedTableRows={setSelectedTableRows}
                                                                prevShownFullTextPopUpDataRef={prevShownFullTextPopUpDataRef}
                                                                changeResponseView={props.changeResponseView}/>);
                            break;
                        }
                    }
                }
            } else {
                tableBody.push(<tr key={0}/>)
            }
        } else if (Array.isArray(searchedArrayRef.current)) {
            if (searchedArrayRef.current.length && searchedArrayRef.current.length > 0) {
                let chunkedResponseIndexes = searchedArrayRef.current.slice((((props.currentPageNumber ? props.currentPageNumber : 1) * responseShownPerPage) - responseShownPerPage), ((((props.currentPageNumber ? props.currentPageNumber : 1) * responseShownPerPage) - responseShownPerPage) + responseShownPerPage));
                for (let j = 0; j < chunkedResponseIndexes.length; j++) {
                    for (let i = 0; i < summaryDataSet.responsesInfo.length; i++) {
                        if (chunkedResponseIndexes[j] === i) {
                            tableBody.push(<TabularViewTableRow responseIndex={i}
                                                                key={summaryDataSet.responsesInfo[i].responseId}
                                                                quizUserInfo={quizUserInfo}
                                                                role={getRole(props.collaboratorsData, userId)}
                                                                SelectedCountTextRef={SelectedCountTextRef}
                                                                setShowDeleteContainer={props.setShowDeleteBottomContainer}
                                                                isChecked={selectedTableRows && selectedTableRows.length > 0 ?
                                                                    (selectedTableRows.indexOf(summaryDataSet.responsesInfo[i].responseId) !== -1) : false}
                                                                summaryDataSet={summaryDataSet}
                                                                HeaderCheckboxAry={props.HeaderCheckboxAry}
                                                                currentPageNumber={props.currentPageNumber}
                                                                filteredArrayRef={filteredArrayRef}
                                                                searchedArrayRef={searchedArrayRef}
                                                                cellWidth={getRole(props.collaboratorsData, userId) === 0 || getRole(props.collaboratorsData, userId) === 1 ? cellWidth + 74 : cellWidth}
                                                                selectAllCheckBoxRef={selectAllCheckBoxRef}
                                                                columnQuestionIndexArray={columnQuestionIndexArray}
                                                                selectedTableRows={selectedTableRows}
                                                                setSelectedTableRows={setSelectedTableRows}
                                                                prevShownFullTextPopUpDataRef={prevShownFullTextPopUpDataRef}
                                                                changeResponseView={props.changeResponseView}/>);
                            break;
                        }
                    }
                }
            } else {
                tableBody.push(<tr key={0}/>)
            }
        } else {
            let chunkedResponsesInfo = summaryDataSet.responsesInfo.slice((((props.currentPageNumber ? props.currentPageNumber : 1) * responseShownPerPage) - responseShownPerPage), (((props.currentPageNumber ? props.currentPageNumber : 1) * responseShownPerPage) - responseShownPerPage) + responseShownPerPage);
            for (let i = 0; i < chunkedResponsesInfo.length; i++) {
                const responseIndex = i + (props.currentPageNumber ? (props.currentPageNumber * responseShownPerPage) - responseShownPerPage : 0);
                tableBody.push(<TabularViewTableRow
                    responseIndex={responseIndex}
                    role={getRole(props.collaboratorsData, userId)}
                    key={summaryDataSet.responsesInfo[i].responseId}
                    quizUserInfo={quizUserInfo}
                    setShowDeleteContainer={props.setShowDeleteBottomContainer}
                    SelectedCountTextRef={SelectedCountTextRef}
                    isChecked={(selectedTableRows && selectedTableRows.length > 0) ?
                        (selectedTableRows.indexOf(summaryDataSet.responsesInfo[responseIndex].responseId) !== -1) : false}
                    summaryDataSet={summaryDataSet}
                    cellWidth={getRole(props.collaboratorsData, userId) === 0 || getRole(props.collaboratorsData, userId) === 1 ? cellWidth + 74 : cellWidth}
                    HeaderCheckboxAry={props.HeaderCheckboxAry}
                    currentPageNumber={props.currentPageNumber}
                    selectAllCheckBoxRef={selectAllCheckBoxRef}
                    columnQuestionIndexArray={columnQuestionIndexArray}
                    selectedTableRows={selectedTableRows}
                    setSelectedTableRows={setSelectedTableRows}
                    prevShownFullTextPopUpDataRef={prevShownFullTextPopUpDataRef}
                    changeResponseView={props.changeResponseView}/>);
            }
        }

        const updateSelectedTableRows = (selectedResponsesArray, currentFilteredArray) => {
            let resultArray = [...selectedResponsesArray]
            for (let i = 0; i < currentFilteredArray.length; i++) {
                let response = currentFilteredArray[i]
                if (selectedResponsesArray.indexOf(response) === -1) {
                    resultArray.push(response)
                }
            }
            return resultArray
        }

        let start
        let end
        if (!filteredArrayRef.current && !searchedArrayRef.current) {
            for (let i = 0; i < props.currentPageNumber; i++) {
                if (props.currentPageNumber === i + 1) {
                    start = i * responseShownPerPage
                    end = start + responseShownPerPage
                }
            }
            allResponseIds = summaryDataSet.responsesInfo.slice(start, end).map(responderInfo => {
                return responderInfo.responseId
            })
        } else {
            allResponseIds = summaryDataSet.responsesInfo.map(responderInfo => {
                return responderInfo.responseId
            })
        }
        const handleSelectAllCheckBoxClick = isChecked => {
            props.HeaderCheckboxAry.map(element => {
                if (element.page === props.currentPageNumber) {
                    element.value = !element.value
                }
            })
            if (isChecked) {
                let selectedAllFilteredRows = getAllFilteredRows(filteredArrayRef, searchedArrayRef, allResponseIds);
                const updatedArray = updateSelectedTableRows(selectedTableRows, selectedAllFilteredRows)
                setSelectedTableRows(updatedArray);
                SelectedCountTextRef.current ?
                    SelectedCountTextRef.current.innerText = updatedArray.length + ' - ' + (updatedArray.length && updatedArray.length > 1 ? summaryDataSet.isQuiz ? t(translationKeys.answers) : t(translationKeys.responses) : summaryDataSet.isQuiz ? t(translationKeys.enter_answer) : t(translationKeys.response) ) + " " + t(translationKeys.selected) : null;
                setTable(<PrepareTable
                    isEmail={props.isEmail}
                    selectAllCheckBoxRef={selectAllCheckBoxRef}
                    columnQuestionIndexArray={columnQuestionIndexArray}
                    quizUserInfo={props.quizUserInfo}
                    filteredArrayRef={filteredArrayRef}
                    HeaderCheckboxAry={props.HeaderCheckboxAry}
                    setIsAllSelected={props.setIsAllSelected}
                    searchedArrayRef={searchedArrayRef}
                    currentPageNumber={props.currentPageNumber}
                    setShowDeleteBottomContainer={props.setShowDeleteBottomContainer}
                    SelectedCountTextRef={SelectedCountTextRef}
                    tableWidth={props.tableWidth}
                    selectedTableRows={updatedArray}
                    summaryDataSet={props.summaryDataSet}
                    setTable={setTable}
                    setSelectedTableRows={setSelectedTableRows}
                    changeResponseView={props.changeResponseView}/>);
                props.setShowDeleteBottomContainer(true)
            } else {
                const ary = []
                let selectedAllFilteredRows = getAllFilteredRows(filteredArrayRef, searchedArrayRef, allResponseIds);
                let updatedArray = updateSelectedTableRows(selectedTableRows, selectedAllFilteredRows)
                updatedArray.map(id1 => {
                    for (let i = 0; i < allResponseIds.length; i++) {
                        if (id1 === allResponseIds[i]) {
                            ary.push(id1)
                        }
                    }
                })
                updatedArray = updatedArray.filter(function (x) {
                    return ary.indexOf(x) < 0;
                });
                setSelectedTableRows(updatedArray)
                SelectedCountTextRef.current ?
                    SelectedCountTextRef.current.innerText = updatedArray.length + ' - ' + (updatedArray.length && updatedArray.length > 1 ? summaryDataSet.isQuiz ? t(translationKeys.answers) : t(translationKeys.responses) : summaryDataSet.isQuiz ? t(translationKeys.enter_answer) : t(translationKeys.response)) + " " + t(translationKeys.selected): null;
                setTable(<PrepareTable
                    isEmail={props.isEmail}
                    selectAllCheckBoxRef={selectAllCheckBoxRef}
                    columnQuestionIndexArray={columnQuestionIndexArray}
                    quizUserInfo={props.quizUserInfo}
                    filteredArrayRef={filteredArrayRef}
                    HeaderCheckboxAry={props.HeaderCheckboxAry}
                    setIsAllSelected={props.setIsAllSelected}
                    searchedArrayRef={searchedArrayRef}
                    currentPageNumber={props.currentPageNumber}
                    setShowDeleteBottomContainer={props.setShowDeleteBottomContainer}
                    SelectedCountTextRef={SelectedCountTextRef}
                    tableWidth={props.tableWidth}
                    selectedTableRows={updatedArray}
                    summaryDataSet={props.summaryDataSet}
                    setTable={setTable}
                    setSelectedTableRows={setSelectedTableRows}
                    changeResponseView={props.changeResponseView}/>);
                    props.setShowDeleteBottomContainer(selectedTableRows.length <= 15 ? false : selectedTableRows.length)
            }
        };
        return (
            <table className={'header-table'} onScroll={e => e.stopPropagation()} test-id={'responses-table'}>
                <thead>
                <tr className='table-row table-header-row' test-id={'header-row'}>
                    {getRole(props.collaboratorsData, userId) === 0 || getRole(props.collaboratorsData, userId) === 1 ? null : <th style={{
                        height: '95%',
                        fontSize: '16px',
                        backgroundColor: '#f5f5f5',
                        color: '#004ba0',
                        border: '1px solid',
                        justifyContent: 'center',
                        alignItems: 'center',
                        minWidth: isMobileOnly ? '50px' : '70px',
                        borderColor: 'transparent #acacac80 #acacac80 transparent'
                    }} className='non-printable-element'>
                        <MaterialCheckBox isChecked={selectedTableRows.length === 0 ? false : props.isAllSelected}
                                          id={'select-all-check-box'}
                                          checkBoxRef={props.selectAllCheckBoxRef}
                                          onChange={handleSelectAllCheckBoxClick}/>
                    </th>}
                    <th style={{
                        height: '95%',
                        fontSize: '16px',
                        backgroundColor: '#f5f5f5',
                        color: '#004ba0',
                        display: 'flex',
                        border: '1px solid',
                        justifyContent: 'center',
                        alignItems: 'center',
                        // minWidth: isMobileOnly ? '60px' : '90px',
                        borderColor: 'transparent #acacac80 #acacac80 transparent'
                    }}>
                                                <span className='table-header-cell-span'
                                                      style={{
                                                          padding: '10px 0',
                                                          textAlign: 'center',
                                                          minWidth: isMobileOnly ? '65px' : '90px'
                                                      }}>{t(translationKeys.serial_number)}</span>
                    </th>
                    <th id='table-header-submitted-time' style={{
                        height: '95%',
                        fontSize: '16px',
                        backgroundColor: '#f5f5f5',
                        color: '#004ba0',
                        display: 'flex',
                        border: '1px solid',
                        alignItems: 'center',
                        borderColor: 'transparent #acacac80 #acacac80 transparent',
                        justifyContent: 'flex-start'
                    }}>
                            <span className='table-header-cell-span' style={{
                                minWidth: isMobileOnly && selectedLanguage === 'ta' ? '196px' : isMobileOnly ? '160px' : '180px', padding: selectedLanguage === 'ta' ? isMobileOnly ? "5px 7px" : "5px 12px" : null
                            }}>{t(translationKeys.submitted_time)} </span>
                    </th>
                    {props.isEmail ? <th style={{
                        height: '95%',
                        fontSize: '16px',
                        backgroundColor: '#f5f5f5',
                        color: '#004ba0',
                        display: 'flex',
                        border: '1px solid',
                        alignItems: 'center',
                        borderColor: 'transparent #acacac80 #acacac80 transparent',
                        width: 280,
                        justifyContent: 'flex-start'
                    }}><span className='table-header-cell-span'>
                            {t(translationKeys.email)}</span></th> : null}
                    {quizUserInfo && quizUserInfo.user_info_1 ? <th style={{
                        height: '95%',
                        fontSize: '16px',
                        backgroundColor: '#f5f5f5',
                        color: '#004ba0',
                        display: 'flex',
                        border: '1px solid',
                        alignItems: 'center',
                        borderColor: 'transparent #acacac80 #acacac80 transparent',
                        width: 280,
                        justifyContent: 'flex-start'
                    }}><span className='table-header-cell-span'>
                            {quizUserInfo.user_info_1}</span></th> : null}
                    {quizUserInfo && quizUserInfo.user_info_2 ? <th style={{
                        height: '95%',
                        fontSize: '16px',
                        backgroundColor: '#f5f5f5',
                        color: '#004ba0',
                        display: 'flex',
                        border: '1px solid',
                        alignIems: 'center',
                        borderColor: 'transparent #acacac80 #acacac80 transparent',
                        width: 280,
                        justifyContent: 'flex-start'
                    }}><span className='table-header-cell-span'>
                            {quizUserInfo.user_info_2}</span></th> : null}
                    {quizUserInfo && quizUserInfo.user_info_3 ? <th style={{
                        height: '95%',
                        fontSize: '16px',
                        backgroundColor: '#f5f5f5',
                        color: '#004ba0',
                        display: 'flex',
                        border: '1px solid',
                        alignItems: 'center',
                        borderColor: 'transparent #acacac80 #acacac80 transparent',
                        width: 280,
                        justifyContent: 'flex-start'
                    }}><span id='table-body' className='table-header-cell-span'>
                            {quizUserInfo.user_info_3}</span></th> : null}
                    {questionCells}</tr>
                </thead>
                {/*table body*/}
                <tbody  >{tableBody}</tbody>
            </table>
        )
    } else return null
}

export default PrepareTable

const GetQuestionCells = props => {
    const {questionsData, cellWidth, columnQuestionIndexArray, prevShownFullTextPopUpDataRef} = props;
    const positionRef = useRef({});
    let questionCells = [];
    const QuestionCell = props => {
        const [showFullQuestionPopUp, setShowFullQuestionPopUp] = useState(false);
        const Ref = useRef();
        const textElementRef = useRef(null);
        return (
            <th key={questionsData[props.questionIndex].questionId}
                style={{
                    height: '95%',
                    fontSize: '16px',
                    backgroundColor: '#f5f5f5',
                    color: '#004ba0',
                    display: 'flex',
                    border: '1px solid',
                    borderColor: 'transparent #acacac80 #acacac80 transparent',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: isMobileOnly ? (cellWidth + 20) : (cellWidth + 40),
                }} className='question-cell-th'
                onMouseOver={e => {
                    prevShownFullTextPopUpDataRef.current ? prevShownFullTextPopUpDataRef.current.setShowFullTextDataPopUp(false) : null;
                    positionRef.current = getPopUpElementPositionStyles(400, 150, e);
                    prevShownFullTextPopUpDataRef.current = {
                        setShowFullTextDataPopUp: setShowFullQuestionPopUp
                    }
                    setShowFullQuestionPopUp(true);
                    document.onmouseover = (e) => {
                        if (Ref.current && !Ref.current.contains(e.target)) {
                            setShowFullQuestionPopUp(false);
                            document.onmouseover = null;
                        }
                    }
                }}>
                <div className='table-text-cell-container' ref={Ref}>
                    <p className='table-header-cell-span' style={{maxWidth: cellWidth, margin: 0}} ref={textElementRef}>
                        {props.questionSNo + ". " + questionsData[props.questionIndex].questionTitle}</p>
                    {showFullQuestionPopUp && (textElementRef.current.offsetWidth >= cellWidth + 40 && (textElementRef.current.offsetWidth < cellWidth + 45)) ?
                        <div className='show-full-text-data' style={{
                            right: positionRef.current && positionRef.current.right ? positionRef.current.right : null,
                            left: positionRef.current && positionRef.current.left ? positionRef.current.left : null,
                        }}>
                            <span>{props.questionSNo + ". " + questionsData[props.questionIndex].questionTitle}</span>
                        </div> : null}
                </div>
            </th>)
    }

    if (columnQuestionIndexArray && columnQuestionIndexArray.length > 0) {
        for (let i = 0; i < columnQuestionIndexArray.length; i++) {
            let removedSectionQuestionsCount = 0
            for (let j = 0; j < questionsData.length; j++) {
                if (questionsData[j].type !== QUESTION_TYPE.SECTION.key) {
                    if (columnQuestionIndexArray[i] === j) {
                        questionCells.push(<QuestionCell key={i} questionIndex={j}
                                                         questionSNo={((j + 1) - removedSectionQuestionsCount)}/>);
                        break;
                    }
                } else {
                    ++removedSectionQuestionsCount
                }

            }
        }
    } else {
        for (let i = 0; i < questionsData.length; i++) {
            if (questionsData[i].type !== QUESTION_TYPE.SECTION.key) {
                questionCells.push(<QuestionCell key={i} questionIndex={i} questionSNo={(questionCells.length + 1)}/>);
            }
        }
    }
    return questionCells
}