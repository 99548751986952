import React from "react"
import {Provider, useDispatch, useSelector} from "react-redux"
import '../../shared/css/autocomplete.css'
import {AUTOCOMPLETE_ACTION_TYPE} from "../../app/reducers/autocompleteReducer"
import {registerGAEvent} from "../utils/analytics";
import appStore from "../../app/store/appStore";

export default function MaterialAutocompleteSuggestions(props) {
    const reduxDispatch = useDispatch()
    const leftPos = useSelector(state => state.autocompleteReducer?.leftPos)
    const width = useSelector(state => state.autocompleteReducer?.width)
    const searchMatches = useSelector(state => state.autocompleteReducer?.matches)

    const updateQuestionText = match => (e) => {
        registerGAEvent('Builder', 'Question', 'used-question-auto-suggestion');
        e.stopPropagation();
        reduxDispatch({type: AUTOCOMPLETE_ACTION_TYPE.SET_SELECTED_VALUE, payload: match})
        reduxDispatch({type: AUTOCOMPLETE_ACTION_TYPE.CLEAR_SUGGESTIONS})
        reduxDispatch({type: AUTOCOMPLETE_ACTION_TYPE.SUGGESTION_CLICKED, payload: false})
    }

    return (
        <Provider store={appStore}>
            <div id={'autocomplete-suggestion-row'} className='autocomplete-dropdown autocomplete-dropdown-box-shadow' style={{top: props.top, left: leftPos, width: width, position: "absolute"}}>
                {
                    searchMatches?.map((match, index) => {
                        return (
                            <div className='autocomplete-suggestion-row' key={index}
                                 onClick={updateQuestionText(match)}
                                 onMouseDown={() => {
                                     reduxDispatch({type: AUTOCOMPLETE_ACTION_TYPE.SUGGESTION_CLICKED, payload: true})
                                 }}
                            >
                                {match}
                            </div>
                        )
                    })
                }
            </div>
        </Provider>
    )
}
