import React from "react"
import FormPageLoginContainer from "./FormPageLoginContainer";
import styles from "../css/welcome-screen.module.css";
import {isMinWidth600, THEME_TYPE} from "../utils/utils";
import {useSelector} from "react-redux";
import {isMobileOnly} from "react-device-detect";

const welcomeTitleContainerPCStyle = {
    "borderRadius": "10px",
    "maxHeight": "70vh",
    "overflow": "auto",
    "maxWidth": "85%",
    "minWidth": "300px",
    'textAlign': 'center'
}

const welcomeDescriptionContainerPCStyle = {
    'maxWidth': 'none',
    "maxHeight": "150px",
    "marginBottom": "15px",
    "fontSize": "22px",
    "overflow": "auto"
}

const CollectEmailPage = () => {
    const formData = useSelector(state => state.formDataReducer.formData)

    let textColor = '#fff';
    if (formData?.theme === THEME_TYPE.CLASSIC) {
        textColor = '#212121';
    }

    return (
        <div style={{
            top: '0',
            left: '0',
            margin: '0',
            padding: '0',
            zIndex: '11',
            width: '100%',
            height: '100%',
            display: 'flex',
            position: 'fixed',
            alignItems: 'center',
            flexDirection: 'column',
            justifyContent: 'center'
        }}>
            <div id={'welcome-title-container'} className={styles.welcome_title_container} style={{
                ...isMinWidth600() ? welcomeTitleContainerPCStyle : null,
                textShadow: "rgba(0, 0, 0, 0.4) 1px 1px",
                background: "none",
                maxWidth: isMobileOnly ? "90%" : "50%",
                padding: "0"
            }}>
                <h3 id={'welcome-screen-container-form-title'} style={isMinWidth600() ? {
                    fontSize: "50px",
                } : {wordWrap: "break-word"}}>
                    <b style={{color: textColor}}>{formData?.welcome_screen.title.length > (isMobileOnly ? 40 : 70) ? formData?.welcome_screen.title.substring(0, (isMobileOnly ? 40 : 70)) + '...' : formData?.welcome_screen.title}</b>
                </h3>
                <div className={styles.welcome_description_container}
                     style={isMinWidth600() ? welcomeDescriptionContainerPCStyle : null}>
                    <p id={'welcome-screen-container-form-description'} style={{
                        ...{color: textColor}
                    }}>{formData?.welcome_screen.description.length > (isMobileOnly ? 70 : 150) ? formData?.welcome_screen.description.substring(0, (isMobileOnly ? 70 : 150)) + '...' : formData?.welcome_screen.description}</p>
                </div>
            </div>
            <div style={{
                height: "35%",
                display: 'flex',
                overflow: 'hidden',
                borderRadius: "10px",
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: '#FFFFFF',
                width: isMobileOnly ? "90%" : "30%",
                boxShadow: "0px 4px 12px 0px rgba(33, 33, 33, 0.25), 0px -4px 8px 0px rgba(189, 189, 189, 0.25)"
            }}>
                <FormPageLoginContainer/>
            </div>
        </div>
    )
}

export default CollectEmailPage
