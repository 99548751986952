import React from "react"
import infoIcon from "../../../../public/images/info.svg";
import MaterialButton from "../../../shared/components/MaterialButton";
import {APP_ACTION_TYPE} from "../../reducers/appReducer";
import {Pages} from "../../utils/pages";
import {PREMIUM_ACTION_TYPE} from "../../reducers/premiumReducer";
import {useDispatch} from "react-redux";
import {translationKeys} from "../../../localizations/translationKeys-localization";
import {useTranslation} from "react-i18next";

const LimitReachedAlert = (props) => {
    const {t} = useTranslation()
    const dispatch = useDispatch()

    return (
        <div className={"quiz-summary-inner-wrapper"} style={{display: "flex", marginBottom: "0"}}>
            <img src={infoIcon} alt={"Info"} style={{margin: "auto 10px", height: "25px"}}/>
            <p style={{fontSize: "18px", alignSelf: "center"}}>{props.isQuiz ? t(translationKeys.limit_reached_alert_message_quiz) : t(translationKeys.limit_reached_alert_message)}</p>
            <div style={{margin: "auto 0"}}>
                <MaterialButton data={{
                    title: t(translationKeys.upgrade),
                    customButtonContainerStyle: {
                        borderRadius: '10px',
                        backgroundColor: '#004BA0',
                        width: '100px',
                        margin: "10px"
                    },
                    customButtonTextStyle: {
                        fontFamily: 'Nunito Sans, Sans-serif, serif',
                        fontSize: '14px'
                    }
                }} handleClick={() => {
                    dispatch({type: APP_ACTION_TYPE.SET_CURRENT_PAGE, payload: Pages.dashboard})
                    dispatch({type: PREMIUM_ACTION_TYPE.SET_SHOW_PREMIUM_PLANS_POP_UP, payload: true})
                }}/>
            </div>
        </div>
    )
}

export default LimitReachedAlert
