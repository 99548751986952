import {getQuestionCategory, QUESTION_CATEGORY, QUESTION_TYPE} from "../../../shared/utils/constants";
import {convertFirstLetterToUpper} from "../../../shared/utils/helper"

export function getSummaryDataSet(responses, formData) {
    let summaryDataSet = formData && responses ? initializeSummaryDataSet(formData, formData.is_quiz) : [];
    return formData && responses ? prepareSummaryDataSet(summaryDataSet, responses, formData.is_quiz) : [];
}

function prepareSummaryDataSet(summaryDataSet, formResponses, isQuiz) {
    if (formResponses.respondents) {
        formResponses.respondents.forEach(responderData => {
            addResponseInSummaryDataSet(responderData, summaryDataSet, isQuiz);
        })
        return summaryDataSet;
    }
}

function getQuestionChoiceList(choice, isQuiz) {
    let graphLabels = [], choiceIdList = [], graphData = [];
    let correctAnswer;
    choice.map(choice => {
        choice.label ? graphLabels.push(choice.label) : null;
        choice._id ? choiceIdList.push(choice._id) : null;
        choice.is_answer ? correctAnswer = [choice.label, choice._id] : null
        graphData.push(0);
    });
    return isQuiz ? {
        graphData: graphData,
        choiceIdList: choiceIdList,
        graphLabels: graphLabels,
        correctAnswer: correctAnswer
    } : {
        graphData: graphData,
        choiceIdList: choiceIdList,
        graphLabels: graphLabels
    }
}

const getCheckboxGridQuestionList = (rows, columns) => {
    let rowsIdList = [], columnsIdList = [], rowGraphData = [], columnGraphData = [], rowGraphLabels = [],
        columnGraphLabels = []

    rows.map((row) => {
        row.label ? rowGraphLabels.push(convertFirstLetterToUpper(row.label)) : null
        row._id ? rowsIdList.push(row._id) : null
        rowGraphData.push(0)
    })

    // For a Checkbox Grid Question, multiple columns can be selected for each row.
    columns.map((column) => {
        column.label ? columnGraphLabels.push(column.label) : null
        column._id ? columnsIdList.push(column._id) : null
    })

    return {
        rowsIdList: rowsIdList,
        columnsIdList: columnsIdList,
        rowGraphData: rowGraphData,
        columnGraphData: columnGraphData,
        rowGraphLabels: rowGraphLabels,
        columnGraphLabels: columnGraphLabels
    }
}
const getMultipleChoiceGridQuestionList = (rows, columns) => {
    let rowsIdList = [], columnsIdList = [], rowGraphData = [], columnGraphData = [], rowGraphLabels = [],
        columnGraphLabels = []
    rows.map((row) => {
        row.label ? rowGraphLabels.push(convertFirstLetterToUpper(row.label)) : null
        row._id ? rowsIdList.push(row._id) : null
        rowGraphData.push(0)
    })
    columns.map((column) => {
        column.label ? columnGraphLabels.push(column.label) : null
        column._id ? columnsIdList.push(column._id) : null
        columnGraphData.push(0)
    })

    return {
        rowsIdList: rowsIdList,
        columnsIdList: columnsIdList,
        rowGraphData: rowGraphData,
        columnGraphData: columnGraphData,
        rowGraphLabels: rowGraphLabels,
        columnGraphLabels: columnGraphLabels
    }
}

function initializeSummaryDataSet(formData, isQuiz) {
    let formQuestions = formData.pages[0].questions;
    let summaryDataSet = isQuiz ? {
        questionsData: [],
        isQuiz: isQuiz,
        responsesInfo: [],
        userInfo: formData.quiz_data.user_info
    } : {
        questionsData: [],
        responsesInfo: [],
    }
    summaryDataSet.questionsData = formQuestions ?
        formQuestions.map((questionObject) => {
            let summaryData = {
                type: questionObject.type,
                questionId: questionObject._id,
                questionTitle: questionObject.title,
                responseCount: 0,
                responseAnalyzeData: [],
                responseTextAnswers: [],
                choiceTextAnswers: []
            };
            if (getQuestionCategory(summaryData.type) === QUESTION_CATEGORY.CHOICE_TYPE || getQuestionCategory(summaryData.type) === QUESTION_CATEGORY.GRID_TYPE) {
                if (summaryData.type === QUESTION_TYPE.CHECKBOX_GRID.key) {
                    summaryData.responseAnalyzeData = getCheckboxGridQuestionList(questionObject.rows, questionObject.columns)
                } else if (summaryData.type === QUESTION_TYPE.MULTIPLE_CHOICE_GRID.key) {
                    summaryData.responseAnalyzeData = getMultipleChoiceGridQuestionList(questionObject.rows, questionObject.columns)
                } else {
                    summaryData.responseAnalyzeData = getQuestionChoiceList(questionObject.choices, isQuiz);
                }
            }
            return summaryData;
        }) : null
    return summaryDataSet
}

function addResponseInSummaryDataSet(responderData, summaryDataSet, isQuiz) {
    isQuiz ?
        summaryDataSet.responsesInfo.push({
            respondedTime: responderData.submit_time,
            responseId: responderData._id,
            userInfo: responderData.quiz_data.user_info,
            quizResults: responderData.quiz_data.results
        }) :
        summaryDataSet.responsesInfo.push({
            respondedTime: responderData.submit_time,
            responseId: responderData._id,
            userInfo: responderData.form_user_data ? responderData.form_user_data : null
        })
    summaryDataSet.questionsData.map(questionSummaryData => {
            let isAnswered = false;
            responderData.responses.forEach(questionResponse => {
                    if (questionSummaryData.questionId === questionResponse.question_id) {
                        isAnswered = true;
                        let isResponseChoiceType = (getQuestionCategory(questionResponse.type) === QUESTION_CATEGORY.CHOICE_TYPE)
                        if (isResponseChoiceType) {
                            //fetching answers of check box choice type questions.
                            if (questionResponse.type === QUESTION_TYPE.CHECKBOX_CHOICE.key) {
                                if (questionResponse.choices) {
                                    let checkBoxAnswers = [];
                                    questionResponse.choices.map(choice => {
                                        //updating graphData.
                                        let choiceIdIndex = questionSummaryData.responseAnalyzeData.choiceIdList.indexOf(choice);
                                        questionSummaryData.responseAnalyzeData.graphData[choiceIdIndex] += 1;
                                        //updating graphLabels.
                                        checkBoxAnswers.push(questionSummaryData.responseAnalyzeData.graphLabels[choiceIdIndex] ?
                                            [questionSummaryData.responseAnalyzeData.graphLabels[choiceIdIndex], choice] : null)
                                    })
                                    questionSummaryData.responseCount += 1;
                                    // getting check box other answer.
                                    questionResponse.others && questionResponse.others.length ? checkBoxAnswers.push([questionResponse.others, '']) : null;
                                    questionSummaryData.choiceTextAnswers.push(checkBoxAnswers);
                                }
                            } else {
                                //fetching answers of choice type questions except check box.
                                //updating graphData.
                                let choiceIdIndex = questionSummaryData.responseAnalyzeData.choiceIdList.indexOf(questionResponse.choice);
                                questionSummaryData.responseAnalyzeData.graphData[choiceIdIndex] += 1;
                                //updating graphLabels.
                                questionSummaryData.choiceTextAnswers.push(questionSummaryData.responseAnalyzeData.graphLabels[choiceIdIndex] ?
                                    [questionSummaryData.responseAnalyzeData.graphLabels[choiceIdIndex], questionResponse.choice] :
                                    questionResponse.others ? [(questionResponse.others), ''] : '');
                                questionSummaryData.responseCount += 1;
                            }
                            //fetching Others answers.
                            if (questionResponse.type === QUESTION_TYPE.CHECKBOX_CHOICE.key
                                || questionResponse.type === QUESTION_TYPE.MULTIPLE_CHOICE.key) {
                                questionResponse.others && questionResponse.others.length ?
                                    questionSummaryData.responseTextAnswers.push([questionResponse.others, '']) : null
                            }
                        } else if (questionResponse.type === QUESTION_TYPE.TIME.key) {
                            if (questionResponse.text !== '') {
                                let splitTimeArray = questionResponse.text.split(':')
                                let hour = parseInt(splitTimeArray[0]);
                                const AmOrPm = hour >= 12 ? 'PM' : 'AM';
                                hour = (hour % 12) || 12;
                                questionSummaryData.responseTextAnswers.push(hour + ':' + splitTimeArray[1] + ' ' + AmOrPm);
                            } else {
                                questionSummaryData.responseTextAnswers.push('')
                            }
                        } else if (questionResponse.type === QUESTION_TYPE.CHECKBOX_GRID.key) {
                            questionSummaryData.responseCount += 1;

                            if (questionResponse.checkbox_grid_choices) {
                                questionResponse.checkbox_grid_choices.map((choice) => {
                                    let rowIdIndex = questionSummaryData.responseAnalyzeData.rowsIdList.indexOf(choice.row)

                                    questionSummaryData.responseAnalyzeData.rowGraphData[rowIdIndex] += 1;

                                    const rowOfExistingColumnGraph = questionSummaryData.responseAnalyzeData.columnGraphData.find(cgd => cgd.row === choice.row)

                                    if (rowOfExistingColumnGraph) {
                                        // Append the Columns to the existing Row.
                                        choice.columns.forEach(columnToAppend =>
                                            rowOfExistingColumnGraph.columns.push(columnToAppend)
                                        )
                                    } else {
                                        // Create a new entry from the Row and Columns.
                                        questionSummaryData.responseAnalyzeData.columnGraphData.push(
                                            {
                                                row: choice.row,
                                                columns: choice.columns
                                            }
                                        )
                                    }
                                })

                                if (!questionSummaryData.checkbox_grid_choices) {
                                    questionSummaryData.checkbox_grid_choices = [...questionResponse.checkbox_grid_choices]
                                } else {
                                    questionSummaryData.checkbox_grid_choices.push(...questionResponse.checkbox_grid_choices)
                                }
                            }
                        } else if (questionResponse.type === QUESTION_TYPE.MULTIPLE_CHOICE_GRID.key) {
                            questionSummaryData.responseCount += 1;

                            if (questionResponse.grid_choices) {
                                questionResponse.grid_choices.map((choice) => {
                                    let rowIdIndex = questionSummaryData.responseAnalyzeData.rowsIdList.indexOf(choice.row);
                                    let columnIdIndex = questionSummaryData.responseAnalyzeData.columnsIdList.indexOf(choice.column);
                                    questionSummaryData.responseAnalyzeData.rowGraphData[rowIdIndex] += 1;
                                    questionSummaryData.responseAnalyzeData.columnGraphData[columnIdIndex] += 1;
                                })

                                if (questionSummaryData.grid_choices) {
                                    questionResponse.grid_choices.forEach(gc => {
                                        questionSummaryData.grid_choices.push({row: gc.row, column: gc.column})
                                    })
                                } else {
                                    questionSummaryData.grid_choices = [...questionResponse.grid_choices]
                                }
                            }
                        } else {
                            //fetching answers of text type questions.
                            questionSummaryData.responseTextAnswers.push(questionResponse.text ? questionResponse.text : '');
                        }
                    }
                }
            );
            if (!isAnswered) {
                if (getQuestionCategory(questionSummaryData.type) === QUESTION_CATEGORY.CHOICE_TYPE) {
                    questionSummaryData.choiceTextAnswers.push('');
                } else {
                    questionSummaryData.responseTextAnswers.push('');
                }
            }
            return questionSummaryData
        }
    );
    return summaryDataSet
}

export function removeResponseInSummaryDataSet(responseId, summaryDataSet, deletedResponses, originalFormRespondents) {
    const responsesInfo = summaryDataSet.responsesInfo;
    let responseIndex;

    for (let i = 0; i < responsesInfo.length; i++) {
        if (responsesInfo[i].responseId === responseId) {
            responseIndex = i;
            responsesInfo.splice(i, 1);
            break;
        }
    }

    summaryDataSet.questionsData.map(summaryData => {
        let responseTextAnswers = summaryData.responseTextAnswers
        const isChoiceType = (getQuestionCategory(summaryData.type) === QUESTION_CATEGORY.CHOICE_TYPE)
        const isGridType = (getQuestionCategory(summaryData.type) === QUESTION_CATEGORY.GRID_TYPE)

        if (isGridType) {
            // Loop through all the Questions and look for a Match with the Deleted Response so that it can be removed.
            deletedResponses?.forEach(deletedResponse => {
                if (deletedResponse.type === QUESTION_TYPE.CHECKBOX_GRID.key) {
                    // Remove the deleted Response from the Form Respondents and recalculate the Checkbox Question's Graph Data
                    let updatedFormRespondents = originalFormRespondents.filter(respondent => respondent._id !== responseId)

                    summaryData.responseCount = originalFormRespondents.length - 1
                    summaryData.checkbox_grid_choices = null
                    summaryData.responseAnalyzeData.rowGraphData = Array.from({length: originalFormRespondents.length}, () => 0)
                    summaryData.responseAnalyzeData.columnGraphData = []
                    updatedFormRespondents.forEach(respondent =>
                        respondent.responses.forEach(questionResponse => {
                            if (questionResponse.checkbox_grid_choices) {
                                questionResponse.checkbox_grid_choices.map((choice) => {
                                    let rowIdIndex = summaryData.responseAnalyzeData.rowsIdList.indexOf(choice.row)

                                    summaryData.responseAnalyzeData.rowGraphData[rowIdIndex] += 1;
                                    const rowOfExistingColumnGraph = summaryData.responseAnalyzeData.columnGraphData.find(cgd => cgd.row === choice.row)
                                    if (rowOfExistingColumnGraph) {
                                        // Append the Columns to the existing Row.
                                        choice.columns.forEach(columnToAppend =>
                                            rowOfExistingColumnGraph.columns.push(columnToAppend)
                                        )
                                    } else {
                                        // Create a new entry from the Row and Columns.
                                        summaryData.responseAnalyzeData.columnGraphData.push(
                                            {
                                                row: choice.row,
                                                columns: choice.columns
                                            }
                                        )
                                    }
                                })
                                if (!summaryData.checkbox_grid_choices) {
                                    summaryData.checkbox_grid_choices = [...questionResponse.checkbox_grid_choices]
                                } else {
                                    summaryData.checkbox_grid_choices.push(...questionResponse.checkbox_grid_choices)
                                }
                            }
                        })
                    )
                } else if (deletedResponse.type === QUESTION_TYPE.MULTIPLE_CHOICE_GRID.key) {
                    // Since Response deletion occurs one at a time, store the row/column pairing in indexesToRemove so that the combination will be used only once per deletion.
                    let indexesToRemove = []

                    // Check for Question ID match
                    if (deletedResponse.question_id === summaryData.questionId) {
                        deletedResponse.grid_choices.forEach(gc => {
                            summaryData.grid_choices.forEach((sdgc, choiceIndex) => {
                                if (sdgc.row === gc.row && sdgc.column === gc.column) {
                                    // Reduce the Response count in the Graph Data
                                    let rowIdIndex = summaryData.responseAnalyzeData.rowsIdList.indexOf(gc.row)
                                    let columnIdIndex = summaryData.responseAnalyzeData.columnsIdList.indexOf(gc.column);

                                    if (rowIdIndex > -1 && columnIdIndex > -1 && !indexesToRemove.find(rc => rc.row === gc.row && rc.column === gc.column)) {
                                        summaryData.responseAnalyzeData.rowGraphData[rowIdIndex] -= 1
                                        summaryData.responseAnalyzeData.columnGraphData[columnIdIndex] -= 1

                                        indexesToRemove.push({row: gc.row, column: gc.column})
                                    }
                                }
                            })
                        })
                    }

                    indexesToRemove.forEach(itr => {
                        let match = summaryData.grid_choices.findIndex(gc => gc.row === itr.row && gc.column === itr.column)
                        if (match > -1) {
                            summaryData.grid_choices.splice(match, 1)
                        }
                    })
                }
            })
        } else if (isChoiceType) {
            let responseAnalyzeData = summaryData.responseAnalyzeData;
            let choiceTextAnswers = summaryData.choiceTextAnswers;
            //deleting choice text answer
            let choiceTextAnswer = choiceTextAnswers.splice(responseIndex, 1)[0];
            if (summaryData.type === QUESTION_TYPE.CHECKBOX_CHOICE.key) {
                //deleting choice text answer
                choiceTextAnswer.map(eachChoiceArray => {
                    //decrementing graphData
                    responseAnalyzeData.graphData[responseAnalyzeData.choiceIdList.indexOf(eachChoiceArray[1])] -= 1;
                });
                summaryData.responseCount -= 1;
            } else {
                //decrementing graphData
                if (choiceTextAnswer)
                responseAnalyzeData.graphData[responseAnalyzeData.choiceIdList.indexOf(choiceTextAnswer[1])] -= 1;
                //decrementing response Count
                summaryData.responseCount -= 1;
            }
        }
        if (responseTextAnswers.length > 0) {
            responseTextAnswers.splice(responseIndex, 1);
        }
    });
    return summaryDataSet;
}