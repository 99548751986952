import React, {useEffect} from 'react'
import {getIndividualResponseText} from "../utils/response-filter";
import {isMobileOnly} from "react-device-detect";
import {QUESTION_TYPE} from "../../../shared/utils/constants";
import {useTranslation} from "react-i18next";
import {translationKeys} from "../../../localizations/translationKeys-localization";

function TableSearchBar(props) {
    const {t} = useTranslation()

    useEffect(() => {
        // Trigger the search logic when onSearchTrigger changes
        implementSearch();
    }, [props.onSearchTrigger]);
    const handleKeyDown = e => {
        if (e.key === 'Enter') {
            implementSearch();
        }
    }

    function handleChange() {
        if (props.inputElementRef.current.value.length) {
            props.closeButtonRef.current.style.display = 'block';
        } else {
            implementSearch()
            props.closeButtonRef.current.style.display = 'none';
        }
    }

    function handleCloseButtonClick() {
        props.searchedArrayRef.current = null;
        props.setIsFilteredArrayChanged(!props.isFilteredArrayChanged)
        props.inputElementRef.current.value = '';
        props.closeButtonRef.current.style.display = 'none';
    }

    const implementSearch = () => {
        if (props.inputElementRef?.current.value.length) {
            props.searchedArrayRef.current = getSearchedRespondentIndexesArray(props.inputElementRef.current.value, props.summaryDataSet, props.filteredArrayRef.current);
            props.setCurrentPageNumber(0);
            props.setIsFilteredArrayChanged(!props.isFilteredArrayChanged);
        } else {
            props.searchedArrayRef.current = null;
            props.setIsFilteredArrayChanged(!props.isFilteredArrayChanged)
        }
    }

    return (
        <div id='search-input-container' className='search-input-container'>
            <img className='table-search-icon' alt='search-icon' test-id={'search-icon'}
                 onClick={implementSearch}
                 src='images/search_white.svg'/>
            <div style={{width: '100%', display: 'flex', justifyContent: 'space-between'}}>
                <input ref={props.inputElementRef} type='text' className='table-search-input'
                       test-id={'search-input'}
                       onKeyDown={handleKeyDown}
                       onChange={handleChange}
                       placeholder={t(translationKeys.search)}/>
                <img ref={props.closeButtonRef} alt='close' test-id={'close-button'}
                     style={{display: 'none', width: isMobileOnly ? '14px' : '18px'}}
                     onClick={handleCloseButtonClick}
                     className='table-search-icon' src='images/close_circle.svg'/>
            </div>
        </div>
    )
}

export default TableSearchBar

const getSearchedRespondentIndexesArray = (searchedText, summaryDataSet, filteredRespondentsIndexArray) => {
    let responseText;
    let searchedResponseIndexesList = [];
    if (Array.isArray(filteredRespondentsIndexArray) && filteredRespondentsIndexArray.length > 0) {
        for (let i = 0; i < filteredRespondentsIndexArray.length; i++) {
            let responseIndex = filteredRespondentsIndexArray[i];
            let isTextPresent = false;
            if (summaryDataSet.isQuiz) {
                let UserInfoAnswersObject = summaryDataSet.responsesInfo[responseIndex]?.userInfo
                if (UserInfoAnswersObject) {
                    if ((UserInfoAnswersObject.user_info_1 && UserInfoAnswersObject.user_info_1.toLowerCase().indexOf(searchedText.toLowerCase()) > -1)
                        || (UserInfoAnswersObject.user_info_2 && UserInfoAnswersObject.user_info_2.toLowerCase().indexOf(searchedText.toLowerCase()) > -1)
                        || (UserInfoAnswersObject.user_info_3 && UserInfoAnswersObject.user_info_3.toLowerCase().indexOf(searchedText.toLowerCase()) > -1)) {
                        isTextPresent = true;
                        searchedResponseIndexesList.push(responseIndex);
                    }
                }
            }
            if (!isTextPresent) {
                for (let questionIndex = 0; questionIndex < summaryDataSet.questionsData.length; questionIndex++) {
                    responseText = getIndividualResponseText(summaryDataSet.questionsData[questionIndex], responseIndex)
                    if (responseText) {
                        if (summaryDataSet.questionsData[questionIndex].type === QUESTION_TYPE.CHECKBOX_CHOICE.key) {
                            for (let i = 0; i < responseText.length; i++) {
                                const textToCompare = Array.isArray(responseText[i]) ? responseText[i][0] : responseText[0]
                                if (textToCompare.toLowerCase().indexOf(searchedText.toLowerCase()) > -1) {
                                    isTextPresent = true;
                                    searchedResponseIndexesList.push(responseIndex);
                                    break;
                                }
                            }
                        } else {
                            const textToCompare = Array.isArray(responseText) ? responseText[0] : responseText
                            /*** replace the new line spaces between the paragraphs with single space (" ")
                             if multiple paragraphs are in the string response ***/
                            const cleanResponseText = textToCompare.replace(/(\r\n|\n|\r)/gm, " ");

                            if (cleanResponseText.toLowerCase().indexOf(searchedText.toLowerCase()) > -1) {
                                isTextPresent = true;
                                searchedResponseIndexesList.push(responseIndex);
                                break;
                            }
                        }
                    }
                    if (isTextPresent) {
                        break;
                    }
                }
            }
        }
    } else {
        for (let responseIndex = 0; responseIndex < summaryDataSet.responsesInfo.length; responseIndex++) {
            let isTextPresent = false;
            if (summaryDataSet.isQuiz) {
                let UserInfoAnswersObject = summaryDataSet.responsesInfo[responseIndex]?.userInfo
                if (UserInfoAnswersObject) {
                    if ((UserInfoAnswersObject.user_info_1 && UserInfoAnswersObject.user_info_1.toLowerCase().indexOf(searchedText.toLowerCase()) > -1)
                        || (UserInfoAnswersObject.user_info_2 && UserInfoAnswersObject.user_info_2.toLowerCase().indexOf(searchedText.toLowerCase()) > -1)
                        || (UserInfoAnswersObject.user_info_3 && UserInfoAnswersObject.user_info_3.toLowerCase().indexOf(searchedText.toLowerCase()) > -1)) {
                        isTextPresent = true;
                        searchedResponseIndexesList.push(responseIndex);
                    }
                }
            }
            if (!isTextPresent) {
                for (let questionIndex = 0; questionIndex < summaryDataSet.questionsData.length; questionIndex++) {
                    responseText = getIndividualResponseText(summaryDataSet.questionsData[questionIndex], responseIndex)
                    if (responseText) {
                        if (summaryDataSet.questionsData[questionIndex].type === QUESTION_TYPE.CHECKBOX_CHOICE.key) {
                            for (let i = 0; i < responseText.length; i++) {
                                const textToCompare = Array.isArray(responseText[i]) ? responseText[i][0] : responseText[0]
                                if (textToCompare.toLowerCase().indexOf(searchedText.toLowerCase()) > -1) {
                                    isTextPresent = true;
                                    searchedResponseIndexesList.push(responseIndex);
                                    break;
                                }
                            }
                        } else {
                            const textToCompare = Array.isArray(responseText) ? responseText[0] : responseText
                            /*** replace the new line spaces between the paragraphs with single space (" ")
                             if multiple paragraphs are in the string response ***/
                            const cleanResponseText = textToCompare.replace(/(\r\n|\n|\r)/gm, " ");

                            if (cleanResponseText.toLowerCase().indexOf(searchedText.toLowerCase()) > -1) {
                                isTextPresent = true;
                                searchedResponseIndexesList.push(responseIndex);
                                break;
                            }
                        }
                    }
                    if (isTextPresent) {
                        break;
                    }
                }
            }
        }
    }
    return searchedResponseIndexesList;
}
