import React from 'react';
import MaterialCheckBox from "../../../shared/components/MaterialCheckBox";
import {translationKeys} from "../../../localizations/translationKeys-localization";
import {t} from "i18next";

function ColumnQuestionTag(props) {
    const {
        index,
        questionIndexArray,
        questionData,
        setQuestionIndexArray,
        setColumnButtonText,
        isChecked,
        questionSNo,
        questionsLength
    } = props;

    const handleCheckBoxClick = isChecked => {
        if (isChecked) {
            let selectedQuestions = questionIndexArray;
            if (selectedQuestions.indexOf(index) === -1) {
                selectedQuestions.push(index);
                setQuestionIndexArray(selectedQuestions);
            }
        } else {
            let cleanedSelectionArray = questionIndexArray;
            cleanedSelectionArray.splice(questionIndexArray.indexOf(index), 1)
            setQuestionIndexArray(cleanedSelectionArray)
        }
        setColumnButtonText(() => {
            if (questionIndexArray) {
                if (questionIndexArray.length === 0 || questionIndexArray.length === questionsLength) {
                    return t(translationKeys.columns_all)
                } else {
                    return t(translationKeys.columns) + ' (' + questionIndexArray.length + ')'
                }
            } else {
                return t(translationKeys.columns_all)
            }
        })
    };

    return (
        <div id='question-tag' key={index} style={{display: 'flex', alignItems: 'center', width: '95%'}}>
            <MaterialCheckBox isChecked={isChecked}
                              onChange={handleCheckBoxClick}
                              checkboxLabel={questionSNo + '. ' + questionData.questionTitle}
                              id={index}/>
        </div>
    )
}

export default ColumnQuestionTag