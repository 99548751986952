export function initializeUserInfoData(quizUserInfo) {
    let userInfo = {};
    quizUserInfo.user_info_1 ? userInfo.user_info_1 = "" : null
    quizUserInfo.user_info_2 ? userInfo.user_info_2 = "" : null
    quizUserInfo.user_info_3 ? userInfo.user_info_3 = "" : null
    return userInfo;
}

/**
 *
 * @param index
 * @param userInfo
 * @param userInfoData
 * @returns {*}
 */
export function updateUserInfoData(index, userInfo, userInfoData) {
    switch (index) {
        case 0:
            userInfoData.user_info_1 = userInfo.trim();
            break;
        case 1:
            userInfoData.user_info_2 = userInfo.trim();
            break;
        case 2:
            userInfoData.user_info_3 = userInfo.trim();
            break;
    }
    return userInfoData;
}

/**
 *
 * @param userInfoData
 * @returns {boolean}
 */
export function validateUserInfoData(userInfoData) {
    let isValid = true;
    if (userInfoData.user_info_1 !== undefined && userInfoData.user_info_1.length === 0) {
        isValid = false;
    }
    if (userInfoData.user_info_2 !== undefined && userInfoData.user_info_2.length === 0) {
        isValid = false;
    }
    if (userInfoData.user_info_3 !== undefined && userInfoData.user_info_3.length === 0) {
        isValid = false;
    }
    return isValid;
}
