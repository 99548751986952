import React from "react"
import useFormImportManager from "../../../app/hooks/useFormImportManager"
import useQuestionTypeMgr from "../../../app/hooks/useQuestionTypeMgr"
import {isMobileOnly} from "react-device-detect";
import {QUESTION_TYPE} from "../../utils/constants";
import {translationKeys} from "../../../localizations/translationKeys-localization";
import {useTranslation} from "react-i18next";

const CurrentFormBody = () => {
    const {
        checkBoxBlack,
        checkBoxBlue,
        currentForm,
        handleDeselectQuestionClick,
        handleImportQuestionsClick,
        handleSelectQuestionClick,
        selectText
    } = useFormImportManager()

    const {
        getImage,
        getTitle
    } = useQuestionTypeMgr()
    const {t} = useTranslation()

    const getCorrectAnswer = (question) => {
        let correctAnswer
        if (question.choices && question.choices.length > 0) {
            question.choices?.map((choice) => {
                if (choice.is_answer) {
                    correctAnswer = choice.label
                }
            })
        } else {
            correctAnswer = question?.correct_answer
        }
        return correctAnswer
    }

    return (
        <>
            {/*************************** Body ************************** */}
            <div id={'importQuestionsList'} className={'import-question-list'}>
                {
                    currentForm?.pages?.[0]?.questions?.map((q, index) => (() => {
                        if (q.type === QUESTION_TYPE.SECTION.key) {
                            return
                        }
                        return (
                            <div className={'import-question-individual-card'}
                                 style={{marginTop: index === 0 ? '4px' : '15px'}}
                                 onClick={() => {
                                     if (q.selected) {
                                         handleDeselectQuestionClick(index)
                                     } else {
                                         handleSelectQuestionClick(index, q)
                                     }
                                 }}
                                 key={index}>
                                <div className={'import-question-individual-card-row'}>
                                    {`${index + 1}. ${q.title}`}
                                    {
                                        (() => {
                                            if (q.selected) {
                                                return (
                                                    <div style={{cursor: 'pointer'}}>
                                                        <img src={checkBoxBlue} alt={selectText}/>
                                                    </div>
                                                )
                                            } else {
                                                return (
                                                    <div style={{cursor: 'pointer'}}>
                                                        <img src={checkBoxBlack} alt={selectText}/>
                                                    </div>
                                                )
                                            }
                                        })()
                                    }
                                </div>
                                {currentForm.is_quiz && q.type !== QUESTION_TYPE.FILE_UPLOAD.key ?
                                    <div style={{display: 'flex', color: '#000000', alignItems: 'center', marginBottom: "10px"}}>
                                        {t(translationKeys.answer_s)}:&nbsp;&nbsp;
                                        <span style={{color: '#8E8E8E'}}>{getCorrectAnswer(q)}</span>
                                    </div> : null}
                                <div style={{display: 'flex', color: '#424242', alignItems: 'center'}}>
                                    <img src={getImage(q.type)} alt={'localized text'}
                                         style={{height: '18px', width: '18px'}}/>
                                    &nbsp;&nbsp;
                                    {getTitle(q.type)}
                                </div>
                            </div>
                        )
                    })())
                }
            </div>
            {/*************************** Footer ***************************/}
            {
                (() => {
                    const selectedQuestionsCount = currentForm?.pages
                        .flatMap((page) => page.questions)
                        .filter((question) => question.selected)
                        .reduce((count) => count + 1, 0)

                    return (
                        <div
                            key={'import-questions-button-container'}
                            style={{
                                visibility: selectedQuestionsCount === 0 ? 'hidden' : 'visible',
                                display: 'flex',
                                justifyContent: 'center',
                                position: isMobileOnly ? "fixed" : null,
                                bottom: "1%",
                                left: "13%"
                            }}>
                            <div
                                key={'import-questions-button'}
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    backgroundColor: '#004BA0',
                                    color: 'white',
                                    width: '280px',
                                    height: '50px',
                                    alignItems: 'center',
                                    borderRadius: '8px',
                                    marginTop: '15px',
                                    cursor: 'pointer'
                                }}
                                onClick={() => handleImportQuestionsClick()}
                            >
                                {`${t(translationKeys.import_questions)} (${selectedQuestionsCount})`}
                            </div>
                        </div>
                    )
                })()
            }
        </>
    )
}

export default CurrentFormBody
