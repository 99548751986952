import React, {useState} from 'react'
import IndividualQuestionCard from "./IndividualQuestionCard";
import {getQuestionCategory, QUESTION_CATEGORY, QUESTION_TYPE} from "../../shared/utils/constants";
import QuizScoreCard from "./QuizScoreCard";
import PoweredBySH from "../../shared/components/PoweredBySurveyheart";
import './../css/answer-sheet.css';
import DetailedIndividualQuestionContainer from "./DetailedIndividualQuestionContainer";
import PendingValidationCard from "./PendingValidationCard";
import {useTranslation} from "react-i18next";
import {translationKeys} from "../../localizations/translationKeys-localization";
import {PremiumJSON} from "../../shared/utils/helper";

const AnswerSheet = props => {
    const {t} = useTranslation()
    const [showDetailedIndividualQuestionCard, setShowDetailedIndividualQuestionCard] = useState(false)
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(false);
    const quizResults = props.quizResults;
    let isValidated = props.isQuizV2 ? !props.isEvaluating : true;
    let isChoiceTypeAvailable = false;
    let isChoiceTypeOnlyAvailable = true;
    let questionsData = props.individualResponderQuestionWiseData;
    const individualQuestionCards = questionsData ?
        questionsData.map((summaryData, index) => {
            if (summaryData.type !== QUESTION_TYPE.SECTION.key) {

                if ((getQuestionCategory(summaryData.type) === QUESTION_CATEGORY.CHOICE_TYPE)) {
                    isChoiceTypeAvailable = true;
                } else {
                    isChoiceTypeOnlyAvailable = false;
                }
                if (summaryData.evaluated_marks === undefined) {
                    isValidated = false
                }

                const handleQuestionCardClick = questionIndex => {
                    setCurrentQuestionIndex(questionIndex)
                    setShowDetailedIndividualQuestionCard(true)
                };

                return (<IndividualQuestionCard key={index} index={index} isQuiz={props.isQuiz}
                                                summaryData={summaryData} isQuizV2={props.isQuizV2}
                                                questions={props.questions}
                                                collaborationData={props.collaborationData}
                                                userId={props.userId}
                                                attachment={summaryData.attachment ? summaryData.attachment : null}
                                                isValidated={summaryData.evaluated_marks !== undefined}
                                                isQuizEdit={props.isQuizEdit}
                                                showQuizCorrectAnswer={props.showQuizCorrectAnswer}
                                                handleQuestionCardClick={handleQuestionCardClick}/>
                )
            } else {
                return null
            }
        }) : null;
    const popUpStyle = {
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        height: '100%',
        width: '100%',
        zIndex: '12',
        top: '0',
        left: '0',
        position: 'fixed',
        display: 'flex',
        justifyContent: 'center',
        alignItems: props.isQuizEdit ? props.screenHeight < 500 ? 'flex-end' : 'center' : 'center',
        margin: '0',
        padding: '0'
    };

    return (
        props.individualResponderQuestionWiseData ?
            <React.Fragment>
                <div className='individual-question-card-outer-wrapper'>
                    <div className='individual-question-card-inner-wrapper'>
                        {props.isQuiz ?
                            (props.isQuizV2 ?
                                    (isValidated ?
                                            <QuizScoreCard isPublish={props.isPublish}
                                                           isQuizEdit={props.isQuizEdit}
                                                           quizResults={quizResults}
                                                           isChoiceTypeOnlyAvailable={isChoiceTypeOnlyAvailable}/> :
                                            <PendingValidationCard isQuizEdit={props.isQuizEdit}/>
                                    )
                                    : (isChoiceTypeAvailable ?
                                            <QuizScoreCard isQuizV1={true} isQuizEdit={props.isQuizEdit}
                                                           quizResults={quizResults}/> : null
                                    )
                            )
                            : null}
                        {individualQuestionCards}
                        {PremiumJSON[props.subscriptionData?.current_subscription?.plan]?.remove_branding ? null : <PoweredBySH/>}
                    </div>
                </div>
                {showDetailedIndividualQuestionCard && props.isQuizV2 ?
                    <div style={popUpStyle}>
                        <DetailedIndividualQuestionContainer answerData={props.answerData}
                                                             currentQuestionIndex={currentQuestionIndex}
                                                             summaryQuestionsData={questionsData}
                                                             setDetailedIndividualCard={setShowDetailedIndividualQuestionCard}
                                                             responseCount={props.responseCount}
                                                             attachment={props.questionsData ? props.questionsData[currentQuestionIndex]?.attachment : null}
                                                             updateMarksInQuestionsData={props.updateMarksInQuestionsData}
                                                             setCurrentQuestionIndex={setCurrentQuestionIndex}
                                                             isQuiz={props.isQuiz}
                                                             userId={props.ownerId}
                                                             screenHeight={props.screenHeight}
                                                             isPublish={props.isPublish}
                                                             isQuizV2={props.isQuizV2} lastEditedBy={props.userId}
                                                             quizId={props.quizId}
                        />
                    </div>
                    : null}
            </React.Fragment> :
            <span style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            }}>{t(translationKeys.answer_data_not_found)}</span>
    )
}

export default AnswerSheet
