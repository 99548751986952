import React from 'react'
import {getDataFromUrl} from "../../../shared/utils/sash-v2-api";
import {AWS_FOLDER_NAMES, MEDIA_BASE_URL} from "../utils/aws";
import {useTranslation} from "react-i18next";
import {translationKeys} from "../../../localizations/translationKeys-localization";
import {useDispatch, useSelector} from "react-redux";
import {PREMIUM_ACTION_TYPE} from "../../reducers/premiumReducer";

function AddImageCard(props) {
    const {t} = useTranslation()
    const questionImages = useSelector(state => state.builderReducer.questionImages)
    const userId = useSelector(state => state.userReducer?.userId)

    const currentUserId = useSelector(state => state.userReducer?.userId)
    const encodedUserId = btoa(currentUserId);
    const dispatch = useDispatch()

    const doClickEvent = () => {
        if (props.doThemeLimitCheck) {
            const successFunction = (res) => {
                let responseJSON = res.data
                if (!questionImages && encodedUserId === 'dGVtcGxhdGVzQHN1cnZleWhlYXJ0LmNvbQ=='){
                    props.openUploadPopUp(true)
                }
                else if (!questionImages && responseJSON.theme_images.length > props.maxLimit) {
                    dispatch({type: PREMIUM_ACTION_TYPE.SET_SHOW_UPGRADE_PLAN_POP_UP, payload: true})
                } else {
                    props.openUploadPopUp(true)
                }
            }
            const failureFunction = (err) => {
                props.openUploadPopUp(true)
                console.error(err)
            }

            const requestUrl = `${MEDIA_BASE_URL}/${AWS_FOLDER_NAMES.USERS_JSON_FOLDER}/${userId}.json?id=${new Date().getTime()}`

            getDataFromUrl(requestUrl, successFunction, failureFunction)
        } else {
            props.openUploadPopUp(true)
        }
    }

    return (
        <>
            <div id={'add-local-image-card'} className='add-local-image-card center' onClick={doClickEvent}>
                <img src="../../../images/add_grey.svg" alt="/images/add_grey.svg"/>
                <span id={'theme-card-add-image'}>{t(translationKeys.add_image)}</span>
            </div>
        </>
    )
}

export default AddImageCard