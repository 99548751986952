import React from 'react'

const containerStyle = {
    padding: "10px 30px 100px 30px",
    backgroundColor: "rgb(255, 255, 255, 80%)"
}

const titleStyle = {
    textAlign: "center"
}

const descriptionStyle = {
    whiteSpace: 'pre-wrap',
    textAlign: "justify",
    fontSize: "18px"
}

export const ThankPageArticle = (props) => {
    return (<div style={containerStyle}>
        <h2 style={titleStyle}>{props.articleData.title}</h2>
        <p style={descriptionStyle}>{props.articleData.description}</p>
    </div>);
}
