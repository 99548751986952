import React, {useEffect, useState} from "react";
import {InputComponent} from "../InputComponent";
import styles from "../../css/choice-question-other-text.module.css";
import {MaterialRadioSelection} from "../MaterialRadioSelection";
import {getTextFocusInBottomBorderColor, getThemeTextColor, isDarkTheme} from "../../utils/theme-color-helper";
import {useDispatch, useSelector} from "react-redux";
import {FORM_DATA_ACTION_TYPE} from "../../reducers/formDataReducer";
import {ACTION_TYPE} from "../../utils/utils";
import {answeredQuestionsCount, updateResponses} from "../../utils/form-response";
import {SCREENS_ACTION_TYPE} from "../../reducers/screensReducer";
import useLogicQuestionMgr from "../../hooks/useLogicQuestionMgr";

export const MultipleChoiceQuestion = (props) => {
    const {updateFormQuestionsBasedOnSelection} = useLogicQuestionMgr()
    const [value, setValue] = useState("")
    const formData = useSelector(state => state.formDataReducer?.formData)
    const themeColor = useSelector(state => state.themeReducer?.themeColor)
    const responses = useSelector(state => state.formDataReducer.formResponses)
    const editResponseId = useSelector(state => state.formDataReducer.editResponseId)
    const fileUploadAnsweredCount = useSelector(state => state.answerCountReducer?.fileUploadAnswerCount)
    const [isClearRadioSelection, clearRadioSelection] = useState(false);
    const [isClearText, clearText] = useState(true);
    const dispatch = useDispatch()

    useEffect(() => {
        responses.map((response) => {
            if (response.question_id === props.questionData._id) {
                if (response?.choice?.length === 0) {
                    clearRadioSelection(true)
                } else if (response.others) {
                    clearText(false)
                    setValue(response.others)
                } else if (editResponseId) {
                    props.questionData.choices.map((choice) => {
                        if (choice._id === response.choice) {
                            updateFormQuestionsBasedOnSelection(choice, props.questionData, false)
                        }
                    })
                }
            }
        })
    }, [responses])

    const onChoiceSelectedEvent = (choiceIndex) => {
        dispatch({
            type: FORM_DATA_ACTION_TYPE.SET_FORM_RESPONSES,
            payload: updateResponses(responses, props.questionData, props.questionData.choices[choiceIndex]._id, false)
        })
        clearText(true)
        clearRadioSelection(false)
        props.validateRequired(props.questionData.choices[choiceIndex]._id)
        updateFormQuestionsBasedOnSelection(props.questionData.choices[choiceIndex], props.questionData, true)
        dispatch({
            type: ACTION_TYPE.SET_ANSWER_COUNT,
            payload: answeredQuestionsCount(responses) + fileUploadAnsweredCount
        })
    }
    const onOtherSelectedEvent = (otherText) => {
        dispatch({
            type: FORM_DATA_ACTION_TYPE.SET_FORM_RESPONSES,
            payload: updateResponses(responses, props.questionData, otherText, true)
        })
        props.validateRequired(otherText)
        dispatch({
            type: ACTION_TYPE.SET_ANSWER_COUNT,
            payload: answeredQuestionsCount(responses) + fileUploadAnsweredCount
        })
    }
    const handleImageClick = (clickedImage) => {
        const image = {
            image_url: clickedImage
        }
        window.location.hash = "image-viewer"
        dispatch({type: SCREENS_ACTION_TYPE.SET_IMAGE_VIEWER, payload: image})
    }

    const isCellSelected = (choice) => {
        let isSelected = false
        responses.map((response) => {
            if (response.question_id === props.questionData._id) {
                if (response.choice === choice._id) {
                    isSelected = true
                }
            }
        })
        return isSelected
    }

    return <React.Fragment>
        {React.Children.toArray(props.questionData.choices.map((choice, index) => {
                return (
                    <>
                        {choice.image_attachment ? <div style={{
                            textAlign: "center",
                            background: (isDarkTheme(formData.theme)) ? '#000000' : '#f6f6f6'
                        }}>
                            <img src={choice.image_attachment} alt={"option image"}
                                 onClick={() => handleImageClick(choice.image_attachment)}
                                 style={{
                                     width: "50%",
                                     height: "175px",
                                     margin: "20px",
                                     objectFit: "cover",
                                     cursor: "pointer"
                                 }}/>
                        </div> : null}
                        <MaterialRadioSelection
                            key={choice._id ? choice._id : Math.random()}
                            id={choice._id}
                            label={choice.label}
                            background={choice.image_attachment ? ((isDarkTheme(formData.theme)) ? '#000000' : '#f6f6f6') : null}
                            customStyle={{
                                radioButtonColor: getTextFocusInBottomBorderColor(formData?.theme, themeColor),
                                radioButtonLabelColor: getThemeTextColor(formData?.theme)
                            }}
                            onFocus={props.onFocus}
                            name={props.questionData._id}
                            isClearRadioSelection={isClearRadioSelection}
                            isChecked={isCellSelected(choice)}
                            onChange={() => {
                                onChoiceSelectedEvent(index);
                            }}/>
                    </>
                );
            }))}
        {props.questionData.is_others_allowed ?
            <InputComponent
                name={props.questionData._id}
                theme={formData?.theme}
                maxLength='100'
                type='text'
                isClearText={isClearText}
                value={value}
                placeHolder='Other'
                customClassName={styles.others_text_input}
                onFocus={() => {
                    clearRadioSelection(!isClearRadioSelection)
                }}
                onBlur={(element) => {
                    onOtherSelectedEvent(element.target.value);
                    if (element.target.value.length > 0) {
                        clearText(false)
                    }
                }}
                onInput={(element) => setValue(element.target.value)}
                customStyle={{marginBottom: '5px'}}/>
            : null}
    </React.Fragment>;
}
