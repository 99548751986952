/* LIBRARIES */
import throttle from "lodash/throttle";
import {isMobile} from "react-device-detect";

/* CONSTANTS */
const OFFSET = 100; // This is the top/bottom offset you use to start scrolling in the div.
const PX_DIFF = 6;

/* GLOBAL VARIABLES */
let scrollIncrement = 0;
let isScrolling = false;
let scrollableElement = null;
let scrollHeightSidebar = 0;
let clientRectBottom = 0;
let clientRectTop = 0;

/**
 * Scroll up.
 */
const goUp = () => {
  scrollIncrement -= PX_DIFF;
  scrollableElement.scrollTop = scrollIncrement;

  if (isScrolling && scrollIncrement >= 0) {
    window.requestAnimationFrame(goUp);
  }
};

/**
 * Scroll down.
 */
const goDown = () => {
  scrollIncrement += PX_DIFF;
  scrollableElement.scrollTop = scrollIncrement;
  if (isScrolling && scrollIncrement <= scrollHeightSidebar) {
    window.requestAnimationFrame(goDown);
  }
};

const onDragOver = event => {
  let y = isMobile ? event.touches[0].clientY : event.clientY;
  const isMouseOnTop = (scrollIncrement >= 0 && (y > clientRectTop) && (y < (clientRectTop + OFFSET)));
  const isMouseOnBottom = (scrollIncrement <= scrollHeightSidebar && y > clientRectBottom - OFFSET && y <= clientRectBottom);

  if (!isScrolling && (isMouseOnTop || isMouseOnBottom)) {
    isScrolling = true;
    scrollIncrement = scrollableElement.scrollTop;
    if (isMouseOnTop) {
      window.requestAnimationFrame(goUp);
    } else {
      window.requestAnimationFrame(goDown);
    }
  } else if (!isMouseOnTop && !isMouseOnBottom) {
    isScrolling = false;
  }
};

/**
 * The "throttle" method prevents executing the same function SO MANY times.
 */
const throttleOnDragOver = throttle(onDragOver, 150);

export const addEventListenerForSidebar = element => {
  // In Chrome the scrolling works.
  if ((navigator.userAgent.indexOf("Chrome") === -1) || isMobile) {
    scrollableElement = element;
    scrollHeightSidebar = scrollableElement.scrollHeight;
    const clientRect = scrollableElement.getBoundingClientRect();
    clientRectTop = clientRect.top;
    clientRectBottom = clientRect.bottom;
    if (isMobile) {
      scrollableElement.addEventListener("touchmove", throttleOnDragOver);
    } else {
      scrollableElement.addEventListener("dragover", throttleOnDragOver);
    }

  }
};

export const removeEventListenerForSidebar = () => {
  isScrolling = false;
  if (scrollableElement) {
    if (isMobile) {
      scrollableElement.removeEventListener("touchmove", throttleOnDragOver);
    } else {
      scrollableElement.removeEventListener("dragover", throttleOnDragOver);
    }
  }
};