import React, {useEffect, useRef} from "react"
import {answeredQuestionsCount, updateResponses} from "../../utils/form-response";
import {ACTION_TYPE} from "../../utils/utils";
import {useDispatch, useSelector} from "react-redux";
import {MaterialRadioSelection} from "../MaterialRadioSelection";
import {FORM_DATA_ACTION_TYPE} from "../../reducers/formDataReducer";
import {getTextFocusInBottomBorderColor, isDarkTheme} from "../../utils/theme-color-helper";
import {DataGrid} from '@mui/x-data-grid';
import {isMobileOnly} from "react-device-detect";

const MultipleChoiceGrid = (props) => {
    const themeColor = useSelector(state => state.themeReducer.themeColor)
    const formData = useSelector(state => state.formDataReducer?.formData)
    const responses = useSelector(state => state.formDataReducer.formResponses)
    const fileUploadAnsweredCount = useSelector(state => state.answerCountReducer.fileUploadAnswerCount)
    const dispatch = useDispatch()
    const loadRef = useRef()

    useEffect(() => {
        if (loadRef.current) {
            dispatch({type: FORM_DATA_ACTION_TYPE.SET_LOADING, payload: false})
        }
    }, [loadRef.current])

    const onChoiceSelectedEvent = (row, column) => {
        dispatch({
            type: FORM_DATA_ACTION_TYPE.SET_FORM_RESPONSES,
            payload: updateResponses(responses, props.questionData, {row: row._id, column: column._id})
        })
        props.validateRequired(column._id)
        dispatch({
            type: ACTION_TYPE.SET_ANSWER_COUNT,
            payload: answeredQuestionsCount(responses) + fileUploadAnsweredCount
        })
    }

    const isCellSelected = (rowId, columnId) => {
        let isSelected = false
        responses.map((response) => {
            if (response.question_id === props.questionData._id) {
                response?.grid_choices?.map((choice) => {
                    if (choice.row === rowId && choice.column === columnId) {
                        isSelected = true
                    }
                })
            }
        })
        return isSelected
    }

    const columns = [{field: " ", sortable: false, width: isMobileOnly || props.previewType ? 150 : 250, renderCell: (cellValue) => {
        return (<div style={{fontWeight: "bold", whiteSpace: "break-spaces", fontFamily: "Nunito Sans , sans-serif", lineBreak: "anywhere"}}>{cellValue.value}</div>)
        }}]
    props.questionData.columns.map((column, index) => {
        columns.push({
            headerName: column.label,
            field: column._id ? column._id : "column"+index,
            id: column._id ? column._id : "column"+index,
            width: 150,
            sortable: false,
            renderCell: (params) => {
                return (
                    <MaterialRadioSelection name={params.row.id}
                                            isChecked={isCellSelected(params.row.rowData._id, column._id)}
                                            onChange={() => onChoiceSelectedEvent(params.row.rowData, column)}
                                            customStyle={{
                                                radioButtonMargin: "auto",
                                                radioButtonColor: (isDarkTheme(formData.theme)) ? '#212121' : getTextFocusInBottomBorderColor(formData.theme, themeColor),
                                            }}/>
                )
            }
        })
    })

    const getRow = (row, rowIndex) => {
        let Row = {}
        columns.map((column, index) => {
            if (index === 0) {
                Row = {...Row, [column.field]: row.label, id: row._id ? row._id : "row"+rowIndex, rowData: row}
            }
        })
        return Row
    }

    const rows = props.questionData.rows.map((row, index) => {
        return (getRow(row, index))
    })

    return (
        <div style={{width: '100%'}} ref={loadRef}>
            <DataGrid rows={rows}
                      columns={columns}
                      sx={{
                          border: "none",
                          '.MuiDataGrid-columnHeaderTitle': {
                              width: "100%",
                              fontWeight: 'bold',
                              lineHeight: "15px",
                              textAlign: "center",
                              whiteSpace: "break-spaces",
                              fontFamily: "Nunito Sans, Sans-serif, sans-serif"
                          },
                          '& .MuiDataGrid-columnHeaderTitleContainerContent': {
                              width: "100%"
                          },
                          '& .MuiDataGrid-cell:focus': {
                              outline: 'none'
                          },
                          '& .MuiDataGrid-virtualScrollerContent': {
                              paddingBottom: `${rows.length * 5}px`, // to compensate space between rows
                              boxSizing: 'content-box',
                          },
                          '&.MuiDataGrid-root .MuiDataGrid-row': {
                              backgroundColor: '#f6f6f6',
                              borderRadius: '5px',
                              marginBottom: '5px'
                          }
                      }}
                      getRowHeight={(params) => {
                          if (isMobileOnly || props.previewType) {
                              if (params.model.rowData.label.length > 45) {
                                  return 100
                              } else if (params.model.rowData.label.length > 30) {
                                  return 80
                              } else if (params.model.rowData.label.length > 15) {
                                  return 60
                              } else {
                                  return 50
                              }
                          }
                      }}
                      hideFooter
                      columnHeaderHeight={80}
                      showCellVerticalBorder={false}
                      disableRowSelectionOnClick
                      disableColumnSelector
                      disableColumnFilter
                      disableColumnMenu/>
        </div>
    )
}

export default MultipleChoiceGrid
