import MenuIconInfo from "../../../shared/classes/MenuIconInfo";
import {QUESTION_TYPE} from "../../../shared/utils/constants.js"

/**
 * @param {QUESTION_TYPE} questionTypeValue - Value from QuestionTypeEnum
 */
class QuestionTypeMenuIconInfo extends MenuIconInfo{
    constructor(questionTypeValue) {
        super(QUESTION_TYPE[questionTypeValue.key].displayText, `${questionTypeValue.key.toLowerCase()}.svg`, "../../../images/icons/builder/question_types/", true)
    }
}

export default QuestionTypeMenuIconInfo