import React, {useEffect, useRef, useState} from 'react'
import SummaryContainer from "./SummaryContainer";
import IndividualContainer from "./IndividualContainer";
import TabularViewContainer from "./TabularViewContainer";
import Loader from "../../../shared/components/Loader";
import {SummaryDataSetProvider} from "./SummaryDataSetContext";
import ExportCard from "./ExportCard";
import QuizPublishAlert from "./QuizPublishAlert";
import {getQuizCollaborator, quizPublishService} from "../../../shared/utils/quiz-api-services";
import {getPendingEvaluationRespondentsIndex} from "../utils/evaluation-helper";
import {RESPONDENT_SORTING_FIELD} from "../utils/response-summary-sort";
import {isMobileOnly} from "react-device-detect";
import QuizPublishSuccessPopUp from "./QuizPublishSuccessPopUp";
import {RESPONSE_VIEW} from "./ResponseContainer";
import {getFormCollaborator} from "../../../shared/utils/sash-v2-api";
import {useDispatch, useSelector} from "react-redux";
import {COLLABORATOR_ACTION_TYPE} from "../../utils/constants";
import {RESPONSES_ACTION_TYPE} from "../../reducers/responsesReducer";
import MobileResponsesScreen from "./MobileResponsesScreen";
import MobileQuestionScreen from "./MobileQuestionScreen";
// import ConnectedCard from "../../../shared/components/ConnectedCard";
// import Message from "../../../shared/containers/ConfirmMessage";
// import ChooseAccountPopUp from "../../../shared/components/ChooseAccountPopUp";
import {APP_ACTION_TYPE} from "../../reducers/appReducer";
import {APP_DASHBOARD_ACTION_TYPE} from "../../reducers/appDashboardReducer";
// import SheetDisconnectedPopUp from "../../../shared/components/SheetDisconnectedPopUp";
// import {useTranslation} from "react-i18next";
// import {translationKeys} from "../../../localizations/translationKeys-localization";

function ContentContainer(props) {
    // const {t} = useTranslation()
    const isQuiz = useSelector(state => state.appReducer.isQuiz)
    const formData = useSelector(state => state.appReducer.formData)
    const showTable = useSelector(state => state.responsesReducer.showTable)
    const showSummary = useSelector(state => state.responsesReducer.showSummary)
    const isShowExport = useSelector(state => state.responsesReducer.showExport)
    const showResponses = useSelector(state => state.responsesReducer.showResponses)
    const showIndividual = useSelector(state => state.responsesReducer.showIndividual)
    const summaryDataSet = useSelector(state => state.responsesReducer.summaryDataSet)
    const formResponses = useSelector(state => state.responsesReducer.formResponsesData)
    const showQuestionScreen = useSelector(state => state.responsesReducer.showQuestionScreen)
    const isShowPublishAlert = useSelector(state => state.responsesReducer.isShowPublishAlert)
    /*const showGoogleSheetPopUp = useSelector(state => state.responsesReducer.showGoogleSheetPopUp)
    const showAcknowledgement = useSelector(state => state.responsesReducer.showAcknowledgement)
    const showChooseAccountPopUp = useSelector(state => state.responsesReducer.showChooseAccountPopUp)
    const showSheetDisconnectedPopup = useSelector(state => state.responsesReducer.showSheetDisconnectedPopup)*/
    const dispatch = useDispatch()
    const originalFormResponses = {...props.originalFormResponses};

    const previouslyOpenedCardCloseFunction = useRef(null)
    const [lastSortedOrder, setLastSortedOrder] = useState(RESPONDENT_SORTING_FIELD.SUBMIT_TIME);
    const handlePublishAlertPositiveButtonClick = unEvaluatedCount => {
        if (unEvaluatedCount) {
            setLastSortedOrder(RESPONDENT_SORTING_FIELD.NOT_EVALUATED)
            props.changeResponseView(RESPONSE_VIEW.OVERVIEW);
        } else {
            publishQuizAnswers()
        }
    }
    const popUpStyle = {
        backgroundColor: 'rgba(0, 0, 0, 0.3)',
        height: '100%',
        width: '100%',
        zIndex: '12',
        top: '0',
        left: '0',
        position: 'fixed',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: '0',
        padding: '0'
    };
    const publishQuizAnswers = () => {
        let data = {quiz_id: formResponses.formData._id}
        const successFunction = res => {
            if (!res.result) {
                props.changeResponseView(RESPONSE_VIEW.OVERVIEW);
            } else {
                const updatedFormResponses = formResponses
                updatedFormResponses.formData.is_publish = true;
                dispatch({type: APP_ACTION_TYPE.SET_FORM_DATA, payload: updatedFormResponses.formData})
                dispatch({type: RESPONSES_ACTION_TYPE.SET_FORM_RESPONSES_DATA, payload: updatedFormResponses})
                dispatch({type: RESPONSES_ACTION_TYPE.SET_IS_SHOW_PUBLISH_ALERT, payload: true})
            }
        }
        const failureFunction = err => {
            console.log(err);
        }
        quizPublishService(data, successFunction, failureFunction);
    }
    const closePreviouslyOpenedCard = (currentCloseFunction) => {
        previouslyOpenedCardCloseFunction.current()
        previouslyOpenedCardCloseFunction.current = currentCloseFunction
    }

    document.onkeydown = (e) => {
        if (e.key === "Escape") {
            dispatch({type: RESPONSES_ACTION_TYPE.SET_SHOW_EXPORT, payload: false})
            dispatch({type: RESPONSES_ACTION_TYPE.SET_IS_SHOW_PUBLISH_ALERT, payload: false})
            dispatch({type: APP_DASHBOARD_ACTION_TYPE.SET_SHOW_SHORTCUTS, payload: false})
        } else if (window.event.ctrlKey && window.event.shiftKey && e.key === "?") {
            dispatch({type: APP_DASHBOARD_ACTION_TYPE.SET_SHOW_SHORTCUTS, payload: true})

        }
    }

    useEffect(() => {
        if (formData?._id) {
            if (!isQuiz) {
                getFormCollaborator(formData?._id, (res) => {
                    if (res && res.result && res.result.collaborators) {
                        dispatch({
                            type: COLLABORATOR_ACTION_TYPE.UPDATE_COLLABORATOR_DATA,
                            payload: res.result.collaborators
                        })
                    }
                }, (err) => {
                    console.log(err)
                })
            } else if (isQuiz) {
                getQuizCollaborator(formData?._id, (res) => {
                    if (res && res.result && res.result.collaborators) {
                        dispatch({
                            type: COLLABORATOR_ACTION_TYPE.UPDATE_COLLABORATOR_DATA,
                            payload: res.result.collaborators
                        })
                    }
                }, (err) => {
                    console.log(err)
                })
            }
        }
    }, [])

    const showPublishAlert = (boolean) => {
        dispatch({type: RESPONSES_ACTION_TYPE.SET_IS_SHOW_PUBLISH_ALERT, payload: boolean})
    }

    return (
        props.isLoading ? <Loader/> :
            <SummaryDataSetProvider summaryDataSet={summaryDataSet}>
                {showSummary ?
                    <SummaryContainer formResponses={formResponses}
                                      lastSortedOrder={lastSortedOrder}
                                      setLastSortedOrder={setLastSortedOrder}
                        // formFiller={true}
                                      changeResponseView={props.changeResponseView}
                                      bodyContainerElementRef={props.bodyContainerElementRef}/>
                    : showIndividual ?
                        <IndividualContainer removeResponse={props.removeResponse}
                                             addResponse={props.addResponse}
                                             bodyContainerElementRef={props.bodyContainerElementRef}
                                             refreshStorageTotals={props.refreshStorageTotals}
                                             quizId={formData?._id}
                        />
                        : showTable ?
                            <TabularViewContainer summaryDataSet={summaryDataSet}
                                                  originalFormResponses={originalFormResponses}
                                                  formData={formResponses.formData}
                                                  changeResponseView={props.changeResponseView}
                                                  removeResponse={props.removeResponse}
                                                  addResponse={props.addResponse}
                                                  closePreviouslyOpenedCard={closePreviouslyOpenedCard}
                                                  previouslyOpenedCardCloseFunction={previouslyOpenedCardCloseFunction}
                                                  refreshStorageTotals={props.refreshStorageTotals}
                            />
                            : showResponses ? <MobileResponsesScreen/>
                                : showQuestionScreen ? <MobileQuestionScreen formData={formResponses.formData}
                                                                             summaryDataSet={summaryDataSet}
                                /> : null}
                {isShowExport ? isMobileOnly ?
                    <div style={popUpStyle}><ExportCard previouslyOpenedCardCloseFunction={previouslyOpenedCardCloseFunction}
                                                        formData={formData}
                                                        closePreviouslyOpenedCard={closePreviouslyOpenedCard}/>
                    </div> :
                    <ExportCard previouslyOpenedCardCloseFunction={previouslyOpenedCardCloseFunction}
                                formData={formData}
                                closePreviouslyOpenedCard={closePreviouslyOpenedCard}/>
                    : null}
                {isShowPublishAlert ? formResponses.formData.is_publish ?
                        <div style={popUpStyle}><QuizPublishSuccessPopUp handlePopUp={showPublishAlert}/></div> :
                        <QuizPublishAlert
                            handlePopUp={showPublishAlert}
                            unEvaluatedCount={getPendingEvaluationRespondentsIndex(formResponses.formResponses.respondents).length}
                            positiveButtonFunction={handlePublishAlertPositiveButtonClick}/>
                    : null}
                {/*{showGoogleSheetPopUp ? <div style={popUpStyle}>
                    <ConnectedCard />
                </div>: null}
                {showChooseAccountPopUp ? <div style={popUpStyle}>
                    <ChooseAccountPopUp />
                </div>: null}
                {showSheetDisconnectedPopup ? <div style={popUpStyle}>
                    <SheetDisconnectedPopUp />
                </div>: null}
                {showAcknowledgement ? <Message message={t(translationKeys.sheet_disconnected)} width={'300px'} setShowAcknowledgement={(value) => dispatch({type: RESPONSES_ACTION_TYPE.SET_SHOW_ACKNOWLEDGEMENT, payload: value})}/> : null}*/}
            </SummaryDataSetProvider>
    )
}

export default ContentContainer