import React, {useEffect, useRef, useState} from 'react';
import TableSearchBar from "./TableSearchBar";
import Loader from "../../../shared/components/Loader";
import ButtonsContainer from "./TabularButtonsContainer";
import {deleteMultipleResponsesInForm, deleteResponse} from "../../../shared/utils/sash-v2-api";
import SummaryDataSet from "./SummaryDataSetContext";
import {removeResponseInSummaryDataSet} from "../utils/summary-data";
import PrepareTable from "./PrepareResponsesTable";
import {Pages} from "../../utils/pages";
import {deleteMultipleAnswersInQuiz, deleteQuizAnswerService} from "../../../shared/utils/quiz-api-services";
import Paginator from "../../../shared/components/Paginator";
import BottomAlertContainer from "../../../shared/components/BottomAlertContainer";
import ModalDialog from "../../../shared/components/ModalDialog";
import {removeResponseIndexesFromGivenArrayRef} from "../utils/responses-helper";
import {useTranslation} from "react-i18next";
import {translationKeys} from "../../../localizations/translationKeys-localization";
import {useDispatch, useSelector} from "react-redux";
import {APP_ACTION_TYPE} from "../../reducers/appReducer";
import GridDetailsPopup from "./GridDetailsPopup";
import {RESPONSES_ACTION_TYPE} from "../../reducers/responsesReducer";
import close from "../../../../public/images/close_grey.svg";
import {APP_DASHBOARD_ACTION_TYPE} from "../../reducers/appDashboardReducer";

const HeaderCheckboxAry = [
    {
        'page': 1,
        'value': false
    }]

function TabularViewContainer(props) {
    const {t} = useTranslation()
    const formResponses = useSelector(state => state.responsesReducer.formResponsesData)
    const formData = formResponses.formData;
    const responseCount = props.summaryDataSet.responsesInfo.length;
    const tableRef = useRef(null);
    const SelectAllCheckBoxRef = useRef();
    const inputElementRef = useRef();
    const closeButtonRef = useRef();
    const [isAllSelected, setIsAllSelected] = useState(false)
    const [isLoading, setIsLoading] = useState(true);
    const [table, setTable] = useState(null);
    const [isFullScreen, setFullScreen] = useState(false);
    const [columnQuestionIndexArray, setColumnQuestionIndexArray] = useState([]);
    const [selectedTableRows, setSelectedTableRows] = useState([]);
    const [showDeleteAlert, setShowDeleteAlert] = useState(false);
    const [showDeleteBottomContainer, setShowDeleteBottomContainer] = useState(false);
    const [isFilteredArrayChanged, setIsFilteredArrayChanged] = useState(false);
    const filteredArrayRef = useRef(null);
    const searchedArrayRef = useRef(null);
    const SelectedCountTextRef = useRef(null);
    const timeFilterObjectRef = useRef(null);
    const questionFilterObject = useSelector(state => state.responsesReducer.questionFilterObject)
    const [currentPageNumber, setCurrentPageNumber] = useState(1);
    const collaboratorsList = useSelector(state => state.collaboratorsData?.collaborators)
    const showGridDetailsPopup = useSelector(state => state.gridDetailsPopupReducer.showPopup)
    let originalFormRespondents = useSelector(state => state.responsesReducer.originalFormRespondents)
    let originalSummaryDataSet = useSelector(state => state.responsesReducer.originalSummaryDataSet)
    const [toastMessage, setToastMessage] = useState(<></>)
    const [searchTrigger, setSearchTrigger] = useState(false);

    const setColumnArray = columnArray => setColumnQuestionIndexArray([...columnArray]);
    const fullScreenTabularBoxStyle = {
        position: 'fixed',
        width: '100%',
        height: '100%',
        top: '0',
        left: '0', zIndex: '5',
        paddingBottom: showDeleteBottomContainer && selectedTableRows.length > 0 ? '80px' : null
    };
    const dispatch = useDispatch()

    document.onkeydown = (e) => {
        if (e.key === "Escape") {
            setShowDeleteAlert(false)
            dispatch({type: APP_DASHBOARD_ACTION_TYPE.SET_SHOW_SHORTCUTS, payload: false})
        } else if (window.event.ctrlKey && window.event.shiftKey && e.key === "?") {
            dispatch({type: APP_DASHBOARD_ACTION_TYPE.SET_SHOW_SHORTCUTS, payload: true})
        }
    }

    useEffect(() => {
        setIsLoading(false);
        timeFilterObjectRef.current = null
    }, []);

    useEffect(() => {
        return () => {
            const storedValue = localStorage.getItem('deletedResponse');

            if (storedValue) {
                try {
                    const storedObject = JSON.parse(storedValue);
                    setIsLoading(true);

                    if(formData.is_quiz) {
                        if (Array.isArray(storedObject.responseId)) {
                            deleteMultipleAnswersInQuiz(storedObject.formId, storedObject.responseId, () =>{localStorage.setItem('deletedResponse', '')}, () =>{})
                        }
                        else {
                            deleteQuizAnswerService(storedObject.formId, storedObject.responseId, () =>{localStorage.setItem('deletedResponse', '')}, () =>{})
                        }
                    }
                    else {
                        if (Array.isArray(storedObject.responseId)) {
                            deleteMultipleResponsesInForm(storedObject.formId, storedObject.responseId, () =>{setIsLoading(false)}, () =>{setIsLoading(false)})
                        }
                        else {
                            deleteResponse(storedObject.formId, storedObject.responseId, () =>{setIsLoading(false)}, () =>{setIsLoading(false)})
                        }
                    }
                    props.removeResponse(storedObject.responseId);
                    localStorage.setItem('deletedResponse','');
                } catch (error) {
                    console.error('Error parsing JSON:', error);
                }
            }
        };
    }, []);

    useEffect(() => {
        setTable(<PrepareTable columnQuestionIndexArray={columnQuestionIndexArray}
                               quizUserInfo={formData.quiz_data ? formData.quiz_data.user_info : formData?.user_info_form?.user_info_1 ? formData?.user_info_form : undefined}
                               isEmail={formData.setting.is_email}
                               filteredArrayRef={filteredArrayRef}
                               searchedArrayRef={searchedArrayRef}
                               isAllSelected={isAllSelected}
                               HeaderCheckboxAry={HeaderCheckboxAry}
                               setIsAllSelected={setIsAllSelected}
                               setIsFilteredArrayChanged={setIsFilteredArrayChanged}
                               collaboratorsData={collaboratorsList}
                               SelectedCountTextRef={SelectedCountTextRef}
                               setShowDeleteBottomContainer={setShowDeleteBottomContainer}
                               tableWidth={tableRef.current.offsetWidth}
                               currentPageNumber={currentPageNumber}
                               setCurrentPageNumber={setCurrentPageNumber}
                               selectedTableRows={selectedTableRows}
                               summaryDataSet={props.summaryDataSet}
                               setTable={setTable}
                               selectAllCheckBoxRef={SelectAllCheckBoxRef}
                               setSelectedTableRows={setSelectedTableRows}
                               changeResponseView={props.changeResponseView}/>);
    }, [columnQuestionIndexArray, isFilteredArrayChanged, currentPageNumber, isFullScreen]);
    let intervalId;

    function handleResponseDelete(contextState) {
        setShowDeleteAlert(false);
        let summaryDataSet = contextState.summaryDataSet;
        let responseIds = selectedTableRows;
        //undo answers
        const handleUndoButtonClick = () => {
            setIsLoading(true)
            if (searchedArrayRef) {
                setSearchTrigger(!searchTrigger);
            }
            dispatch({type: APP_ACTION_TYPE.SET_FORM_DATA, payload: formData})
            contextState.updateSummaryDataSet(JSON.parse(JSON.stringify(originalSummaryDataSet)));
            dispatch({
                type: RESPONSES_ACTION_TYPE.SUMMARY_DATA_SET,
                payload: JSON.parse(JSON.stringify(originalSummaryDataSet))
            })

            if (responseCount > 15 && (responseCount - selectedTableRows.length) < 16) {
                setCurrentPageNumber(1)
            }
            setSelectedTableRows([]);
            showDeleteBottomContainer ? setShowDeleteBottomContainer(false) : null;

            clearInterval(intervalId);
            //add the response
            setTable(<PrepareTable columnQuestionIndexArray={columnQuestionIndexArray}
                                   quizUserInfo={formData.quiz_data ? formData.quiz_data.user_info : formData?.user_info_form?.user_info_1 ? formData?.user_info_form : undefined}
                                   isEmail={formData.setting.is_email}
                                   filteredArrayRef={filteredArrayRef}
                                   HeaderCheckboxAry={HeaderCheckboxAry}
                                   setIsAllSelected={setIsAllSelected}
                                   searchedArrayRef={searchedArrayRef}
                                   currentPageNumber={(responseCount > 15 && (responseCount - selectedTableRows.length) < 16) ? 1 : currentPageNumber}
                                   collaboratorsData={formData.collaborators}
                                   setCurrentPageNumber={setCurrentPageNumber}
                                   SelectedCountTextRef={SelectedCountTextRef}
                                   setShowDeleteBottomContainer={setShowDeleteBottomContainer}
                                   tableWidth={tableRef.current.offsetWidth}
                                   selectedTableRows={[]}
                                   selectAllCheckBoxRef={SelectAllCheckBoxRef}
                                   summaryDataSet={originalSummaryDataSet}
                                   setTable={setTable}
                                   setSelectedTableRows={setSelectedTableRows}
                                   changeResponseView={props.changeResponseView}/>);
            setToastMessage(<></>)
            localStorage.setItem('deletedResponse','');
            props.refreshStorageTotals()
            setIsLoading(false)
        }

        const handleCloseButton = () => {
            clearInterval(intervalId);
            setToastMessage(<></>);
            localStorage.setItem('deletedResponse','');
            deleteMultipleResponsesInForm(formData._id, responseIds, successFunction, failureFunction)
        }
        let removedSummaryDataSet;

        const deleteResponses = () => {
            setToastMessage(<></>);
            if (responseCount === selectedTableRows.length) {
                clearInterval(intervalId);
                deleteMultipleResponsesInForm(formData._id, responseIds, successFunction, failureFunction)
                dispatch({type: APP_ACTION_TYPE.SET_IS_QUIZ, payload: summaryDataSet.isQuiz})
                dispatch({type: APP_ACTION_TYPE.SET_CURRENT_PAGE, payload: Pages.dashboard})
            } else {
                if (filteredArrayRef?.current) {
                    if (filteredArrayRef.current.length === selectedTableRows.length) {
                        filteredArrayRef.current = []
                    } else {
                        removeResponseIndexesFromGivenArrayRef(responseIds, filteredArrayRef, summaryDataSet.responsesInfo)
                    }
                }
                const updatedSummaryDataSet = () => {
                    let updatedSummaryDataSet = {...summaryDataSet}

                    for (let i = 0; i < responseIds.length; i++) {
                        // Store the current state of responses, before removing the response, so that it can be passed to 'removeResponseInSummaryDataSet'.
                        // This is needed for Multiple Choice Grid questions.
                        let deletedRespondent = originalFormRespondents.find(respondent => respondent._id === responseIds[i])

                        updatedSummaryDataSet = removeResponseInSummaryDataSet(responseIds[i], updatedSummaryDataSet, deletedRespondent.responses, originalFormRespondents)

                        originalFormRespondents = originalFormRespondents.filter(respondent => respondent._id !== responseIds[i])
                    }
                    return updatedSummaryDataSet
                }
                removeResponseIndexesFromGivenArrayRef(responseIds, searchedArrayRef, summaryDataSet.responsesInfo)
                removedSummaryDataSet = updatedSummaryDataSet();
                if (responseCount > 15 && (responseCount - selectedTableRows.length) < 16) {
                    setCurrentPageNumber(1)
                }
                contextState.updateSummaryDataSet(removedSummaryDataSet)
                setSelectedTableRows([]);
                showDeleteBottomContainer ? setShowDeleteBottomContainer(false) : null;
                setTable(<PrepareTable columnQuestionIndexArray={columnQuestionIndexArray}
                                       quizUserInfo={formData.quiz_data ? formData.quiz_data.user_info : formData?.user_info_form?.user_info_1 ? formData?.user_info_form : undefined}
                                       isEmail={formData.setting.is_email}
                                       filteredArrayRef={filteredArrayRef}
                                       HeaderCheckboxAry={HeaderCheckboxAry}
                                       setIsAllSelected={setIsAllSelected}
                                       searchedArrayRef={searchedArrayRef}
                                       currentPageNumber={(responseCount > 15 && (responseCount - selectedTableRows.length) < 16) ? 1 : currentPageNumber}
                                       collaboratorsData={formData.collaborators}
                                       setCurrentPageNumber={setCurrentPageNumber}
                                       SelectedCountTextRef={SelectedCountTextRef}
                                       setShowDeleteBottomContainer={setShowDeleteBottomContainer}
                                       tableWidth={tableRef.current.offsetWidth}
                                       selectedTableRows={[]}
                                       selectAllCheckBoxRef={SelectAllCheckBoxRef}
                                       summaryDataSet={removedSummaryDataSet}
                                       setTable={setTable}
                                       setSelectedTableRows={setSelectedTableRows}
                                       changeResponseView={props.changeResponseView}/>);
            }
                props.refreshStorageTotals()
        }
        const  successFunction = res => {
            setToastMessage(<></>);
            localStorage.setItem('deletedResponse','');
            const updatedFormData = {...formData}
            updatedFormData.response_count = responseCount - responseIds.length
            dispatch({type: APP_ACTION_TYPE.SET_FORM_DATA, payload: updatedFormData})
            if (res && res.result) {
                props.removeResponse(responseIds);

                let updatedFormRespondents = JSON.parse(JSON.stringify(originalFormRespondents))

                responseIds.forEach(rId => {
                    updatedFormRespondents = updatedFormRespondents.filter(respondent => respondent._id !== rId)
                })

                dispatch({
                    type: RESPONSES_ACTION_TYPE.SET_ORIGINAL_FORM_RESPONDENTS,
                    payload: JSON.parse(JSON.stringify(updatedFormRespondents))
                })

                dispatch({
                    type: RESPONSES_ACTION_TYPE.SET_ORIGINAL_SUMMARY_DATA_SET,
                    payload: JSON.parse(JSON.stringify(removedSummaryDataSet))
                })
            }
            else {
                console.error('error deleting responses');
            }
        }
        const failureFunction = err => console.log(err)
        if (summaryDataSet.isQuiz) {

            // Delete in forms in tabular view
            deleteResponses();
            if (responseIds.length === 1) {
                const deleteResponseFunction= ()=> {
                    deleteQuizAnswerService(formData._id, responseIds[0], successFunction, failureFunction)
                    clearInterval(intervalId);
                }

                const deletedResponse = {formId: formData._id,
                    responseId: responseIds[0]}
                localStorage.setItem('deletedResponse', JSON.stringify(deletedResponse));
                setToastMessage (
                    <div className={"response-undo-toast-container"}>
                        <p className={"response-undo-message"}>{responseIds.length} {responseIds.length > 1 ? t(translationKeys.answers_deleted) : t(translationKeys.answer_deleted)} </p>
                        <div className={"response-undo-buttons-container"}>
                            <a href='#' onClick={handleUndoButtonClick} className={"response-undo-button"}>{t(translationKeys.undo)}</a>
                            <img className="response-undo-close-icon" src={close} alt="close icon" onClick={handleCloseButton}/>
                        </div>
                    </div>)
                intervalId = setInterval(deleteResponseFunction, 5000);
            } else {
                const deleteResponseFunction= ()=> {
                    deleteMultipleAnswersInQuiz(formData._id, responseIds, successFunction, failureFunction)
                    clearInterval(intervalId);
                }

                const deletedResponse = {formId: formData._id,
                    responseId: responseIds}
                localStorage.setItem('deletedResponse', JSON.stringify(deletedResponse));
                setToastMessage (
                    <div className={"response-undo-toast-container"}>
                        <p className={"response-undo-message"}>{responseIds.length} {responseIds.length > 1 ? t(translationKeys.answers_deleted) : t(translationKeys.answer_deleted)} </p>
                        <div className={"response-undo-buttons-container"}>
                            <a href='#' onClick={handleUndoButtonClick} className={"response-undo-button"}>{t(translationKeys.undo)}</a>
                            <img className="response-undo-close-icon" src={close} alt="close icon" onClick={handleCloseButton}/>
                        </div>
                    </div>)
                intervalId = setInterval(deleteResponseFunction, 5000);
            }
        } else {
            // Delete in forms in tabular view
            deleteResponses();
            const deleteResponseFunction= ()=> {
                deleteMultipleResponsesInForm(formData._id, responseIds, successFunction, failureFunction)
                clearInterval(intervalId);
            }
            const deletedResponse = {formId: formData._id,
                responseId: responseIds}
            localStorage.setItem('deletedResponse', JSON.stringify(deletedResponse));
            setToastMessage (
                <div className={"response-undo-toast-container"}>
                    <p className={"response-undo-message"}>{responseIds.length} {responseIds.length > 1 ? t(translationKeys.responses_deleted) : t(translationKeys.response_deleted)}</p>
                    <div className={"response-undo-buttons-container"}>
                        <a href='#' onClick={handleUndoButtonClick} className={"response-undo-button"}>{t(translationKeys.undo)}</a>
                        <img className="response-undo-close-icon" src={close} alt="close icon" onClick={handleCloseButton}/>
                    </div>
                </div>)
            intervalId = setInterval(deleteResponseFunction, 5000);
        }
    }

    const handlePageClick = (clickedData) => {
        let check = false
        HeaderCheckboxAry.map(element => {
            if (element.page === clickedData.selected + 1) {
                setIsAllSelected(element.value)
                check = true
            }
        })
        if (!check) {
            setIsAllSelected(false)
            HeaderCheckboxAry.push({
                'page': clickedData.selected + 1,
                'value': false
            })
        }
        setCurrentPageNumber(clickedData.selected + 1);
    }

    return (
        <>
            <SummaryDataSet.Consumer>
                {contextState => {
                    const summaryDataSet = contextState.summaryDataSet;
                    const cancelButtonClick = () => {
                        setIsAllSelected(false)
                        setSelectedTableRows([]);
                        setTable(<PrepareTable
                            columnQuestionIndexArray={columnQuestionIndexArray}
                            quizUserInfo={formData.quiz_data ?
                                formData.quiz_data.user_info : formData?.user_info_form?.user_info_1 ? formData?.user_info_form : undefined}
                            isEmail={formData.setting.is_email}
                            filteredArrayRef={filteredArrayRef}
                            collaboratorsData={formData.collaborators}
                            HeaderCheckboxAry={HeaderCheckboxAry}
                            searchedArrayRef={searchedArrayRef}
                            currentPageNumber={currentPageNumber}
                            isAllSelected={isAllSelected}
                            setCurrentPageNumber={setCurrentPageNumber}
                            setShowDeleteBottomContainer={setShowDeleteBottomContainer}
                            SelectedCountTextRef={SelectedCountTextRef}
                            tableWidth={tableRef.current.offsetWidth}
                            selectedTableRows={[]}
                            selectAllCheckBoxRef={SelectAllCheckBoxRef}
                            summaryDataSet={props.summaryDataSet}
                            setTable={setTable}
                            setSelectedTableRows={setSelectedTableRows}
                            changeResponseView={props.changeResponseView}/>);
                        setShowDeleteBottomContainer(false);
                    }
                    const deleteButtonClick = () => setShowDeleteAlert(true)

                    return (
                        <>
                            <React.Fragment>
                                <div className='tabular-box'
                                     style={isFullScreen ? fullScreenTabularBoxStyle :
                                         (showDeleteBottomContainer && selectedTableRows.length > 0) ? {paddingBottom: '80px'} : null}>
                                    <div className='table-container-header'
                                         style={isFullScreen ? {padding: '0 2%'} : null}>
                                        <TableSearchBar summaryDataSet={summaryDataSet}
                                                        filteredArrayRef={filteredArrayRef}
                                                        setIsFilteredArrayChanged={setIsFilteredArrayChanged}
                                                        searchedArrayRef={searchedArrayRef}
                                                        inputElementRef={inputElementRef}
                                                        closeButtonRef={closeButtonRef}
                                                        setSelectedTableRows={setSelectedTableRows}
                                                        setCurrentPageNumber={setCurrentPageNumber}
                                                        isFilteredArrayChanged={isFilteredArrayChanged}
                                                        onSearchTrigger={searchTrigger}/>
                                        <ButtonsContainer isFullScreen={isFullScreen}
                                                          setColumnQuestionIndexArray={setColumnArray}
                                                          columnQuestionIndexArray={columnQuestionIndexArray}
                                                          setFullScreen={setFullScreen}
                                                          timeFilterObjectRef={timeFilterObjectRef}
                                                          questionFilterObject={questionFilterObject}
                                                          filteredArrayRef={filteredArrayRef}
                                                          searchedArrayRef={searchedArrayRef}
                                                          isFilteredArrayChanged={isFilteredArrayChanged}
                                                          setIsFilteredArrayChanged={setIsFilteredArrayChanged}
                                                          searchInputElementRef={inputElementRef}
                                                          searchCloseButtonRef={closeButtonRef}
                                                          setSelectedTableRows={setSelectedTableRows}
                                                          setCurrentPageNumber={setCurrentPageNumber}
                                                          summaryDataset={summaryDataSet}
                                                          setShowDeleteBottomContainer={setShowDeleteBottomContainer}
                                                          questionsData={summaryDataSet.questionsData.length ? summaryDataSet.questionsData : null}
                                                          closePreviouslyOpenedCard={props.closePreviouslyOpenedCard}
                                                          previouslyOpenedCardCloseFunction={props.previouslyOpenedCardCloseFunction}
                                        />
                                    </div>
                                    <div className='responses-table' ref={tableRef}
                                         style={isFullScreen ? {width: '100%', height: '100%'} : null}>
                                        {isLoading ? <Loader/> : table}
                                    </div>
                                    {(responseCount > 15) ? Array.isArray(filteredArrayRef.current) ? filteredArrayRef.current.length > 15 ?
                                        Array.isArray(searchedArrayRef.current) ? searchedArrayRef.current.length > 15 ?
                                            <Paginator handlePageChange={handlePageClick} initialPage={0}
                                                       totalPageCount={Math.abs(searchedArrayRef.current.length / 15)}/>
                                            : null : <Paginator handlePageChange={handlePageClick} initialPage={0}
                                                                totalPageCount={Math.abs(filteredArrayRef.current.length / 15)}/>
                                        : null : Array.isArray(searchedArrayRef.current) ? searchedArrayRef.current.length > 15 ?
                                            <Paginator handlePageChange={handlePageClick} initialPage={0}
                                                       totalPageCount={Math.abs(searchedArrayRef.current.length / 15)}/> : null
                                        : <Paginator handlePageChange={handlePageClick}
                                                     totalPageCount={Math.abs(responseCount / 15)}/> : null}
                                </div>
                                {showDeleteBottomContainer && selectedTableRows.length > 0 ?
                                    <BottomAlertContainer positiveButtonClick={deleteButtonClick}
                                                          negativeButtonClick={cancelButtonClick}
                                                          negativeButtonText={t(translationKeys.cancel)}
                                                          positiveButtonText={t(translationKeys.delete)}
                                                          selectedDataType={formData.is_quiz ? t(translationKeys.answer_s) : t(translationKeys.response)}
                                                          selectedDataArray={selectedTableRows}
                                                          isFullScreen={isFullScreen}
                                                          SelectedCountTextRef={SelectedCountTextRef}/>
                                    : null}
                                {showDeleteAlert ?
                                    <ModalDialog
                                        header={t(translationKeys.delete) + " " + (selectedTableRows.length ? (selectedTableRows.length + (selectedTableRows.length && selectedTableRows.length > 1 ? formData.is_quiz ? " " + t(translationKeys.answers) : " " + t(translationKeys.responses) : formData.is_quiz ? " " + t(translationKeys.answer_s) : " " + t(translationKeys.response))) : formData.is_quiz ? "  " + t(translationKeys.answer_s) : " " + t(translationKeys.response))}
                                        body={[(selectedTableRows.length ? (selectedTableRows.length && selectedTableRows.length > 1 ? formData.is_quiz ? t(translationKeys.delete_answers_alert) : t(translationKeys.delete_responses_alert) : formData.is_quiz ? t(translationKeys.delete_answer_alert) : t(translationKeys.delete_response_alert)) : formData.is_quiz ? t(translationKeys.delete_answer_alert) : t(translationKeys.delete_response_alert))]}
                                        buttons={
                                            [
                                                {
                                                    text: t(translationKeys.cancel).toUpperCase(),
                                                    action: () => setShowDeleteAlert(false)
                                                },
                                                {
                                                    text: t(translationKeys.delete).toUpperCase(),
                                                    action: () => handleResponseDelete(contextState)
                                                }
                                            ]
                                        }
                                    />
                                    : null}
                                {toastMessage}
                            </React.Fragment>
                        </>
                    )
                }}
            </SummaryDataSet.Consumer>
            {
                (() => {
                    if (showGridDetailsPopup) return (
                        <GridDetailsPopup/>
                    )
                })()
            }
        </>
    )
}

export default TabularViewContainer
