import React, {useState} from "react";

const SummaryDataSet = React.createContext();
export default SummaryDataSet;

export const SummaryDataSetProvider = (props) => {
    const [summaryDataSet, setSummaryDataSet] = useState(props.summaryDataSet)

    return (
        <SummaryDataSet.Provider value={{
            summaryDataSet: summaryDataSet,
            updateSummaryDataSet: (summaryDataSet) => {
                setSummaryDataSet(summaryDataSet)
            }
        }}>
            {props.children}
        </SummaryDataSet.Provider>
    );
}