import {QUESTION_TYPE} from "../../shared/utils/constants";
import {isValidEmailTextPattern} from "../../shared/utils/validator";

export function validateFormData(responses, formQuestions, fileUploadInfo) {
    let isValid = true;
    let inValidQuestionIdList = [];
    let questionData;
    for (let i = 0; i < formQuestions.length; i++) {
        questionData = formQuestions[i];

        if (questionData.is_required && questionData.type !== QUESTION_TYPE.SECTION.key) {
            if (questionData.type === QUESTION_TYPE.FILE_UPLOAD.key) {
                let fuInfo = fileUploadInfo.find(fui => fui.questionId === questionData._id)

                if (!fuInfo || !fuInfo.file) {
                    inValidQuestionIdList.push({id: questionData._id, type: questionData.type})
                }
            } else {
                responses.map((response) => {
                    if (response.question_id === questionData._id) {
                        if (questionData.type === QUESTION_TYPE.SHORT_TEXT.key ||
                            questionData.type === QUESTION_TYPE.LONG_TEXT.key ||
                            questionData.type === QUESTION_TYPE.NUMBER.key ||
                            questionData.type === QUESTION_TYPE.DATE.key ||
                            questionData.type === QUESTION_TYPE.TIME.key) {
                            if (response.text?.trim() === '') {
                                inValidQuestionIdList.push({id: questionData._id, type: questionData.type})
                            }
                        } else if (questionData.type === QUESTION_TYPE.DROPDOWN_CHOICE.key ||
                            questionData.type === QUESTION_TYPE.STAR_RATING_SCALE.key ||
                            questionData.type === QUESTION_TYPE.LINEAR_SCALE.key) {
                            if (response.choice?.trim() === '' || response.others?.trim() === '') {
                                inValidQuestionIdList.push({id: questionData._id, type: questionData.type})
                            }
                        } else if (questionData.type === QUESTION_TYPE.MULTIPLE_CHOICE.key) {
                            if (!response.choice && !response.others ||
                                response.choice?.trim() === '' && !response.others ||
                                response.others?.trim() === '' && !response.choice ||
                                response.choice?.trim() === '' && response.others?.trim() === '') {
                                inValidQuestionIdList.push({id: questionData._id, type: questionData.type})
                            }
                        } else if (questionData.type === QUESTION_TYPE.CHECKBOX_CHOICE.key) {
                            if ((response.choices?.length === 0 && !response.others) || (response.choices?.length === 0 && response.others?.trim() === '')) {
                                inValidQuestionIdList.push({id: questionData._id, type: questionData.type})
                            }
                        } else if (questionData.type === QUESTION_TYPE.MULTIPLE_CHOICE_GRID.key || questionData.type === QUESTION_TYPE.CHECKBOX_GRID.key) {
                            if (response.grid_choices?.length === 0 || response.checkbox_grid_choices?.length === 0) {
                                inValidQuestionIdList.push({id: questionData._id, type: questionData.type})
                            }
                        } else {
                            if (response.type === QUESTION_TYPE.EMAIL.key && !isValidEmailTextPattern(response.text)) {
                                inValidQuestionIdList.push({
                                    id: questionData._id,
                                    type: questionData.type,
                                    isInvalidEmailAddress: true
                                })
                            } else if (questionData.type === QUESTION_TYPE.SMILEY.key && !response.choice) {
                                inValidQuestionIdList.push({
                                    id: questionData._id,
                                    type: questionData.type
                                })
                            }
                        }
                    }
                })
            }
        }
    }

    if (inValidQuestionIdList.length > 0) {
        isValid = false;
    }
    return {isValid: isValid, inValidQuestionIdList: inValidQuestionIdList};
}
