import React, {useState, useEffect, useRef} from 'react'
import {ShowCorrectAnswerContainer} from "./DetailedQuizQuestionCard";
import {ATTACHMENT_TYPE, getQuestionCategory, QUESTION_CATEGORY, QUESTION_TYPE} from "../../shared/utils/constants";
import {getRole} from "../../shared/utils/helper";
import {translationKeys} from "../../localizations/translationKeys-localization";
import {t} from "i18next";
import {isMobileOnly} from "react-device-detect";
import styles from "../../form/css/question-attachment.module.css";
import {detectHyperlinkText} from "../../shared/utils/validator";

function IndividualQuestionCard(props) {

    const getQuestion = () => {
        let currentQuestion
        if (props.summaryData.type === QUESTION_TYPE.MULTIPLE_CHOICE_GRID.key || props.summaryData.type === QUESTION_TYPE.CHECKBOX_GRID.key) {
            questions?.map((question) => {
                if (question._id === props.summaryData.questionId) {
                    currentQuestion = question
                }
            })
        }
        return currentQuestion
    }

    let questionTitle = props.summaryData.title;
    let questionDescription = props.summaryData.description;
    const MarkInputElement = useRef();
    const questions = props.questions
    const question = getQuestion()
    const [showFullDesc, setShowFullDesc] = useState(false)
    const [evaluatedMark, setEvaluatedMark] = useState(props.summaryData.evaluated_marks)
    const isWeblink = props.summaryData.type === QUESTION_TYPE.WEB_LINK.key || props.summaryData.type === QUESTION_TYPE.FILE_UPLOAD.key;
    const isCorrectlyAnswered = props.isQuiz ? props.isQuizV2 ? (evaluatedMark === props.summaryData.marks) : validateQuizV1Answer(props.summaryData) : null;
    const handleWebLinkUrlClickEvent = () => {
        if (isWeblink) {
            if (props.isQuiz) {
                props.summaryData.given_answer && props.summaryData.given_answer?.length ? open(props.summaryData.given_answer) : null
            } else if (props.summaryData.given_answer && props.summaryData.given_answer?.length && props.summaryData.type === QUESTION_TYPE.FILE_UPLOAD.key) {
                open(props.summaryData.given_answer)
            }
        }
    };

    const openAttachmentLink = () => {
        if (props.attachment.url) {
            window.open(props.attachment.url)
        }
    }

    useEffect(() => {
        if (props.isQuiz) {
            setEvaluatedMark(props.summaryData.evaluated_marks);
        }
    }, [props.summaryData.evaluated_marks])

    const getGridResponse = () => {
        let rowChoices = ''
        if (props.summaryData.type === QUESTION_TYPE.CHECKBOX_GRID.key) {
            for (let iRowCtr = 0; iRowCtr < question.rows?.length; iRowCtr++) {
                let rowResponseData = props.summaryData.given_answer.find(cbgc => cbgc.row === question.rows[iRowCtr]._id)

                if (rowResponseData) {
                    if (rowChoices?.length === 0) {
                        rowChoices += `${question.rows.find(r => r._id === rowResponseData.row)?.label} x`
                    } else {
                        rowChoices += `\n${question.rows.find(r => r._id === rowResponseData.row)?.label} x`
                    }

                    let previouslyAddedColumns = []

                    for (let rowResponseDataColCtr = 0; rowResponseDataColCtr < rowResponseData.columns?.length; rowResponseDataColCtr++) {
                        let currentColumnID = rowResponseData.columns[rowResponseDataColCtr]

                        if (!previouslyAddedColumns.find(column => column === currentColumnID)) {
                            let columnLabel = question.columns.find(c => c._id === currentColumnID)?.label

                            if (columnLabel) {
                                rowChoices += ` ${columnLabel},`
                                previouslyAddedColumns.push(currentColumnID)
                            }
                        }
                    }
                }
            }

            return rowChoices === '' ? t(translationKeys.no_answer) : rowChoices.slice(0, -1)
        } else if (props.summaryData.type === QUESTION_TYPE.MULTIPLE_CHOICE_GRID.key) {
            for (let iRowCtr = 0; iRowCtr < question.rows?.length; iRowCtr++) {
                let columnFromGridChoices = props.summaryData.given_answer.find(gc => gc.row === question.rows[iRowCtr]._id)?.column

                if (columnFromGridChoices) {
                    let columnLabel = question.columns.find(c => c._id === columnFromGridChoices)?.label

                    rowChoices += `${question.rows[iRowCtr]?.label} x ${columnLabel?.trim()},\n`
                }
            }
        }

        return rowChoices === '' ? t(translationKeys.no_answer) : rowChoices.slice(0, -2)
    }

    return (questionTitle ? <div
        className={props.isQuiz && props.isQuizEdit ? 'individual-response-question-card pointer' : 'individual-response-question-card'}
        onClick={getRole(props.collaborationData, props.userId) === 0 ? null : () => {
            if (props.isQuizV2 && props.isQuizEdit) {
                props.handleQuestionCardClick(props.index);
            }
        }}>
        <div id={'question-title-container'} className='question-title-container'
             style={{paddingBottom: '10px', borderBottom: '1px solid #c2c2c2'}}>
            <p className='answer-sheet-response-question-title' style={{whiteSpace: 'unset'}}>
                {(props.index + 1) + '. ' + questionTitle}
            </p>
            <p className='answer-sheet-response-question-description' style={{whiteSpace: 'unset'}}>
                {questionDescription?.length > (isMobileOnly ? 130 : 380) && !showFullDesc ? questionDescription.substring(0, (isMobileOnly ? 130 : 380)) + '...' : questionDescription}
                {questionDescription?.length > (isMobileOnly ? 130 : 380) ?
                    <span style={{color: "#1976D2", cursor: "pointer"}}
                          onClick={(e) => {
                              e.stopPropagation()
                              setShowFullDesc(!showFullDesc)
                          }}>{showFullDesc ? " Show less" : " Show more"}</span> : null}
            </p>
        </div>
        {props.attachment ? <div style={{
            paddingBottom: "15px",
            borderBottom: "solid 1px #9B9B9B"
        }}>
            {props.attachment && props.attachment.file_type === ATTACHMENT_TYPE.IMAGE.key ? <div style={{
                justifyContent: "center",
                display: 'flex',
                height: isMobileOnly ? null : '300px'
            }}>
                <img src={props.attachment?.image_url}
                     alt={'image_Url'}
                     style={{
                         display: 'flex',
                         width: isMobileOnly ? '100%' : null,
                         objectFit: 'cover'
                     }}/>
            </div> : null}
            {props.attachment && props.attachment.file_type !== ATTACHMENT_TYPE.IMAGE.key ?
                <div className={styles.question_attachment_web_container} style={{
                    display: 'flex',
                    cursor: "pointer",
                    backgroundColor: '#f5f5f5'
                }} onClick={openAttachmentLink}>
                    <img style={{
                        display: 'flex',
                        width: '30%'
                    }}
                         alt={'image'}
                         onError={e => e.target.src = '/images/link.svg'}
                         src={props.attachment?.image_url ? props.attachment?.image_url : '/images/link.svg'}/>
                    <div className={styles.question_attachment_text_container}
                         style={{
                             display: 'flex',
                             width: isMobileOnly ? '80%' : '90%'
                         }}>
                        <p className={styles.question_attachment_title}
                           style={{
                               color: '#212121'
                           }}>
                            {props.attachment?.title}
                        </p>
                        <p className={styles.question_attachment_description}>
                            {props.attachment?.description}
                        </p>
                        <p id={props.id ? props.id : null} className={styles.question_attachment_url}
                           style={{
                               color: '#0000ee'
                           }}>
                            {props.attachment?.url}
                        </p>
                    </div>
                </div> : null}
        </div> : null}
        {props.summaryData.given_answer && props.summaryData.type !== QUESTION_TYPE.FILE_UPLOAD.key && props.summaryData.type !== QUESTION_TYPE.MULTIPLE_CHOICE_GRID.key && props.summaryData.type !== QUESTION_TYPE.CHECKBOX_GRID.key ?
            <p className={isWeblink && props.summaryData.given_answer?.length ? 'response-text hover-pointer' : 'response-text'}
               style={props.isQuiz ? {
                   color: props.isQuizV2 ? getV2AnswerTextColor(evaluatedMark, props.summaryData.marks, props.summaryData.type, props.summaryData.given_answer) : getV1AnswerTextColor(props.summaryData.type, isCorrectlyAnswered),
                   textDecoration: isWeblink && props.summaryData.given_answer?.length ? 'underline' : null
               } : null} dangerouslySetInnerHTML={{__html: detectHyperlinkText(props.summaryData.given_answer)}}/> :
            <p className={isWeblink && props.summaryData.given_answer?.length ? 'response-text hover-pointer' : 'response-text'}
               onClick={handleWebLinkUrlClickEvent}
               style={props.isQuiz ? {
                   color: props.isQuizV2 ? getV2AnswerTextColor(evaluatedMark, props.summaryData.marks, props.summaryData.type, props.summaryData.given_answer) : getV1AnswerTextColor(props.summaryData.type, isCorrectlyAnswered),
                   textDecoration: isWeblink && props.summaryData.given_answer?.length ? 'underline' : null
               } : null}>
                {props.summaryData.given_answer ? props.summaryData.type === QUESTION_TYPE.FILE_UPLOAD.key ? props.summaryData.given_answer.split('/').pop() : props.summaryData.type === QUESTION_TYPE.MULTIPLE_CHOICE_GRID.key || props.summaryData.type === QUESTION_TYPE.CHECKBOX_GRID.key ? getGridResponse() : props.summaryData.given_answer : t(translationKeys.no_answer)}
            </p>}
        {props.isQuizV2 ? <div className='quiz-question-validation-container'
                               style={!props.isQuizEdit && (isCorrectlyAnswered || !props.showQuizCorrectAnswer || isWeblink) ? {justifyContent: 'flex-end'} : null}
        >
            {props.isQuizEdit ? <div id={'question-validated-tag'} className='question-validated-tag'>
                {props.isValidated ? <div className='validated-tick-mark-circle'>
                    <img alt='validated'
                         src='images/done.svg'/>
                </div> : <img alt='validated' style={{width: '14px', marginRight: '6px'}}
                              src='images/pending_validation.svg'/>}
                <span>{props.isValidated ? t(translationKeys.evaluated) : t(translationKeys.pending_evaluation)}</span>
            </div> : null}
            <div id={'summary-data-marks'} style={{justifyContent: "end", display: "flex", width: "100%"}}>
                {(getQuestionCategory(props.summaryData.type) !== QUESTION_CATEGORY.CHOICE_TYPE) && props.isQuizEdit ?
                    <img alt='edit' className='edit-mark-icon' src='images/edit_blue.svg'/> : null}
                <span className='mark-data'>
                              {props.isQuizV2 ? ((evaluatedMark ? evaluatedMark : 0) + '/' + (props.summaryData.marks ? props.summaryData.marks : 0) + t(translationKeys.marks)) : '1/1' + t(translationKeys.mark)}
                          </span>
            </div>
        </div> : null}
        {props.isQuiz && ((props.isQuizV2 && props.showQuizCorrectAnswer && !isCorrectlyAnswered && props.summaryData.type !== QUESTION_TYPE.WEB_LINK.key && props.summaryData.type !== QUESTION_TYPE.FILE_UPLOAD.key) || (!props.isQuizV2 && getQuestionCategory(props.summaryData.type) === QUESTION_CATEGORY.CHOICE_TYPE && !isCorrectlyAnswered)) ?
            <ShowCorrectAnswerContainer isQuizEdit={props.isQuizEdit} markInput={MarkInputElement}
                                        index={props.index}
                                        isQuizV2={props.isQuizV2}
                                        summaryData={props.summaryData}/> : null}
    </div> : null)
}

export default IndividualQuestionCard

const getV2AnswerTextColor = (evaluatedMark, fullMark, questionType, answer) => {
    return questionType !== QUESTION_TYPE.WEB_LINK.key ? (evaluatedMark !== undefined) ? evaluatedMark > 0 ? (evaluatedMark === fullMark) ? 'green' : '#FBC02D' : 'red' : (questionType === QUESTION_TYPE.LONG_TEXT.key) ? '#212121' : '#212121' : answer?.length ? 'blue' : '#212121';
}

const getV1AnswerTextColor = (questionType, isCorrectlyAnswered) => {
    if (getQuestionCategory(questionType) === QUESTION_CATEGORY.CHOICE_TYPE) {
        return isCorrectlyAnswered ? 'green' : 'red'
    }
    return '#212121'
}

function validateQuizV1Answer(summaryData) {
    if (summaryData.correct_answer_id && summaryData.given_answer_id) {
        return summaryData.correct_answer_id === summaryData.given_answer_id;
    }
    return false;
}