import React from 'react'
import '../css/powered-by-surveyheart.css'
import {useTranslation} from "react-i18next";
import {translationKeys} from "../../localizations/translationKeys-localization";

export default function PoweredBySH() {
    const {t} = useTranslation()
    return (
        <div id={'powered-by-sh'} className='poweredBy-sh-tag'><p style={{textAlign: 'center', width:'100vw', fontSize:'20px'}}>{t(translationKeys.powered_by_surveyheart)}</p></div>
    )
}
