// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2p5BVlguZG2-PBytnjkHvp {\n    height: auto;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    flex-direction: column;\n    margin: 15px;\n    position: relative;\n}\n\n._2p5BVlguZG2-PBytnjkHvp p {\n    max-height: 125px;\n    margin-bottom: 15px;\n    text-align: center;\n    font-size: 20px;\n    overflow: auto;\n    color: #eeeeee;\n    line-height: 1.5;\n}\n\n._2p5BVlguZG2-PBytnjkHvp p::-webkit-scrollbar {\n    display: none;\n}\n\n._2p5BVlguZG2-PBytnjkHvp h4 {\n    font-size: 28px;\n    font-weight: bold;\n    text-transform: capitalize;\n    text-shadow: 1px 1px rgba(0, 0, 0, 0.4);\n    text-align: center;\n    color: #ffffff;\n    margin: 16px;\n    overflow-x: auto;\n}\n\n.PCIdVtii1XMqoAF_b2Bn5 {\n    height: auto;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    flex-direction: column;\n    padding: 10px;\n    width: -webkit-fill-available;\n    background-color: rgba(0, 0, 0, 0.5);\n}\n", ""]);
// Exports
exports.locals = {
	"section_container": "_2p5BVlguZG2-PBytnjkHvp",
	"section_title_container": "PCIdVtii1XMqoAF_b2Bn5"
};
module.exports = exports;
