import React, {useState} from 'react'
import MaterialTextField from "../../../shared/components/MaterialTextField";
import {translationKeys} from "../../../localizations/translationKeys-localization";
import {useDispatch, useSelector} from "react-redux";
import {t} from "i18next";
import MaterialCheckBox from "../../../shared/components/MaterialCheckBox";
import {BUILDER_ACTION_TYPE} from "../../reducers/builderReducer";
import ModalDialog from "../../../shared/components/ModalDialog";
import {isMobileOnly} from "react-device-detect";
import {QUESTION_TYPE} from "../../../shared/utils/constants";
import {APP_ACTION_TYPE} from "../../reducers/appReducer";

const SettingsFormControlGeneralContainer = () => {
    const formData = useSelector(state => state.appReducer.formData)
    const formUserInfoText = useSelector(state => state.builderReducer.formUserInfoText)
    const formUserInfoCheck = useSelector(state => state.builderReducer.formUserInfoCheck)
    const [showUserInfoPopUp, setShowUserInfoPopUp] = useState(false)
    const dispatch = useDispatch()

    const handleInfo1Blur = (value) => {
        dispatch({type: BUILDER_ACTION_TYPE.SET_FORM_USER_INFO_TEXT, payload: value})
        const updatedFormData = {...formData}
        updatedFormData.user_info_form = {
            user_info_1: value?.trim()?.length ? value.trim() : t(translationKeys.name_s)
        }
        dispatch({type: APP_ACTION_TYPE.SET_FORM_DATA, payload: updatedFormData})
    }

    const handleUserInfoChanged = (e) => {
        if (!formData?.setting?.is_email) {
            dispatch({type: BUILDER_ACTION_TYPE.SET_FORM_USER_INFO_CHECK, payload: e})
            dispatch({type: BUILDER_ACTION_TYPE.SET_IS_MANUALLY_CHANGED_FORM_USER_INFO, payload: true})
            const updatedFormData = {...formData}
            if (e) {
                updatedFormData.user_info_form = {
                    user_info_1: formUserInfoText?.trim()?.length ? formUserInfoText.trim() : t(translationKeys.name_s)
                }
            } else {
                updatedFormData.user_info_form = null
            }
            dispatch({type: APP_ACTION_TYPE.SET_FORM_DATA, payload: updatedFormData})
            if (formData?.pages[0]?.questions?.filter((question) => question.type === QUESTION_TYPE.FILE_UPLOAD.key)?.length) {
                setShowUserInfoPopUp(!e)
            }
        }
    }

    return (
        <div id={'settings-quiz-control-wrapper'}
             style={{padding: '0', display: isMobileOnly ? "initial" : null}}
             className="settings-quiz-control-wrapper">
            {isMobileOnly ?
                <div style={{display: 'flex'}}>
                    {/* Row 1 */}
                    <img src="images/info.svg" alt="Info icon" style={{height: 'fit-content'}}/>
                    <div style={{width: "100%"}}>
                        <div id={'settings-quiz-control-user-info'}
                             className="settings-quiz-control-box2">
                            <div className="settings-bold-text">{t(translationKeys.user_info)}</div>
                        </div>
                        {/* Row 2 */}
                        <div id={'settings-quiz-control-user-info-description'}
                             className="settings-quiz-control-box3">
                            <span
                                className='settings-normal-text'>{t(translationKeys.user_information_description)}</span>
                        </div>
                        <div>
                            {/* Row 3 */}
                            <div id={'settings-quiz-control-box4'} className="settings-quiz-control-box4"
                                 style={{height: '5vh'}}>
                                <div style={{width: "100%"}}>
                                    <MaterialTextField key={'info1'}
                                                       data={{defaultValue: formData?.user_info_form?.user_info_1 ? formData?.user_info_form?.user_info_1 : t(translationKeys.name_s)}}
                                                       applyV2CSS={true}
                                                       onBlur={handleInfo1Blur}
                                    />
                                </div>
                                <div id={'settings-responses-and-questions-box1'}
                                     className="settings-responses-and-questions-box1">
                                    <MaterialCheckBox isChecked={formUserInfoCheck}
                                                      removeWidth={true}
                                                      onChange={handleUserInfoChanged}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div> : <>
                    <div id={'settings-responses-and-questions-box1'}
                         className="settings-responses-and-questions-box1">
                        <MaterialCheckBox isChecked={formUserInfoCheck}
                                          removeWidth={true}
                                          isDisabled={formData?.setting?.is_email}
                                          onChange={handleUserInfoChanged}
                        />
                    </div>
                    <div id={'settings-quiz-control-user-info'}
                         className="settings-responses-and-questions-box2">
                        <img src="images/info.svg" alt="Info icon"/>
                        <div className="settings-bold-text">{t(translationKeys.user_info)}</div>
                    </div>
                    {/* Row 2 */}
                    <div id={'settings-quiz-control-user-info-details'}
                         className="settings-responses-and-questions-box4">
                        <span className='settings-normal-text'>{t(translationKeys.user_information_description)}</span>
                    </div>
                    {/* Row 3 */}
                    {formUserInfoCheck ? <div id={'settings-quiz-control-box4'} className="settings-quiz-control-box4">
                        <MaterialTextField key={'info1'}
                                           data={{
                                               isReadOnly: formData?.setting?.is_email,
                                               defaultValue: formData?.user_info_form?.user_info_1 ? formData?.user_info_form?.user_info_1 : t(translationKeys.name_s)
                                           }}
                                           applyV2CSS={true}
                                           onBlur={handleInfo1Blur}
                                           maxLength={100}
                        />
                    </div> : null}
                </>
            }
            {showUserInfoPopUp ? <div style={{whiteSpace: "normal"}}>
                <ModalDialog header={t(translationKeys.user_info_for_file_upload)}
                             body={[t(translationKeys.user_info_for_file_upload_desc1), t(translationKeys.user_info_for_file_upload_desc2)]}
                             buttons={[{
                                 text: t(translationKeys.ok),
                                 action: () => {
                                     setShowUserInfoPopUp(false)
                                 }
                             }]}/>
            </div> : null}
        </div>
    )
}

export default SettingsFormControlGeneralContainer