import React from 'react'
import {PREVIEW_TYPE} from "../../../shared/utils/constants";
import {useTranslation} from "react-i18next";
import {translationKeys} from "../../../localizations/translationKeys-localization";

const PreviewPaneRightNavigator = (props) => {
    const {t} = useTranslation()
    /**
     *
     * @param {string} previewType
     */
    const handlePreviewImageClick = previewType => {
        if (previewType === PREVIEW_TYPE.PHONE) {
            props.setPreviewType(PREVIEW_TYPE.PHONE);
        } else if (previewType === PREVIEW_TYPE.TABLET) {
            props.setPreviewType(PREVIEW_TYPE.TABLET);
        } else {
            props.setPreviewType(PREVIEW_TYPE.DESKTOP);
        }
        props.openPreviewPane();
    }

    return (
        <React.Fragment>
            <div id={'builder-text-preview'} className='builder-text-preview' onClick={() => handlePreviewImageClick(PREVIEW_TYPE.PHONE)}>
                {t(translationKeys.preview)}
            </div>
            <div className='builder-preview-images'>
                <PreviewRightNavigatorIconContainer imageType={PREVIEW_TYPE.PHONE}
                                                    handleImageClick={handlePreviewImageClick}/>
                <PreviewRightNavigatorIconContainer imageType={PREVIEW_TYPE.TABLET}
                                                    handleImageClick={handlePreviewImageClick}/>
                <PreviewRightNavigatorIconContainer imageType={PREVIEW_TYPE.DESKTOP}
                                                    handleImageClick={handlePreviewImageClick}/>
            </div>
        </React.Fragment>
    )
}

export default PreviewPaneRightNavigator

const PreviewRightNavigatorIconContainer = (props) => {
    return <div className='preview-right-navigator-image-container'
                onClick={() => props.handleImageClick(props.imageType)}>
        <img
            src={props.imageType === PREVIEW_TYPE.PHONE ? "images/phone_android_24px.svg" : props.imageType === PREVIEW_TYPE.TABLET ? "images/preview_tablet_grey.svg" : "images/preview_desktop_grey.svg"}
            alt={props.imageType}
        />
    </div>
}
