// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._uwIK5IlkPu0VSwQ-HTuZ {\n    position: fixed;\n    display: flex;\n    flex-direction: column;\n    bottom: 0;\n    right: 0;\n    padding: 5px;\n    margin-right: 10px;\n    margin-bottom: 65px;\n}\n\n.eYelt8eTtIdLUX3ZMukWe {\n    height: 18px;\n    width: 18px;\n    transform: rotate(0deg);\n    transition: 0.2s ease-in-out;\n}\n\n._2rWAUhLioxAqQigz9JrBl8 {\n    height: 18px;\n    width: 18px;\n    transform: rotate(180deg);\n    transition: 0.2s ease-in-out;\n}\n\n._3-kcY8Vqor6S_pXIVx2ubB {\n    opacity: 0.5;\n    height: 56px;\n    width: 56px;\n    margin: 4px;\n    border-radius: 50%;\n    background-color: #000;\n    display: flex;\n    color: #fff;\n    align-items: center;\n    justify-content: center;\n    cursor: pointer;\n}\n\n._3yPo2GSShT20-NFhVJHD70 {\n    display: inline-block;\n    height: 18px;\n    width: 18px;\n    vertical-align: middle;\n    margin: 5px;\n}\n", ""]);
// Exports
exports.locals = {
	"scroll_fab": "_uwIK5IlkPu0VSwQ-HTuZ",
	"scroll_to_bottom": "eYelt8eTtIdLUX3ZMukWe",
	"scroll_to_top": "_2rWAUhLioxAqQigz9JrBl8",
	"scroll_to_next": "_3-kcY8Vqor6S_pXIVx2ubB",
	"image_icon": "_3yPo2GSShT20-NFhVJHD70"
};
module.exports = exports;
