// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1FSn4P7AaXhu6xs7mAFm9W {\n    position: absolute;\n    width: 100%;\n    height: 100%;\n    left: 50%;\n    transform: translateX(-50%);\n}\n\n._1FSn4P7AaXhu6xs7mAFm9W img {\n    width: 100%;\n    height: 100%;\n    object-fit: cover;\n    background-size: cover;\n    background-position: center;\n}\n\n@media only screen and (min-width: 600px) {\n    /* For desktop: */\n    ._1FSn4P7AaXhu6xs7mAFm9W img {\n        width: 100%;\n        height: 100%;\n        object-fit: cover;\n    }\n}\n", ""]);
// Exports
exports.locals = {
	"formsapp_bg_image": "_1FSn4P7AaXhu6xs7mAFm9W"
};
module.exports = exports;
