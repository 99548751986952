import * as d3 from "d3";
import {getBgColors} from "../utils/graph";

export const DonutChart = (element, data, totalCount) => {
    const colors = getBgColors(data.length)
    const boxSize = 500;

    d3.select(element).select("svg").remove(); // Remove the old svg
    // Create new svg
    const svg = d3
        .select(element)
        .append("svg")
        .attr("preserveAspectRatio", "xMidYMid meet")
        .attr("height", "100%")
        .attr("width", "100%")
        .attr("viewBox", `0 0 ${boxSize} ${boxSize}`)
        .append("g")
        .attr("transform", `translate(${boxSize / 2}, ${boxSize / 2})`);

    const arcGenerator = d3.arc().padAngle(0.01).innerRadius(80).outerRadius(170);

    const pieGenerator = d3.pie().value((d) => d);

    const arcs = svg.selectAll().data(pieGenerator(data)).enter();
    arcs
        .append("path")
        .attr("d", arcGenerator)
        .style("fill", (d, i) => colors[i % data.length]);
    arcs
        .append("text")
        .attr("text-anchor", "middle")
        .text((d) => d.data ? `${((d.data/totalCount) * 100).toFixed(2)}%` : null)
        .style("fill", "#ffffff")
        .style("font-size", "18px")
        .attr("transform", (d) => {
            const [x, y] = arcGenerator.centroid(d)
            return `translate(${x}, ${y})`
        })
};
