import React, {useState} from 'react'
import FormSettingsHeader from "./FormSettingsHeader"
import SettingsViewContainer from "./SettingsViewContainer";
import {isMobileOnly, isMobile} from "react-device-detect";
import SettingsCollaboratorContainer from "./SettingsCollaboratorContainer";
import SettingsControlContainer from "./SettingsControlContainer";
import SettingsViewContainerMobile from "./SettingsViewContainerMobile";
import {registerGAEvent} from "../../../shared/utils/analytics";
import {translationKeys} from "../../../localizations/translationKeys-localization";
import {t} from "i18next";
import {SettingsMenuItem} from "./BuilderLeftNavigatorHelper";
import SettingsCreateTemplate from "./SettingsCreateTemplate";
import {useSelector} from "react-redux";
import Message from "../../../shared/containers/ConfirmMessage";

export const SETTING_TABS_FOR_NEXT_PREV_CHANGE = {
    VIEW: t(translationKeys.view),
    RESPONSES: t(translationKeys.responses),
    CONTROL: t(translationKeys.control),
    QUESTIONS: t(translationKeys.questions),
    COLLABORATE: t(translationKeys.collaboration)
}

const FormSettingsContainer = props => {
    const currentUserId = useSelector(state => state.userReducer?.userId)
    const builderMessage = useSelector(state => state.builderReducer?.builderMessage)
    const builderToastMessage = useSelector(state => state.builderReducer?.builderToastMessage)
    const encodedUserId = btoa(currentUserId);
    const viewTab = {title: t(translationKeys.view), selected: true}
    const controlTab = {title: t(translationKeys.control), selected: false}
    const collaborationTab = {title: t(translationKeys.collaboration), selected: false}
    const templateCategory = {title: t(translationKeys.templateCategory), selected: false}
    const [currentTab, setCurrentTab] = useState(SETTING_TABS_FOR_NEXT_PREV_CHANGE.VIEW);
    const [containerTabItems, setContainerTabItems] = useState(isMobileOnly ? [viewTab, controlTab, collaborationTab] : [viewTab, controlTab, collaborationTab, templateCategory])
    let startSwipeX, startSwipeY, distX, startTime, elapsedTime, distY;
    const allowedTime = 200;
    const updateTabContainer = clickedItem => {
        registerGAEvent('Form-Builder', 'Settings', `clicked-${clickedItem.title}`);
        if (clickedItem.title === containerTabItems.filter(tabItem => tabItem.selected)[0].title) {
            return
        }

        // Deselect previous Tab Item and Update Current Selection
        setContainerTabItems(oldItems => {
            const newItems = [...oldItems]

            newItems.forEach((tabItem) => {
                    tabItem.selected = tabItem.title === clickedItem.title
                }
            )

            return newItems
        })
        setCurrentTab(clickedItem.title);
    }

    const nextTab = () => {
        let clickedItem
        if (currentTab && currentTab !== SETTING_TABS_FOR_NEXT_PREV_CHANGE.COLLABORATE && isMobileOnly) {
            if (currentTab === SETTING_TABS_FOR_NEXT_PREV_CHANGE.VIEW) {
                clickedItem = containerTabItems[1]
                setCurrentTab(SETTING_TABS_FOR_NEXT_PREV_CHANGE.CONTROL)
            } else if (currentTab === SETTING_TABS_FOR_NEXT_PREV_CHANGE.CONTROL) {
                clickedItem = containerTabItems[2];
                setCurrentTab(SETTING_TABS_FOR_NEXT_PREV_CHANGE.COLLABORATE)
            }
            updateTabContainer(clickedItem)
        } else {
            if (currentTab && currentTab !== SETTING_TABS_FOR_NEXT_PREV_CHANGE.COLLABORATE) {
                if (currentTab === SETTING_TABS_FOR_NEXT_PREV_CHANGE.VIEW) {
                    clickedItem = containerTabItems[1]
                    setCurrentTab(SETTING_TABS_FOR_NEXT_PREV_CHANGE.CONTROL)
                } else if (currentTab === SETTING_TABS_FOR_NEXT_PREV_CHANGE.CONTROL) {
                    clickedItem = containerTabItems[2];
                    setCurrentTab(SETTING_TABS_FOR_NEXT_PREV_CHANGE.COLLABORATE)
                }
                updateTabContainer(clickedItem)
            }
        }
    }

    const prevTab = () => {
        let clickedItem
        if (currentTab && currentTab !== SETTING_TABS_FOR_NEXT_PREV_CHANGE.VIEW) {
            if (currentTab === SETTING_TABS_FOR_NEXT_PREV_CHANGE.COLLABORATE) {
                clickedItem = containerTabItems[1]
                setCurrentTab(SETTING_TABS_FOR_NEXT_PREV_CHANGE.CONTROL)
            } else if (currentTab === SETTING_TABS_FOR_NEXT_PREV_CHANGE.CONTROL) {
                clickedItem = containerTabItems[0];
                setCurrentTab(SETTING_TABS_FOR_NEXT_PREV_CHANGE.VIEW)
            }
            updateTabContainer(clickedItem)
        }
    }

    return (
        <div className={'forq-settings-container'} style={{
            display: props.currentContainerTitle === SettingsMenuItem.getTitle() ? null : 'none'
        }}>
            <div className='settings-column'
                 style={{
                     display: props.currentContainerTitle === SettingsMenuItem.getTitle() ? null : 'none'
                 }}>
                {isMobileOnly ? null :
                    <FormSettingsHeader {...props}
                                        updateTabContainer={updateTabContainer}
                                        nextTab={nextTab}
                                        currentTab={currentTab}
                                        setCurrentTab={setCurrentTab}
                                        containerTabItems={containerTabItems}
                                        setContainerTabItems={setContainerTabItems}
                    />
                }

                <div className='settings-box1'>
                    <div className='mobile-header-tab-row'>
                        <div style={{
                            display: 'flex',
                            justifyContent: 'space-around',
                            height: '35px',
                            width: isMobileOnly ? '100%' : null,
                            padding: isMobileOnly ? '5px 0' : null
                        }}>
                            {
                                containerTabItems.map((tabItem, index) => (
                                    () => {
                                        if (tabItem.title === 'Template Category' && encodedUserId !== 'dGVtcGxhdGVzQHN1cnZleWhlYXJ0LmNvbQ==') {
                                            return null;
                                        } else {
                                            return (
                                                <div
                                                    id={index}
                                                    key={index}
                                                    className={'header-button center'}
                                                    style={{
                                                        borderBottom: tabItem?.selected && isMobileOnly ? '5px solid white' : null,
                                                        minInlineSize: "fit-content",
                                                        width: "-webkit-fill-available",
                                                        padding: "0 20px"
                                                    }}
                                                    onClick={() => updateTabContainer(tabItem)}>
                                        <span
                                            className={tabItem?.selected ? 'mobile-header-tab-row-item-settings mobile-header-tab-row-item-clicked-settings' : 'mobile-header-tab-row-item-settings'}>
                                            {isMobileOnly ? tabItem.title.toUpperCase() : tabItem.title}
                                        </span>
                                                </div>
                                            )
                                        }
                                    }
                                )())
                            }
                        </div>
                    </div>
                    <div onTouchStart={(e) => {
                        if (isMobile) {
                            startSwipeX = e.changedTouches[0].pageX;
                            startSwipeY = e.changedTouches[0].pageY;
                            startTime = new Date().getTime();
                        }
                    }} onTouchEnd={e => {
                        if (isMobile) {
                            distX = e.changedTouches[0].pageX - startSwipeX;
                            distY = e.changedTouches[0].pageY - startSwipeY;
                            elapsedTime = new Date().getTime() - startTime;
                            if (elapsedTime <= allowedTime && distY < 80) {
                                if (distX < -120) {
                                    nextTab()
                                } else if (distX > 120) {
                                    prevTab()
                                }
                            }
                        }
                    }} style={isMobile ? {paddingBottom: '80px', height: '100%', minHeight: "80vh"} : null}>
                        {
                            (() => {
                                if (containerTabItems.filter(tabItem => tabItem.selected)[0].title === viewTab.title) return (
                                    isMobileOnly ?
                                        <SettingsViewContainerMobile key='SettingsViewContainerMobile' {...props}/> :
                                        <SettingsViewContainer key='SettingsViewContainer' {...props}/>
                                )
                                if (containerTabItems.filter(tabItem => tabItem.selected)[0].title === collaborationTab.title) return (
                                    <SettingsCollaboratorContainer key='SettingsCollaboratorContainer' builder={true}/>
                                )
                                if (containerTabItems.filter(tabItem => tabItem.selected)[0].title === controlTab.title) return (
                                    <SettingsControlContainer key='SettingsCollaboratorContainer' {...props}/>
                                )
                                if (containerTabItems.filter(tabItem => tabItem.selected)[0].title === templateCategory.title) return (
                                    <SettingsCreateTemplate key='SettingsCreateTemplate' {...props}/>
                                )
                            })()
                        }
                    </div>
                </div>
            </div>
            {builderToastMessage ? <Message width={'250px'} message={builderMessage}/> : null}
        </div>
    )
}

export default FormSettingsContainer