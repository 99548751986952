import React, {useEffect, useRef, useState} from 'react';
import '../css/column-popup.css';
import MaterialButton, {ButtonTypes} from "../../../shared/components/MaterialButton";
import ColumnQuestionTag from "./ColumnQuestionTag";
import {QUESTION_TYPE} from "../../../shared/utils/constants";
import {useTranslation} from "react-i18next";
import {translationKeys} from "../../../localizations/translationKeys-localization";
import {useSelector} from "react-redux";
import {isMobileOnly} from "react-device-detect";

export default function ColumnPopUpContainer(props) {
    const {t} = useTranslation()
    const {
        initialColumnButtonText,
        setInitialColumnButtonText,
        columnQuestionIndexArray,
        setColumnQuestionIndexArray,
        setShowPopUp
    } = props;
    const selected = useSelector(state => state.languageReducer.selectedLanguage)
    const [questionIndexArray, setQuestionIndexArray] = useState([]);
    const [columnButtonText, setColumnButtonText] = useState(initialColumnButtonText);
    const columnPopUpCardRef = useRef(null)

    useEffect(() => {
        //using initial column array to get back to the previous column value.
        setQuestionIndexArray([...columnQuestionIndexArray]);
        setColumnButtonText(() => {
                if (columnQuestionIndexArray) {
                    if (columnQuestionIndexArray.length === 0 || columnQuestionIndexArray.length === props.questionsLength) {
                        return t(translationKeys.columns_all)
                    } else {
                        return t(translationKeys.columns) + ' (' + columnQuestionIndexArray.length + ')'
                    }
                } else {
                    return t(translationKeys.columns_all)
                }
            }
        )
        props.previouslyOpenedCardCloseFunction?.current && props.previouslyOpenedCardCloseFunction.current !== props.closePopUp ? props.closePreviouslyOpenedCard(props.closePopUp) : null
        document.onmousedown = props.handleOutSideClick
    }, [])

    const handleColumnButtonClick = () => {
        questionIndexArray.sort((a, b) => a - b)
        setShowPopUp(null);
        setColumnQuestionIndexArray(questionIndexArray);
        setInitialColumnButtonText(columnButtonText)
    };

    const handleClearAllClick = () => {
        setQuestionIndexArray([]);
        setColumnButtonText(t(translationKeys.columns_all));
        setColumnQuestionIndexArray([]);
        setInitialColumnButtonText(t(translationKeys.columns_all))
    };

    return (
        <div className='column-questions-container' ref={columnPopUpCardRef} style={!isMobileOnly && (selected === 'ta' || selected === 'ml') ? {width: "500px"} : null }>
            <div className='column-header-container'>
                <span id='select-questions'>{t(translationKeys.select_questions)}</span>
                <MaterialButton data={{title: t(translationKeys.clear_all), buttonType: ButtonTypes.OUTLINED, bg_color: '#424242'}}
                                id={'column-clear-all-button'}
                                handleClick={handleClearAllClick}/>
            </div>
            <div style={{maxHeight: '350px', overflowY: 'auto'}}>
                <QuestionTags questionsData={props.questionsData}
                              setQuestionIndexArray={setQuestionIndexArray}
                              questionIndexArray={questionIndexArray}
                              setColumnButtonText={setColumnButtonText}
                              questionsLength={props.questionsLength}
                />
            </div>
            <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                <MaterialButton data={{title: t(translationKeys.close), buttonType: ButtonTypes.OUTLINED, bg_color: '#1976d2'}}
                                handleClick={props.closePopUp} id={'column-close-button'}/>
                <div style={{margin: '0 15px'}}>
                    <MaterialButton
                        data={{title: columnButtonText, buttonType: ButtonTypes.CONTAINED, bg_color: '#1976d2'}}
                        id={'popup-column-button'}
                        handleClick={handleColumnButtonClick}/>
                </div>
            </div>
        </div>
    )
}

function QuestionTags(props) {
    let questionTags = [];
    for (let i = 0; i < props.questionsData.length; i++) {
        if (props.questionsData[i].type !== QUESTION_TYPE.SECTION.key) {
            questionTags.push(<ColumnQuestionTag key={props.questionsData[i].questionId}
                                                 questionsLength={props.questionsLength}
                                                 questionData={props.questionsData[i]} index={i}
                                                 questionSNo={questionTags.length + 1}
                                                 isChecked={props.questionIndexArray && props.questionIndexArray.length > 0 ?
                                                     (props.questionIndexArray.indexOf(i) !== -1) : false}
                                                 setQuestionIndexArray={props.setQuestionIndexArray}
                                                 questionIndexArray={props.questionIndexArray}
                                                 setColumnButtonText={props.setColumnButtonText}/>)
        }
    }
    return questionTags
}