import React, {useEffect, useRef, useState} from 'react';
import styles from "../../css/text-question.module.css";
import {QUESTION_TYPE} from "../../../shared/utils/constants";
import {
    getTextFocusInBottomBorderColor,
    getTextFocusOutBottomBorderColor,
    getThemeTextColor,
    isDarkTheme
} from "../../utils/theme-color-helper"
import {InputComponent} from "../InputComponent";
import {isValidEmailTextPattern} from "../../../shared/utils/validator";
import {useDispatch, useSelector} from "react-redux";
import {FORM_DATA_ACTION_TYPE} from "../../reducers/formDataReducer";
import {answeredQuestionsCount, updateResponses} from "../../utils/form-response";
import {ACTION_TYPE} from "../../utils/utils";


export const TextQuestion = (props) => {
    const [value, setValue] = useState("")
    const formData = useSelector(state => state.formDataReducer?.formData)
    const themeColor = useSelector(state => state.themeReducer?.themeColor)
    const responses = useSelector(state => state.formDataReducer.formResponses)
    const fileUploadAnsweredCount = useSelector(state => state.answerCountReducer?.fileUploadAnswerCount)
    const characterCounter = useRef(undefined);
    const characterCounterContainer = useRef(undefined);
    const dispatch = useDispatch()

    const textAreaAutoExpand = (element) => {
        let selectedTextArea = element.target;
        selectedTextArea.style.height = "auto";
        selectedTextArea.style.height = selectedTextArea.scrollHeight + "px"
    }

    function handleValidateRequired(element) {
        if (element.target.value.length === 0 || element.target.value.length === 1) {
            validateRequired(element)
        }
    }

    const updateTextLengthCounter = (element) => {
        handleInputValidation(element)
        setValue(element.target.value)
        if (characterCounter.current) {
            characterCounter.current.innerText = element.target.value.length
        }
        handleValidateRequired(element);
    }

    const showCharacterCounter = () => {
        if (characterCounterContainer.current) {
            characterCounterContainer.current.style.visibility = "visible"
        }
    }

    const hideCharacterCounter = () => {
        if (characterCounterContainer.current) {
            characterCounterContainer.current.style.visibility = "hidden"
        }
    }

    const setOnfocusInTextQuestionEvents = (element) => {
        showCharacterCounter();
        element.target.style.borderBottomColor =
            getTextFocusInBottomBorderColor(formData?.theme, themeColor);
        characterCounter.current.innerText = element.target.value.length
        element.target.style.height = element.target.scrollHeight + "px";
    }

    function validateRequired(element) {
        if (props.questionData.is_required) {
            if (element.target.value.trim().length === 0) {
                props.setInfoTag({
                    is_show: true,
                    text: "This question requires an answer",
                    textColor: 'rgb(255, 255, 255)',
                    backgroundColor: 'rgb(244, 67, 54)'
                });
            } else {
                props.setInfoTag({
                    is_show: true,
                    text: "Required",
                    textColor: isDarkTheme(formData?.theme) ? '#fff' : '#212121',
                    backgroundColor: isDarkTheme(formData?.theme) ? '#000' : '#e8e8e8'
                });
            }
        }
    }

    function validateEmailText(emailText) {
        if (emailText.length > 0) {
            if (!isValidEmailTextPattern(emailText)) {
                props.setInfoTag({
                    is_show: true,
                    text: "Invalid email address",
                    textColor: 'rgb(255, 255, 255)',
                    backgroundColor: 'rgb(244, 67, 54)'
                });
            } else {
                props.setInfoTag({
                    is_show: true,
                    text: "Required",
                    textColor: '#212121',
                    backgroundColor: '#e8e8e8'
                });
            }
        }
    }

    const setOnfocusOutTextQuestionEvents = (element) => {
        dispatch({type: FORM_DATA_ACTION_TYPE.SET_FORM_RESPONSES, payload: updateResponses(responses, props.questionData, element.target.value.trim())})
        dispatch({type: ACTION_TYPE.SET_ANSWER_COUNT, payload: answeredQuestionsCount(responses) + fileUploadAnsweredCount})
        hideCharacterCounter();
        validateRequired(element);
        (props.questionData.type === QUESTION_TYPE.EMAIL.key) ? validateEmailText(element.target.value.trim()) : null;
        element.target.style.borderBottomColor =
            getTextFocusOutBottomBorderColor(formData?.theme);
    }

    const getTextCount = (() => {
        if (props.questionData.type === QUESTION_TYPE.LONG_TEXT.key) {
            return 10000;
        }
        return 100;
    })()

    const getInputType = (() => {
        switch (props.questionData.type) {
            case QUESTION_TYPE.EMAIL.key:
                return "email";
            case QUESTION_TYPE.NUMBER.key:
                return "number";
            case QUESTION_TYPE.DATE.key:
                return "date";
            case QUESTION_TYPE.TIME.key:
                return "time";
        }
    })();

    const [defaultData, setDefaultData] = useState("")

    useEffect(() => {
        responses.map((response) => {
            if (response.question_id === props.questionData._id) {
                setValue(response.text)
            }
        })
    }, [responses])

    useEffect(() => {
        if (window.location.pathname === '/form/5c6cdd029f25685f413430ef') {
            const reportId = window.location.search.split('=')[1]
            setDefaultData(window.location.search && props.questionData.title === 'Form Id' ? reportId :
                window.location.search && props.questionData.title === 'Form Link' ? window.location.origin + '/forms/' + reportId : '')
            if (reportId) {
                const updateResponses = [...responses]
                updateResponses[0].text = reportId
                updateResponses[1].text = window.location.origin + '/forms/' + reportId
                dispatch({type: FORM_DATA_ACTION_TYPE.SET_FORM_RESPONSES, payload: updateResponses})
                dispatch({type: ACTION_TYPE.SET_ANSWER_COUNT, payload: answeredQuestionsCount(responses) + fileUploadAnsweredCount})
            }
        } else if (window.location.pathname === '/form/5f323faf7693da10a21c84c6') {
            const reportId = window.location.search.split('=')[1]
            setDefaultData(window.location.search && props.questionData.title === 'Quiz Id' ? reportId : '')
            if (reportId) {
                const updateResponses = [...responses]
                updateResponses[0].text = reportId
                dispatch({type: FORM_DATA_ACTION_TYPE.SET_FORM_RESPONSES, payload: updateResponses})
                dispatch({type: ACTION_TYPE.SET_ANSWER_COUNT, payload: answeredQuestionsCount(responses) + fileUploadAnsweredCount})
            }
        }
    }, [])

        const textAreaTypeQuestion = (
        <>
            <textarea onKeyDown={textAreaAutoExpand}
                      className={styles.answer_text_field}
                      field-type={props.questionData.type}
                      name={props.questionData._id}
                      id={props.questionData._id}
                      maxLength={getTextCount}
                      defaultValue={defaultData ? defaultData : ''}
                      value={value}
                      onInput={updateTextLengthCounter}
                      onFocus={setOnfocusInTextQuestionEvents}/*"moveNextQuestionUI(this)*/
                      onBlur={setOnfocusOutTextQuestionEvents}
                      style={{
                          borderBottomColor: getTextFocusOutBottomBorderColor(formData?.theme),
                          fontSize: "18px",
                          padding: "2.5px 0px 0px 0px",
                          color: getThemeTextColor(formData?.theme)
                      }} rows="1"/>
            <div ref={characterCounterContainer}
                 style={{
                     visibility: "hidden",
                     cssFloat: "right",
                     fontSize: "12px",
                     color: getThemeTextColor(formData?.theme)
                 }}>
                <span ref={characterCounter}>0</span><span>/</span><span
                id="total-count">{getTextCount}</span>
            </div>
        </>
    );


    function handleInputValidation(element) {
        handleValidateRequired(element);
        (props.questionData.type === QUESTION_TYPE.EMAIL.key) ? validateEmailText(element.target.value) : null;
    }

    const textQuestion = (() => {
        if (props.questionData.type === QUESTION_TYPE.SHORT_TEXT.key
            || props.questionData.type === QUESTION_TYPE.LONG_TEXT.key) {
            return textAreaTypeQuestion;
        } else {
            return <InputComponent
                id={props.questionData._id}
                theme={formData?.theme}
                maxLength={getTextCount}
                defaultValue={defaultData}
                value={value}
                type={getInputType}
                onFocus={setOnfocusInTextQuestionEvents}
                onBlur={setOnfocusOutTextQuestionEvents}
                onInput={updateTextLengthCounter}
                // isClearText={props.previewType}
                customStyle={{marginBottom: '15px'}}
                name={props.questionData._id}
            />;
        }
    })();

    return (
        <div>
            {textQuestion}
        </div>
    );
}
