import React, {useState} from 'react'
import {ATTACHMENT_TYPE, getQuestionCategory} from "../../../shared/utils/constants";
import TextAnswerCard from "./TextAnswerCard";
import ResultsContainer from "./ResultsContainer";
import QuestionDetailedView from "./QuestionDetailedView";
import MobileQuestionDetailedView from "./MobileQuestionDetailedView";
import {QUESTION_CATEGORY, QUESTION_TYPE} from "../../../shared/utils/constants";
import QuestionTitleBar from "./QuestionTitleBar";
import {isMobileOnly} from "react-device-detect";
import MaterialButton, {ButtonTypes} from "../../../shared/components/MaterialButton";
import {translationKeys} from "../../../localizations/translationKeys-localization";
import {t} from "i18next";
import GridResultsContainer from "./GridResultsContainer";
import {RESPONSES_ACTION_TYPE} from "../../reducers/responsesReducer";
import {useDispatch, useSelector} from "react-redux";
import styles from "../../../form/css/question-attachment.module.css";

export const CHART_TYPE = {
    RESULT_CARD: 0,
    PIE_CHART: 1,
    BAR_CHART: 2,
    TABULAR_CHART: 3,
    GROUPED_BAR_CHART: 4,
    STACKED_BAR_CHART: 5
}

const SummaryQuestionCard = (props) => {
    const [chartType, setChartType] = useState(() => {
        // This is the first Chart displayed for the QUESTION_TYPE
        if (props.index === -1) {
            return CHART_TYPE.RESULT_CARD
        }
        if (props.summaryDataSet.questionsData[props.index].type === QUESTION_TYPE.CHECKBOX_GRID.key || props.summaryDataSet.questionsData[props.index].type === QUESTION_TYPE.MULTIPLE_CHOICE_GRID.key) {
            return CHART_TYPE.TABULAR_CHART
        }

        return CHART_TYPE.RESULT_CARD
    });
    const [showDetailedView, setShowDetailedView] = useState(false);
    const showQuestionScreen = useSelector(state => state.responsesReducer?.showQuestionScreen)
    const dispatch = useDispatch()

    const showDetailedPopUp = (boolean, chartType) => {
        if (boolean) {
            setShowDetailedView(boolean);
            setChartType(chartType);
        } else {
            setShowDetailedView(boolean);
        }
    }

    const currentQuestionSummaryData = props.index === -1 ? false : props.summaryDataSet.questionsData[props.index];
    let skippedAnswerCount = 0;
    let responseCardsToShow = undefined;
    let textAnswers = [];
    let isChoiceType = false;
    let isGridType = props.index === -1 ? false : (currentQuestionSummaryData.type === QUESTION_TYPE.MULTIPLE_CHOICE_GRID.key || currentQuestionSummaryData.type === QUESTION_TYPE.CHECKBOX_GRID.key)
    let answeredCount;

    if (getQuestionCategory(currentQuestionSummaryData.type) === QUESTION_CATEGORY.CHOICE_TYPE && !isGridType) {
        // getting choice type cards
        isChoiceType = true;
        answeredCount = currentQuestionSummaryData.choiceTextAnswers.length;
        skippedAnswerCount = currentQuestionSummaryData.choiceTextAnswers.filter((answer) => answer.length === 0).length;
        responseCardsToShow = <ResultsContainer questionSummaryData={currentQuestionSummaryData}
                                                skippedAnswerCount={skippedAnswerCount}
                                                answeredCount={answeredCount}/>
    } else if (isGridType) {
        props.respondentsDataRef.current.map((response) => {
            if (response.responses[props.index]?.checkbox_grid_choices?.length === 0 || response.responses[props.index]?.grid_choices?.length === 0) {
                skippedAnswerCount = skippedAnswerCount + 1
            }
        })
        isChoiceType = true;
        answeredCount = currentQuestionSummaryData.responseCount;
        responseCardsToShow = <GridResultsContainer questionSummaryData={currentQuestionSummaryData}
                                                    answeredCount={currentQuestionSummaryData.responseCount}
                                                    showAllValues={false}
        />
    } else if (props.index === -1 || (getQuestionCategory(currentQuestionSummaryData.type) === QUESTION_CATEGORY.TEXT_TYPE || currentQuestionSummaryData.type === QUESTION_TYPE.FILE_UPLOAD.key)) {
        // getting text and FILE_UPLOAD type answer cards
        isChoiceType = false;
        if (props.index === -1) {
            if (props.formFiller) {
                responseCardsToShow = props.respondentsDataRef.current.map((response, i) => {
                    return <TextAnswerCard key={i} responseText={response.responder_email_id}
                                           index={i}/>
                }).reverse().slice(0, 5);
            } else {
                responseCardsToShow = props.respondentsDataRef.current.map((response, i) => {
                    return <TextAnswerCard key={i} responseText={response.responder_email_id}
                                           index={i}/>
                }).slice(0, 5);
            }
        } else {
            for (let j = 0; j < currentQuestionSummaryData.responseTextAnswers.length; j++) {
                let textAnswer = currentQuestionSummaryData.responseTextAnswers[j];
                if (textAnswer.length === 0) {
                    skippedAnswerCount++
                }
                textAnswers.push({text: textAnswer, type: currentQuestionSummaryData.type});
            }
            responseCardsToShow = textAnswers.map((textResponse, i) => {
                return <TextAnswerCard key={i} responseText={textResponse.text} responseType={textResponse.type}
                                       index={i}/>
            }).reverse().slice(0, 5);
        }
    }

    const isShowChartIcons = () => {
        if (isChoiceType) {
            for (let i = 0; i < currentQuestionSummaryData.choiceTextAnswers.length; i++) {
                if (currentQuestionSummaryData.choiceTextAnswers[i].length > 1) {
                    return true
                }
            }
            return !!currentQuestionSummaryData?.responseAnalyzeData?.graphData?.reduce((a, b) => a + b, 0);
        }
    }

    const showChartIcons = isShowChartIcons();
    const popUpStyle = {
        backgroundColor: 'rgba(0, 0, 0, 0.3)',
        height: '100%',
        width: '100%',
        zIndex: isMobileOnly ? '12' : '7',
        top: '0',
        left: '0',
        position: 'fixed',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: '0',
        padding: '0'
    }

    const openAttachmentLink = () => {
        if (props.attachment.url) {
            window.open(props.attachment.url)
        }
    }

    const ViewAllButtonText = () => {
        if (isGridType) {
            return (
                t(translationKeys.view_all) + ' (' + (currentQuestionSummaryData.responseAnalyzeData.columnsIdList.length + "X" + currentQuestionSummaryData.responseAnalyzeData.rowsIdList.length) + ')'
            )
        } else {
            return (t(translationKeys.view_all) + ' (' + (currentQuestionSummaryData.responseAnalyzeData.rowsIdList.length
                + ((currentQuestionSummaryData.responseTextAnswers && currentQuestionSummaryData.responseTextAnswers.length > 0) ? 1 : 0) + ')'))
        }
    }

    return (
        <div className='response-question-card' style={{maxWidth: '1050px'}} onClick={() => {
            if (isMobileOnly && !showQuestionScreen && !props.formFiller) {
                location.hash = '#view_all';
                dispatch({
                    type: RESPONSES_ACTION_TYPE.SET_QUESTION_TITLE,
                    payload: props.index === -1 ? "Email Addresses" : props.questionSNo + 1 + '. ' + currentQuestionSummaryData.questionTitle
                })
                dispatch({type: RESPONSES_ACTION_TYPE.SET_CURRENT_QUESTION_INDEX, payload: props.index})
                dispatch({type: RESPONSES_ACTION_TYPE.SET_SHOW_QUESTION_SCREEN, payload: true})
                dispatch({type: RESPONSES_ACTION_TYPE.SET_SHOW_SUMMARY, payload: false})
            }
        }}>
            <QuestionTitleBar isChoiceType={isChoiceType} questionSNo={props.questionSNo}
                              questionTitle={currentQuestionSummaryData.questionTitle}
                              isEmail={props.isEmail}
                              index={props.index}
                              showChartIcons={showChartIcons}
                              chartType={chartType}
                              handleClick={(chartType) => showDetailedPopUp(true, chartType)}
                              isGridType={isGridType}
            />
            {props.attachment ? <div style={{
                paddingBottom: "15px",
                borderBottom: "solid 1px #9B9B9B"
            }}>
                {props.attachment && props.attachment.file_type === ATTACHMENT_TYPE.IMAGE.key ? <div style={{
                    justifyContent: "center",
                    display: 'flex',
                    height: isMobileOnly ? null : '300px'
                }}>
                    <img src={props.attachment?.image_url}
                         alt={'image_Url'}
                         style={{
                             display: 'flex',
                             width: '100%',
                             objectFit: 'contain'
                         }}/>
                </div> : null}
                {props.attachment && props.attachment.file_type !== ATTACHMENT_TYPE.IMAGE.key ?
                    <div className={styles.question_attachment_web_container} style={{
                        display: 'flex',
                        cursor: "pointer",
                        backgroundColor: '#f5f5f5'
                    }} onClick={openAttachmentLink}>
                        <img style={{
                            display: 'flex',
                            width: '30%'
                        }}
                             alt={'image'}
                             onError={e => e.target.src = '/images/link.svg'}
                             src={props.attachment?.image_url ? props.attachment?.image_url : '/images/link.svg'}/>
                        <div className={styles.question_attachment_text_container}
                             style={{
                                 display: 'flex',
                                 width: isMobileOnly ? '70%' : '90%',
                                 overflow: "hidden"
                             }}>
                            <p className={styles.question_attachment_title}
                               style={{
                                   color: '#212121'
                               }}>
                                {props.attachment?.title}
                            </p>
                            <p className={styles.question_attachment_description}>
                                {props.attachment?.description}
                            </p>
                            <p id={props.id ? props.id : null} className={styles.question_attachment_url}
                               style={{
                                   color: '#0000ee'
                               }}>
                                {props.attachment?.url}
                            </p>
                        </div>
                    </div> : null}
            </div> : null}
            {responseCardsToShow}
            {(!isGridType && isChoiceType || textAnswers.length) ?
                <div className='response-card-bottom-container'>
                    <div id='response-card-bottom-text' style={{width: '50%'}}>
                        {skippedAnswerCount > 0 ?
                            <p className='skipped-count-text'>{t(translationKeys.skipped) + ': ' + skippedAnswerCount}</p> : null}
                    </div>
                    {(textAnswers.length > 5 || (isChoiceType && currentQuestionSummaryData.responseAnalyzeData.choiceIdList.length > 4)) ?
                        <div style={{width: '50%', display: 'flex', justifyContent: 'flex-end'}}>
                            <MaterialButton data={{
                                buttonType: ButtonTypes.OUTLINED, bg_color: '#1976d2',
                                fontSize: isMobileOnly ? '12px' : '18px',
                                customButtonContainerStyle: {
                                    margin: '5px',
                                    padding: isMobileOnly ? '0 6px' : '0 12px',
                                    fontSize: '14px',
                                    textAlign: 'center',
                                    textTransform: 'uppercase'
                                },
                                title: isChoiceType ? t(translationKeys.view_all) + ' (' + (currentQuestionSummaryData.responseAnalyzeData.choiceIdList.length
                                    + ((currentQuestionSummaryData.responseTextAnswers && currentQuestionSummaryData.responseTextAnswers.length > 0) ? 1 : 0) + ')') : t(translationKeys.view_details)
                            }}
                                            handleClick={() => {
                                                if (isMobileOnly && props.index === -1) {

                                                } else if (isMobileOnly && !props.formFiller) {
                                                    location.hash = '#view_all';
                                                    dispatch({
                                                        type: RESPONSES_ACTION_TYPE.SET_QUESTION_TITLE,
                                                        payload: props.questionSNo + 1 + '. ' + currentQuestionSummaryData.questionTitle
                                                    })
                                                    dispatch({
                                                        type: RESPONSES_ACTION_TYPE.SET_CURRENT_QUESTION_INDEX,
                                                        payload: props.index
                                                    })
                                                    dispatch({
                                                        type: RESPONSES_ACTION_TYPE.SET_SHOW_QUESTION_SCREEN,
                                                        payload: true
                                                    })
                                                    dispatch({
                                                        type: RESPONSES_ACTION_TYPE.SET_SHOW_SUMMARY,
                                                        payload: false
                                                    })
                                                } else {
                                                    showDetailedPopUp(true, chartType);
                                                }
                                            }}/>
                        </div> : null}
                </div> : null}
            {(isGridType) ?
                <div className='response-card-bottom-container'>
                    <div id='response-card-bottom-text' style={{width: '50%'}}>
                        {skippedAnswerCount > 0 ?
                            <p className='skipped-count-text'>{t(translationKeys.skipped) + ': ' + skippedAnswerCount}</p> : null}
                    </div>
                    {((isGridType && currentQuestionSummaryData.responseAnalyzeData.columnsIdList.length > 4) || (isGridType && currentQuestionSummaryData.responseAnalyzeData.rowsIdList.length > 3)) ?
                        <div style={{width: '50%', display: 'flex', justifyContent: 'flex-end'}}>
                            <MaterialButton data={{
                                buttonType: ButtonTypes.OUTLINED, bg_color: '#1976d2',
                                fontSize: isMobileOnly ? '12px' : '18px',
                                customButtonContainerStyle: {
                                    margin: '5px',
                                    padding: isMobileOnly ? '0 6px' : '0 12px',
                                    fontSize: '14px',
                                    textAlign: 'center',
                                    textTransform: 'uppercase'
                                },
                                title: ViewAllButtonText()
                            }}
                                            handleClick={() => {
                                                showDetailedPopUp(true, chartType);
                                            }}/>
                        </div> : null}
                </div> : null}
            {showDetailedView ?
                <div style={popUpStyle}>
                    {
                        isMobileOnly ?
                            <MobileQuestionDetailedView questionSNo={props.questionSNo} answeredCount={answeredCount}
                                                        questionSummaryData={currentQuestionSummaryData}
                                                        isChoiceType={isChoiceType}
                                                        isGridType={isGridType}
                                                        respondentsDataRef={props.respondentsDataRef}
                                                        respondersInfo={props.summaryDataSet.responsesInfo}
                                                        chartType={chartType}
                                                        skippedAnswerCount={skippedAnswerCount}
                                                        formFiller={props.formFiller}
                                                        setChartType={props.setChartType}
                                                        showChartIcons={showChartIcons}
                                                        showDetailedPopUp={showDetailedPopUp}
                                                        formTitle={props.formTitle}
                                                        changeResponseView={props.changeResponseView}/>
                            :
                            <QuestionDetailedView questionSNo={props.questionSNo} answeredCount={answeredCount}
                                                  questionSummaryData={currentQuestionSummaryData}
                                                  isChoiceType={isChoiceType}
                                                  isGridType={isGridType}
                                                  respondentsDataRef={props.respondentsDataRef}
                                                  respondersInfo={props.summaryDataSet.responsesInfo}
                                                  chartType={chartType}
                                                  skippedAnswerCount={skippedAnswerCount}
                                                  formFiller={props.formFiller}
                                                  setChartType={props.setChartType}
                                                  showChartIcons={showChartIcons}
                                                  showDetailedPopUp={showDetailedPopUp}
                                                  formTitle={props.formTitle}
                                                  changeResponseView={props.changeResponseView}/>
                    }
                </div> : null}

        </div>
    );
}

export default SummaryQuestionCard
