/**
 *
 * @param responseId
 * @param formResponses = res object which is got from the server.
 * @param originalResponses = original respondents of the form/quiz
 */

const addResponse = (responseId, formResponses, originalResponses) => {
    formResponses.formResponses.respondents = originalResponses;

    formResponses.formData.response_count = (formResponses.formData.response_count + 1)

    return formResponses;
}

export default addResponse