// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3vPGtRbLrtesfARD3bhzV7 {\n    width: 65%;\n    display: flex;\n    overflow-x: auto;\n    justify-content: space-between;\n}\n\n::-webkit-scrollbar {\n    height: 4px;\n}\n\n.ini634ucUlQVxmPsGf_32 {\n    width: 35%;\n    display: grid;\n    font-weight: bold;\n    margin: 70px 0 4px;\n}\n._1nd0F02m94CTv9KZDZiCLj {\n    margin: 2px 0;\n    padding: 10px 5%;\n    border-radius: 5px 0 0 5px;\n    background: #f6f6f6;\n}\n._1zJSD_Xez0-Blw-bSSSy5d {\n    margin: 2px 0;\n    padding: 0 15%;\n    border-radius: 0 5px 5px 0;\n    background: #f6f6f6;\n}\n._1XItjHxJL6iCXGakmYr4Pn {\n    display: flex;\n    text-align: center;\n    flex-direction: column;\n    width: -webkit-fill-available;\n    justify-content: space-between;\n}\n._3YxMBQ0QOVGQfVNbzkuh8G {\n    background: #f6f6f6;\n}\n._2Bg2TpyUnMaktBUp121fgZ {\n    width: 33%;\n    padding: 10px 15px;\n    word-break: break-word;\n}\n", ""]);
// Exports
exports.locals = {
	"grid_columns": "_3vPGtRbLrtesfARD3bhzV7",
	"grid_rows_title": "ini634ucUlQVxmPsGf_32",
	"grid_row": "_1nd0F02m94CTv9KZDZiCLj",
	"grid_column_row": "_1zJSD_Xez0-Blw-bSSSy5d",
	"linear_scale": "_1XItjHxJL6iCXGakmYr4Pn",
	"linear_scale_row": "_3YxMBQ0QOVGQfVNbzkuh8G",
	"linear_scale_label": "_2Bg2TpyUnMaktBUp121fgZ"
};
module.exports = exports;
