import React from 'react'
import {isMobileOnly} from "react-device-detect";
import {translationKeys} from "../../../localizations/translationKeys-localization";
import {t} from "i18next";
import {useSelector} from "react-redux";

const PopupMenuContainer = (props) => {
    const selectedLanguage = useSelector(state => state.languageReducer.selectedLanguage)
    return (
        <div ref={props.popUpContainer ? props.popUpContainer : null} style={{
            visibility: props.display ? 'visible' : 'hidden',
            top: isMobileOnly ? null : props.top ? props.top : null,
            right: isMobileOnly ? props.right : null,
            left: isMobileOnly ? null : props.left ? `${props.left}px` : '',
            width: props.width ? props.width : isMobileOnly ? '80%' : null,
            height: props.height ? props.height : "auto",
            maxHeight: props.height ? props.height : isMobileOnly ? '80%' : null,
            margin: props.margin ? props.margin : null,
            transform: props.addTransForm ? 'translateX(225px)' : null,
            overflow: 'auto',
            position: isMobileOnly ? (selectedLanguage === 'fa' || selectedLanguage === 'ar') ? "relative" : "unset" : null
        }}
             className="popup-menu-container"
        >
            <div style={{
                display: 'flex',
                flexFlow: 'column',
                width: '100%',
                height: '100%',
                overflow: isMobileOnly ? 'auto' : 'unset',
            }}>
                {
                    props.Groups.map((group, groupIndex) => (
                        (() => {
                            return (
                                group.map((menuItem, index) => (
                                        /* The condition logic below puts a bottom border underneath each Group, except for the last one. */
                                        <div key={menuItem.getKey()} data-attribute={`${groupIndex}-${index}`}
                                             onClick={props.menuItemClickHandler}
                                             style={groupIndex === 0 ?
                                                 {
                                                     borderTopLeftRadius: index === 0 ? '8px' : '0px',
                                                     borderTopRightRadius: index === 0 ? '8px' : '0px',
                                                     borderBottom: index === 0 && (group.length - 1 > 0) ? '0px' : '1px solid #eeeeee'
                                                 } :
                                                 ((groupIndex === props.Groups.length - 1) && (index === group.length - 1)) ?
                                                     {
                                                         borderBottomLeftRadius: '8px',
                                                         borderBottomRightRadius: '8px',
                                                         borderBottom: '0px'
                                                     } :
                                                     index < (group.length - 1) ?
                                                         {borderBottom: '0px'} : {}
                                             }
                                             className="form-control-drop-down-card"
                                        >
                                            <img className="drop-down-card-icon"
                                                 src={menuItem.getIconInfo().getImgSrcPath()}
                                                 alt={menuItem.getIconInfo().getAltText()}/>
                                            <p style={{
                                                width: menuItem.getDisplayNewImage && menuItem.getDisplayNewImage() ? "auto" : null,
                                                textAlign: (selectedLanguage === 'fa' || selectedLanguage === 'ar') ? "right" : null
                                            }}>
                                                {menuItem.getText()}
                                            </p>
                                            {menuItem.getDisplayNewImage && menuItem.getDisplayNewImage() ?
                                                /*<img className="drop-down-new-image"
                                                     src={newSvg}
                                                     alt="New"/>*/
                                                <span className='menu-btn-new-icon'
                                                      style={{textTransform: 'uppercase'}}>{t(translationKeys.new)}</span>
                                                : null}
                                        </div>
                                    )
                                )
                            )
                        })())
                    )
                }
            </div>
        </div>
    )
}

export default PopupMenuContainer