
const updateMarkInFormResponsesData = (responseId, questionId, mark, formResponses) => {
    for (let i = 0; i < formResponses.formResponses.respondents.length; i++) {
        if (responseId === formResponses.formResponses.respondents[i]._id) {
            for (let j = 0; j < formResponses.formResponses.respondents[i].responses.length; j++) {
                if (questionId === formResponses.formResponses.respondents[i].responses[j].question_id) {
                    formResponses.formResponses.respondents[i].responses[j].marks = mark;
                    break;
                }
            }
            break;
        }
    }
    return formResponses;
}

export default updateMarkInFormResponsesData;
