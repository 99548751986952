import React, {useEffect, useState} from 'react'
import {translationKeys} from "../../../localizations/translationKeys-localization";
import {useDispatch, useSelector} from "react-redux";
import {t} from "i18next";
import add_image from "../../../../public/images/icons/add-white-24dp.svg";
import edit_pen from "../../../../public/images/edit_pen_icon.svg";
import check_circle_grey from "../../../../public/images/check_circle_grey.svg";
import check_circle_green from "../../../../public/images/check_circle_green.svg";
import ActionButtonContainer from "../../../shared/containers/ActionButtonContainer";
import PopUpContainer from "../../../shared/components/PopUpContainer";
import AddThemeCategoryPopup from "../../../shared/components/AddThemeCategoryPopup";
import {BUILDER_ACTION_TYPE} from "../../reducers/builderReducer";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import {Checkbox} from "@mui/material";
import {APP_ACTION_TYPE} from "../../reducers/appReducer";
import {MEDIA_BASE_URL} from "../utils/aws";
import {uploadTemplateCategories} from "../utils/aws";

const SettingsCreateTemplate = () => {
    const [showPopUp, setShowPopUp] = useState(false)
    const [isAddButtonClicked, setIsAddButtonClicked] = useState(false)
    const formData = useSelector(state => state.appReducer.formData)
    const [checkedItem, setCheckedItem] = useState(null);
    const [hoveredIndex, setHoveredIndex] = useState(null);
    const [editCategoryName, setEditCategoryName] = useState(null);

    const dispatch = useDispatch()

    useEffect(() => {
        const fetchJsonFileFromS3 = async () => {
            const url = `${MEDIA_BASE_URL}/public/templates_categories.json`;

            try {
                const response = await fetch(url);
                if (!response.ok) {
                    throw new Error('Failed to fetch JSON file');
                }
                const data = await response.json();
                dispatch({
                    type: BUILDER_ACTION_TYPE.SET_TEMPLATE_CATEGORIES_LIST,
                    payload: data
                })
            } catch (error) {
                console.error('Error fetching JSON file:', error);
            }
        };

        fetchJsonFileFromS3();
    }, []);

    const templateCategories = useSelector(state => state.builderReducer.templateCategories);

    useEffect(() => {
        //update the categories in file data
        uploadTemplateCategories(templateCategories, 'application/json', ()=>{},()=>{})
    }, [templateCategories]);

    useEffect(() => {
        setCheckedItem(formData.template_category);
    },[formData.template_category])

    const handleAddCategoryClick = () => {
        setIsAddButtonClicked(true)
        setShowPopUp(true)
    }

    const handleToggle = (value) => () => {
        if (checkedItem === value) {
            // Uncheck the item if it's already checked
            setCheckedItem(null);
        } else {
            // Check the new item and uncheck the previous one
            setCheckedItem(value);
            //selected item update the form data structure
            let updatedData = {...formData}
            updatedData.template_category = value
            dispatch({type: APP_ACTION_TYPE.SET_FORM_DATA, payload: updatedData})
        }
    };

    const editClicked = (e,index,item) => {
        e.stopPropagation();
        setEditCategoryName(item)
        setShowPopUp(true)
        setIsAddButtonClicked(false)
    }

    return (
        <div className={'setting-create-template-container'}>
            <ActionButtonContainer buttonText={t(translationKeys.add_category)}
                                   fontSize={'15px'}
                                   buttonWidth={'177px'}
                                   height={'45px'}
                                   handleClick={handleAddCategoryClick}
                                   base64ImageData={add_image}/>
            <h1 className={'setting-create-template--heading'}>
                Select From Category
            </h1>
            <div style={{maxWidth: '45%'}}>
                <List component="nav">
                    {templateCategories?.map((item, index) => {
                        const labelId = `checkbox-list-label-${index}`;
                        return (
                            <ListItem button key={index}
                                      onClick={handleToggle(item)}
                                      onMouseEnter={() => setHoveredIndex(index)}
                                      onMouseLeave={() => setHoveredIndex(null)}
                                      style={{position: 'relative', marginLeft: '32px', borderBottom: '1px solid #BDBDBD'}}>
                                <Checkbox
                                    edge="start"
                                    checked={checkedItem === item}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{'aria-labelledby': labelId}}
                                    style={{display: 'none'}}
                                />
                                <ListItemText primary={item}/>
                                {hoveredIndex === index && (
                                    <img src={edit_pen} alt={'edit icon'} style={{cursor: "pointer"}} onClick={(e) => editClicked(e,index,item)}/>
                                )}
                                {
                                    checkedItem === item ?
                                        <img src={check_circle_green} alt={'checked icon'} style={{cursor: "pointer", marginLeft: '17px'}} />
                                        : <img src={check_circle_grey} alt={'uncheck icon'} style={{cursor: "pointer", marginLeft: '17px'}} />
                                }
                            </ListItem>
                        )})}
                </List>
            </div>
            {
                showPopUp && <PopUpContainer children={<AddThemeCategoryPopup setShowPopUp={setShowPopUp} isAddContainer={isAddButtonClicked} editCategoryName={editCategoryName} setEditCategoryName={setEditCategoryName} currentIndex={hoveredIndex}/>}/>
            }
        </div>
    )
}

export default SettingsCreateTemplate