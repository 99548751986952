import React, {useEffect, useState} from "react"
import timer from "../../../public/images/timer.svg"
import WelcomePageContainer from "./WelcomePageContainer";
import {useSelector} from "react-redux";
import {isMobileOnly} from "react-device-detect";

const ForqTimer = (props) => {

    const formData = useSelector(state => state.formDataReducer.formData)
    const timeRemaining = formData?.time_remaining
    const [timeData, setTimeData] = useState({days: 0, hours: 0, minutes: 0, seconds: 0});

    useEffect(() => {
        if (formData?.time_remaining) {
            let timeDifference = timeRemaining
            const timeInterval = setInterval(function () {
                const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
                const hours = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
                const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

                setTimeData({
                    days: days,
                    hours: hours,
                    minutes: minutes,
                    seconds: seconds
                })
                timeDifference = timeDifference - 1000

                if (timeDifference < 0) {
                    clearInterval(timeInterval);
                    window.location.reload()
                }
            }, 1000);
        }
    }, [])

    const popUpStyle = {
        top: '0',
        left: '0',
        margin: '0',
        zIndex: '11',
        padding: '0',
        width: '100%',
        height: '100%',
        display: 'flex',
        color: "#FFFFFF",
        cursor: 'default',
        position: 'fixed',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.7)'
    }
    const dateStyle = {
        fontSize: isMobileOnly ? "40px" : "50px",
        margin: 0
    }

    return (
        <>
            <WelcomePageContainer setSelectedPreviewScreen={props.setSelectedPreviewScreen}
                                  forqTimer={true}
                                  previewType={props.previewType}/>
            <div style={popUpStyle}>
                <img src={timer} alt={"timer"}/>
                <h1 style={{fontSize: isMobileOnly ? "30px" : null}}>{props.isQuiz ? "QUIZ" : "FORM"} will be available in</h1>
                <div style={{display: "flex", justifyContent: "center"}}>
                    <div>
                        <h1 style={dateStyle}>{timeData.days < 10 ? "0" + timeData.days : timeData.days} :&nbsp;</h1>
                        <p style={{margin: "0", marginLeft: "10px"}}>DAYS</p>
                    </div>
                    <div>
                        <h1 style={dateStyle}>{timeData.hours < 10 ? "0" + timeData.hours : timeData.hours} :&nbsp;</h1>
                        <p style={{margin: "0", marginLeft: "5px"}}>HOURS</p>
                    </div>
                    <div>
                        <h1 style={dateStyle}>{timeData.minutes < 10 ? "0" + timeData.minutes : timeData.minutes} :&nbsp;</h1>
                        <p style={{margin: "0"}}>MINUTES</p>
                    </div>
                    <div>
                        <h1 style={dateStyle}>{timeData.seconds < 10 ? "0" + timeData.seconds : timeData.seconds}</h1>
                        <p style={{margin: "0"}}>SECONDS</p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ForqTimer
