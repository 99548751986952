import React from 'react';
import {FORM_SCREEN, isMinWidth600, THEME_TYPE} from "../utils/utils";
import styles from '../css/welcome-screen.module.css'
import MaterialButton from "../../shared/components/MaterialButton";
import {PREVIEW_TYPE} from "../../shared/utils/constants";
import {estimatedTime, questionCount} from "../../shared/utils/helper";
import {useDispatch, useSelector} from "react-redux";
import {SCREENS_ACTION_TYPE} from "../reducers/screensReducer";
import {THEME_ACTION_TYPE} from "../reducers/themeReducer";
import {FORM_DATA_ACTION_TYPE} from "../reducers/formDataReducer";
import FormTheme from "./FormTheme";
import {AWS_FOLDER_NAMES, MEDIA_BASE_URL} from "../../app/builder/utils/aws";

const welcomeTitleContainerPCStyle = {
    "borderRadius": "10px",
    "maxHeight": "70vh",
    "overflow": "auto",
    "maxWidth": "85%",
    "minWidth": "300px",
    'textAlign': 'center'
}

const welcomeDescriptionContainerPCStyle = {
    'maxWidth': 'none',
    "maxHeight": "130px",
    "marginBottom": "30px",
    "fontSize": "22px",
    "overflow": "auto"
}

export default function WelcomePageContainer(props) {
    const formData = useSelector(state => state.formDataReducer.formData)
    const themeColor = useSelector(state => state.themeReducer?.themeColor)
    const fillerSummary = useSelector(state => state.screensReducer.fillerSummary)
    const dispatch = useDispatch()

    function isPCView() {
        return (!props.previewType || props.previewType === PREVIEW_TYPE.DESKTOP) && isMinWidth600()
    }

    let containerBackgroundColor;
    let textColor = '#fff';
    if (formData?.theme === THEME_TYPE.DARK ||
        formData?.theme === THEME_TYPE.CLASSIC) {
        containerBackgroundColor = 'transparent';
    } else {
        containerBackgroundColor = 'rgba(0, 0, 0, 0.3)';
    }
    if (formData?.theme === THEME_TYPE.CLASSIC) {
        textColor = '#212121';
    }

    function getStartButtonBackgroundColor() {
        return formData?.theme === THEME_TYPE.CLASSIC
            ? "#FFFFFF" : themeColor?.primaryColor
    }

    function getLogoUrl(userId) {
        return `${MEDIA_BASE_URL}/${AWS_FOLDER_NAMES.LOGOS_FOLDER}/${userId}_logo.png?r=${Math.random()}`;
    }

    return (
        <div id={'welcome-screen-container'} className={styles.welcome_screen_container}
             style={props.formThemeImageSrc ? {
                 zIndex: "1",
                 filter: props.forqTimer ? 'blur(5px)' : null,
                 backgroundColor: formData.theme === THEME_TYPE.DARK && !fillerSummary ? '#000' : formData.theme === THEME_TYPE.CLASSIC ? '#fff' : fillerSummary ? '#f5f5f5' : "transparent",
             } : null}>
            {props.formThemeImageSrc ? <FormTheme formThemeImageSrc={props.formThemeImageSrc}/> : null}
            {formData?.setting?.is_show_logo ? <img style={{maxWidth: "150px", maxHeight: "150px"}}
                                                    src={getLogoUrl(formData?.user_id)}
                                                    alt={"logo"}/> : null}
            <div id={'welcome-title-container'} className={styles.welcome_title_container} style={{
                ...{backgroundColor: containerBackgroundColor},
                ...isPCView() ? welcomeTitleContainerPCStyle : null
            }}>
                <h3 id={'welcome-screen-container-form-title'} style={isPCView() ? {
                    fontSize: "40px",
                    wordWrap: props.previewType ? "break-word" : null,
                    overflow: props.previewType ? "auto" : null
                } : {
                    margin: "5px 0",
                    overflow: "auto",
                    fontSize: "30px",
                    minHeight: "50px",
                    wordWrap: "break-word"
                }}>
                    <b style={{color: textColor}}>{formData?.welcome_screen.title}</b>
                </h3>
                {formData?.welcome_screen?.description ?
                    <div className={styles.welcome_description_container}
                         style={isPCView() ? welcomeDescriptionContainerPCStyle : null}>
                        <p id={'welcome-screen-container-form-description'} style={{
                            ...{color: textColor}
                        }}>{formData?.welcome_screen.description}</p>
                    </div> : null}
                {formData?.setting?.show_question_count ? <p style={{margin: '5px', color: textColor}}>Total Questions
                    : {questionCount(formData?.pages[0].questions)}</p> : null}
                {formData?.setting?.show_estimated_time && !formData?.is_quiz ?
                    <p style={{margin: '5px', color: textColor}}>Estimated Time
                        : {estimatedTime(formData?.pages[0].questions)} Mins</p> : null}
            </div>
            {props.formThemeImageSrc ? null :
                <MaterialButton id={'start-survey-button'} data={{
                    title: formData?.welcome_screen.button_text,
                    textTransform: "uppercase",
                    fontSize: "16px",
                    bg_color: getStartButtonBackgroundColor(),
                    customButtonContainerStyle: {
                        margin: '5px',
                        padding: '20px 24px',
                        fontSize: '14px',
                        textAlign: 'center',
                        border: formData?.theme === THEME_TYPE.CLASSIC ? '1px solid #212121' : null
                    },

                    customButtonTextStyle: {
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        letterSpacing: '1px',
                        fontWeight: '600',
                        color: textColor
                    }
                }} handleClick={() => {
                    if (props.forqTimer) {
                        return
                    }
                    if (formData.abuse_scan_result && !props.previewType && !window.location.search.includes("admin=83A7DA3315ED77BC361D4271BC216")) {
                        dispatch({type: SCREENS_ACTION_TYPE.SET_FORM_SCREEN, payload: FORM_SCREEN.ABUSE_WARNING})
                    } else {
                        openFormPage();
                    }
                }}/>
            }
        </div>
    );

    function openFormPage() {
        dispatch({type: FORM_DATA_ACTION_TYPE.SET_LOADING, payload: true})
        dispatch({type: THEME_ACTION_TYPE.SET_THEME_IMAGE_BLUR, payload: 4})
        dispatch({type: SCREENS_ACTION_TYPE.SET_IMAGE_VIEWER, payload: null})

        if (formData?.is_quiz) {
            dispatch({type: SCREENS_ACTION_TYPE.SET_FORM_SCREEN, payload: FORM_SCREEN.QUIZ_USER_INFO})
            if (props.setSelectedPreviewScreen) {
                props.setSelectedPreviewScreen(FORM_SCREEN.QUIZ_USER_INFO);
            }
        } else if (formData.user_info_form?.user_info_1 && !formData.setting?.is_email) {
            dispatch({type: SCREENS_ACTION_TYPE.SET_FORM_SCREEN, payload: FORM_SCREEN.FORM_USER_INFO})
            if (props.setSelectedPreviewScreen) {
                props.setSelectedPreviewScreen(FORM_SCREEN.FORM_USER_INFO);
            }
        } else {
            dispatch({type: SCREENS_ACTION_TYPE.SET_FORM_SCREEN, payload: FORM_SCREEN.FORM_PAGE})
            if (props.setSelectedPreviewScreen) {
                props.setSelectedPreviewScreen(FORM_SCREEN.FORM_PAGE);
            }
        }
    }
}
