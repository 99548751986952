import React, {useRef, useState} from "react";
import MaterialButton from "../../../shared/components/MaterialButton";
import FilterPopUpContainer from "./FilterPopUpContainer";
import {isMobileOnly} from "react-device-detect";
import ColumnContainer from "./ColumnContainer";
import {useTranslation} from "react-i18next";
import {translationKeys} from "../../../localizations/translationKeys-localization";
import {useSelector} from "react-redux";

export const ButtonPopUps = {
    FILTER: 0,
    COLUMN: 1
}

const filterCountNotificationBadge = {
    width: '20px',
    height: '20px',
    backgroundColor: '#fff',
    display: 'flex',
    justifyContent: 'center',
    fontSize: '11px',
    color: '#1976d2',
    fontWeight: 'bold',
    alignItems: 'center',
    borderRadius: '50%',
    position: 'absolute',
    right: '-9px',
    top: '-7px'
}

function ButtonsContainer(props) {
    const {t} = useTranslation()
    const selectedLanguage = useSelector(state => state.languageReducer?.selectedLanguage)
    const [showPopUp, setShowPopUp] = useState(null);
    const filterObjectCountRef = useRef(0)
    const filterButtonData = {
        title: isMobileOnly ? '' : filterObjectCountRef?.current ? (`${t(translationKeys.filter)} (` + filterObjectCountRef.current + ')') : t(translationKeys.filter),
        bg_color: '#004ba0',
        imageFileName: 'filter_white.svg',
        customButtonContainerStyle: {
            minWidth: '0',
            height: isMobileOnly ? '30px' : null,
            alignItems: 'center',
            padding: isMobileOnly ? '0 6px 0 8px' : null
        },
        customImageStyle: {
            marginRight: (isMobileOnly ? '0' : '8px')
        }
    }
    const printButtonData = {
        title: isMobileOnly ? '' : t(translationKeys.print), imageFileName: 'print_icon.svg',
        bg_color: '#004ba0',
        customButtonContainerStyle: {
            minWidth: '0',
            height: isMobileOnly ? '30px' : null,
            padding: isMobileOnly ? '0 6px 0 8px' : null
        },
        customImageStyle: {
            marginRight: isMobileOnly ? '0' : '8px'
        }
    }
    const fullScreenButtonData = {
        title: props.isFullScreen ? t(translationKeys.exit_screen) : t(translationKeys.full_screen),
        bg_color: '#004ba0',
        imageFileName: props.isFullScreen ? 'fullscreen_exit.svg' : 'fullscreen.svg'
    }

    //Buttons click events
    const showFilterPopUp = () => setShowPopUp(ButtonPopUps.FILTER);

    const printData = () => window.print();
    const handleFullScreenButtonClick = () => {
        props.isFullScreen ? props.setFullScreen(false) : props.setFullScreen(true);
    }

    return <div id='total-buttons-container' className='buttons-container'>
        <ColumnContainer {...props} setShowPopUp={setShowPopUp} showPopUp={showPopUp}/>

        {/*filter*/}
        <div style={{paddingLeft: isMobileOnly && (selectedLanguage === 'fa' || selectedLanguage === 'ar') ? '3px' : '12px', paddingRight: selectedLanguage === 'fa' ? '3px' : null, position: 'relative'}}>
            <MaterialButton id={'filter-button'} data={filterButtonData}
                            handleClick={showFilterPopUp}
            />
            {isMobileOnly && filterObjectCountRef?.current ?
                <div style={filterCountNotificationBadge}>
                    <span>{filterObjectCountRef.current}</span>
                </div>
                : null}
            {showPopUp === ButtonPopUps.FILTER ?
                <FilterPopUpContainer questionsData={props.questionsData}
                                      setShowPopUp={setShowPopUp}
                                      timeFilterObjectRef={props.timeFilterObjectRef}
                                      questionFilterObject={props.questionFilterObject}
                                      filteredArrayRef={props.filteredArrayRef}
                                      searchedArrayRef={props.searchedArrayRef}
                                      isFilteredArrayChanged={props.isFilteredArrayChanged}
                                      setIsFilteredArrayChanged={props.setIsFilteredArrayChanged}
                                      searchInputElementRef={props.searchInputElementRef}
                                      searchCloseButtonRef={props.searchCloseButtonRef}
                                      setSelectedTableRows={props.setSelectedTableRows}
                                      setCurrentPageNumber={props.setCurrentPageNumber}
                                      setShowDeleteBottomContainer={props.setShowDeleteBottomContainer}
                                      summaryDataSet={props.summaryDataset}
                                      filterObjectCountRef={filterObjectCountRef}
                /> : null}
        </div>
        {/*Print button*/}
        <div style={{paddingLeft: isMobileOnly ? '12px' : '12px'}}>
            <MaterialButton id='print-button' data={printButtonData} handleClick={printData}/>
        </div>

        {/*full screen button*/}
        {isMobileOnly ? null :
            <div style={{paddingLeft: isMobileOnly ? '8px' : '12px'}}>
                <MaterialButton id='full-screen-button' data={fullScreenButtonData} handleClick={handleFullScreenButtonClick}/>
            </div>
        }
    </div>
}

export default ButtonsContainer