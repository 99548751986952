import React from 'react'
import {isMobileOnly} from "react-device-detect";
import {translationKeys} from "../../../localizations/translationKeys-localization";
import {useTranslation} from "react-i18next";

const ThemesHeader = props => {
    const {t} = useTranslation()
    return (
        <div id={'theme-header'} className='header'>
            <div style={{
                display: 'flex',
                justifyContent: !isMobileOnly ? 'space-between' : 'unset',
                height: '100%',
                width: isMobileOnly ? '100%' : null
            }}>
                {/*Default button*/}
                <div id={'header-button-Theme'}
                     className={'header-button center'}
                     onClick={() => props.changeContainer('default')}
                >
                <span
                    className={props.showDefaultThemes ? 'mobile-header-tab-row-item-theme-settings mobile-header-tab-row-item-theme-clicked-settings' : 'mobile-header-tab-row-item-theme-settings'}>{t(translationKeys.themes)}</span>
                </div>

                {/*My themes button*/}
                <div id={'header-button-mytheme'}
                     className={'header-button center'}
                     onClick={() => props.changeContainer('myThemes')}
                >
                <span
                    className={props.showMyThemes ? 'mobile-header-tab-row-item-theme-settings mobile-header-tab-row-item-theme-clicked-settings' : 'mobile-header-tab-row-item-theme-settings'}>{t(translationKeys.my_themes)}</span>
                </div>
            </div>
            {/*close button*/}
            <div id={'theme-close-button center'} className="theme-close-button center"
                 onClick={() => props.changeContainer()}>
                <img src="images/close_black.svg" alt="images/close_black.svg"/>
            </div>
        </div>
    )
}

export default ThemesHeader
