import React, {useState} from "react"
import '../css/builder.css'
import LinearScaleMobilePopup from "./LinearScaleMobilePopup"
import {useSelector} from "react-redux"

const MobileLinearScaleBody = (props) => {
    const question = useSelector(state => state.appReducer.formData.pages?.[0]?.questions?.[props.index])

    const [showMobileLinearScalePopup, setShowMobileLinearScalePopup] = useState(true)

    return (
        <>
            {
                (() => {
                    if (!showMobileLinearScalePopup)
                        return (
                            <div style={{
                                display: 'Flex',
                                paddingBottom: '5px',
                                color: '#004BA0',
                                fontFamily: 'Nunito',
                                fontWeight: '700'
                            }} onClick={() => setShowMobileLinearScalePopup(true)}>
                                Show Scale ({question?.choices?.length})
                            </div>)
                })()
            }
            {
                (() => {
                    if (showMobileLinearScalePopup)
                        return (
                            <LinearScaleMobilePopup {...props}/>
                        )
                })()
            }
        </>
    )
}

export default MobileLinearScaleBody