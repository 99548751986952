import {isMobileOnly} from "react-device-detect";
import React from "react";
import {useTranslation} from "react-i18next";
import {translationKeys} from "../../../localizations/translationKeys-localization";

function OtherOptionBar(props) {
    const {t} = useTranslation()
    const otherResponseCount = props.otherResponses.length;
    const answeredCount = props.answeredCount >=1 ? props.answeredCount - props.skippedAnswerCount : 1;
    let percentageAnswered = ((otherResponseCount / answeredCount) * 100);
    percentageAnswered = (Math.round(percentageAnswered) !== percentageAnswered) ?
        isMobileOnly ? percentageAnswered.toFixed(1) + '%' : percentageAnswered.toFixed(2) + '%'
        : percentageAnswered + '%';
    return (
        <div className='result-option-bar-container'>
            {/*percentage element*/}
            <div id='percentage-other-optionbar' className='percentages'>
                {percentageAnswered}
            </div>
            {/*bar element*/}
            <div id='option-bar-others' className='option-bar'>
                <div className='option-active-color' style={{width: percentageAnswered}}/>
                <span className='option-text'>{t(translationKeys.others)}</span>
            </div>
            {/*Response Count element*/}
            <div id='chosen-count-optionbar' className='chosen-count'>{otherResponseCount}</div>
        </div>
    )
}

export default OtherOptionBar