import React from "react";
import {QUESTION_GROUPS} from "../../../shared/utils/constants.js"
import StarBody from "./StarBody";
import ChoiceBody from "./ChoiceBody";
import TextQuestionCorrectAnswerAndMarks from "./TextQuestionCorrectAnswerAndMarks";
import TextQuestionBody from "./TextQuestionBody";
import FileUploadMarks from "./FileUploadMarks"
import {isMobile} from "react-device-detect";
import GridBodyDesktop from "./GridBodyDesktop"
import MobileLinearScaleBody from "./MobileLinearScaleBody"
import LinearScaleBodyDesktop from "./LinearScaleBodyDesktop"
import MobileGridBody from "./MobileGridBody"
import SmileBody from "./SmileBody"

const QuestionBody = (props) => {
    return (
        <div id={'builder-question-body'} className="builder-question-body">
            {
                (() => {
                    if (!props.isQuiz && (props.questionGroup === QUESTION_GROUPS.TEXT || props.questionGroup === QUESTION_GROUPS.FILE_UPLOAD)) return (
                        <TextQuestionBody {...props}/>
                    )
                    if (props.questionGroup === QUESTION_GROUPS.CHOICE) return (
                        <ChoiceBody {...props} startQuestionNumber={1}/>
                    )
                    if (props.questionGroup === QUESTION_GROUPS.STAR) return (
                        <StarBody {...props}/>
                    )
                    if (props.questionGroup === QUESTION_GROUPS.LINEAR_SCALE) return (() => {
                        if (isMobile) return <MobileLinearScaleBody index={props.index}/>

                        return <LinearScaleBodyDesktop {...props}/>
                    })()
                    if (props.questionGroup === QUESTION_GROUPS.GRID) return (() => {
                        if (isMobile) return <MobileGridBody {...props}/>
                        return <GridBodyDesktop {...props}/>
                    })()
                    if (props.questionGroup === QUESTION_GROUPS.SMILE) return (() => {
                        return <SmileBody {...props}/>
                    })()
                    if (props.isQuiz && props.questionGroup === QUESTION_GROUPS.TEXT) return (
                        <TextQuestionCorrectAnswerAndMarks {...props}/>
                    )
                    if (props.isQuiz && props.questionGroup === QUESTION_GROUPS.FILE_UPLOAD) return (
                        <FileUploadMarks {...props}/>
                    )
                })()
            }
        </div>
    )
}

export default QuestionBody
