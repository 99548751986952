import React, {useEffect, useRef, useState} from 'react'
import PopUpContainer from "../../../shared/components/PopUpContainer"
import MaterialCheckBox from "../../../shared/components/MaterialCheckBox"
import {FORM_SETTINGS} from "./FormBuilderContainer"
import DefaultThemeCard from "./DefaultThemeCard"
import {AWS_FOLDER_NAMES, AWS_UPLOAD_TYPE, MEDIA_BASE_URL} from "../utils/aws";
import {isMobileOnly} from "react-device-detect"
import MaterialTextField from "../../../shared/components/MaterialTextField";
import {translationKeys} from "../../../localizations/translationKeys-localization";
import enTheme from "../../../../public/images/sh_images_en.json"
import frTheme from "../../../../public/images/sh_images_fr.json"
import inTheme from "../../../../public/images/sh_images_in.json"
import hiTheme from "../../../../public/images/sh_images_hi.json"
import paTheme from "../../../../public/images/sh_images_pa.json"
import mlTheme from "../../../../public/images/sh_images_ml.json"
import mrTheme from "../../../../public/images/sh_images_mr.json"
import faTheme from "../../../../public/images/sh_images_fa.json"
import ptTheme from "../../../../public/images/sh_images_pt.json"
import esTheme from "../../../../public/images/sh_images_es.json"
import taTheme from "../../../../public/images/sh_images_ta.json"
import teTheme from "../../../../public/images/sh_images_te.json"
import arTheme from "../../../../public/images/sh_images_ar.json"
import {t} from "i18next";
import {useDispatch, useSelector} from "react-redux";
import {BUILDER_ACTION_TYPE} from "../../reducers/builderReducer";
import {getRole, PremiumJSON} from "../../../shared/utils/helper";
import premiumCrown from "../../../../public/images/premium_crown.svg"
import {COLLABORATOR_ROLES} from "../enums/collaboratorsRoles";
import {PREMIUM_ACTION_TYPE} from "../../reducers/premiumReducer";
import {COLLABORATOR_ACTION_TYPE} from "../../utils/constants";

const SettingsViewContainer = props => {
    const shared = useSelector(state => state.appReducer.shared)
    const userId = useSelector(state => state.userReducer.userId)
    const formData = useSelector(state => state.appReducer.formData)
    const collaborator = useSelector(state => state.collaboratorsData?.collaborators)
    const subscriptionData = useSelector(state => state.userReducer?.subscriptionData)
    const showConfirmationMessageBasedOnUser = shared ? PremiumJSON[subscriptionData?.subscription_plan]?.customizable_thank_you && PremiumJSON[formData?.subscription_data?.current_subscription?.plan]?.customizable_thank_you : PremiumJSON[subscriptionData?.subscription_plan]?.customizable_thank_you
    const [showFileBrowser, setShowFileBrowser] = useState(false)
    const selected = useSelector(state => state.languageReducer?.selectedLanguage)
    const [allowStartTextChange, setAllowStartTextChange] = useState(formData?.welcome_screen.show_screen)
    const [logoURL, setLogoURL] = useState(`${MEDIA_BASE_URL}/${AWS_FOLDER_NAMES.LOGOS_FOLDER}/${userId}_logo.png?id=${new Date().getTime()}`)
    const dispatch = useDispatch()

    const generateRandomNumber = () => Math.floor(Math.random() * 900000) + 100000;

    const [uniqueSixDigitNumber, setUniqueSixDigitNumber] = useState(formData?.setting?.passcode);
    const [isAdminEditor, setIsAdminEditor] = useState(false);

    useEffect(() => {
        if (formData?.collaborators) {
            dispatch({type: COLLABORATOR_ACTION_TYPE.UPDATE_COLLABORATOR_DATA, payload: formData.collaborators})
        }
        setIsAdminEditor(getRole(collaborator, userId) === COLLABORATOR_ROLES.ADMINISTRATOR || userId === formData.user_id)
    }, [collaborator]);

    const selectThemeImage = () => {
        switch (selected) {
            case 'en':
                return enTheme
            case 'fr':
                return frTheme
            case 'in':
                return inTheme
            case 'hi':
                return hiTheme
            case 'pa':
                return paTheme
            case 'ml':
                return mlTheme
            case 'mr':
                return mrTheme
            case 'fa':
                return faTheme
            case 'pt':
                return ptTheme
            case 'es':
                return esTheme
            case 'ta':
                return taTheme
            case 'te':
                return teTheme
            case 'ar':
                return arTheme
            default :
                return enTheme
        }
    }

    const [themes, setThemes] = useState(() => {
        let tempThemes = selectThemeImage()

        tempThemes.unshift({"file": "dark.jpeg", "name": t(translationKeys.dark_mode)})
        tempThemes.unshift({"file": "classic.jpeg", "name": t(translationKeys.default_text)})
        tempThemes.unshift({"file": "classic_theme", "name": "classic.jpeg"})
        tempThemes.unshift({"file": "", "name": t(translationKeys.my_themes)})

        return tempThemes
    })

    const getThemeInfo = () => {
        let themeToSearchFor = null

        if (formData?.theme === 'classic_theme') {
            themeToSearchFor = 'classic.jpeg'
        } else if (formData?.theme === 'dark_theme') {
            themeToSearchFor = 'dark.jpeg'
        } else {
            themeToSearchFor = formData?.theme
        }

        if (themeToSearchFor?.startsWith('http')) {
            // User has selected a custom theme image
            return {file: themeToSearchFor, name: t(translationKeys.my_themes)}
        } else {
            return themes.filter(theme => theme.file === themeToSearchFor)[0]
        }
    }

    const [themeInfo, setThemeInfo] = useState(() => {
        return getThemeInfo()
    })

    const refLogoExists = useRef(true)
    const refLogoImage = useRef(null)

    useEffect(() => {
        setThemeInfo(getThemeInfo())
    }, [formData?.theme])

    useEffect(() => {
            // Check if the Logo Exists
            if (!shared) {
                refLogoImage.current.onerror = () => {
                    refLogoExists.current = false
                }
            }
        }
        , [])

    const shouldFileBrowserBeVisible = (value) => {
        setShowFileBrowser(value)
    }

    const onLogoUpdated = (updatedLogo) => {
        // Note the URL doesn't change for a Logo ... "id" is used to for a browser refresh of the logo.
        setLogoURL(`${updatedLogo}?id=${new Date().getTime()}`)
        refLogoExists.current = true
        props.updateSetting(FORM_SETTINGS.SHOW_LOGO, true)
    }

    const onFileBrowserCancel = () => {
        if (!refLogoExists.current) {
            setShowFileBrowser(false)
        }
    }

    const handleShowLogoChanged = (bool) => {
        if (bool) {
            if (refLogoExists.current) {
                props.updateSetting(FORM_SETTINGS.SHOW_LOGO, bool)
            } else {
                props.updateSetting(FORM_SETTINGS.SHOW_LOGO, bool)
                setShowFileBrowser(true)
            }
        } else {
            props.updateSetting(FORM_SETTINGS.SHOW_LOGO, bool)
        }
    }

    const handleShowWelcomePageChanged = (e) => {
        props.updateSetting(FORM_SETTINGS.SHOW_WELCOME_PAGE, e)
        setAllowStartTextChange(e)
    }

    const handleStartButtonBlur = (e) => {
        if (e === '') {
            // 'Start' is the default value.
            props.updateSetting(FORM_SETTINGS.START_BUTTON_TEXT, t(translationKeys.start))
        }
    }

    const handleStartButtonTextChanged = (e) => {
        props.updateSetting(FORM_SETTINGS.START_BUTTON_TEXT, e)
    }

    const handleThankYouTextChanged = (e) => {
        props.updateSetting(FORM_SETTINGS.CONFIRMATION_MESSAGE, e)
    }

    const handleShowEstimatedTimeChanged = (e) => {
        props.updateSetting(FORM_SETTINGS.ESTIMATED_TIME, e)
    }

    const handleShowQuestionCountChanged = (e) => {
        props.updateSetting(FORM_SETTINGS.SHOW_QUESTION_COUNT, e)
    }

    const ignoreClick = () => {
        // do nothing
    }

    useEffect(() => {
        if (uniqueSixDigitNumber !== undefined)
            props.updateSetting(FORM_SETTINGS.PASSCODE, uniqueSixDigitNumber?.toString())
    }, [uniqueSixDigitNumber]);

    const handlePasscodeProtection = (event) => {
        if (event) {
            // to enable and add the 6 digit string to the passcode in settings
            setUniqueSixDigitNumber(generateRandomNumber());
        } else {
            // to delete the passcode from settings
            props.updateSetting(FORM_SETTINGS.PASSCODE, 'delete')
        }
    }

    const handleConfirmationMessage = (event) => {
        if (event) {
            props.updateSetting(FORM_SETTINGS.CONFIRMATION_MESSAGE, formData?.setting?.confirmation_message ? formData?.setting?.confirmation_message : `${formData?.is_quiz ? t(translationKeys.default_custom_thank_message_quiz) : t(translationKeys.default_custom_thank_message)}`)
        } else {
            props.updateSetting(FORM_SETTINGS.CONFIRMATION_MESSAGE, '')
        }
    }

    const copyToClipboard = () => {
        navigator.clipboard.writeText(uniqueSixDigitNumber.toString())
            .then(() => {
                dispatch({type: BUILDER_ACTION_TYPE.SET_BUILDER_MESSAGE, payload: 'Copied'})
                dispatch({type: BUILDER_ACTION_TYPE.SET_BUILDER_TOAST_MESSAGE, payload: true})
            })
            .catch((error) => {
                console.error('Unable to copy text to clipboard:', error);
            });
    };

    const handleDisabledCheckboxClick = () => {
        if (formData?.is_logic_enabled) {
            dispatch({type: BUILDER_ACTION_TYPE.SET_BUILDER_MESSAGE, payload: 'Action cannot be performed'});
            dispatch({type: BUILDER_ACTION_TYPE.SET_BUILDER_TOAST_MESSAGE, payload: true})
        }
    }

    const regenerateCode = () => setUniqueSixDigitNumber(generateRandomNumber());

    return (
        <div id={'settings-view-wrapper'} className="settings-view-wrapper">
            {/* Row 1 */}
            <div id={'settings-view-box1'} className="settings-view-box1">
                <img src="images/check_box_disabled.svg" alt="Disabled Checkbox Icon"/>
            </div>
            <div id={'settings-view-change-theme'} className="settings-view-box2">
                <img src="images/color_lens.svg" alt="SurveyHeart icon"/>
                <div id={'settings-bold-text'} className="settings-bold-text">{t(translationKeys.change_theme)}</div>
            </div>
            {/* Row 2 */}
            <div id={'settings-click-to-change-current-theme'} className="settings-view-box4">
                <span style={selected === "ml" || selected === "ta" ? {fontSize: "14px"} : null}
                      className='settings-normal-text'>{t(translationKeys.click_or_change_current_theme)}</span>
            </div>
            {/* Row 3 */}
            <div id={'settings-view-box6'} className="settings-view-box6">
                <DefaultThemeCard key={themeInfo}
                                  imageData={themeInfo}
                                  updateChosenTheme={ignoreClick}
                                  changeContainer={() => {
                                      props.showThemesContainer()
                                  }}
                                  handleClick={(e) => {
                                      props.showThemesContainer(e)
                                  }}
                                  lastClickedImage={formData?.theme}
                                  hideCheck={true}
                />

            </div>
            {/* Row 4 */}
            <div id={'settings-view-change'} className="settings-view-box8">
                <div className='settings-button' onClick={(e) => {
                    props.showThemesContainer(e)
                }}>
                    <span>{t(translationKeys.change).toUpperCase()}</span>
                </div>
            </div>
            {/* Row 5 */}
            {shared ? null : <>
                <div id={'settings-view-box9'} className="settings-view-box9">
                    <MaterialCheckBox removeWidth={true}
                                      onChange={handleShowLogoChanged}
                                      isChecked={formData?.setting.is_show_logo && refLogoExists.current}
                    />
                </div>
                <div className="settings-view-box10">
                    <img src="images/surveyheart_icon_small_dark_blue.svg"
                         alt="SurveyHeart icon"/>
                    <div className="settings-bold-text">{t(translationKeys.show_logo)}</div>
                </div>
                {/* Row 6 */}
                <div id={'settings-view-show-your-logo'} className="settings-view-box12">
                    <span className='settings-normal-text'>{t(translationKeys.show_logo_alert)}</span>
                </div>
                {/* Row 7 */}
                <div id={'settings-view-box14'} className="settings-view-box14"
                     style={{display: formData?.setting.is_show_logo && refLogoExists.current ? 'block' : 'none'}}>
                    <img style={isMobileOnly ? {maxHeight: 100, maxWidth: 150} : {maxHeight: 200}} src={logoURL}
                         ref={refLogoImage}
                    />
                </div>
                {/* Row 8 */}
                <div id={'settings-view-browse'} className="settings-view-box16"
                     style={{display: formData?.setting.is_show_logo ? 'flex' : 'none'}}>
                    <div className='settings-button' onClick={() => shouldFileBrowserBeVisible(true)}>
                        <span>{t(translationKeys.browse).toUpperCase()}</span>
                    </div>
                    {
                        (() => {
                            if (showFileBrowser) return (
                                <PopUpContainer
                                    zIndex={11}
                                    isImageUploader={true}
                                    uploadType={AWS_UPLOAD_TYPE.LOGO}
                                    userId={userId}
                                    shouldFileBrowserBeVisible={() => shouldFileBrowserBeVisible(false)}
                                    updateLogo={onLogoUpdated}
                                    cancelAction={onFileBrowserCancel}
                                />
                            )
                        })()
                    }
                </div>
            </>}
            {/* Row 9 */}
            <div id={'settings-view-box17'} className="settings-view-box17">
                <MaterialCheckBox isChecked={formData?.welcome_screen.show_screen}
                                  removeWidth={true}
                                  onChange={handleShowWelcomePageChanged}/>
            </div>
            <div id={'settings-view-welcome-page'} className="settings-view-box18">
                <img src="images/home_icon_small_dark_blue.svg" alt="SurveyHeart icon"/>
                <div className="settings-bold-text">{t(translationKeys.welcome_page)}</div>
            </div>
            {/* Row 10 */}
            <div id={'settings-view-welcome-page-info'} className="settings-view-box20">
                <span className='settings-normal-text'>{t(translationKeys.show_welcome_page_alert)}</span>
            </div>
            {/* Row 11 */}
            <div id={'settings-view-start-button'} className="settings-view-box22">
                <div id={'settings-start-button-checkbox'} className="settings-view-box20"
                     style={{display: allowStartTextChange ? 'block' : 'none'}}>
                    <MaterialTextField
                        data={{
                            hint: t(translationKeys.start_button),
                            defaultValue: formData?.welcome_screen.button_text
                        }}
                        applyV2CSS={true}
                        onChange={handleStartButtonTextChanged}
                        onBlur={handleStartButtonBlur}
                        maxLength={20}
                    />
                </div>
            </div>
            {props.isQuiz ? null : <>
                <div id={'settings-view-box23'} className="settings-view-box23" onClick={handleDisabledCheckboxClick}>
                    <MaterialCheckBox removeWidth={true}
                                      onChange={handleShowEstimatedTimeChanged}
                                      isChecked={formData?.setting.show_estimated_time}
                                      isDisabled={formData?.is_logic_enabled}
                    />
                </div>
                <div id={'settings-view-estimated-time'} className="settings-view-box24">
                    <img src="images/estimated-time.svg"
                         alt="SurveyHeart icon"/>
                    <div className="settings-bold-text">{t(translationKeys.estimated_time)}</div>
                </div>
                {/* Row 6 */}
                <div id={'settings-view-show-estimated-time'} className="settings-view-box25">
                    <span
                        className='settings-normal-text'>{props.isQuiz ? t(translationKeys.do_you_want_to_show_estimated_time_taking_quiz) : t(translationKeys.do_you_want_to_show_estimated_time_taking_form)}</span>
                </div>
            </>}
            <div id={'settings-view-box26'} className="settings-view-box26" onClick={handleDisabledCheckboxClick}>
                <MaterialCheckBox removeWidth={true}
                                  onChange={handleShowQuestionCountChanged}
                                  isChecked={formData?.setting.show_question_count}
                                  isDisabled={formData?.is_logic_enabled}
                />
            </div>
            <div id={'settings-view-show-question-count'} className="settings-view-box27">
                <img src="images/question-count.svg"
                     alt="SurveyHeart icon"/>
                <div className="settings-bold-text">{t(translationKeys.show_question_count)}</div>
            </div>
            {/* Row 6 */}
            <div id={'settings-view-show-total-number-questions'} className="settings-view-box28">
                <span
                    className='settings-normal-text'>{props.isQuiz ? t(translationKeys.do_you_want_to_show_total_number_questions_quiz) : t(translationKeys.do_you_want_to_show_total_number_questions_form)}</span>
            </div>
            {/*Password Protection*/}
            {
                isAdminEditor ? <>
                    <div id={'settings-view-box29'} className="settings-view-box29">
                        <MaterialCheckBox removeWidth={true}
                                          onChange={handlePasscodeProtection}
                                          isChecked={formData && formData.setting && formData.setting.passcode} // Check if passcode is present
                        />
                    </div>
                    <div id={'settings-view-estimated-time'} className="settings-view-box30">
                        <img src="images/lock_blue.svg"
                             alt="passcode lock icon"/>
                        <div className="settings-bold-text">{t(translationKeys.passcode_protection)}</div>
                    </div>
                    <div id={'settings-view-show-total-number-questions'} className="settings-view-box31">
                <span className='settings-normal-text'>
                    {props.isQuiz ? t(translationKeys.passcode_protection_description_quiz) : t(translationKeys.passcode_protection_description)}</span>
                    </div>

                    {
                        formData?.setting?.passcode && <>
                            <div id={'settings-view-show-total-number-questions'} className="settings-view-box32">
                                <div className={'settings-passcode'}>{uniqueSixDigitNumber}</div>
                                <div className={'settings-passcode-copy-icon'}>
                                    <img src="images/copy_icon_blue.svg"
                                         alt="passcode copy icon" onClick={copyToClipboard}/>
                                </div>
                            </div>

                            <div id={'settings-regenerate-code'} className="settings-view-box33">
                                <button className='regenerate-code-button' onClick={regenerateCode}>
                                    <span>{t(translationKeys.re_generate_code)}</span>
                                </button>
                            </div>
                        </>
                    }
                </> : null
            }
            {/*Custom Thank you*/}
            <div className={"custom-thank-you"}
                 style={showConfirmationMessageBasedOnUser ? null : {background: "#F5F5F5", cursor: "pointer"}}
                 onClick={() => {
                     if (!showConfirmationMessageBasedOnUser) {
                         if (shared && !PremiumJSON[formData?.subscription_data?.current_subscription?.plan]?.customizable_thank_you) {
                             dispatch({type: PREMIUM_ACTION_TYPE.SET_SHOW_FREE_OWNER_POP_UP, payload: true})
                         } else {
                             dispatch({type: PREMIUM_ACTION_TYPE.SET_SHOW_UPGRADE_PLAN_POP_UP, payload: true})
                         }
                     }
                 }}>
                <div id={'settings-view-box34'} className={"settings-view-box34"}
                     style={{visibility: showConfirmationMessageBasedOnUser ? null : "hidden"}}>
                    <MaterialCheckBox removeWidth={true}
                                      onChange={handleConfirmationMessage}
                                      isChecked={formData?.setting?.confirmation_message}
                    />
                </div>
                <div className="settings-view-box35">
                    <img src="images/thankyou.svg"
                         alt="thank you icon"/>
                    <div className="settings-bold-text">
                        {t(translationKeys.confirmation_message)} {showConfirmationMessageBasedOnUser ? null :
                        <img src={premiumCrown} alt={"Premium"}/>}
                    </div>
                </div>
                <div className="settings-view-box36">
                    <span
                        className='settings-normal-text'>{formData?.is_quiz ? t(translationKeys.confirmation_message_description_quiz) : t(translationKeys.confirmation_message_description)}</span>
                </div>
                {formData?.setting?.confirmation_message ? <div className="settings-view-box37">
                    <div className='sh-text-field'>
                        <MaterialTextField applyV2CSS={true}
                                           data={{
                                               defaultValue: formData?.setting?.confirmation_message ? formData?.setting?.confirmation_message : `${formData?.is_quiz ? t(translationKeys.default_custom_thank_message_quiz) : t(translationKeys.default_custom_thank_message)}`,
                                               hint: "Text",
                                               isReadOnly: !formData?.setting?.confirmation_message || !showConfirmationMessageBasedOnUser
                                           }}
                                           onBlur={handleThankYouTextChanged}
                                           maxLength={100}/>
                    </div>
                </div> : null}
            </div>
        </div>
    )
}

export default SettingsViewContainer
