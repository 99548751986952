import {useEffect, useState} from "react"
import {translationKeys} from "../../localizations/translationKeys-localization"
import {t} from "i18next"
import {registerGAEvent} from "../../shared/utils/analytics"
import doSearchImage from "../../../public/images/do_search.svg"
import noSearchResultsFound from "../../../public/images/no_search_results_found.svg"
import checkBoxBlack from "../../../public/images/check_box_black.svg"
import checkBoxBlue from "../../../public/images/check_box_blue.svg"
import searchGreyImage from "../../../public/images/search_grey.svg"
import loadingImage from '../../../public/images/loader.gif'
import {useDispatch, useSelector} from "react-redux"
import {IMPORT_QUESTIONS_ACTION_TYPE, SOURCE_OF_QUESTIONS_LIST} from "../reducers/importQuestionsReducer"
import {APP_ACTION_TYPE} from "../reducers/appReducer"
import {maxNumberOfFileUploadOTsHasBeenReached, removeProp} from "../builder/components/helpers/BuilderHelper"
import {getSearchQuestionData, getSpecificFormData} from "../../shared/utils/sash-v2-api"
import {QUESTION_TYPE} from "../../shared/utils/constants";
import {isMobile} from "react-device-detect";
import {BUILDER_ACTION_TYPE} from "../reducers/builderReducer";
import {getQuizById, getSearchQuizQuestionData} from "../../shared/utils/quiz-api-services";
import {AUTOCOMPLETE_ACTION_TYPE} from "../reducers/autocompleteReducer";
import {PremiumJSON} from "../../shared/utils/helper";
import {PREMIUM_ACTION_TYPE} from "../reducers/premiumReducer";

const useFormImportMgr = () => {
    const isQuiz = useSelector(state => state.appReducer.isQuiz)
    const formData = useSelector(state => state.appReducer.formData)
    const currentForm = useSelector(state => state.importQuestionsReducer?.currentForm)
    const subscriptionData = useSelector(state => state.userReducer?.subscriptionData)
    const formUserInfoText = useSelector(state => state.builderReducer?.formUserInfoText)
    const questionSearchResults = useSelector(state => state.importQuestionsReducer?.questionSearchResults)
    const sourceOfQuestionsList = useSelector(state => state.importQuestionsReducer?.sourceOfQuestionsList)
    const showSearchAndFormsList = useSelector(state => state.importQuestionsReducer?.showSearchAndFormsList)
    const isManuallyChangedFormUserInfo = useSelector(state => state.builderReducer?.isManuallyChangedFormUserInfo)
    const reduxDispatch = useDispatch()

    const [showForms, setShowForms] = useState(true)
    const [searchText, setSearchText] = useState('')
    const [searchTextReadonly, setSearchTextReadonly] = useState('')
    const [showLoader, setShowLoader] = useState(false)
    const [showNoSearchResults, setShowNoSearchResults] = useState(false)

    const clearSearchInputImg = "clearSearchInputImg"
    const searchInput = "searchInput"
    const searchInputReadOnly = "searchInputReadOnly"

    const dispatch = useDispatch()

    const isQuizDashboard = useSelector(state => state.appDashboardReducer.showQuiz)
    const formsList = isQuizDashboard || isQuiz ? useSelector(state => state.appDashboardReducer.quizzesList) : useSelector(state => state.appDashboardReducer?.formsList)

    const backText = t(translationKeys.back)
    const selectText = t(translationKeys.select)
    const selectAllText2 = t(translationKeys.select_all)

    useEffect(() => {
        const searchInputControl = document.getElementById(searchInput)

        if (searchInputControl) {
            searchInputControl.focus()
        }
    }, [])

    useEffect(() => {
        const clearSearchInputImgControl = document.getElementById(clearSearchInputImg)

        if (clearSearchInputImgControl) {
            const searchInputControl = document.getElementById(searchInput)

            clearSearchInputImgControl.style.visibility = searchInputControl.value.length > 0 ? 'visible' : 'hidden'
        }

        if (searchText.trim().length > 3) {
            setSearchTextReadonly(`Search for '${searchText}' in ${isQuiz ? "Quizzes" : "Forms"}`)
            setShowForms(false)
        } else {
            setShowForms(true)
            setSearchTextReadonly('')
        }
    }, [searchText])

    const clearSearchInput = () => {
        dispatch({
            type: IMPORT_QUESTIONS_ACTION_TYPE.SET_QUESTIONS_SEARCH_RESULTS,
            payload: []
        })
        dispatch({type: AUTOCOMPLETE_ACTION_TYPE.CLEAR_SUGGESTIONS})
        setShowNoSearchResults(false)
        setShowForms(true)
        setSearchText('')
        document.getElementById(searchInput)?.focus()
    }

    const getFormDataSuccessFunction = res => {
        dispatch({
            type: IMPORT_QUESTIONS_ACTION_TYPE.SET_CURRENT_FORM,
            payload: res
        })
        showFormsList(false)
        setShowLoader(false)
    }

    const getDataGenericFailureFunction = err => {
        setShowLoader(false)
        console.log(err)
    }

    const handleFormCardClick = (formID) => {
        dispatch({
            type: IMPORT_QUESTIONS_ACTION_TYPE.SET_QUESTIONS_SEARCH_RESULTS,
            payload: []
        })
        setShowLoader(true)
        if (isQuizDashboard || isQuiz) {
            getQuizById(formID, getFormDataSuccessFunction, getDataGenericFailureFunction)
        } else {
            getSpecificFormData(formID, getFormDataSuccessFunction, getDataGenericFailureFunction)
        }
    }

    const handleCloseDialog = () => {
        clearSearchInput()
        registerGAEvent('Import Questions', 'pop-up', 'close')
        showFormsList(true)
        dispatch({type: IMPORT_QUESTIONS_ACTION_TYPE.SET_SHOW_IMPORT_QUESTIONS_DIALOG, payload: false})
    }

    const showFormsList = (value) => {
        dispatch({
            type: IMPORT_QUESTIONS_ACTION_TYPE.SET_SHOW_SEARCH_AND_FORMS_LIST,
            payload: value
        })
    }

    const handleSearchTextChange = (event) => {
        if (event.length > 3) {
            reduxDispatch({type: AUTOCOMPLETE_ACTION_TYPE.IMPORT_QUESTION_SEARCH, payload: event})
        } else {
            reduxDispatch({type: AUTOCOMPLETE_ACTION_TYPE.CLEAR_SUGGESTIONS})
        }
        dispatch({
            type: IMPORT_QUESTIONS_ACTION_TYPE.SET_QUESTIONS_SEARCH_RESULTS,
            payload: []
        })
        setShowNoSearchResults(false)
        setSearchText(event)
    }

    const handleSelectAllClick = () => {
        const maxFileUploadLimit = PremiumJSON[subscriptionData?.subscription_plan]?.file_upload_questions_per_form

        const currentFileUploadQuestionCountInFormData = formData.pages[0].questions?.filter((question) => question.type === QUESTION_TYPE.FILE_UPLOAD.key)?.length
        let selectedFileUploadQuestionCount = (sourceOfQuestionsList === SOURCE_OF_QUESTIONS_LIST.SEARCH_RESULTS) ? questionSearchResults?.filter((question) => question.selected && question.type === QUESTION_TYPE.FILE_UPLOAD.key)?.length : currentForm?.pages[0].questions?.filter((question) => question.selected && question.type === QUESTION_TYPE.FILE_UPLOAD.key)?.length
        let limitReached = false

        const selectedQuestionsCount = currentForm?.pages[0].questions
            .filter((question) => {
                if (question.type === QUESTION_TYPE.FILE_UPLOAD.key) {
                    // File Upload QT is being added
                    if (maxNumberOfFileUploadOTsHasBeenReached(formData.pages[0].questions, subscriptionData) || (currentFileUploadQuestionCountInFormData + selectedFileUploadQuestionCount === maxFileUploadLimit)) {
                        if (selectedFileUploadQuestionCount !== maxFileUploadLimit) {
                            limitReached = true
                            dispatch({type: PREMIUM_ACTION_TYPE.SET_SHOW_UPGRADE_PLAN_POP_UP, payload: true})
                        }
                    } else {
                        selectedFileUploadQuestionCount = selectedFileUploadQuestionCount + 1
                        return question.selected
                    }
                } else {
                    return question.selected
                }
            }).reduce((count) => count + 1, 0)

        if (selectedQuestionsCount === currentForm?.pages[0].questions.filter(q => q.type !== QUESTION_TYPE.SECTION.key).length) {
            // All questions are selected, so deselect all questions
            dispatch({
                type: IMPORT_QUESTIONS_ACTION_TYPE.DESELECT_ALL_QUESTIONS
            })
        } else {
            const selectedFileUploadQuestionCount = (sourceOfQuestionsList === SOURCE_OF_QUESTIONS_LIST.SEARCH_RESULTS) ? questionSearchResults?.filter((question) => question.selected && question.type === QUESTION_TYPE.FILE_UPLOAD.key)?.length : currentForm?.pages[0].questions?.filter((question) => question.selected && question.type === QUESTION_TYPE.FILE_UPLOAD.key)?.length
            if (!limitReached) {
                dispatch({
                    type: IMPORT_QUESTIONS_ACTION_TYPE.SELECT_ALL_QUESTIONS,
                    payload: maxNumberOfFileUploadOTsHasBeenReached(formData.pages[0].questions, subscriptionData) || (currentFileUploadQuestionCountInFormData + selectedFileUploadQuestionCount === maxFileUploadLimit)
                })
            }
        }
    }

    const handleDeselectQuestionClick = (questionIndex) => {
        dispatch({
            type: IMPORT_QUESTIONS_ACTION_TYPE.UPDATE_QUESTION_SELECTED_STATE,
            payload: {
                question_index: questionIndex,
                new_selected_state: false
            }
        })
    }

    const handleImportQuestionsClick = () => {
        let updatedData = {...formData}

        if (sourceOfQuestionsList === SOURCE_OF_QUESTIONS_LIST.CURRENT_FORM) {
            const selectedQuestions = currentForm?.pages[0].questions.filter((question) => question.selected)

            selectedQuestions?.forEach(selectedQuestion => {
                const updatedSelectedQuestion = {...selectedQuestion}
                updatedSelectedQuestion.is_logic_enabled = false
                updatedData.pages[0].questions = [...updatedData.pages[0].questions, updatedSelectedQuestion]
                if (updatedSelectedQuestion.attachment) {
                    updatedData.pages[0].questions[updatedData.pages[0].questions.length - 1].tempAttachmentValue = updatedSelectedQuestion.attachment.url
                }
            })
        } else if (sourceOfQuestionsList === SOURCE_OF_QUESTIONS_LIST.SEARCH_RESULTS) {
            const selectedQuestions = questionSearchResults?.filter((question) => question.selected)

            selectedQuestions?.forEach(selectedQuestion => {
                const updatedSelectedQuestion = {...selectedQuestion}
                updatedSelectedQuestion.is_logic_enabled = false
                updatedData.pages[0].questions = [...updatedData.pages[0].questions, updatedSelectedQuestion]
                if (updatedSelectedQuestion.attachment) {
                    updatedData.pages[0].questions[updatedData.pages[0].questions.length - 1].tempAttachmentValue = updatedSelectedQuestion.attachment.url
                }
            })
        }

        removeProp([...updatedData.pages[0].questions], 'selected')
        if (updatedData.pages[0].questions.filter((question) => question.type === QUESTION_TYPE.FILE_UPLOAD.key)?.length) {
            if (!isManuallyChangedFormUserInfo) {
                dispatch({type: BUILDER_ACTION_TYPE.SET_FORM_USER_INFO_CHECK, payload: true})
                updatedData.user_info_form = {
                    user_info_1: formUserInfoText?.trim()?.length ? formUserInfoText.trim() : t(translationKeys.name_s)
                }
            }
        }
        reduxDispatch({type: APP_ACTION_TYPE.SET_FORM_DATA, payload: updatedData})
        handleCloseDialog()
        dispatch({type: BUILDER_ACTION_TYPE.SET_NEW_QUESTION, payload: false})
        dispatch({type: IMPORT_QUESTIONS_ACTION_TYPE.SET_SHOW_MOBILE_IMPORT_QUESTIONS_SCREEN, payload: false})
    }

    const getSearchQuestionDataSuccessFunction = res => {
        if (res.questionsData.length === 0) {
            setShowNoSearchResults(true)
            setShowLoader(false)
        } else {
            let tempSearchResults = res.questionsData.map((q) => ({
                ...q,
                selected: false
            }))

            setShowLoader(false)
            dispatch({
                type: IMPORT_QUESTIONS_ACTION_TYPE.SET_QUESTIONS_SEARCH_RESULTS,
                payload: tempSearchResults
            })
        }
    }

    const searchForTextInForms = () => {
        let json = {
            "user_id": formData.user_id,
            "search_text": searchText.trim(),
            "page_index": 0,
            "limit": 20
        }

        setShowNoSearchResults(false)
        setShowLoader(true)
        if (isQuizDashboard || isQuiz) {
            getSearchQuizQuestionData(json, getSearchQuestionDataSuccessFunction, getDataGenericFailureFunction)
        } else {
            getSearchQuestionData(json, getSearchQuestionDataSuccessFunction, getDataGenericFailureFunction)
        }
    }

    const handleSelectQuestionClick = (questionIndex, question) => {
        const maxFileUploadLimit = PremiumJSON[subscriptionData?.subscription_plan]?.file_upload_questions_per_form

        const currentFileUploadQuestionCountInFormData = formData.pages[0].questions?.filter((question) => question.type === QUESTION_TYPE.FILE_UPLOAD.key)?.length
        const selectedFileUploadQuestionCount = (sourceOfQuestionsList === SOURCE_OF_QUESTIONS_LIST.SEARCH_RESULTS) ? questionSearchResults?.filter((question) => question.selected && question.type === QUESTION_TYPE.FILE_UPLOAD.key)?.length : currentForm?.pages[0].questions?.filter((question) => question.selected && question.type === QUESTION_TYPE.FILE_UPLOAD.key)?.length

        if (question.type === QUESTION_TYPE.FILE_UPLOAD.key) {
            // File Upload QT is being added
            if (maxNumberOfFileUploadOTsHasBeenReached(formData.pages[0].questions, subscriptionData) || (currentFileUploadQuestionCountInFormData + selectedFileUploadQuestionCount === maxFileUploadLimit)) {
                if (isMobile) {
                    dispatch({type: PREMIUM_ACTION_TYPE.SET_SHOW_UPGRADE_PLAN_POP_UP, payload: true})
                } else {
                    dispatch({type: PREMIUM_ACTION_TYPE.SET_SHOW_UPGRADE_PLAN_POP_UP, payload: true})
                }
                return
            }
        }
        dispatch({
            type: IMPORT_QUESTIONS_ACTION_TYPE.UPDATE_QUESTION_SELECTED_STATE,
            payload: {
                question_index: questionIndex,
                new_selected_state: true
            }
        })
    }

    const getBackgroundURL = theme => {
        let backgroundTheme
        let formTheme = (theme === 'classic_theme') ? 'classic_new.jpeg' : (theme === 'dark_theme') ? 'dark.jpeg' : theme;

        if (formTheme.startsWith('http')) {
            backgroundTheme = `url('${formTheme}')`
        } else {
            backgroundTheme = "url('https://surveyheartmedia.s3.ap-south-1.amazonaws.com/public/images/thumbnail_web/" + formTheme + "')"
        }

        return backgroundTheme
    }

    return {
        backText,
        checkBoxBlack,
        checkBoxBlue,
        clearSearchInput,
        clearSearchInputImg,
        currentForm,
        dispatch,
        doSearchImage,
        formsList,
        getBackgroundURL,
        handleCloseDialog,
        handleDeselectQuestionClick,
        handleFormCardClick,
        handleImportQuestionsClick,
        handleSelectAllClick,
        handleSelectQuestionClick,
        handleSearchTextChange,
        loadingImage,
        noSearchResultsFound,
        registerGAEvent,
        searchGreyImage,
        searchForTextInForms,
        searchInput,
        searchInputReadOnly,
        searchText,
        searchTextReadonly,
        selectAllText2,
        selectText,
        showForms,
        showFormsList,
        showSearchAndFormsList,
        showLoader,
        showNoSearchResults,
        t,
        translationKeys
    }
}

export default useFormImportMgr