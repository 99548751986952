import axios from "axios";

const token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX21vbmdvX2lkIjoiNWY4OTQ4ZTY5ZDBjYjkwY2Q5ZTVhYjQ5IiwidXNlcl9pZCI6InRlc3RAc3VydmV5aGVhcnQuY29tIiwiaWF0IjoxNjAyODMyNjE3fQ.ZIilmpWsY1U3LSnvdGjd7-Smo98gfv9tCwSGh-MgvG4';
export const makeQuizServerRequest = (requestURL, requestMethod, data, successCallBackFunction, failureCallBackFunction) => {
    const requestConfiguration = {
        method: requestMethod,
        url: requestURL,
        data: data,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'authorization': 'Bearer ' + token
        }
    };
    axios(requestConfiguration)
        .then(
            function (serverResponse) {
                successCallBackFunction(serverResponse.data, serverResponse.status)
            }
        )
        .catch(function (err) {
            console.log(err)
            failureCallBackFunction(err.response)
        })
}
