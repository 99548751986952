import React, {useEffect, useState} from "react";
import IndividualResponseContent from "./IndividualResponseContent";
import '../css/individual-response.css'
import Slider from "../../../shared/components/Slider";
import {RESPONDENT_SORTING_FIELD, sortRespondents} from "../utils/response-summary-sort";
import {useDispatch, useSelector} from "react-redux";
import {RESPONSES_ACTION_TYPE} from "../../reducers/responsesReducer";
import Loader from "../../../shared/components/Loader";
import {isMobileOnly} from "react-device-detect";
import LimitReachedUI from "./LimitReachedUI";

function IndividualContainer(props) {
    const isQuiz = useSelector(state => state.appReducer.isQuiz)
    const responseCount = useSelector(state => state.responsesReducer.responseCount)
    const formResponses = useSelector(state => state.responsesReducer.formResponsesData)
    const quizSourceIsTabular = useSelector(state => state.responsesReducer.quizSourceIsTabular)
    const individualResponseNumber = useSelector(state => state.responsesReducer.individualResponseNumber)
    const [currentResponseNumber, setCurrentResponseNumber] = useState(individualResponseNumber ? individualResponseNumber : 1);
    let responsesData = formResponses?.formResponses;
    const dispatch = useDispatch()

    if (responsesData && !isQuiz || quizSourceIsTabular) {
        responsesData.respondents = sortRespondents(responsesData?.respondents, RESPONDENT_SORTING_FIELD.SUBMIT_TIME)?.reverse()
    }

    useEffect(() => {
        if (!responseCount) {
            dispatch({
                type: RESPONSES_ACTION_TYPE.SET_RESPONSE_COUNT,
                payload: responsesData?.respondents ? responsesData.respondents.length : 1
            })
        }
    }, [])

    if (responseCount) {
        return (
            <React.Fragment>
                <div style={{marginTop: isMobileOnly ? "15%" : null, width: "90%"}}>
                    <Slider currentNumber={currentResponseNumber}
                            setCurrentNumber={setCurrentResponseNumber}
                            maxCount={responsesData?.respondents ? responsesData.respondents.length : 1}/>
                </div>
                <IndividualResponseContent
                    setCurrentResponseNumber={setCurrentResponseNumber}
                    maxCount={responsesData?.respondents ? responsesData.respondents.length : 1}
                    currentResponseNumber={currentResponseNumber && currentResponseNumber > 0 ? currentResponseNumber : 1}
                    bodyContainerElementRef={props.bodyContainerElementRef}
                    removeResponse={props.removeResponse}
                    addResponse={props.addResponse}
                    refreshStorageTotals={props.refreshStorageTotals}
                    quizId={props.quizId}
                />
            </React.Fragment>
        )
    } else if (!responsesData?.respondents?.length) {
        return <LimitReachedUI isQuiz={isQuiz}/>
    } else return <Loader height={"100vh"}/>
}

export default IndividualContainer
