import React, {useEffect, useState} from "react"
import '../css/builder.css'
import MaterialTextField from "../../../shared/components/MaterialTextField";
import MaterialSwitch from "../../../shared/components/MaterialSwitch";
import {translationKeys} from "../../../localizations/translationKeys-localization";
import {LINEAR_SCALE_LABEL_TYPES} from "../../../shared/utils/constants"
import {QUESTION_TYPE} from "../../../shared/utils/constants";
import {LinearScaleStartFromZero} from "./shared/LinearScaleStartFromZero"
import {LinearScaleNumbersAndCircles} from "./LinearScaleNumbersAndCircles"
import {useDispatch, useSelector} from "react-redux"
import useLinearScaleMgr from "../../hooks/useLinearScaleMgr"
import {BUILDER_ACTION_TYPE} from "../../reducers/builderReducer";
import useQuestionMgr from "../../hooks/useQuestionMgr";
import useLogicJumpMgr from "../../hooks/useLogicJumpMgr";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import continueButton from "../../../../public/images/icons/builder/undo_button.svg";
import submitButton from "../../../../public/images/icons/builder/submit_gray_tick_mark.svg";
import FormControl from "@mui/material/FormControl";
import sectionIcon from "../../../../public/images/icons/builder/section_black_icon.svg";
import {PremiumJSON} from "../../../shared/utils/helper";
import {PREMIUM_ACTION_TYPE} from "../../reducers/premiumReducer";
import {useTranslation} from "react-i18next";

const LinearScaleBodyDesktop = React.memo((props) => {
    const {t} = useTranslation()
    const linearScaleLength = useSelector(state => state.appReducer.formData.pages?.[0]?.questions?.[props.index]?.choices?.length)
    const question = useSelector(state => state.appReducer.formData.pages?.[0]?.questions?.[props.index])
    const subscriptionData = useSelector(state => state.userReducer?.subscriptionData)
    const showShared = useSelector(state => state.appDashboardReducer.showShared)
    const formData = useSelector(state => state.appReducer.formData)
    const showLogicJumpBasedOnUser = showShared ? PremiumJSON[subscriptionData?.subscription_plan]?.logic_jump && PremiumJSON[formData?.subscription_data?.current_subscription?.plan]?.logic_jump : PremiumJSON[subscriptionData?.subscription_plan]?.logic_jump
    const {questionRequiredChanged} = useQuestionMgr()
    const {addLogicJumpToQuestion, addTargetQuestionId, generateUniqueString, addLogicQuestionId, selectTargetForTextQuestion, removeLogic} = useLogicJumpMgr()

    const [logicEnabled, setLogicEnabled] = useState(false);
    const [selectedQuestions, setSelectedQuestions] = useState([]);

    const {updateLabel} = useLinearScaleMgr()
    const dispatch = useDispatch()

    useEffect(() => {
        setLogicEnabled(props.question.is_logic_enabled);
    }, [props.question.is_logic_enabled]);

    const disabledSwitchClicked = () => {
        if (!showLogicJumpBasedOnUser) {
            if (showShared && !PremiumJSON[formData?.subscription_data?.current_subscription?.plan]?.logic_jump) {
                dispatch({type: PREMIUM_ACTION_TYPE.SET_SHOW_FREE_OWNER_POP_UP, payload: true})
            } else {
                dispatch({type: PREMIUM_ACTION_TYPE.SET_SHOW_UPGRADE_PLAN_POP_UP, payload: true})
            }
        } else if (formData.pages[0].questions.length <= 1 || formData.pages[0].questions.length - 1 === props.index) {
            dispatch({type: BUILDER_ACTION_TYPE.SET_BUILDER_MESSAGE, payload: 'Action cannot be performed'})
            dispatch({type: BUILDER_ACTION_TYPE.SET_BUILDER_TOAST_MESSAGE, payload: true})
        }
    }


    const setLogic = (index, checkedStatus) => {
        questionRequiredChanged(index, checkedStatus)
        addLogicJumpToQuestion(index, checkedStatus)
        setLogicEnabled(checkedStatus)
        if (!checkedStatus) {
            selectTargetForTextQuestion(index, '')
            removeLogic(index)
            setSelectedQuestions([])
        }
    }

    const handleJumpToQuestion = (choiceIndex, targetQuestionIndex) => {
        const newSelectedValues = [...selectedQuestions];
        newSelectedValues[choiceIndex] = targetQuestionIndex;
        setSelectedQuestions(newSelectedValues);
        if (targetQuestionIndex === "0" || targetQuestionIndex === "-1") {
            addLogicQuestionId(props.index, choiceIndex, targetQuestionIndex)
        } else if (props.formData?.pages[0]?.questions[targetQuestionIndex]?.logic_question_id) {
            addLogicQuestionId(props.index, choiceIndex, props.formData.pages[0].questions[targetQuestionIndex].logic_question_id)
        } else {
            const uniqueString = generateUniqueString();
            addTargetQuestionId(targetQuestionIndex, uniqueString);
            addLogicQuestionId(props.index, choiceIndex, uniqueString)
        }
    }

    const containerHeight = Math.min(linearScaleLength * 60, 360);

    return (
        <div id={'linear-scale-body-container'} style={{marginRight: "20px"}}>
            <LinearScaleStartFromZero index={props.index} mapDispatchToProps={props.mapDispatchToProps}/>
            <LinearScaleNumbersAndCircles index={props.index} mapDispatchToProps={props.mapDispatchToProps}/>
            <div style={{display: "flex", marginTop: "15px"}}>
                <div className='sh-text-field' style={{marginRight: "25px"}}>
                    <MaterialTextField
                        id={`linear-scale-question-label-1`}
                        data={{
                            defaultValue: question.start_label
                        }}
                        customStyleObject={{fontSize: "18px"}}
                        placeholder={t(translationKeys.left_label_optional)}
                        onChange={value => updateLabel(props.index, LINEAR_SCALE_LABEL_TYPES.START, value)}
                        maxLength={100}
                        noLabel={true}
                        hideHelpLine={true}
                        forceUpdateRefVarFocus={false}
                        setForceUpdateRefVarFocus={null}
                    />
                </div>
                <div className='sh-text-field'
                     style={{marginRight: "25px", display: linearScaleLength >= 5 ? 'block' : 'none'}}>
                    <MaterialTextField
                        id={`linear-scale-question-label-2`}
                        data={{
                            defaultValue: question.center_label
                        }}
                        placeholder={t(translationKeys.center_label_optional)}
                        onChange={value => updateLabel(props.index, LINEAR_SCALE_LABEL_TYPES.CENTER, value)}
                        maxLength={100}
                        noLabel={true}
                        hideHelpLine={true}
                        forceUpdateRefVarFocus={false}
                        setForceUpdateRefVarFocus={null}
                    />
                </div>
                <div className='sh-text-field'>
                    <MaterialTextField
                        id={`linear-scale-question-label-3`}
                        data={{
                            defaultValue: question.end_label
                        }}
                        placeholder={t(translationKeys.right_label_optional)}
                        onChange={value => updateLabel(props.index, LINEAR_SCALE_LABEL_TYPES.END, value)}
                        maxLength={100}
                        noLabel={true}
                        hideHelpLine={true}
                        forceUpdateRefVarFocus={false}
                        setForceUpdateRefVarFocus={null}
                    />
                </div>
            </div>
            <div className={'linear-scale-add-logic-jump-container'}>
                <div id={'choice-body-add-logic-jump-option'} className="choice-body-add-logic-jump-option">
                    {t(translationKeys.add_logic)}
                </div>
                <div onClick={disabledSwitchClicked} style={{cursor: "pointer"}}>
                    <MaterialSwitch
                        index={props.index}
                        initialCheckedState={props.question?.is_logic_enabled}
                        switchChanged={setLogic}
                        customStyle={(props.formData?.pages[0].questions.length <= 1 || props.formData?.pages[0].questions.length - 1 === props.index) || (QUESTION_TYPE[props.question?.type] === QUESTION_TYPE.CHECKBOX_CHOICE) ? {cursor: 'not-allowed'} : null}
                        isSwitchDisabled={formData.pages[0].questions.length <= 1 || formData.pages[0].questions.length - 1 === props.index}
                    />
                </div>
            </div>
            {
                logicEnabled && showLogicJumpBasedOnUser &&
                <div style={{height: `${containerHeight}px`, overflowY: 'scroll'}}>
                    {Array.from({length: linearScaleLength}, (_, choiceIndex) => {
                        let questionNumber = 1;

                        return (
                            <div key={choiceIndex} className={'linear-scale-add-logic-jump-row-container'}>
                                {/* Add content or styling here if needed */}
                                <div className={'sh-linear-scale-logic-container'}>
                                    <div className={'sh-circle-linear-body'}></div>
                                    <div className='sh-text-field' style={{marginBottom: '15px'}}>
                                        <MaterialTextField
                                            id={`linear-scale-question-text-input-${props.index}`}
                                            data={{
                                                defaultValue: formData?.pages[0]?.questions[props.index]?.choices[choiceIndex].label,
                                                isReadOnly: true
                                            }}
                                            maxLength={2}
                                            noLabel={true}
                                            hideHelpLine={true}
                                            // handleFocus={() => handleOptionFocus(choiceIndex)}
                                        />
                                    </div>
                                </div>
                                <div className={'linear-scale-option-logic-question-container'}>
                                    <p className={'linear-scale-logic-jump-to-label'}>Jump To</p>
                                    <div className={'choice-body-add-logic-dropdown-container'}>
                                        <FormControl variant="standard"
                                                     sx={{m: 1, minWidth: 220, margin: 0, width: '100%'}}>
                                            <Select
                                                labelId={`select-question-helper-label-${choiceIndex}`}
                                                id={`select-question-helper-${choiceIndex}`}
                                                label="Select Question"
                                                MenuProps={{
                                                    style: {width: '280px'}, // to Set the width of the dropdown menu
                                                }}
                                                value={selectedQuestions[choiceIndex] || (props?.question?.choices[choiceIndex]?.target_question_id !== ("0" || "-1") ? props?.formData?.pages[0]?.questions.findIndex((question) => props?.question?.choices[choiceIndex]?.target_question_id === question.logic_question_id) : props?.question?.choices[choiceIndex]?.target_question_id)}
                                                onChange={(e) => handleJumpToQuestion(choiceIndex, e.target.value)}
                                            >
                                                <MenuItem value="0"><span
                                                    className={'logic-jump-question-to-select'}><img
                                                    src={continueButton} alt={'continue to next question icon'}/> {t(translationKeys.continue_to_next_question)}</span></MenuItem>
                                                <MenuItem value="-1"><span
                                                    className={'logic-jump-question-to-select'}><img
                                                    src={submitButton}
                                                    alt={'submit form  icon'}/> {t(translationKeys.submit_form)}</span></MenuItem>
                                                {props.formData.pages[0].questions.map((object, index) => {
                                                    if (object.type === 'SECTION') {
                                                        return (
                                                            index > props.index &&
                                                            (<MenuItem value={index}>
                                                                    <span
                                                                        className={'logic-jump-question-to-select'}><img
                                                                        src={sectionIcon}
                                                                        alt={'section icon'}/> {object.title}</span>
                                                            </MenuItem>))
                                                    } else {
                                                        const questionContent = `${questionNumber}. ${object.title}`;
                                                        questionNumber++;
                                                        return (
                                                            index > props.index &&
                                                            (<MenuItem value={index}> <span
                                                                className={'logic-jump-question-to-select'}>{questionContent}</span></MenuItem>))
                                                    }
                                                })}
                                            </Select>
                                        </FormControl>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
            }
        </div>
    )
})

export default LinearScaleBodyDesktop