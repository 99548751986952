import React, {useRef, useState} from 'react'
import {getDateWithTime} from "../../../shared/utils/get-date-with-time";
import {AWS_FOLDER_NAMES, MEDIA_BASE_URL} from "../../builder/utils/aws"
import {useTranslation} from "react-i18next";
import {translationKeys} from "../../../localizations/translationKeys-localization";
import {useSelector} from "react-redux";

const SummaryTableRow = (props) => {
    const selectedLanguage = useSelector(state => state.languageReducer?.selectedLanguage)
    const {t} = useTranslation()
    const [rowHeight, setRowHeight] = useState(undefined);
    const responseTextCell = useRef()

    const handleClick = () => {
        if (responseTextCell.clientHeight > 45) {
            setRowHeight(responseTextCell.clientHeight)
        } else {
            setRowHeight(45)
        }
    }

    const isDescOrder = props.sortOrder;
    const textResponse = props.textResponse;

    return (
        <table>
            <tbody id='summary-table-row'>
        <tr className='table-row' onMouseOver={handleClick}
            onMouseLeave={() => setRowHeight(undefined)}
            style={(props.index % 2) === 0 ? {
                    backgroundColor: '#f8fBff',
                    height: rowHeight ? rowHeight : "45px"
                }
                : {backgroundColor: '#fff', height: rowHeight ? rowHeight : '45px'}}>
            <td className='table-body-cell'
                style={{width:  '70px'}}>
                    <span id='table-body-cell-response-count' style={{minWidth: '0', padding:  '5px 10px'}}
                          className='table-body-cell-span'>{isDescOrder ? (props.responsesCount - props.index) : props.index + 1}</span>
            </td>
            <td className='table-body-cell' style={{
                width: '210px'
            }} dir={(selectedLanguage === 'fa' || selectedLanguage === 'ar') ? "ltr" : null}>
                    <span id='table-body-cell-date-time' style={{fontSize: '14px', minWidth: '0'}}
                          className='response-time-printing table-body-cell-span'>{getDateWithTime(props.responseTime)}</span>
            </td>
            <td className='table-body-cell' style={{
                width: props.textResponseWidth ? props.textResponseWidth : 'auto',
                justifyContent: 'flex-start'
            }}><span id='table-body-cell-text-response' className='table-body-cell-span'
                     ref={responseTextCell}
                     onClick={
                         textResponse && textResponse.toString().startsWith(`${MEDIA_BASE_URL}/${AWS_FOLDER_NAMES.FILES_FOLDER}`) ? (e) => {
                             e.stopPropagation();
                             open(textResponse)
                         } : null
                     }
            >{textResponse && textResponse.length > 0 ? textResponse : t(translationKeys.no_answer)}</span>
            </td>
        </tr>
            </tbody>
        </table>
    );
}


export default SummaryTableRow;