import React, {useEffect, useRef, useState} from "react"
import {useSelector} from "react-redux"
import MaterialTextField from "../../../shared/components/MaterialTextField"
import useGridMgr from "../../hooks/useGridMgr"
import {translationKeys} from "../../../localizations/translationKeys-localization"
import {useTranslation} from "react-i18next"

const GridBodyRows = (props) => {
    const {t} = useTranslation()
    const {
        addAnimationStyles,
        COMPONENT_TYPE,
        handleItemBlur,
        setHeightAndOverflowStatus,
        updateItemText
    } = useGridMgr()

    const [heightInitComplete, setHeightInitComplete] = useState(false)
    const [toggleRefresh, setToggleRefresh] = useState(false)

    const rows = useSelector(state => state.appReducer.formData?.pages?.[0]?.questions?.[props.index]?.rows)

    // refRows is needed to support animation in showing the rows. So, we can reference 'rows' directly.
    const refRows = useRef([])
    const refAnimationInitialized = useRef(false)

    useEffect(() => {
        refRows.current = rows?.slice(0)
        setToggleRefresh(!toggleRefresh)
    }, [rows?.length])

    useEffect(() => {
        const rowDiv = document.querySelector(`#row-text-${props.index}-0`)

        if (rowDiv && !refAnimationInitialized.current) {
            addAnimationStyles(props.index, COMPONENT_TYPE.ROW, rowDiv, refAnimationInitialized, rows.length)
        }

        if (rowDiv && !heightInitComplete) {
            setHeightAndOverflowStatus(props.index, COMPONENT_TYPE.ROW, rowDiv, rows)
            setHeightInitComplete(true)
        }
    }, [refRows.current?.length])

    return (
        <div style={{width: "100%", paddingRight: '10px'}}>
            <div style={{fontWeight: '700', display: "flex"}}>{t(translationKeys.rows)}</div>
            <div style={{paddingRight: '10px'}} id={`mcg-rows-${props.index}`}>
                {
                    (() => {
                            return (
                                refRows?.current?.map((row, index) => {
                                    return (
                                        <div key={index} id={`row-text-${props.index}-${index}`}
                                             className="star-line">
                                            <div id={`row-number-${index}`} className="grid-line-center">{index + 1}.
                                            </div>
                                            <div className='builder-question-text-input'>
                                                <div className='sh-text-field' style={{marginBottom: "15px"}}>
                                                    <MaterialTextField id={`row-text-${props.index}-input-${index}`}
                                                                       index={index}
                                                                       data={{
                                                                           // Note: we can't use 'defaultValue: row.label' here because we need a reference to the actual label in rows as we are looping through refRows.current
                                                                           defaultValue: rows[index]?.label
                                                                       }}
                                                                       noLabel={true}
                                                                       onChange={(newValue) => {
                                                                           updateItemText(props.index, COMPONENT_TYPE.ROW, index, newValue)
                                                                           setToggleRefresh(!toggleRefresh)
                                                                       }}
                                                                       maxLength={50}
                                                                       highlightText={false}
                                                                       removeHighlightText={false}
                                                                       handleFocus={null}
                                                                       onBlur={() => {
                                                                           handleItemBlur(props.index, COMPONENT_TYPE.ROW, index)
                                                                           setToggleRefresh(!toggleRefresh)
                                                                       }}
                                                                       validateURL={false}
                                                    />
                                                </div>
                                            </div>
                                            {props.buttons}
                                        </div>
                                    )
                                })
                            )
                        }
                    )()
                }
            </div>
        </div>
    )
}

export default GridBodyRows

