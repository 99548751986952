import React, {useEffect, useState} from 'react';
import {ThankCardComponent} from "./ThankCardComponent";
import {SummaryDataSetProvider} from "../../app/responses/components/SummaryDataSetContext";
import ResponsesFormTitleContainer from "../../app/responses/components/ResponsesFormTitleContainer";
import SummaryContainer from "../../app/responses/components/SummaryContainer";
import Loader from "../../shared/components/Loader";
import {isMobileOnly} from "react-device-detect";
import GoogleAds from "../../shared/components/GoogleAds";
import {ThankPageArticleContainer} from "./ThankPageArticleContainer";
import {useSelector} from "react-redux";
import axios from "axios";
import {PremiumJSON} from "../../shared/utils/helper";

const promoURL = "https://nucleapp.s3.ap-south-1.amazonaws.com/assets/promotion/json/nucleapp_sh_promo.json?nocache=" + new Date().getTime().toString();
export default function ThankPageContainer(props) {
    const preview = useSelector(state => state.screensReducer.preview)
    const formData = useSelector(state => state.formDataReducer.formData)
    const fillerSummary = useSelector(state => state.screensReducer.fillerSummary)
    const [formResponsesData, setFormResponsesData] = useState(undefined)
    const [showNucleAppPromo, setShowNucleAppPromo] = useState(false)
    const [nucleAppJSON, setNucleAppJSON] = useState(undefined)
    const [summaryDataSet, setSummaryDataSet] = useState(undefined)
    const userAgent = navigator.userAgent || navigator.vendor

    const getNucleAppPromoJSON = async () => await new Promise(resolve => {
        resolve(axios.get(promoURL))
    })

    useEffect(() => {
        getNucleAppPromoJSON().then(res => {
            if (res?.data) {
                setNucleAppJSON(res.data)
                setShowNucleAppPromo(true)
            }
        }).catch(err => console.log(err))
    }, [])

    const handleNucleAppClick = () => {
        if (/iPad|iPhone|iPod/.test(userAgent)) {
            window.location = nucleAppJSON.ios_url
        } else if (/android/i.test(userAgent)) {
            window.location = nucleAppJSON.android_url
        } else {
            window.location = nucleAppJSON.web_url
        }
    }

    if (fillerSummary && formData?.setting.allow_summary_view) {
        return (<>
                {formResponsesData && summaryDataSet ?
                    <SummaryDataSetProvider summaryDataSet={summaryDataSet}>
                        <div style={{backgroundColor: '#1976D2', height: '30%'}}>
                            <ResponsesFormTitleContainer title={formData?.welcome_screen.title}/>
                            <SummaryContainer formResponses={formResponsesData}
                                              formFiller={true}/>
                        </div>
                    </SummaryDataSetProvider> : <Loader width={'100%'} height={'100%'}/>
                }
            </>
        )
    } else {
        const adData = {
            adClient: "ca-pub-2987317143669509",
            adSlot: formData?.is_quiz ? "8705253276" : "4220236837"
        };
        return (
            <div style={{
                display: 'flex',
                position: 'relative',
                height: isMobileOnly ? 'auto' : '100%',
                width: '100%',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                overflow: "hidden"
            }}>
                {formData?.is_quiz ? <ThankCardComponent {...props}/> :
                    <ThankCardComponent {...props} formResponsesData={formResponsesData}
                                        setSummaryDataSet={setSummaryDataSet}
                                        setFormResponsesData={setFormResponsesData}/>
                }
                {/*Displaying Ad here*/}
                {isMobileOnly && !preview && !props.previewType && (PremiumJSON[formData?.subscription_data?.current_subscription?.plan]?.advertising || !formData?.subscription_data?.current_subscription || !formData?.subscription_data) ?
                    <GoogleAds adClient={adData.adClient} adSlot={adData.adSlot}/> : null
                }
                {/*NucleApp Promo*/}
                {showNucleAppPromo && nucleAppJSON && isMobileOnly && !props.previewType && !preview && (PremiumJSON[formData?.subscription_data?.current_subscription?.plan]?.advertising || !formData?.subscription_data?.current_subscription || !formData?.subscription_data) ?
                    <img alt={"nucle-app"}
                         width={'95%'}
                         crossOrigin={"anonymous"}
                         src={nucleAppJSON?.image_url}
                         onClick={handleNucleAppClick}/> : null}
                {isMobileOnly && !props.previewType && !preview && (PremiumJSON[formData?.subscription_data?.current_subscription?.plan]?.advertising || !formData?.subscription_data?.current_subscription || !formData?.subscription_data) ?
                    <ThankPageArticleContainer/> : null}
            </div>
        )
    }
}
