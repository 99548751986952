import {QUESTION_TYPE} from "../../../shared/utils/constants";

/**
 *
 * @param questions
 * @param responses
 * @returns {{total: number, correct: number, correct_percentage: (string|number), wrong: number, skipped: number}}
 */
const calculateQuizV2Result = (questions, responses) => {
    let totalMarks = 0
    let attainedMarks = 0;
    let skippedMarks = 0;
    let wrongAnswerMarks = 0;
    let currentAnswer;
    let currentQuestion;
    let isSkippedQuestion;

    function assignMark(validatedIndex, attainedMarks, currentQuestion, j, wrongAnswerMarks) {
        if (validatedIndex === 1) {
            attainedMarks += parseInt(currentQuestion.marks);
            responses[j].marks = parseInt(currentQuestion.marks);
        } else if (validatedIndex === 0) {
            wrongAnswerMarks += parseInt(currentQuestion.marks);
            responses[j].marks = 0;
        }
        return {attainedMarks, wrongAnswerMarks};
    }

    for (let i = 0; i < questions.length; i++) {
        currentQuestion = questions[i];
        if (currentQuestion.type === QUESTION_TYPE.SECTION.key) {
            continue;
        }
        totalMarks += parseInt(currentQuestion.marks)
        isSkippedQuestion = true;

        for (let j = 0; j < responses.length; j++) {
            currentAnswer = responses[j];
            if ((currentQuestion._id).toString() === currentAnswer.question_id) {
                if (currentAnswer.type === QUESTION_TYPE.MULTIPLE_CHOICE.key
                    || currentAnswer.type === QUESTION_TYPE.DROPDOWN_CHOICE.key) {
                    if (currentAnswer.choice && currentAnswer.choice.length > 0) {
                        isSkippedQuestion = false;
                        const validatedIndex = currentAnswer.choice ? evaluateChoiceAnswer(currentQuestion.choices, currentAnswer.choice) : 0;
                        const assignedMarks = assignMark(validatedIndex, attainedMarks, currentQuestion, j, wrongAnswerMarks);
                        attainedMarks = assignedMarks.attainedMarks;
                        wrongAnswerMarks = assignedMarks.wrongAnswerMarks;
                    } else {
                        responses[j].marks = 0;
                    }
                } else if (currentAnswer.type === QUESTION_TYPE.SHORT_TEXT.key) {
                    attainedMarks += parseInt(currentAnswer.marks);
                } else if (currentQuestion.type === QUESTION_TYPE.LONG_TEXT.key || currentQuestion.type === QUESTION_TYPE.FILE_UPLOAD.key) {
                    if (currentAnswer.marks) {
                        attainedMarks += parseInt(currentAnswer.marks);
                    }
                }
                break;
            }
        }
        if (isSkippedQuestion) {
            skippedMarks += parseInt(currentQuestion.marks);
        }
    }
    return ({
        total: totalMarks,
        correct: attainedMarks,
        correct_percentage: calculatePercentage(attainedMarks, totalMarks),
        wrong: wrongAnswerMarks,
        skipped: skippedMarks
    })
}

export default calculateQuizV2Result

function evaluateChoiceAnswer(choices, choiceId) {
    let currentChoice;
    for (let j = 0; j < choices.length; j++) {
        currentChoice = choices[j];
        if ((currentChoice._id).toString() === choiceId
            && currentChoice.is_answer) {
            return 1;
        }
    }
    return 0;
}

function evaluateTextAnswer(correctAnswer, actualAnswer) {
    if (correctAnswer && correctAnswer.toLowerCase() === actualAnswer.toLowerCase()) {
        return 1;
    }
    return 0;
}

function calculatePercentage(number, total) {
    if (number
        && Number.isFinite(number)
        && number > -1
        && total
        && Number.isFinite(total)
        && total > 0) {
        let result = (number / total) * 100;
        if (result !== 100
            && result !== 0) {
            return result.toFixed(1);
        }
        return result;
    }
    return 0;
}