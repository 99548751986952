import React, {useEffect, useState} from 'react'
import '../css/export.css'
// import googleSheet from '../../../../public/images/export_sheets.svg'
// import googleSheetDisconnected from '../../../../public/images/google_sheet_disconnected.svg'
import xlsx from '../../../../public/images/export_xlsx.svg'
import pdf from '../../../../public/images/export_pdf.svg'
import csv from '../../../../public/images/export_csv.svg'
import {downloadAnswersAsFile} from "../../../shared/utils/quiz-api-services";
import {
    // createGoogleAuthLink, createGoogleSheet,
    downloadResponsesAsFile,
    // getGoogleSheetAccounts,
    TYPE
} from "../../../shared/utils/sash-v2-api";
import Loader from "../../../shared/components/Loader";
import {useTranslation} from "react-i18next";
import {translationKeys} from "../../../localizations/translationKeys-localization";
import {useDispatch, useSelector} from "react-redux";
import {RESPONSES_ACTION_TYPE} from "../../reducers/responsesReducer";
import {isMobileOnly} from "react-device-detect";
import ModalDialog from "../../../shared/components/ModalDialog";
import warningIcon from "../../../../public/images/icons/warning_icon.svg";
// import {registerGAEvent} from "../../../shared/utils/analytics";

function ExportCard(props) {
    const {t} = useTranslation()
    const isQuiz = useSelector(state => state.appReducer.isQuiz)
    // const userId = useSelector(state => state.userReducer?.userId)
    // const isRevoked = useSelector(state => state.responsesReducer.isRevoked)
    const selected = useSelector(state => state.languageReducer.selectedLanguage)
    // const spreadSheetId = useSelector(state => state.responsesReducer.googleSheetId)
    const formResponses = useSelector(state => state.responsesReducer.formResponsesData)
    // const isSheetConnected = useSelector(state => state.responsesReducer.isSheetConnected)
    const ExportCard = React.createRef();
    const [isLoading, setIsLoading] = useState(false);
    const [notConnectedPopup, setNotConnectedPopup] = useState(false);
    const dispatch = useDispatch()
    const closeExportPopUp = () => {
        dispatch({type: RESPONSES_ACTION_TYPE.SET_SHOW_EXPORT, payload: false})
    }
    useEffect(() => {
        props.previouslyOpenedCardCloseFunction?.current && props.previouslyOpenedCardCloseFunction.current !== closeExportPopUp ? props.closePreviouslyOpenedCard(closeExportPopUp) : null
        document.onmousedown = e => {
            if (ExportCard.current && !ExportCard.current.contains(e.target)) {
                closeExportPopUp();
                document.onmousedown = null;
            }
        }
    }, [])

    const downloadFile = (fileType) => {
        setIsLoading(true);
        const successFunction = res => {
            const url = window.URL.createObjectURL(new Blob([res.data], {type: ((fileType === TYPE.PDF) ? 'application/pdf' : 'application/vnd.ms-excel')}));
            const link = document.createElement('a');
            link.href = url;
            const fileName = (fileType === TYPE.EXCEL) ? formResponses.formData.welcome_screen.title + '.xlsx' : (fileType === TYPE.CSV) ? formResponses.formData.welcome_screen.title + '.csv' : (fileType === TYPE.PDF) ? formResponses.formData.welcome_screen.title + '.pdf' : formResponses.formData.welcome_screen.title;
            link.id = fileName;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
            document.getElementById(fileName).remove();
            setIsLoading(false);
            closeExportPopUp()
        }
        const failureFunction = err => {
            setIsLoading(false);
            closeExportPopUp()
            console.log(err);
            alert('Something Went Wrong, try download after sometimes');
        }
        if (isQuiz) {
            downloadAnswersAsFile(fileType, formResponses.formData._id, successFunction, failureFunction)
        } else {
            downloadResponsesAsFile(fileType, formResponses.formData._id, successFunction, failureFunction)
        }

    };

  /*  const firstTimeConnectingWithGoogleSheets = () => {
        const checkForTokenSaved = setInterval(() => {
            const googleAccountId = window.localStorage.getItem("googleAccountId")
            if (window.localStorage.getItem("googleSheet") === "SUCCESS" && googleAccountId) {
                const successFunction = (res) => {
                    if (res.google_sheets_tokens.length === 1) {
                        createGoogleSheet(userId, formResponses.formData._id, googleAccountId, new Date().getTimezoneOffset(), (res) => {
                            if (res.result) {
                                registerGAEvent('Google-Sheet', 'Connected', 'google-sheet-connected')
                                dispatch({type: RESPONSES_ACTION_TYPE.SET_GOOGLE_SHEET_ID, payload: res.spreadsheet_id})
                                dispatch({type: RESPONSES_ACTION_TYPE.SET_SHOW_GOOGLE_SHEET_POP_UP, payload: true})
                                dispatch({type: RESPONSES_ACTION_TYPE.SET_IS_SHEET_CONNECTED, payload: true})
                                dispatch({type: RESPONSES_ACTION_TYPE.SET_IS_REVOKED, payload: false})
                                setIsLoading(false)
                            }
                        }, (err) => {
                            console.log(err)
                        })
                    }
                    window.localStorage.setItem("googleSheet", "false")
                    window.localStorage.setItem("googleAccountId", null)
                    clearInterval(checkForTokenSaved)
                }
                const failureFunction = (err) => {
                    console.log(err)
                    clearInterval(checkForTokenSaved)
                }
                getGoogleSheetAccounts(successFunction, failureFunction)
            }
        }, 1000)
        createGoogleAuthLink((res) => {
            window.localStorage.setItem("userId", userId)
            window.localStorage.setItem("googleSheet", "false")
            window.open(res.url, "_blank", "width=500,height=700")
        }, (err) => {
            console.log(err)
        })
    }*/

    /*const googleSheetsHandler = () => {
        setIsLoading(true)
        if (formResponses.formData.spreadsheet_data && !formResponses.formData.spreadsheet_data?.is_connected && isRevoked && userId === formResponses.formData.user_id) {
            dispatch({type: RESPONSES_ACTION_TYPE.SET_SHEET_DISCONNECTED_POP_UP, payload: true})
            dispatch({type: RESPONSES_ACTION_TYPE.SET_SHOW_EXPORT, payload: false})
            return
        }
        if (isSheetConnected) {
            dispatch({type: RESPONSES_ACTION_TYPE.SET_GOOGLE_SHEET_ID, payload: spreadSheetId ? spreadSheetId : formResponses.formData.spreadsheet_data.spreadsheet_id})
            dispatch({type: RESPONSES_ACTION_TYPE.SET_SHOW_GOOGLE_SHEET_POP_UP, payload: true})
            dispatch({type: RESPONSES_ACTION_TYPE.SET_SHOW_EXPORT, payload: false})
            setIsLoading(false)
        } else {
            const successFunction = (res) => {
                if (res.result === "Google sheet for this user is not yet integrated" || res.google_sheets_tokens.length === 0) {
                    firstTimeConnectingWithGoogleSheets()
                } else if (res.google_sheets_tokens.length > 0) {
                    dispatch({type: RESPONSES_ACTION_TYPE.SET_SHOW_CHOOSE_ACCOUNT_POP_UP, payload: true})
                    dispatch({
                        type: RESPONSES_ACTION_TYPE.SET_GOOGLE_SHEETS_DATA,
                        payload: res.google_sheets_tokens
                    })
                    dispatch({type: RESPONSES_ACTION_TYPE.SET_SHOW_EXPORT, payload: false})
                    setIsLoading(false)
                }
            }
            const failureFunction = (err) => {
                if (err.data.result === "Google sheet for this user is not yet integrated") {
                    firstTimeConnectingWithGoogleSheets()
                } else {
                    console.log(err)
                }
            }
            if (userId !== formResponses.formData.user_id) {
                setNotConnectedPopup(true)
                setIsLoading(false)
            } else {
                getGoogleSheetAccounts(userId, successFunction, failureFunction)
            }
        }
    }*/

    return (<div ref={ExportCard} className='responses-export-popup-container'
                 style={!isMobileOnly && (selected === 'fa' || selected === 'ar') ? {right: '8%', left: 'auto'} : null}>
            <div id='export-card' style={{position: 'relative', width: '100%', height: '100%'}}>
                <div className='export-header-container'>
                    <span
                        style={(selected === 'fa' || selected === 'ar') ? {marginRight: '10px'} : {marginLeft: '10px'}}>{t(translationKeys.download_as)}</span>
                </div>
                <div style={{padding: "10px"}}>
                    {/*{isQuiz ? <>*/}
                            <div className='export-body-container'>
                                <div className='each-export-type-container'>
                                    <a onClick={() => downloadFile(TYPE.EXCEL)}>
                                        <img alt='xlsx' src={xlsx}/>
                                    </a>
                                    <span>.XLSX</span>
                                </div>
                                <div className='each-export-type-container'>
                                    <a onClick={() => downloadFile(TYPE.PDF)}
                                       download target='blank'>
                                        <img alt='pdf' src={pdf}/>
                                    </a>
                                    <span>.PDF</span>
                                </div>
                                <div className='each-export-type-container' style={{borderColor: 'transparent'}}>
                                    <a onClick={() => downloadFile(TYPE.CSV)}>
                                        <img alt='csv' src={csv}/>
                                    </a>
                                    <span>.CSV</span>
                                </div>
                            </div>
                        {/*</>
                        : <>
                            <div className='export-body-container'>
                                <div className='each-export-type-container'
                                     style={{borderRight: "solid 1px #e0e0e0", borderBottom: "solid 1px #e0e0e0"}}>
                                    <a onClick={() => googleSheetsHandler()}>
                                        <img alt='google-sheet'
                                             src={formResponses?.formData?.spreadsheet_data && !formResponses?.formData?.spreadsheet_data?.is_connected && isRevoked ? googleSheetDisconnected : googleSheet}/>
                                    </a>
                                    <span>Google Sheet</span>
                                </div>
                                <div className='each-export-type-container'>
                                    <a onClick={() => downloadFile(TYPE.EXCEL)}>
                                        <img alt='xlsx' src={xlsx}/>
                                    </a>
                                    <span>.XLSX</span>
                                </div>
                            </div>
                            <div className='export-body-container'>
                                <div className='each-export-type-container'>
                                    <a onClick={() => downloadFile(TYPE.PDF)}
                                       download target='blank'>
                                        <img alt='pdf' src={pdf}/>
                                    </a>
                                    <span>.PDF</span>
                                </div>
                                <div className='each-export-type-container' style={{
                                    margin: "1px 0 0 -1px",
                                    borderLeft: "solid 1px #e0e0e0", borderTop: "solid 1px #e0e0e0"
                                }}>
                                    <a onClick={() => downloadFile(TYPE.CSV)}>
                                        <img alt='csv' src={csv}/>
                                    </a>
                                    <span>.CSV</span>
                                </div>
                            </div>
                        </>
                    }*/}
                </div>
                {isLoading ? <Loader/> : null}
                {notConnectedPopup ?
                    <ModalDialog
                        imageIcon={warningIcon}
                        header={t(translationKeys.sheet_not_connected)}
                        body={[t(translationKeys.sheet_not_connected_desc)]}
                        buttons={
                            [
                                {
                                    text: t(translationKeys.ok),
                                    action: () => {
                                        dispatch({type: RESPONSES_ACTION_TYPE.SET_SHOW_EXPORT, payload: false})
                                        setNotConnectedPopup(false)
                                    }
                                }
                            ]
                        }
                    /> : null}
            </div>
        </div>
    )
}

export default ExportCard
