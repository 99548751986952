import React from "react"
import {isMobileOnly} from "react-device-detect";
import {ANSWER_EVENT} from "./QuizBuilderContainer"
import MaterialTextField, {TEXT_FIELD_TYPES} from "../../../shared/components/MaterialTextField";
import {translationKeys} from "../../../localizations/translationKeys-localization";
import {t} from "i18next";
import {useSelector} from "react-redux";

const ChoiceQuestionCorrectAnswerAndMarks = (props) => {
    const selectedLanguage = useSelector(state => state.languageReducer.selectedLanguage)
    const handleMarksChange = (newValue) => {
        if (newValue.length < 4) {
            props.updateAnswers(ANSWER_EVENT.UPDATE_MARKS, {questionIndex: props.index, newValue: newValue})
        }
    }
    const selectedAnswerText = isMobileOnly ? (props.selectedAnswerText?.length > 16) ? (props.selectedAnswerText.slice(0, 15) + '...') : props.selectedAnswerText
        : (props.selectedAnswerText?.length > 50) ? (props.selectedAnswerText.slice(0, 49) + '...') : props.selectedAnswerText;

    const markInputCustomStyleObject = {
        '--mdc-theme-primary': '#9e9e9e',
        width: selectedLanguage === 'ta' || selectedLanguage === 'ml' ? '120px' : selectedLanguage === 'te' ? '90px' : '75px',
        textAlign: 'center'
    };

    return (
        <div id={'quiz-choice-answer-marks-wrapper'} className="quiz-choice-answer-marks-wrapper">
            <div id={'quiz-choice-answer-marks'} className="quiz-choice-answer-marks-box1">
                {t(translationKeys.enter_answer)} :
            </div>
            {
                (() => {
                        if (!selectedAnswerText)
                            return <div className="quiz-choice-answer-marks-box2 quiz-choice-answer-not-specified">
                                {t(translationKeys.not_specified)}
                            </div>

                        if (selectedAnswerText)
                            return <div className="quiz-choice-answer-marks-box2 quiz-choice-answer-specified">
                                {selectedAnswerText}
                            </div>
                    }
                )()
            }

            <div className="quiz-choice-answer-marks-box3">
                <div id={'quiz-choice-answer-mark-button'} style={{marginRight: '5px'}}>
                    <MaterialTextField
                        data={{
                            hint: t(translationKeys.marks),
                            inputType: 'number',
                            defaultValue: props.marks === undefined ? String(1) : String(props.marks)
                        }}
                        onChange={handleMarksChange}
                        textFieldType={TEXT_FIELD_TYPES.OUTLINED}
                        customStyleObject={markInputCustomStyleObject}
                        index={props.index}
                        maxLength={3}
                    />
                </div>
            </div>
        </div>
    )
}

export default ChoiceQuestionCorrectAnswerAndMarks