// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1DumrtsySzLvUT0bD4CXlO {\n    display: flex;\n    height: auto;\n    top: 0;\n    width: 100%;\n    align-items: center;\n    justify-content: center;\n    flex-direction: column;\n    margin-top: 8px;\n    position: relative;\n}\n\n._2jvo50QCY6md5XH-ZWVnvF {\n    max-width: 70%;\n    max-height: 80px;\n    margin: 16px 0 8px;\n    display: none;\n}\n\n._2yOInpuXeQ3kv5bRceVjFQ {\n    max-width: 80%;\n    font-size: 28px;\n    font-weight: bold;\n    text-transform: capitalize;\n    text-shadow: 1px 1px rgba(0, 0, 0, 0.4);\n    text-align: center;\n    color: #ffffff;\n    margin: 16px;\n    overflow-x: auto;\n}\n\n._2tAjkgMZfZe5qi0UiFDiGT {\n    max-width: 50%;\n    max-height: 125px;\n    margin-bottom: 15px;\n    text-shadow: 1px 1px rgba(0, 0, 0, 0.4);\n    text-align: center;\n    font-size: 20px;\n    overflow: auto;\n    color: #eeeeee;\n    line-height: 1.5;\n}\n\n._2tAjkgMZfZe5qi0UiFDiGT::-webkit-scrollbar {\n    display: none;\n}\n\n._1fRLQvw9u6F7tpjJo0hP87 {\n    height: 90px;\n    display: flex;\n    max-width: 770px;\n    min-width: 300px;\n    width: -webkit-fill-available;\n    justify-content: space-between;\n    background: rgba(0, 0, 0, 0.4);\n}\n\n._1fRLQvw9u6F7tpjJo0hP87 p {\n    width: 100%;\n    color: #ffffff;\n    margin: auto 5px;\n    font-size: medium;\n    padding: 2px 10px;\n    border-radius: 2px;\n    text-align: center;\n}\n\n._1fRLQvw9u6F7tpjJo0hP87 p span {\n    font-size: large;\n    font-weight: bold;\n}\n\n@media screen and (max-width: 600px) {\n    ._1fRLQvw9u6F7tpjJo0hP87 {\n        height: auto;\n        margin: 0 16px;\n        flex-direction: column;\n    }\n\n    ._1fRLQvw9u6F7tpjJo0hP87 p {\n        width: 50%;\n        margin: 10px auto;\n    }\n\n    ._2yOInpuXeQ3kv5bRceVjFQ {\n        max-width: 90%;\n    }\n\n    ._2tAjkgMZfZe5qi0UiFDiGT {\n        max-width: 90%;\n    }\n}\n", ""]);
// Exports
exports.locals = {
	"fixed_navigation_top": "_1DumrtsySzLvUT0bD4CXlO",
	"form_logo": "_2jvo50QCY6md5XH-ZWVnvF",
	"form_title": "_2yOInpuXeQ3kv5bRceVjFQ",
	"form_desc": "_2tAjkgMZfZe5qi0UiFDiGT",
	"form_header_data": "_1fRLQvw9u6F7tpjJo0hP87"
};
module.exports = exports;
