import React, {useEffect, useRef, useState} from 'react';
import FormHeaderComponent from "./FormHeaderComponent";
import {FormQuestionContainer} from "./FormQuestionContainer";
import FormFooterComponent from "./FormFooterComponent";
import {
    ACTION_TO_TAKE_BASED_ON_USER_STORAGE_LEVEL,
    FORM_QUIZ_RESPONSE_USER_STORAGE_LOAD_ACTION,
    QUESTION_TYPE
} from '../../shared/utils/constants'
import {FormFloatButtonContainer} from "./FormFloatButtonContainer";
import {FormSubmitContainer} from "./FormSubmitContainer";
import SurveyHeartContentPopup from "./SurveyHeartContentPopup";
import {QuizTimer} from "./QuizTimer";
import style from "../css/form-page.module.css"
import '../../app/builder/css/themes.css'
import QuizzoryContentPopup from "./QuizzoryContentPopup";
import {AWS_FOLDER_NAMES, AWS_UPLOAD_TYPE, deleteFileAws, setAwsFolderName} from "../../app/builder/utils/aws"
import {uploadImageFile} from "../../app/builder/utils/upload-file-to-aws"
import Loader from "../../shared/components/Loader"
import {shuffleArray, shuffleQuestions} from "../utils/shuffle-questions";
import cryptoJS from "crypto-js"
import {
    getActionToTakeBasedOnUserStorageLevel,
    getUserStorageActionToTakeWhileFormOrQuizIsLoading
} from "../../app/responses/utils/form-page-helper"
import OutOfSpacePage from "../../shared/components/OutOfSpacePage"
import {useDispatch, useSelector} from "react-redux";
import {FORM_DATA_ACTION_TYPE} from "../reducers/formDataReducer";
import {SCREENS_ACTION_TYPE} from "../reducers/screensReducer";
import {QUESTIONS_ACTION_TYPE} from "../reducers/questionsReducer";
import {
    answeredQuestionsCount,
    createFormResponse,
    createFormResponsesArray
} from "../utils/form-response";
import AbuseWarningComponent from "./AbuseWarningComponent";
import {Pages} from "../../app/utils/pages";
import ClearFormComponent from "./ClearFormComponent";
import {FormTimer} from "./FormTimer";
import {ACTION_TYPE} from "../utils/utils";

export default function FormPage(props) {

    const [showFloatButton, setShowFloatButton] = useState(false)
    const formData = useSelector(state => state.formDataReducer.formData)
    const storageUsed = useSelector(state => state.formDataReducer.storageUsed)
    const responses = useSelector(state => state.formDataReducer.formResponses)
    const filesToUpload = useSelector(state => state.questionsReducer.filesToUpload)
    const filesToDelete = useSelector(state => state.questionsReducer.filesToDelete)
    const editResponseId = useSelector(state => state.formDataReducer.editResponseId)
    const showSubmitButton = useSelector(state => state.formDataReducer.showSubmitButton)
    const showAbuseWarning = useSelector(state => state.formDataReducer.showAbuseWarning)
    const totalUserStorage = useSelector(state => state.formDataReducer.totalUserStorage)
    const showFormPageTimer = useSelector(state => state.formDataReducer.showFormPageTimer)
    const fileUploadAnsweredCount = useSelector(state => state.answerCountReducer?.fileUploadAnswerCount)
    const actionToTakeBasedOnUserStorageLevel = useSelector(state => state.screensReducer.actionToTakeBasedOnUserStorageLevel)

    const formRef = useRef(undefined);
    const formContainerRef = useRef(undefined);
    const contentContainerRef = useRef(undefined);

    const [showUploadAnimation, setShowUploadAnimation] = useState(false)
    const [userStorageActionToTakeWhileFormOrQuizIsLoading, setUserStorageActionToTakeWhileFormOrQuizIsLoading] = useState(FORM_QUIZ_RESPONSE_USER_STORAGE_LOAD_ACTION.PENDING)
    let updatedStorage = storageUsed
    let updatedTotalUserStorage = totalUserStorage

    const dispatch = useDispatch()

    window.onhashchange = () => {
        if (window.location.hash === "#dashboard") {
            props.dispatchCurrentScreen(Pages.dashboard)
        }
    }

    useEffect(() => {
        if (localStorage?.getItem("isClear") && (formData.is_quiz || formData?.user_info_form?.user_info_1)) {
            dispatch({
                type: FORM_DATA_ACTION_TYPE.SET_USER_INFO_DATA,
                payload: JSON.parse(localStorage?.getItem("userInfoData"))
            })
            localStorage?.removeItem("userInfoData")
        }
        if (localStorage?.getItem("editResponseId")) {
            dispatch({
                type: FORM_DATA_ACTION_TYPE.SET_EDIT_RESPONSE_ID,
                payload: localStorage?.getItem("editResponseId")
            })
            localStorage?.removeItem("editResponseId")
        }
        localStorage?.removeItem("isClear")
    }, [])

    useEffect(() => {
        if (props.previewType) {
            dispatch({
                type: QUESTIONS_ACTION_TYPE.SET_QUESTIONS,
                payload: getFormQuestions(formData?.pages[0].questions)
            })
        }
        if (editResponseId === null) {
            dispatch({type: FORM_DATA_ACTION_TYPE.SET_FORM_RESPONSES, payload: createFormResponsesArray(formData)})
        } else {
            //while edit response if formData is changed then updating responses data
            if (formData?.pages[0].questions.length !== responses.length) {
                formData?.pages[0].questions.map((question) => {
                    if (responses.filter(response => response.question_id === question._id).length === 0) {
                        const updatedResponses = [...responses]
                        updatedResponses.push(createFormResponse(question._id, question.type, '', 0))
                        dispatch({
                            type: FORM_DATA_ACTION_TYPE.SET_FORM_RESPONSES,
                            payload: updatedResponses
                        })
                    }
                })
            }
        }
    }, [formData?.pages[0].questions.length, formData?.pages[0].questions])

    function getFormQuestions(questions) {
        if (formData?.is_logic_enabled) {
            const logicQuestions = []
            for (let i = 0; i < questions.length; i++) {
                logicQuestions.push(questions[i])
                if (questions[i].is_logic_enabled && questions[i].target_question_id !== "0") {
                    if (questions[i].target_question_id === "-1" || i === questions.length - 1) {
                        dispatch({type: FORM_DATA_ACTION_TYPE.SET_SHOW_SUBMIT_BUTTON, payload: true})
                    } else {
                        dispatch({type: FORM_DATA_ACTION_TYPE.SET_SHOW_SUBMIT_BUTTON, payload: false})
                    }
                    break
                }
                if (questions[i].target_question_id === "-1" || i === questions.length - 1) {
                    dispatch({type: FORM_DATA_ACTION_TYPE.SET_SHOW_SUBMIT_BUTTON, payload: true})
                }
            }
            return logicQuestions
        } else {
            dispatch({type: FORM_DATA_ACTION_TYPE.SET_SHOW_SUBMIT_BUTTON, payload: true})
            return (formData?.setting.is_shuffled) ? shuffleQuestions(questions) : questions;
        }
    }

    function popFilesToUploadData() {
        let filesToUpload = []

        for (let questionCtr = 0; questionCtr < formData?.pages[0].questions.length; questionCtr++) {
            if (formData?.pages[0].questions[questionCtr].type === QUESTION_TYPE.FILE_UPLOAD.key) {
                filesToUpload.push({
                    questionId: formData?.pages[0].questions[questionCtr]._id,
                    file: null,
                    link: null
                })
            }
        }
        dispatch({type: QUESTIONS_ACTION_TYPE.SET_FILES_TO_UPLOAD, payload: filesToUpload})
    }

    const shuffleOptions = (questions) => {
        const updatedQuestions = [...questions]
        questions.map((questionData) => {
            if (questionData?.is_options_shuffled && (questionData?.type === QUESTION_TYPE.MULTIPLE_CHOICE.key || questionData?.type === QUESTION_TYPE.CHECKBOX_CHOICE.key)) {
                updatedQuestions.choices = shuffleArray(questionData.choices)
            }
        })
        return updatedQuestions
    }

    useEffect(() => {
        if (props.previewType) {
            dispatch({type: FORM_DATA_ACTION_TYPE.SET_LOADING, payload: false})
        }
        setUserStorageActionToTakeWhileFormOrQuizIsLoading(getUserStorageActionToTakeWhileFormOrQuizIsLoading(formData?.pages[0].questions))
        // shuffling options at initial state for all questions if it's enabled
        dispatch({
            type: QUESTIONS_ACTION_TYPE.SET_QUESTIONS,
            payload: getFormQuestions(shuffleOptions(formData?.pages[0].questions))
        })
        dispatch({
            type: ACTION_TYPE.SET_ANSWER_COUNT,
            payload: answeredQuestionsCount(responses) + fileUploadAnsweredCount
        })
    }, [])

    useEffect(() => {
        if (props.previewType) {
            dispatch({
                type: QUESTIONS_ACTION_TYPE.SET_QUESTIONS,
                payload: getFormQuestions(shuffleOptions(formData?.pages[0].questions))
            })
        }
    }, [formData])

    useEffect(() => {
        if (userStorageActionToTakeWhileFormOrQuizIsLoading === FORM_QUIZ_RESPONSE_USER_STORAGE_LOAD_ACTION.CHECK_USER_STORAGE_LEVEL) {
            dispatch({
                type: SCREENS_ACTION_TYPE.SET_ACTION_TO_TAKE_BASED_ON_USER_STORAGE_LEVEL,
                payload: getActionToTakeBasedOnUserStorageLevel(totalUserStorage, formData?.pages[0].questions, formData.is_quiz ? formData.quiz_owner_subscription_plan : formData?.form_owner_subscription_plan)
            })
            if (actionToTakeBasedOnUserStorageLevel !== ACTION_TO_TAKE_BASED_ON_USER_STORAGE_LEVEL.SHOW_OUT_OF_SPACE_PAGE) {
                popFilesToUploadData()
            }
        } else {
            dispatch({
                type: SCREENS_ACTION_TYPE.SET_ACTION_TO_TAKE_BASED_ON_USER_STORAGE_LEVEL,
                payload: ACTION_TO_TAKE_BASED_ON_USER_STORAGE_LEVEL.NONE
            })
            popFilesToUploadData()
        }
    }, [userStorageActionToTakeWhileFormOrQuizIsLoading])

    const quizTimer = <>{
        formData?.is_quiz && !showFormPageTimer ?
            <QuizTimer setSelectedPreviewScreen={props.setSelectedPreviewScreen}
                       formContainerRef={formContainerRef}
                       saveFileUploadsInAWS={() => saveFileUploadsInAWS()}
            />
            : null
    }</>;

    const formTimer = <>{
        formData.setting?.auto_close_time && showFormPageTimer ?
            <FormTimer setSelectedPreviewScreen={props.setSelectedPreviewScreen}
                       formContainerRef={formContainerRef}
                       saveFileUploadsInAWS={() => saveFileUploadsInAWS()}
            />
            : null
    }</>;

    const saveFileUploadsInAWS = () => {
        // Save the File Uploads Sequentially
        if (filesToDelete.length > 0) {
            setShowUploadAnimation(true)
            filesToDelete?.map((fileInfo) => {
                if (fileInfo.fileName) {
                    const fileKey = `${AWS_FOLDER_NAMES.FILES_FOLDER}/${cryptoJS.SHA3(formData?.user_id).toString().substr(0, 30)}/${formData?._id}/${fileInfo.fileName}`
                    deleteFileAws(fileKey, fileDeleteSuccessFunction)
                }
            })
        } else if (filesToUpload.length > 0 && filesToUpload.filter(fileInfo => fileInfo.file !== null).length > 0) {
            setShowUploadAnimation(true)
            setAwsFolderName(`${AWS_FOLDER_NAMES.FILES_FOLDER}/${cryptoJS.SHA3(formData?.user_id).toString().substr(0, 30)}/${formData?._id}`)

            filesToUpload?.map((fileInfo) => {
                if (fileInfo.file) {
                    uploadImageFile(AWS_UPLOAD_TYPE.FILE, fileInfo.file, fileUploadSucceeded, fileUploadFailed, formData?.user_id)
                }
            })
        } else {
            dispatch({type: QUESTIONS_ACTION_TYPE.SET_FILE_UPLOAD_COMPLETE, payload: true})
        }
    }

    const getLargestFileUploadIndex = () => {
        let largestFileUploadIndex = -1

        for (let indexCtr = 0; indexCtr < filesToUpload?.length; indexCtr++) {
            if (filesToUpload[indexCtr].file !== null) {
                largestFileUploadIndex = indexCtr
            }
        }

        return largestFileUploadIndex
    }

    const getLargestFileDeleteIndex = () => {
        let largestFileUploadIndex = -1

        for (let indexCtr = 0; indexCtr < filesToDelete?.length; indexCtr++) {
            if (filesToDelete[indexCtr].file !== null) {
                largestFileUploadIndex = indexCtr
            }
        }

        return largestFileUploadIndex
    }

    const fileUploadSucceeded = async (data) => {
        // Save the link to the file
        let ctr

        for (ctr = 0; ctr < filesToUpload?.length; ctr++) {
            if (filesToUpload[ctr].file !== null && filesToUpload[ctr].link === null) {
                // 'replace' function is needed below because of AWS replaces '@' with '%40' when retrieving URLs:
                // https://stackoverflow.com/questions/49036159/file-name-character-is-replaced-with-40-in-uploading-file-to-aws-s3-in-an
                filesToUpload[ctr].link = data.Location.replace('%40', '@')
                const updatedResponses = responses
                updatedResponses.find(question => question.question_id === filesToUpload[ctr].questionId).fileSize = filesToUpload[ctr].file.size
                updatedResponses.find(question => question.question_id === filesToUpload[ctr].questionId).text = filesToUpload[ctr].link
                dispatch({type: FORM_DATA_ACTION_TYPE.SET_FORM_RESPONSES, payload: updatedResponses})
                updatedStorage = updatedStorage + filesToUpload[ctr].file.size
                updatedTotalUserStorage = updatedTotalUserStorage + filesToUpload[ctr].file.size
                break
            }
        }
        dispatch({type: FORM_DATA_ACTION_TYPE.SET_STORAGE_USED, payload: updatedStorage})
        dispatch({type: FORM_DATA_ACTION_TYPE.SET_TOTAL_USER_STORAGE, payload: updatedTotalUserStorage})

        if (ctr === getLargestFileUploadIndex()) {
            dispatch({type: QUESTIONS_ACTION_TYPE.SET_FILE_UPLOAD_COMPLETE, payload: true})
            setShowUploadAnimation(false)
        }
    }

    const fileDeleteSuccessFunction = () => {
        let ctr

        for (ctr = 0; ctr < filesToDelete?.length; ctr++) {
            if (filesToDelete[ctr].fileName !== null) {
                const updatedResponses = responses
                updatedStorage = updatedStorage - updatedResponses.find(question => question.question_id === filesToDelete[ctr].questionId).fileSize
                updatedResponses.find(question => question.question_id === filesToDelete[ctr].questionId).fileSize = 0
                updatedResponses.find(question => question.question_id === filesToDelete[ctr].questionId).text = ""
                dispatch({type: FORM_DATA_ACTION_TYPE.SET_FORM_RESPONSES, payload: updatedResponses})
                break
            }
        }
        dispatch({type: FORM_DATA_ACTION_TYPE.SET_STORAGE_USED, payload: updatedStorage})

        if (ctr === getLargestFileDeleteIndex()) {
            if (filesToUpload.length > 0 && filesToUpload.filter(fileInfo => fileInfo.file !== null).length > 0) {
                setShowUploadAnimation(true)
                setAwsFolderName(`${AWS_FOLDER_NAMES.FILES_FOLDER}/${cryptoJS.SHA3(formData?.user_id).toString().substr(0, 30)}/${formData?._id}`)

                filesToUpload?.map((fileInfo) => {
                    if (fileInfo.file) {
                        uploadImageFile(AWS_UPLOAD_TYPE.FILE, fileInfo.file, fileUploadSucceeded, fileUploadFailed, formData?.user_id)
                    }
                })
            } else {
                dispatch({type: QUESTIONS_ACTION_TYPE.SET_FILE_UPLOAD_COMPLETE, payload: true})
                setShowUploadAnimation(false)
            }
        }
    }

    const fileUploadFailed = (data) => {
        // Since the File Uploads are going in sequential order,
        // If an upload fails, we will mark the first 'null' link as 'undefined'.
        // This will allow the next file upload to populate the link correctly
        // since the first null link is matched.
        let ctr

        for (ctr = 0; ctr < filesToUpload.length; ctr++) {
            if (filesToUpload[ctr].file !== null && filesToUpload[ctr].link === null) {
                console.log(`File Upload Error: ${data}`)
                filesToUpload[ctr].link = undefined
                break
            }
        }

        if (ctr === getLargestFileUploadIndex()) {
            dispatch({type: QUESTIONS_ACTION_TYPE.SET_FILE_UPLOAD_COMPLETE, payload: true})
            setShowUploadAnimation(false)
        }
    }
//</editor-fold>

    useEffect(() => {
        if (formRef?.current?.offsetHeight > 200) {
            setShowFloatButton(true)
        }
    }, [formRef?.current])

    let url = new URL(decodeURI(window.location.href));
    let preFilledFormData = JSON.parse(url.searchParams.get("prefilleddata"));

    return (
        <>
            {
                (() => {
                        if (actionToTakeBasedOnUserStorageLevel === ACTION_TO_TAKE_BASED_ON_USER_STORAGE_LEVEL.SHOW_OUT_OF_SPACE_PAGE) return (
                            <OutOfSpacePage previewType={props.previewType}/>
                        )

                        if (userStorageActionToTakeWhileFormOrQuizIsLoading === FORM_QUIZ_RESPONSE_USER_STORAGE_LOAD_ACTION.NONE || actionToTakeBasedOnUserStorageLevel === ACTION_TO_TAKE_BASED_ON_USER_STORAGE_LEVEL.NONE || actionToTakeBasedOnUserStorageLevel === ACTION_TO_TAKE_BASED_ON_USER_STORAGE_LEVEL.ALLOW_RESPONSES_TO_NON_FILE_UPLOAD_QUESTIONS) return (
                            <div id={'form-page'} className={style.form_page_container} ref={formContainerRef}>
                                {formData?.is_quiz ?
                                    <QuizzoryContentPopup contentContainerRef={contentContainerRef}/> :
                                    <SurveyHeartContentPopup contentContainerRef={contentContainerRef}/>}
                                {quizTimer}
                                {formTimer}
                                {formData?.abuse_scan_result && showAbuseWarning && !props.previewType ?
                                    <AbuseWarningComponent/> : null}
                                <FormHeaderComponent previewType={props.previewType}/>
                                <FormQuestionContainer {...props}
                                                       formRef={formRef}
                                                       previewType={props.previewType}
                                />
                                {props.previewType || props.preview || formData.user_id === "surveyheartapp@gmail.com" || preFilledFormData ? null :
                                    <ClearFormComponent previewType={props.previewType}/>}
                                {!props.previewType ? <FormFooterComponent snackBarRef={props.snackBarRef}
                                                                           previewType={props.previewType}
                                                                           contentContainerRef={contentContainerRef}
                                                                           saveFileUploadsInAWS={() => saveFileUploadsInAWS()}
                                                                           setSelectedPreviewScreen={props.setSelectedPreviewScreen}
                                /> : null}
                                {showFloatButton ?
                                    <FormFloatButtonContainer formContainerRef={formContainerRef}
                                                              previewType={props.previewType}/> : null}
                                {showSubmitButton ? <FormSubmitContainer preview={props.preview}
                                                                         snackBarRef={props.snackBarRef}
                                                                         previewType={props.previewType}
                                                                         contentContainerRef={contentContainerRef}
                                                                         saveFileUploadsInAWS={() => saveFileUploadsInAWS()}
                                                                         setSelectedPreviewScreen={props.setSelectedPreviewScreen}
                                /> : null}
                                {showUploadAnimation ? <Loader height='100%' width='100%' left='0px'/> : null}
                            </div>
                        )
                    }
                )()
            }
        </>
    )
}
