import React, {useRef, useState} from 'react'
import {isMobile, isMobileOnly} from "react-device-detect";
import MaterialFloatingActionButton from "../../../shared/components/MaterialFab";
import lastEdited from "../../../../public/images/last-edited.svg"
import MaterialTextField from "../../../shared/components/MaterialTextField";
import {resetFocusTitle} from "./helpers/BuilderContainerHelper"
import {translationKeys} from "../../../localizations/translationKeys-localization";
import {getLastEditedByTime} from "../../dashboard/utils/get-date";
import ModalDialog from "../../../shared/components/ModalDialog";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import useQuestionMgr from "../../hooks/useQuestionMgr";
import QuestionTypePopup from "../../../shared/components/QuestionTypePopup";

const TitleSectionComponent = (props) => {
    const {t} = useTranslation()
    const {deleteQuestion} = useQuestionMgr()
    const userId = useSelector(state => state.userReducer?.userId)
    const formData = useSelector(state => state.appReducer.formData)
    const sectionTitleToFocus = useSelector(state => state.builderReducer.sectionTitleToFocus)
    const descriptionInputRef = useRef(null)
    const refTitleSection = useRef(null)
    const refSectionCardAddQuestion = useRef(null)
    const refSectionCardDragIndicator = useRef(null)
    const [showLastEdited, setShowLastEdited] = useState(false)

    const AddFocusToQuestionCardUI = () => {
        if (!props.isSection) {
            return
        }

        // Section Card
        refTitleSection.current.classList.add('builder-title-section-card-focused')
        refTitleSection.current.classList.remove('builder-title-section-card-unfocused')

        // Add Question Button
        refSectionCardAddQuestion.current?.classList?.remove('builder-question-card-hide')
        refSectionCardAddQuestion.current?.classList?.add('builder-section-card-show-add-question')

        // Drag Indicator
        refSectionCardDragIndicator.current.classList.remove('builder-question-card-hide')
        refSectionCardDragIndicator.current.classList.add('builder-question-card-show-drag-indicator')
    }

    const RemoveFocusFromQuestionCardUI = () => {
        if (!props.isSection) {
            return
        }

        refTitleSection.current.classList.remove('builder-title-section-card-focused')
        refTitleSection.current.classList.add('builder-title-section-card-unfocused')

        // Add Question Button
        refSectionCardAddQuestion.current?.classList?.remove('builder-section-card-show-add-question')
        refSectionCardAddQuestion.current?.classList?.add('builder-question-card-hide')

        // Drag Indicator
        refSectionCardDragIndicator.current.classList.remove('builder-question-card-show-drag-indicator')
        refSectionCardDragIndicator.current.classList.add('builder-question-card-hide')
    }

    const handleOutSideClick = () => {
        // This event will only be called when props.isSection is true.
        document.onclick = () => {
            props.refreshQTMenuDisplay(-1)
        }
    }

    const handleAddQuestionTypesImageClick = (e) => {
        props.refreshQTMenuDisplay(-1)

        if (!props.question.showQTMenu) {
            props.refreshQTMenuDisplay(props.index)
        }

        e.nativeEvent.stopImmediatePropagation()
    }

    const handleAddQuestionClick = (e) => {
        e.stopPropagation();
        handleOutSideClick();
        handleAddQuestionTypesImageClick(e);
    }

    const handleOutsideToQuestionCardClick = () => {
        document.onclick = e => {
            if (isMobile && (refTitleSection.current && !refTitleSection.current.contains(e.target))) {
                RemoveFocusFromQuestionCardUI();
                props.refreshQTMenuDisplay(-1)
                document.onclick = null;
            }
        }
    }

    const setSectionTitle = (index, value) => {
        if (value === undefined) {
            return
        }

        props.setTitle(index, value)
    }

    const setSectionDesc = (index, value) => {
        if (value === undefined) {
            return
        }

        props.setDesc(props.index, value)
    }

    const lastEditedClick = () => {
        setShowLastEdited(true)
    }

    return (
        <>
            {showLastEdited ? <ModalDialog header={t(translationKeys.last_edited)}
                                           body={[t(translationKeys.last_edited_on) + `${getLastEditedByTime(formData?.date_edited, true)}` + t(translationKeys.by) + `${formData?.last_edited_by === userId ? t(translationKeys.you) : formData?.last_edited_by}`]}
                                           buttons={[{
                                               text: t(translationKeys.ok),
                                               action: () => {
                                                   setShowLastEdited(false)
                                               }
                                           }]}/> : null}
            {formData?.last_edited_by && !props.isSection ?
                <div onClick={() => isMobileOnly ? lastEditedClick() : null} style={{
                    width: '-webkit-fill-available',
                    textAlign: isMobileOnly ? 'center' : '-webkit-right'
                }}>
                <span style={{
                    display: 'flex',
                    color: '#CCDBEC',
                    background: '#004BA0',
                    borderRadius: isMobileOnly ? '0' : '50px',
                    margin: isMobileOnly ? '0' : '15px',
                    justifyContent: 'center',
                    padding: isMobile && formData?.last_edited_by !== userId ? '0 23%' : isMobile && formData?.last_edited_by === userId ? '0 10%' : '0 10px',
                    width: isMobileOnly ? '-webkit-fill-available' : 'fit-content'
                }}>
                <img style={isMobileOnly ? {height: 'fit-content', marginTop: '3px'} : null} src={lastEdited}
                     alt={'last_edited'}/>
                <p style={{
                    margin: isMobileOnly ? '5px 1px' : '5px',
                    fontSize: '14px',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden'
                }}>{t(translationKeys.last_edited_on)}<span
                    style={{
                        color: 'white',
                        fontWeight: 'bold'
                    }}> {getLastEditedByTime(formData?.date_edited, true)}</span> {t(translationKeys.by)} <span
                    style={{
                        color: 'white',
                        fontWeight: 'bold'
                    }}>{formData?.last_edited_by === userId ? t(translationKeys.you) : formData?.last_edited_by}</span></p>
                </span>
                </div> : null}
            <div
                id={'title-section'}
                className={`builder-title-section-card builder-title-section-card-unfocused`}
                onMouseEnter={() => {
                    if (!isMobile) AddFocusToQuestionCardUI()
                }}
                onMouseLeave={() => {
                    if (!isMobile) RemoveFocusFromQuestionCardUI()
                }}
                onFocus={() => {
                    if (props.isSection && isMobile) AddFocusToQuestionCardUI()
                }}
                onBlur={() => {
                    if (props.isSection && isMobile) handleOutsideToQuestionCardClick()
                }}
                ref={refTitleSection}
                style={{transform: 'translate3d(0, 0, 0)', minHeight: props.isSection ? "200px" : null}}
            >
                <div className='builder-question-card-hide' ref={refSectionCardDragIndicator}>
                    <img
                        src="images/icons/builder/drag_indicator_white.svg"
                        alt="Drag"
                        style={{cursor: 'pointer'}}
                        onMouseDown={() => {
                            props.refreshQTMenuDisplay(-1)
                        }}
                        onClick={(e) => {
                            handleOutSideClick()
                        }
                        }
                    />
                </div>
                {formData?.is_quiz && formData?.response_count > 0 ? null :
                    <div
                        className={props.isSection ? 'builder-title-section-card-visible' : 'builder-title-section-card-hidden'}>
                        <img
                            src="images/icons/builder/close_section.svg"
                            alt="Add Question"
                            onClick={() => {
                                deleteQuestion(props.index)
                            }}
                        />
                    </div>}
                <div id={'sh-text-field-required'} className='sh-text-field-required'>
                    <MaterialTextField
                        data={{
                            hint: props.title,
                            defaultValue: props.defaultTitle
                        }}
                        index={props.index}
                        errorText={{current: t(translationKeys.title_required)}}
                        setFocus={props.isSection ? sectionTitleToFocus === props.index : props.focusFormTitle}
                        onChange={props.isSection ? setSectionTitle : props.setTitle}
                        required={true}
                        onKeyDown={(e) => {
                            if (e.key === "Enter") {
                                descriptionInputRef.current.focus()
                            }
                        }}
                        includeIndexInOnChange={props.includeIndexInOnChange ? props.includeIndexInOnChange : false}
                        maxLength={250}
                        setForceUpdateRefVarFocus={resetFocusTitle(false)}
                    />
                    <MaterialTextField {...props}
                                       data={{
                                           hint: props.description,
                                           defaultValue: props.defaultDesc
                                       }}
                                       inputRef={descriptionInputRef}
                                       onChange={props.isSection ? setSectionDesc : props.setDesc}
                                       maxLength={1000}
                                       includeIndexInOnChange={props.includeIndexInOnChange ? props.includeIndexInOnChange : false}
                    />
                </div>
                {formData?.is_quiz && formData?.response_count > 0 || isMobileOnly ? null :
                    <div id={'builder-question-card-hide'} className='builder-question-card-hide'
                         ref={refSectionCardAddQuestion}>
                        {!isMobile ? <img
                            src="images/add_white.svg"
                            alt="Add Question"
                            onClick={(e) => {
                                handleAddQuestionClick(e)
                            }}
                        /> : props.totalQuestionsCount && (props.totalQuestionsCount === props.index + 1) ? null
                            : <MaterialFloatingActionButton iconName={'add'} buttonSize={36}
                                                            iconSize={18}
                                                            iconColor={'#004ab0'}
                                                            backgroundColor={'#fff'}
                                                            handleClick={handleAddQuestionClick}/>
                        }
                    </div>}
                {
                    (() => {
                        if (props.isSection)
                            return (
                                <div id={'question-card-question-types-menu-mobile-view'}
                                     className={isMobile ? 'question-card-question-types-menu-mobile-view' : 'builder-question-card-question-types-menu builder-question-card-question-types-menu-form'}
                                     style={{
                                         display: isMobile ? (props.question.showQTMenu) ? 'flex' : 'none' : null,
                                         zIndex: isMobile ? null : 2
                                     }}>
                                    {
                                        (() => {
                                            if (props.question.showQTMenu) return (
                                                <QuestionTypePopup questionIndex={props.index}/>
                                            )
                                        })()
                                    }
                                </div>
                            )
                    })()
                }
            </div>
        </>
    )
}

export default TitleSectionComponent
