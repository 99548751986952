import React from 'react'
import {useSelector} from "react-redux"
import blue_radio_button from '../../../../public/images/blue_radio_button.svg'
import check_box_tick from '../../../../public/images/check_box_tick.svg'
import {convertFirstLetterToUpper} from "../../../shared/utils/helper"
import {QUESTION_TYPE} from "../../../shared/utils/constants"

const GridQuestionPopupValues = () => {
    const responseAnalyzeData = useSelector(state => state.gridDetailsPopupReducer.response_analyze_data)
    const gridAnswerColAndRowIndexes = useSelector(state => state.gridDetailsPopupReducer.grid_answer_col_and_row_indexes)
    const gridImage = () => {
        const gridType = useSelector(state => state.gridDetailsPopupReducer.type)

        if (gridType === QUESTION_TYPE.CHECKBOX_GRID.key) {
            return check_box_tick
        } else if (gridType === QUESTION_TYPE.MULTIPLE_CHOICE_GRID.key) {
            return blue_radio_button
        }
    }

    const getHeaderRow = () => {
        let emptyTD = <td style={{textAlign: 'left'}} key={'grid-popup-header-empty'}></td>
        let colHeaders = []

        for (let colCtr = 0; colCtr < responseAnalyzeData.columnGraphLabels.length; colCtr++) {
            colHeaders.push(
                <td style={{textAlign: 'center'}} key={`grid-popup-header-col-${colCtr}`}>
                    {convertFirstLetterToUpper(responseAnalyzeData.columnGraphLabels[colCtr])}
                </td>)
        }

        return (
            <tr key={'grid-popup-header-col'}>
                {emptyTD}{colHeaders}
            </tr>
        )
    }

    const getBodyRows = () => {
        let bodyRows = []

        for (let rowCtr = 0; rowCtr < responseAnalyzeData.rowGraphLabels.length; rowCtr++) {
            let colsInBodyRow = []
            let tdRowHeader = <td key={`grid-popup-body-row-${rowCtr}`} style={{textAlign: 'left'}}>
                {responseAnalyzeData.rowGraphLabels[rowCtr]}
            </td>

            for (let colCtr = 0; colCtr < responseAnalyzeData.columnsIdList.length; colCtr++) {
                let rowMatch = false
                let columnMatch = false

                for (let gridAnswerColAndRowIndexCtr = 0; gridAnswerColAndRowIndexCtr < gridAnswerColAndRowIndexes?.length; gridAnswerColAndRowIndexCtr++) {

                    if (gridAnswerColAndRowIndexes[gridAnswerColAndRowIndexCtr].row === rowCtr) {
                        rowMatch = true

                        gridAnswerColAndRowIndexes[gridAnswerColAndRowIndexCtr].columns.forEach(col => {
                            if (col === colCtr) {
                                columnMatch = true
                            }
                        })
                    }
                }

                colsInBodyRow.push(<td key={`grid-popup-cell-${rowCtr}-${colCtr}`} style={{textAlign: 'center'}}>
                    {rowMatch && columnMatch ? <img src={gridImage()} alt='blue-radio-button'/> : '-'}</td>)
            }

            let tdRowContainer = <tr key={`grid-popup-row-container-${rowCtr}`}>
                {tdRowHeader}{colsInBodyRow}
            </tr>

            bodyRows.push(tdRowContainer)
        }

        return bodyRows
    }

    return (
        <table style={{width: '100%'}} id='grid-question-popup-table'>
            <thead>
                {getHeaderRow()}
            </thead>
            <tbody>
                {getBodyRows()}
            </tbody>
        </table>
    )
}

export default GridQuestionPopupValues