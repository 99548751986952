export const removeResponseIndexesFromGivenArrayRef = (responseIds, givenArrayRef, responsesInfo) => {
    if (Array.isArray(givenArrayRef.current) && givenArrayRef.current.length > 0) {
        if (Array.isArray(responseIds) && responsesInfo) {
            for (let i = 0; i < responseIds.length; i++) {
                const currentId = responseIds[i];
                removeOneIndexFromGivenArrayRef(currentId, givenArrayRef, responsesInfo)
            }
        } else if (responseIds) {
            removeOneIndexFromGivenArrayRef(responseIds, givenArrayRef, responsesInfo)
        }
    }
}

const removeOneIndexFromGivenArrayRef = (responseId, givenArrayRef, responsesInfo) => {
    for (let j = 0; j < responsesInfo.length; j++) {
        const currentResponseId = responsesInfo[j].responseId
        if ((responseId === currentResponseId)) {
            const responseIndexInSearchedArray = givenArrayRef.current.indexOf(j)
            if (responseIndexInSearchedArray !== -1) {
                givenArrayRef.current.splice(responseIndexInSearchedArray, 1)
                for (let k = 0; k < givenArrayRef.current.length; k++) {
                    if (k >= responseIndexInSearchedArray) {
                        givenArrayRef.current[k] = givenArrayRef.current[k] - 1
                    }
                }
            }
        }
    }
}

export const isSelectedAllResponses = (arrOne, arrTwo) => {
    return ((arrOne.length === arrTwo.length) && arrOne.every(function (element) {
        return arrTwo.indexOf(element) !== -1;
    }));
}

export const isSelectedAllSearchedResponses = (searchedArray, selectedArray) => {
    if (searchedArray.length) {
        if (selectedArray.length < searchedArray.length) return false
        for (let i = 0; i < searchedArray.length; i++) {
            if (selectedArray.indexOf(searchedArray[i]) === -1) return false
        }
        return true
    }
    return false
}

export const getSearchedResponseIds = (searchedIndexesArray, allResponseIds) => {
    let searchedResponseIds = [];
    for (let i = 0; i < searchedIndexesArray.length; i++) {
        searchedResponseIds.push(allResponseIds[searchedIndexesArray[i]])
    }
    return searchedResponseIds
}
export const getAllFilteredRows = (filteredArrayRef, searchedArrayRef, allResponseIds) => {
    let selectedAllFilteredRows = [];
    if (filteredArrayRef.current && Array.isArray(filteredArrayRef.current)) {
        for (let i = 0; i < filteredArrayRef.current.length; i++) {
            if (searchedArrayRef.current && Array.isArray(searchedArrayRef.current)) {
                for (let j = 0; j < searchedArrayRef.current.length; j++) {
                    if (searchedArrayRef.current[j] === filteredArrayRef.current[i]) {
                        selectedAllFilteredRows.push(allResponseIds[filteredArrayRef.current[i]])
                        break;
                    }
                }
            } else {
                selectedAllFilteredRows.push(allResponseIds[filteredArrayRef.current[i]])
            }
        }
    } else if (searchedArrayRef.current && Array.isArray(searchedArrayRef.current)) {
        for (let i = 0; i < searchedArrayRef.current.length; i++) {
            selectedAllFilteredRows.push(allResponseIds[searchedArrayRef.current[i]])
        }
    } else {
        selectedAllFilteredRows = allResponseIds;
    }
    return selectedAllFilteredRows;
}

export const getIsAllSelectedInfo = (allResponseIds, selectedTableRows, searchedArrayRef, filteredArrayRef) => {
    if (allResponseIds.length === selectedTableRows.length) return true
    if (selectedTableRows.length > 0) {
        if (searchedArrayRef.current) {
            return isSelectedAllSearchedResponses(getSearchedResponseIds(searchedArrayRef.current, allResponseIds), selectedTableRows)
        } else {
            return isSelectedAllResponses(getAllFilteredRows(filteredArrayRef, searchedArrayRef, allResponseIds), selectedTableRows)
        }
    } else {
        return false
    }
}

export const getSelectedSearchResponsesLength = (allResponseIds, selectedRespondents, searchedArray) => {
    const searchedResponseIds = [];
    let selectedSearchResponsesLength = 0
    for (let i = 0; i < searchedArray.length; i++) {
        searchedResponseIds.push(allResponseIds[searchedArray[i]])
    }
    for (let i = 0; i < searchedResponseIds.length; i++) {
        if (selectedRespondents.indexOf(searchedResponseIds[i]) !== -1) {
            selectedSearchResponsesLength = selectedSearchResponsesLength + 1
        }
    }
    if (searchedArray.length === selectedSearchResponsesLength) return selectedRespondents.length
    return selectedSearchResponsesLength
}