import React, {useRef, useState, useEffect} from "react"
import {isMobileOnly} from "react-device-detect";
import MaterialTextField from "../../../shared/components/MaterialTextField";
import PropTypes from "prop-types"
import {getOGDataFromUrl} from "../../../shared/utils/sash-v2-api"
import {ATTACHMENT_TYPE} from "../../../shared/utils/constants"
import QuestionAttachmentComponent from "../../../form/components/questions/QuestionAttachmentComponent"
import {translationKeys} from "../../../localizations/translationKeys-localization";
import {t} from "i18next";
import useQuestionMgr from "../../hooks/useQuestionMgr";

// This Component was created to display and preview ATTACHMENT_TYPE.VIDEO and ATTACHMENT_TYPE.WEBSITE
const LinkPreview = (props) => {
    const {removeAttachmentInfo, addAttachmentInfo, setQuestionAttachment} = useQuestionMgr()
    const [attachmentType, setAttachmentType] = useState(ATTACHMENT_TYPE[props.attachmentType])
    const [OGDataTitle, setOGDataTitle] = useState(null)
    const [OGDataDesc, setOGDataDesc] = useState(null)
    const [OGDataURLEntered, setOGDataURLEntered] = useState('')
    const [OGDataImageURL, setOGDataImageURL] = useState(null)

    const refURLIsValid = useRef(false)
    const attachmentRef = useRef(null)

    useEffect(() => {
        processAttachments()
    }, [props.question.attachment])

    useEffect(() => {
        setAttachmentType(ATTACHMENT_TYPE[props.attachmentType])

        if (props.question.tempAttachmentValue.trim() === '') {
            refURLIsValid.current = false
        }
    }, [props.attachmentType])

    useEffect(() => {
        if (props.question.tempAttachmentValue.trim() === '') {
            attachmentRef.current.focus()
        }
    }, [attachmentType])

    const showOGData = () => {
        setOGDataTitle(props.question.attachment.title)
        setOGDataDesc(props.question.attachment.description)
        setOGDataURLEntered(props.question.attachment.url)
        setOGDataImageURL(props.question.attachment.image_url)
        refURLIsValid.current = true
    }

    const processAttachments = () => {
        if (props.question.attachment && props.question.attachment.file_type !== undefined) {
            showOGData()
        }
    }

    const closeLinkPreview = () => {
        removeAttachmentInfo(props.index)
        props.closeLinkPreview()
    }

    const handleVideoOrWebsiteInputBlur = (newValue) => {
        if (newValue.trim() === '') {
            removeAttachmentInfo(props.index)
            props.closeLinkPreview()
            return
        }

        if (!refURLIsValid.current && !isMobileOnly) {
            removeAttachmentInfo(props.index)
            props.question.tempAttachmentValue = ""
            if (newValue.trim().length > 0) {
                props.showModalDialog(t(translationKeys.invalid_url), t(translationKeys.invalid_url_description))
            }
        }
    }

    const getLinkImage = result => {
        try {
            if (result.data.ogImage.url) {
                return result.data.ogImage.url
            }

            if (result.data.ogImage) {
                return result.data.ogImage
            }

            return '/images/link.svg'
        } catch (e) {
            return '/images/link.svg'
        }
    }

    const handleAttachmentLinkChange = (urlEntered, urlIsValid) => {
        setQuestionAttachment(props.index, urlEntered)

        refURLIsValid.current = urlIsValid

        if (refURLIsValid.current) {
            const successFunction = res => {
                OGDataReceived(res)
            }
            const failureFunction = err => {
                OGDataNotReceived(err);
            }
            getOGDataFromUrl(urlEntered.trim(), successFunction, failureFunction);
            const OGDataReceived = result => {
                try {
                    setOGDataTitle(result.data.ogTitle)
                    setOGDataDesc(result.data.ogDescription)
                    setOGDataURLEntered(urlEntered)

                    let linkImage = getLinkImage(result)

                    setOGDataImageURL(linkImage)

                    if ((result.data.ogType && result.data.ogType.toUpperCase().startsWith(ATTACHMENT_TYPE.VIDEO.key) || result.data.ogVideo)) {
                        if (attachmentType === ATTACHMENT_TYPE.VIDEO) {
                            addAttachmentInfo(props.index, attachmentType, urlEntered, result.data.ogTitle, result.data.ogDescription, linkImage)
                        } else {
                            // Web Attachment has been changed to a Video. Show and Save the Attachment as a Video.
                            addAttachmentInfo(props.index, ATTACHMENT_TYPE.VIDEO, urlEntered, result.data.ogTitle, result.data.ogDescription, linkImage)

                            setAttachmentType(ATTACHMENT_TYPE.VIDEO)
                        }
                    } else if ((result.data.ogType && result.data.ogType.toUpperCase() === ATTACHMENT_TYPE.WEBSITE.key) || result.data.ogImage) {
                        if (attachmentType === ATTACHMENT_TYPE.WEBSITE) {
                            addAttachmentInfo(props.index, attachmentType, urlEntered, result.data.ogTitle, result.data.ogDescription, linkImage)
                        } else {
                            // Video Attachment has been changed to a Website. Show and Save the Attachment as a Website.
                            addAttachmentInfo(props.index, ATTACHMENT_TYPE.WEBSITE, urlEntered, result.data.ogTitle, result.data.ogDescription, linkImage)

                            setAttachmentType(ATTACHMENT_TYPE.WEBSITE)
                        }
                    } else {
                        // No 'ogType' was specified.
                        addAttachmentInfo(props.index, attachmentType, urlEntered, result.data.ogTitle, result.data.ogDescription, linkImage)
                    }
                } catch
                    (e) {
                    console.log(e)
                }
            }

            const OGDataNotReceived = () => {
                refURLIsValid.current = false
            }
        }
    }

    return (
        <>
            <div>
                <div id={'builder-question-line'} className="builder-question-line">
                    {!isMobileOnly ? <div style={{visibility: 'hidden'}}>{props.number}.</div> : null}
                    <div className='builder-question-text-input'
                         style={{marginBottom: isMobileOnly ? '5px' : null}}>
                        <div className='sh-text-field' style={{
                            paddingTop: 0,
                            marginBottom: '10px'
                        }}>
                            <MaterialTextField key={attachmentType.key}
                                               data={{
                                                   defaultValue: props.question.tempAttachmentValue.trim()
                                               }}
                                               onBlur={handleVideoOrWebsiteInputBlur}
                                               onChange={handleAttachmentLinkChange}
                                               noLabel={true}
                                               textFieldStyle={{
                                                   paddingTop: 0,
                                                   marginBottom: '10px'
                                               }}
                                               customStyleObject={{fontSize: '16px'}}
                                               placeholder={attachmentType.displayText === t(translationKeys.video) ? t(translationKeys.paste_your_video_link_here) : t(translationKeys.paste_your_website_link_here) }
                                               validateURL={true}
                                               errorText={{current: refURLIsValid.current || !props.question.tempAttachmentValue.trim().length ? null : t(translationKeys.invalid_url)}}
                                               required={t(translationKeys.required)}
                                               inputRef={attachmentRef}
                            />
                        </div>
                    </div>
                    {!isMobileOnly ? <img src="images/icons/builder/attach_file.svg"
                                          alt={'attach'}
                                          style={{visibility: 'hidden'}}/> : null}
                </div>
                {props.question.tempAttachmentValue.trim() ? <div className="builder-question-line builder-question-left-align">
                    {!isMobileOnly ? <div style={{visibility: 'hidden'}}>{props.number}.</div> : null}
                    <div className='builder-question-text-input'
                         style={{
                             display: refURLIsValid.current ? 'block' : 'none',
                             width: isMobileOnly ? '100%' : 'auto'
                         }}>
                        <QuestionAttachmentComponent
                            key={attachmentType.key}
                            attachment={{
                                title: OGDataTitle,
                                description: OGDataDesc,
                                image_url: OGDataImageURL,
                                file_type: attachmentType.key,
                                url: OGDataURLEntered
                            }}
                            isMobileQuestionPopUp={props.isMobileQuestionPopUp}
                            theme={"classic_theme"}
                            backgroundColorProvided={false}
                            onCloseClick={closeLinkPreview}
                        />
                    </div>
                    {!isMobileOnly ? <img src="images/icons/builder/attach_file.svg"
                                          alt={'attach'}
                                          style={{visibility: 'hidden'}}/> : null}
                </div> : null}
            </div>
        </>
    )
}

export default LinkPreview

LinkPreview.propTypes = {
    question: PropTypes.object.isRequired,
    index: PropTypes.number.isRequired,
    number: PropTypes.number.isRequired,
    attachmentType: PropTypes.oneOf(Object.keys(ATTACHMENT_TYPE)).isRequired,
    closeLinkPreview: PropTypes.func.isRequired,
    showModalDialog: PropTypes.func.isRequired,
    updateQuestions: PropTypes.func.isRequired
}
