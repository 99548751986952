import React from 'react'
import {GRID_DETAILS_POPUP_ACTION_TYPE} from "../../reducers/gridDetailsPopupReducer"
import close_black from '../../../../public/images/close_black.svg'
import {useDispatch, useSelector} from "react-redux"

const GridQuestionPopupTitleBar = () => {
    const dispatch = useDispatch()
    const selected = useSelector(state => state.languageReducer?.selectedLanguage)
    const sNo = useSelector(state => state.gridDetailsPopupReducer.s_no)
    const title = useSelector(state => state.gridDetailsPopupReducer.title)

    return (
        <div id='grid-question-title-container' className='grid-question-title-container'>
            <div className='response-question-title' style={{whiteSpace: "break-spaces"}}>
                {(sNo) + '. ' + title}</div>
            <div style={{display: 'flex', cursor: 'pointer'}}>
                <img style={(selected === 'fa' || selected === 'ar') ? {right: '95%'} : null}
                     alt='close-button'
                     src={close_black}
                     onClick={() => dispatch({type: GRID_DETAILS_POPUP_ACTION_TYPE.SET_SHOW_POPUP, payload: false})}
                />
            </div>
        </div>
    )
}

export default GridQuestionPopupTitleBar