import React from 'react'
import {useTranslation} from "react-i18next";
import {translationKeys} from "../../localizations/translationKeys-localization";

function PendingValidationCard(props) {
    const {t} = useTranslation()
    return (<div id={'pending-card-container'} className="pending-card-container">
            <span className='pending-card-title'>{t(translationKeys.pending_evaluation)}</span>
            <img style={{height: '100px'}} alt='pending-result' src='../../../images/pending_form.svg'/>
            {!props.isQuizEdit ? <>
                <span className='result-alert-text'> {t(translationKeys.results_published_soon)}</span>
                <span style={{fontWeight: "normal", fontSize: '12px'}}
                      className='result-alert-text'>{t(translationKeys.save_link_for_future_reference)}</span>
            </> : null}
        </div>
    )
}

export default PendingValidationCard;