import React, {useEffect, useRef, useState} from "react"
import QuestionTypeMenuIconInfo from "../classes/QuestionTypeMenuIconInfo"
import {ATTACHMENT_TYPE, getQuestionCategory, QUESTION_CATEGORY, QUESTION_TYPE} from "../../../shared/utils/constants"
import MaterialSwitch from "../../../shared/components/MaterialSwitch"
import ChoiceQuestionCorrectAnswerAndMarks from "./ChoiceQuestionCorrectAnswerAndMarks"
import {ANSWER_EVENT} from "./QuizBuilderContainer"
import MaterialTextField from "../../../shared/components/MaterialTextField";
import '../../../shared/css/sh-text-field.css'
import OptionAutocompleteSuggestions from "../../../shared/components/OptionAutocompleteSuggestions"
import {useDispatch, useSelector} from "react-redux"
import {OPTIONS_ACTION_TYPE} from "../../reducers/optionsReducer"
import {translationKeys} from "../../../localizations/translationKeys-localization";
import {isDesktop, isMobileOnly} from "react-device-detect";
import MaterialButton from "../../../shared/components/MaterialButton";
import optionsImage from "../../../../public/images/icons/builder/options_image.svg"
import dragImage from "../../../../public/images/icons/builder/drag_indicator_horizontal.svg"
import sectionIcon from "../../../../public/images/icons/builder/section_black_icon.svg";
import QuestionAttachmentComponent from "../../../form/components/questions/QuestionAttachmentComponent";
import useChoiceMgr from "../../hooks/useChoiceMgr";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import {APP_ACTION_TYPE} from "../../reducers/appReducer";
import {BUILDER_ACTION_TYPE} from "../../reducers/builderReducer";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import continueButton from "../../../../public/images/icons/builder/undo_button.svg";
import submitButton from "../../../../public/images/icons/builder/submit_gray_tick_mark.svg";
import useQuestionMgr from "../../hooks/useQuestionMgr";
import useLogicJumpMgr from "../../hooks/useLogicJumpMgr";
import {PremiumJSON} from "../../../shared/utils/helper";
import {PREMIUM_ACTION_TYPE} from "../../reducers/premiumReducer";
import TextQuestionBody from "./TextQuestionBody";
import {useTranslation} from "react-i18next";

let scroll = 0

const ChoiceBody = (props) => {
    const {
        addOptionValue,
        updateOptionValue,
        removeOptionValue,
        addOtherOptionForChoiceQuestion,
        setOptionAttachment,
        addShufflerOptionsForChoiceQuestion
    } = useChoiceMgr()
    const [selectedAnswerText, setSelectedAnswerText] = useState(null)
    const [selectedAnswerIndex, setSelectedAnswerIndex] = useState(-1)
    const [refreshOptions, setRefreshOptions] = useState(false)
    const [showSuggestions, setShowSuggestions] = useState(true)
    const [currentChoiceIndex, setCurrentChoiceIndex] = useState(-1)
    const [toggleRefresh, setToggleRefresh] = useState(false)
    const [logicEnabled, setLogicEnabled] = useState(false);
    const [selectedQuestions, setSelectedQuestions] = useState([]);

    const currentTextBoxIndex = useSelector(state => state.autocompleteReducer?.currentTextBoxIndex)
    const attachmentAvailableInOptions = props.question.choices.filter((choice) => choice.image_attachment)?.length > 0

    const {t} = useTranslation()
    const optionFieldRef = useRef(null)

    const reducerCurrentOptionIndex = useSelector(state => state.optionsReducer?.currentOptionIndex)
    const currentQuestionIndex = useSelector(state => state.optionsReducer?.currentQuestionIndex)
    const selectedValue = useSelector(state => state.optionsReducer?.selectedValue)
    const suggestionClicked = useSelector(state => state.optionsReducer?.suggestionClicked)
    const subscriptionData = useSelector(state => state.userReducer?.subscriptionData)
    const showShared = useSelector(state => state.appDashboardReducer.showShared)
    const searchMatches = useSelector(state => state.optionsReducer?.matches)
    const wordList = useSelector(state => state.optionsReducer?.wordList)
    const formData = useSelector(state => state.appReducer.formData)
    const showLogicJumpBasedOnUser = showShared ? PremiumJSON[subscriptionData?.subscription_plan]?.logic_jump && PremiumJSON[formData?.subscription_data?.current_subscription?.plan]?.logic_jump : PremiumJSON[subscriptionData?.subscription_plan]?.logic_jump


    const {
        questionRequiredChanged,
        questionOtherOptionChanged,
        questionOtherOptionDisabled
    } = useQuestionMgr()
    const {addLogicJumpToQuestion, addTargetQuestionId, generateUniqueString, addLogicQuestionId, removeLogic, selectTargetForTextQuestion} = useLogicJumpMgr()
    const reduxDispatch = useDispatch()

    const handleChange = (optionIndex, value) => {
        setShowSuggestions(true)
        updateOptionValue(props.index, optionIndex, value)

        if (optionIndex === selectedAnswerIndex) {
            setSelectedAnswerText(value)
        }

        document.onclick = () => {
            reduxDispatch({type: OPTIONS_ACTION_TYPE.CLEAR_SUGGESTIONS})
            document.onclick = null;
        }

        /* If 'document.onclick' above fires, the code below will never be reached. */
        if (value.length > 0) {
            reduxDispatch({type: OPTIONS_ACTION_TYPE.GET_KEYWORDS, payload: value})
        } else {
            reduxDispatch({type: OPTIONS_ACTION_TYPE.CLEAR_SUGGESTIONS})
        }
        if (showSuggestions) {
            const parentDiv = document.querySelector(`#star-body-container` + props.index)
            parentDiv.style.height = "auto"
        }
    }
    const handleAddClick = (currentOptionIndex, e) => {
        if (props.formData?.is_quiz && props.formData?.response_count > 0) {
            reduxDispatch({type: BUILDER_ACTION_TYPE.SET_SHOW_QUIZ_ACTION_BLOCKED_POP_UP, payload: true})
            return
        }
        addAnimationStyles(true)
        addOptionValue(props.index, currentOptionIndex)

        if (props.isQuiz) {
            let correctOptionIndex = props.question.choices.findIndex(choice => choice.is_answer)

            if (currentOptionIndex < correctOptionIndex) {
                setSelectedAnswerIndex(prevState => prevState + 1)
            }
        }
    }
    const handleCorrectAnswerClick = (currentIndex, answer, e) => {
        if (currentIndex !== selectedAnswerIndex && (answer !== '')) {
            setSelectedAnswerIndex(currentIndex)
            setSelectedAnswerText(answer)
            props.updateAnswers(ANSWER_EVENT.UPDATE_CHOICE_CORRECT_ANSWER, {
                questionIndex: props.index, correctAnswerIndex: currentIndex
            })
        }
    }
    const handleDeleteClick = (currentOptionIndex, e) => {
        if (props.formData?.is_quiz && props.formData?.response_count > 0) {
            reduxDispatch({type: BUILDER_ACTION_TYPE.SET_SHOW_QUIZ_ACTION_BLOCKED_POP_UP, payload: true})
            return
        }
        addAnimationStyles(false)
        if (props.isQuiz) {
            if (currentOptionIndex === props.question.choices.findIndex(choice => choice.is_answer)) {
                props.updateAnswers(ANSWER_EVENT.UPDATE_CHOICE_CORRECT_ANSWER, {
                    questionIndex: props.index, correctAnswerIndex: -1
                })
                setSelectedAnswerText(null)
                setSelectedAnswerIndex(-1)
            }
        }
        removeOptionValue(props.index, currentOptionIndex)

        setToggleRefresh(prevState => !prevState)
    }

    const addOthersOptionClicked = () => {
        if (props.question.is_logic_enabled) {
            reduxDispatch({type: BUILDER_ACTION_TYPE.SET_BUILDER_MESSAGE, payload: 'Action cannot be performed'})
            reduxDispatch({type: BUILDER_ACTION_TYPE.SET_BUILDER_TOAST_MESSAGE, payload: true})
        }
    }

    useEffect(() => {
        setSelectedAnswerIndex(() => {
            if (props.isQuiz) {
                let selectedAnswerIndex = -1
                setSelectedAnswerText('')

                props.question.choices.map((choice, index) => {
                    if (choice.is_answer) {
                        selectedAnswerIndex = index
                        setSelectedAnswerText(choice.label)
                    }
                })

                return selectedAnswerIndex
            } else {
                return -1
            }
        })
    })

    useEffect(() => {
        if (props.refreshOptions) {
            setRefreshOptions(true)
            props.removeRefreshOptions()
        }
    }, [props.refreshOptions])

    useEffect(() => {
        if (refreshOptions) {
            setRefreshOptions(false)
        }
    }, [refreshOptions])

    useEffect(() => {
        if (currentQuestionIndex === props.index && reducerCurrentOptionIndex === currentChoiceIndex && searchMatches.length > 0) {
            optionFieldRef.current.scrollTop = optionFieldRef.current.scrollHeight
        }
    }, [searchMatches])

    useEffect(() => {
        if (selectedValue !== '' && currentQuestionIndex === props.index && reducerCurrentOptionIndex === currentChoiceIndex) {
            reduxDispatch({
                type: OPTIONS_ACTION_TYPE.GET_WORDLIST,
                payload: selectedValue
            })

            setToggleRefresh(prevState => !prevState)
        }
    }, [selectedValue])

    useEffect(() => {
        if (wordList && wordList.length > 0 && currentQuestionIndex === props.index && reducerCurrentOptionIndex === currentChoiceIndex) {
            wordList.forEach((w, index) => {
                updateOptionValue(props.index, currentChoiceIndex + index, w)

                if (index < wordList.length - 1) {
                    addOptionValue(props.index, currentChoiceIndex + index)
                }
            })

            reduxDispatch({type: OPTIONS_ACTION_TYPE.CLEAR_SELECTED_VALUE_AND_WORDLIST})
        }
    }, [wordList])

    useEffect(() => {
        if (currentTextBoxIndex > -1) {
            setCurrentChoiceIndex(-1)
        }
    }, [currentTextBoxIndex])

    useEffect(() => {
        setLogicEnabled(props.question.is_logic_enabled);
    }, [props.question.is_logic_enabled]);

    const addOthersOptionForChoiceQuestion = (index, checkedStatus) => {
        addOtherOptionForChoiceQuestion(index, checkedStatus)
    }

    const addLogicForChoiceQuestion = (index, checkedStatus) => {
        questionRequiredChanged(index, checkedStatus)
        questionOtherOptionChanged(index, checkedStatus)
        questionOtherOptionDisabled(index, checkedStatus)
        addLogicJumpToQuestion(index, checkedStatus)
        setLogicEnabled(checkedStatus)
        if (!checkedStatus) {
            selectTargetForTextQuestion(index, '0')
            removeLogic(index)
            setSelectedQuestions([])
        }
    }

    const handleOptionBlur = () => {
        if (!suggestionClicked) {
            reduxDispatch({type: OPTIONS_ACTION_TYPE.CLEAR_SUGGESTIONS})
        }
    }

    const handleOptionFocus = (index) => {
        setCurrentChoiceIndex(index)
        reduxDispatch({
            type: OPTIONS_ACTION_TYPE.SET_CURRENT_OPTION_VALUES,
            payload: {
                questionIndex: props.index,
                optionIndex: index,
                top: top + optionFieldRef.current.children[index]?.offsetHeight
            }
        })
    }

    const addAnimationStyles = (add) => {
        const parentDiv = document.querySelector(`#star-body-container` + props.index)

        if (attachmentAvailableInOptions) {
            parentDiv.style.overflowY = "auto"
            parentDiv.style.overflowY = "auto"
        } else if (add) {
            if (props.question.choices.length >= (isMobileOnly ? 3 : 5)) {
                parentDiv.style.overflowY = "auto"
            } else {
                parentDiv.style.height = `${parentDiv?.offsetHeight + (props.question.is_logic_enabled ? 88 : 60)}px`
                parentDiv.style.transition = "height " + .5 + "s ease"
            }
        } else {
            if (props.question.choices.length === 1) {
                parentDiv.style.height = "auto"
            }
            if ((props.question.choices.length <= (isMobileOnly ? 3 : 5)) && (props.question.choices.length > (isMobileOnly ? 0 : 1))) {
                parentDiv.style.overflowY = "hidden"
                parentDiv.style.height = `${parentDiv?.offsetHeight - 60}px`
                parentDiv.style.transition = "height " + .5 + "s ease"
            } else if (props.question.choices.length > (isMobileOnly ? 3 : 5)) {
                parentDiv.style.height = "auto"
                parentDiv.style.overflowY = "auto"
            }
        }
    }

    const handleCloseImage = (choiceIndex) => {
        setCurrentChoiceIndex(choiceIndex)
        setOptionAttachment(props.index, choiceIndex, "")
    }

    const handleScroll = () => {
        scroll = scroll + 1
        if (scroll > 5) {
            setShowSuggestions(false)
            scroll = 0
        }
    }

    const handleDragEnd = (result) => {
        if (!result.destination) {
            return;
        }

        const updatedItems = [...props.question.choices];
        let originalFormData = {...props.formData};
        const [reorderedItem] = updatedItems.splice(result.source.index, 1);
        updatedItems.splice(result.destination.index, 0, reorderedItem);
        const index = originalFormData.pages[0].questions.findIndex(obj => obj._id === props.question._id);
        if (index !== -1) {
            originalFormData.pages[0].questions[index].choices = updatedItems;
        }
        reduxDispatch({type: APP_ACTION_TYPE.SET_FORM_DATA, payload: originalFormData})
    };

    const onLongPressStart = (e) => {
        if (!isMobileOnly && !props.formData.is_logic_enabled) {
            reduxDispatch({type: BUILDER_ACTION_TYPE.SET_IS_DRAG_AND_DROP_DISABLED, payload: true})
        }
    };

    const onMouseLeft = (e) => {
        if (!isMobileOnly && !props.formData.is_logic_enabled) {
            reduxDispatch({type: BUILDER_ACTION_TYPE.SET_IS_DRAG_AND_DROP_DISABLED, payload: false})
        }
    }

    const switchClicked = () => {
        if (!showLogicJumpBasedOnUser) {
            if (showShared && !PremiumJSON[formData?.subscription_data?.current_subscription?.plan]?.logic_jump) {
                reduxDispatch({type: PREMIUM_ACTION_TYPE.SET_SHOW_FREE_OWNER_POP_UP, payload: true})
            } else {
                reduxDispatch({type: PREMIUM_ACTION_TYPE.SET_SHOW_UPGRADE_PLAN_POP_UP, payload: true})
            }
        } else if (formData.pages[0].questions.length <= 1 || formData.pages[0].questions.length - 1 === props.index) {
            reduxDispatch({type: BUILDER_ACTION_TYPE.SET_BUILDER_MESSAGE, payload: 'Action cannot be performed'});
            reduxDispatch({type: BUILDER_ACTION_TYPE.SET_BUILDER_TOAST_MESSAGE, payload: true})
        }
    }

    const handleJumpToQuestion = (choiceIndex, targetQuestionIndex) => {
        const newSelectedValues = [...selectedQuestions];
        newSelectedValues[choiceIndex] = targetQuestionIndex;
        setSelectedQuestions(newSelectedValues);
        if (targetQuestionIndex === "0" || targetQuestionIndex === "-1") {
            addLogicQuestionId(props.index, choiceIndex, targetQuestionIndex)
        } else if (props.formData?.pages[0]?.questions[targetQuestionIndex]?.logic_question_id) {
            addLogicQuestionId(props.index, choiceIndex, props.formData.pages[0].questions[targetQuestionIndex].logic_question_id)
        } else {
            const uniqueString = generateUniqueString();
            addTargetQuestionId(targetQuestionIndex, uniqueString);
            addLogicQuestionId(props.index, choiceIndex, uniqueString);
        }
    }
    const handleChoiceKeyDown = (e, choiceIndex) => {
        if (e.key === "Enter" && !window.event.shiftKey && !window.event.ctrlKey) {
            handleAddClick(choiceIndex, e)
        } else if (e.key === "ArrowUp") {
            if (choiceIndex > 0) {
                document.getElementById(`choice-question-text-input-${props.index}-${choiceIndex - 1}`).focus()
            } else {
                document.getElementById(`choice-question-text-input-${props.index}-${props.question?.choices?.length - 1}`).focus()
            }
        } else if (e.key === "ArrowDown") {
            if (choiceIndex < props.question?.choices?.length - 1) {
                document.getElementById(`choice-question-text-input-${props.index}-${choiceIndex + 1}`).focus()
            } else {
                document.getElementById(`choice-question-text-input-${props.index}-${0}`).focus()
            }
        } else if ((e.key === "Delete") && e.target?.value?.length === 0) {
            handleDeleteClick(choiceIndex, e)
        }
    }

    return (<div>
        <div id={'star-body-container' + props.index} className="star-body-container" ref={optionFieldRef} style={{
            maxHeight: isMobileOnly ? "170px" : "300px",
            height: "auto",
            overflowY: props.question.choices.length > (isMobileOnly ? 3 : 5) || attachmentAvailableInOptions ? "auto" : null
        }} onScroll={handleScroll}>
            <DragDropContext onDragEnd={handleDragEnd}>
                <Droppable droppableId="droppable">
                    {(provided) => (
                        <div {...provided.droppableProps}
                             ref={provided.innerRef}
                             style={{width: "100%"}}>
                            {(() => {
                                let values = props.question.choices.map((choice, choiceIndex) => ((() => {
                                    let questionNumber = 1;

                                    return (
                                        <Draggable key={props.question._id + choiceIndex}
                                                   draggableId={props.question._id + choiceIndex} index={choiceIndex}>
                                            {(provided, snapshot) => (
                                                <div ref={provided.innerRef}
                                                     {...provided.draggableProps}
                                                     {...provided.dragHandleProps}
                                                     key={choiceIndex} className="star-line-container-droppable"
                                                     style={{
                                                         ...provided.draggableProps.style,
                                                         borderRadius: snapshot.isDragging ? '6px' : 'none',
                                                         background: snapshot.isDragging ? '#F1F8FF' : 'white',
                                                         left: "auto !important",
                                                         top: "auto !important",
                                                         position: "relative"
                                                     }}
                                                     onMouseOver={onLongPressStart}
                                                     onMouseLeave={onMouseLeft}>
                                                    <div key={choiceIndex} className="star-line">
                                                        <div
                                                            className={`star-line-option-container ${props.question.is_logic_enabled && (props.question.type !== QUESTION_TYPE.CHECKBOX_CHOICE.key) ? 'star-line-option-container--logic-enabled' : ''}`}>
                                                            <div className='builder-option-drag-indicator'
                                                                 id='drag-indicator'>
                                                                <img
                                                                    src={dragImage}
                                                                    alt="Drag"
                                                                />
                                                            </div>
                                                            {(() => {
                                                                if (props.isQuiz) {
                                                                    if (choice.is_answer) {
                                                                        return (<img
                                                                            src={"../../../images/icons/builder/choice_checked.svg"}
                                                                            alt={"Option"}
                                                                            className="choice-line-start-img"
                                                                            onClick={(e) => handleCorrectAnswerClick(choiceIndex, choice.label, e)}
                                                                        />)
                                                                    }

                                                                    if (!choice.is_answer) {
                                                                        return (<img
                                                                            src={"../../../images/icons/builder/choice_unchecked.svg"}
                                                                            alt={"Option"}
                                                                            className="choice-line-start-img"
                                                                            onClick={(e) => handleCorrectAnswerClick(choiceIndex, choice.label, e)}
                                                                        />)
                                                                    }
                                                                }

                                                                let imgInfo = new QuestionTypeMenuIconInfo(QUESTION_TYPE[props.question.type])

                                                                if (!props.isQuiz) return (
                                                                    <img src={imgInfo.getImgSrcPath()}
                                                                         alt={imgInfo.getAltText()}
                                                                         className="option-line-start-img"/>)
                                                            })()}
                                                            <div className='sh-text-field'
                                                                 style={{marginBottom: '15px'}}>
                                                                <MaterialTextField
                                                                    id={`choice-question-text-input-${props.index}-${choiceIndex}`}
                                                                    data={{
                                                                        defaultValue: choice.label
                                                                    }}
                                                                    onChange={value => handleChange(choiceIndex, value)}
                                                                    maxLength={100}
                                                                    noLabel={true}
                                                                    placeholder={t(translationKeys.options)}
                                                                    hideHelpLine={true}
                                                                    onKeyDown={(e) => handleChoiceKeyDown(e, choiceIndex)}
                                                                    forceUpdateRefVarFocus={choice.setFocus ? choice.setFocus : false}
                                                                    setForceUpdateRefVarFocus={props.removeSetFocus}
                                                                    onBlur={handleOptionBlur}
                                                                    handleFocus={() => handleOptionFocus(choiceIndex)}
                                                                />
                                                            </div>
                                                            {props.question.type !== QUESTION_TYPE.DROPDOWN_CHOICE.key ?
                                                                <img
                                                                    src={choiceIndex !== currentChoiceIndex && props.question.choices[choiceIndex].image_attachment ? props.question.choices[choiceIndex].image_attachment : optionsImage}
                                                                    alt={"option image"}
                                                                    className={"option-line-end-img"}
                                                                    style={{
                                                                        width: "25px",
                                                                        height: "25px",
                                                                        paddingTop: "10px"
                                                                    }}
                                                                    onClick={() => {
                                                                        document.activeElement.blur()
                                                                        props.setShowMyImages(true)
                                                                        reduxDispatch({
                                                                            type: OPTIONS_ACTION_TYPE.SET_CURRENT_OPTION_VALUES,
                                                                            payload: {
                                                                                questionIndex: props.index,
                                                                                optionIndex: choiceIndex
                                                                            }
                                                                        })
                                                                    }}/> : null}
                                                            {isMobileOnly ? null :
                                                                <img
                                                                    src={"../../../images/icons/builder/option_add.svg"}
                                                                    alt={"Add Option"}
                                                                    className="option-line-end-img"
                                                                    style={{filter: props.formData?.is_quiz && props.formData?.response_count > 0 ? "grayscale(1)" : null}}
                                                                    onClick={(e) => handleAddClick(choiceIndex, e)}/>}
                                                            <img
                                                                src={isMobileOnly ? "../../../images/close_circle_grey.svg" : "../../../images/icons/builder/option_delete_blue.svg"}
                                                                alt={"Delete Option"}
                                                                className="option-line-end-img"
                                                                style={{filter: props.formData?.is_quiz && props.formData?.response_count > 0 ? "opacity(0.2)" : null}}
                                                                onClick={(e) => {
                                                                    if (formData?.response_count > 0 && props.refSavedData?.current?.pages[0]?.questions?.filter(question => question._id === props.question._id).length) {
                                                                        reduxDispatch({
                                                                            type: BUILDER_ACTION_TYPE.SET_SHOW_OPTION_DELETE_POP_UP,
                                                                            payload: true
                                                                        })
                                                                        reduxDispatch({
                                                                            type: BUILDER_ACTION_TYPE.SET_OPTION_DELETE_ACTION,
                                                                            payload: () => handleDeleteClick(choiceIndex, e)
                                                                        })
                                                                    } else {
                                                                        handleDeleteClick(choiceIndex, e)
                                                                    }
                                                                }}/>
                                                        </div>
                                                        {(logicEnabled && showLogicJumpBasedOnUser && isDesktop && (props.question.type !== QUESTION_TYPE.CHECKBOX_CHOICE.key)) &&
                                                            <div className={"logic-jump-to-question-container"}>
                                                                <p>Jump to</p>
                                                                <div
                                                                    className={'choice-body-add-logic-dropdown-container'}>
                                                                    <FormControl variant="standard" sx={{
                                                                        m: 1,
                                                                        minWidth: 220,
                                                                        margin: 0,
                                                                        width: '280px'
                                                                    }}>
                                                                        <Select
                                                                            labelId={`select-question-helper-label-${choiceIndex}`}
                                                                            id={`select-question-helper-${choiceIndex}`}
                                                                            label="Select Question"
                                                                            MenuProps={{
                                                                                style: {width: '280px'}, // Set the width of the dropdown menu
                                                                            }}
                                                                            value={selectedQuestions[choiceIndex] || (props?.question?.choices[choiceIndex]?.target_question_id !== ("0" || "-1") ? props?.formData?.pages[0]?.questions.findIndex((question) => props?.question?.choices[choiceIndex]?.target_question_id === question.logic_question_id) : props?.question?.choices[choiceIndex]?.target_question_id)}
                                                                            onChange={(e) => handleJumpToQuestion(choiceIndex, e.target.value)}
                                                                        >
                                                                            <MenuItem value="0"><span
                                                                                className={'logic-jump-question-to-select'}><img
                                                                                src={continueButton}
                                                                                alt={'continue to next question icon'}/> Continue to Next Question</span></MenuItem>
                                                                            <MenuItem value="-1"><span
                                                                                className={'logic-jump-question-to-select'}><img
                                                                                src={submitButton}
                                                                                alt={'submit form  icon'}/> Submit Form</span></MenuItem>
                                                                            {props.formData.pages[0].questions.map((object, index) => {
                                                                                if (object.type === 'SECTION') {
                                                                                    return (
                                                                                        index > props.index &&
                                                                                        (<MenuItem value={index}> <span
                                                                                            className={'logic-jump-question-to-select'}><img
                                                                                            src={sectionIcon}
                                                                                            alt={'section icon'}/> {object.title}</span></MenuItem>))
                                                                                } else {
                                                                                    const questionContent = `${questionNumber}. ${object.title}`;
                                                                                    questionNumber++;
                                                                                    return (
                                                                                        index > props.index &&
                                                                                        (<MenuItem value={index}> <span
                                                                                            className={'logic-jump-question-to-select'}>{questionContent}</span></MenuItem>))
                                                                                }
                                                                            })}
                                                                        </Select>
                                                                    </FormControl>
                                                                </div>
                                                            </div>}
                                                    </div>
                                                    {(() => {
                                                        if (currentQuestionIndex === props.index && showSuggestions && reducerCurrentOptionIndex === choiceIndex && searchMatches.length > 0) {
                                                            return (
                                                                <div style={{
                                                                    zIndex: 2,
                                                                    height: 'auto',
                                                                    width: '100%',
                                                                }}
                                                                     className='star-line'>
                                                                    <OptionAutocompleteSuggestions
                                                                        optionFieldRef={optionFieldRef}
                                                                        choiceIndex={choiceIndex}
                                                                        attachment={props.question?.attachment}
                                                                        choicesCount={props.question.choices.length}/>
                                                                </div>)
                                                        }
                                                    })()}
                                                    {choiceIndex === currentChoiceIndex && props.question.choices[choiceIndex].image_attachment && props.question.type !== QUESTION_TYPE.DROPDOWN_CHOICE.key ?
                                                        <div className='builder-question-text-input'>
                                                            <QuestionAttachmentComponent
                                                                key={ATTACHMENT_TYPE.IMAGE.key}
                                                                option={true}
                                                                attachment={{
                                                                    file_type: ATTACHMENT_TYPE.IMAGE.key,
                                                                    image_url: props.question.choices[choiceIndex].image_attachment
                                                                }}
                                                                imageHeight={"10vh"}
                                                                theme={"classic_theme"}
                                                                backgroundColorProvided={false}
                                                                onCloseClick={() => handleCloseImage(choiceIndex)}
                                                                userId={props.userId}
                                                            />
                                                        </div> : null}
                                                </div>
                                            )}
                                        </Draggable>
                                    )
                                })()))
                                return (values)
                            })()}
                        </div>)}
                </Droppable>
            </DragDropContext>
        </div>
        {isMobileOnly ? <div id={'star-body-container-options'}
                             style={{display: 'flex', justifyContent: "space-between", alignItems: "center"}}>
            {(() => {
                return (<>
                    {getQuestionCategory(props.question.type) === QUESTION_CATEGORY.CHOICE_TYPE && props.question.type !== QUESTION_TYPE.LINEAR_SCALE.key && props.question.type !== QUESTION_TYPE.STAR_RATING_SCALE.key ?
                        <div style={{
                            textAlign: 'start',
                            filter: props.formData?.is_quiz && props.formData?.response_count > 0 ? "opacity(0.2)" : null
                        }}>
                            <MaterialButton data={{
                                title: t(translationKeys.add_option),
                                customButtonContainerStyle: {
                                    borderRadius: '10px'
                                },
                                customButtonTextStyle: {
                                    fontFamily: 'Nunito Sans, Sans-serif, serif',
                                    fontSize: '14px'
                                }
                            }} handleClick={(e) => handleAddClick(currentChoiceIndex, e)}
                            />
                        </div> : null}
                    {QUESTION_TYPE[props.question.type] === QUESTION_TYPE.DROPDOWN_CHOICE || props.isQuiz || isMobileOnly ? null :
                        <div style={{width: '65%', display: "flex", justifyContent: "end"}}>
                            <div id={'choice-body-add-other-option'} className="choice-body-add-other-option">
                                {t(translationKeys.add_other_option)}
                            </div>
                            <div onClick={addOthersOptionClicked}>
                                <MaterialSwitch
                                    index={props.index}
                                    initialCheckedState={props.question.is_others_allowed}
                                    switchChanged={addOthersOptionForChoiceQuestion}
                                    isSwitchDisabled={props.question.is_others_disabled}
                                />
                            </div>
                        </div>}
                </>)
            })()}
        </div> : <div id={'star-body-container-options'}
                      style={{display: 'flex', marginBottom: '10px'}}>
            {(props.question.type === QUESTION_TYPE.CHECKBOX_CHOICE.key) ?
                <div style={{display: "flex", flexGrow: "1"}}>
                    <TextQuestionBody {...props}/>
                </div> : <div style={{display: "flex", flexGrow: "1"}}>
                    <div id={'choice-body-add-logic-option'} className="choice-body-add-logic-jump-option">
                        {t(translationKeys.add_logic)}
                    </div>
                    <div onClick={switchClicked} style={{cursor: "pointer"}}>
                        <MaterialSwitch
                            index={props.index}
                            initialCheckedState={props.question.is_logic_enabled}
                            switchChanged={addLogicForChoiceQuestion}
                            customStyle={(props.formData?.pages[0].questions.length <= 1 || props.formData?.pages[0].questions.length - 1 === props.index) ? {cursor: 'not-allowed'} : null}
                            isSwitchDisabled={(props.formData?.pages[0].questions.length <= 1 || props.formData?.pages[0].questions.length - 1 === props.index) || !showLogicJumpBasedOnUser}
                        />
                    </div>
                </div>}

            <div
                style={QUESTION_TYPE[props.question.type] === QUESTION_TYPE.CHECKBOX_CHOICE || QUESTION_TYPE[props.question.type] === QUESTION_TYPE.MULTIPLE_CHOICE ? {
                    display: 'flex',
                } : {display: 'none'}}>
                {(() => {
                    if (!props.isQuiz && !isMobileOnly) {
                        return (<div style={{display: "flex", paddingRight: "10px"}}>
                            <div id={'choice-body-add-other-option'} className="choice-body-add-other-option">
                                {t(translationKeys.add_other_option)}
                            </div>
                            <div onClick={addOthersOptionClicked}>
                                <MaterialSwitch
                                    index={props.index}
                                    initialCheckedState={props.question.is_others_allowed}
                                    switchChanged={addOthersOptionForChoiceQuestion}
                                    isSwitchDisabled={props.question.is_others_disabled}
                                />
                            </div>
                        </div>)
                    }
                })()}
                <div style={{display: "flex", paddingLeft: "10px", borderLeft: '1px solid #E0E0E0'}}>
                    <div id={'choice-body-add-other-option'} className="choice-body-add-other-option">
                        {t(translationKeys.shuffle_options)}
                    </div>
                    <MaterialSwitch
                        index={props.index}
                        initialCheckedState={props.question.is_options_shuffled}
                        switchChanged={addShufflerOptionsForChoiceQuestion}
                    />
                </div>
            </div>

        </div>}
        {(() => {
            if (props.isQuiz) {
                return (<ChoiceQuestionCorrectAnswerAndMarks
                    index={props.index}
                    selectedAnswerText={selectedAnswerText}
                    marks={props.question.marks}
                    updateAnswers={props.updateAnswers}
                />)
            }
        })()}
    </div>)
}

export default ChoiceBody
