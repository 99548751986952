import React from 'react'
import SummaryQuestionCard from "./SummaryQuestionCard";
import {QUESTION_TYPE} from "../../../shared/utils/constants";
import {isMobileOnly} from "react-device-detect";

const SummarizedQuestionContainer = props => {
    const summaryDataSet = props.summaryDataSet;
    let questionCards = [];
    const questions = props.formData?.pages[0].questions;
    if (props.formData?.setting.is_email) {
        questionCards.push(<SummaryQuestionCard key={"email"}
                                                index={-1}
                                                formFiller={props.formFiller}
                                                questionSNo={questionCards.length}
                                                respondentsDataRef={props.respondentsDataRef}
                                                changeResponseView={props.changeResponseView}
                                                summaryDataSet={summaryDataSet}
                                                isEmail={props.formData?.setting?.is_email}
                                                formTitle={props.formData?.welcome_screen.title}/>)
    }
    for (let i = 0; i < questions?.length; i++) {
        if (summaryDataSet.questionsData[i]?.questionId === questions[i]?._id) {
            if (summaryDataSet.questionsData[i].type !== QUESTION_TYPE.SECTION.key) {
                questionCards.push(<SummaryQuestionCard key={questions[i]?._id}
                                                        index={i}
                                                        formFiller={props.formFiller}
                                                        questionSNo={questionCards.length}
                                                        attachment={questions[i]?.attachment}
                                                        respondentsDataRef={props.respondentsDataRef}
                                                        changeResponseView={props.changeResponseView}
                                                        summaryDataSet={summaryDataSet}
                                                        isEmail={props.formData?.setting?.is_email}
                                                        formTitle={props.formData?.welcome_screen.title}/>)
            }
        }
    }
    return (
        <div id='summarized-question-card' className='summarized-question-container' style={isMobileOnly ? {height: "auto", paddingBottom: "10vh"} : null}>
            {questionCards}
        </div>
    )
}
export default SummarizedQuestionContainer
