import React, {useEffect, useRef, useState} from 'react'
import {
    ATTACHMENT_TYPE,
    BUILDER_TYPE,
    getQuestionCategory,
    QUESTION_CATEGORY,
    QUESTION_TYPE,
    QUIZ_QUESTION_TYPE
} from "../../shared/utils/constants";
import MaterialButton, {ButtonTypes} from "../../shared/components/MaterialButton";
import {quizCustomMarkUpdateService} from "../../shared/utils/quiz-api-services";
import sortMinimizeBlue from '../../../public/images/sort_minimize_blue.svg'
import sortMore from '../../../public/images/sort_more.svg'
import {isMobileOnly} from "react-device-detect";
import MaterialTextField, {TEXT_FIELD_TYPES} from "../../shared/components/MaterialTextField";
import Loader from "../../shared/components/Loader";
import {editRightsRemovedDialog} from "../../app/builder/components/helpers/BuilderContainerHelper"
import {translationKeys} from "../../localizations/translationKeys-localization";
import {t} from "i18next";
import {useSelector} from "react-redux";
import styles from "../../form/css/question-attachment.module.css";

let tempMarkData;
const fullMarkStyle = {color: '#46A24A'};
const intermediateMarkStyle = {color: '#FBC02D'};
const zeroMarkStyle = {color: '#DC554A'};
const DefaultStyle = {
    maxHeight: '100px',
    height: 'auto',
    overflow: 'auto',
    display: 'block',
    color: '#212121'
};
const questionTitleContainerStyle = {paddingBottom: '10px', borderBottom: '1px solid #c2c2c2'};
const invalidMarkContainerStyle = {
    width: '100%',
    display: 'flex',
    visibility: 'hidden',
    justifyContent: 'flex-end',
    fontSize: '14px',
    color: 'red'
};
const closeButtonData = {
    title: t(translationKeys.close),
    buttonType: ButtonTypes.OUTLINED,
    bg_color: '#004ba0'
};

function DetailedQuizQuestionCard(props) {
    const selectedLanguage = useSelector(state => state.languageReducer?.selectedLanguage)
    const formResponsesData = useSelector(state => state.responsesReducer?.formResponsesData)
    let summaryData = props.summaryQuestionsData[props.currentQuestionNumber && props.currentQuestionNumber > 0 ? props.currentQuestionNumber - 1 : 0];
    const isChoiceType = (getQuestionCategory(summaryData.type) === QUESTION_CATEGORY.CHOICE_TYPE);
    const isAutoValidatedQuestion = (isChoiceType || summaryData.type === QUESTION_TYPE.SHORT_TEXT.key);
    const [showEvaluatedTag, setShowEvaluatedTag] = useState(summaryData.evaluated_marks !== undefined)
    const [showLoader, setShowLoader] = useState(false);
    const [showEditRightsRemoved, setShowEditRightsRemoved] = useState(false)
    const MarkInputRef = useRef(null);
    const invalidMarkContainerRef = useRef(null);
    const isLimitReached = formResponsesData?.formResponses?.respondents?.length !== props.responseCount
    const markInputCustomStyleObject = {
        '--mdc-theme-primary': '#9e9e9e',
        width: '85px',
        textAlign: 'center',
        color: isChoiceType ? '#9e9e9e' : '#4D4D4D'
    };
    const getNextUnEvaluatedQuestionIndex = currentQuestionIndex => {
        for (let i = (currentQuestionIndex + 1); i < props.summaryQuestionsData.length; i++) {
            let questionSummary = props.summaryQuestionsData[i];
            if (questionSummary.evaluated_marks === undefined) {
                return i;
            }
        }
        return currentQuestionIndex;
    }

    const setInvalidMarkTagVisibility = (visibility) => {
        invalidMarkContainerRef.current ? invalidMarkContainerRef.current.style.visibility = visibility : null
    }
    const saveClickEvent = () => {
        if (!isValidMarkEntered(MarkInputRef.current.value, summaryData.marks)) {
            setInvalidMarkTagVisibility('visible')
            setTimeout(() => {
                setInvalidMarkTagVisibility('hidden')
            }, 5000)
        } else if (parseInt(MarkInputRef.current.value) !== summaryData.evaluated_marks) {
            let data = {
                answerId: props.answerData._id,
                questionId: summaryData.questionId,
                markToUpdate: MarkInputRef.current.value,
                isPublish: !!props.isPublish,
                quizId: props.quizId
            };
            const successFunction = result => {
                setShowLoader(false)

                if (result.user_can_save_data === false) {
                    setShowEditRightsRemoved(true)
                    return
                }

                if (result && MarkInputRef.current) {
                    const mark = (tempMarkData === undefined) ? MarkInputRef.current.value : tempMarkData[0]
                    props.updateMarksInQuestionsData(parseInt(mark), (props.currentQuestionNumber - 1))
                    if (!showEvaluatedTag) {
                        setShowEvaluatedTag(true);
                    }
                    let nextQuestionIndex = getNextUnEvaluatedQuestionIndex((props.currentQuestionNumber - 1));
                    if ((props.currentQuestionNumber - 1) === nextQuestionIndex) {
                        closeClickEvent()
                    } else {
                        props.setCurrentQuestionNumber(nextQuestionIndex + 1)
                    }
                }
            };
            const failureFunction = error => {
                setShowLoader(false)
                console.log(error);
            };
            tempMarkData = [MarkInputRef.current.value, summaryData.questionId, props.answerData._id]
            setShowLoader(true)
            quizCustomMarkUpdateService(data, successFunction, failureFunction);
        } else {
            closeClickEvent()
        }
    };
    const closeClickEvent = () => {
        props.setDetailedIndividualCard(false);
    };

    const onChangeMarkInput = (value) => {
        if (isMobileOnly) {
            if (isValidMarkEntered(value, summaryData.marks)) {
                tempMarkData = [value, summaryData.questionId, props.answerData._id];
            } else {
                tempMarkData = undefined
            }
        }
    }

    useEffect(() => {
        if (isMobileOnly
            && tempMarkData
            && (tempMarkData[2] === props.answerData._id)
            && (tempMarkData[1] === summaryData.questionId)) {
            MarkInputRef.current.value = tempMarkData[0]
        } else {
            tempMarkData = undefined
        }
    }, [innerHeight])

    useEffect(() => {
        setShowEvaluatedTag(summaryData.evaluated_marks !== undefined)
    }, [summaryData.questionId])

    const openAttachmentLink = () => {
        if (props.attachment.url) {
            window.open(props.attachment.url)
        }
    }

    return (
        <>

            {summaryData.title ?
                <div id='individual-response-question-card' className='individual-response-question-card answer-sheet-detailed-question-card'>
                    <div id={'question-title-container'} className='question-title-container'
                         style={questionTitleContainerStyle}>
                        <p className='answer-sheet-response-detailed-card-question-title' style={{whiteSpace: 'unset'}}>
                            {(props.currentQuestionNumber ? props.currentQuestionNumber : 1) + '. ' + summaryData.title}
                        </p>
                    </div>
                    {props.attachment ? <div style={{
                        paddingBottom: "15px",
                        borderBottom: "solid 1px #9B9B9B"
                    }}>
                        {props.attachment && props.attachment.file_type === ATTACHMENT_TYPE.IMAGE.key ? <div style={{
                            justifyContent: "center",
                            display: 'flex',
                            height: isMobileOnly ? null : '300px'
                        }}>
                            <img src={props.attachment?.image_url}
                                 alt={'image_Url'}
                                 style={{
                                     display: 'flex',
                                     width: isMobileOnly ? '100%' : null,
                                     objectFit: 'cover',
                                     maxHeight: !isMobileOnly ? null : '300px'
                                 }}/>
                        </div> : null}
                        {props.attachment && props.attachment.file_type !== ATTACHMENT_TYPE.IMAGE.key ?
                            <div className={styles.question_attachment_web_container} style={{
                                display: 'flex',
                                cursor: "pointer",
                                backgroundColor: '#f5f5f5'
                            }} onClick={openAttachmentLink}>
                                <img style={{
                                    display: 'flex',
                                    width: '30%'
                                }}
                                     alt={'image'}
                                     onError={e => e.target.src = '/images/link.svg'}
                                     src={props.attachment?.image_url ? props.attachment?.image_url : '/images/link.svg'}/>
                                <div className={styles.question_attachment_text_container}
                                     style={{
                                         display: 'flex',
                                         width: isMobileOnly ? '100%' : '90%'
                                     }}>
                                    <p className={styles.question_attachment_title}
                                       style={{
                                           color: '#212121'
                                       }}>
                                        {props.attachment?.title}
                                    </p>
                                    <p className={styles.question_attachment_description}>
                                        {props.attachment?.description}
                                    </p>
                                    <p id={props.id ? props.id : null} className={styles.question_attachment_url}
                                       style={{
                                           color: '#0000ee'
                                       }}>
                                        {props.attachment?.url}
                                    </p>
                                </div>
                            </div> : null}
                    </div> : null}
                    <div id={'detailed-view-response-text'} className='detailed-view-response-text' onScroll={e => e.stopPropagation()}
                         style={(summaryData.evaluated_marks !== undefined) ? summaryData.evaluated_marks > 0 ?
                             (summaryData.evaluated_marks === summaryData.marks) ? fullMarkStyle : intermediateMarkStyle : zeroMarkStyle : isAutoValidatedQuestion ? zeroMarkStyle : DefaultStyle}>
                        {summaryData.given_answer ? summaryData.given_answer : t(translationKeys.no_answer) }
                    </div>
                    {!(props.currentQuestionNumber > 0 && props.summaryQuestionsData[props.currentQuestionNumber - 1].type === QUIZ_QUESTION_TYPE.FILE_UPLOAD.key) && !(summaryData.evaluated_marks === summaryData.marks) ?
                        <ShowCorrectAnswerContainer isQuizEdit={true}
                                                    index={props.currentQuestionNumber - 1}
                                                    summaryData={summaryData}/> : null}
                    <div className='custom-mark-status-container'>
                        <div>
                            <div id={'question-validated-tag'} className='question-validated-tag'>
                                {showEvaluatedTag ? <EvaluatedTag/> : <PendingTag/>}
                            </div>
                            <div id={'multiple-question-validation'} style={{marginTop: '5px'}} className='question-validated-tag'>
                                <span>{getValidationText(isAutoValidatedQuestion, summaryData)}</span>
                            </div>
                        </div>
                        {props.isQuizV2 && !isLimitReached ?
                            <div id={'mark-data-container'} className='mark-data-container'>
                                <div style={{marginRight: '5px'}}>
                                    <MaterialTextField
                                        data={{
                                            hint: t(translationKeys.mark),
                                            inputType: "number",
                                            isReadOnly: isChoiceType,
                                            defaultValue: (showLoader && (tempMarkData !== undefined)) ? tempMarkData[0] : summaryData.evaluated_marks ? summaryData.evaluated_marks : 0
                                        }}
                                        onChange={onChangeMarkInput}
                                        textFieldType={TEXT_FIELD_TYPES.OUTLINED}
                                        inputRef={MarkInputRef}
                                        customStyleObject={markInputCustomStyleObject}/>
                                </div>
                                <span
                                    className='mark-data'>/ {summaryData.marks ? summaryData.marks : 0}</span>
                            </div> :
                            <div>
                                <span id={'mark-data'} className='mark-data'>0/1 {t(translationKeys.mark)}</span>
                            </div>
                        }
                    </div>
                    <div id={'invalid-mark-data'} ref={invalidMarkContainerRef} style={invalidMarkContainerStyle}>
                        <span>{t(translationKeys.enter_valid_mark)}</span>
                    </div>
                    <div id={'summary-mark-data-button'} style={{width: '100%', display: 'flex', justifyContent: 'flex-end', marginTop: '10px'}}>
                        <div style={{display: 'flex', flexDirection: 'row'}}>
                            <MaterialButton handleClick={closeClickEvent}
                                            data={closeButtonData}/>
                            {props.isQuiz && !isChoiceType && !isLimitReached ?
                                <div style={(selectedLanguage === 'fa' || selectedLanguage === 'ar') ? {marginRight: '10px'} : {marginLeft: '10px'}}>
                                    <MaterialButton handleClick={saveClickEvent}
                                                    data={{
                                                        title: t(translationKeys.save),
                                                        buttonType: ButtonTypes.CONTAINED,
                                                        bg_color: '#004ba0'
                                                    }}/>
                                </div> : null}
                        </div>
                    </div>
                    {
                        (() => {
                                if (showEditRightsRemoved)
                                    return editRightsRemovedDialog(BUILDER_TYPE.QUIZ, () => {
                                        setShowEditRightsRemoved(false)
                                        closeClickEvent()
                                    })
                            }
                        )()
                    }
                    {showLoader ? <Loader height={'100%'} width={'100%'} left={'0'}/> : null}
                </div> : null
            }
        </>
    )
}

export default DetailedQuizQuestionCard

function getValidationText(isAutoValidatedQuestion, summaryData) {
    if (isAutoValidatedQuestion) {
        switch (summaryData.type) {
            case  QUESTION_TYPE.MULTIPLE_CHOICE.key:
                return t(translationKeys.choice_type_validation_note)
            case QUESTION_TYPE.DROPDOWN_CHOICE.key:
                return t(translationKeys.dropdown_answer_type_validation_note)
            case QUESTION_TYPE.SHORT_TEXT.key:
                return t(translationKeys.short_answer_type_validation_note)
            default :
                return t(translationKeys.validated_automatically)
        }
    } else {
        if (summaryData.type === QUESTION_TYPE.FILE_UPLOAD.key) {
            return t(translationKeys.fileupload_answer_type_validation_note)
        } else {
            return t(translationKeys.long_answer_type_validation_note)
        }
    }
}

export function ShowCorrectAnswerContainer(props) {
    const [showCorrectAnswer, setShowCorrectAnswer] = useState(false);
    useEffect(() => {
        showCorrectAnswer ? setShowCorrectAnswer(false) : null
    }, [props.index])
    return (
        <React.Fragment>
            <div className='quiz-question-validation-container'>
                <div className='quiz-show-answer-button'
                     onClick={e => {
                         e.stopPropagation()
                         showCorrectAnswer ? setShowCorrectAnswer(false) : setShowCorrectAnswer(true)
                     }}>
                    <span>{showCorrectAnswer ? t(translationKeys.hide_answer) : t(translationKeys.show_answer)}</span>
                    <img style={{width: '24px', height: '24px'}}
                         src={showCorrectAnswer ? sortMinimizeBlue : sortMore}
                         alt="more-icon"/>
                </div>
                {showCorrectAnswer ? <span className='printable-title'>{t(translationKeys.answer)}</span> : <span/>}
            </div>
            <div className='quiz-correct-answer-container'
                 onScroll={e => e.stopPropagation()}
                 style={showCorrectAnswer ? {display: 'flex'} : {display: 'none'}}>
                {props.summaryData.correct_answer && props.summaryData.correct_answer.length > 0 ? props.summaryData.correct_answer : t(translationKeys.answer_not_available)}
            </div>
        </React.Fragment>
    )
}

const EvaluatedTag = () => {
    return <>
        <div className='validated-tick-mark-circle'>
            <img alt='validated'
                 src='images/done.svg'/>
        </div>
        <span>{t(translationKeys.evaluated)}</span>
    </>
}
const PendingTag = () => {
    return <>
        <img alt='pending' style={{width: '14px', marginRight: '6px'}}
             src='images/pending_validation.svg'/>
        <span>{t(translationKeys.pending_evaluation)}</span>
    </>
}

const isValidMarkEntered = (enteredMark, fullMark) => {
    if (enteredMark) {
        const givenMark = parseInt(enteredMark);
        return !((givenMark > fullMark) || givenMark < 0);
    } else return false
}
