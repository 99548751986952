import React, {useState} from "react"
import {useDispatch, useSelector} from "react-redux"
import optionAddBlue from "../../../../public/images/icons/builder/option_add_blue.svg"
import optionDeleteBlue from "../../../../public/images/icons/builder/option_delete_blue.svg"
import optionDeleteGrey from "../../../../public/images/icons/builder/option_delete_grey.svg"
import GridBodyRows from "./GridBodyRows"
import GridBodyColumns from "./GridBodyColumns"
import useGridMgr from "../../hooks/useGridMgr"
import {APP_ACTION_TYPE} from "../../reducers/appReducer"
import {BUILDER_ACTION_TYPE} from "../../reducers/builderReducer";
import TextQuestionBody from "./TextQuestionBody";

const GridBodyDesktop = (props) => {
    const reduxDispatch = useDispatch()

    const [toggleRefresh, setToggleRefresh] = useState(false)
    const [showRemoveRowButton, setShowRemoveRowButton] = useState(true)
    const [showRemoveColumnButton, setShowRemoveColumnButton] = useState(true)

    const {addItem, COMPONENT_TYPE, removeItem} = useGridMgr()
    const formData = useSelector(state => state.appReducer.formData)
    const columnsLength = useSelector(state => state.appReducer.formData?.pages?.[0]?.questions?.[props.index]?.columns?.length)
    const rowsLength = useSelector(state => state.appReducer.formData?.pages?.[0]?.questions?.[props.index]?.rows?.length)

    const colButtons = () => {
        return (
            <div className={'grid-line-bottom'}>
                <img src={optionAddBlue} alt={"Add Line"}
                     className="option-line-end-img"
                     style={{width: "25px"}}
                     onClick={(e) => {
                         if (columnsLength >= 20) {
                             reduxDispatch({
                                 type: APP_ACTION_TYPE.SET_SHOW_COLUMN_OR_ROW_LIMIT_REACHED_POP_UP,
                                 payload: true
                             })
                         } else {
                             addItem(props.index, COMPONENT_TYPE.COLUMN, e)
                         }
                     }}
                />
                {
                    (() => {
                        if (columnsLength > 1 && showRemoveColumnButton)
                            return (
                                <img src={optionDeleteBlue}
                                     alt={"Delete Line"}
                                     className="option-line-end-img"
                                     onClick={(e) => {
                                         if (formData.response_count > 0 && props.refSavedData?.current?.pages[0]?.questions?.filter(question => question._id === props.question._id).length) {
                                             reduxDispatch({type: BUILDER_ACTION_TYPE.SET_SHOW_OPTION_DELETE_POP_UP, payload: true})
                                             reduxDispatch({type: BUILDER_ACTION_TYPE.SET_OPTION_DELETE_ACTION, payload: () => {
                                                     removeItem(props.index, COMPONENT_TYPE.COLUMN, parseInt(e.target.parentElement.parentElement.id.split("-").pop()), setShowRemoveColumnButton)
                                                 }})
                                         } else {
                                             setShowRemoveColumnButton(false)
                                             removeItem(props.index, COMPONENT_TYPE.COLUMN, parseInt(e.target.parentElement.parentElement.id.split("-").pop()), setShowRemoveColumnButton)
                                         }
                                     }}
                                />
                            )

                        return (
                            <img src={optionDeleteGrey}
                                 alt={"Delete Line"}
                                 className="option-line-end-img"
                            />
                        )
                    })()
                }
            </div>
        )
    }

    const rowButtons = () => {
        return (
            <div className={'grid-line-bottom'}>
                <img src={optionAddBlue} alt={"Add Line"}
                     className="option-line-end-img"
                     style={{width: "25px"}}
                     onClick={(e) => {
                         if (rowsLength >= 20) {
                             reduxDispatch({
                                 type: APP_ACTION_TYPE.SET_SHOW_COLUMN_OR_ROW_LIMIT_REACHED_POP_UP,
                                 payload: true
                             })
                         } else {
                             addItem(props.index, COMPONENT_TYPE.ROW, e)
                         }
                     }}
                />
                {
                    (() => {
                        if (rowsLength > 1 && showRemoveRowButton)
                            return (
                                <img src={optionDeleteBlue}
                                     alt={"Delete Line"}
                                     className="option-line-end-img"
                                     onClick={(e) => {
                                         if (formData.response_count > 0 && props.refSavedData?.current?.pages[0]?.questions?.filter(question => question._id === props.question._id).length) {
                                             reduxDispatch({type: BUILDER_ACTION_TYPE.SET_SHOW_OPTION_DELETE_POP_UP, payload: true})
                                             reduxDispatch({type: BUILDER_ACTION_TYPE.SET_OPTION_DELETE_ACTION, payload: () => {
                                                     removeItem(props.index, COMPONENT_TYPE.ROW, parseInt(e.target.parentElement.parentElement.id.split("-").pop()), setShowRemoveRowButton)
                                                     setToggleRefresh(!toggleRefresh)
                                                 }})
                                         } else {
                                             setShowRemoveRowButton(false)
                                             removeItem(props.index, COMPONENT_TYPE.ROW, parseInt(e.target.parentElement.parentElement.id.split("-").pop()), setShowRemoveRowButton)
                                             setToggleRefresh(!toggleRefresh)
                                         }
                                     }}
                                />
                            )

                        return (
                            <img src={optionDeleteGrey}
                                 alt={"Delete Line"}
                                 className="option-line-end-img"
                            />
                        )
                    })()
                }
            </div>
        )
    }

    return (
        <>
            <div style={{display: "flex", justifyContent: "flex-start"}}>
                <GridBodyRows index={props.index} buttons={rowButtons()}/>
                <GridBodyColumns index={props.index} buttons={colButtons()}/>
            </div>
            <div style={{paddingTop: "8px"}}>
                <TextQuestionBody {...props}/>
            </div>
        </>
    )
}

export default GridBodyDesktop