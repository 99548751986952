import MaterialButton from "../../shared/components/MaterialButton";
import React, {useEffect, useState} from 'react';
import {isClassicTheme, isDarkTheme, isImageTheme} from "../utils/theme-color-helper";
import styles from '../css/thank-card-component.module.css'
import {PREVIEW_TYPE} from "../../shared/utils/constants";
import {ACTION_TYPE, FORM_SCREEN, isCalledFromAppResponseCollector, isMinWidth600} from "../utils/utils";
import {initializeUserInfoData, updateUserInfoData} from "../utils/user-info-data-helper";
import {isMobileOnly} from "react-device-detect";
import PromotionalComponent from "./PromotionalComponent";
import viewAnswerBlackIcon from '../../../public/images/icons/view_answer_black.svg'
import viewAnswerWhiteIcon from '../../../public/images/icons/view_answer_white.svg'
import {SCREENS_ACTION_TYPE} from "../reducers/screensReducer";
import {useDispatch, useSelector} from "react-redux";
import {FORM_DATA_ACTION_TYPE} from "../reducers/formDataReducer";
import {QUESTIONS_ACTION_TYPE} from "../reducers/questionsReducer";
import {
    addAnswer,
    addResponse,
    editResponse,
    getResponsesOfResponder,
    submitFormQuiz
} from "../../shared/utils/forq-api-services";
import * as JSONWebToken from "jsonwebtoken";
import {PremiumJSON} from "../../shared/utils/helper";
import {translationKeys} from "../../localizations/translationKeys-localization";
import {useTranslation} from "react-i18next";
import {getPublicFormResponsesForResponders} from "../../shared/utils/sash-v2-api";
import {getSummaryDataSet} from "../../app/responses/utils/summary-data";
import STATUS_PAGE_PATH from "../../status-page-path";

export const ThankCardComponent = (props) => {
    const {t} = useTranslation()
    const preview = useSelector(state => state.screensReducer.preview)
    const formData = useSelector(state => state.formDataReducer.formData)
    const themeColor = useSelector(state => state.themeReducer.themeColor)
    const storageUsed = useSelector(state => state.formDataReducer.storageUsed)
    const userInfoData = useSelector(state => state.formDataReducer.userInfoData)
    const formResponses = useSelector(state => state.formDataReducer.formResponses)
    const editResponseId = useSelector(state => state.formDataReducer.editResponseId)
    const responderResponses = useSelector(state => state.formDataReducer.responderResponses)
    const totalUserResponseCount = useSelector(state => state.formDataReducer.totalUserResponseCount)
    const dispatch = useDispatch()
    const [submitTick, setSubmitTick] = useState(false);
    const [answerID, setAnswerID] = useState();
    const customButtonContainerStyle = {
        margin: '5px',
        padding: '0 24px',
        fontSize: '12px',
        textAlign: 'center',
        width: '200px'
    };
    const customButtonTextStyle = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: isClassicTheme(formData?.theme) ? '#212121' : '#ffffff'
    };

    const customButtonImageStyle = {
        width: '14px',
        height: '14px'
    }
    const buttonBackgroundColor = isImageTheme(formData?.theme) ? themeColor?.primaryColor
        : isDarkTheme(formData?.theme) ? '#000000' : '#ffffff';

    useEffect(() => {
        if (!preview) {
            let apiRoute = '/response';
            let formSubmitData = {
                form_id: formData?._id,
                email: "",
                responses: formResponses,
                submit_time: String(new Date().getTime())
            };
            const successFunction = (res) => {
                if (res.status === 200 || res.result) {
                    if (window.localStorage !== null) {
                        window.localStorage?.setItem(String(formData?._id), String(formData?._id))
                    }
                    setSubmitTick(true);
                    formData?.is_quiz && res.data ? setAnswerID(res.data.answer_id) : null
                    formData?.is_quiz && formData?.setting.is_email ? setAnswerID(res.answer_id) : null
                    window.submitSuccess()
                    if (window?.localStorage?.getItem("responder_token")) {
                        getResponsesOfResponder(formData._id, (res) => {
                            if (res.responder_responses.length > 0) {
                                dispatch({
                                    type: FORM_DATA_ACTION_TYPE.SET_RESPONDER_RESPONSES,
                                    payload: res.responder_responses
                                })
                            }
                        }, failureFunction)
                    }
                    if (!formData?.is_quiz && formData?.setting.allow_summary_view) {
                        getPublicFormResponsesForResponders(formData?._id, function (responseData) {
                            if (responseData) {
                                props.setSummaryDataSet(getSummaryDataSet(responseData.formResponses, responseData.formData))
                                props.setFormResponsesData(responseData)
                            } else {
                                location.replace(STATUS_PAGE_PATH.ERROR);
                            }
                        }, function () {
                            location.replace(STATUS_PAGE_PATH.ERROR);
                        });
                    }
                }
            }

            const failureFunction = (err) => {
                console.log(err)
            }

            if (formData?.is_quiz
                && formSubmitData) {
                formSubmitData.quiz_data = {
                    user_info: userInfoData,
                };
                if (formData?.is_quizzory_v2) {
                    formSubmitData.is_quizzory_v2 = formData?.is_quizzory_v2;
                }
                apiRoute = '/answer';
            } else if (!formData?.is_quiz && formSubmitData && userInfoData) {
                if (formData.setting.is_email && window?.localStorage?.getItem("responder_token")) {
                    const responderUserName = JSONWebToken.decode(window?.localStorage?.getItem("responder_token"))?.responder_name
                    updateUserInfoData(0, responderUserName, userInfoData)
                }
                formSubmitData.form_user_data = userInfoData
            }
            if (props.previewType) {
                setSubmitTick(true);
            } else {
                formSubmitData.storage_used = storageUsed
                if (editResponseId) {
                    editResponse(editResponseId, formData?._id, formResponses, successFunction, failureFunction)
                } else if (formData?.setting?.is_email && !formData.is_quiz) {
                    addResponse(formSubmitData, successFunction, failureFunction)
                } else if (formData?.setting?.is_email && formData.is_quiz) {
                    addAnswer(formSubmitData, successFunction, failureFunction)
                } else {
                    submitFormQuiz(apiRoute, formSubmitData, successFunction, failureFunction)
                }
            }
        } else setSubmitTick(true)
    }, []);

    function handleQuizResult() {
        answerID ? window.location.replace("/answer-sheet/" + answerID) : null
    }

    const handleEditResponseClick = () => {
        const successFunction = (res) => {
            if (res?.result) {
                dispatch({
                    type: QUESTIONS_ACTION_TYPE.SET_QUESTIONS,
                    payload: null
                })
                dispatch({
                    type: FORM_DATA_ACTION_TYPE.SET_FORM_RESPONSES,
                    payload: res.responder_responses[res.responder_responses.length - 1].responses
                })
                dispatch({
                    type: FORM_DATA_ACTION_TYPE.SET_EDIT_RESPONSE_ID,
                    payload: res.responder_responses[res.responder_responses.length - 1]._id
                })
                dispatch({type: QUESTIONS_ACTION_TYPE.SET_FILE_UPLOAD_COMPLETE, payload: false})
                dispatch({type: SCREENS_ACTION_TYPE.SET_FORM_SCREEN, payload: FORM_SCREEN.FORM_PAGE})
            }
        }
        const failureFunction = (err) => {
            console.log(err)
        }
        getResponsesOfResponder(formData._id, successFunction, failureFunction)
    }

    const handleResponseHistoryClick = () => {
        dispatch({type: QUESTIONS_ACTION_TYPE.SET_FILE_UPLOAD_COMPLETE, payload: false})
        dispatch({type: SCREENS_ACTION_TYPE.SET_FORM_SCREEN, payload: FORM_SCREEN.RESPONSE_HISTORY})
    }

    const isPCPreview = !props.previewType || props.previewType === PREVIEW_TYPE.DESKTOP && isMinWidth600();
    return <div id="thank_screen_card" className={styles.thanks_card}
                style={{
                    ...{
                        backgroundColor: isDarkTheme(formData?.theme) ? 'rgba(255, 255, 255, 0.8)'
                            : isClassicTheme(formData?.theme) ? '#eeeeee' : null
                    }, ...isPCPreview ? {
                        width: isMobileOnly ? null : "auto",
                        maxWidth: isMobileOnly ? null : "30%",
                        padding: "15px"
                    } : null, ...isMobileOnly && !props.previewType ? {
                        marginTop: '25%'
                    } : null
                }}>
        <div id="thank_screen_card_header" style={{
            borderRadius: '12px',
            textAlign: 'center',
            padding: '15px'
        }}>
            <div className={`${styles.circle_loader} ${submitTick ? styles.load_complete : null}`}
                 id="circle-loader"
                 style={{
                     width: props.previewType === PREVIEW_TYPE.TABLET ? '50px' : null,
                     backgroundColor: isImageTheme(formData?.theme) ? themeColor?.primaryColor
                         : isDarkTheme(formData?.theme) ? '#000000' : null
                 }}>
                {submitTick ? <div id="submit-success" className={`${styles.submit_success} ${styles.draw}`}/> : null}
            </div>
            <div style={{display: 'flex', flexDirection: 'column', paddingLeft: '10px'}}>
                <h3 id="submitted_success_text"
                    style={{
                        ...isPCPreview ? {
                            fontWeight: "500",
                            margin: "5px 0 0 5px",
                            fontSize: "25px"
                        } : null
                    }}>
                    {submitTick ? "Submitted" : "Submitting..."}
                </h3>
                {submitTick ? <p id="submitted_success_text_thankInfo" style={{
                    color: "#757575",
                    fontSize: "14px",
                    margin: isPCPreview ? '5px' : null
                }}>
                    {formData?.setting?.confirmation_message ? formData?.setting?.confirmation_message : `${formData?.is_quiz ? t(translationKeys.default_custom_thank_message_quiz) : t(translationKeys.default_custom_thank_message)}`}
                </p> : null}
            </div>
        </div>
        {submitTick && !props.previewType && (!preview) && !isCalledFromAppResponseCollector() ?
            <div id="response-suggestion-container" className={styles.response_suggestion_container}>
                {(formData?.setting.allow_summary_view && formData.is_quiz) || (formData?.setting.allow_summary_view && props.formResponsesData?.formResponses?.respondents?.length === props.formResponsesData?.formData?.response_count && props.formResponsesData?.formResponses?.respondents?.length && !formData.is_quiz) ?
                    <MaterialButton data={{
                        title: formData?.is_quiz ? "View Results" : "Response Summary",
                        textTransform: "uppercase",
                        fontSize: "12px",
                        bg_color: buttonBackgroundColor,
                        imageFileName: !formData?.is_quiz ? (isClassicTheme(formData?.theme) ? 'icons/bar_chart_black.svg' : "icons/bar_chart-white-24dp.svg") : null,
                        base64ImageData: formData?.is_quiz ? (isClassicTheme(formData?.theme) ? viewAnswerBlackIcon : viewAnswerWhiteIcon) : null,
                        customButtonContainerStyle: customButtonContainerStyle,
                        customButtonTextStyle: customButtonTextStyle,
                        customImageStyle: customButtonImageStyle
                    }} handleClick={() => formData?.is_quiz ? handleQuizResult() : dispatch({
                        type: SCREENS_ACTION_TYPE.SET_FILLER_SUMMARY,
                        payload: true
                    })}
                    /> : null}
                {(!formData?.is_quiz && formData?.setting.allow_multiple_submit) ?
                    <MaterialButton data={{
                        title: "Add Response",
                        textTransform: "uppercase",
                        fontSize: "12px",
                        bg_color: buttonBackgroundColor,
                        imageFileName: isClassicTheme(formData?.theme) ? 'icons/add_black.svg' : "icons/add-white-24dp.svg",
                        customButtonContainerStyle: customButtonContainerStyle,
                        customButtonTextStyle: customButtonTextStyle,
                        customImageStyle: customButtonImageStyle
                    }} handleClick={() => {
                        let url = new URL(window.location.href);
                        if (totalUserResponseCount >= PremiumJSON[formData.is_quiz ? formData?.quiz_owner_subscription_plan : formData?.form_owner_subscription_plan].response_limit.collect - 1) {
                            window.location.reload()
                        } else {
                            dispatch({
                                type: FORM_DATA_ACTION_TYPE.SET_TOTAL_USER_RESPONSE_COUNT,
                                payload: totalUserResponseCount + 1
                            })
                        }
                        if (formData.is_quiz || (formData?.setting?.auto_close_time - new Date().getTime() < 0)) {
                            window.location.reload();
                        } else if (JSON.parse(url.searchParams.get("prefilleddata"))) {
                            window.location.reload();
                        } else {
                            dispatch({type: FORM_DATA_ACTION_TYPE.SET_USER_INFO_DATA, payload: formData.is_quiz && formData?.quiz_data?.user_info ? initializeUserInfoData(formData.quiz_data.user_info) : formData?.user_info_form?.user_info_1 ? initializeUserInfoData(formData.user_info_form) : undefined})
                            dispatch({type: FORM_DATA_ACTION_TYPE.SET_FORM_RESPONSES, payload: []})
                            dispatch({type: FORM_DATA_ACTION_TYPE.SET_EDIT_RESPONSE_ID, payload: null})
                            dispatch({type: QUESTIONS_ACTION_TYPE.SET_FILE_UPLOAD_COMPLETE, payload: false})
                            if (formData.welcome_screen.show_screen) {
                                dispatch({type: SCREENS_ACTION_TYPE.SET_FORM_SCREEN, payload: FORM_SCREEN.WELCOME_PAGE})
                            } else if (formData.abuse_scan_result) {
                                dispatch({
                                    type: SCREENS_ACTION_TYPE.SET_FORM_SCREEN,
                                    payload: FORM_SCREEN.ABUSE_WARNING
                                })
                            } else if (props.formData?.user_info_form?.user_info_1) {
                                dispatch({
                                    type: SCREENS_ACTION_TYPE.SET_FORM_SCREEN,
                                    payload: FORM_SCREEN.FORM_USER_INFO
                                })
                            } else {
                                dispatch({type: SCREENS_ACTION_TYPE.SET_FORM_SCREEN, payload: FORM_SCREEN.FORM_PAGE})
                            }
                        }
                    }}/> : null}
                <br/>
                {(!formData?.is_quiz && formData?.setting?.allow_response_edit) ?
                    <MaterialButton data={{
                        title: "Edit Response",
                        textTransform: "uppercase",
                        fontSize: "12px",
                        bg_color: buttonBackgroundColor,
                        imageFileName: isClassicTheme(formData?.theme) ? 'edit_grey.svg' : "edit_white.svg",
                        customButtonContainerStyle: customButtonContainerStyle,
                        customButtonTextStyle: customButtonTextStyle,
                        customImageStyle: customButtonImageStyle
                    }} handleClick={handleEditResponseClick}/> : null}
                {(!formData?.is_quiz && responderResponses?.length) ?
                    <MaterialButton data={{
                        title: "Response History",
                        textTransform: "uppercase",
                        fontSize: "12px",
                        bg_color: buttonBackgroundColor,
                        imageFileName: isClassicTheme(formData?.theme) ? 'history_grey.svg' : "history.svg",
                        customButtonContainerStyle: customButtonContainerStyle,
                        customButtonTextStyle: customButtonTextStyle,
                        customImageStyle: customButtonImageStyle
                    }} handleClick={handleResponseHistoryClick}
                    /> : null}
            </div> : !isCalledFromAppResponseCollector() ?
                <MaterialButton data={{
                    title: "Refresh",
                    textTransform: "uppercase",
                    fontSize: "12px",
                    bg_color: buttonBackgroundColor,
                    imageFileName: isClassicTheme(formData?.theme) ? "icons/refresh_black_24dp.svg" : "icons/refresh_white_24dp.svg",
                    customButtonContainerStyle: customButtonContainerStyle,
                    customButtonTextStyle: customButtonTextStyle,
                    customImageStyle: customButtonImageStyle
                }} handleClick={() => {
                    if (!props.previewType && !submitTick) {
                        window.location.reload();
                        return
                    }
                    const formScreen = formData?.welcome_screen.show_screen ? FORM_SCREEN.WELCOME_PAGE :
                        (formData?.is_quiz ? FORM_SCREEN.QUIZ_USER_INFO : FORM_SCREEN.FORM_PAGE)
                    dispatch({
                        type: FORM_DATA_ACTION_TYPE.SET_USER_INFO_DATA,
                        payload: formData?.is_quiz ? initializeUserInfoData(formData?.quiz_data.user_info) : formData?.user_info_form?.user_info_1 ? initializeUserInfoData(formData.user_info_form) : undefined
                    })
                    dispatch({type: SCREENS_ACTION_TYPE.SET_FORM_SCREEN, payload: formScreen})
                    dispatch({type: ACTION_TYPE.SET_ANSWER_COUNT, payload: 0})
                    dispatch({type: ACTION_TYPE.SET_FILE_UPLOAD_ANSWER_COUNT, payload: 0})
                    dispatch({type: QUESTIONS_ACTION_TYPE.SET_FILE_UPLOAD_COMPLETE, payload: false})
                    if (props.setSelectedPreviewScreen) {
                        props.setSelectedPreviewScreen(formScreen)
                    }
                }}/> : null
        }
        {(props.previewType || preview || isCalledFromAppResponseCollector() || PremiumJSON[formData?.subscription_data?.current_subscription?.plan]?.remove_branding) ? null :
            <PromotionalComponent/>
        }
    </div>;
}
