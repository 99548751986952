import MenuIconInfo from "../../../shared/classes/MenuIconInfo"
/**
 * @param {number} key - Unique Key
 * @param {MenuIconInfo} iconInfo - The Path to the Icon
 * @param {string} text - Text to Display
 */
class GenericMenuItem {
    constructor(key, iconInfo, text) {
        this.Key = key
        this.ImgPath = iconInfo
        this.Text = text
    }

    getKey() {
        return this.Key
    }

    getText() {
        return this.Text
    }

    getIconInfo() {
        return this.ImgPath
    }
}

export default GenericMenuItem