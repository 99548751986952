import React from "react"
import ModalDialog from "../../../../shared/components/ModalDialog"
import {doNavigation, SurveyHeartMenuItem} from "../BuilderLeftNavigatorHelper"
import {getBlockedWordsText} from "../../../../shared/utils/get-blocked-words-found"
import {
    BUILDER_TYPE,
    getQuestionGroupV2,
    getQuestionTypes,
    getQuizQuestionGroup,
    getQuizQuestionTypes,
    HIGHLIGHT_TEXT,
    QUESTION_TYPE,
    QUIZ_QUESTION_TYPE,
    REFRESH_OPTIONS,
    SET_FOCUS,
    SHOW_MENU
} from "../../../../shared/utils/constants";
import QuestionTypeMenuItem from "../../classes/QuestionTypeMenuItem"
import QuestionTypeMenuIconInfo from "../../classes/QuestionTypeMenuIconInfo"
import {maxNumberOfFileUploadOTsHasBeenReached, removeProp} from "./BuilderHelper"
import {addEventListenerForSidebar, removeEventListenerForSidebar} from "../../../../shared/utils/scroll-helper"
import {ANSWER_EVENT} from "../QuizBuilderContainer"
import {QUIZ_BASE_URL} from "../../../../shared/utils/quiz-api-services";
import {isMobile} from "react-device-detect"
import {translationKeys} from "../../../../localizations/translationKeys-localization";
import {t} from "i18next";
import {APP_ACTION_TYPE} from "../../../reducers/appReducer";
import {BUILDER_ACTION_TYPE} from "../../../reducers/builderReducer";
import {PREMIUM_ACTION_TYPE} from "../../../reducers/premiumReducer";

const _ = require('lodash');

export const addMissingChoiceOptions = (question, optionAdded) => {
    let ctr = 1
    question.choices = question.choices.map((item) => {
        if (item.label.trim() === '') {
            item.label = `${t(translationKeys.option)} ${ctr}`
            ctr++
            optionAdded = true
        } else if (item.label.startsWith(t(translationKeys.option))) {
            ctr++
        }

        return item
    })
    return optionAdded
}

export const editRightsRemovedDialog = (builderType, action) => {
    let builder = ''

    if (builderType.key === BUILDER_TYPE.FORM.key) {
        builder = 'Form'
    } else if (builderType.key === BUILDER_TYPE.QUIZ.key) {
        builder = 'Quiz'
    }

    return (
        <div>
            <ModalDialog
                header={t(translationKeys.edit_rights_removed)}
                body={builder === 'Form' ? t(translationKeys.your_rights_to_edit_this_form_have_been_removed) + " " + t(translationKeys.please_contact_form_owner_for_more_info) : t(translationKeys.your_rights_to_edit_this_quiz_have_been_removed) + " " + t(translationKeys.please_contact_quiz_owner_for_more_info)}
                buttons={[
                    {
                        text: t(translationKeys.ok),
                        action:
                            () => {
                                action()
                            }
                    }
                ]}
            />
        </div>
    )
}

/**
 * @param {object} sourceData
 * @param {string} userID
 * @param builderType
 * @param {BUILDER_TYPE} userID
 * @param action for mobile only
 */
export const unapprovedModalDialog = (sourceData, userID, builderType, action) => (
    <ModalDialog
        header={builderType.displayText === 'Form' ? t(translationKeys.unapproved_form) : t(translationKeys.unapproved_quiz)}
        body={[<span
            style={{fontWeight: 'bold'}}>{'"' + (sourceData?.welcome_screen?.title ? sourceData.welcome_screen.title : '') + '"'}</span>,
            <br/>, (builderType.displayText === 'Form' ? t(translationKeys.unapproved_form_alert) : t(translationKeys.unapproved_quiz_alert))]}
        buttons={
            [
                {
                    text: t(translationKeys.close),
                    action: () => {
                        if (action) {
                            action()
                        }
                        doNavigation(SurveyHeartMenuItem)
                    }
                },
                {
                    text: t(translationKeys.provide_explanation),
                    action:
                        () => {
                            if (action) {
                                action()
                            }
                            doNavigation(SurveyHeartMenuItem)

                            const data = JSON.stringify([{
                                qid: '5eb4e0c90be7456b687e61fd',
                                data: sourceData?.welcome_screen?.title ? sourceData.welcome_screen.title : ''
                            }, {
                                qid: '5eb3d579c411ae0a298e7a0a',
                                data: ((sourceData.is_quiz ? (QUIZ_BASE_URL + '/id/') : (location.origin + '/form/')) + (sourceData?._id ? sourceData._id : ''))
                            }, {
                                qid: '5eb3d579c411ae0a298e7a0b',
                                data: userID
                            }, {
                                qid: '5ec4ec0cc9dff62eb822a839',
                                data: getBlockedWordsText(sourceData.blocked_words_found)
                            }])
                            const formId = sourceData.is_quiz ? '5f211698b770fe0f8c274122' : '5eb3d579c411ae0a298e7a08';
                            const domainName = location.href.split('/').slice(0, 3).join('/');
                            open(encodeURI(domainName + '/form/' + formId + '?prefilleddata=' + data + '#form/0'));
                        }
                }
            ]
        }
    />
)

let _data = null
let _dispatch
let _isQuiz = null
let _focusTitle = null
let _builderCreationSource = null
let _modalDialogButtons = null
let _modalDialogBody = null
let _modalDialogTitle = null
let _setQuestionAnswerToFocus = null
let _refreshAnswerErrors = null
let _questionAnswerToFocus = null
let _refCardsContainer = null
let _isMobile = null

export const dataHasChanged = (currentData, savedFormDataRef) => {
    if ((!savedFormDataRef || !savedFormDataRef.current) && currentData?.pages?.[0]?.questions?.length === 0) {
        return false
    }

    let dataToCompare = JSON.parse(JSON.stringify(currentData))

    // The 'showQTMenu', 'tempAttachmentValue' and '_id' properties need to be removed when checking for changes.
    removeProp([dataToCompare?.pages?.[0]?.questions], 'showQTMenu')
    removeProp([dataToCompare?.pages?.[0]?.questions], 'tempAttachmentValue')
    removeProp([dataToCompare?.pages?.[0]?.questions], '_id')

    removeProp([savedFormDataRef.current?.pages?.[0]?.questions], 'showQTMenu')
    removeProp([savedFormDataRef.current?.pages?.[0]?.questions], 'tempAttachmentValue')
    removeProp([savedFormDataRef.current?.pages?.[0]?.questions], '_id')

    if (_.isEqual(dataToCompare?.pages?.[0]?.questions, savedFormDataRef.current?.pages?.[0]?.questions) &&
        (_.isEqual(dataToCompare?.welcome_screen, savedFormDataRef.current?.welcome_screen)) &&
        (_.isEqual(dataToCompare?.setting, savedFormDataRef.current?.setting)) &&
        (_.isEqual(dataToCompare?.user_info_form, savedFormDataRef.current?.user_info_form)) &&
        (_.isEqual(dataToCompare?.theme, savedFormDataRef.current?.theme))) {
        if (dataToCompare?.is_quiz) {
            return !_.isEqual(dataToCompare?.quiz_data, savedFormDataRef.current?.quiz_data);
        }

        return false
    } else {
        return true
    }
}

export const questionHasBeenAdded = (data) => {
    if (data) {
        if (_isQuiz) {
            return (data.pages[0].questions.filter(q => q.type !== QUIZ_QUESTION_TYPE.SECTION.key).length !== 0);
        } else {
            return (data.pages[0].questions.filter(q => q.type !== QUESTION_TYPE.SECTION.key).length !== 0)
        }
    }

    return false
}

export const resetFocusTitle = (value) => {
    _focusTitle = value
}

export const initializeHelperVars = (data, dispatch, isQuiz, focusTitle, refModalDialogButtons, refModalDialogBody, refModalDialogTitle, refBuilderCreationSource, refCardsContainer, isMobile, setQuestionAnswerToFocus, questionAnswerToFocus, refreshAnswerErrors) => {
    _data = data
    _isQuiz = isQuiz
    _dispatch = dispatch

    if (_focusTitle === null) {
        // By checking for 'null', we make sure that _focusTitle is initialized only once.
        _focusTitle = focusTitle
    }

    _modalDialogButtons = refModalDialogButtons
    _modalDialogBody = refModalDialogBody
    _modalDialogTitle = refModalDialogTitle
    _builderCreationSource = refBuilderCreationSource
    _refCardsContainer = refCardsContainer
    _isMobile = isMobile
    _setQuestionAnswerToFocus = setQuestionAnswerToFocus
    _questionAnswerToFocus = questionAnswerToFocus
    _refreshAnswerErrors = refreshAnswerErrors
}
export const showModalDialog = (title, body, buttonsArray) => {
    _modalDialogTitle.current = title
    _modalDialogBody.current = body
    _modalDialogButtons.current = buttonsArray ? buttonsArray : [
        {
            text: t(translationKeys.ok),
            action:
                () => {
                    _dispatch({type: BUILDER_ACTION_TYPE.SET_SHOW_MODAL_DIALOG, payload: false})
                }
        }
    ]

    _dispatch({type: BUILDER_ACTION_TYPE.SET_SHOW_MODAL_DIALOG, payload: true})
}

const removeHighlightText = () => {
    removeProp([..._data.pages[0].questions], HIGHLIGHT_TEXT)
}

const removeSetFocus = () => {
    removeProp([..._data.pages[0].questions], SET_FOCUS)
}

const removeRefreshOptions = () => {
    removeProp([..._data.pages[0].questions], REFRESH_OPTIONS)
}

export const getQuestionProps = (question, index, qnInfo, userId, isMobile, setHideBottomBar) => {
    let questionProps = {
        question: question,
        initialValueToSelect: initialValueToSelect,
        initialImage: initialImage,
        initialImageAltText: initialImageAltText,
        key: index,
        index: index,
        number: qnInfo[0] === undefined ? 0 : qnInfo[0].questionNumber,
        userId: userId,
        GetQTGroups: _isQuiz ? GetQuizQTGroups : GetFormQTGroups,
        refreshQTMenuDisplay: refreshQTMenuDisplay,
        totalQuestionsCount: _data.pages[0].questions.length,
        getQuestionType: getQuestionType,
        // removeFocusFromAllQuestionCards: removeFocusFromAllQuestionCards,
        setScrollingListener: setScrollingListener,
        hideBottomBar: setHideBottomBar,
        removeSetFocus: removeSetFocus,
        removeHighlightText: removeHighlightText,
        showModalDialog: showModalDialog,
        isQuiz: _isQuiz,
        removeRefreshOptions: removeRefreshOptions
    }

    if (_isQuiz) {
        questionProps['updateAnswers'] = updateAnswers
        questionProps['refreshAnswerErrors'] = _refreshAnswerErrors
        questionProps['questionAnswerToFocus'] = _questionAnswerToFocus
    }

    if (question.showQTMenu) {
        questionProps[SHOW_MENU] = true
    }

    if (question.setFocus) {
        questionProps[SET_FOCUS] = true
    }

    if (question.refreshOptions) {
        questionProps[REFRESH_OPTIONS] = true
    }

    return questionProps
}

const updateAnswers = (answerEvent, params) => {
    switch (answerEvent) {
        case ANSWER_EVENT.UPDATE_CHOICE_CORRECT_ANSWER:
            updateChoiceCorrectAnswer(params.questionIndex, params.correctAnswerIndex)
            break
        case ANSWER_EVENT.UPDATE_TEXT_CORRECT_ANSWER:
            updateTextCorrectAnswer(params.questionIndex, params.newValue)
            break
        case ANSWER_EVENT.UPDATE_MARKS:
            updateMarks(params.questionIndex, params.newValue)
            break
        case ANSWER_EVENT.SET_QUESTION_ANSWER_TO_FOCUS:
            _setQuestionAnswerToFocus(params.questionIndex)
            break
    }
}

const updateChoiceCorrectAnswer = (questionIndex, correctAnswerIndex) => {
    let updatedData = {..._data}
    let updatedQs = [...updatedData.pages[0].questions]
    let updatedChoiceItems = updatedQs[questionIndex].choices.map((choice, index) => {
        choice.is_answer = index === correctAnswerIndex

        return choice
    })

    updatedQs[questionIndex].choices = updatedChoiceItems
    updatedData.pages[0].questions = updatedQs
    _dispatch({type: APP_ACTION_TYPE.SET_FORM_DATA, payload: updatedData})
}

const updateTextCorrectAnswer = (questionIndex, newValue) => {
    let updatedData = {..._data}
    let updatedQs = [...updatedData.pages[0].questions]

    updatedQs[questionIndex].correct_answer = newValue
    updatedData.pages[0].questions = updatedQs
    _dispatch({type: APP_ACTION_TYPE.SET_FORM_DATA, payload: updatedData})
}

const updateMarks = (questionIndex, newValue) => {
    let updatedData = {..._data}
    let updatedQs = [...updatedData.pages[0].questions]

    updatedQs[questionIndex].marks = parseInt(newValue)
    updatedData.pages[0].questions = updatedQs
    _dispatch({type: APP_ACTION_TYPE.SET_FORM_DATA, payload: updatedData})
}

const getQuestionType = (index) => {
    return _data.pages[0].questions[index].type
}

let initialValueToSelect = undefined
let initialImage = undefined
let initialImageAltText = undefined

export const getTitleProps = () => {
    return {
        title: t(translationKeys.title),
        description: t(translationKeys.description),
        setTitle: setTitle,
        setDesc: setDesc,
        isSection: false,
        defaultTitle: _data?.welcome_screen?.title,
        defaultDesc: _data?.welcome_screen?.description,
        focusFormTitle: _focusTitle
    }
}

export const popInitialSelectMenuItems = (questionType) => {
    let selectedQuestionTypeMenuInfo

    if (_isQuiz) {
        selectedQuestionTypeMenuInfo = new QuestionTypeMenuIconInfo(QUIZ_QUESTION_TYPE[questionType])

        initialValueToSelect = QUIZ_QUESTION_TYPE[questionType].key
    } else {
        selectedQuestionTypeMenuInfo = new QuestionTypeMenuIconInfo(QUESTION_TYPE[questionType])

        initialValueToSelect = QUESTION_TYPE[questionType].key
    }

    initialImage = selectedQuestionTypeMenuInfo.getImgSrcPath()
    initialImageAltText = selectedQuestionTypeMenuInfo.getAltText()
    return initialImage
}

export const popSelectValues = (questionType, logicEnabled) => {
    let menuItems = []
    let questionTypes
    if (_isQuiz) {
        questionTypes = getQuizQuestionTypes(getQuizQuestionGroup(QUIZ_QUESTION_TYPE[questionType]))
    } else {
        if (logicEnabled) {
            questionTypes = getQuestionTypes(getQuestionGroupV2(QUESTION_TYPE[questionType]))
        }
        else {
            questionTypes = getQuestionTypes(getQuestionGroupV2(QUESTION_TYPE[questionType]))
        }
    }

    questionTypes.map((qt, index) => (
        (() => {
            let qtMenuItem

            if (_isQuiz) {
                qtMenuItem = new QuestionTypeMenuItem(index, new QuestionTypeMenuIconInfo(QUIZ_QUESTION_TYPE[qt.key]), QUIZ_QUESTION_TYPE[qt.key])
            } else {
                qtMenuItem = new QuestionTypeMenuItem(index, new QuestionTypeMenuIconInfo(QUESTION_TYPE[qt.key]), QUESTION_TYPE[qt.key])
            }

            menuItems.push({
                key: qtMenuItem.QuestionTypeValue.key,
                displayText: qtMenuItem.QuestionTypeValue.displayText,
                altText: qtMenuItem.QuestionTypeValue.displayText,
                imgPath: `${qtMenuItem.ImgPath.defaultPath}${qtMenuItem.ImgPath.imgBaseFile}`
            })
        })()
    ))

    return menuItems
}

export const getSectionProps = (question, index) => {
    return {
        question: question,
        key: index,
        index: index,
        isSection: true,
        title: t(translationKeys.section_title),
        description: t(translationKeys.section_description),
        setDesc: setSectionDesc,
        setTitle: setSectionTitle,
        defaultTitle: question.title,
        defaultDesc: question.description,
        includeIndexInOnChange: true,
        GetQTGroups: _isQuiz ? GetQuizQTGroups : GetFormQTGroups,
        refreshQTMenuDisplay: refreshQTMenuDisplay,
        totalQuestionsCount: _data.pages[0].questions.length,
        setScrollingListener: setScrollingListener
    }
}

export const getMenuItemQT = newQuestionInfo => {
    // Example newQuestionInfo input "2-1". The "2" is the index of the QTGroup and the "1" is the index of the QT within the Group.
    let arrInfo = newQuestionInfo.split("-")
    let qtGroup = _isQuiz ? GetQuizQTGroups()[arrInfo[0]] : GetFormQTGroups()[arrInfo[0]]

    return qtGroup[arrInfo[1]]
}

export const refreshQTMenuDisplay = index => {
    const updatedData = {..._data}

    if (index === -1) {
        removeProp(updatedData.pages[0].questions, 'showQTMenu')
    } else {
        updatedData.pages[0].questions[index].showQTMenu = true
    }
    _dispatch({type: APP_ACTION_TYPE.SET_FORM_DATA, payload: updatedData})
}
export const setScrollingListener = isDragging => {
    if (isDragging) {
        addEventListenerForSidebar(document.getElementsByClassName('builder-box1')[0])
    } else {
        removeEventListenerForSidebar()
    }
}

export const GetFormQTGroups = () => {
    let keyCtr = 0

    let ShortAnswerQTMenuItem = new QuestionTypeMenuItem(keyCtr++, new QuestionTypeMenuIconInfo(QUESTION_TYPE.SHORT_TEXT), QUESTION_TYPE.SHORT_TEXT)
    let LongAnswerQTMenuItem = new QuestionTypeMenuItem(keyCtr++, new QuestionTypeMenuIconInfo(QUESTION_TYPE.LONG_TEXT), QUESTION_TYPE.LONG_TEXT)
    let EmailQTMenuItem = new QuestionTypeMenuItem(keyCtr++, new QuestionTypeMenuIconInfo(QUESTION_TYPE.EMAIL), QUESTION_TYPE.EMAIL)
    let NumberQTMenuItem = new QuestionTypeMenuItem(keyCtr++, new QuestionTypeMenuIconInfo(QUESTION_TYPE.NUMBER), QUESTION_TYPE.NUMBER)
    let FileUploadQTMenuItem = new QuestionTypeMenuItem(keyCtr++, new QuestionTypeMenuIconInfo(QUESTION_TYPE.FILE_UPLOAD), QUESTION_TYPE.FILE_UPLOAD)
    let MultipleChoiceQTMenuItem = new QuestionTypeMenuItem(keyCtr++, new QuestionTypeMenuIconInfo(QUESTION_TYPE.MULTIPLE_CHOICE), QUESTION_TYPE.MULTIPLE_CHOICE)
    let CheckBoxQTMenuItem = new QuestionTypeMenuItem(keyCtr++, new QuestionTypeMenuIconInfo(QUESTION_TYPE.CHECKBOX_CHOICE), QUESTION_TYPE.CHECKBOX_CHOICE)
    let DropDownQTMenuItem = new QuestionTypeMenuItem(keyCtr++, new QuestionTypeMenuIconInfo(QUESTION_TYPE.DROPDOWN_CHOICE), QUESTION_TYPE.DROPDOWN_CHOICE)
    let StarRatingQTMenuItem = new QuestionTypeMenuItem(keyCtr++, new QuestionTypeMenuIconInfo(QUESTION_TYPE.STAR_RATING_SCALE), QUESTION_TYPE.STAR_RATING_SCALE)
    let LinearScaleQTMenuItem = new QuestionTypeMenuItem(keyCtr++, new QuestionTypeMenuIconInfo(QUESTION_TYPE.LINEAR_SCALE), QUESTION_TYPE.LINEAR_SCALE, true)
    let SmileQTMenuItem = new QuestionTypeMenuItem(keyCtr++, new QuestionTypeMenuIconInfo(QUESTION_TYPE.SMILEY), QUESTION_TYPE.SMILEY, true)
    let MultipleChoiceGridQTMenuItem = new QuestionTypeMenuItem(keyCtr++, new QuestionTypeMenuIconInfo(QUESTION_TYPE.MULTIPLE_CHOICE_GRID), QUESTION_TYPE.MULTIPLE_CHOICE_GRID, true)
    let CheckBoxGridQTMenuItem = new QuestionTypeMenuItem(keyCtr++, new QuestionTypeMenuIconInfo(QUESTION_TYPE.CHECKBOX_GRID), QUESTION_TYPE.CHECKBOX_GRID, true)
    let DateQTMenuItem = new QuestionTypeMenuItem(keyCtr++, new QuestionTypeMenuIconInfo(QUESTION_TYPE.DATE), QUESTION_TYPE.DATE)
    let TimeQTMenuItem = new QuestionTypeMenuItem(keyCtr++, new QuestionTypeMenuIconInfo(QUESTION_TYPE.TIME), QUESTION_TYPE.TIME)
    let SectionQTMenuItem = new QuestionTypeMenuItem(keyCtr++, new QuestionTypeMenuIconInfo(QUESTION_TYPE.SECTION), QUESTION_TYPE.SECTION)

    // QTs are placed in groups that are separated by bottom border lines when the menu is shown.
    let QTGroup1 = [ShortAnswerQTMenuItem, LongAnswerQTMenuItem]
    let QTGroup2 = [EmailQTMenuItem, NumberQTMenuItem]
    let QTGroup3 = [FileUploadQTMenuItem]
    let QTGroup4 = [MultipleChoiceQTMenuItem, CheckBoxQTMenuItem, DropDownQTMenuItem]
    let QTGroup5 = [StarRatingQTMenuItem]
    let QTGroup6 = [LinearScaleQTMenuItem]
    let QTGroup7 = [MultipleChoiceGridQTMenuItem, CheckBoxGridQTMenuItem]
    let QTGroup8 = [SmileQTMenuItem]
    let QTGroup9 = [DateQTMenuItem, TimeQTMenuItem]
    let QTGroup10 = [SectionQTMenuItem]

    return [QTGroup1, QTGroup2, QTGroup3, QTGroup4, QTGroup5, QTGroup6, QTGroup7, QTGroup8, QTGroup9, QTGroup10]
}
export const GetQuizQTGroups = () => {
    let keyCtr = 0

    let ShortAnswerQTMenuItem = new QuestionTypeMenuItem(keyCtr++, new QuestionTypeMenuIconInfo(QUIZ_QUESTION_TYPE.SHORT_TEXT), QUIZ_QUESTION_TYPE.SHORT_TEXT)
    let MultipleChoiceQTMenuItem = new QuestionTypeMenuItem(keyCtr++, new QuestionTypeMenuIconInfo(QUIZ_QUESTION_TYPE.MULTIPLE_CHOICE), QUIZ_QUESTION_TYPE.MULTIPLE_CHOICE)
    let DropDownQTMenuItem = new QuestionTypeMenuItem(keyCtr++, new QuestionTypeMenuIconInfo(QUIZ_QUESTION_TYPE.DROPDOWN_CHOICE), QUIZ_QUESTION_TYPE.DROPDOWN_CHOICE)
    let LongAnswerQTMenuItem = new QuestionTypeMenuItem(keyCtr++, new QuestionTypeMenuIconInfo(QUIZ_QUESTION_TYPE.LONG_TEXT), QUIZ_QUESTION_TYPE.LONG_TEXT)
    let FileUploadQTMenuItem = new QuestionTypeMenuItem(keyCtr++, new QuestionTypeMenuIconInfo(QUIZ_QUESTION_TYPE.FILE_UPLOAD), QUIZ_QUESTION_TYPE.FILE_UPLOAD, true)
    let SectionQTMenuItem = new QuestionTypeMenuItem(keyCtr++, new QuestionTypeMenuIconInfo(QUIZ_QUESTION_TYPE.SECTION), QUIZ_QUESTION_TYPE.SECTION)

    // QTs are placed in groups that are separated by bottom border lines when the menu is shown.
    let QTGroup1 = [ShortAnswerQTMenuItem, LongAnswerQTMenuItem]
    let QTGroup2 = [MultipleChoiceQTMenuItem, DropDownQTMenuItem]
    let QTGroup3 = [FileUploadQTMenuItem]
    let QTGroup4 = [SectionQTMenuItem]

    return [QTGroup1, QTGroup2, QTGroup3, QTGroup4]
}

const setTitle = (updatedTitle) => {
    let updatedData = {..._data}

    updatedData.welcome_screen.title = updatedTitle
    _dispatch({type: APP_ACTION_TYPE.SET_FORM_DATA, payload: updatedData})
}

const setDesc = (updatedDesc) => {
    let updatedData = {..._data}

    updatedData.welcome_screen.description = updatedDesc
    _dispatch({type: APP_ACTION_TYPE.SET_FORM_DATA, payload: updatedData})
}

const setSectionTitle = (currentIndex, value) => {
    let updatedData = {..._data}

    updatedData.pages[0].questions[currentIndex].title = value
    _dispatch({type: APP_ACTION_TYPE.SET_FORM_DATA, payload: updatedData})
}
const setSectionDesc = (currentIndex, value) => {
    let updatedData = {..._data}

    updatedData.pages[0].questions[currentIndex].description = value
    _dispatch({type: APP_ACTION_TYPE.SET_FORM_DATA, payload: updatedData})
}

export const isNewlyAddedQuestion = (savedQuestions, questionId) => {
    if (savedQuestions?.length && questionId?.length) {
        for (let i = 0; i < savedQuestions.length; i++) {
            if (savedQuestions[i]._id === questionId) {
                return false
            }
        }
        return true
    } else {
        return true
    }
}

export const addQuestionToBuilder = (param, questionIndex, addQuestion, copyQuestion, subscriptionData) => {
    // Note: QUESTION_TYPE.FILE_UPLOAD.key === QUIZ_QUESTION_TYPE.FILE_UPLOAD.key.
    // So, we don't need to check for Form or Quiz Question Type.
    if (param.target) {
        // This is a QUESTION_EVENT.ADD_QUESTION
        if (param === QUESTION_TYPE.FILE_UPLOAD.key || getMenuItemQT(param.target.getAttribute("data-attribute")).QuestionTypeValue.key === QUESTION_TYPE.FILE_UPLOAD.key) {
            // File Upload QT is being added
            if (maxNumberOfFileUploadOTsHasBeenReached(_data.pages[0].questions, subscriptionData)) {
                if (isMobile) {
                    _dispatch({type: PREMIUM_ACTION_TYPE.SET_SHOW_UPGRADE_PLAN_POP_UP, payload: true})
                } else {
                    _dispatch({type: PREMIUM_ACTION_TYPE.SET_SHOW_UPGRADE_PLAN_POP_UP, payload: true})
                }
                return
            }
        }

        addQuestion(questionIndex, param.target.getAttribute("data-attribute"))
    } else {
        // 'param' will be the 'key' of the Question Type. This is a QUESTION_EVENT.COPY_QUESTION
        if (param === QUESTION_TYPE.FILE_UPLOAD.key) {
            // File Upload QT is being added
            if (maxNumberOfFileUploadOTsHasBeenReached(_data.pages[0].questions, subscriptionData)) {
                if (isMobile) {
                    _dispatch({type: PREMIUM_ACTION_TYPE.SET_SHOW_UPGRADE_PLAN_POP_UP, payload: true})
                } else {
                    _dispatch({type: PREMIUM_ACTION_TYPE.SET_SHOW_UPGRADE_PLAN_POP_UP, payload: true})
                }
                return
            }
        }

        copyQuestion(questionIndex)
    }
}

export const getNewQuestionsForAutocomplete = (forqData, existingSuggestions) => {
    let newQuestionsInForq = []

    if (forqData.pages) {
        let uniqueQuestionsInForq = [...new Set(forqData.pages[0].questions
            .filter(q => q.title.length > 3)
            .map(q => q.title)
        )]

        uniqueQuestionsInForq.map((newQ) => {
            if (!existingSuggestions.find((existingSuggestion) => existingSuggestion.toLowerCase() === newQ.toLowerCase())) {
                newQuestionsInForq.push(newQ)
            }
        })
    }

    return newQuestionsInForq
}
