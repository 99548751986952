import {
    ACTION_TO_TAKE_BASED_ON_USER_STORAGE_LEVEL,
    FORM_QUIZ_RESPONSE_USER_STORAGE_LOAD_ACTION, maxUserFileStorageBasedOnUserPlan,
    QUESTION_TYPE
} from "../../../shared/utils/constants"

export const getUserStorageActionToTakeWhileFormOrQuizIsLoading = (questions) => {
    if (questions?.find(q => q.type === QUESTION_TYPE.FILE_UPLOAD.key)) {
        return FORM_QUIZ_RESPONSE_USER_STORAGE_LOAD_ACTION.CHECK_USER_STORAGE_LEVEL
    }

    return FORM_QUIZ_RESPONSE_USER_STORAGE_LOAD_ACTION.NONE
}
export const getActionToTakeBasedOnUserStorageLevel = (userStorageLevel, questions, userPlan) => {
    if (userStorageLevel >= maxUserFileStorageBasedOnUserPlan(userPlan)) {
        if (questions.find(q => q.type === QUESTION_TYPE.FILE_UPLOAD.key && q.is_required)) {
            return ACTION_TO_TAKE_BASED_ON_USER_STORAGE_LEVEL.SHOW_OUT_OF_SPACE_PAGE
        }

        if (questions.find(q => q.type === QUESTION_TYPE.FILE_UPLOAD.key)) {
            return ACTION_TO_TAKE_BASED_ON_USER_STORAGE_LEVEL.ALLOW_RESPONSES_TO_NON_FILE_UPLOAD_QUESTIONS
        }
    }

    return ACTION_TO_TAKE_BASED_ON_USER_STORAGE_LEVEL.NONE
}