import React, {useEffect, useState} from 'react';
import {QuestionComponent} from "./QuestionComponent";
import {QUESTION_TYPE} from "../../shared/utils/constants";
import SectionComponent from "./questions/SectionComponent";
import {useDispatch, useSelector} from "react-redux";
import * as mongoObjectID from "bson-objectid";
import {FORM_DATA_ACTION_TYPE} from "../reducers/formDataReducer";
import {answeredQuestionsCount, updateResponses} from "../utils/form-response";
import {ACTION_TYPE} from "../utils/utils";
import {QUESTIONS_ACTION_TYPE} from "../reducers/questionsReducer";

export const FormQuestionContainer = (props) => {
    let questionNumberMaintainer = 0;
    const [showForm, setShowForm] = useState(false)
    const formData = useSelector(state => state.formDataReducer?.formData)
    const questions = useSelector(state => state.questionsReducer?.questions)
    const responses = useSelector(state => state.formDataReducer.formResponses)
    const fileUploadAnsweredCount = useSelector(state => state.answerCountReducer?.fileUploadAnswerCount)
    const dispatch = useDispatch()

    useEffect(() => {
        let url = new URL(decodeURI(window.location.href));
        let preFilledFormData = JSON.parse(url.searchParams.get("prefilleddata"));
        if (preFilledFormData && preFilledFormData.length > 0) {
            setShowForm(false)
            preFilledFormData.map((element, index) => {
                if (formData.pages[0].questions[index]._id === element.qid) {
                    if (Array.isArray(element.data)) {
                        element.data.map(data => {
                            dispatch({
                                type: FORM_DATA_ACTION_TYPE.SET_FORM_RESPONSES,
                                payload: updateResponses(responses, formData.pages[0].questions[index], data, false, true)
                            })
                        })
                    } else {
                        dispatch({
                            type: FORM_DATA_ACTION_TYPE.SET_FORM_RESPONSES,
                            payload: updateResponses(responses, formData.pages[0].questions[index], element.data, false, true)
                        })
                    }
                    setShowForm(true)
                }
            })
            dispatch({
                type: ACTION_TYPE.SET_ANSWER_COUNT,
                payload: answeredQuestionsCount(responses) + fileUploadAnsweredCount
            })
            if (url.searchParams.get("submit")) {
                dispatch({type: QUESTIONS_ACTION_TYPE.SET_FILE_UPLOAD_COMPLETE, payload: true})
            }
        } else {
            setShowForm(true)
        }
    }, [])

    return (
        <>
            {showForm ? <form id="form_container" action="#" ref={props.formRef}
                              style={{
                                  margin: '0 auto',
                                  maxWidth: '800px',
                              }} onSubmit={(e) => e.preventDefault()}>
                {questions?.map((questionData, index) => {
                    if (questionData.type === QUESTION_TYPE.SECTION.key) {
                        return (
                            <SectionComponent
                                index={questionNumberMaintainer}
                                key={questionData._id ? questionData._id : Math.random()}
                                previewType={props.previewType}
                                questionData={questionData}
                            />
                        );
                    } else {
                        questionNumberMaintainer = questionNumberMaintainer + 1;
                    }
                    const updatedQuestionData = {...questionData}
                    if (props.previewType && (questionData?.type === QUESTION_TYPE.MULTIPLE_CHOICE_GRID.key || questionData?.type === QUESTION_TYPE.CHECKBOX_GRID.key)) {
                        updatedQuestionData.columns.map((column) => {
                            if (!column._id) {
                                column._id = mongoObjectID().toString() + "tempId"
                            }
                        })
                        updatedQuestionData.rows.map((row) => {
                            if (!row._id) {
                                row._id = mongoObjectID().toString() + "tempId"
                            }
                        })
                        questionData = updatedQuestionData
                    }

                    return (
                        <QuestionComponent
                            questionData={questionData}
                            previewType={props.previewType}
                            index={index}
                            key={questionData._id ? questionData._id : Math.random()}
                            questionIndex={formData?.setting.show_question_number ? questionNumberMaintainer : undefined}
                        />
                    );
                })
                }
            </form> : null}
        </>
    );
}
