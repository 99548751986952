import styles from "../css/text-question.module.css";
import React, {useEffect, useRef, useState} from "react";
import {
    getTextFocusInBottomBorderColor,
    getTextFocusOutBottomBorderColor,
    getThemeTextColor
} from "../utils/theme-color-helper";
import {useSelector} from "react-redux";

export const InputComponent = (props) => {
    const themeColor = useSelector(state => state.themeReducer.themeColor)
    const inputComponent = useRef(undefined);
    useEffect(() => {
        if (props.isClearText && inputComponent.current) {
            inputComponent.current.value = null;
        }
    });

    function changeBottomBorderThemeOnFocusOut(element) {
        element.target.style.borderBottomColor =
            getTextFocusOutBottomBorderColor(props.theme);
    }

    function changeBottomBorderThemeOnFocusIn(element) {
        element.target.style.borderBottomColor
            = getTextFocusInBottomBorderColor(props.theme, element, themeColor);
    }

    function triggerOnFocusInEvent(element) {
        changeBottomBorderThemeOnFocusIn(element);
        if (props.onFocus) {
            props.onFocus(element);
        }
    }

    function triggerOnFocusOutEvent(element) {
        changeBottomBorderThemeOnFocusOut(element);
        if (props.onBlur) {
            props.onBlur(element);
        }
    }

    function triggerOnInputEvent(element) {
        if (props.onInput) {
            props.onInput(element);
        }
    }

    function triggerOnChangeEvent(element) {
        if (props.onChange) {
            props.onChange(element);
        }
    }
    const [symbolsArr] = useState(["0","1","2","3","4","5","6","7","8","9",".",",","Backspace","ArrowUp","ArrowDown","ArrowLeft","ArrowRight","Delete"]);

    const handleKeyDown = (e) => {
        if (props.type === "number") {
            return !symbolsArr.includes(e.key) && e.preventDefault()
        } else if (props.type === "date") {
            e.preventDefault()
        } else return null
    }

    return (
        <React.Fragment>
            <input ref={inputComponent}
                   id={'input-' + props.placeHolder}
                   name={props.name ? props.name : null}
                   maxLength={props.maxLength}
                   type={props.type}
                   className={[styles.answer_text_field_input, props.customClassName].join(' ')}
                   placeholder={props.placeHolder ? props.placeHolder : ""}
                   defaultValue={props.defaultValue}
                   value={props.value}
                   onFocus={triggerOnFocusInEvent}
                   onBlur={triggerOnFocusOutEvent}
                   onInput={triggerOnInputEvent}
                   onChange={triggerOnChangeEvent}
                   onKeyDown={(e) => handleKeyDown(e)}
                   style={{
                       ...{
                           borderBottomColor: getTextFocusOutBottomBorderColor,
                           fontSize: "18px",
                           fontFamily: "Nunito Sans, Sans-serif, serif",
                           color: getThemeTextColor(props.theme)
                       },
                       ...props.customStyle
                   }}/>
        </React.Fragment>
    );
}
