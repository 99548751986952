import React, {useEffect} from "react";
import MaterialButton from "../../shared/components/MaterialButton";
import {getThemeTextColor, isImageTheme} from "../utils/theme-color-helper";
import {showSnackBarByRef} from "../../shared/components/SnackBar";
import {QUESTION_TYPE} from "../../shared/utils/constants";
import {FORM_SCREEN} from "../utils/utils";
import {validateFormData} from "../utils/form-validation";
import {SCREENS_ACTION_TYPE} from "../reducers/screensReducer";
import {useDispatch, useSelector} from "react-redux";
import {QUESTIONS_ACTION_TYPE} from "../reducers/questionsReducer";

export const FormSubmitContainer = (props) => {
    const questions = useSelector(state => state.questionsReducer?.questions)
    const responses = useSelector(state => state.formDataReducer.formResponses)
    const formData = useSelector(state => state.formDataReducer.formData)
    const themeColor = useSelector(state => state.themeReducer.themeColor)
    const filesToUpload = useSelector(state => state.questionsReducer.filesToUpload)
    const fileUploadComplete = useSelector(state => state.questionsReducer.fileUploadComplete)
    const questionComponentList = useSelector(state => state.questionsReducer.questionComponentList)
    const dispatch = useDispatch()

    useEffect(() => {
        if (fileUploadComplete) {
            //Note [fileUploadComplete] Effect is also fired and used by QuizTimer.js, which has this line of code: dispatch({type: QUESTIONS_ACTION_TYPE.SET_FILE_UPLOAD_COMPLETE, payload: true})
            dispatch({type: SCREENS_ACTION_TYPE.SET_FORM_SCREEN, payload: FORM_SCREEN.THANK_PAGE})
            if (props.setSelectedPreviewScreen) {
                props.setSelectedPreviewScreen(FORM_SCREEN.THANK_PAGE);
            }
        }
    }, [fileUploadComplete])

    function showAboutContent() {
        props.contentContainerRef.current ? props.contentContainerRef.current.style.display = "flex" : null;
    }

    function handleFormSubmitEvent() {
        let formValidationResult = validateFormData(responses, questions, filesToUpload);

        if (formValidationResult.isValid) {
            if (props.previewType || props.preview) {
                dispatch({type: QUESTIONS_ACTION_TYPE.SET_FILE_UPLOAD_COMPLETE, payload: true})
            } else {
                props.saveFileUploadsInAWS()
            }
        } else {
            highlightInvalidQuestionsUI(formValidationResult);
        }
        dispatch({type: QUESTIONS_ACTION_TYPE.SET_QUESTION_COMPONENT_LIST, payload: []})
    }

    function highlightInvalidQuestionsUI(formValidationResult) {
        const inValidAnsweredCount = formValidationResult.inValidQuestionIdList.length;
        showSnackBarByRef(props.snackBarRef, {
            timeoutMS: 6000,
            messageText: inValidAnsweredCount + " - Question" + ((inValidAnsweredCount > 1) ? "s " : " ") + "requires answer",
            buttonText: "Close"
        })
        let requiredQuestionElement;
        formValidationResult.inValidQuestionIdList.map((inValidQuestion, index) => {
            for (let i = 0; i < questionComponentList.length; i++) {
                requiredQuestionElement = questionComponentList[i];
                if (inValidQuestion.id === requiredQuestionElement.question_id) {
                    let message = "This question requires an answer";

                    if (inValidQuestion.type === QUESTION_TYPE.EMAIL.key && inValidQuestion.isInvalidEmailAddress && responses[index].text.length !== 0) {
                        message = "Invalid email address"
                    }
                    requiredQuestionElement.setInfoTag({
                        is_show: true,
                        text: message,
                        textColor: 'rgb(255, 255, 255)',
                        backgroundColor: 'rgb(244, 67, 54)'
                    });
                    if (index === 0 && requiredQuestionElement.questionComponentRef.current) {
                        requiredQuestionElement.questionComponentRef.current.scrollIntoView({behavior: 'smooth'});
                        const coreFormElement = requiredQuestionElement.questionComponentRef.current.querySelector('input, textarea, select');
                        coreFormElement ? coreFormElement.focus() : null;
                    }
                    break;
                }
            }
        })
    }

    const isShowReportAbuse = () => {
        if (formData?.user_id === 'surveyheartapp@gmail.com') {
            return true
        } else if (window.location.href.split('?')[1] === 'appresponsecollector=true') {
            return true
        }
    }

    function openReportAbuseForm() {
        const rootPath = window.location.origin
        if (formData?.is_quiz) {
            const id = formData?._id
            window.open('https://surveyheart.com/form/5f323faf7693da10a21c84c6' + "?reportid=" + id)
        } else {
            const id = formData?._id
            window.open(rootPath + "/form/5c6cdd029f25685f413430ef" + "?reportid=" + id)
        }
    }

    return (
        <div style={{
            textAlign: 'center',
            marginBottom: '160px'
        }}>
            <MaterialButton id={'form-submit-container-button'} data={{
                title: 'Submit',
                textTransform: "uppercase",
                fontSize: "15px",
                bg_color: isImageTheme(formData?.theme) ? themeColor?.secondaryColor : '#212121',
                endImageFileName: formData?.is_quiz ? 'done.svg' : 'icons/done-white-24dp.svg',
                customButtonContainerStyle: {
                    margin: '5px',
                    padding: '0 24px',
                    fontSize: '14px',
                    textAlign: 'center'
                },
                customButtonTextStyle: {
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    letterSpacing: '1.5px',
                    fontWeight: '600',
                    color: '#ffffff'
                }
            }} handleClick={() => {
                handleFormSubmitEvent();
            }}/>
            {props.previewType || props.preview || formData.user_id === "surveyheartapp@gmail.com" ? null : <div>
                <p style={{
                    fontSize: '14px',
                    color: isImageTheme(formData?.theme) ? "#ffffff" : getThemeTextColor(formData?.theme),
                    marginLeft: '15px',
                    marginRight: '15px',
                    textShadow: '1px 1px rgb(0 0 0 / 40%)'
                    /*textShadow: '1px 1px rgba(0, 0, 0, 0.4)'*/
                }}>
                    This {formData?.is_quiz ? 'quiz' : 'form'} content is not
                    created or endorsed by &nbsp;
                    {formData?.is_quiz ?
                        <span onClick={showAboutContent} style={{cursor: 'pointer'}}><u><b>Quizzory</b></u></span> :
                        <span onClick={showAboutContent} style={{cursor: 'pointer'}}><u><b>SurveyHeart</b></u></span>}
                    {!isShowReportAbuse() ? <span style={{cursor: 'pointer'}}
                                                  onClick={openReportAbuseForm}><u><b> - Report Abuse</b></u>
                    </span> : null}
                </p>
            </div>}
        </div>
    );
}
