import React, {useEffect, useRef} from 'react';
import '../css/material-snackbar.css'
import '@material/snackbar'
import {MDCSnackbar} from "@material/snackbar/component";
import {isMobileOnly} from "react-device-detect";
// # Component usage
/*
<SnackBar timeoutMS={5000} //time to auto hide snackbar (if it is not given then snackbar will stay in the screen)
          messageText={"Hello"} //Title of the snackbar
          buttonText={"Action"} //Action button in snackbar (if it is not given then snackbar will not show button)
          buttonClickEvent={() => { //Event of the above action button
              console.log("hai")
          }}
/>

or

Create useref for snackBar component and call showSnackBar function to use it.
 */

export default function SnackBar(props) {
    const snackBarRef = props.snackBarRef ? props.snackBarRef : useRef(props.snackBarRef);
    useEffect(() => {
        let timeoutMS = props.timeoutMS ? props.timeoutMS : -1;
        let message = props.messageText ? props.messageText : "";
        if (snackBarRef.current && message.length > 0) {
            const snackBar = new MDCSnackbar(snackBarRef.current);
            snackBar.labelText = message;
            snackBar.timeoutMs = timeoutMS;
            snackBar.actionButtonText = props.buttonText ? props.buttonText : "";
            snackBar.open();
        }
    }, [])


    return (
        <div id={'snackbar'} className="mdc-snackbar" ref={snackBarRef} style={{position: isMobileOnly ? "fixed" : null}}>
            <div className="mdc-snackbar__surface" role="status" aria-relevant="additions">
                <div className="mdc-snackbar__label" aria-atomic="false">
                </div>
                <div id="snack-bar-button" className="mdc-snackbar__actions" aria-atomic="true" onClick={() => {
                    props.buttonClickEvent
                }}>
                    <button type="button" className="mdc-button mdc-snackbar__action">
                        <div className="mdc-button__ripple"></div>
                        <span className="mdc-button__label"></span>
                    </button>
                </div>
            </div>
        </div>
    );
}

/**
 *
 * @param snackBarRef
 * @param snackBarProps
 * @param buttonClickEvent
 */
export const showSnackBarByRef = (snackBarRef, snackBarProps, buttonClickEvent) => {
    let timeoutMS = snackBarProps.timeoutMS ? snackBarProps.timeoutMS : -1;
    let message = snackBarProps.messageText ? snackBarProps.messageText : "";
    if (snackBarRef.current) {
        const snackBar = new MDCSnackbar(snackBarRef.current);
        snackBar.labelText = message;
        snackBar.timeoutMs = timeoutMS;
        snackBar.actionButtonText = snackBarProps.buttonText ? snackBarProps.buttonText : "";
        snackBarRef.current.querySelector('#snack-bar-button').onClick = () => {
            buttonClickEvent()
        }
        snackBar.open();
    }
};
