// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2qAYmQcoeRvf2HMg---fEj {\n    height: 100%;\n    width: 100%;\n    position: relative;\n    z-index: 20;\n    background-color: rgba(0, 0, 0, 0.8);\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    flex-flow: column;\n}\n\n._2nCUvMIISVClvyEoHGYyEL {\n    background-color: #ffffff;\n    display: flex;\n    padding: 20px;\n    width: 50%;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n}\n\n._2nCUvMIISVClvyEoHGYyEL input {\n    margin-bottom: 8px;\n}\n\n@media screen and (max-width: 600px) {\n    ._2nCUvMIISVClvyEoHGYyEL {\n        padding: 15px;\n        width: 80%;\n    }\n}\n", ""]);
// Exports
exports.locals = {
	"popup_container": "_2qAYmQcoeRvf2HMg---fEj",
	"quiz_user_info_popup": "_2nCUvMIISVClvyEoHGYyEL"
};
module.exports = exports;
