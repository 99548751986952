import React, {useState, useRef} from 'react'
import './../css/sort-container.css'
import {RESPONDENT_SORTING_FIELD} from "../utils/response-summary-sort";
import {translationKeys} from "../../../localizations/translationKeys-localization";
import {t} from "i18next";

const SummarySortContainer = props => {
    const [showSortOptions, setShowSortOptions] = useState(false);
    const sortedBy = useRef();
    const Ref = useRef();
    const handleSortSelection = (sortOrder) => {
        props.sortDataList(sortOrder);
        sortedBy.current.innerText = sortOrder;
        setShowSortOptions(false);
    };
    const handleSortButtonClick = e => {
        e.stopPropagation();
        if (showSortOptions) {
            setShowSortOptions(false);
            document.onclick = null;
        } else {
            setShowSortOptions(true);
            document.onclick = e => {
                if (Ref.current && !Ref.current.contains(e.target)) {
                    setShowSortOptions(false);
                    document.onclick = null;
                }
            }
        }
    };
    return (
        <>
            <img style={{width: '28px', height: '28px', marginRight: '5px'}}
                 src="images/sort_icon.svg"
                 alt="more-icon"/>
            <div className="sort-container">
                <div className="sort-header"
                     style={showSortOptions ? {
                         backgroundColor: '#fff',
                         color: '#004BA3',
                         borderBottomLeftRadius: 0,
                         borderBottomRightRadius: 0
                     } : {backgroundColor: '#fff'}}
                     onClick={handleSortButtonClick}>
                    <span id='sort-header-order' ref={sortedBy} style={{width: '160px', marginRight: '5px'}}>{props.lastSortedOrder}</span>
                    <img style={{width: '24px', height: '24px'}}
                         src={showSortOptions ? "../../../images/sort_minimize.svg" : "../../../images/sort_more.svg"}
                         alt="more-icon"/>
                </div>
                {showSortOptions ?
                    <div className="sort-options-container" ref={Ref}>
                        <div id='sort-each-option-alphabet' className="sort-each-option"
                             onClick={() => handleSortSelection(RESPONDENT_SORTING_FIELD.ALPHABET)}>
                            {t(translationKeys.alphabet)}
                        </div>
                        <div className="sort-each-option"
                             onClick={() => handleSortSelection(RESPONDENT_SORTING_FIELD.SUBMIT_TIME)}>
                            {t(translationKeys.submit_time)}
                        </div>
                        {}
                        <div className="sort-each-option"
                             onClick={() => handleSortSelection(RESPONDENT_SORTING_FIELD.NOT_EVALUATED)}>
                            {t(translationKeys.not_evaluated)}
                        </div>
                        <div className="sort-each-option"
                             onClick={() => handleSortSelection(RESPONDENT_SORTING_FIELD.EVALUATED)}>
                            {t(translationKeys.evaluated)}
                        </div>
                        <div className="sort-each-option"
                             onClick={() => handleSortSelection(RESPONDENT_SORTING_FIELD.PERCENTAGE_ASCENDING)}>
                            {t(translationKeys.percentage_ascending)}
                        </div>
                        <div className="sort-each-option"
                             style={{borderBottomLeftRadius: '5px', borderBottomRightRadius: '5px'}}
                             onClick={() => handleSortSelection(RESPONDENT_SORTING_FIELD.PERCENTAGE_DESCENDING)}>
                            {t(translationKeys.percentage_descending)}
                        </div>
                    </div> : null}
            </div>
        </>
    )
}

export default SummarySortContainer