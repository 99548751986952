import React from 'react'
import {useTranslation} from "react-i18next";
import {translationKeys} from "../../localizations/translationKeys-localization";

export default function ErrorPage() {
    const {t} = useTranslation()
    const styles = 'body {\n    height: 100%;\n    width: 100%;\n    margin: 0;\n    padding: 0;\n    font-family: \'Ubuntu\', sans-serif;\n    position: fixed;\n    background-color: #f5f5f5;\n}\n\n.page-container {\n    height: 100%;\n    width: 100%;\n    margin: 0;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    flex-direction: row;\n    flex-wrap: wrap;\n}\n\n.page-info {\n    height: auto;\n    width: auto;\n    margin-bottom: 25px;\n}\n\n.page-title {\n    width: 100%;\n}\n\n.page-title p {\n    text-align: center;\n    font-size: 75px;\n    color: #bdbdbd;\n    font-weight: bold;\n    letter-spacing: 10px;\n    margin: 0 0 25px;\n}\n\n.page-description p {\n    margin: 0;\n    text-align: center;\n    font-size: 30px;\n    color: #454545;\n    font-weight: bold;\n    word-spacing: 5px;\n}\n\n.page-info-image {\n    width: 100%;\n    text-align: center;\n}\n\n.page-info-image img {\n    width: 150px;\n    height: 150px;\n    text-align: center;\n    margin-bottom: 10px;\n}\n\n\n.page-description {\n    margin: 20px; \n}\n\n@media only screen and (max-width: 600px) {\n    .page-title p {\n        font-size: 32px;\n    }\n\n    .page-description p {\n        font-size: 25px;\n    }\n}'
    const styleElement = document.createElement('style');
    styleElement.innerHTML = styles;
    document.head.appendChild(styleElement);
    return (
        <div id={'error-page-container'} className="page-container">
            <div className="page-info">
                <div className="page-info-image">
                    <img alt='error-image' src="images/error.svg"/>
                </div>
                <div className="page-title">
                    <p>OOPS!</p>
                </div>
                <div className="page-description">
                    <p>{t(translationKeys.we_cant_find_the_page_that_you_are_looking_for)} :(</p>
                </div>
            </div>
        </div>
    )
}
