import React, {useState} from 'react'
import PreviewPaneRightNavigator from "./PreviewPaneRightNavigator";
import PreviewPane from "./PreviewPane";
import '../css/preview_builder.css'
import {PREVIEW_TYPE} from "../../../shared/utils/constants";
import {getPreviewPreference} from "../../utils/local-storage";
import {useDispatch, useSelector} from "react-redux";
import {BUILDER_ACTION_TYPE} from "../../reducers/builderReducer";

const PreviewContainer = props => {
    const lastSavedPreviewPreference = getPreviewPreference();
    const showPreviewPane = useSelector(state => state.builderReducer.showPreviewPane)
    const [previewType, setPreviewType] = useState(lastSavedPreviewPreference ? lastSavedPreviewPreference : PREVIEW_TYPE.PHONE);
    const dispatch = useDispatch()
    const selectedLanguage = useSelector(state => state.languageReducer.selectedLanguage)

    const closePreviewPane = () => {
        dispatch({type: BUILDER_ACTION_TYPE.SET_SHOW_PREVIEW_PANE, payload: false})
    };
    if (selectedLanguage === 'fa' || selectedLanguage === 'ar') {

    }
    const stlesForrtl = {
        borderLeft: (selectedLanguage === 'fa' || selectedLanguage === 'ar') ? '0px' : '',
        borderRight: (selectedLanguage === 'fa' || selectedLanguage === 'ar') ? '2px solid #C4C4C4' : ''
    }
    return (
        <div id={'preview_container'} className='builder-box2' style={stlesForrtl}>
            {
                (() => {
                        if (showPreviewPane) {
                            return (
                                <div>
                                    <PreviewPane
                                        {...props}
                                        previewType={previewType}
                                        setPreviewType={setPreviewType}
                                        closePreviewPane={closePreviewPane}
                                        formData={props.formData}
                                    />
                                </div>
                            )
                        }
                        if (!showPreviewPane) return (
                            <PreviewPaneRightNavigator openPreviewPane={() => dispatch({type: BUILDER_ACTION_TYPE.SET_SHOW_PREVIEW_PANE, payload: true})}
                                                       previewType={previewType}
                                                       setPreviewType={setPreviewType}/>
                        )
                    }
                )()
            }
        </div>
    )
}

export default PreviewContainer
