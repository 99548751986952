import React from "react"
import MaterialSelectMenu from "../../../shared/components/MaterialSelectMenu"
import MaterialSwitch from "../../../shared/components/MaterialSwitch"
import {isMobile} from "react-device-detect";
import {addQuestionToBuilder, isNewlyAddedQuestion, popSelectValues} from "./helpers/BuilderContainerHelper"
import {AUTOCOMPLETE_ACTION_TYPE} from "../../reducers/autocompleteReducer"
import {useDispatch, useSelector} from "react-redux"
import {translationKeys} from "../../../localizations/translationKeys-localization"
import {t} from "i18next"
import {QUESTION_TYPE} from "../../../shared/utils/constants"
import {MDCSelect} from "@material/select/index"
import {APP_ACTION_TYPE} from "../../reducers/appReducer"
import useQuestionMgr from "../../hooks/useQuestionMgr";
import {BUILDER_ACTION_TYPE} from "../../reducers/builderReducer";

const QuestionFooter = (props) => {
    const {addQuestion, questionRequiredChanged, copyQuestion, setQuestionType, deleteQuestion} = useQuestionMgr()
    const formData = useSelector(state => state.appReducer.formData)
    const subscriptionData = useSelector(state => state.userReducer?.subscriptionData)
    const selectedLanguage = useSelector(state => state.languageReducer.selectedLanguage)
    const reduxDispatch = useDispatch()

    const setQuestionTypes = (questionIndex, selectedValue) => {
        if (formData.pages[0].questions[questionIndex].type !== selectedValue) {
            if (formData.response_count > 0 && !isNewlyAddedQuestion(props.refSavedData?.current?.pages[0]?.questions, props.question?._id) && (selectedValue === QUESTION_TYPE.MULTIPLE_CHOICE_GRID.key || selectedValue === QUESTION_TYPE.CHECKBOX_GRID.key || selectedValue === QUESTION_TYPE.CHECKBOX_CHOICE.key || selectedValue === QUESTION_TYPE.MULTIPLE_CHOICE.key || selectedValue === QUESTION_TYPE.DROPDOWN_CHOICE.key)) {
                // Prevent existing QUESTION_TYPE.CHECKBOX_GRID, QUESTION_TYPE.MULTIPLE_CHOICE_GRID and all choice questions from being edited when the Form has responses
                new MDCSelect(props.refSelectMenu.current).selectedIndex = popSelectValues(props.question.type).findIndex(val => val.key === formData.pages[0].questions[questionIndex].type)

                reduxDispatch({type: APP_ACTION_TYPE.SET_SHOW_GRID_QT_CHANGE_NOT_ALLOWED_POP_UP, payload: true})
            } else {
                setQuestionType(questionIndex, selectedValue)
            }
        }
    }

    const requiredSwitchClicked = () => {
        if (props.question.is_logic_enabled) {
            reduxDispatch({type: BUILDER_ACTION_TYPE.SET_BUILDER_MESSAGE, payload: 'Action cannot be performed'});
            reduxDispatch({type: BUILDER_ACTION_TYPE.SET_BUILDER_TOAST_MESSAGE, payload: true})
        }
    }

    const switchChanged = (index, checkedStatus) => {
        questionRequiredChanged(index, checkedStatus)
    }

    return (
        <div id={'builder-footer'} className='builder-footer' key={props.index}>
            <div onClick={() => {
                if (props.formData?.is_quiz && props.formData?.response_count > 0) {
                    reduxDispatch({type: BUILDER_ACTION_TYPE.SET_SHOW_QUIZ_ACTION_BLOCKED_POP_UP, payload: true})
                }
            }}>
                <MaterialSelectMenu
                    index={props.index}
                    customStyle={{width: '255px'}}
                    disabled={props.formData?.is_quiz && props.formData?.response_count > 0}
                    selectValues={popSelectValues(props.question.type, props.question?.is_logic_enabled)}
                    selectionHandler={setQuestionTypes}
                    initialValueToSelect={props.question.type}
                    initialImage={props.initialImage}
                    initialImageAltText={props.initialImageAltText}
                    refSelectMenu={props.refSelectMenu}
                    setMinWidth={true}
                    selectedLanguage={selectedLanguage}
                />
            </div>
            <div className='builder-footer-right'
                 style={(selectedLanguage === 'fa' || selectedLanguage === 'ar') ? {
                     marginRight: "auto",
                     marginLeft: "inherit"
                 } : null}>
                {/*is required switch*/}
                <div id={'builder-footer-required-switch'} className='builder-footer-required-switch' onClick={requiredSwitchClicked}>
                    <MaterialSwitch
                        index={props.index}
                        initialCheckedState={props.question.is_required}
                        switchChanged={switchChanged}
                        isSwitchDisabled={props.question.is_logic_enabled}
                    />
                </div>
                <div id={'builder-question-required'}
                     className='builder-footer-label builder-footer-label-required'>{t(translationKeys.required)}</div>
                <div className='builder-footer-separator'>
                    |
                </div>
                <div className='builder-footer-group'
                     style={{filter: props.formData?.is_quiz && props.formData?.response_count > 0 ? "opacity(0.5)" : null}}
                     onClick={() => {
                         if (props.formData?.is_quiz && props.formData?.response_count > 0) {
                             reduxDispatch({
                                 type: BUILDER_ACTION_TYPE.SET_SHOW_QUIZ_ACTION_BLOCKED_POP_UP,
                                 payload: true
                             })
                         } else {
                             reduxDispatch({type: AUTOCOMPLETE_ACTION_TYPE.CLEAR_SELECTED_VALUE})
                             deleteQuestion(props.index, isNewlyAddedQuestion(props.refSavedData?.current?.pages[0]?.questions, props.question?._id))
                         }
                     }}
                >
                    <div id={'builder-footer-label' + props.index} className='builder-footer-label'
                         style={isMobile ? {display: 'none'} : null}>{t(translationKeys.delete)}</div>
                    <img className='builder-footer-image'
                         style={{width: isMobile ? '12px' : null}}
                         src="images/icons/builder/delete.svg" alt={"Delete"}
                    />
                </div>
                <div className='builder-footer-separator'>
                    |
                </div>
                <div className='builder-footer-group'
                     style={{
                         marginRight: (selectedLanguage === 'fa' || selectedLanguage === 'ar') ? '5px' : null,
                         filter: props.formData?.is_quiz && props.formData?.response_count > 0 ? "opacity(0.5)" : null
                     }}
                     onClick={() => {
                         if (props.formData?.is_quiz && props.formData?.response_count > 0) {
                             reduxDispatch({
                                 type: BUILDER_ACTION_TYPE.SET_SHOW_QUIZ_ACTION_BLOCKED_POP_UP,
                                 payload: true
                             })
                         } else {
                             addQuestionToBuilder(props.question.type, props.index, addQuestion, copyQuestion, subscriptionData)
                         }
                     }}
                >
                    <div id={'builder-footer-label' + props.index} className='builder-footer-label'
                         style={isMobile ? {display: 'none'} : null}>{t(translationKeys.duplicate)}</div>
                    <img className='builder-footer-image' data-attribute='builder-question-card-copy-button'
                         style={{width: isMobile ? '14px' : null}}
                         src="images/icons/builder/copy.svg" alt={translationKeys.duplicate}
                    />
                </div>
            </div>
        </div>
    )
}

export default QuestionFooter
