import {makeServerRequest} from "./server-request";
import {REQUEST_METHOD} from "../../../shared/utils/helper";
import formattedDate from "../../../shared/utils/formatted-date";

export const getAllForms = (startDate, endDate, index, limit, sortBy, successFunction, failureFunction) => {
    const startDateMillis = new Date(`${formattedDate(startDate)} 00:00:00`).getTime()
    const endDateMillis = new Date(`${formattedDate(endDate)} 23:59:59`).getTime()
    const data = JSON.stringify({
        "start_date": startDateMillis,
        "end_date": endDateMillis,
        "page_index": index,
        "limit": limit,
        "sort_by": sortBy
    })
    makeServerRequest('/getallformsbypage', REQUEST_METHOD.POST, data, successFunction, failureFunction)
}
export const getFormsWithVerificationHelpRequests = (index, limit, sortBy, successFunction, failureFunction) => {
    const data = JSON.stringify({
        "page_index": index,
        "limit": limit,
        "sort_by": sortBy
    })
    makeServerRequest('/gethelprequestedforms', REQUEST_METHOD.POST, data, successFunction, failureFunction)
}
export const getUnverifiedForms = (from, to, index, limit, sortBy, successFunction, failureFunction) => {
    const fromMillis = new Date(`${from} 00:00:00`).getTime();
    const toMillis = new Date(`${to} 23:59:59`).getTime();
    const data = JSON.stringify(
        {
            "from": fromMillis,
            "to": toMillis,
            "page_index": index,
            "limit": limit,
            "sort_by": sortBy
        }
    )

    makeServerRequest('/getunverifiedforms', REQUEST_METHOD.POST, data, successFunction, failureFunction)
}
export const saveVerificationHelpRequest = (adminId, formId, comment, type, responseId, successFunction, failureFunction) => {
    const data = JSON.stringify({
        "requested_by": adminId,
        "form_id": formId,
        "comment": comment,
        "type": type,
        "invalidated_appeal_response_id": responseId,
    })

    makeServerRequest('/formhelprequested', REQUEST_METHOD.POST, data, successFunction, failureFunction)
}
export const verifyForm = (adminId, formId, successFunction, failureFunction) => {
    const data = JSON.stringify({
        "verified_by": adminId,
        "form_id": formId,
    })
    makeServerRequest('/verifyform', REQUEST_METHOD.POST, data, successFunction, failureFunction)
}
export const acceptAbuseForms = (ids, successFunction, failureFunction) => {
    const data = JSON.stringify({
        'ids': ids
    })
    makeServerRequest('/acceptmultipleabuseform', REQUEST_METHOD.POST, data, successFunction, failureFunction)
}
export const getAbuseForms = (pageIndex, limit, sortBy, successFunction, failureFunction) => {
    const data = JSON.stringify({
        "page_index": pageIndex,
        "limit": limit,
        "sort_by": sortBy
    })
    makeServerRequest('/getabuseforms', REQUEST_METHOD.POST, data, successFunction, failureFunction)
}
export const blockFormByIds = (ids, successFunction, failureFunction) => {
    const data = JSON.stringify({
        'ids': ids
    })
    makeServerRequest('/blockformsbyid', REQUEST_METHOD.POST, data, successFunction, failureFunction)
}
export const getFormsByUserId = (id, successFunction, failureFunction) => {
    const data = JSON.stringify({
        surveyHeart: {
            user_id: id
        }
    })
    makeServerRequest('/v2/getforms', REQUEST_METHOD.POST, data, successFunction, failureFunction)
}
export const adminGetFormsByUserId = (id, successFunction, failureFunction) => {
    const data = JSON.stringify({
            user_id: id
    })
    makeServerRequest('/admingetallformsofuser', REQUEST_METHOD.POST, data, successFunction, failureFunction)
}
export const getFormByFormId = (id, successFunction, failureFunction) => {
    const data = JSON.stringify({})
    makeServerRequest('/v2/form/' + id, REQUEST_METHOD.POST, data, successFunction, failureFunction)
}
export const getBlockedFormByFormId = (id, successFunction, failureFunction) => {
    const data = JSON.stringify({})
    makeServerRequest('/v2/blockedform/' + id, REQUEST_METHOD.POST, data, successFunction, failureFunction)
}
export const getBlockedFormUserByUserId = (id, successFunction, failureFunction) => {
    const data = JSON.stringify({})
    makeServerRequest('/v2/blockeduser/' + id, REQUEST_METHOD.POST, data, successFunction, failureFunction)
}
export const unHandledAdminResponses = (id, successFunction, failureFunction) => {
    const data = JSON.stringify({
        "form_id": id
    })
    makeServerRequest('/getunhandledadminresponses', REQUEST_METHOD.POST, data, successFunction, failureFunction)
}
export const getDashboardInfo = (successFunction, failureFunction) => {
    const data = JSON.stringify({})
    makeServerRequest('/getdashboardinfo', REQUEST_METHOD.POST, data, successFunction, failureFunction)
}
export const verifyAdminFormResponses = (responseIds, formId, successFunction, failureFunction) => {
    const data = JSON.stringify({
        "response_ids": responseIds,
        "form_id": formId
    })
    makeServerRequest('/flagadminresponse', REQUEST_METHOD.POST, data, successFunction, failureFunction)
}
export const unBlockUserForm = (userId, formId, successFunction, failureFunction) => {
    const data = JSON.stringify({
        "user_id": userId,
        "form_id": formId
    })
    makeServerRequest("/v2/unblockuser", REQUEST_METHOD.POST, data, successFunction, failureFunction)
}
export const unBlockMultipleUsers = (unBlockList, successFunction, failureFunction) => {
    const data = JSON.stringify({
        "unblock_list": unBlockList
    })
    makeServerRequest("/unblockmultipleusers", REQUEST_METHOD.POST, data, successFunction, failureFunction)
}
export const getFormsByContainSearch = (searchText, index, limit, successFunction, failureFunction) => {
    const data = JSON.stringify({
        "search_text": searchText,
        "page_index": index,
        "limit": limit
    })
    makeServerRequest('/formcontainsearch', REQUEST_METHOD.POST, data, successFunction, failureFunction)
}
export const validateForm = (id, status, successFunction, failureFunction) => {
    const data = JSON.stringify({
        form_id: id,
        status: status
    })
    makeServerRequest("/v2/updateformvalidation", REQUEST_METHOD.PUT, data, successFunction, failureFunction)
}
export const validateMultipleForms = (ids, successFunction, failureFunction) => {
    const data = JSON.stringify({
        form_ids: ids,
        status: true
    })
    makeServerRequest("/updatemultipleformvalidation", REQUEST_METHOD.PUT, data, successFunction, failureFunction)
}
export const getFormsByUserIdFromQuizServer = (id, successFunction, failureFunction) => {
    const data = JSON.stringify({
        surveyHeart: {
            user_id: id
        }
    })
    makeServerRequest('/v2/getforms', REQUEST_METHOD.POST, data, successFunction, failureFunction)
}
export const getBlockedFormByUserId = (id, successFunction, failureFunction) => {
    const data = JSON.stringify({
        surveyHeart: {
            user_id: id
        }
    })
    makeServerRequest('/v2/getblockedform', REQUEST_METHOD.POST, data, successFunction, failureFunction)
}

// Remove Help Request
export const removeHelpRequest = (id, successFunction, failureFunction) => {
    const data = JSON.stringify({
        form_id: id,
    })
    makeServerRequest('/formremovehelprequest', REQUEST_METHOD.PUT, data, successFunction, failureFunction)
}

