import React, {useEffect, useState} from 'react'
import '../css/themes.css'

import PrepareDefaultThemes from "./PrepareDefaultThemes";
import PrepareAwsThemes from "./PrepareAwsThemes";
import ThemesHeader from "./ThemesHeader";
import {FORM_SETTINGS} from "./FormBuilderContainer";
import {PreviewMenuItem} from "./BuilderLeftNavigatorHelper";
import {isMobileOnly} from "react-device-detect";
import {useDispatch, useSelector} from "react-redux";
import {APP_ACTION_TYPE} from "../../reducers/appReducer";
import {translationKeys} from "../../../localizations/translationKeys-localization";
import {useTranslation} from "react-i18next";

const ThemesContainer = (props) => {
    const {t} = useTranslation()
    const showPreviewPane = useSelector(state => state.builderReducer.showPreviewPane)
    const formData = useSelector(state => state.appReducer.formData)
    const [showDefaultThemes, setShowDefaultThemes] = useState(true)
    const [showMyThemes, setShowMyThemes] = useState(false)
    const [chosenTheme, setChosenTheme] = useState(props.chosenTheme)
    const dispatch = useDispatch()

    useEffect(() => {
        if (props.currentContainerTitle === t(translationKeys.settings)) {
            setShowDefaultThemes(true)
            setShowMyThemes(false)
        }
    }, [props.currentContainerTitle])

    function changeContainer(container) {
        if (container === 'default') {
            setShowDefaultThemes(true)
            setShowMyThemes(false)
        } else if (container === 'myThemes') {
            setShowDefaultThemes(false)
            setShowMyThemes(true)
        } else {
            setShowDefaultThemes(false)
            setShowMyThemes(false)
            props.changeContainer()
        }
    }

    function updateChosenTheme(themeName) {
        const updatedFormData = {...formData}
        updatedFormData.theme = themeName
        setChosenTheme(themeName)
        props.updateSetting(FORM_SETTINGS.THEME, themeName)
        dispatch({type: APP_ACTION_TYPE.SET_TEMPLATE_THEME, payload: themeName})
        dispatch({type: APP_ACTION_TYPE.SET_FORM_DATA, payload: updatedFormData})
        if (isMobileOnly) props.updateContainer(PreviewMenuItem)
    }

    if (showDefaultThemes || showMyThemes) {
        return (
            <div id={'theme-container'} className='theme-container'
                 style={{
                     gridTemplateColumns: showPreviewPane ? null : '94% 6%',
                     display: props.currentContainerTitle === 'ThemesContainer' ? null : 'none'
                 }}
            >
                {isMobileOnly ? null : <ThemesHeader changeContainer={changeContainer} showDefaultThemes={showDefaultThemes}
                               showMyThemes={showMyThemes}/>}
                {showDefaultThemes &&
                <PrepareDefaultThemes changeContainer={changeContainer} chosenTheme={chosenTheme}
                                      updateChosenTheme={updateChosenTheme}/>}
                {showMyThemes && <PrepareAwsThemes chosenTheme={chosenTheme}
                                                   updateChosenTheme={updateChosenTheme}
                />}
            </div>
        )
    } else {
        return <div/>
    }
}

export default ThemesContainer


