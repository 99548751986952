import React from 'react';
import MaterialButton from "../../shared/components/MaterialButton";
import {isImageTheme} from "../utils/theme-color-helper";
import {FORM_DATA_ACTION_TYPE} from "../reducers/formDataReducer";
import {useDispatch, useSelector} from "react-redux";
import {isMobileOnly} from "react-device-detect";
import ModalDialog from "../../shared/components/ModalDialog";
import warningIcon from "../../../public/images/icons/warning_icon.svg";

const ClearFormComponent = (props) => {
    const formData = useSelector(state => state.formDataReducer?.formData)
    const themeColor = useSelector(state => state.themeReducer.themeColor)
    const userInfoData = useSelector(state => state.formDataReducer.userInfoData)
    const editResponseId = useSelector(state => state.formDataReducer.editResponseId)
    const showClearPopUp = useSelector(state => state.formDataReducer?.showClearPopUp)
    const showSubmitButton = useSelector(state => state.formDataReducer.showSubmitButton)
    const dispatch = useDispatch()

    const handleClearEvent = () => {
        if (formData.is_quiz || formData?.user_info_form?.user_info_1) {
            localStorage?.setItem("userInfoData", JSON.stringify(userInfoData))
        }
        if (editResponseId) {
            localStorage?.setItem("editResponseId", editResponseId)
        }
        localStorage?.setItem("isClear", true)
        window.location.replace(window.location + "?clear=true")
    }

    return (
        <>
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                width: '-webkit-fill-available',
            }}>
                <div style={{
                    width: '-webkit-fill-available',
                    maxWidth: isMobileOnly ? '800px' : '775px',
                    minWidth: props.previewType ? '0' : '300px',
                    paddingBottom: showSubmitButton ? "50px" : '100px',
                    margin: isMobileOnly ? '0px 12px 10px 12px' : null
                }}>
                    <MaterialButton id={'form-submit-container-button'} data={{
                        title: `Clear ${formData?.is_quiz ? "quiz" : "form"}`,
                        fontSize: "15px",
                        bg_color: '#FFFFFF',
                        customButtonContainerStyle: {
                            margin: '5px',
                            padding: '0 24px',
                            fontSize: '14px',
                            textAlign: 'center'
                        },
                        customButtonTextStyle: {
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            letterSpacing: '1.5px',
                            fontWeight: '600',
                            color: isImageTheme(formData?.theme) ? themeColor?.secondaryColor : '#212121'
                        }
                    }} handleClick={() => {
                        dispatch({type: FORM_DATA_ACTION_TYPE.SET_SHOW_CLEAR_POPUP, payload: true})
                    }}/>
                </div>
            </div>
            {showClearPopUp ? <ModalDialog header={`CLEAR ${formData.is_quiz ? "QUIZ" : "FORM"}`}
                                           imageIcon={warningIcon}
                                           color={isImageTheme(formData?.theme) ? themeColor?.secondaryColor : '#212121'}
                                           textColor={isImageTheme(formData?.theme) ? themeColor?.secondaryColor : '#212121'}
                                           body={[`Please confirm that you want to clear all of the information previously entered on the ${formData.is_quiz ? "quiz" : "form"}.`]}
                                           buttons={
                                               [
                                                   {
                                                       text: "CANCEL",
                                                       action: () => dispatch({
                                                           type: FORM_DATA_ACTION_TYPE.SET_SHOW_CLEAR_POPUP,
                                                           payload: false
                                                       })
                                                   },
                                                   {
                                                       text: "YES",
                                                       action: () => {
                                                           handleClearEvent()
                                                       }
                                                   }
                                               ]
                                           }/> : null}
        </>
    );
}

export default ClearFormComponent;
