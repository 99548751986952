import React, {useEffect, useState} from "react"
import smile_worst_empty from "../../../../public/images/smile_worst_empty.svg"
import smile_dislike_empty from "../../../../public/images/smile_dislike_empty.svg"
import smile_neutral_empty from "../../../../public/images/smile_neutral_empty.svg"
import smile_like_empty from "../../../../public/images/smile_like_empty.svg"
import smile_best_empty from "../../../../public/images/smile_best_empty.svg"
import smile_worst_filled from "../../../../public/images/smile_worst_filled.svg"
import smile_dislike_filled from "../../../../public/images/smile_dislike_filled.svg"
import smile_neutral_filled from "../../../../public/images/smile_neutral_filled.svg"
import smile_like_filled from "../../../../public/images/smile_like_filled.svg"
import smile_best_filled from "../../../../public/images/smile_best_filled.svg"
import styles from "../../css/smiley-question.module.css"
import {FORM_DATA_ACTION_TYPE} from "../../reducers/formDataReducer"
import {answeredQuestionsCount, updateResponses} from "../../utils/form-response"
import {ACTION_TYPE} from "../../utils/utils"
import {useDispatch, useSelector} from "react-redux"
import useLogicQuestionMgr from "../../hooks/useLogicQuestionMgr";

const SmileyQuestion = (props) => {
    const {updateFormQuestionsBasedOnSelection} = useLogicQuestionMgr()
    const responses = useSelector(state => state.formDataReducer.formResponses)
    const editResponseId = useSelector(state => state.formDataReducer.editResponseId)
    const fileUploadAnsweredCount = useSelector(state => state.answerCountReducer?.fileUploadAnswerCount)
    const [indexToFocus, setIndexToFocus] = useState(-1);
    const [description, setDescription] = useState('Description');
    const dispatch = useDispatch()

    useEffect(() => {
        responses.map((response) => {
            if (response.question_id === props.questionData._id) {
                if (response?.choice?.length === 0) {
                    setIndexToFocus(-1)
                } else if (response.choice) {
                    props.questionData.choices.map((choice, index) => {
                        if (choice._id === response.choice) {
                            setIndexToFocus(index)
                            setDescription(props.questionData.choices[index].label)
                            if (editResponseId) {
                                updateFormQuestionsBasedOnSelection(choice, props.questionData, false)
                            }
                        }
                    })
                }
            }
        })
    }, [responses])

    const handleOnClickStar = (index, value) => {
        setIndexToFocus(index)
        setDescription(props.questionData.choices[index].label)
        props.validateRequired(value)
        dispatch({
            type: FORM_DATA_ACTION_TYPE.SET_FORM_RESPONSES,
            payload: updateResponses(responses, props.questionData, value)
        })
        updateFormQuestionsBasedOnSelection(props.questionData.choices[index], props.questionData, true)
        dispatch({
            type: ACTION_TYPE.SET_ANSWER_COUNT,
            payload: answeredQuestionsCount(responses) + fileUploadAnsweredCount
        })
    }

    return (
        <>
            <div className={styles.smiley_container} id="smiley_container">
                {
                    props.questionData.choices.map((choice, index) => {
                            let imageToDisplay

                            switch (index) {
                                case 0:
                                    imageToDisplay = indexToFocus === 0 ? smile_worst_filled : smile_worst_empty
                                    break
                                case 1:
                                    imageToDisplay = indexToFocus === 1 ? smile_dislike_filled : smile_dislike_empty
                                    break
                                case 2:
                                    imageToDisplay = indexToFocus === 2 ? smile_neutral_filled : smile_neutral_empty
                                    break
                                case 3:
                                    imageToDisplay = indexToFocus === 3 ? smile_like_filled : smile_like_empty
                                    break
                                case 4:
                                    imageToDisplay = indexToFocus === 4 ? smile_best_filled : smile_best_empty
                                    break
                            }

                            return (
                                <div key={choice._id}>
                                    <img key={choice._id} alt={`Smile ${index + 1}`} src={imageToDisplay}
                                         className={styles.smiley_image}
                                         style={{width: props.previewType === 'phone' ? "35px" : props.previewType === 'tablet' ? "40px" : null}}
                                         onClick={() => {
                                             handleOnClickStar(index, choice._id)
                                         }}
                                    />
                                </div>
                            )
                        }
                    )
                }
            </div>
            <div id="rating-description-text"
                 className={indexToFocus === -1 ? styles.smiley_hidden : styles.smiley_visible}>{description}</div>
        </>
    )
}


export default SmileyQuestion
