import React, {useEffect, useRef, useState} from "react";
import '../css/filter-popup.css';
import MaterialButton, {ButtonTypes} from "../../../shared/components/MaterialButton";
import * as responseFilter from "../utils/response-filter";
import {isMobileOnly} from "react-device-detect";
import FilterByTimeSection from "./FilterTimePeriodSelectorMenu";
import FilterResultsContainer from "./FilterResultsContainer";
import FilterByQuestionSection from "./FilterByQuestionSection";
import PopUpContainer from "../../../shared/components/PopUpContainer";
import {translationKeys} from "../../../localizations/translationKeys-localization";
import {t} from "i18next";
import {useDispatch} from "react-redux";
import {RESPONSES_ACTION_TYPE} from "../../reducers/responsesReducer";
import {useSelector} from "react-redux";
import {QUESTION_TYPE} from "../../../shared/utils/constants";

export const TimePeriod = {
    CUSTOM_DATE: t(translationKeys.custom_date),
    LAST_DAY: t(translationKeys.last_24_hours),
    LAST_WEEK: t(translationKeys.last_7_days),
    LAST_MONTH: t(translationKeys.last_30_days),
    LAST_YEAR: t(translationKeys.last_year),
    LIFE_TIME: t(translationKeys.life_time)
}

function FilterPopUpContainer(props) {
    const selectedLanguage = useSelector(state => state.languageReducer.selectedLanguage)
    const TimeTagTextElement = useRef();
    const [screenHeight, setScreenHeight] = useState(undefined);
    const tempFilteredArrayRef = useRef(props.filteredArrayRef.current);
    const tempTimeFilterObjectRef = useRef(props.timeFilterObjectRef.current);
    const tempQuestionFilterObjectRef = useRef([...props.questionFilterObject]);
    const tempSelectedQuestionObjectRef = useRef(null);
    const filterPopUpCardRef = useRef(null);
    const dispatch = useDispatch()
    let selectedTimePeriodOptionRef = useRef(tempTimeFilterObjectRef.current?.time.selectedTimePeriod ? tempTimeFilterObjectRef.current.time.selectedTimePeriod : TimePeriod.LIFE_TIME);
    const closePopUp = () => {
        props.setShowPopUp(null);
    }

    const handleOutSideClick = e => {
        const domainName = location.href.split('/').slice(0, 3).join('/');
        if (filterPopUpCardRef.current && !filterPopUpCardRef.current.contains(e.target) && (e.target.src !== (domainName + '/images/close.png'))) {
            closePopUp()
            document.onmousedown = null;
        }
    };
    useEffect(() => {
        TimeTagTextElement.current.innerText = tempTimeFilterObjectRef.current?.tagText ? tempTimeFilterObjectRef.current.tagText : TimePeriod.LIFE_TIME;
        const currentDate = new Date();

        switch (tempTimeFilterObjectRef.current.time.selectedTimePeriod) {
            case TimePeriod.CUSTOM_DATE:
                if (tempTimeFilterObjectRef.current.time.from && tempTimeFilterObjectRef.current.time.to) {
                    if (tempTimeFilterObjectRef.current.time.from < tempTimeFilterObjectRef.current.time.to) {
                        TimeTagTextElement.current.style.color = '#212121';
                    } else {
                        TimeTagTextElement.current.innerText = t(translationKeys.invalid_date);
                        TimeTagTextElement.current.style.color = 'red';
                    }
                } else {
                    tempTimeFilterObjectRef.current.time.selectedTimePeriod = TimePeriod.LIFE_TIME;
                    tempTimeFilterObjectRef.current.time.from = (props.summaryDataSet.responsesInfo[0].respondedTime);
                    tempTimeFilterObjectRef.current.time.to = currentDate.getTime() + 86399999;
                    tempTimeFilterObjectRef.current.tagText = TimePeriod.LIFE_TIME;
                    TimeTagTextElement.current.innerText = TimePeriod.LIFE_TIME;
                    TimeTagTextElement.current.style.color = '#212121';
                    selectedTimePeriodOptionRef.current = TimePeriod.LIFE_TIME;
                }
                break;
        }
        let tempFilterObject;
        if (tempQuestionFilterObjectRef && tempQuestionFilterObjectRef.current.length > 0) {
            tempFilterObject = tempQuestionFilterObjectRef.current.slice(0);
            tempFilterObject.unshift(tempTimeFilterObjectRef.current)
        } else {
            tempFilterObject = [tempTimeFilterObjectRef.current];
        }
        if (!Array.isArray(tempFilteredArrayRef.current) || tempFilteredArrayRef.current.length === 0) {
            tempFilteredArrayRef.current = tempTimeFilterObjectRef.current.time.from ? responseFilter.getFilteredResponses(tempFilterObject, props.summaryDataSet) : tempFilteredArrayRef.current;
        }
        props.setIsFilteredArrayChanged(!props.isFilteredArrayChanged);

        props.previouslyOpenedCardCloseFunction?.current && props.previouslyOpenedCardCloseFunction.current !== closePopUp ? props.closePreviouslyOpenedCard(closePopUp) : null
        document.onmousedown = handleOutSideClick
    }, [])

    useEffect(() => {
        let tempFilterObject;
        if (tempQuestionFilterObjectRef && tempQuestionFilterObjectRef.current.length > 0) {
            tempFilterObject = tempQuestionFilterObjectRef.current.slice(0);
            tempFilterObject.unshift(tempTimeFilterObjectRef.current)
        } else {
            tempFilterObject = [tempTimeFilterObjectRef.current];
        }
        tempFilteredArrayRef.current = responseFilter.getFilteredResponses(tempFilterObject, props.summaryDataSet);

    }, [tempQuestionFilterObjectRef.current, tempTimeFilterObjectRef.current])

    const getFilterObjectsCount = () => {
        let count = 0;
        if (tempQuestionFilterObjectRef.current.length) {
            count = count + tempQuestionFilterObjectRef.current.length
        }
        if (tempTimeFilterObjectRef.current && tempTimeFilterObjectRef.current.time.selectedTimePeriod !== TimePeriod.LIFE_TIME) {
            count = count + 1
        }
        return count
    }
    const applyFilter = () => {
        if (isMobileOnly) {
            props.filteredArrayRef.current = tempFilteredArrayRef.current;
            props.searchedArrayRef.current = null;
            dispatch({type: RESPONSES_ACTION_TYPE.SET_SEARCH_TEXT, payload: ''})
            dispatch({
                type: RESPONSES_ACTION_TYPE.SET_QUESTION_FILTER_OBJECT,
                payload: tempQuestionFilterObjectRef.current
            })
            props.timeFilterObjectRef.current = tempTimeFilterObjectRef.current;
            props.filterObjectCountRef.current = getFilterObjectsCount()
            props.setIsFilteredArrayChanged(!props.isFilteredArrayChanged);
            props.setShowPopUp(null);
        } else {
            props.filteredArrayRef.current = tempFilteredArrayRef.current;
            props.searchedArrayRef.current = null;
            props.searchInputElementRef.current.value = '';
            props.searchCloseButtonRef.current.style.display = 'none';
            dispatch({
                type: RESPONSES_ACTION_TYPE.SET_QUESTION_FILTER_OBJECT,
                payload: tempQuestionFilterObjectRef.current
            })
            props.timeFilterObjectRef.current = tempTimeFilterObjectRef.current;
            props.filterObjectCountRef.current = getFilterObjectsCount()
            props.setCurrentPageNumber(1);
            props.setIsFilteredArrayChanged(!props.isFilteredArrayChanged);
            props.setSelectedTableRows([]);
            props.setShowDeleteBottomContainer(false);
            props.setShowPopUp(null);
        }
    }
    if (isMobileOnly) {
        document.body.onresize = () => {
            if (window.innerHeight < 400) {
                //for not shrink the height while keyboard opened.
                setScreenHeight(300);
            } else {
                //to handle the chrome url tab shown/hidden screen adjustment in Mobile view.
                setScreenHeight("auto");
            }
        }
    }

    let showQuestionFilter = false
    props.questionsData.map((question) => {
        if (question.type !== QUESTION_TYPE.SECTION.key && question.type !== QUESTION_TYPE.MULTIPLE_CHOICE_GRID.key && question.type !== QUESTION_TYPE.CHECKBOX_GRID.key && question.type !== QUESTION_TYPE.FILE_UPLOAD.key) {
            showQuestionFilter = true
        }
    })

    return (isMobileOnly ?
            <PopUpContainer zIndex={12}>
                <div id='filter-by-date' className='filter-popup-container'
                     style={screenHeight ? {height: screenHeight} : null}>
                    <FilterResultsContainer TimeTagTextElement={TimeTagTextElement}
                                            summaryDataSet={props.summaryDataSet}
                                            filteredArrayRef={tempFilteredArrayRef}
                                            isFilteredArrayChanged={props.isFilteredArrayChanged}
                                            setIsFilteredArrayChanged={props.setIsFilteredArrayChanged}
                                            timeFilterObject={tempTimeFilterObjectRef.current}
                                            questionFilterObjectRef={tempQuestionFilterObjectRef}/>
                    <span  className='filter-card-title'>{t(translationKeys.filter_by_date)}</span>
                    <FilterByTimeSection timeTagTextElementRef={TimeTagTextElement}
                                         selectedTimePeriodOptionRef={selectedTimePeriodOptionRef}
                                         summaryDataSet={props.summaryDataSet}
                                         timeFilterObjectDataRef={tempTimeFilterObjectRef}
                                         filteredArrayRef={tempFilteredArrayRef}
                                         isFilteredArrayChanged={props.isFilteredArrayChanged}
                                         setIsFilteredArrayChanged={props.setIsFilteredArrayChanged}
                                         questionFilterObjectRef={tempQuestionFilterObjectRef}/>
                    {showQuestionFilter ? <FilterByQuestionSection questionsData={props.questionsData}
                                                                       filteredArrayRef={tempFilteredArrayRef}
                                                                       isFilteredArrayChanged={props.isFilteredArrayChanged}
                                                                       setIsFilteredArrayChanged={props.setIsFilteredArrayChanged}
                                                                       summaryDataSet={props.summaryDataSet}
                                                                       tempSelectedQuestionObjectRef={tempSelectedQuestionObjectRef}
                                                                       timeFilterObject={tempTimeFilterObjectRef.current}
                                                                       questionFilterObjectRef={tempQuestionFilterObjectRef}/> : null}
                    <FilterButtonsContainer applyFilter={applyFilter} showQuestionFilter={showQuestionFilter} setShowPopUp={props.setShowPopUp}/>
                </div>
            </PopUpContainer>
            :
            <div className='filter-popup-container' ref={filterPopUpCardRef}
                 style={(selectedLanguage === 'fa' || selectedLanguage === 'ar') ? {left: 0, right: "auto"} : null}>
                <div className='response-filter-left-container'
                     style={(selectedLanguage === 'fa' || selectedLanguage === 'ar')? {borderColor: 'transparent transparent transparent #e0e0e0'} : null}>
                    <span className='filter-card-title'>{t(translationKeys.filter_by_date)}</span>
                    <FilterByTimeSection timeTagTextElementRef={TimeTagTextElement}
                                         summaryDataSet={props.summaryDataSet}
                                         timeFilterObjectDataRef={tempTimeFilterObjectRef}
                                         selectedTimePeriodOptionRef={selectedTimePeriodOptionRef}
                                         filteredArrayRef={tempFilteredArrayRef}
                                         isFilteredArrayChanged={props.isFilteredArrayChanged}
                                         setIsFilteredArrayChanged={props.setIsFilteredArrayChanged}
                                         questionFilterObjectRef={tempQuestionFilterObjectRef}/>
                    {showQuestionFilter ? <FilterByQuestionSection questionsData={props.questionsData}
                                                                       filteredArrayRef={tempFilteredArrayRef}
                                                                       isFilteredArrayChanged={props.isFilteredArrayChanged}
                                                                       setIsFilteredArrayChanged={props.setIsFilteredArrayChanged}
                                                                       summaryDataSet={props.summaryDataSet}
                                                                       timeFilterObject={tempTimeFilterObjectRef.current}
                                                                       questionFilterObjectRef={tempQuestionFilterObjectRef}/> : null}
                </div>
                <div className='response-filter-right-container' style={!showQuestionFilter ? {height: "fit-content"} : null}>
                    <FilterResultsContainer TimeTagTextElement={TimeTagTextElement}
                                            summaryDataSet={props.summaryDataSet}
                                            filteredArrayRef={tempFilteredArrayRef}
                                            isFilteredArrayChanged={props.isFilteredArrayChanged}
                                            setIsFilteredArrayChanged={props.setIsFilteredArrayChanged}
                                            timeFilterObject={tempTimeFilterObjectRef.current}
                                            questionFilterObjectRef={tempQuestionFilterObjectRef}/>

                    <FilterButtonsContainer applyFilter={applyFilter} setShowPopUp={props.setShowPopUp}/>
                </div>
            </div>
    )
}

export default FilterPopUpContainer

function FilterButtonsContainer(props) {
    const selectedLanguage = useSelector(state => state.languageReducer.selectedLanguage)
    return (
        <div className='filter-button-container' style={isMobileOnly && !props.showQuestionFilter ? {marginTop: "60%"} : null}>
            <div style={(selectedLanguage === 'fa' || selectedLanguage === 'ar') ? {marginLeft: '15px'} : {marginRight: '15px'}}>
                <MaterialButton data={{
                    buttonType: ButtonTypes.OUTLINED, bg_color: '#1976d2',
                    title: t('close')
                }} handleClick={() => props.setShowPopUp(null)}
                />
            </div>
            <MaterialButton data={{
                buttonType: ButtonTypes.CONTAINED,
                title: t(translationKeys.apply)
            }} handleClick={() => {
                props.applyFilter()
            }}/>
        </div>
    )
}

