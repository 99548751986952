import React, { useState, useEffect } from 'react';
import '../css/add-theme-category-popup.css';
import MaterialTextFieldOutlinedContainer from "../../admin/containers/MaterialTextFieldOutlinedContainer";
import closeIcon from "../../../public/images/close_black.svg";
import ActionButtonContainer from "../containers/ActionButtonContainer";
import {useDispatch, useSelector} from "react-redux";
import {BUILDER_ACTION_TYPE} from "../../app/reducers/builderReducer";
import {t} from "i18next";
import {translationKeys} from "../../localizations/translationKeys-localization";

const AddThemeCategoryPopup = (props) => {
    const [wordInput, setWordInput] = useState('')
    const templateCategories = useSelector(state => state.builderReducer.templateCategories);
    const dispatch = useDispatch()
    const [currentCategoryIndex, setCurrentCategoryIndex] = useState(0);
    const [showError, setShowError] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')


    useEffect(() => {
        setCurrentCategoryIndex(props.currentIndex)
        setWordInput(templateCategories[props.currentIndex])
    }, []);

    const handleClosePopUp = () => {
        props.setShowPopUp(false)
        props.setEditCategoryName(null)
    }

    const handleWordChange = (value) => {
        setWordInput(value)
    }

    const handleAddButtonClick = () => {
        const updatedCategories = [...templateCategories]

        if (updatedCategories.includes(wordInput)) {
            setShowError(true);
            setErrorMessage('category already present');
        }
        else if (wordInput===undefined) {
            setShowError(true);
            setErrorMessage('Enter valid value');
        }

        else {
            setShowError(false);
            updatedCategories.push(wordInput)

            dispatch({
                type: BUILDER_ACTION_TYPE.SET_TEMPLATE_CATEGORIES_LIST,
                payload: updatedCategories
            })

            props.setShowPopUp(false)
            props.setEditCategoryName(null)
        }
    }

    const handleDeleteButtonClick = () => {
        const updatedCategories = [...templateCategories]
        updatedCategories.splice(currentCategoryIndex, 1)
        dispatch({
            type: BUILDER_ACTION_TYPE.SET_TEMPLATE_CATEGORIES_LIST,
            payload: updatedCategories
        })
        props.setShowPopUp(false)
        props.setEditCategoryName(null)
    }
    const handleSaveButtonClick = () => {
        const updatedCategories = [...templateCategories]
        updatedCategories[currentCategoryIndex] = wordInput

        dispatch({
            type: BUILDER_ACTION_TYPE.SET_TEMPLATE_CATEGORIES_LIST,
            payload: updatedCategories
        })
        props.setShowPopUp(false)
        props.setEditCategoryName(null)
    }

    const isValidCategory = () => {
        return showError
    }

    return (
        <div className={'add-theme-category-container'}>
            <p className={'add-theme-category-heading'}>{t(translationKeys.add_category)}</p>
            <img className={'add-theme-category-close-icon'} alt={'close icon'} src={closeIcon} onClick={handleClosePopUp}/>
            <div className={'add-theme-category-input-container'}>
                <MaterialTextFieldOutlinedContainer placeHolder={'Template Category'}
                                                    onChange={handleWordChange}
                                                    autoFocus={true}
                                                    customStyleObject={{
                                                        '--mdc-theme-primary': '#9e9e9e',
                                                    }}
                                                    inputType={'text'}
                                                    defaultValue={props.editCategoryName?props.editCategoryName:''}
                                                    validationFunction={isValidCategory}
                                                    errorText={errorMessage}
                />
            </div>
            <div className={'add-theme-category-buttons-container'}>
                <ActionButtonContainer handleClick={props.isAddContainer ? handleAddButtonClick : handleDeleteButtonClick}
                                       buttonText={props.isAddContainer ? 'ADD' : 'DELETE'}
                                       fontSize={'20px'}
                                       buttonWidth={'130px'}
                                       bgColor={props.isAddContainer ? '#1976D2' : '#E45B6E'}
                />
                {
                    !props.isAddContainer &&
                        <ActionButtonContainer handleClick={handleSaveButtonClick}
                                               buttonText={'SAVE'}
                                               fontSize={'20px'}
                                               buttonWidth={'130px'}/>
                }
            </div>
        </div>
    );
};

export default AddThemeCategoryPopup;
