import React from 'react'
import FormComponent from "./components/FormComponent";
import cryptoJS from "crypto-js"
import {initializeBugTracker} from "../shared/utils/bug-tracker";
import STATUS_PAGE_PATH from "../status-page-path";
import {Provider} from "react-redux";
import forqStore from "./store/forqStore";
import {createRoot} from "react-dom/client";
import {disableReactDevTools} from "@fvilers/disable-react-devtools";

const domContainer = document.querySelector('#app');
const formRoot = createRoot(domContainer)
const formId = new URL(window.location.href).pathname.split('/')[2];

if (!process.env.REACT_APP_ENV_IS_DEV) {
    initializeBugTracker();
}

if (process.env.REACT_APP_ENV_IS_REACT_DEV_TOOLS !== "true") {
    disableReactDevTools()
}

const initializeUI = (formData) => {
    document.title = formData.welcome_screen.title ? formData.welcome_screen.title : formData.is_quiz ? 'Quiz' : 'SurveyHeart - Form'
    checkForPersianLanguage(formData);
    formRoot.render(<Provider store={forqStore}>
        <FormComponent formData={formData}/>
    </Provider>, domContainer);
}

function rerenderEveryThing() {
    previewFormData ?
        formRoot.render(
            <div>
                <Provider store={forqStore}>
                    <FormComponent formData={previewFormData} preview={true}/>
                    <a id="preview-trigger-point" onClick={rerenderEveryThing}/>
                </Provider>
            </div>,
            domContainer)
        : null
}

if (window.location.href.split('/')[3] === 'newpreview') {
    formRoot.render(<a id="preview-trigger-point" onClick={rerenderEveryThing}/>, domContainer)
} else {
    if (formId) {
        try {
            const decryptedData = cryptoJS.AES.decrypt(rawFormData, formId);
            const formData = JSON.parse(decryptedData.toString(cryptoJS.enc.Utf8));
            if (!formData.setting?.allow_multiple_submit
                && formData.response_count !== 0
                && !formData.time_remaining
                && !formData.setting?.allow_response_edit
                && window.localStorage !== null
                && window.localStorage?.getItem(String(formData._id))) {
                let formId = window.localStorage?.getItem(String(formData._id));
                if (formId != null && formId.length > 0 && !formData.is_quiz) {
                    window.location = STATUS_PAGE_PATH.RESPONDED
                } else if (formId != null && formId.length > 0 && formData.is_quiz && formData.setting.allow_summary_view) {
                    let answerId = window.localStorage?.getItem(String(formData._id) + "_answer_id");
                    if (answerId && answerId.length > 0) {
                        window.location = "/answer-sheet/" + answerId
                    } else {
                        window.location = STATUS_PAGE_PATH.ANSWERED
                    }
                } else if (formData.is_quiz && !formData.setting.allow_summary_view) {
                    window.location = STATUS_PAGE_PATH.ANSWERED
                }
            } else {
                initializeUI(formData)
            }
        } catch (e) {
            window.location = STATUS_PAGE_PATH.ERROR
        }
    }
}

function checkForPersianLanguage(formData) {
    if (formData !== undefined && isPersianLanguage(formData.welcome_screen.title)
        || isPersianLanguage(formData.welcome_screen.description)) {
        document.documentElement.lang = "fa";
        document.documentElement.dir = "rtl";
    }

    function isPersianLanguage(string) {
        let persianUnicodeRex = /[\u0600-\u06FF]+/;
        return persianUnicodeRex.test(string);
    }
}

