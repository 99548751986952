import React, {useState, useEffect} from 'react'
import {SETTING_TABS_FOR_NEXT_PREV_CHANGE} from "./FormSettingsContainer";
import {useTranslation} from "react-i18next";
import {translationKeys} from "../../../localizations/translationKeys-localization";
import {BuilderMenuItem} from "./BuilderLeftNavigatorHelper";

const FormSettingsHeader = props => {
    const {t} = useTranslation()
    const [showNextTab, setShowNextTab] = useState(true)

    useEffect(() => {
        if (props.containerTabItems.filter(tabItem => tabItem.selected)[0].title === SETTING_TABS_FOR_NEXT_PREV_CHANGE.VIEW) {
            setShowNextTab(true)
            props.setCurrentTab(SETTING_TABS_FOR_NEXT_PREV_CHANGE.VIEW)
        } else if (props.containerTabItems.filter(tabItem => tabItem.selected)[0].title === SETTING_TABS_FOR_NEXT_PREV_CHANGE.CONTROL) {
            setShowNextTab(true)
            props.setCurrentTab(SETTING_TABS_FOR_NEXT_PREV_CHANGE.CONTROL)
        } else {
            setShowNextTab(false)
        }
    }, [props.containerTabItems])

    const handleClose = () => {
        props.updateContainer(BuilderMenuItem)
    }

    const submitSettings = () => {
        props.submitSettings()
    }

    return (
        <div id={'settings-header'} className='settings-header'>
            <div id='SettingsContainer'>
                <label className='settings-label'>{t(translationKeys.settings)}</label>
            </div>
            <div id={'builder-settings-next-tab'} className='builder-settings-button builder-settings-header-button-1'
                 style={{visibility: showNextTab ? 'visible' : 'hidden'}} onClick={() => {
                props.nextTab()
            }}>
                <span className='builder-settings-header-text'>{t(translationKeys.next)}</span>
                <img
                    src="images/icons/builder/right_blue.svg"
                    alt="Next Tab"
                    onClick={() => {
                        props.nextTab()
                    }}
                />
            </div>
            <div id={'builder-settings-submit'} className='builder-settings-button builder-settings-header-button-2' onClick={() => {
                submitSettings()
            }}>
                <span className='builder-settings-header-text'>{t(translationKeys.submit)}</span>
                <img src="images/icons/builder/check_white.svg"
                     alt="Submit"
                />
            </div>
            {/*close button*/}
            <div id={'settings-close-button'} className="settings-close-button center" onClick={handleClose}>
                <img src="../../../images/close_black.svg" alt="images/close_black.svg"/>
            </div>
        </div>
    )
}

export default FormSettingsHeader
