import React, {useEffect, useState} from "react"
import style from "../../css/grid-question-style.module.css";
import {MaterialRadioSelection} from "../MaterialRadioSelection";
import {useDispatch, useSelector} from "react-redux";
import {FORM_DATA_ACTION_TYPE} from "../../reducers/formDataReducer";
import {answeredQuestionsCount, updateResponses} from "../../utils/form-response";
import {ACTION_TYPE} from "../../utils/utils";
import {isMobileOnly} from "react-device-detect";
import {getTextFocusInBottomBorderColor, isDarkTheme} from "../../utils/theme-color-helper";
import useLogicQuestionMgr from "../../hooks/useLogicQuestionMgr";

const LinearScaleChoiceQuestion = (props) => {
    const {updateFormQuestionsBasedOnSelection} = useLogicQuestionMgr()
    const themeColor = useSelector(state => state.themeReducer.themeColor)
    const formData = useSelector(state => state.formDataReducer?.formData)
    const [isClearRadioSelection, clearRadioSelection] = useState(false)
    const responses = useSelector(state => state.formDataReducer.formResponses)
    const editResponseId = useSelector(state => state.formDataReducer.editResponseId)
    const fileUploadAnsweredCount = useSelector(state => state.answerCountReducer.fileUploadAnswerCount)
    const dispatch = useDispatch()

    useEffect(() => {
        responses.map((response) => {
            if (response.question_id === props.questionData._id) {
                if (response?.choice?.length === 0) {
                    clearRadioSelection(true)
                } else if (editResponseId) {
                    props.questionData.choices.map((choice) => {
                        if (choice._id === response.choice) {
                            updateFormQuestionsBasedOnSelection(choice, props.questionData, false)
                        }
                    })
                }
            }
        })
    }, [responses])

    const onChoiceSelectedEvent = (choice) => {
        clearRadioSelection(false)
        dispatch({type: FORM_DATA_ACTION_TYPE.SET_FORM_RESPONSES, payload: updateResponses(responses, props.questionData, choice._id)})
        updateFormQuestionsBasedOnSelection(choice, props.questionData, true)
        props.validateRequired(choice._id)
        dispatch({type: ACTION_TYPE.SET_ANSWER_COUNT, payload: answeredQuestionsCount(responses) + fileUploadAnsweredCount})
    }

    const isCellSelected = (choice) => {
        let isSelected = false
        responses.map((response) => {
            if (response.question_id === props.questionData._id) {
                if (response.choice === choice._id) {
                    isSelected = true
                }
            }
        })
        return isSelected
    }

    return (
        <>
            <div style={{display: "flex"}} id={"linear_scale_container"}>
                {props.questionData.choices.map((choice, index) => {
                    return <div key={choice._id} className={style.linear_scale}>
                        <div style={{margin: "15px 0", color: (isDarkTheme(formData?.theme)) ? '#ffffff' : '#212121'}}>{choice.label}</div>
                        <div className={style.linear_scale_row}
                             style={{paddingTop: "6px", borderRadius: index === 0 ? "5px 0 0 5px" : index === props.questionData.choices.length - 1 ? "0 5px 5px 0" : null}}>
                            <MaterialRadioSelection key={choice._id ? choice._id : Math.random()}
                                                    id={choice._id}
                                                    name={props.questionData._id}
                                                    onChange={() => onChoiceSelectedEvent(choice)}
                                                    width={"auto"}
                                                    isChecked={isCellSelected(choice)}
                                                    isClearRadioSelection={isClearRadioSelection}
                                                    customStyle={{
                                                        radioButtonPadding: props.previewType ? "0" : isMobileOnly ? "2px" : null,
                                                        radioButtonColor: (isDarkTheme(formData?.theme)) ? '#212121' : getTextFocusInBottomBorderColor(formData?.theme, themeColor),
                                                    }}/>
                        </div>
                    </div>
                })}
            </div>
            <div style={{display: "flex", placeContent: "space-between", color: (isDarkTheme(formData?.theme)) ? '#ffffff' : '#212121'}} id={"linear_scale_label_container"}>
                <div className={style.linear_scale_label} style={{textAlign:'left'}}>{props.questionData.start_label}</div>
                {props.questionData.choices.length > 4 ? <div className={style.linear_scale_label} style={{textAlign:'center'}}>{props.questionData.center_label}</div> : null}
                <div className={style.linear_scale_label} style={{textAlign:'right'}}>{props.questionData.end_label}</div>
            </div>
        </>
    )
}

export default LinearScaleChoiceQuestion