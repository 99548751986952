import React, {useEffect, useState} from 'react'
import DetailedQuizQuestionCard from "./DetailedQuizQuestionCard";
import Slider from "../../shared/components/Slider";
import {isMobileOnly} from "react-device-detect";

function DetailedIndividualQuestionContainer(props) {
    const [currentQuestionNumber, setCurrentQuestionNumber] = useState(props.currentQuestionIndex + 1)

    useEffect(() => {
        props.setCurrentQuestionIndex(currentQuestionNumber - 1)
    }, [currentQuestionNumber])

    let mobileViewSliderContainerStyle = {
        width: '90%',
        position: props.screenHeight < 500 ? 'unset' : 'absolute',
        display: 'flex',
        bottom: props.screenHeight > 500 ? '30px' : '0',
        height: "50px"
    }

    return (
        <React.Fragment>
            <div id={'detailed-individual-question-card'} className='detailed-individual-question-card'>
                <DetailedQuizQuestionCard
                    answerData={props.answerData}
                    responseCount={props.responseCount}
                    summaryQuestionsData={props.summaryQuestionsData}
                    isQuiz={props.isQuiz}
                    userId={props.userId}
                    attachment={props.attachment}
                    updateMarksInQuestionsData={props.updateMarksInQuestionsData}
                    isQuizV2={props.isQuizV2}
                    isPublish={props.isPublish}
                    setDetailedIndividualCard={props.setDetailedIndividualCard}
                    currentQuestionNumber={currentQuestionNumber}
                    setCurrentQuestionNumber={setCurrentQuestionNumber}
                    lastEditedBy={props.lastEditedBy}
                    quizId={props.quizId}
                />
                {isMobileOnly ? <div style={mobileViewSliderContainerStyle}>
                        <Slider currentNumber={currentQuestionNumber} isDetailedView={true}
                                setCurrentNumber={setCurrentQuestionNumber}
                                maxCount={props.summaryQuestionsData.length ? props.summaryQuestionsData.length : 0}/>
                    </div> :
                    <Slider currentNumber={currentQuestionNumber} isDetailedView={true}
                            setCurrentNumber={setCurrentQuestionNumber}
                            maxCount={props.summaryQuestionsData.length ? props.summaryQuestionsData.length : 0}/>
                }
            </div>
        </React.Fragment>
    )
}

export default DetailedIndividualQuestionContainer