import React from 'react'
import GridQuestionPopupTitleBar from "./GridQuestionPopupTitleBar"
import GridQuestionPopupValues from "./GridQuestionPopupValues"

const GridDetailsPopup = () => {
    return (
        <div className='popup-background'>
            <div className='grid-view-details-card'>
                <GridQuestionPopupTitleBar/>
                <GridQuestionPopupValues/>
            </div>
        </div>
    )
}

export default GridDetailsPopup
