import {getQuestionCategory, QUESTION_CATEGORY, QUESTION_TYPE} from "../../../shared/utils/constants";

let summaryDataSet;
export function setSummaryDataSet(passedSummaryDataSet) {
    summaryDataSet = passedSummaryDataSet;
}

export const FILTER_TYPE = {
    CONTAIN: "CONTAIN",
    EQUAL: "EQUAL",
    NOT_EQUAL: "NOT_EQUAL"
}
export const FILTER_MODEL = {
    TIME: "TIME",
    QUESTION: "QUESTION"
}

export function getFilteredResponses(filterObjectList, summaryDataSet) {
    let filteredIndexes = [];
    filterObjectList.forEach((filterObject) => {
        if (filterObject.model === FILTER_MODEL.QUESTION) {
            filteredIndexes = getQuestionBasedFilterRespondentIndex(filteredIndexes, filterObject, summaryDataSet);
        } else if (filterObject.model === FILTER_MODEL.TIME) {
            filteredIndexes = getTimeBasedFilterRespondentIndex(filterObject, summaryDataSet)
        }
    });
    return filteredIndexes;
}

export function getTimeBasedFilterRespondentIndex(filterObject, summaryDataSet) {
    let filteredIndexes = [];
    let respondedTime;
    for (let respondentIndex = 0; respondentIndex < summaryDataSet.responsesInfo.length; respondentIndex++) {
        respondedTime = Number(summaryDataSet.responsesInfo[respondentIndex].respondedTime);
        if (filterObject.time.from && filterObject.time.to) {
            if (respondedTime >= filterObject.time.from
                && respondedTime < filterObject.time.to) {
                filteredIndexes.push(respondentIndex);
            }
        } else if (respondedTime >= filterObject.time.from) {
            filteredIndexes.push(respondentIndex);
        }
    }
    return filteredIndexes;
}

export function getQuestionBasedFilterRespondentIndex(filteredIndexes, filterObject, summaryDataSet) {
    let currentQuestionData;
    let newFilteredIndexes = [];
    for (let i = 0; i < filteredIndexes.length; i++) {
        let respondentIndex = filteredIndexes[i];
        for (let questionIndex = 0; questionIndex < summaryDataSet.questionsData.length; questionIndex++) {
            currentQuestionData = summaryDataSet.questionsData[questionIndex];
            if (currentQuestionData.questionId === filterObject.questionId
                && isResponseGotFiltered(filterObject, getIndividualResponseText(currentQuestionData, respondentIndex),
                    currentQuestionData.type)) {
                newFilteredIndexes.push(respondentIndex);
                break;
            }
        }
    }
    return newFilteredIndexes;
}

export function getIndividualResponseText(summaryQuestionData, respondentIndex) {
    if (getQuestionCategory(summaryQuestionData.type) === QUESTION_CATEGORY.CHOICE_TYPE) {
        if (summaryQuestionData.type === QUESTION_TYPE.CHECKBOX_CHOICE.key) {
            return summaryQuestionData.choiceTextAnswers[respondentIndex];
        } else {
            return summaryQuestionData.choiceTextAnswers[respondentIndex][0];
        }
    } else if (getQuestionCategory(summaryQuestionData.type) === QUESTION_CATEGORY.TEXT_TYPE) {
        return summaryQuestionData.responseTextAnswers[respondentIndex];
    }
}


function isFilteredDataPresent(filterObject, responseText, questionType) {
    if (filterObject.model === FILTER_MODEL.QUESTION) {
        /*** replace the new line spaces between the paragraphs with single space (" ")
         if multiple paragraphs are in the string response ***/
        const cleanResponseText = (!Array.isArray(responseText)) ? responseText.replace(/(\r\n|\n|\r)/gm, " ") : responseText
        const textToCompare = Array.isArray(cleanResponseText) ? cleanResponseText[0] : cleanResponseText;
        switch (filterObject.type) {
            case FILTER_TYPE.CONTAIN:
                if (textToCompare.toLowerCase().indexOf(filterObject.text.toLowerCase()) > -1) {
                    return true;
                }
                break;
            case FILTER_TYPE.EQUAL:
                if (textToCompare.toLowerCase() === filterObject.text.toLowerCase()) {
                    return true;
                }
                break;
            case FILTER_TYPE.NOT_EQUAL:
                if (questionType && questionType === QUESTION_TYPE.CHECKBOX_CHOICE.key) {
                    let isDataPresent = false;
                    for (let i = 0; i < responseText.length; i++) {
                        const textToCompare = Array.isArray(responseText[i]) ? responseText[i][0] : responseText[0]
                        if (textToCompare.toLowerCase() === filterObject.text.toLowerCase()) {
                            isDataPresent = true;
                            break;
                        }
                    }
                    if (!isDataPresent) {
                        return true;
                    }
                } else {
                    if (!(textToCompare.toLowerCase() === filterObject.text.toLowerCase())) {
                        return true;
                    }
                }
                break;
        }
    }
    return false;
}

export function isResponseGotFiltered(filterObject, responseText, questionType) {
    if (!responseText) {
        return false;
    }
    if (questionType && questionType === QUESTION_TYPE.CHECKBOX_CHOICE.key) {
        if (filterObject.type === FILTER_TYPE.NOT_EQUAL) {
            return isFilteredDataPresent(filterObject, responseText, questionType)
        } else {
            for (let i = 0; i < responseText.length; i++) {
                const textToCompare = Array.isArray(responseText[i]) ? responseText[i][0] : responseText[0]
                if (isFilteredDataPresent(filterObject, textToCompare)) {
                    return true;
                }
            }
        }
        return false;
    } else {
        return isFilteredDataPresent(filterObject, responseText)
    }
}
