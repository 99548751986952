import React from "react"
import ModalDialog from "../../shared/components/ModalDialog";
import warningIcon from "../../../public/images/icons/warning_icon.svg";
import {isImageTheme} from "../utils/theme-color-helper";
import {isMobileOnly} from "react-device-detect";
import {useDispatch, useSelector} from "react-redux";
import {THEME_ACTION_TYPE} from "../reducers/themeReducer";
import {SCREENS_ACTION_TYPE} from "../reducers/screensReducer";
import {FORM_SCREEN} from "../utils/utils";
import {FORM_DATA_ACTION_TYPE} from "../reducers/formDataReducer";

const AbuseWarningPopup = (props) => {
    const formData = useSelector(state => state.formDataReducer.formData)
    const themeColor = useSelector(state => state.themeReducer?.themeColor)
    const dispatch = useDispatch()

    function openFormPage() {
        dispatch({type: FORM_DATA_ACTION_TYPE.SET_LOADING, payload: true})
        dispatch({type: THEME_ACTION_TYPE.SET_THEME_IMAGE_BLUR, payload: 4})
        dispatch({type: SCREENS_ACTION_TYPE.SET_IMAGE_VIEWER, payload: null})

        if (formData?.is_quiz) {
            dispatch({type: SCREENS_ACTION_TYPE.SET_FORM_SCREEN, payload: FORM_SCREEN.QUIZ_USER_INFO})
            if (props.setSelectedPreviewScreen) {
                props.setSelectedPreviewScreen(FORM_SCREEN.QUIZ_USER_INFO);
            }
        } else if (formData?.user_info_form?.user_info_1 && !formData.setting?.is_email) {
            dispatch({type: SCREENS_ACTION_TYPE.SET_FORM_SCREEN, payload: FORM_SCREEN.FORM_USER_INFO})
            if (props.setSelectedPreviewScreen) {
                props.setSelectedPreviewScreen(FORM_SCREEN.FORM_USER_INFO);
            }
        } else {
            dispatch({type: SCREENS_ACTION_TYPE.SET_FORM_SCREEN, payload: FORM_SCREEN.FORM_PAGE})
            if (props.setSelectedPreviewScreen) {
                props.setSelectedPreviewScreen(FORM_SCREEN.FORM_PAGE);
            }
        }
    }

    return (
        <ModalDialog header={'Warning'}
                     imageIcon={warningIcon}
                     color={isImageTheme(formData?.theme) ? themeColor?.secondaryColor : '#212121'}
                     body={[`Please be sure that you trust the creator of this ${formData.is_quiz ? "quiz" : "form"} before submitting it.`, `This ${formData.is_quiz ? "quiz" : "form"} contains words that potentially could be used to gather sensitive and personal information and has not been verified by our team yet.`]}
                     buttons={[
                         {
                             text: 'EXIT',
                             action: () => {
                                 if (isMobileOnly) {
                                     const userAgent = navigator.userAgent || navigator.vendor
                                     if (/android/i.test(userAgent)) {
                                         window.open("https://play.google.com/store/apps/details?id=com.surveyheart&hl=en", "_self")
                                     } else if (/iPad|iPhone|iPod/.test(userAgent)) {
                                         window.open("https://apps.apple.com/us/app/surveyheart-online-form-maker/id6443449067?itsct=apps_box_badge&amp;itscg=30200%22", "_self")
                                     } else {
                                         window.open("https://surveyheart.com", "_self")
                                     }
                                 } else {
                                     window.open("https://surveyheart.com", "_self")
                                 }
                             }
                         },
                         {
                             text: `CONTINUE TO ${formData.is_quiz ? "QUIZ" : "FORM"}`,
                             action: () => {
                                 openFormPage()
                             }
                         }
                     ]}/>
    )
}

export default AbuseWarningPopup