import React, {useEffect, useRef, useState} from "react"
import {useSelector} from "react-redux"
import MaterialTextField from "../../../shared/components/MaterialTextField"
import useGridMgr from "../../hooks/useGridMgr"
import checkBoxCircle from "../../../../public/images/icons/builder/question_types/checkbox_choice_2.svg"
import multipleChoiceCircle from "../../../../public/images/icons/builder/question_types/multiple_choice_2.svg"
import {translationKeys} from "../../../localizations/translationKeys-localization"
import {QUESTION_TYPE} from "../../../shared/utils/constants"
import {useTranslation} from "react-i18next"

const GridBodyColumns = (props) => {
    const {t} = useTranslation()
    const {
        addAnimationStyles, COMPONENT_TYPE, handleItemBlur, setHeightAndOverflowStatus,
        updateItemText
    } = useGridMgr()

    const [heightInitComplete, setHeightInitComplete] = useState(false)
    const [toggleRefresh, setToggleRefresh] = useState(false)

    const columns = useSelector(state => state.appReducer.formData?.pages?.[0]?.questions?.[props.index]?.columns)

    const questionType = useSelector(state => state.appReducer.formData?.pages[0].questions[props.index].type)

    // refColumns is needed to support animation in showing the columns. So, we can reference 'columns' directly.
    const refColumns = useRef([])
    const refAnimationInitialized = useRef(false)

    useEffect(() => {
        refColumns.current = columns?.slice(0)
        setToggleRefresh(!toggleRefresh)
    }, [columns?.length])

    useEffect(() => {
        const colDiv = document.querySelector(`#col-text-${props.index}-0`)

        if (colDiv && !refAnimationInitialized.current) {
            addAnimationStyles(props.index, COMPONENT_TYPE.COLUMN, colDiv, refAnimationInitialized, columns.length)
        }

        if (colDiv && !heightInitComplete) {
            setHeightAndOverflowStatus(props.index, COMPONENT_TYPE.COLUMN, colDiv, columns)
            setHeightInitComplete(true)
        }
    }, [refColumns.current?.length])

    return (
        <div style={{width: "100%", paddingRight: '10px'}}>
            <div style={{fontWeight: '700', display: "flex"}}>{t(translationKeys.columns)}</div>
            <div style={{paddingRight: '10px'}} id={`mcg-cols-${props.index}`}>
                {
                    (() => {
                            return (
                                refColumns?.current?.map((column, index) => {
                                    return (
                                        <div key={index} id={`col-text-${props.index}-${index}`}
                                             className="star-line">
                                            <div className="grid-line-center">
                                                {
                                                    (() => {
                                                        if (questionType === QUESTION_TYPE.MULTIPLE_CHOICE_GRID.key) return (
                                                            <img src={multipleChoiceCircle} style={{height: '22px'}}
                                                                 alt={t(translationKeys.multiple_choice)}
                                                            />
                                                        )

                                                        if (questionType === QUESTION_TYPE.CHECKBOX_GRID.key) return (
                                                            <img src={checkBoxCircle} style={{height: '22px'}}
                                                                 alt={t(translationKeys.check_box)}
                                                            />
                                                        )
                                                    })()
                                                }
                                            </div>
                                            <div className='builder-question-text-input'>
                                                <div className='sh-text-field' style={{marginBottom: "15px"}}>
                                                    <MaterialTextField id={`col-text-${props.index}-input-${index}`}
                                                                       index={index}
                                                                       data={{
                                                                           // Note: we can't use 'defaultValue: column.label' here because we need a reference to the actual label in columns as we are looping through refColumns.current
                                                                           defaultValue: columns[index]?.label
                                                                       }}
                                                                       noLabel={true}
                                                                       onChange={(newValue) => {
                                                                           updateItemText(props.index, COMPONENT_TYPE.COLUMN, index, newValue)
                                                                           setToggleRefresh(!toggleRefresh)
                                                                       }}
                                                                       maxLength={50}
                                                                       highlightText={false}
                                                                       removeHighlightText={false}
                                                                       handleFocus={null}
                                                                       onBlur={() => {
                                                                           handleItemBlur(props.index, COMPONENT_TYPE.COLUMN, index)
                                                                           setToggleRefresh(!toggleRefresh)
                                                                       }}
                                                                       validateURL={false}
                                                    />
                                                </div>
                                            </div>
                                            {props.buttons}
                                        </div>
                                    )
                                })
                            )
                        }
                    )()
                }
            </div>
        </div>
    )
}

export default GridBodyColumns