import React, {useState} from 'react'
import MaterialCheckBox from "../../../shared/components/MaterialCheckBox"
import {QUIZ_SETTINGS} from "./QuizBuilderContainer"
import ModalDialog from "../../../shared/components/ModalDialog";
import NumberSlider from "../../../shared/components/NumberSlider";
import MaterialTextField from "../../../shared/components/MaterialTextField";
import {translationKeys} from "../../../localizations/translationKeys-localization";
import {t} from "i18next";
import {useSelector} from "react-redux";
import {isMobileOnly} from "react-device-detect";

const SettingsQuizControlGeneralContainer = props => {
    const formData = useSelector(state => state.appReducer.formData)
    const selectedLanguage = useSelector(state => state.languageReducer.selectedLanguage)
    const [info2ReadOnly, setInfo2ReadOnly] = useState(!formData?.quiz_data.user_info.user_info_2)
    const [info3ReadOnly, setInfo3ReadOnly] = useState(!formData?.quiz_data.user_info.user_info_3)
    const duration = formData?.quiz_data.duration
    const [showDuration, setShowDuration] = useState(() => {
        if (duration?.is_enabled) {
            return duration.is_enabled
        }

        return false
    })
    const [durationHours, setDurationHours] = useState(() => {
        if (duration && duration.is_enabled) {
            return duration.hours
        }

        return 0
    })
    const [durationMinutes, setDurationMinutes] = useState(() => {
        if (duration && duration.is_enabled) {
            return duration.minutes
        }

        return 0
    })
    const [showDurationPopup, setShowDurationPopup] = useState(false)
    const [collectEmailAlert, setCollectEmailAlert] = useState(false)
    const [isEmailEnabled, setIsEmailEnabled] = useState(formData?.setting?.is_email)

    const handleInfo1Blur = () => {
        if (formData?.quiz_data.user_info.user_info_1.trim() === '') {
            props.updateSetting(QUIZ_SETTINGS.QUIZ_CONTROL_USER_INFO_1_TEXT, t(translationKeys.name_s))
        }
    }

    const handleInfo1Change = (newValue) => {
        props.updateSetting(QUIZ_SETTINGS.QUIZ_CONTROL_USER_INFO_1_TEXT, newValue)
    }
    const handleInfo2Change = (newValue) => {
        props.updateSetting(QUIZ_SETTINGS.QUIZ_CONTROL_USER_INFO_2_TEXT, newValue)
    }
    const handleInfo3Change = (newValue) => {
        props.updateSetting(QUIZ_SETTINGS.QUIZ_CONTROL_USER_INFO_3_TEXT, newValue)
    }
    const handleDurationChecked = (bool) => {
        if (bool) {
            // Make default duration 30 minutes.
            setDurationMinutes(30)
            setShowDurationPopup(true)
        } else {
            setShowDuration(bool)
            setDurationHours(0)
            setDurationMinutes(0)
            props.updateSetting(QUIZ_SETTINGS.DURATION, bool)
            props.updateSetting(QUIZ_SETTINGS.DURATION_HOURS, 0)
            props.updateSetting(QUIZ_SETTINGS.DURATION_MINUTES, 0)
        }
    }
    const handleInfo2Checked = (isChecked) => {
        if (isChecked) {
            props.updateSetting(QUIZ_SETTINGS.QUIZ_CONTROL_USER_INFO_2_TEXT, t(translationKeys.email))
            setInfo2ReadOnly(false)
        } else {
            setInfo2ReadOnly(true)
        }

        props.updateSetting(QUIZ_SETTINGS.QUIZ_CONTROL_USER_INFO_2_CHECKED, isChecked)
    }
    const handleInfo3Checked = (isChecked) => {
        if (isChecked) {
            props.updateSetting(QUIZ_SETTINGS.QUIZ_CONTROL_USER_INFO_3_TEXT, t(translationKeys.number))
            setInfo3ReadOnly(false)
        } else {
            setInfo3ReadOnly(true)
        }

        props.updateSetting(QUIZ_SETTINGS.QUIZ_CONTROL_USER_INFO_3_CHECKED, isChecked)
    }
    const handleAllowMultipleAttemptsChecked = (e) => {
        props.updateSetting(QUIZ_SETTINGS.ALLOW_MULTIPLE_ATTEMPTS, e)
    }

    const saveDuration = () => {
        if (durationHours > 0 || durationMinutes > 0) {
            setShowDuration(true)
            props.updateSetting(QUIZ_SETTINGS.DURATION, true)
            props.updateSetting(QUIZ_SETTINGS.DURATION_HOURS, durationHours)
            props.updateSetting(QUIZ_SETTINGS.DURATION_MINUTES, durationMinutes)
        }
    }

    const setCurrentHours = value => {
        setDurationHours(value)
    }

    const setCurrentMinutes = value => {
        setDurationMinutes(value)
    }

    const handleCollectEmailChange = () => {
        if (!isEmailEnabled) {
            setCollectEmailAlert(true)
        }
        else {
            setIsEmailEnabled(false)
            props.updateSetting(QUIZ_SETTINGS.COLLECT_EMAIL, false)
        }
    }

    return (
        <div id={'settings-quiz-control-wrapper'}
             style={{padding: '0', display: "initial"}}
             className="settings-quiz-control-wrapper">
            <div style={{display: 'flex'}}>
                {/* Row 1 */}
                <img src="images/info.svg" alt="Info icon" style={{height: 'fit-content'}}/>
                <div>
                    <div id={'settings-quiz-control-user-info'}
                          className="settings-quiz-control-box2">
                        <div className="settings-bold-text">{t(translationKeys.user_info)}</div>
                    </div>
                    {/* Row 2 */}
                    <div id={'settings-quiz-control-user-info-description'}
                         className="settings-quiz-control-box3">
                        <span className='settings-normal-text'>{t(translationKeys.user_information_description)}</span>
                    </div>
                    <div>
                    {/* Row 3 */}
                        <div id={'settings-quiz-control-box4'} className="settings-quiz-control-box4" style={{height: '5vh'}}>
                            <img src="images/check_box_disabled.svg" alt="Disabled Checkbox Icon" style={{paddingRight: 10}}/>
                            <MaterialTextField key={'info1'} data={{defaultValue: formData?.quiz_data.user_info.user_info_1}}
                                               applyV2CSS={true}
                                               onChange={handleInfo1Change}
                                               onBlur={handleInfo1Blur}
                                // inputRef={info1Ref}
                            />
                        </div>
                    {/* Row 4 */}
                        <div id={'settings-quiz-control-box5'}
                             className="settings-quiz-control-box5" style={{height: '5vh'}}>
                            <MaterialCheckBox removeWidth={true} onChange={handleInfo2Checked}
                                              isChecked={formData?.quiz_data.user_info.user_info_2 ? formData?.quiz_data.user_info.user_info_2 : null}
                            />
                            <MaterialTextField key={'info2'}
                                               data={{
                                                   isReadOnly: info2ReadOnly,
                                                   defaultValue: formData?.quiz_data.user_info.user_info_2
                                               }}
                                               applyV2CSS={true}
                                               onChange={handleInfo2Change}
                            />
                        </div>
                    {/* Row 5 */}
                        <div id={'settings-quiz-control-box6'} className="settings-quiz-control-box6" style={{height: '5vh'}}>
                            <MaterialCheckBox removeWidth={true} onChange={handleInfo3Checked}
                                              isChecked={formData?.quiz_data.user_info.user_info_3 ? formData?.quiz_data.user_info.user_info_3 : null}
                            />
                            <MaterialTextField key={'info3'}
                                               data={{
                                                   isReadOnly: info3ReadOnly,
                                                   defaultValue: formData?.quiz_data.user_info.user_info_3
                                               }}
                                               applyV2CSS={true}
                                               onChange={handleInfo3Change}
                            />
                        </div>
                    </div>
                </div>
            </div>

            {/* Row 6 */}
            <div style={{display: "flex", marginTop: '3%'}}>
                <img src="images/time.svg" alt="Info icon" style={{height: 'fit-content'}}/>
                <div style={{width: '100%'}}>
                    <div id={'settings-quiz-control-duration'}
                          style={{height: '4vh', alignItems: 'flex-start'}}
                          className="settings-quiz-control-box8">
                        <div className="settings-bold-text">{t(translationKeys.duration)}</div>
                        <div id={'settings-quiz-control-box7'} className="settings-quiz-control-box7"
                             style={{margin: (selectedLanguage === 'fa' || selectedLanguage === 'ar') ? '0 auto 0 0' : '0 0 0 auto'}}>
                            <MaterialCheckBox key={'info1'}
                                              removeWidth={true} onChange={handleDurationChecked}
                                              isChecked={duration && duration.is_enabled ? duration.is_enabled : false}
                            />
                        </div>
                    </div>
                    {/* Row 7 */}
                        <div id={'settings-quiz-control-set-duration'}
                        className="settings-quiz-control-box9">
                        <span className='settings-normal-text'>{t(translationKeys.set_duration_quiz_alert)}</span>
                        </div>
                </div>
            </div>
            {/* Row 8 */}
            {
                (() => {
                        if (showDuration)
                            return (
                                <div id={'settings-bold-text settings-quiz-control-box10'}
                                     className="settings-bold-text settings-quiz-control-box10">
                                    {
                                        (() => {
                                                if (duration && ((duration.hours === undefined) || (parseInt(duration.hours) === 0)))
                                                    return (
                                                        t(translationKeys.time_text) +` : ${parseInt(duration.minutes)}`+' '+ `${parseInt(duration.minutes) === 1 ?  t(translationKeys.minute) : t(translationKeys.minutes)}`
                                                    )
                                                if (duration && parseInt(duration.hours) > 0)
                                                    if ((duration.minutes === undefined) || (parseInt(duration.minutes) === 0))
                                                        return (
                                                            t(translationKeys.time_text) +` : ${parseInt(duration.hours)} `+' '+ `${parseInt(duration.hours) === 1 ? t(translationKeys.hour) : t(translationKeys.hours)}`
                                                        )
                                                if (duration && parseInt(duration.minutes) > 0)
                                                    return (
                                                        t(translationKeys.time_text) +` : ${parseInt(duration.hours)}  `+' '+ `${parseInt(duration.hours) !== 1 ? t(translationKeys.hours) : t(translationKeys.hour)} ${parseInt(duration.minutes)} `+' '+ `${parseInt(duration.minutes) === 1 ? t(translationKeys.minute) : t(translationKeys.minutes)}`
                                                    )
                                            }
                                        )()
                                    }
                                </div>
                            )
                    }
                )()
            }
            {/* Row 9 */}
            <div style={{display: "flex", marginTop: '3%'}}>
                <img src="images/library_books.svg" alt="Info icon" style={{height: 'fit-content'}}/>
                <div style={{width: '100%'}}>
                    <div id={'settings-quiz-control-allow-multiple-attempts'}
                         style={{height: '4vh', alignItems: 'flex-start'}}
                         className="settings-quiz-control-box12">
                        <div className="settings-bold-text" style={isMobileOnly && (selectedLanguage === 'ta' || selectedLanguage === 'te') ? {whiteSpace: "nowrap"} : null}>{t(translationKeys.allow_multiple_attempts)}</div>
                        <div id={'settings-quiz-control-box11'}
                             style={{margin: (selectedLanguage === 'fa' || selectedLanguage === 'ar') ? '0 auto 0 0' : '0 0 0 auto'}}
                             className="settings-quiz-control-box11">
                            <MaterialCheckBox removeWidth={true} onChange={handleAllowMultipleAttemptsChecked}
                                              isChecked={formData?.setting.allow_multiple_submit}/>
                        </div>
                    </div>
                    {/* Row 10 */}
                        <div id={'settings-allow-multiple-attempts-more-than-once'}
                        className="settings-quiz-control-box13">
                        <span className='settings-normal-text'>{t(translationKeys.allow_more_quiz_attempt_alert)}</span>
                        </div>
                </div>
            </div>
            {/*collect email*/}
            <div style={{display: "flex", marginTop: '3%'}}>
                <img src="images/email_blue.svg" alt="Email icon" style={{height: 'fit-content'}}/>
                <div style={{width: '100%'}}>
                    <div style={{height: '4vh', alignItems: 'center'}}
                         className="settings-quiz-control-box14">
                        <div className="settings-bold-text" style={isMobileOnly && (selectedLanguage === 'ta' || selectedLanguage === 'te') ? {whiteSpace: "nowrap"} : null}>{t(translationKeys.collect_email)}</div>
                        <div id={'settings-quiz-control-box11'}
                             style={{margin: (selectedLanguage === 'fa' || selectedLanguage === 'ar') ? '0 auto 0 0' : '0 0 0 auto'}}
                             className="settings-quiz-control-box11">
                            <MaterialCheckBox removeWidth={true} onChange={handleCollectEmailChange}
                                              isChecked={formData?.setting.is_email}/>
                        </div>
                    </div>
                    <div id={'settings-collect-email-address'}
                         className="settings-quiz-control-box15">
                        <span className='settings-normal-text'>{t(translationKeys.collect_email_description)}</span>
                    </div>
                </div>
            </div>

            {
                (() => {
                        if (showDurationPopup)
                            return (
                                <>
                                    <ModalDialog
                                        useWideVersion={true}
                                        header={t(translationKeys.set_duration)}
                                        body={
                                            <>
                                                <NumberSlider
                                                    currentNumber={durationHours}
                                                    maxCount={23}
                                                    setCurrentNumber={setCurrentHours}
                                                    label={t(translationKeys.hours)}
                                                />
                                                <NumberSlider
                                                    currentNumber={durationMinutes}
                                                    maxCount={59}
                                                    setCurrentNumber={setCurrentMinutes}
                                                    label={t(translationKeys.minutes)}
                                                />
                                            </>
                                        }
                                        buttons={
                                            [
                                                {
                                                    text: t(translationKeys.cancel),
                                                    action:
                                                        () => {
                                                            setDurationHours(0)
                                                            setDurationMinutes(0)
                                                            setShowDuration(false)
                                                            setShowDurationPopup(false)
                                                        }
                                                },
                                                {
                                                    text: t(translationKeys.done),
                                                    action:
                                                        () => {
                                                            saveDuration()
                                                            setShowDurationPopup(false)
                                                        }
                                                }
                                            ]
                                        }
                                    />
                                </>
                            )
                    }
                )()
            }
            {/*collect email*/}
            {collectEmailAlert ? <div style={{whiteSpace: "normal"}}>
                <ModalDialog header={t(translationKeys.collect_email_header)}
                             body={[formData.is_quiz ? t(translationKeys.collect_email_description_quiz) : t(translationKeys.collect_email_description_form), t(translationKeys.collect_email_alert_note)]}
                             buttons={[
                                 {
                                     text: t(translationKeys.cancel).toUpperCase(),
                                     action:
                                         () => {
                                             setCollectEmailAlert(false)                                        }
                                 },
                                 {
                                     text: 'CONTINUE',
                                     action:
                                         () => {
                                             setCollectEmailAlert(false)
                                             setIsEmailEnabled(true)
                                             props.updateSetting(QUIZ_SETTINGS.COLLECT_EMAIL, true)
                                         }
                                 }
                             ]}
                />
            </div> : null}
        </div>
    )
}

export default SettingsQuizControlGeneralContainer