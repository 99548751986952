import React from 'react'
import {isMobileOnly} from "react-device-detect";

const OptionBarContainer = (props) => {

        const graphOptionData = props.response.graphData[props.index];
        const denominatorCount = (props.answeredCount && props.answeredCount >= 1) ? props.answeredCount - props.skippedAnswerCount : 1;
        let percentageAnswered = graphOptionData ? ((graphOptionData / denominatorCount) * 100) : 0;
        percentageAnswered = (Math.round(percentageAnswered) !== percentageAnswered) ?
            isMobileOnly ? percentageAnswered.toFixed(1) + '%' : percentageAnswered.toFixed(2) + '%'
            : percentageAnswered + '%';

        return (
            <div className='result-option-bar-container'>
                {/*percentage element*/}
                <div id='result-percentages' className='percentages'>
                    {percentageAnswered}
                </div>
                {/*bar element*/}
                <div className='option-bar'>
                    <div id='option-active-text' className='option-active-color' style={{width: percentageAnswered}}/>
                    <span  className='option-text'>
                        {props.response.graphLabels[props.index]}
                    </span>
                </div>
                {/*Response Count element*/}
                <div id='chosen-count-option-bar' className='chosen-count'>{graphOptionData}</div>
            </div>
        );
}

export default OptionBarContainer