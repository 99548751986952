// this will give the date in a format which can be accepted as a date input element value.

export default function formattedDate(millis) {
    let date;
    if (millis) {
       date = new Date(millis);
    } else {
        date = new Date()
    }
    let dd = date.getDate();
    let mm = date.getMonth() + 1; //January is 0!
    let yyyy = date.getFullYear();

    if (dd < 10) {
        dd = '0' + dd;
    }
    if (mm < 10) {
        mm = '0' + mm;
    }
    date = yyyy + '-' + mm + '-' + dd;
    return date + "";
}