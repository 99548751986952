import React, {useEffect} from 'react';
import styles from '../../css/section-component.module.css'
import {isClassicTheme} from "../../utils/theme-color-helper";
import {useDispatch, useSelector} from "react-redux";
import {FORM_DATA_ACTION_TYPE} from "../../reducers/formDataReducer";

export default function SectionComponent(props) {
    const questions = useSelector(state => state.questionsReducer?.questions)
    const formData = useSelector(state => state.formDataReducer.formData)
    const dispatch = useDispatch()

    useEffect(() => {
        const sectionCount = formData?.pages[0]?.questions.reduce((count, item) => {
            return item.type === 'SECTION' ? count + 1 : count;
        }, 0);
        if (props.index === (formData?.pages[0]?.questions?.length - sectionCount)) {
            dispatch({type: FORM_DATA_ACTION_TYPE.SET_LOADING, payload: false})
        }
    }, []);

    return (
        <div className={styles.section_container}>
            <div className={styles.section_title_container}
                 style={{
                     backgroundColor: isClassicTheme(formData?.theme) ? 'transparent' : null
                 }}>
                <h4 id={'section-container-title'} style={{
                    color: isClassicTheme(formData?.theme) ? '#212121' : null,
                    textShadow: isClassicTheme(formData?.theme) ? 'none' : null
                }}>
                    {props.questionData.title}
                </h4>
                <p id={'section-container-description'} style={{
                    color: isClassicTheme(formData?.theme) ? '#212121' : null,
                    textShadow: isClassicTheme(formData?.theme) ? 'none' : null
                }}>
                    {props.questionData.description}
                </p>
            </div>
        </div>
    );
}
