import React from 'react'
import {isMobileOnly} from "react-device-detect";
import {QUESTION_TYPE} from "../../../shared/utils/constants";
import {translationKeys} from "../../../localizations/translationKeys-localization";
import {t} from "i18next";
import {useSelector} from "react-redux";

function TextAnswerCard(props) {
    const textAnswer = props.responseText;
    const selectedLanguage = useSelector(state => state.languageReducer?.selectedLanguage)
    const handleWebLinkUrlClickEvent = () => {
        // open(props.responseText)
    }
    return (
        <div  className='text-card'>
            <div id='text-response-card-round' style={{width: isMobileOnly ? '10%' : '5%'}}>
                <div className="response-count-round">
                    {props.index + 1}
                </div>
            </div>
            <div id='text-response-card' className="text-response-container">
                {
                    (() => {
                            if (props.responseType === QUESTION_TYPE.FILE_UPLOAD.key && textAnswer && textAnswer.length > 0) return (
                                <p className={isMobileOnly ? "summary-text-response-text hover-pointer" : "response-text hover-pointer"}
                                   onClick={handleWebLinkUrlClickEvent}>
                                    {textAnswer}
                                </p>
                            )
                            return (
                                <p className="summary-text-response-text" style={(selectedLanguage === 'fa' || selectedLanguage === 'ar') ? {textAlign: 'right'} : null}>
                                    {(textAnswer &&
                                        textAnswer.length > 0) ?
                                        textAnswer : t(translationKeys.no_answer)}</p>
                            )
                        }
                    )()
                }
            </div>
        </div>
    )
}

export default TextAnswerCard