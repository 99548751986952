import React, {useEffect, useRef, useState} from 'react'
import '../css/quiz-summary.css'
import {getDateWithTime} from "../../../shared/utils/get-date-with-time";
import {getScoreColor} from "../utils/get-score-color";
import {isAllQuestionsEvaluated} from "../utils/evaluation-helper";
import {RESPONSE_VIEW} from "./ResponseContainer";
import {translationKeys} from "../../../localizations/translationKeys-localization";
import {t} from "i18next";
import {useSelector} from "react-redux";

const QuizSummaryContainer = props => {
    const [isLoading, setIsLoading] = useState(false);
    const TableRef = useRef();
    let cellWidth = 170;
    const quizUserInfo = props.formData.quiz_data.user_info;
    useEffect(() => {
        setIsLoading(false);
    }, [])

    return (
        <div className='quiz-summary-outer-wrapper'>
            <div  className='quiz-summary-inner-wrapper over-flow' ref={TableRef}>
                {!isLoading && props.respondentsDataRef.current.length ?
                    <table style={{width: '100%'}}>
                        <thead><TableHeader cellWidth={cellWidth} userInfo={quizUserInfo} isQuizV2={props.formData.is_quizzory_v2}/></thead>
                        <tbody id='quiz-summary-container'>
                        {props.respondentsDataRef.current.map((responderData, index) => {
                            return <TableRow cellWidth={cellWidth}
                                             key={index}
                                             index={index}
                                             isQuizV2={props.formData.is_quizzory_v2}
                                             userInfo={quizUserInfo}
                                             respondentData={responderData}
                                             changeResponseView={props.changeResponseView}/>
                        })}
                        </tbody>

                    </table>
                    : null}
            </div>
        </div>
    )
}

export default QuizSummaryContainer

const TableHeader = (props) => {
    return (
        <tr className='quiz-summary-table-header-row'>
            <th style={{borderTopLeftRadius: "5px", minWidth: '70px'}}>{t(translationKeys.serial_number)}</th>

            <th style={{minWidth: '240px'}}>{t(translationKeys.time_text)}</th>
            {props.userInfo.user_info_1 ? <th style={{width: props.cellWidth}}>
                <span className={'table-header-cell-span'}>{props.userInfo.user_info_1}</span></th> : null}

            <th style={{width: props.cellWidth}}>{t(translationKeys.percentage)}</th>
            {props.isQuizV2 ? <th style={{width: props.cellWidth}}>{t(translationKeys.correction_status)}</th> : null}

            {props.userInfo.user_info_2 ? <th style={{width: props.cellWidth}}>
                <span className={'table-header-cell-span'}>{props.userInfo.user_info_2}</span></th> : null}

            {props.userInfo.user_info_3 ? <th style={{width: props.cellWidth}}>
                <span className={'table-header-cell-span'}>{props.userInfo.user_info_3}</span></th> : null}
        </tr>
    )
}

const TableRow = (props) => {
    const selectedLanguage = useSelector(state => state.languageReducer.selectedLanguage)
    let responderData = props.respondentData;
    const isValidated = props.isQuizV2 && responderData.responses.length !== 0 ? isAllQuestionsEvaluated(responderData.responses) : null;
    const scoreColor = props.isQuizV2 ? isValidated ? getScoreColor(responderData.quiz_data.results.correct_percentage) : '#FBC02D' : getScoreColor(responderData.quiz_data.results.correct_percentage) ;
    return (
        <tr className='quiz-summary-table-body-row'
            onClick={() => {
                props.changeResponseView(RESPONSE_VIEW.INDIVIDUAL, props.index + 1)
            }}>
            <td style={{width: '70px', textAlign: 'center', color: '#757575'}}>{props.index + 1}</td>
            <td style={{width: '240px', textAlign: 'center', color: '#757575'}} dir={(selectedLanguage === 'fa' || selectedLanguage === 'ar') ? "ltr" : null}>
                {getDateWithTime(responderData.submit_time)}</td>
            <td style={{width: props.cellWidth, textAlign: 'center'}}>
                {responderData.quiz_data.user_info && responderData.quiz_data.user_info.user_info_1 ? responderData.quiz_data.user_info.user_info_1 : t(translationKeys.no_answer)}
            </td>
            <td style={{width: props.cellWidth, textAlign: 'center', display: 'flex', justifyContent: 'center'}}>
                <div className='quiz-summary-percentage-color-box'
                     style={{backgroundColor: scoreColor + 15}}>
                    <span style={{color: scoreColor, fontSize: '16px'}}>{props.isQuizV2 ? isValidated ? (Math.round(responderData.quiz_data.results.correct_percentage) + '%') : null : (responderData.quiz_data.results.correct_percentage + '%')}</span>
                    {props.isQuizV2 && !isValidated ? <img style={{width: '24px'}} alt='dots' src='images/more_yellow.svg'/> : null}
                </div>
            </td>
            {props.isQuizV2 ? <td style={{
                width: props.cellWidth,
                textAlign: 'center',
                color: isValidated ? '#46A24A' : '#FBC02D'
            }}>
                {isValidated ? t(translationKeys.evaluated) : t(translationKeys.pending_evaluation)}</td> : null}
            {props.userInfo && props.userInfo.user_info_2 ?
                <td style={{width: props.cellWidth, color: '#4d4d4d', textAlign: 'center'}}>
                    {responderData.quiz_data.user_info.user_info_2 && responderData.quiz_data.user_info.user_info_2.length > 0 ?
                        responderData.quiz_data.user_info.user_info_2 : 'No Data'}
                </td> : null}
            {props.userInfo && props.userInfo.user_info_3 ?
                <td style={{width: props.cellWidth, color: '#4d4d4d', textAlign: 'center'}}>
                    {responderData.quiz_data.user_info.user_info_3 && responderData.quiz_data.user_info.user_info_3.length > 0 ?
                        responderData.quiz_data.user_info.user_info_3 : 'No Data'}
                </td> : null}
        </tr>
)
}
