import calculateQuizV2Result from "./calculate-quiz-result";

/**
 * @param responseJSONObject = object is the result JSON of /responses api call
 * @returns {*} returns the updated responseJSONObject
 */

const updateFormResponsesData = (responseJSONObject) => {
    for (let i = 0; i < responseJSONObject.formResponses.respondents.length; i++) {
        const currentRespondentData = responseJSONObject.formResponses.respondents[i];
        currentRespondentData.quiz_data.results = calculateQuizV2Result(responseJSONObject.formData.pages[0].questions, currentRespondentData.responses);
    }
    return responseJSONObject
}

export default updateFormResponsesData