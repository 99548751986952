import React from 'react'
import MaterialCheckBox from "../../../shared/components/MaterialCheckBox";
import {QUIZ_SETTINGS} from "./QuizBuilderContainer";
import {translationKeys} from "../../../localizations/translationKeys-localization";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";

const SettingsQuizQuestionsContainer = props => {
    const {t} =  useTranslation()
    const selectedLanguage = useSelector(state => state.languageReducer.selectedLanguage)
    const formData = useSelector(state => state.appReducer.formData)
    const handleShowQuestionNumberChanged = (e) => {
        props.updateSetting(QUIZ_SETTINGS.SHOW_QUESTION_NUMBER, e)
    }

    const handleShuffleQuestionsChanged = (e) => {
        props.updateSetting(QUIZ_SETTINGS.IS_SHUFFLED, e)
    }

    const handleShowQuestionMarksChanged = (e) => {
        props.updateSetting(QUIZ_SETTINGS.SHOW_QUESTION_MARKS, e)
    }

    return (
        <div id={'settings-quiz-container'} className="settings-quiz-result-AND-questions-wrapper" style={selectedLanguage === "ta" ? {whiteSpace: "pre-wrap"} : null}>
            {/* Row 1 */}
            <div id={'settings-show-question-number-checkbox'} className="settings-quiz-result-AND-questions-box1">
                <MaterialCheckBox isChecked={formData?.setting.show_question_number}
                                  removeWidth={true}
                                  onChange={handleShowQuestionNumberChanged}

                />
            </div>
            <div className="settings-quiz-result-AND-questions-box2">
                <img src="../../images/show_numbers.svg" alt="Show Numbers"/>
                <div id={'show-question-number'} className="settings-bold-text">{t(translationKeys.show_question_number)}</div>
            </div>
            {/* Row 2 */}
            <div className="settings-quiz-result-AND-questions-box3">
                <span id={'show-quiz-question-no'}
                    className='settings-normal-text'>{t(translationKeys.show_question_number_in_quiz)}</span>
            </div>
            {/* Row 3 */}
            <div id={'settings-show-question-marks-checkbox'} className="settings-quiz-result-AND-questions-box4">
                <MaterialCheckBox isChecked={formData?.setting.show_question_marks}
                                  removeWidth={true}
                                  onChange={handleShowQuestionMarksChanged}

                />
            </div>
            <div className="settings-quiz-result-AND-questions-box5">
                <img src="../../images/question-marks.svg" alt="QuestionMarks"/>
                <div id={'show-question-marks'} className="settings-bold-text">{t(translationKeys.show_question_marks)}</div>
            </div>
            {/* Row 4 */}
            <div className="settings-quiz-result-AND-questions-box6">
                <span id={'show-question-marks-on-quiz'} className='settings-normal-text'>{t(translationKeys.show_question_marks_quiz)}?</span>
            </div>
            {/* Row 5 */}
            <div id={'settings-shuffled'} className="settings-quiz-result-AND-questions-box7">
                <MaterialCheckBox isChecked={formData?.setting.is_shuffled}
                                  removeWidth={true}
                                  onChange={handleShuffleQuestionsChanged}

                />
            </div>
            <div className="settings-quiz-result-AND-questions-box8">
                <img src="../../images/shuffle.svg" alt="Shuffle"/>
                <div id={'setting-shuffle-questions'} className="settings-bold-text">{t(translationKeys.shuffle_questions)}</div>
            </div>
            {/* Row 6 */}
            <div className="settings-quiz-result-AND-questions-box9">
                <span id={'setting-shuffle-quiz-questions'} className='settings-normal-text'>{t(translationKeys.shuffle_quiz_questions_alert)}</span>
            </div>
        </div>
    )
}

export default SettingsQuizQuestionsContainer
