import Chart from 'chart.js';
import {isMobileOnly} from "react-device-detect";
import {translationKeys} from "../../../localizations/translationKeys-localization";
import {t} from "i18next";
import {convertFirstLetterToUpper, isInt} from "../../../shared/utils/helper"
import {QUESTION_TYPE} from "../../../shared/utils/constants"

export function createBarChart(graphLabels, graphData, chartHolderElement, canvasElement) {
    let barChartElement = canvasElement.getContext('2d');
    barChartElement.clearRect(0, 0, canvasElement.width, canvasElement.height);
    let data = {
        labels: graphLabels,
        datasets: [{
            backgroundColor: getBgColors(graphLabels.length),
            data: graphData
        }]
    };
    let fontSize;
    if (isMobileOnly) {
        fontSize = 12
    } else {
        fontSize = 14
    }
    let options = {
        showAllTooltips: true,
        maintainAspectRatio: false,
        title: {
            display: false
        },
        scales: {
            yAxes: [{
                stacked: true,
                gridLines: {
                    display: false,
                },
                ticks: {
                    autoSkip: false,
                    min: 0,
                    // max: this.max,
                    callback: function (value) {
                        // return (value / this.max * 100).toFixed(0) + '%'; // convert it to percentage
                        return value;
                    },
                    fontSize: fontSize,
                    fontColor: "#212121"
                }
            }],
            xAxes: [{
                stacked: true,
                gridLines: {
                    display: false,
                },
                ticks: {
                    autoSkip: false,
                    fontSize: fontSize,
                    fontColor: "#000000",
                },
                scaleLabel: {
                    display: true,
                    labelString: t(translationKeys.response_count),
                    fontColor: '#424242',
                    fontSize: fontSize
                }
            }]
        },
        legend: {
            display: false,
            align: 'start',
            fullWidth: true,
            labels: {
                generateLabels: chart => {
                    const data = chart.data;
                    return Array.isArray(data.labels) ? data.labels.map((label, i) => {
                        return {
                            text: label,
                            fillStyle: (!Array.isArray(data.datasets[0].backgroundColor) ? data.datasets[0].backgroundColor : data.datasets[0].backgroundColor[i])
                        };
                    }, this) : [];
                },
                fontSize: 16,
                boxWidth: 30,
                padding: 20,
            },

        },
        tooltips: {
            enabled: false,
        },
        plugins: {
            labels: {
                // render 'label', 'value', 'percentage', 'image' or custom function, default is 'percentage'
                render: 'value',

                // precision for percentage, default is 0
                precision: 0,

                // identifies whether or not labels of value 0 are displayed, default is false
                showZero: true,

                // font size, default is defaultFontSize
                fontSize: 16,

                // font color, can be color array for each data or function for dynamic color, default is defaultFontColor
                fontColor: '#212121',

                // font style, default is defaultFontStyle
                fontStyle: 'normal',

                // font family, default is defaultFontFamily
                fontFamily: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",

                // draw text shadows under labels, default is false
                textShadow: false,

                // text shadow intensity, default is 6
                shadowBlur: 10,

                // text shadow X offset, default is 3
                shadowOffsetX: -5,

                // text shadow Y offset, default is 3
                shadowOffsetY: 5,

                // text shadow color, default is 'rgba(0,0,0,0.3)'
                shadowColor: 'rgba(255,0,0,0.75)',

                // draw label in arc, default is false
                // bar chart ignores this
                arc: true,

                // position to draw label, available value is 'default', 'border' and 'outside'
                // bar chart ignores this
                // default is 'default'
                position: 'default',

                // draw label even it's overlap, default is true
                // bar chart ignores this
                overlap: true,

                // show the real calculated percentages from the values and don't apply the additional logic to fit the percentages to 100 in total, default is false
                showActualPercentages: true,

                // set images when `render` is 'image'
                images: [
                    {
                        src: 'image.png',
                        width: 16,
                        height: 16
                    }
                ],
                // add padding when position is `outside`
                // default is 2
                outsidePadding: 4,

                // add margin of text when position is `outside` or `border`
                // default is 2
                textMargin: 4
            }
        }
    };

    let barChart = new Chart(barChartElement, {
        type: 'horizontalBar',
        data: data,
        options: options
    });
}

const scaleLabelObj = (labelString, fontSize) => {
    return {
        display: true,
        labelString: labelString,
        fontColor: '#424242',
        fontSize: fontSize,
        fontStyle: 'bold'
    }
}

const groupedAndStackedBarChartLegend = (fontSize) => {
    return {
        align: "end",
        position: "top",
        display: true,
        labels: {
            fontSize: fontSize,
            fontColor: 'rgb(0, 0, 0)'
        }
    }
}

export const getColumnsDataSets = (graphData) => {
    let columnDataSets = new Array(graphData.responseAnalyzeData.columnsIdList?.length)

    for (let ctr = 0; ctr < graphData.responseAnalyzeData.columnsIdList?.length; ctr++) {
        columnDataSets[ctr] = {
            label: convertFirstLetterToUpper(graphData.responseAnalyzeData.columnGraphLabels[ctr]),
            backgroundColor: getBgColor(ctr),
            data: new Array(graphData.responseAnalyzeData.rowsIdList?.length).fill(0),
            columnId: graphData.responseAnalyzeData.columnsIdList[ctr]
        }
    }

    let maxBarChartValue = 0

    if (graphData.type === QUESTION_TYPE.CHECKBOX_GRID.key) {
        for (let gcCtr = 0; gcCtr < graphData.checkbox_grid_choices?.length; gcCtr++) {
            for (let ctr = 0; ctr < columnDataSets.length; ctr++) {
                let insertionIndex = graphData.responseAnalyzeData.rowsIdList.indexOf(graphData.checkbox_grid_choices[gcCtr].row)
                let graphDataAssociatedWithRow = graphData.responseAnalyzeData.columnGraphData.find(cgd => cgd.row === graphData.checkbox_grid_choices[gcCtr].row)

                columnDataSets[ctr].data[insertionIndex] = graphDataAssociatedWithRow.columns.filter(col => col === columnDataSets[ctr].columnId).length

                if (columnDataSets[ctr].data[insertionIndex] > maxBarChartValue) {
                    maxBarChartValue = columnDataSets[ctr].data[insertionIndex]
                }
            }
        }
    } else if (graphData.type === QUESTION_TYPE.MULTIPLE_CHOICE_GRID.key) {
        for (let gcCtr = 0; gcCtr < graphData.grid_choices?.length; gcCtr++) {
            for (let ctr = 0; ctr < columnDataSets.length; ctr++) {
                if (graphData.grid_choices[gcCtr].column === columnDataSets[ctr].columnId) {
                    let insertionIndex = graphData.responseAnalyzeData.rowsIdList.indexOf(graphData.grid_choices[gcCtr].row)
                    columnDataSets[ctr].data[insertionIndex]++

                    if (columnDataSets[ctr].data[insertionIndex] > maxBarChartValue) {
                        maxBarChartValue = columnDataSets[ctr].data[insertionIndex]
                    }
                }
            }
        }
    }
    return [columnDataSets, maxBarChartValue]
}

export function createGroupedBarChart(datasets, labels, maxBarChartValue, chartHolderElement, canvasElement) {
    let groupedBarChartElement = canvasElement.getContext('2d');

    groupedBarChartElement.clearRect(0, 0, canvasElement.width, canvasElement.height);

    let data = {
        labels: labels,
        datasets: datasets
    };

    let fontSize;

    if (isMobileOnly) {
        fontSize = 12
    } else {
        fontSize = 16
    }
    let options = {
        showAllTooltips: true,
        maintainAspectRatio: false,
        responsive: true,
        title: {
            display: false
        },
        scales: {
            xAxes: [{
                gridLines: {
                    display: false,
                },
                ticks: {
                    autoSkip: false,
                    fontSize: fontSize,
                    fontColor: "#212121"
                },
                scaleLabel: scaleLabelObj(t(translationKeys.rows), fontSize)
            }],
            yAxes: [{
                ticks: {
                    min: 0,
                    max: Math.ceil(maxBarChartValue / 10) * 10,
                    callback: function (value) {
                        if (value === 0) return ''
                        if (isInt(value)) return value
                        return null
                    },
                    fontSize: fontSize,
                    fontColor: "#212121"
                },
                scaleLabel: scaleLabelObj(t(translationKeys.columns), fontSize)
            }]
        },
        legend: groupedAndStackedBarChartLegend(fontSize),
        tooltips: {
            enabled: false,
        }
    };

    let groupedBarChart = new Chart(groupedBarChartElement, {
        type: 'bar',
        data: data,
        options: options,
        plugins: {
            beforeInit: function (chart) {
                chart.legend.afterFit = function () {
                    this.height = this.height + 25
                }
            }
        }
    })
}

export const prepareStackedBarChart = (canvasElement, graphData) => {
    let stackedBarChartElement = canvasElement.getContext('2d');

    stackedBarChartElement.clearRect(0, 0, canvasElement.width, canvasElement.height);

    let columnDataSets = new Array(graphData.responseAnalyzeData.columnsIdList.length)

    for (let ctr = 0; ctr < graphData.responseAnalyzeData.columnsIdList.length; ctr++) {
        columnDataSets[ctr] = {
            label: convertFirstLetterToUpper(graphData.responseAnalyzeData.columnGraphLabels[ctr]),
            backgroundColor: getBgColor(ctr),
            data: new Array(graphData.responseAnalyzeData.rowsIdList.length).fill(0),
            columnId: graphData.responseAnalyzeData.columnsIdList[ctr]
        }
    }
    return {stackedBarChartElement, columnDataSets}
}

export const displayStackedBarChart = function (graphData, columnDataSets, stackedBarChartElement) {
    let data = {
        labels: graphData.responseAnalyzeData.rowGraphLabels,
        datasets: columnDataSets
    };

    let fontSize;
    if (isMobileOnly) {
        fontSize = 12
    } else {
        fontSize = 16
    }
    let options = {
        showAllTooltips: true,
        responsive: true,
        maintainAspectRatio: false,
        title: {
            display: false
        },
        scales: {
            xAxes: [{
                gridLines: false,
                stacked: true,
                ticks: {
                    display: true,
                    stepSize: .25,
                    min: 0,
                    callback: function (value) {
                        return `${value * 100}%`;
                    },
                    fontSize: fontSize,
                    fontColor: "#212121"
                },
                scaleLabel: scaleLabelObj(t(translationKeys.columns), fontSize)
            }],
            yAxes: [{
                gridLines: false,
                stacked: true,
                ticks: {
                    fontSize: fontSize,
                    fontColor: "#212121",
                    callback: function (value) {
                        if (value.length > 10) {
                            return `${value.substring(0, 10)}...   `;
                        } else {
                            return `${value}   `;
                        }
                    },
                },
                scaleLabel: scaleLabelObj(t(translationKeys.rows), fontSize)
            }]
        },
        legend: groupedAndStackedBarChartLegend(fontSize),
        tooltips: {
            enabled: false,
        }
    };

    let stackedBarChart = new Chart(stackedBarChartElement, {
        type: 'horizontalBar',
        data: data,
        options: options,
        plugins: [
            {
                beforeInit: function (chart) {
                    chart.legend.afterFit = function () {
                        this.height = this.height + 25
                    }
                },
                afterDraw: chart => {
                    let ctx = chart.chart.ctx
                    ctx.save()
                    ctx.textAlign = 'center'
                    ctx.textBaseline = 'middle'
                    ctx.font = "12px Nunito Sans"
                    let xAxis = chart.scales['x-axis-0']
                    let yAxis = chart.scales['y-axis-0']
                    let datasets = chart.chart.data.datasets.filter(ds => !ds._meta?.[0]?.hidden)

                    yAxis.ticks.forEach((value, xIndex) => {
                        let y = yAxis.getPixelForTick(xIndex)
                        datasets.forEach((dataset, iDataset) => {
                            if (dataset.data[xIndex] > 0) {
                                // If the value is too low (below 3%), it is not shown if the below line is used.
                                // if (dataset.data[xIndex] > 3) {
                                let xValue = datasets.slice(0, iDataset)
                                        .map(ds => ds.data[xIndex])
                                        .reduce((a, b) => a + b, 0) +
                                    dataset.data[xIndex] / 2
                                let x = xAxis.getPixelForValue(xValue)

                                ctx.fillStyle = '#FFFFFF'

                                ctx.fillText(`${(dataset.data[xIndex] * 100).toFixed(0)}%`, x, y)
                            }
                        })
                    })
                    ctx.restore()
                }
            }
        ]
    });
}

export function getColumnDataSetsForStackedBarChartForCheckboxGrid(graphData, columnDataSets, respondents) {

    // Loop through the responses in respondents (respondents[counter].responses). When a checkbox question is found, check for a matching Row.
    // When a matching Row is found, populate the Column Data Set based on matching the column ID.
    columnDataSets?.forEach(cds => {
        respondents?.forEach(respondent => {
            respondent.responses?.forEach(response => {
                if (response.type === QUESTION_TYPE.CHECKBOX_GRID.key) {
                    response.checkbox_grid_choices.forEach(cgc => {
                        let rowIndex = graphData.responseAnalyzeData.rowsIdList.indexOf(cgc.row)

                        cgc.columns?.forEach(col => {
                            if (cds.columnId === col) {
                                cds.data[rowIndex] += 1
                            }
                        })
                    })
                }
            })
        })
    })

    let uniqueRows = Array.from(graphData.responseAnalyzeData.rowsIdList, rowId => ({
        row: rowId,
        totalNumberOfMatchingColumns: 0
    }))

    for (let ctr = 0; ctr < columnDataSets.length; ctr++) {
        for (let cgdCtr = 0; cgdCtr < graphData.responseAnalyzeData.columnGraphData.length; cgdCtr++) {
            let matchingColumns = graphData.responseAnalyzeData.columnGraphData[cgdCtr].columns.filter(col => col === columnDataSets[ctr].columnId)

            if (matchingColumns.length > 0) {
                let uniqueRow = uniqueRows.find(ur => ur.row === graphData.responseAnalyzeData.columnGraphData[cgdCtr].row)

                uniqueRow.totalNumberOfMatchingColumns += matchingColumns.length
            }
        }
    }

    uniqueRows = uniqueRows.sort((a, b) => a.row > b.row ? 1 : -1)

    uniqueRows.forEach((uniqueRow, rowIndex) => {
        columnDataSets.forEach((cds) => {
            cds.data[rowIndex] = cds.data[rowIndex] / uniqueRow.totalNumberOfMatchingColumns
        })
    })

    return columnDataSets
}

export function getColumnDataSetsForStackedBarChartForMultipleChoiceGrid(graphData, columnDataSets) {

    // Sort the Grid Rows so that the processing will work correctly.
    let sortedGridChoices = [...graphData.grid_choices.sort((a, b) => a.row > b.row ? 1 : -1)]
    let rowIndex

    for (let gcCtr = 0; gcCtr < sortedGridChoices.length; gcCtr++) {
        rowIndex = -1

        for (let ctr = 0; ctr < columnDataSets.length; ctr++) {
            if (sortedGridChoices[gcCtr].column === columnDataSets[ctr].columnId) {
                // When a match is found, increase the count associated with the Row
                rowIndex = graphData.responseAnalyzeData.rowsIdList.indexOf(sortedGridChoices[gcCtr].row)
                columnDataSets[ctr].data[rowIndex] = columnDataSets[ctr].data[rowIndex] + 1
            }
        }
    }

    let uniqueRows = [...new Set(graphData.grid_choices.map(gc => gc.row))]

    rowIndex = 0

    uniqueRows.forEach((uniqueRow) => {
        let columnsAssociatedWithTheRow = graphData.grid_choices.filter(gc => gc.row === uniqueRow).map(gc => gc.column)
        let previouslyProcessedColumnIds = []
        let rowResponseCount = graphData.grid_choices.filter(gc => gc.row === uniqueRow).length

        for (let ctr = 0; ctr < columnDataSets.length; ctr++) {
            columnsAssociatedWithTheRow.forEach((columnAssociatedWithTheRow) => {
                if (columnAssociatedWithTheRow === columnDataSets[ctr].columnId && previouslyProcessedColumnIds.indexOf(columnDataSets[ctr].columnId) === -1) {
                    columnDataSets[ctr].data[rowIndex] = columnDataSets[ctr].data[rowIndex] / rowResponseCount
                    previouslyProcessedColumnIds.push(columnDataSets[ctr].columnId)
                }
            })
        }

        rowIndex++
    })
    return columnDataSets
}

export function createPieChart(graphLabels, graphData, pieChartElement) {
    let trimmedGraphLabels = graphLabels.map(label => {
        if (label.length > 15) {
            label = label.substring(0, 14) + '...';
            return label
        } else return label;
    });
    let data = {
        labels: trimmedGraphLabels,
        datasets: [{
            label: trimmedGraphLabels,
            backgroundColor: getBgColors(graphLabels.length),
            data: graphData,
        }]
    };
    let fontSize;
    if (isMobileOnly) {
        fontSize = 16
    } else {
        fontSize = 20
    }
    let options = {
        maintainAspectRatio: false,
        title: {
            display: true,
            text: t(translationKeys.pie_chart),
            fontSize: fontSize
        },
        legend: {
            position: "bottom",
            display: true,
            labels: {
                usePointStyle: true,
                fontSize: 16,
                fontColor: 'rgb(0, 0, 0)',
            }
        },
        tooltips: {
            mode: 'nearest',
            titleFontSize: fontSize
        }
    };
    let context = pieChartElement.getContext('2d');
    let pieChart = new Chart(context, {
        type: 'doughnut',
        data: data,
        options: options
    });
}

const bgColorArray = ['rgba(255, 99, 132, 1)',
    'rgba(54, 162, 235, 1)',
    'rgba(255, 206, 86, 1)',
    'rgba(75, 192, 192, 1)',
    'rgba(153, 102, 255, 1)',
    'rgba(255, 159, 64, 1)',
    'rgba(255, 99, 132, 1)',
    'rgba(54, 162, 235, 1)',
    'rgba(255, 206, 86, 1)'];

const adminColors = [
    'rgba(139, 0, 0, 1)',
    'rgba(0, 100, 0, 1)',
    'rgba(0, 0, 139, 1)',
    'rgba(184, 134, 11, 1)',
    'rgba(128, 0, 128, 1)',
    'rgba(0, 139, 139, 1)',
    'rgba(90, 12, 90, 1)',
    'rgba(255, 140, 0, 1)',
    'rgba(255, 20, 147, 1)',
    'rgba(0, 109, 109, 1)',
    'rgba(115, 79, 121, 1)',
    'rgba(139, 69, 19, 1)',
    'rgba(30, 144, 255, 1)',
    'rgba(172, 75, 75, 1)',
    'rgba(85, 107, 47, 1)',
    'rgba(0, 0, 128, 1)',
    'rgba(121, 117, 66, 1)',
    'rgba(46, 139, 87, 1)',
    'rgba(210, 105, 30, 1)',
    'rgba(47, 79, 79, 1)',
    'rgba(0, 79, 159, 1)',
    'rgba(255, 191, 0, 1)',
    'rgba(202, 0, 76, 1)',
    'rgba(132, 0, 255, 1)',
    'rgba(229, 0, 46, 1)',
    'rgba(0, 193, 174, 1)',
    'rgba(225, 0, 225, 1)',
    'rgba(64, 105, 147, 1)',
    'rgba(255, 92, 76, 1)',
    'rgba(0, 194, 0, 1)'
];

const getBgColor = index => {
    if (index < bgColorArray.length) {
        return bgColorArray[index]
    } else {
        return bgColorArray[index % bgColorArray.length]
    }
}

export const getBgColors = numberOfLabels => {
    let requiredArray = bgColorArray;
    const forLoopLength = ((numberOfLabels ? numberOfLabels : 0) / 9);
    if (forLoopLength > 1) {
        for (let i = 1; i < forLoopLength; i++) {
            requiredArray = requiredArray.concat(bgColorArray)
        }
    }
    return requiredArray
}

export const getAdminColors = numberOfLabels => {
    let requiredArray = adminColors;
    const forLoopLength = ((numberOfLabels ? numberOfLabels : 0) / 9);
    if (forLoopLength > 1) {
        for (let i = 1; i < forLoopLength; i++) {
            requiredArray = requiredArray.concat(adminColors)
        }
    }
    return requiredArray
}
