import React from 'react'
import MaterialCheckBox from "../../../shared/components/MaterialCheckBox";
import {FORM_SETTINGS} from "./FormBuilderContainer";
import {QUIZ_SETTINGS} from "./QuizBuilderContainer";
import {translationKeys} from "../../../localizations/translationKeys-localization";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {BUILDER_ACTION_TYPE} from "../../reducers/builderReducer";

const SettingsControlQuestionContainer = props => {
    const {t} = useTranslation()
    const formData = useSelector(state => state.appReducer.formData)
    const selectedLanguage = useSelector(state => state.languageReducer.selectedLanguage)
    const dispatch = useDispatch()

    const handleShowQuestionNumbersChanged = (e) => {
        props.updateSetting(FORM_SETTINGS.SHOW_QUESTION_NUMBER, e)
    }

    const handleShuffleQuestionsChanged = (e) => {
        props.updateSetting(FORM_SETTINGS.IS_SHUFFLED, e)
    }

    const handleShowQuestionMarksChanged = (e) => {
        props.updateSetting(QUIZ_SETTINGS.SHOW_QUESTION_MARKS, e)
    }

    const handleDisabledCheckboxClick = () => {
        if (formData?.is_logic_enabled) {
            dispatch({type: BUILDER_ACTION_TYPE.SET_BUILDER_MESSAGE, payload: 'Action cannot be performed'});
            dispatch({type: BUILDER_ACTION_TYPE.SET_BUILDER_TOAST_MESSAGE, payload: true})
        }
    }

    return (
        <React.Fragment>
            <div id={'settings-responses-and-questions-wrapper'}
                 className="settings-responses-and-questions-wrapper" style={{padding: '0'}}>
                {/* Row 1 */}
                <div id={'settings-show-question-number'} className="settings-responses-and-questions-box2"
                     style={{justifyContent: 'center', alignItems: 'center'}}>
                    <img src="../../images/show_numbers.svg" alt="Number One Icon"/>
                    <div className="settings-bold-text">{t(translationKeys.show_question_number)}</div>
                    <div id={'settings-responses-and-questions-box1'} className="settings-responses-and-questions-box1"
                         style={{margin: (selectedLanguage === 'fa' || selectedLanguage === 'ar') ? '0 auto 0 0' : '0 0 0 auto'}}>
                        <MaterialCheckBox isChecked={formData?.setting.show_question_number}
                                          removeWidth={true}
                                          onChange={handleShowQuestionNumbersChanged}

                        />
                    </div>
                </div>
                {/* Row 2 */}
                <div id={'settings-show-question-number-form'} className="settings-responses-and-questions-box4"
                     style={{margin: '0 30px'}}>
                    <span className='settings-normal-text'>{formData?.is_quiz ? t(translationKeys.show_question_number_in_quiz) : t(translationKeys.show_question_number_in_form)}</span>
                </div>
                {props.isQuiz ? <>{/* Row 3 */}
                    <div id={'settings-show-question-marks'} className="settings-responses-and-questions-box6"
                         style={{justifyContent: 'center', alignItems: 'center'}}>
                        <img src="../../images/question-marks.svg" alt="Shuffle Icon"/>
                        <div className="settings-bold-text">{t(translationKeys.show_question_marks)}</div>
                        <div id={'settings-responses-and-questions-box5'}
                             className="settings-responses-and-questions-box9" style={{margin: (selectedLanguage === 'fa' || selectedLanguage === 'ar') ? '0 auto 0 0' : '0 0 0 auto'}}>
                            <MaterialCheckBox isChecked={formData?.setting.show_question_marks}
                                              removeWidth={true}
                                              onChange={handleShowQuestionMarksChanged}/>
                        </div>
                    </div>
                    {/* Row 4 */}
                    <div id={'settings-show-question-marks-quiz'} className="settings-responses-and-questions-box8"
                         style={{margin: '0 30px'}}>
                        <span className='settings-normal-text'>{t(translationKeys.show_question_marks_quiz)}?</span>
                    </div>
                </> : null}
                {/* Row 5 */}
                <div id={'settings-shuffle-questions'} className="settings-responses-and-questions-box10"
                     style={{justifyContent: 'center', alignItems: 'center'}}>
                    <img src="../../images/shuffle.svg" alt="Shuffle Icon"/>
                    <div className="settings-bold-text">{t(translationKeys.shuffle_questions)}</div>
                    <div id={'settings-responses-and-questions-box9'} className="settings-responses-and-questions-box9"
                         style={{margin: (selectedLanguage === 'fa' || selectedLanguage === 'ar') ? '0 auto 0 0' : '0 0 0 auto'}}
                         onClick={handleDisabledCheckboxClick}>
                        <MaterialCheckBox isChecked={formData?.setting.is_shuffled}
                                          removeWidth={true}
                                          onChange={handleShuffleQuestionsChanged}
                                          isDisabled={formData?.is_logic_enabled}/>
                    </div>
                </div>
                {/* Row 6 */}
                <div id={'settings-shuffle-form-questions-alert'} className="settings-responses-and-questions-box12"
                     style={{margin: '0 30px'}}>
                    <span className='settings-normal-text'>{props.isQuiz ? t(translationKeys.shuffle_quiz_questions_alert) : t(translationKeys.shuffle_questions_alert)}</span>
                </div>
            </div>
        </React.Fragment>

    )
}
export default SettingsControlQuestionContainer