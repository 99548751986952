import FormPage from "./FormPage";
import FormTheme from "./FormTheme";
import * as Vibrant from "node-vibrant";
import {isMobileOnly} from "react-device-detect";
import {useDispatch, useSelector} from "react-redux";
import ThankPageContainer from "./ThankPageContainer";
import {QuizUserInfoPopup} from "./QuizUserInfoPopup";
import {FORM_SCREEN, THEME_TYPE} from "../utils/utils";
import SnackBar from "../../shared/components/SnackBar";
import {isImageTheme} from "../utils/theme-color-helper";
import React, {useEffect, useRef, useState} from "react";
import {PREVIEW_TYPE} from "../../shared/utils/constants";
import WelcomePageContainer from "./WelcomePageContainer";
import {THEME_ACTION_TYPE} from "../reducers/themeReducer";
import {SCREENS_ACTION_TYPE} from "../reducers/screensReducer";
import {initializeUserInfoData} from "../utils/user-info-data-helper";
import AnimationLoader from "../../shared/components/AnimationLoader";
import {
    AWS_BUCKET_MIDDLE_PATH,
    AWS_FOLDER_NAMES,
    AWS_UPLOAD_TYPE,
    MEDIA_BASE_URL,
    setAwsFolderName
} from "../../app/builder/utils/aws";
import {FORM_DATA_ACTION_TYPE} from "../reducers/formDataReducer";
import {QUESTIONS_ACTION_TYPE} from "../reducers/questionsReducer";
import AbuseWarningPopup from "./AbuseWarningPopup";
import {FormUserInfoPopup} from "./FormUserInfoPopup";
import ForqTimer from "./ForqTimer";
import cryptoJS from "crypto-js";
import {uploadImageFile} from "../../app/builder/utils/upload-file-to-aws";
import store from "../store/forqStore";
import CollectEmailPage from "./CollectEmailPage";
import PassCodePage from "./PassCodePage";
import EditResponsePage from "./EditResponsePage";
import {getResponsesOfResponder} from "../../shared/utils/forq-api-services";
import ResponseHistoryPage from "./ResponseHistoryPage";

let formThemeImageSrc;
export default function FormComponent(props) {
    const [responded, setResponded] = useState(false)
    const dispatch = useDispatch()

    const currentFormScreen = (() => {
        if (localStorage?.getItem("isClear")) {
            return FORM_SCREEN.FORM_PAGE
        }
        const nextFormScreen = () => {
            if (props.formData.time_remaining) {
                dispatch({type: FORM_DATA_ACTION_TYPE.SET_SHOW_FORQ_TIMER, payload: true})
                return FORM_SCREEN.FORM_AVAILABLE_TIMER
            }
            if (!window.location.search.includes("admin=83A7DA3315ED77BC361D4271BC216") && props.formData?.setting?.passcode && !props.previewType && !props.preview) {
                return FORM_SCREEN.PASSCODE_PAGE
            }
            if (!window.location.search.includes("admin=83A7DA3315ED77BC361D4271BC216") && !props.previewType && !props.preview && props.formData.setting.is_email && !window?.localStorage?.getItem("responder_token")) {
                return FORM_SCREEN.COLLECT_EMAIL_PAGE
            }
            if (!window.location.search.includes("admin=83A7DA3315ED77BC361D4271BC216") && !props.previewType && !props.preview && props.formData.setting.allow_response_edit && window?.localStorage?.getItem("responder_token")) {
                if (responded || (window.localStorage?.getItem(String(props.formData._id))) && props.formData.response_count > 0) {
                    return FORM_SCREEN.FORM_EDIT_RESPONSE
                }
            }
            if (props.formData.welcome_screen.show_screen) {
                return FORM_SCREEN.WELCOME_PAGE
            }
            if (props.formData.abuse_scan_result && !props.previewType) {
                return FORM_SCREEN.ABUSE_WARNING
            } else if (props.formData.is_quiz) {
                return FORM_SCREEN.QUIZ_USER_INFO
            }
            if (!props.formData.is_quiz && props.formData?.user_info_form?.user_info_1 && !props.formData.setting?.is_email) {
                return FORM_SCREEN.FORM_USER_INFO
            } else {
                return FORM_SCREEN.FORM_PAGE
            }
        }

        if (props.formData.abuse_scan_result && window.location.search.includes("admin=83A7DA3315ED77BC361D4271BC216") && !props.formData.welcome_screen.show_screen) {
            return FORM_SCREEN.FORM_PAGE
        }

        return nextFormScreen();
    })()
    const snackBarRef = useRef(undefined)
    const loading = useSelector(state => state.formDataReducer.loading)
    const themeColor = useSelector(state => state.themeReducer.themeColor)
    const formScreen = useSelector(state => state.screensReducer.formScreen)
    const responses = useSelector(state => state.formDataReducer.formResponses)
    const storageUsed = useSelector(state => state.formDataReducer.storageUsed)
    const fillerSummary = useSelector(state => state.screensReducer.fillerSummary)
    const showForqTimer = useSelector(state => state.formDataReducer.showForqTimer)
    const filesToUpload = useSelector(state => state.questionsReducer.filesToUpload)
    const totalUserStorage = useSelector(state => state.formDataReducer.totalUserStorage)
    const AWS_PC_WEB_PATH = MEDIA_BASE_URL + AWS_BUCKET_MIDDLE_PATH + AWS_FOLDER_NAMES.PC_FOLDER;
    const AWS_MOBILE_WEB_PATH = MEDIA_BASE_URL + AWS_BUCKET_MIDDLE_PATH + AWS_FOLDER_NAMES.MOBILE_FOLDER;
    let updatedStorage = storageUsed
    let updatedTotalUserStorage = totalUserStorage

    useEffect(() => {
            dispatch({type: SCREENS_ACTION_TYPE.SET_PREVIEW, payload: props.preview})
            dispatch({type: FORM_DATA_ACTION_TYPE.SET_FORM_DATA, payload: props.formData})
            dispatch({type: SCREENS_ACTION_TYPE.SET_FORM_SCREEN, payload: currentFormScreen})
            dispatch({
                type: THEME_ACTION_TYPE.SET_THEME_IMAGE_BLUR,
                payload: props.formData.welcome_screen.show_screen ? 0 : 4
            })
            dispatch({
                type: FORM_DATA_ACTION_TYPE.SET_USER_INFO_DATA,
                payload: props.formData.is_quiz && props.formData?.quiz_data?.user_info ? initializeUserInfoData(props.formData.quiz_data.user_info) : props.formData?.user_info_form?.user_info_1 ? initializeUserInfoData(props.formData.user_info_form) : undefined
            })
        }, [
            showForqTimer,
            props.formData.theme,
            props.formData?.user_info_form?.user_info_1,
            props.formData?.quiz_data?.duration?.is_enabled,
            props.formData?.quiz_data?.user_info?.user_info_2,
            props.formData?.quiz_data?.user_info?.user_info_1,
            props.formData?.quiz_data?.user_info?.user_info_3
        ]
    )

    useEffect(() => {
        dispatch({type: FORM_DATA_ACTION_TYPE.SET_FORM_DATA, payload: props.formData})
        dispatch({type: FORM_DATA_ACTION_TYPE.SET_TOTAL_USER_STORAGE, payload: props.formData?.total_user_storage})
        dispatch({type: FORM_DATA_ACTION_TYPE.SET_TOTAL_USER_RESPONSE_COUNT, payload: props.formData?.total_user_response_count})
    }, [props.formData])

    useEffect(() => {
        if (props.formData.setting?.allow_response_edit && window?.localStorage?.getItem("responder_token")) {
            const successFunction = (res) => {
                if (res.responder_responses.length > 0) {
                    setResponded(true)
                    dispatch({type: FORM_DATA_ACTION_TYPE.SET_RESPONDER_RESPONSES, payload: res.responder_responses})
                }
            }
            const failureFunction = (err) => {
                console.log(err)
            }
            getResponsesOfResponder(props.formData._id, successFunction, failureFunction)
        }
        if (props.formData?.setting?.auto_close_time && !props.previewType && !props.preview) {
            const autoCloseTimeInterval = setInterval(function () {
                let now = new Date().getTime();
                let timeDifference = props.formData?.setting?.auto_close_time - now;

                let quizTimer
                if (props.formData.is_quiz && props.formData.quiz_data?.duration?.is_enabled) {
                    const hourInMilliSeconds = props.formData?.quiz_data?.duration?.hours ? (props.formData.quiz_data.duration.hours * 60 * 60 * 1000) : 0;
                    const minutesInMilliSeconds = props.formData?.quiz_data?.duration?.minutes ? (props.formData.quiz_data.duration.minutes * 60 * 1000) : 0;
                    quizTimer = hourInMilliSeconds + minutesInMilliSeconds
                }

                if (timeDifference <= 1800000) {
                    if (props.formData.is_quiz && props.formData.quiz_data?.duration?.is_enabled && quizTimer < timeDifference) {
                        dispatch({type: FORM_DATA_ACTION_TYPE.SET_SHOW_FORM_PAGE_TIMER, payload: false})
                    } else {
                        dispatch({type: FORM_DATA_ACTION_TYPE.SET_SHOW_FORM_PAGE_TIMER, payload: true})
                    }
                } else {
                    dispatch({type: FORM_DATA_ACTION_TYPE.SET_SHOW_FORM_PAGE_TIMER, payload: false})
                }

                if (timeDifference < 0) {
                    clearInterval(autoCloseTimeInterval);
                    if (store.getState().screensReducer.formScreen === FORM_SCREEN.FORM_PAGE) {
                        saveFileUploadsInAWS()
                    } else {
                        window.location.reload()
                    }
                }
            }, 1000);
        }
        dispatch({type: QUESTIONS_ACTION_TYPE.SET_FILE_UPLOAD_COMPLETE, payload: false})
    }, [])

    const saveFileUploadsInAWS = () => {
        if (filesToUpload.length > 0 && filesToUpload.filter(fileInfo => fileInfo.file !== null).length > 0) {
            dispatch({type: FORM_DATA_ACTION_TYPE.SET_LOADING, payload: true})
            setAwsFolderName(`${AWS_FOLDER_NAMES.FILES_FOLDER}/${cryptoJS.SHA3(props.formData?.user_id).toString().substr(0, 30)}/${props.formData?._id}`)
            filesToUpload?.map((fileInfo) => {
                if (fileInfo.file) {
                    uploadImageFile(AWS_UPLOAD_TYPE.FILE, fileInfo.file, fileUploadSucceeded, fileUploadFailed, props.formData?.user_id)
                }
            })
        } else {
            dispatch({type: QUESTIONS_ACTION_TYPE.SET_FILE_UPLOAD_COMPLETE, payload: true})
        }
    }

    const getLargestFileUploadIndex = () => {
        let largestFileUploadIndex = -1
        for (let indexCtr = 0; indexCtr < filesToUpload?.length; indexCtr++) {
            if (filesToUpload[indexCtr].file !== null) {
                largestFileUploadIndex = indexCtr
            }
        }
        return largestFileUploadIndex
    }

    const fileUploadSucceeded = async (data) => {
        let ctr
        for (ctr = 0; ctr < filesToUpload?.length; ctr++) {
            if (filesToUpload[ctr].file !== null && filesToUpload[ctr].link === null) {
                filesToUpload[ctr].link = data.Location.replace('%40', '@')
                const updatedResponses = responses
                updatedResponses.find(question => question.question_id === filesToUpload[ctr].questionId).fileSize = filesToUpload[ctr].file.size
                updatedResponses.find(question => question.question_id === filesToUpload[ctr].questionId).text = filesToUpload[ctr].link
                dispatch({type: FORM_DATA_ACTION_TYPE.SET_FORM_RESPONSES, payload: updatedResponses})
                updatedStorage = updatedStorage + filesToUpload[ctr].file.size
                updatedTotalUserStorage = totalUserStorage + filesToUpload[ctr].file.size
                break
            }
        }
        dispatch({type: FORM_DATA_ACTION_TYPE.SET_STORAGE_USED, payload: updatedStorage})
        dispatch({type: FORM_DATA_ACTION_TYPE.SET_TOTAL_USER_STORAGE, payload: updatedTotalUserStorage})
        if (ctr === getLargestFileUploadIndex()) {
            dispatch({type: QUESTIONS_ACTION_TYPE.SET_FILE_UPLOAD_COMPLETE, payload: true})
            dispatch({type: FORM_DATA_ACTION_TYPE.SET_LOADING, payload: false})
        }
    }

    const fileUploadFailed = (data) => {
        let ctr
        for (ctr = 0; ctr < filesToUpload.length; ctr++) {
            if (filesToUpload[ctr].file !== null && filesToUpload[ctr].link === null) {
                console.log(`File Upload Error: ${data}`)
                filesToUpload[ctr].link = undefined
                break
            }
        }
        if (ctr === getLargestFileUploadIndex()) {
            dispatch({type: QUESTIONS_ACTION_TYPE.SET_FILE_UPLOAD_COMPLETE, payload: true})
            dispatch({type: FORM_DATA_ACTION_TYPE.SET_LOADING, payload: false})
        }
    }

    function setFormTheme() {
        const isCustomTheme = props.formData.theme?.startsWith("http");
        if (isCustomTheme) {
            formThemeImageSrc = props.formData.theme + "?nocache";
        } else {
            if (isMobileOnly || props.previewType === PREVIEW_TYPE.PHONE) {
                formThemeImageSrc = AWS_MOBILE_WEB_PATH + `/${props.formData.theme}`;
            } else {
                formThemeImageSrc = AWS_PC_WEB_PATH + `/${props.formData.theme}`;
            }
        }
        setFormThemeColors(isCustomTheme);
    }

    function getCurrentScreenComponent() {
        switch (formScreen) {
            case FORM_SCREEN.WELCOME_PAGE:
                dispatch({type: FORM_DATA_ACTION_TYPE.SET_LOADING, payload: false})
                return <WelcomePageContainer setSelectedPreviewScreen={props.setSelectedPreviewScreen}
                                             previewType={props.previewType}/>
            case FORM_SCREEN.FORM_PAGE:
                return <FormPage {...props}
                                 previewType={props.previewType}
                                 snackBarRef={snackBarRef}/>
            case FORM_SCREEN.THANK_PAGE:
                dispatch({type: FORM_DATA_ACTION_TYPE.SET_LOADING, payload: false})
                return <ThankPageContainer setSelectedPreviewScreen={props.setSelectedPreviewScreen}
                                           previewType={props.previewType}/>
            case FORM_SCREEN.QUIZ_USER_INFO:
                dispatch({type: FORM_DATA_ACTION_TYPE.SET_LOADING, payload: false})
                return <QuizUserInfoPopup setSelectedPreviewScreen={props.setSelectedPreviewScreen}
                                          previewType={props.previewType}/>
            case FORM_SCREEN.FORM_USER_INFO:
                dispatch({type: FORM_DATA_ACTION_TYPE.SET_LOADING, payload: false})
                return <FormUserInfoPopup setSelectedPreviewScreen={props.setSelectedPreviewScreen}
                                          previewType={props.previewType}/>
            case FORM_SCREEN.ABUSE_WARNING:
                dispatch({type: FORM_DATA_ACTION_TYPE.SET_LOADING, payload: false})
                return <AbuseWarningPopup setSelectedPreviewScreen={props.setSelectedPreviewScreen}
                                          previewType={props.previewType}/>
            case FORM_SCREEN.FORM_AVAILABLE_TIMER:
                dispatch({type: FORM_DATA_ACTION_TYPE.SET_LOADING, payload: false})
                return <ForqTimer setSelectedPreviewScreen={props.setSelectedPreviewScreen}
                                  isQuiz={props.formData.is_quiz}
                                  previewType={props.previewType}/>
            case FORM_SCREEN.COLLECT_EMAIL_PAGE:
                dispatch({type: FORM_DATA_ACTION_TYPE.SET_LOADING, payload: false})
                return <CollectEmailPage/>
            case FORM_SCREEN.PASSCODE_PAGE:
                dispatch({type: FORM_DATA_ACTION_TYPE.SET_LOADING, payload: false})
                return <PassCodePage previewType={props.previewType}/>
            case FORM_SCREEN.FORM_EDIT_RESPONSE:
                dispatch({type: FORM_DATA_ACTION_TYPE.SET_LOADING, payload: false})
                return <EditResponsePage/>
            case FORM_SCREEN.RESPONSE_HISTORY:
                dispatch({type: FORM_DATA_ACTION_TYPE.SET_LOADING, payload: false})
                return <ResponseHistoryPage/>
        }
    }

    function setFormThemeColors() {
        if (isImageTheme(props.formData.theme)) {
            Vibrant.from(formThemeImageSrc).getPalette(function (error, palette) {
                if (error) {
                    props.formData.theme = 'classic_theme'
                    setFormThemeColors()
                } else if (palette
                    && palette.Vibrant
                    && palette.Vibrant.getHex()
                    && palette.DarkVibrant
                    && palette.DarkVibrant.getHex()) {
                    dispatch({
                        type: THEME_ACTION_TYPE.SET_THEME_COLOR, payload: {
                            primaryColor: palette.Vibrant.getHex(),
                            secondaryColor: palette.DarkVibrant.getHex()
                        }
                    })
                }
            });
        } else {
            dispatch({
                type: THEME_ACTION_TYPE.SET_THEME_COLOR, payload: {
                    primaryColor: props.formData.theme === THEME_TYPE.DARK ? '#212121' : '#000',
                    secondaryColor: props.formData.theme === THEME_TYPE.DARK ? '#000' : '#fff'
                }
            })
        }
    }

    let currentScreenComponent;

    if (!currentScreenComponent) {
        currentScreenComponent = getCurrentScreenComponent();
    }

    useEffect(() => {
        setFormTheme();
    }, [props.formData.theme])

    useEffect(() => {
        setFormThemeColors()
    }, [formThemeImageSrc])

    if (themeColor?.primaryColor
        && themeColor?.secondaryColor) {
        return (
            <>
                <div style={{
                    backgroundColor: props.formData.theme === THEME_TYPE.DARK && !fillerSummary ? '#000' : props.formData.theme === THEME_TYPE.CLASSIC ? '#fff' : fillerSummary ? '#f5f5f5' : "transparent",
                    height: props.previewType === undefined ? '100vh' : '100%',
                    overflow: 'auto',
                    display: loading ? 'none' : null,
                }}>
                    <FormTheme
                        previewType={props.previewType}
                        formThemeImageSrc={formThemeImageSrc}
                    />
                    {currentScreenComponent}
                    <SnackBar snackBarRef={snackBarRef}/>
                </div>
                {loading ? <AnimationLoader previewType={props.previewType}/> : null}
            </>
        );
    } else return <AnimationLoader previewType={props.previewType}/>
}
