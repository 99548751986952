import React, {useState} from "react"
import styles from "../css/welcome-screen.module.css";
import {FORM_SCREEN, isMinWidth600} from "../utils/utils";
import {isMobileOnly} from "react-device-detect";
import {useDispatch, useSelector} from "react-redux";
import MaterialButton, {ButtonTypes} from "../../shared/components/MaterialButton";
import {SCREENS_ACTION_TYPE} from "../reducers/screensReducer";
import {FORM_DATA_ACTION_TYPE} from "../reducers/formDataReducer";
import {isDarkTheme, isImageTheme} from "../utils/theme-color-helper";
import ModalDialog from "../../shared/components/ModalDialog";
import peopleImage from "../../../public/images/people.svg";

const welcomeTitleContainerPCStyle = {
    "borderRadius": "10px",
    "maxHeight": "70vh",
    "overflow": "auto",
    "maxWidth": "85%",
    "minWidth": "300px",
    'textAlign': 'center'
}

const welcomeDescriptionContainerPCStyle = {
    'maxWidth': 'none',
    "maxHeight": "150px",
    "marginBottom": "15px",
    "fontSize": "22px",
    "overflow": "auto"
}

const EditResponsePage = () => {
    const [showChangePopUp, setShowChangePopUp] = useState(false)
    const formData = useSelector(state => state.formDataReducer.formData)
    const themeColor = useSelector(state => state.themeReducer.themeColor)
    const responderEmailId = useSelector(state => state.formLoginReducer.responderEmailId)
    const responderResponses = useSelector(state => state.formDataReducer.responderResponses)
    const dispatch = useDispatch()

    let textColor = '#fff';
    const buttonBackgroundColor = isImageTheme(formData?.theme) ? themeColor?.primaryColor
        : isDarkTheme(formData?.theme) ? '#000000' : '#1976D2';
    const handleChangeClick = () => {
        setShowChangePopUp(true)
    }

    const nextFormScreen = () => {
        if (formData.time_remaining) {
            dispatch({type: FORM_DATA_ACTION_TYPE.SET_SHOW_FORQ_TIMER, payload: true})
            return FORM_SCREEN.FORM_AVAILABLE_TIMER
        }
        if (formData.welcome_screen.show_screen) {
            return FORM_SCREEN.WELCOME_PAGE
        }
        if (formData.abuse_scan_result) {
            return FORM_SCREEN.ABUSE_WARNING
        } else if (formData.is_quiz) {
            return FORM_SCREEN.QUIZ_USER_INFO
        }
        if (!formData.is_quiz && formData?.user_info_form?.user_info_1) {
            return FORM_SCREEN.FORM_USER_INFO
        } else {
            return FORM_SCREEN.FORM_PAGE
        }
    }

    return (
        <div style={{
            top: '0',
            left: '0',
            margin: '0',
            padding: '0',
            zIndex: '11',
            width: '100%',
            height: '100%',
            display: 'flex',
            position: 'fixed',
            alignItems: 'center',
            flexDirection: 'column',
            justifyContent: 'center',
            backgroundColor: isImageTheme(formData?.theme) ? null : "#999999"
        }}>
            <div id={'welcome-title-container'} className={styles.welcome_title_container} style={{
                ...isMinWidth600() ? welcomeTitleContainerPCStyle : null,
                background: "none",
                maxWidth: isMobileOnly ? "90%" : "50%",
                padding: "0"
            }}>
                <h3 id={'welcome-screen-container-form-title'} style={isMinWidth600() ? {
                    fontSize: "50px",
                } : {wordWrap: "break-word"}}>
                    <b style={{color: textColor}}>{formData?.welcome_screen.title.length > (isMobileOnly ? 40 : 70) ? formData?.welcome_screen.title.substring(0, (isMobileOnly ? 40 : 70)) + '...' : formData?.welcome_screen.title}</b>
                </h3>
                {formData?.welcome_screen.description ?
                    <div className={styles.welcome_description_container}
                         style={isMinWidth600() ? welcomeDescriptionContainerPCStyle : null}>
                        <p id={'welcome-screen-container-form-description'} style={{
                            ...{color: textColor}
                        }}>{formData?.welcome_screen.description.length > (isMobileOnly ? 70 : 130) ? formData?.welcome_screen.description.substring(0, (isMobileOnly ? 70 : 130)) + '...' : formData?.welcome_screen.description}</p>
                    </div> : null}
                {formData?.setting.is_email ? <div style={{
                    display: "flex",
                    color: textColor,
                    alignItems: "center",
                    flexDirection: "column",
                    justifyContent: "center",
                    margin: isMobileOnly ? "5px 0" : null
                }}>
                    <span style={{fontWeight: "bold"}}>{responderEmailId}</span>
                    <MaterialButton handleClick={handleChangeClick}
                                    data={{
                                        title: "Change",
                                        buttonType: ButtonTypes.CONTAINED,
                                        bg_color: buttonBackgroundColor
                                    }}/>
                </div> : null}
            </div>
            <div style={{
                width: isMobileOnly ? "80%" : "30%",
                textAlign: "center",
                background: "#F5F5F5",
                padding: "30px",
                borderRadius: "10px"
            }}>
                {/*Added hard-coded svg image because we need dynamic svg color*/}
                <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 24 24">
                    <path d="M0 0h24v24H0z" fill="none"/>
                    <path
                        d="M19.35 10.04C18.67 6.59 15.64 4 12 4 9.11 4 6.6 5.64 5.35 8.04 2.34 8.36 0 10.91 0 14c0 3.31 2.69 6 6 6h13c2.76 0 5-2.24 5-5 0-2.64-2.05-4.78-4.65-4.96zM10 17l-3.5-3.5 1.41-1.41L10 14.17 15.18 9l1.41 1.41L10 17z"
                        fill={buttonBackgroundColor}/>
                </svg>
                <h1 style={{letterSpacing: "10px", color: "#8E8E8E", fontSize: "40px", margin: "0"}}>RESPONDED</h1>
                <p style={{marginTop: "0", fontSize: "25px"}}>You've already responded to this form</p>
                {formData.setting?.allow_multiple_submit ?
                    <MaterialButton data={{
                        title: "Add New Response",
                        textTransform: "uppercase",
                        fontSize: "12px",
                        bg_color: buttonBackgroundColor,
                        imageFileName: "icons/add-white-24dp.svg",
                        customButtonContainerStyle: {
                            margin: '5px',
                            padding: '0 24px',
                            fontSize: '12px',
                            textAlign: 'center',
                            width: '200px'
                        },
                        customButtonTextStyle: {
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            color: '#ffffff'
                        },
                        customImageStyle: {
                            width: '14px',
                            height: '14px'
                        }
                    }} handleClick={() => {
                        dispatch({type: SCREENS_ACTION_TYPE.SET_FORM_SCREEN, payload: nextFormScreen()})
                    }}/> : null}
                {responderResponses?.length ? <MaterialButton data={{
                    title: "Edit Response",
                    textTransform: "uppercase",
                    fontSize: "12px",
                    bg_color: buttonBackgroundColor,
                    imageFileName: "edit_white.svg",
                    customButtonContainerStyle: {
                        margin: '5px',
                        padding: '0 24px',
                        fontSize: '12px',
                        textAlign: 'center',
                        width: '200px'
                    },
                    customButtonTextStyle: {
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        color: '#ffffff'
                    },
                    customImageStyle: {
                        width: '14px',
                        height: '14px'
                    }
                }} handleClick={() => {
                    dispatch({
                        type: FORM_DATA_ACTION_TYPE.SET_FORM_RESPONSES,
                        payload: responderResponses[responderResponses.length - 1].responses
                    })
                    dispatch({
                        type: FORM_DATA_ACTION_TYPE.SET_EDIT_RESPONSE_ID,
                        payload: responderResponses[responderResponses.length - 1]._id
                    })
                    dispatch({type: SCREENS_ACTION_TYPE.SET_FORM_SCREEN, payload: FORM_SCREEN.FORM_PAGE})
                }}/> : null}
                {responderEmailId && responderResponses?.length ? <MaterialButton data={{
                    title: "Response History",
                    textTransform: "uppercase",
                    fontSize: "12px",
                    bg_color: buttonBackgroundColor,
                    imageFileName: "history.svg",
                    customButtonContainerStyle: {
                        margin: '5px',
                        padding: '0 24px',
                        fontSize: '12px',
                        textAlign: 'center',
                        width: '200px'
                    },
                    customButtonTextStyle: {
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        color: '#ffffff'
                    },
                    customImageStyle: {
                        width: '14px',
                        height: '14px'
                    }
                }} handleClick={() => {
                    dispatch({type: SCREENS_ACTION_TYPE.SET_FORM_SCREEN, payload: FORM_SCREEN.RESPONSE_HISTORY})
                }}/> : null}
            </div>
            {showChangePopUp ? <ModalDialog header={'Switch Account'}
                                            imageIcon={peopleImage}
                                            color={buttonBackgroundColor}
                                            body={["Do you want to change the account?", "Make sure your data will be erased and you will be start from beginning"]}
                                            buttons={[
                                                {
                                                    text: 'CANCEL',
                                                    buttonTextColor: buttonBackgroundColor,
                                                    action: () => {
                                                        setShowChangePopUp(false)
                                                    }
                                                },
                                                {
                                                    text: "SIGN OUT",
                                                    buttonTextColor: buttonBackgroundColor,
                                                    action: () => {
                                                        window?.localStorage?.removeItem("responder_token")
                                                        window.location.reload()
                                                    }
                                                }
                                            ]}/> : null}
        </div>
    )
}

export default EditResponsePage
