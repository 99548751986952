import React, {useState} from 'react'
import MaterialCheckBox from "../../../shared/components/MaterialCheckBox";
import {useDispatch, useSelector} from "react-redux";
import styles from "../../../form/css/text-question.module.css";
import {FORM_SETTINGS} from "./FormBuilderContainer";
import {BUILDER_ACTION_TYPE} from "../../reducers/builderReducer";
import {useTranslation} from "react-i18next";
import {translationKeys} from "../../../localizations/translationKeys-localization";

const SettingScheduleForqStatus = props => {
    const {t} = useTranslation()
    const formData = useSelector(state => state.appReducer.formData)
    const [showAutoOpenTime, setShowAutoOpenTime] = useState(formData?.setting?.auto_open_time && !isNaN(formData?.setting?.auto_open_time))
    const [showAutoCloseTime, setShowAutoCloseTime] = useState(formData?.setting?.auto_close_time && !isNaN(formData?.setting?.auto_close_time))
    const dispatch = useDispatch()

    const handleAutoOpenTimeChange = (value, type) => {
        let autoOpenTime
        const date = formattedDate(formData?.setting?.auto_open_time ? formData?.setting?.auto_open_time : new Date().getTime())
        const time = formattedTime(formData?.setting?.auto_open_time ? formData?.setting?.auto_open_time : new Date().getTime())
        if (type === "date") {
            autoOpenTime = value + " " + time
        } else {
            autoOpenTime = date + " " + value
        }
        const currentDate = formattedDate(new Date().getTime())
        const currentTime = formattedTime(new Date().getTime())
        if (new Date(autoOpenTime).getTime() < new Date(currentDate + " " + currentTime).getTime() ||
            (formData?.setting?.auto_close_time && new Date(autoOpenTime).getTime() > new Date(parseInt(formData?.setting?.auto_close_time)).getTime())) {
            dispatch({
                type: BUILDER_ACTION_TYPE.SET_BUILDER_MESSAGE,
                payload: "Auto open time should be less than auto close time and greater than current time."
            })
            dispatch({type: BUILDER_ACTION_TYPE.SET_BUILDER_TOAST_MESSAGE, payload: true})
        } else {
            props.updateSetting(FORM_SETTINGS.AUTO_OPEN_TIME, (new Date(autoOpenTime).getTime()).toString())
        }
    }

    const handleAutoCloseTimeChange = (value, type) => {
        let autoCloseTime
        const date = formattedDate(formData?.setting?.auto_close_time ? formData?.setting?.auto_close_time : new Date().getTime())
        const time = formattedTime(formData?.setting?.auto_close_time ? formData?.setting?.auto_close_time : new Date().getTime())
        if (type === "date") {
            autoCloseTime = value + " " + time
        } else {
            autoCloseTime = date + " " + value
        }
        if (new Date(autoCloseTime).getTime() < new Date().getTime() ||
            (formData?.setting?.auto_open_time && new Date(autoCloseTime).getTime() < new Date(parseInt(formData?.setting?.auto_open_time)).getTime())) {
            dispatch({
                type: BUILDER_ACTION_TYPE.SET_BUILDER_MESSAGE,
                payload: "Auto close time should be greater than current time and auto open time."
            })
            dispatch({type: BUILDER_ACTION_TYPE.SET_BUILDER_TOAST_MESSAGE, payload: true})
        } else {
            props.updateSetting(FORM_SETTINGS.AUTO_CLOSE_TIME, (new Date(autoCloseTime).getTime()).toString())
        }
    }

    const formattedDate = (millis) => {
        let date = millis && !isNaN(millis) ? new Date(parseInt(millis)) : new Date()
        return (date.getFullYear() + '-' + (date.getMonth() < 9 ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1)) + '-' + (date.getDate() < 10 ? '0' + (date.getDate()) : date.getDate()))
    }

    const formattedTime = (millis) => {
        let time = millis && !isNaN(millis) ? new Date(parseInt(millis)) : new Date()
        const hours = new Date(time).getHours() > 9 ? new Date(time).getHours() : "0" + new Date(time).getHours()
        const minutes = new Date(time).getMinutes() > 9 ? new Date(time).getMinutes() : "0" + new Date(time).getMinutes()
        return (hours + ":" + minutes)
    }

    return (
        <React.Fragment>
            <div id={'settings-responses-and-questions-wrapper'}
                 className="settings-responses-and-questions-wrapper">
                {/* Row 1 */}
                <div id={'settings-responses-and-questions-box1'} className="settings-responses-and-questions-box1">
                    <MaterialCheckBox isChecked={showAutoOpenTime}
                                      removeWidth={true}
                                      onChange={() => {
                                          if (showAutoOpenTime) {
                                              props.updateSetting(FORM_SETTINGS.AUTO_OPEN_TIME, "delete")
                                          } else {
                                              handleAutoOpenTimeChange(formattedDate(), "date")
                                              handleAutoOpenTimeChange(formattedTime(), "time")
                                          }
                                          setShowAutoOpenTime(!showAutoOpenTime)
                                      }}
                    />
                </div>
                <div id={'settings-show-question-number'} className="settings-responses-and-questions-box2">
                    <img src="../../images/schedule_opening.svg" alt="schedule opening Icon"/>
                    <div className="settings-bold-text">{t(translationKeys.schedule_opening_date_and_time)}</div>
                </div>
                {/* Row 2 */}
                <div id={'settings-show-question-number-form'} className="settings-responses-and-questions-box4">
                    <span className='settings-normal-text'>{formData?.is_quiz ? t(translationKeys.schedule_opening_date_and_time_description_quiz) : t(translationKeys.schedule_opening_date_and_time_description)}</span>
                </div>
                {showAutoOpenTime ?
                    <div className="settings-responses-and-questions-box6">
                        <input type={"date"}
                               className={styles.answer_text_field_input}
                               min={formattedDate(new Date().getTime())}
                               max={formData?.setting?.auto_close_time ? formattedDate(formData?.setting?.auto_close_time) : null}
                               defaultValue={formattedDate(formData?.setting?.auto_open_time)}
                               onChange={(e) => handleAutoOpenTimeChange(e.target.value, "date")}
                               style={{
                                   fontSize: "16px",
                                   fontFamily: "Nunito Sans, Sans-serif, serif",
                               }}
                        />
                        <span style={{margin: "10px"}}>at</span>
                        <input type={"time"}
                               className={styles.answer_text_field_input}
                               defaultValue={formattedTime(formData?.setting?.auto_open_time)}
                               onChange={(e) => handleAutoOpenTimeChange(e.target.value, "time")}
                               style={{
                                   fontSize: "16px",
                                   fontFamily: "Nunito Sans, Sans-serif, serif",
                               }}
                        />
                    </div> : null}
                {/* Row 3 */}
                <div className="settings-responses-and-questions-box9">
                    <MaterialCheckBox isChecked={showAutoCloseTime}
                                      removeWidth={true}
                                      onChange={() => {
                                          if (showAutoCloseTime) {
                                              props.updateSetting(FORM_SETTINGS.AUTO_CLOSE_TIME, "delete")
                                          } else {
                                              const date = formData?.setting?.auto_open_time ? new Date(formattedDate(formData?.setting?.auto_open_time)) : new Date();
                                              const tomorrow_date = date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + (date.getDate() + 1);
                                              handleAutoCloseTimeChange(tomorrow_date, "date")
                                              handleAutoCloseTimeChange(formattedTime(), "time")
                                          }
                                          setShowAutoCloseTime(!showAutoCloseTime)
                                      }}
                    />
                </div>
                <div className="settings-responses-and-questions-box10">
                    <img src="../../images/schedule_closing.svg" alt="schedule closing Icon"/>
                    <div className="settings-bold-text">{t(translationKeys.schedule_closing_date_and_time)}</div>
                </div>
                {/* Row 4 */}
                <div className="settings-responses-and-questions-box12">
                    <span className='settings-normal-text'>{formData?.is_quiz ? t(translationKeys.schedule_closing_date_and_time_description_quiz) : t(translationKeys.schedule_closing_date_and_time_description)}</span>
                </div>
                {showAutoCloseTime ?
                    <div className="settings-view-box14">
                        <input type={"date"}
                               className={styles.answer_text_field_input}
                               min={formattedDate(formData?.setting?.auto_open_time)}
                               defaultValue={formattedDate(formData?.setting?.auto_close_time)}
                               onChange={(e) => handleAutoCloseTimeChange(e.target.value, "date")}
                               style={{
                                   fontSize: "16px",
                                   fontFamily: "Nunito Sans, Sans-serif, serif",
                               }}
                        />
                        <span style={{margin: "10px"}}>at</span>
                        <input type={"time"}
                               className={styles.answer_text_field_input}
                               defaultValue={formattedTime(formData?.setting?.auto_close_time)}
                               onChange={(e) => handleAutoCloseTimeChange(e.target.value, "time")}
                               style={{
                                   fontSize: "16px",
                                   fontFamily: "Nunito Sans, Sans-serif, serif",
                               }}
                        />
                    </div> : null}
            </div>
        </React.Fragment>

    )
}
export default SettingScheduleForqStatus