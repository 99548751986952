import React, {useEffect, useState} from 'react'
import Loader from "../../../shared/components/Loader"
import AddImageCard from "./AddThemeCard"
import AwsThemeContainer from "./AwsThemeContainer"
import {setAwsUserJson} from "../utils/prepare-aws-user-json"
import UploadImageContainer from "./UploadImageContainer"
import PopUpContainer from "../../../shared/components/PopUpContainer"
import {AWS_FOLDER_NAMES, AWS_UPLOAD_TYPE, MEDIA_BASE_URL} from "../utils/aws"
import {getDataFromUrl} from "../../../shared/utils/sash-v2-api"
import {isMobileOnly} from "react-device-detect";
import {useDispatch, useSelector} from "react-redux";
import {BUILDER_ACTION_TYPE} from "../../reducers/builderReducer";
import {PremiumJSON} from "../../../shared/utils/helper";
import {PREMIUM_ACTION_TYPE} from "../../reducers/premiumReducer";

const PrepareAwsThemes = (props) => {

    const [isLoading, setIsLoading] = useState(true)
    const [openUploadPopUp, setOpenUploadPopUp] = useState(false)
    const [awsThemes, setAwsThemes] = useState(null)
    const subscriptionData = useSelector(state => state.userReducer?.subscriptionData)
    const maxThemeUploadLimit = PremiumJSON[subscriptionData?.subscription_plan]?.theme_attachments
    const userId = useSelector(state => state.userReducer?.userId)
    const dispatch = useDispatch()

    useEffect(() => {
            if (!awsThemes) {
                const successFunction = (res) => {
                    let responseJSON = res.data
                    if (responseJSON.theme_images.length) {
                        setAwsThemes(responseJSON.theme_images)
                    }
                    setIsLoading(false)
                    setAwsUserJson(responseJSON)
                }
                const failureFunction = (err) => {
                    console.error(err)
                    awsFetchFailed(err)
                }
                const requestUrl = `${MEDIA_BASE_URL}/${AWS_FOLDER_NAMES.USERS_JSON_FOLDER}/${userId}.json?id=${new Date().getTime()}`

                getDataFromUrl(requestUrl, successFunction, failureFunction)
            } else {
                setIsLoading(false)
            }
        dispatch({type: BUILDER_ACTION_TYPE.SET_QUESTION_IMAGES, payload: false})
    }, [])

    const updateThemesList = (newThemesList, addedTheme) => {
        if (awsThemes !== newThemesList) {
            setAwsThemes(newThemesList)
            setOpenUploadPopUp(false)
        } else {
            setAwsThemes(newThemesList)
            setOpenUploadPopUp(false)
        }
        props.updateChosenTheme(addedTheme)
    }

    const openUpload = (boolean) => {
        if (awsThemes?.length >= maxThemeUploadLimit && !openUploadPopUp) {
            dispatch({type: PREMIUM_ACTION_TYPE.SET_SHOW_UPGRADE_PLAN_POP_UP, payload: true})
        } else {
            setOpenUploadPopUp(boolean)
        }
    }

    const awsFetchFailed = (response) => {
        if (!response.ok && response.status) {
            if (response.status === 403) {
                setAwsThemes([])
            } else {
                console.error(response)
            }
        } else {
            setIsLoading(false)

            if (response.toString().includes('403')) {
                // The 'userID.json' file doesn't exist. It will be created automatically when an image is uploaded.
                openUpload(true)
            } else {
                console.error(response)
            }
        }
    }

        if (isLoading) {
            return (
                <Loader width={isMobileOnly ? '100%' : '60%'}/>
            )
        } else {
            if (awsThemes && awsThemes?.length > 0) {
                return (
                    <AwsThemeContainer openUploadPopUp={openUpload}
                                       doThemeLimitCheck={true}
                                       themeImages={awsThemes}
                                       maxLimit={maxThemeUploadLimit}
                                       chosenTheme={props.chosenTheme}
                                       openUploadState={openUploadPopUp}
                                       updateThemesList={updateThemesList}
                                       updateChosenTheme={props.updateChosenTheme}
                                       AWSUploadType={AWS_UPLOAD_TYPE.THEME}
                                       hideCheck={false}
                    />
                )
            } else {
                if (openUploadPopUp) {
                    return (
                        <div id={'aws-theme-container'} className='aws-theme-container'>
                            <AddImageCard openUploadPopUp={openUpload} maxLimit={maxThemeUploadLimit}/>
                            <PopUpContainer zIndex={11}>
                                <UploadImageContainer updateThemesList={updateThemesList}
                                                      openUploadPopUp={openUpload}
                                                      updateChosenTheme={props.updateChosenTheme}
                                                      uploadType={AWS_UPLOAD_TYPE.THEME}
                                />
                            </PopUpContainer>
                        </div>
                    )
                } else {
                    return (
                        <div className='aws-theme-container'>
                            <AddImageCard openUploadPopUp={openUpload} maxLimit={maxThemeUploadLimit}/>
                        </div>
                    )
                }
            }
        }

}

export default PrepareAwsThemes
