import {t} from "i18next"
import {translationKeys} from "../../../../localizations/translationKeys-localization"
import MaterialSwitch from "../../../../shared/components/MaterialSwitch"
import React from "react"
import * as PropTypes from "prop-types"
import {useSelector} from "react-redux"
import useLinearScaleMgr from "../../../hooks/useLinearScaleMgr"

export const LinearScaleStartFromZero = (props) => {
    const formData = useSelector(state => state.appReducer.formData)

    const {startFromZeroSwitchChanged} = useLinearScaleMgr()

    return (
        <div className={'linear-scale-row'}
             style={{marginLeft: '5px'}}>
            <span style={{marginRight: '15px'}}>{t(translationKeys.start_scale_from_zero)}</span>
            <MaterialSwitch
                index={0}
                initialCheckedState={parseInt(formData.pages?.[0]?.questions?.[props.index]?.choices?.[0]?.label) === 0}
                switchChanged={() => startFromZeroSwitchChanged(props.index)}
            />
        </div>
    )
}

LinearScaleStartFromZero.propTypes = {
    initialCheckedState: PropTypes.bool,
    switchChanged: PropTypes.func
}

