// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2Yu1LwNWh1nrBRjtG-iAnu {\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    position: relative;\n    height: 100%;\n    width: 100%;\n}\n\n._1Si11YE0Bq1BWTPWc-szC9 {\n    border-radius: 8px;\n    background-color: rgba(0, 0, 0, 0.3);\n    color: white;\n    max-height: 40vh;\n    max-width: 85%;\n    overflow: auto;\n    padding: 15px;\n    margin: 15px;\n    text-align: center;\n}\n\n._1Si11YE0Bq1BWTPWc-szC9 h3 {\n    font-size: 35px;\n    margin: 30px 0;\n    font-weight: 400;\n    line-height: 110%;\n    text-transform: capitalize;\n    width: 100%;\n    max-height: 50%;\n}\n\n.Pj8lCxMS5D-aCieqR4tGM {\n    /*max-width: 400px;*/\n    margin-bottom: 15px;\n    text-align: center;\n    font-size: 20px;\n    overflow: auto;\n    color: #eeeeee;\n    line-height: 1.5;\n}\n\n.Pj8lCxMS5D-aCieqR4tGM::-webkit-scrollbar-thumb {\n    background: #757575;\n}\n\n.Pj8lCxMS5D-aCieqR4tGM p {\n    text-align: center;\n    margin: 0;\n    width: 100%;\n}\n\n@media only screen and (max-width: 600px) {\n    ._1Si11YE0Bq1BWTPWc-szC9 {\n        display: flex;\n        overflow: hidden;\n        flex-flow: column;\n    }\n\n    ._1Si11YE0Bq1BWTPWc-szC9::-webkit-scrollbar {\n        visibility: hidden;\n    }\n\n    ._1Si11YE0Bq1BWTPWc-szC9::-webkit-scrollbar-track {\n        background: transparent;\n    }\n\n    .Pj8lCxMS5D-aCieqR4tGM {\n        margin-bottom: 30px;\n    }\n\n    ._1Si11YE0Bq1BWTPWc-szC9 h3 {\n        margin: 5px 0;\n        font-size: 30px;\n    }\n}\n", ""]);
// Exports
exports.locals = {
	"welcome_screen_container": "_2Yu1LwNWh1nrBRjtG-iAnu",
	"welcome_title_container": "_1Si11YE0Bq1BWTPWc-szC9",
	"welcome_description_container": "Pj8lCxMS5D-aCieqR4tGM"
};
module.exports = exports;
