import React, {useEffect, useState} from 'react';
import MaterialSwitch from "../../../shared/components/MaterialSwitch";
import FormControl from "@mui/material/FormControl";
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import {useDispatch, useSelector} from "react-redux";
import useQuestionMgr from "../../hooks/useQuestionMgr";
import continueButton from "../../../../public/images/icons/builder/undo_button.svg";
import submitButton from "../../../../public/images/icons/builder/submit_gray_tick_mark.svg";
import useLogicJumpMgr from "../../hooks/useLogicJumpMgr";
import {BUILDER_ACTION_TYPE} from "../../reducers/builderReducer";
import {PremiumJSON} from "../../../shared/utils/helper";
import {PREMIUM_ACTION_TYPE} from "../../reducers/premiumReducer";
import {translationKeys} from "../../../localizations/translationKeys-localization";
import {useTranslation} from "react-i18next";

const TextQuestionBody = (props) => {
    const {t} = useTranslation()

    const formData = useSelector(state => state.appReducer.formData)
    const showShared = useSelector(state => state.appDashboardReducer.showShared)
    const subscriptionData = useSelector(state => state.userReducer?.subscriptionData)
    const showLogicJumpBasedOnUser = showShared ? PremiumJSON[subscriptionData?.subscription_plan]?.logic_jump && PremiumJSON[formData?.subscription_data?.current_subscription?.plan]?.logic_jump : PremiumJSON[subscriptionData?.subscription_plan]?.logic_jump
    const {questionRequiredChanged, questionOtherOptionDisabled} = useQuestionMgr()
    const {addLogicJumpToQuestion, selectTargetForTextQuestion} = useLogicJumpMgr()
    const dispatch = useDispatch()

    const [selectedValue, setSelectedValue] = useState('0');
    const [logicEnabled, setLogicEnabled] = useState(false);
    const handleJumpToQuestion = (event) => {
        setSelectedValue(event.target.value);
        selectTargetForTextQuestion(props.index, event.target.value)
    };

    const setLogic = (index, checkedStatus) => {
        questionRequiredChanged(index, checkedStatus)
        questionOtherOptionDisabled(index, checkedStatus)
        addLogicJumpToQuestion(index, checkedStatus)
        setLogicEnabled(checkedStatus)
        if (props.question.target_question_id === undefined) {
            selectTargetForTextQuestion(props.index, '0')
        }
    }

    useEffect(() => {
        setLogicEnabled(props.question?.is_logic_enabled);
    }, [props.question?.is_logic_enabled]);


    const switchClicked = () => {
        if (!showLogicJumpBasedOnUser) {
            if (showShared && !PremiumJSON[formData?.subscription_data?.current_subscription?.plan]?.logic_jump) {
                dispatch({type: PREMIUM_ACTION_TYPE.SET_SHOW_FREE_OWNER_POP_UP, payload: true})
            } else {
                dispatch({type: PREMIUM_ACTION_TYPE.SET_SHOW_UPGRADE_PLAN_POP_UP, payload: true})
            }
        } else if (formData.pages[0].questions.length <= 1 || formData.pages[0].questions.length - 1 === props.index) {
            dispatch({type: BUILDER_ACTION_TYPE.SET_BUILDER_MESSAGE, payload: 'Action cannot be performed'});
            dispatch({type: BUILDER_ACTION_TYPE.SET_BUILDER_TOAST_MESSAGE, payload: true})
        }
    }

    return (
        <div className={'text-body-add-logic-jump'}>
            <div id={'choice-body-add-logic-jump-option'} className="choice-body-add-logic-jump-option">
                {t(translationKeys.add_logic)}
            </div>
            <div onClick={switchClicked} style={{cursor: "pointer"}}>
                <MaterialSwitch
                    index={props.index}
                    initialCheckedState={props.question?.is_logic_enabled}
                    switchChanged={setLogic}
                    customStyle={(props.formData?.pages[0].questions.length <= 1 || props.formData?.pages[0].questions.length - 1 === props.index) ? {cursor: 'not-allowed'} : null}
                    isSwitchDisabled={formData.pages[0].questions.length <= 1 || formData.pages[0].questions.length - 1 === props.index || !showLogicJumpBasedOnUser}
                />
            </div>
            {logicEnabled && showLogicJumpBasedOnUser && <div className={'text-body-add-logic-dropdown-container'}>
                <FormControl variant="standard" sx={{m: 1, minWidth: 220, margin: 0}}>
                    <Select
                        label="Select an option"
                        value={props.question.target_question_id || selectedValue}
                        onChange={handleJumpToQuestion}>
                        <MenuItem value="0"><img src={continueButton} alt={'continue to next question icon'}/> {t(translationKeys.continue_to_next_question)}</MenuItem>
                        <MenuItem value="-1"><img src={submitButton} alt={'submit form  icon'}/> {t(translationKeys.submit_form)}</MenuItem>
                    </Select>
                </FormControl>
            </div>}
        </div>
    )
}

export default TextQuestionBody