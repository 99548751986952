import React, {useEffect, useRef, useState} from "react"
import {isMobile, isMobileOnly} from "react-device-detect";
import {
    addQuestionToBuilder,
    isNewlyAddedQuestion,
    popSelectValues
} from "./helpers/BuilderContainerHelper";
import MaterialSwitch from "../../../shared/components/MaterialSwitch";
import {translationKeys} from "../../../localizations/translationKeys-localization";
import {useTranslation} from "react-i18next";
import PopUpContainer from "../../../shared/components/PopUpContainer";
import {AUTOCOMPLETE_ACTION_TYPE} from "../../reducers/autocompleteReducer";
import {useDispatch, useSelector} from "react-redux";
import {
    ATTACHMENT_TYPE, BUILDER_CREATION_SOURCE,
    getQuestionCategory, getQuestionTypeText, QUESTION_CATEGORY
} from "../../../shared/utils/constants";
import {MDCSelect} from "@material/select/index";
import {QUESTION_TYPE} from "../../../shared/utils/constants";
import QuestionAttachmentComponent from "../../../form/components/questions/QuestionAttachmentComponent";
import styles from "../../../form/css/question-attachment.module.css";
import MobileQuestionPopupContainer from "./MobileQuestionPopupContainer";
import useQuestionMgr from "../../hooks/useQuestionMgr";
import {BUILDER_ACTION_TYPE} from "../../reducers/builderReducer";
import {RESPONSES_ACTION_TYPE} from "../../reducers/responsesReducer";
import Message from "../../../shared/containers/ConfirmMessage";

const MobileQuestionContainer = (props) => {
    const {t} = useTranslation()
    const {
        addQuestion,
        setQuestionTitle,
        questionRequiredChanged,
        copyQuestion,
        deleteQuestion,
        removeAttachmentInfo
    } = useQuestionMgr()
    const currentTextBoxIndex = useSelector(state => state.autocompleteReducer?.currentTextBoxIndex)
    const builderCreationSource = useSelector(state => state.appReducer?.builderCreationSource)
    const selectedLanguage = useSelector(state => state.languageReducer?.selectedLanguage)
    const selectedValue = useSelector(state => state.autocompleteReducer?.selectedValue)
    const newQuestion = useSelector(state => state.builderReducer.newQuestion)
    const formData = useSelector(state => state.appReducer.formData)
    const subscriptionData = useSelector(state => state.userReducer?.subscriptionData)
    const showAcknowledgement = useSelector(state => state.responsesReducer.showAcknowledgement)
    const acknowledgementMessage = useSelector(state => state.responsesReducer.acknowledgmentMessage)

    const [attachmentType, setAttachmentType] = useState(null)
    const [showQuestion, setShowQuestion] = useState(false)
    const refQuestionCardDragIndicator = useRef(null)
    const refQuestionCardAddQuestion = useRef(null)
    const refQuestionCard = useRef(null)
    const refSelectMenu = useRef(null)
    const questionRef = useRef()
    const copyRef = useRef()
    const dispatch = useDispatch()

    const getCorrectAnswer = () => {
        let correctAnswer
        if (props.question.choices && props.question.choices.length > 0) {
            props.question.choices?.map((choice) => {
                if (choice.is_answer) {
                    correctAnswer = choice.label
                }
            })
        } else {
            correctAnswer = props.question?.correct_answer
        }
        return correctAnswer
    }

    let correctAnswer = getCorrectAnswer()

    const selectedAnswerText = isMobileOnly ? (correctAnswer?.length > 16) ? (correctAnswer?.slice(0, 15) + '...') : correctAnswer
        : (correctAnswer?.length > 50) ? (correctAnswer?.slice(0, 49) + '...') : correctAnswer;

    useEffect(() => {
        processAttachments()
    }, [props.question.attachment])

    useEffect(() => {
        if (builderCreationSource === BUILDER_CREATION_SOURCE.TEMPLATE) {
            if (props.refSavedData.current !== null || newQuestion) {
                setShowQuestion(true)
                handleOutsideClick()
            }
        } else if (newQuestion) {
            setShowQuestion(true)
            handleOutsideClick()
        }
    }, [])

    useEffect(() => {
        if (selectedValue !== '' && currentTextBoxIndex === props.index) {
            setQuestionTitle(props.index, selectedValue)
        }
    }, [selectedValue])

    const switchChanged = (index, checkedStatus) => {
        questionRequiredChanged(index, checkedStatus)
    }

    const handleOutsideClick = () => {
        document.onmousedown = (e) => {
            if (questionRef.current && !questionRef.current.contains(e.target)) {
                if(e.target.classList.contains('popup-container')) {
                    setShowQuestion(false)
                }
                props.addMissingOptionValuesToMCQs()
                if (props.question.tempAttachmentValue.trim().length > 0 && !props.question.attachment) {
                    props.showModalDialog(t(translationKeys.invalid_url), t(translationKeys.invalid_url_description))
                    removeAttachmentInfo(props.index)
                    props.question.tempAttachmentValue = ""
                }
                document.onclick = null;
            }
        }
        document.onclick = (e) => {
            props.refreshQTMenuDisplay(-1)
            document.onclick = null;
        }
        dispatch({type: AUTOCOMPLETE_ACTION_TYPE.SET_SELECTED_VALUE, payload: ''})
    }

    const AddFocusToQuestionCardUI = () => {
        // Question Card - Left Border
        if (!isMobileOnly) {
            refQuestionCard.current.classList.remove('builder-question-card-unfocused')
            refQuestionCard.current.classList.add('builder-question-card-focused')
        }

        // Add Question Button
        if (isMobile) {
            if (props.totalQuestionsCount && (props.totalQuestionsCount === props.index + 1)) {
                refQuestionCardAddQuestion.current.classList.add('remove-visibility')
                refQuestionCardAddQuestion.current.classList.remove('add-visibility')
            } else {
                refQuestionCardAddQuestion.current.classList.remove('remove-visibility')
                refQuestionCardAddQuestion.current.classList.add('add-visibility')
            }
        } else {
            refQuestionCardAddQuestion.current.classList.remove('remove-visibility')
            refQuestionCardAddQuestion.current.classList.add('add-visibility')
        }

        // Drag Indicator
        refQuestionCardDragIndicator.current.classList.remove('builder-question-card-hide')
        refQuestionCardDragIndicator.current.classList.add('builder-question-card-show-drag-indicator')
    }

    const RemoveFocusFromQuestionCardUI = () => {
        // Left Border
        refQuestionCard.current.classList.add('builder-question-card-unfocused')
        refQuestionCard.current.classList.remove('builder-question-card-focused')

        // Add Question Button
        refQuestionCardAddQuestion.current.classList.remove('add-visibility')
        refQuestionCardAddQuestion.current.classList.add('remove-visibility')

        // Drag Indicator
        refQuestionCardDragIndicator.current.classList.remove('builder-question-card-show-drag-indicator')
        refQuestionCardDragIndicator.current.classList.add('builder-question-card-hide')

        if (refSelectMenu.current.classList.contains('mdc-select--activated')) {
            // Close the Question Type drop down menu.
            new MDCSelect(refSelectMenu.current).selectedIndex = popSelectValues(props.question.type).findIndex(val => val.key === props.getQuestionType(props.index))
        }
    }

    const handleOutsideToQuestionCardClick = () => {
        document.onclick = e => {
            if (isMobile && ((refQuestionCard.current && !refQuestionCard.current.contains(e.target))
                || (e.target.getAttribute('data-attribute') === 'builder-question-card-copy-button'))) {
                RemoveFocusFromQuestionCardUI();
                props.refreshQTMenuDisplay(-1)
                document.onclick = null;
            }
        }
    }

    const processAttachments = () => {
        // Process Attachment Types
        if (props.question.attachment && props.question.attachment.file_type !== undefined) {
            switch (props.question.attachment.file_type) {
                case ATTACHMENT_TYPE.IMAGE.key:
                    setAttachmentType(ATTACHMENT_TYPE.IMAGE)
                    break
                case ATTACHMENT_TYPE.VIDEO.key:
                    setAttachmentType(ATTACHMENT_TYPE.VIDEO)
                    break
                case ATTACHMENT_TYPE.WEBSITE.key:
                    setAttachmentType(ATTACHMENT_TYPE.WEBSITE)
                    break
            }
        }
    }

    function getQuestionAttachmentWithImage() {
        return (
            <div>
                <div className="builder-question-line">
                    {!isMobileOnly ? <div style={{visibility: 'hidden'}}>{props.number}.</div> : null}
                    <div className='builder-question-text-input'>
                        <QuestionAttachmentComponent
                            key={ATTACHMENT_TYPE.IMAGE.key}
                            attachment={{
                                file_type: ATTACHMENT_TYPE.IMAGE.key,
                                image_url: props.question.attachment?.image_url
                            }}
                            imageHeight={'10vh'}
                            theme={"classic_theme"}
                            userId={formData.user_id}
                            backgroundColorProvided={false}
                        />
                    </div>
                    {!isMobileOnly ? <img src="images/icons/builder/attach_file.svg"
                                          alt={'attach'}
                                          style={{visibility: 'hidden'}}/> : null}
                </div>
            </div>
        )
    }

    const handleRequiredButtonClick = () => {
        if (props.question.is_logic_enabled) {
            copyRef.current = true;
            dispatch({type: RESPONSES_ACTION_TYPE.SET_SHOW_ACKNOWLEDGEMENT, payload: true})
            dispatch({
                type: RESPONSES_ACTION_TYPE.SET_ACKNOWLEDGEMENT_MESSAGE,
                payload: 'Action cannot be performed'
            })
        }
    }

    return (
        <>
            <div id={'question_card_' + props.index}
                 className={`mdc-card builder-question-card builder-question-card-unfocused`}
                 draggable={isMobile}
                 key={props.index}
                 ref={refQuestionCard}
                 style={{transform: 'translate3d(0, 0, 0)', padding: '15px', paddingBottom: '0'}}
                 onClick={() => {
                     if (!copyRef.current) {
                         setShowQuestion(!showQuestion)
                     } else {
                         copyRef.current = null
                     }
                     handleOutsideClick()
                 }}
                 onFocus={() => {
                     if (isMobile) AddFocusToQuestionCardUI()
                 }}
                 onBlur={() => {
                     if (isMobile) handleOutsideToQuestionCardClick()
                 }}

                 onMouseEnter={() => {
                     if (!isMobile) AddFocusToQuestionCardUI()
                 }}
                 onMouseLeave={() => {
                     if (!isMobile) RemoveFocusFromQuestionCardUI()
                 }}
            >
                <div id='drag-indicator' ref={refQuestionCardDragIndicator} onMouseDown={() => {
                    dispatch({type: AUTOCOMPLETE_ACTION_TYPE.SET_CURRENT_TEXT_BOX_INDEX, payload: -1})
                    props.refreshQTMenuDisplay(-1)
                }}>
                    <div className="builder-question-header" style={{borderBottom: "0.1px solid"}}>
                        <div id={"question-text" + props.number} className="builder-question-line"
                             style={{justifyContent: "flex-start"}}>
                            <div id={"question-number" + props.number}>{props.number}. {props.question.title}</div>
                        </div>
                    </div>
                    {props.question.title.length === 0 ?
                        <div style={{
                            fontSize: "10px",
                            textAlign: "left",
                            margin: "3px",
                            color: "#b00020"
                        }}>{t(translationKeys.title_required)}</div> : null}
                    {
                        (() => {
                            if ((attachmentType === ATTACHMENT_TYPE.WEBSITE || attachmentType === ATTACHMENT_TYPE.VIDEO) && props.question.attachment && props.question.attachment.file_type !== undefined) {
                                return (
                                    <div className="builder-question-line builder-question-left-align"
                                         style={{background: '#f0f0f0'}}>
                                        {!isMobileOnly ?
                                            <div style={{visibility: 'hidden'}}>{props.number}.</div> : null}
                                        <img style={{
                                            display: 'flex',
                                            width: '25%'
                                        }}
                                             alt={'image'}
                                             onError={e => e.target.src = '/images/link.svg'}
                                             src={props.question.attachment?.image_url ? props.question.attachment?.image_url : '/images/link.svg'}/>
                                        <div className='builder-question-text-input'
                                             style={{
                                                 display: 'block',
                                                 width: '75%'
                                             }}>
                                            <div className={styles.question_attachment_text_container}
                                                 style={{
                                                     display: (props.question.attachment?.file_type
                                                         === ATTACHMENT_TYPE.IMAGE.key) ? 'none' : 'flex',
                                                     width: '100%'
                                                 }}>
                                                <div className={styles.question_attachment_web_close} style={{
                                                    display: (props.onCloseClick && (props.question.attachment?.file_type
                                                        === ATTACHMENT_TYPE.WEBSITE.key)) ? 'flex' : 'none'
                                                }}
                                                >
                                                    <img src="images/icons/builder/close_attachment.svg"
                                                         style={{width: isMobileOnly ? '22px' : null}}
                                                         onClick={(e) => {
                                                             props.onCloseClick(e);
                                                             e.stopPropagation()
                                                         }} alt={'Close'}/>
                                                </div>
                                                <p className={styles.question_attachment_title}
                                                   style={{
                                                       color: '#212121'
                                                   }}>
                                                    {props.question.attachment?.title}
                                                </p>
                                                <p className={styles.question_attachment_description}>
                                                    {props.question.attachment?.description}
                                                </p>
                                                <p className={styles.question_attachment_url}
                                                   style={{
                                                       color: '#0000ee'
                                                   }}>
                                                    {props.question.attachment?.url}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                )
                            } else if (attachmentType === ATTACHMENT_TYPE.IMAGE && props.question.attachment?.image_url) {
                                return (
                                    <div style={{display: 'block'}}>
                                        {getQuestionAttachmentWithImage()}
                                    </div>
                                )
                            }
                        })()
                    }
                    {
                        (() => {
                            if (getQuestionCategory(props.question.type) === QUESTION_CATEGORY.CHOICE_TYPE) {
                                return (
                                    <div style={{
                                        marginTop: "10px",
                                        color: '#004BA0',
                                        fontSize: '14px',
                                        fontWeight: "bold",
                                        textAlign: 'start'
                                    }}>
                                        {props.question.type === QUESTION_TYPE.LINEAR_SCALE.key ? (`${t(translationKeys.show_scale)} (${props.question.choices.length})`) :
                                            (props.question.choices.length > 0 ? `${t(translationKeys.show_options)} (${props.question.choices.length})` : `${t(translationKeys.add) + ' ' + t(translationKeys.options)}`)}
                                    </div>)
                            }

                            if (getQuestionCategory(props.question.type) === QUESTION_CATEGORY.GRID_TYPE) {
                                return (
                                    <div style={{
                                        marginTop: "10px",
                                        color: '#004BA0',
                                        fontSize: '14px',
                                        fontWeight: "bold",
                                        textAlign: 'start'
                                    }}>
                                        {`${t(translationKeys.show_rows_and_columns)} (${props.question.rows?.length}x${props.question.columns?.length})`}
                                    </div>
                                )
                            }

                            return null
                        })()
                    }
                    {props.isQuiz ?
                        <div id={'quiz-choice-answer-marks'} className="quiz-choice-answer-marks-box1"
                             style={{margin: '10px 0'}}>
                            {(props.question.type !== QUESTION_TYPE.FILE_UPLOAD.key) ? <>
                                {t(translationKeys.enter_answer)} :
                                {
                                    (() => {
                                            if (!selectedAnswerText || selectedAnswerText?.length === 0)
                                                return <div
                                                    className="quiz-choice-answer-marks-box2 quiz-choice-answer-not-specified">
                                                    {t(translationKeys.not_specified)}
                                                </div>

                                            if (selectedAnswerText)
                                                return <div
                                                    className="quiz-choice-answer-marks-box2 quiz-choice-answer-specified">
                                                    {selectedAnswerText}
                                                </div>
                                        }
                                    )()
                                }
                            </> : null}
                            <div
                                style={selectedLanguage === "fa" ? {marginRight: 'auto'} : {marginLeft: 'auto'}}>{(props.question.marks ? props.question.marks : t(translationKeys.no)) + (props.question.marks > 1 ? ` ${t(translationKeys.marks)}` : ` ${t(translationKeys.mark)}`)}</div>
                        </div> : null}
                    <div className='builder-footer' key={props.index}>
                        <img alt='select' src={props.initialImage} style={{width: '15px'}}/>
                        <span style={{
                            margin: "auto 5px",
                            fontSize: "12px",
                            color: "#9E9E9E"
                        }}>{getQuestionTypeText(props.question.type)}</span>
                        <div className='builder-footer-right'>
                            <div className='builder-footer-required-switch' onClick={handleRequiredButtonClick}>
                                <MaterialSwitch
                                    index={props.index}
                                    initialCheckedState={props.question.is_required}
                                    switchChanged={switchChanged}
                                    isSwitchDisabled={props.question.is_logic_enabled}
                                />
                            </div>
                            <div
                                className='builder-footer-label builder-footer-label-required'>{t(translationKeys.required)}</div>
                            <div className='builder-footer-separator'>
                                |
                            </div>
                            <div className='builder-footer-group'
                                 style={{filter: props.formData?.is_quiz && props.formData?.response_count > 0 ? "opacity(0.5)" : null}}
                                 onClick={() => {
                                     if (formData?.is_quiz && formData?.response_count > 0) {
                                         copyRef.current = true;
                                         dispatch({type: BUILDER_ACTION_TYPE.SET_SHOW_QUIZ_ACTION_BLOCKED_POP_UP, payload: true})
                                     } else {
                                         deleteQuestion(props.index, isNewlyAddedQuestion(props.refSavedData?.current?.pages[0]?.questions, props.question?._id))
                                         dispatch({type: BUILDER_ACTION_TYPE.SET_NEW_QUESTION, payload: false})
                                     }
                                 }}
                            >
                                <div id={'builder-footer-label' + props.index} className='builder-footer-label'
                                     style={isMobile ? {display: 'none'} : null}>{t(translationKeys.delete)}</div>
                                <img className='builder-footer-image'
                                     style={{width: isMobile ? '12px' : null}}
                                     src="images/icons/builder/delete.svg" alt={"Delete"}
                                />
                            </div>
                            <div className='builder-footer-separator'>

                            </div>
                            <div className='builder-footer-group'
                                 style={{filter: props.formData?.is_quiz && props.formData?.response_count > 0 ? "opacity(0.5)" : null}}
                                 onClick={() => {
                                     document.activeElement.blur()
                                     // duplicating and adding the question
                                     if (formData?.is_quiz && formData?.response_count > 0) {
                                         copyRef.current = true;
                                         dispatch({
                                             type: BUILDER_ACTION_TYPE.SET_SHOW_QUIZ_ACTION_BLOCKED_POP_UP,
                                             payload: true
                                         })
                                     } else {
                                         dispatch({type: BUILDER_ACTION_TYPE.SET_NEW_QUESTION, payload: false})
                                         copyRef.current = true
                                         addQuestionToBuilder(props.question.type, props.index, addQuestion, copyQuestion, subscriptionData)
                                     }
                                 }}
                            >
                                <div id={'builder-footer-label' + props.index} className='builder-footer-label'
                                     style={isMobile ? {display: 'none'} : null}>{t(translationKeys.copy)}</div>
                                <img className='builder-footer-image' data-attribute='builder-question-card-copy-button'
                                     style={{width: isMobile ? '14px' : null}}
                                     src="images/icons/builder/copy.svg" alt={"Copy"}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {showQuestion ?
                <PopUpContainer children={<MobileQuestionPopupContainer {...props}
                                                                        attachmentType={attachmentType}
                                                                        setShowQuestion={setShowQuestion}
                                                                        setAttachmentType={setAttachmentType}
                                                                        questionRef={questionRef}/>}/> : null}
            {showAcknowledgement ? <Message width={'250px'} message={acknowledgementMessage}/> : null}
        </>
    )
}

export default MobileQuestionContainer