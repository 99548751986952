import {THEME_TYPE} from "./utils";

export const getTextFocusInBottomBorderColor = (theme, themeColor) => {
    if (theme === THEME_TYPE.DARK) {
        return "#fff";
    } else if (theme === THEME_TYPE.CLASSIC) {
        return "#000"
    } else {
        return themeColor?.secondaryColor;
    }
}

export const getTextFocusOutBottomBorderColor = (theme) => {
    return theme === THEME_TYPE.DARK ? "#9e9e9e"
        : theme === THEME_TYPE.CLASSIC ?
            "#bdbdbd" : "#e0e0e0";
}

export const getThemeTextColor = (theme) => {
    return theme === THEME_TYPE.DARK ? "#fff" : '#212121';
}

export const isImageTheme = (theme) => {
    return theme !== THEME_TYPE.CLASSIC &&
        theme !== THEME_TYPE.DARK
}

export const isDarkTheme = (theme) => {
    return theme === THEME_TYPE.DARK;
}

export const isClassicTheme = (theme) => {
    return theme === THEME_TYPE.CLASSIC;
}
