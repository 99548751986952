import {APP_ACTION_TYPE} from "../reducers/appReducer";
import {useDispatch, useSelector} from "react-redux";
import {
    ATTACHMENT_TYPE, BUILDER_CREATION_SOURCE,
    QUESTION_TYPE,
    QUIZ_QUESTION_TYPE
} from "../../shared/utils/constants";
import {translationKeys} from "../../localizations/translationKeys-localization";
import {maxNumberOfFileUploadOTsHasBeenReached} from "../builder/components/helpers/BuilderHelper";
import {registerGAEvent} from "../../shared/utils/analytics";
import {isMobile} from "react-device-detect";
import Form from "../builder/classes/Form";
import * as mongoObjectID from "bson-objectid";
import {BUILDER_ACTION_TYPE} from "../reducers/builderReducer";
import {showModalDialog} from "../builder/components/helpers/BuilderContainerHelper";
import {useTranslation} from "react-i18next";
import {PREMIUM_ACTION_TYPE} from "../reducers/premiumReducer";

const useQuestionMgr = () => {
    const {t} = useTranslation()
    const formData = useSelector(state => state.appReducer?.formData)
    const subscriptionData = useSelector(state => state.userReducer?.subscriptionData)
    const builderCreationSource = useSelector(state => state.appReducer?.builderCreationSource)
    const formUserInfoText = useSelector(state => state.builderReducer?.formUserInfoText)
    const isManuallyChangedFormUserInfo = useSelector(state => state.builderReducer?.isManuallyChangedFormUserInfo)
    const dispatch = useDispatch()

    const setQuestionTitle = (questionIndex, value) => {
        let updatedData = {...formData}

        updatedData.pages[0].questions[questionIndex].title = value

        dispatch({type: APP_ACTION_TYPE.SET_FORM_DATA, payload: updatedData})
    }

    const setQuestionDescription = (questionIndex, value) => {
        let updatedData = {...formData}

        updatedData.pages[0].questions[questionIndex].description = value

        dispatch({type: APP_ACTION_TYPE.SET_FORM_DATA, payload: updatedData})
    }

    const removeAttachmentInfo = (questionIndex) => {
        let updatedData = {...formData}

        delete updatedData.pages[0].questions[questionIndex]['attachment']
        dispatch({type: APP_ACTION_TYPE.SET_FORM_DATA, payload: updatedData})
    }

    const addAttachmentInfo = (questionIndex, fileType, URL, title, desc, imageURL) => {
        let updatedData = {...formData}
        let updatedQs = [...updatedData.pages[0].questions]

        updatedQs[questionIndex]['attachment'] = {}
        updatedQs[questionIndex]['attachment']['file_type'] = fileType.key

        if (fileType === ATTACHMENT_TYPE.IMAGE) {
            updatedQs[questionIndex]['attachment']['image_url'] = URL
        } else if (fileType === ATTACHMENT_TYPE.VIDEO || fileType === ATTACHMENT_TYPE.WEBSITE) {
            updatedQs[questionIndex]['attachment']['image_url'] = imageURL
            updatedQs[questionIndex]['attachment']['title'] = title
            updatedQs[questionIndex]['attachment']['description'] = desc
            updatedQs[questionIndex]['attachment']['url'] = URL
        }
        dispatch({type: APP_ACTION_TYPE.SET_FORM_DATA, payload: updatedData})
    }

    const setQuestionAttachment = (questionIndex, value) => {
        let updatedData = {...formData}

        updatedData.pages[0].questions[questionIndex].tempAttachmentValue = value
        dispatch({type: APP_ACTION_TYPE.SET_FORM_DATA, payload: updatedData})
    }

    const questionRequiredChanged = (questionIndex, checked) => {
        const updatedData = {...formData}

        updatedData.pages[0].questions[questionIndex].is_required = checked

        dispatch({type: APP_ACTION_TYPE.SET_FORM_DATA, payload: updatedData})
    }


    const questionOtherOptionChanged = (questionIndex) => {
        const updatedData = {...formData}

        updatedData.pages[0].questions[questionIndex].is_others_allowed = false

        dispatch({type: APP_ACTION_TYPE.SET_FORM_DATA, payload: updatedData})
    }

    const questionOtherOptionDisabled = (questionIndex, checked) => {
        const updatedData = {...formData}

        updatedData.pages[0].questions[questionIndex].is_others_disabled = checked

        dispatch({type: APP_ACTION_TYPE.SET_FORM_DATA, payload: updatedData})
    }

    const setQuestionType = (questionIndex, questionType) => {
        let truncatedShortTextAnswer = null

        if (formData.is_quiz) {
            // If existing QT is long answer, and the new QT is short answer, the correct answer needs to be truncated to fit the max length of a short answer question.
            if (formData.pages[0].questions[questionIndex].type === QUIZ_QUESTION_TYPE.LONG_TEXT.key) {
                if (questionType === QUIZ_QUESTION_TYPE.SHORT_TEXT.key) {
                    truncatedShortTextAnswer = formData.pages[0].questions[questionIndex].correct_answer.substring(0, 100)
                }
            }
        }
        const updatedData = {...formData}

        updatedData.pages[0].questions[questionIndex].type = questionType

        if (QUESTION_TYPE[questionType] === QUESTION_TYPE.SHORT_TEXT) {
            updatedData.pages[0].questions[questionIndex].max_value = 100
        } else if (QUESTION_TYPE[questionType] === QUESTION_TYPE.LONG_TEXT) {
            updatedData.pages[0].questions[questionIndex].max_value = 10000
        } else if (QUESTION_TYPE[questionType] === QUESTION_TYPE.STAR_RATING_SCALE) {
            // A switch is being made from a LINEAR_SCALE to STAR_RATING_SCALE (these 2 are in the same group). Reset 'Choices' to the default values for a STAR_RATING_SCALE.
            updatedData.pages[0].questions[questionIndex].choices = [
                {
                    label: t(translationKeys.worst)
                },
                {
                    label: t(translationKeys.not_good)
                },
                {
                    label: t(translationKeys.neutral)
                },
                {
                    label: t(translationKeys.good)
                },
                {
                    label: t(translationKeys.very_good)
                }
            ]
        } else if (QUESTION_TYPE[questionType] === QUESTION_TYPE.LINEAR_SCALE) {
            // A switch is being made from a STAR_RATING_SCALE to LINEAR_SCALE (these 2 are in the same group). Reset 'Choices' to the default values for a LINEAR_SCALE.
            updatedData.pages[0].questions[questionIndex].choices = [
                {
                    label: 1
                },
                {
                    label: 2
                },
                {
                    label: 3
                },
                {
                    label: 4
                },
                {
                    label: 5
                },
                {
                    label: 6
                },
                {
                    label: 7
                },
                {
                    label: 8
                },
                {
                    label: 9
                },
                {
                    label: 10
                }
            ]
        }

        if (truncatedShortTextAnswer) {
            updatedData.pages[0].questions[questionIndex].correct_answer = truncatedShortTextAnswer
        }

        dispatch({type: APP_ACTION_TYPE.SET_FORM_DATA, payload: updatedData})
    }

    const copyQuestion = (currentIndex) => {
        // Remove any existing ID from the copied question and adding new tempId.
        const {_id, ...newQ} = JSON.parse(JSON.stringify(formData.pages[0].questions[currentIndex]))
        if (newQ) {
            newQ._id = mongoObjectID().toString() + "tempId"
        }
        const updatedData = {...formData}

        updatedData.pages[0].questions.splice((currentIndex + 1), 0, newQ)
        updatedData.pages[0].questions[currentIndex + 1].setFocus = true
        updatedData.pages[0].questions[currentIndex + 1].highlightText = true
        updatedData.pages[0].questions[currentIndex + 1].is_logic_enabled = false
        delete updatedData.pages[0].questions[currentIndex + 1].logic_question_id

        dispatch({type: APP_ACTION_TYPE.SET_FORM_DATA, payload: updatedData})
    }

    const addQuestion = (questionIndex, questionType) => {
        registerGAEvent('Builder', 'Question', `clicked-${questionType}`);
        let updatedData = {...formData}

        //if is_logic_enabled is undefined then add default false value
        if (updatedData.is_logic_enabled === undefined) {
            updatedData.is_logic_enabled = false;
        }

        if (questionType === QUESTION_TYPE.FILE_UPLOAD.key) {
            // File Upload QT is being added
            if (!isManuallyChangedFormUserInfo) {
                dispatch({type: BUILDER_ACTION_TYPE.SET_FORM_USER_INFO_CHECK, payload: true})
                updatedData.user_info_form = {
                    user_info_1: formUserInfoText?.trim()?.length ? formUserInfoText.trim() : t(translationKeys.name_s)
                }
                dispatch({type: APP_ACTION_TYPE.SET_FORM_DATA, payload: updatedData})
            }
            if (maxNumberOfFileUploadOTsHasBeenReached(formData.pages[0].questions, subscriptionData)) {
                if (isMobile) {
                    dispatch({type: PREMIUM_ACTION_TYPE.SET_SHOW_UPGRADE_PLAN_POP_UP, payload: true})
                } else {
                    dispatch({type: PREMIUM_ACTION_TYPE.SET_SHOW_UPGRADE_PLAN_POP_UP, payload: true})
                }
                return
            }
        }

        let newQuestion = Form.addQuestion(questionType)

        if (!newQuestion._id) {
// Make sure that a temp '_id' is assigned for the new Question.
            newQuestion._id = mongoObjectID().toString() + "tempId"
        }

        newQuestion.setFocus = true

        if (formData.is_quiz) {
            if (questionType === QUIZ_QUESTION_TYPE.LONG_TEXT.key || questionType === QUIZ_QUESTION_TYPE.SHORT_TEXT.key) {
                newQuestion.correct_answer = ''
            }

            if (questionType !== QUIZ_QUESTION_TYPE.SECTION.key) {
                newQuestion.marks = 1
            }
        }

        if ((questionIndex + 1) === formData.pages[0].questions.length) {
            // Add new Question to the bottom of the list
            updatedData.pages[0].questions = [...updatedData.pages[0].questions, newQuestion]
            dispatch({type: APP_ACTION_TYPE.SET_FORM_DATA, payload: updatedData})

        } else {
            // Insert Question
            let updatedQs = [...updatedData.pages[0].questions]

            updatedQs.splice(questionIndex + 1, 0, newQuestion)

            updatedData.pages[0].questions = updatedQs
            dispatch({type: APP_ACTION_TYPE.SET_FORM_DATA, payload: updatedData})

            if (formData.is_quiz) {
                if (questionType === QUIZ_QUESTION_TYPE.SECTION.key) {
                    dispatch({type: BUILDER_ACTION_TYPE.SET_SECTION_TITLE_TO_FOCUS, payload: questionIndex + 1})
                }
            } else {
                if (questionType === QUESTION_TYPE.SECTION.key) {
                    dispatch({type: BUILDER_ACTION_TYPE.SET_SECTION_TITLE_TO_FOCUS, payload: questionIndex + 1})
                }
            }
        }
    }

    const deleteQuestion = (questionIndex, isNewQuestion) => {
        // For a Section, 'isNewQuestion' will be undefined.
        // Quiz Questions can't be deleted.
        if (!formData.is_quiz && builderCreationSource.current === BUILDER_CREATION_SOURCE.SAVED && formData.response_count > 0 && (isNewQuestion !== undefined && !isNewQuestion)) {
            // Only Submitted Forms that have responses can be edited. If a Quiz has Responses (Answers), it can not be edited, so the logic below will not apply.
            const title = t(translationKeys.delete_question)
            const body = [t(translationKeys.delete_response_collected_question_alert)]
            const buttonArray = [
                {
                    text: t(translationKeys.cancel),
                    action:
                        () => {
                            // Do Nothing
                            dispatch({type: BUILDER_ACTION_TYPE.SET_SHOW_MODAL_DIALOG, payload: false})
                        }
                },
                {
                    text: t(translationKeys.yes),
                    action:
                        () => {
                            const updatedData = {...formData}

                            updatedData.pages[0].questions.splice(questionIndex, 1)

                            const fileUploadQuestions = updatedData.pages[0].questions.filter((q) => q.type === QUESTION_TYPE.FILE_UPLOAD.key)
                            if (fileUploadQuestions?.length === 0 && !isManuallyChangedFormUserInfo) {
                                dispatch({type: BUILDER_ACTION_TYPE.SET_FORM_USER_INFO_CHECK, payload: false})
                                updatedData.user_info_form = null
                            }
                            if (questionIndex === updatedData.pages[0].questions.length) {
                                if (updatedData.pages[0].questions.length > 0) {
                                    updatedData.pages[0].questions[questionIndex - 1].setFocus = true
                                }
                            } else {
                                updatedData.pages[0].questions[questionIndex].setFocus = true
                            }
                            dispatch({type: APP_ACTION_TYPE.SET_FORM_DATA, payload: updatedData})

                            dispatch({type: BUILDER_ACTION_TYPE.SET_SHOW_MODAL_DIALOG, payload: false})
                        }
                }
            ]
            showModalDialog(title, body, buttonArray)

            dispatch({type: BUILDER_ACTION_TYPE.SET_SHOW_MODAL_DIALOG, payload: true})
        } else {
            const updatedData = {...formData}
            updatedData.pages[0].questions.splice(questionIndex, 1)

            const fileUploadQuestions = updatedData.pages[0].questions.filter((q) => q.type === QUESTION_TYPE.FILE_UPLOAD.key)
            if (fileUploadQuestions?.length === 0 && !isManuallyChangedFormUserInfo) {
                dispatch({type: BUILDER_ACTION_TYPE.SET_FORM_USER_INFO_CHECK, payload: false})
                updatedData.user_info_form = null
            }
            if (questionIndex === updatedData.pages[0].questions.length) {
                if (updatedData.pages[0].questions.length > 0) {
                    updatedData.pages[0].questions[questionIndex - 1].setFocus = true
                }
            } else {
                updatedData.pages[0].questions[questionIndex].setFocus = true
            }
            dispatch({type: APP_ACTION_TYPE.SET_FORM_DATA, payload: updatedData})

            if (formData.is_quiz) {
                dispatch({type: BUILDER_ACTION_TYPE.SET_REFRESH_ANSWER_ERRORS, payload: true})
            }
        }
    }

    return {
        addQuestion,
        copyQuestion,
        deleteQuestion,
        setQuestionType,
        setQuestionTitle,
        addAttachmentInfo,
        removeAttachmentInfo,
        setQuestionAttachment,
        setQuestionDescription,
        questionRequiredChanged,
        questionOtherOptionChanged,
        questionOtherOptionDisabled
    }
}

export default useQuestionMgr