import React from 'react'
import * as responseFilter from "../utils/response-filter";
import {FILTER_TYPE} from "../utils/response-filter";
import {useTranslation} from "react-i18next";
import {translationKeys} from "../../../localizations/translationKeys-localization";

export default function FilterResultsContainer(props) {
    const {t} = useTranslation()
    const getFilterTag = (filterObject, key) => {
        const filteredText = filterObject.text.length > 15 ? (filterObject.text.slice(0, 14) + '...') : filterObject.text
        const tagText = '(' + ((filterObject.type === FILTER_TYPE.CONTAIN) ? t(translationKeys.contains) :
            (filterObject.type === FILTER_TYPE.EQUAL) ? t(translationKeys.equal_to)  : (filterObject.type === FILTER_TYPE.NOT_EQUAL) ? t(translationKeys.not_equal_to) : '') + ')' + ' + ' + filteredText
        const questionTitle = filterObject.questionTitle.length > 15 ? (filterObject.questionTitle.slice(0, 14) + '...') : filterObject.questionTitle;
        const tagTextWithQuestionTitle = '(' + questionTitle + ') + ' + tagText
        const handleCloseIconClick = () => {
            let filterObjectArray = props.questionFilterObjectRef.current.splice(0)
            filterObjectArray.splice(key, 1);
            props.questionFilterObjectRef.current = filterObjectArray;
            let filterObjectList = [props.timeFilterObject, ...filterObjectArray];
            props.filteredArrayRef.current = responseFilter.getFilteredResponses(filterObjectList, props.summaryDataSet);
            props.setIsFilteredArrayChanged(!props.isFilteredArrayChanged)
        };
        return (
            <div key={key} className='filter-result-tag'>
                <span style={{color: '#212121', fontSize: '12px'}}>{tagTextWithQuestionTitle}</span>
                <div className='close-button-container'
                     onClick={handleCloseIconClick}>
                    <img style={{width: '12px', height: '12px'}} alt='close' src='images/close.png'/>
                </div>
            </div>
        )
    }

    return (
        <div className='filtered-list'>
            <div className='filter-results-container'>
                <span id='filter-result'
                    className='results-title'>{t(translationKeys.results)} ({props.filteredArrayRef.current ? props.filteredArrayRef.current.length : 0})</span>
                <div className='filter-tags-container'>
                    <div className='filter-result-tag'>
                        <span ref={props.TimeTagTextElement} style={{color: '#212121', fontSize: '12px'}}/>
                    </div>
                    {props.questionFilterObjectRef.current.length > 0 ? props.questionFilterObjectRef.current.map((questionFilterObject, index) => {
                        return getFilterTag(questionFilterObject, index)
                    }) : null}
                </div>
            </div>
        </div>
    )
}
