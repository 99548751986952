import React, {useEffect} from 'react';
import styles from '../css/form-footer-component.module.css'
import {isDarkTheme, isImageTheme} from "../utils/theme-color-helper";
import {validateFormData} from "../utils/form-validation";
import {FORM_SCREEN} from "../utils/utils";
import {showSnackBarByRef} from "../../shared/components/SnackBar";
import {QUESTION_TYPE} from "../../shared/utils/constants";
import {useDispatch, useSelector} from "react-redux";
import {SCREENS_ACTION_TYPE} from "../reducers/screensReducer";

export default function FormFooterComponent(props) {
    const questions = useSelector(state => state.questionsReducer?.questions)
    const responses = useSelector(state => state.formDataReducer.formResponses)
    const formData = useSelector(state => state.formDataReducer.formData)
    const themeColor = useSelector(state => state.themeReducer.themeColor)
    const answerCount = useSelector(state => state.answerCountReducer.answerCount)
    const filesToUpload = useSelector(state => state.questionsReducer.filesToUpload)
    const fileUploadComplete = useSelector(state => state.questionsReducer.fileUploadComplete)
    const questionComponentList = useSelector(state => state.questionsReducer.questionComponentList)
    const dispatch = useDispatch()

    useEffect(() => {
        if (fileUploadComplete) {
            dispatch({type: SCREENS_ACTION_TYPE.SET_FORM_SCREEN, payload: FORM_SCREEN.THANK_PAGE})
            if (props.setSelectedPreviewScreen) {
                props.setSelectedPreviewScreen(FORM_SCREEN.THANK_PAGE);
            }
        }
    }, [fileUploadComplete])

    const submit = () => {
        let formValidationResult = validateFormData(responses, questions, filesToUpload);

        if (formValidationResult.isValid) {
            props.saveFileUploadsInAWS()
        } else {
            highlightInvalidQuestionsUI(formValidationResult);
        }
    }

    function highlightInvalidQuestionsUI(formValidationResult) {
        const inValidAnsweredCount = formValidationResult.inValidQuestionIdList.length;
        showSnackBarByRef(props.snackBarRef, {
            timeoutMS: 6000,
            messageText: inValidAnsweredCount + " - Question" + ((inValidAnsweredCount > 1) ? "s " : " ") + "requires answer",
            buttonText: "Close"
        })
        let requiredQuestionElement;
        formValidationResult.inValidQuestionIdList.map((inValidQuestion, index) => {
            for (let i = 0; i < questionComponentList.length; i++) {
                requiredQuestionElement = questionComponentList[i];
                if (inValidQuestion.id === requiredQuestionElement.question_id) {
                    let message = "This question requires an answer";
                    if (inValidQuestion.type === QUESTION_TYPE.EMAIL.key && inValidQuestion.isInvalidEmailAddress) {
                        message = "Invalid email address"
                    }
                    requiredQuestionElement.setInfoTag({
                        is_show: true,
                        text: message,
                        textColor: 'rgb(255, 255, 255)',
                        backgroundColor: 'rgb(244, 67, 54)'
                    });
                    if (index === 0 && requiredQuestionElement.questionComponentRef.current) {
                        requiredQuestionElement.questionComponentRef.current.scrollIntoView({behavior: 'smooth'});
                        const coreFormElement = requiredQuestionElement.questionComponentRef.current.querySelector('input, textarea, select');
                        coreFormElement ? coreFormElement.focus() : null;
                    }
                    break;
                }
            }
        })
    }
    const getQuestionCount = (() => {
        let totalQuestionCount = 0;
        let questions = formData?.pages[0].questions;
        for (let i = 0; i < questions?.length; i++) {
            if (questions[i].type !== QUESTION_TYPE.SECTION.key) {
                totalQuestionCount += 1;
            }
        }
        return totalQuestionCount;
    })();

    return (
        <div id="fixed_navigation_bottom" className={styles.fixed_navigation_bottom}
             style={{
                 backgroundColor: isImageTheme(formData?.theme) ? themeColor?.secondaryColor : isDarkTheme(formData?.theme) ? '#212121' : '#ffffff',
                 boxShadow: 'rgba(0, 0, 0, 0.2) 0px 8px 10px 5px'
             }}>
            <div style={{width: '70%'}} className={styles.answered_count_container}>
                <div className={styles.chip}
                     style={{
                         margin: '0',
                         paddingLeft: '30px',
                         paddingRight: '30px',
                         backgroundColor: '#fff',
                         textAlign: 'center'
                     }}>
                    <label>
                        <span style={{fontSize: 'small', color: '#212121'}}>Answered</span>
                        <span id="answered_question_count"
                              style={{
                                  color: '#000',
                                  fontWeight: '600',
                                  fontSize: '16px'
                              }}>{answerCount}</span>
                        <span id="total_question_count" style={{fontSize: 'small'}}>of {getQuestionCount}</span>
                    </label>
                </div>
            </div>

            <div id="submit_fixed_button_container" className={styles.submit_fixed_button_container}
                 style={{width: '12%'}}>
                <i id="submit_fixed_button" className="material-icons"
                   style={{
                       textAlign: 'center',
                       color: isImageTheme(formData?.theme) ? '#ffffff' : isDarkTheme(formData?.theme) ? '#ffffff' : '#212121',
                       fontSize: '35px',
                   }}
                   onClick={submit}>done</i>
            </div>
        </div>
    );
}
