import React from 'react'
import useFormImportManager from "../../../app/hooks/useFormImportManager"
import closeImage from "../../../../public/images/close.png"
import importImage from '../../../../public/images/icons/builder/import.svg'
import closeBlackImage from '../../../../public/images/close_black.svg'
import {getDate} from "../../../app/dashboard/utils/get-date"
import CurrentFormHeader from "./CurrentFormHeader"
import CurrentFormBody from "./CurrentFormBody"
import {useSelector} from "react-redux"
import QuestionsToBeImported from "./QuestionsToBeImported"

const SearchAndFormsList = () => {
    const questionSearchResults = useSelector(state => state.importQuestionsReducer.questionSearchResults)
    const searchMatches = useSelector(state => state.autocompleteReducer?.matches);
    const formData = useSelector(state => state.appReducer.formData)

    const {
        clearSearchInputImg,
        clearSearchInput,
        doSearchImage,
        formsList,
        handleFormCardClick,
        handleCloseDialog,
        handleSearchTextChange,
        loadingImage,
        noSearchResultsFound,
        searchGreyImage,
        getBackgroundURL,
        searchForTextInForms,
        searchInput,
        searchInputReadOnly,
        searchText,
        searchTextReadonly,
        showSearchAndFormsList,
        showForms,
        showLoader,
        showNoSearchResults,
        t,
        translationKeys
    } = useFormImportManager()

    const popUpStyle = {
        backgroundColor: 'rgba(0, 0, 0, 0.3)',
        height: '100%',
        width: '100%',
        zIndex: '10',
        top: '0',
        left: '0',
        position: 'fixed',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: '0',
        padding: '0',
        cursor: 'default'
    }

    return (
        <div style={popUpStyle}>
            <div id={'importQuestionsDialog'} style={{width: '1135px', position: 'absolute', top: '50px'}}>
                <div className={'feature-spotlight-header'}>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <img style={{marginRight: '3px'}}
                             src={importImage} alt={'Import'}
                        />
                        <div className={'feature-spotlight-header-text'}>
                            {t(translationKeys.import_questions)}
                        </div>
                    </div>
                    <div>
                        <img onClick={() => {
                            handleCloseDialog()
                        }}
                             className={'feature-spotlight-close-img'}
                             src={closeImage} alt={t(translationKeys.close)}
                        />
                    </div>
                </div>

                <>
                    <div className={'feature-spotlight-body'}
                         style={{paddingLeft: '25px', paddingRight: '2px', height: "75vh"}}>
                        {
                            (() => {
                                if (showLoader) {
                                    return (
                                        <div style={{
                                            display: 'flex',
                                            height: '550px',
                                            justifyContent: 'center',
                                            alignItems: 'center'
                                        }}>
                                            <img src={loadingImage} style={{height: '50px'}}
                                                 alt="loader.gif"/>
                                        </div>
                                    )
                                }

                                if (showSearchAndFormsList) {
                                    return (
                                        <>
                                            <div
                                                className={'import-questions-input import-questions-input-write'}>
                                                <div style={{
                                                    display: 'flex',
                                                    marginRight: '5px',
                                                    width: '100%'
                                                }}>
                                                    <img src={searchGreyImage}
                                                         alt={t(translationKeys.search)}/>
                                                    <input type="text" id={searchInput}
                                                           name={searchInput}
                                                           value={searchText}
                                                           onChange={(event) => {
                                                               handleSearchTextChange(event.target.value)
                                                           }}
                                                           onKeyDown={(value) => {
                                                               if (value.key === 'Enter' && searchText.trim().length > 3) {
                                                                   searchForTextInForms()
                                                               }
                                                           }}
                                                           placeholder={t(translationKeys.search)}
                                                           maxLength='75'
                                                           style={{
                                                               border: 'none',
                                                               outline: 'none'
                                                           }}
                                                    />
                                                </div>
                                                <img src={closeBlackImage}
                                                     alt={t(translationKeys.clear)}
                                                     style={{
                                                         visibility: searchText.length > 0 ? 'visible' : 'hidden',
                                                         cursor: 'pointer'
                                                     }}
                                                     id={clearSearchInputImg}
                                                     onClick={() => clearSearchInput()}
                                                />
                                            </div>

                                            {
                                                (() => {
                                                    if (showNoSearchResults) return (
                                                        <div style={{
                                                            display: "flex",
                                                            alignItems: "center",
                                                            flexFlow: "column",
                                                            justifyContent: "center",
                                                            height: "85%"
                                                        }}>
                                                            <img src={noSearchResultsFound}
                                                                 alt={t(translationKeys.no_results_found)}
                                                                 style={{marginBottom: '10px'}}
                                                            />
                                                            {t(translationKeys.no_results_found)}
                                                        </div>
                                                    )

                                                    if (questionSearchResults.length > 0) return (
                                                        <QuestionsToBeImported
                                                            availableQuestions={questionSearchResults}
                                                        />
                                                    )

                                                    return (
                                                        <>
                                                            <div
                                                                className={'import-questions-input import-questions-input-read-only'}
                                                                style={{display: showForms ? 'none' : 'flex'}}
                                                                onClick={() => searchForTextInForms()}
                                                            >
                                                                <img src={doSearchImage}
                                                                     alt={t(translationKeys.do_search)}/>
                                                                <input type="text" id={searchInputReadOnly}
                                                                       name={searchInputReadOnly}
                                                                       value={searchTextReadonly}
                                                                       readOnly={true}
                                                                       placeholder={''}
                                                                       maxLength='75'
                                                                       style={{
                                                                           border: 'none',
                                                                           outline: 'none'
                                                                       }}
                                                                />
                                                            </div>
                                                            {searchMatches.length > 0 ? <div style={{zIndex: 2,}}>
                                                                {
                                                                    searchMatches?.map((match, index) => {
                                                                        return (
                                                                            <div
                                                                                className={'import-questions-input import-questions-input-read-only'}
                                                                                style={{
                                                                                    padding: '10px 14px 0',
                                                                                    color: 'black'
                                                                                }}
                                                                                key={index}
                                                                                onClick={() => {
                                                                                    searchForTextInForms()
                                                                                    handleSearchTextChange(match)
                                                                                }}
                                                                            >
                                                                                <img
                                                                                    src={searchGreyImage}
                                                                                    alt={t(translationKeys.search)}/>
                                                                                <div style={{
                                                                                    padding: '10px 14px 0',
                                                                                    width: "-webkit-fill-available",
                                                                                    borderTop: "1px solid #E1E1E1"
                                                                                }}>{match}</div>
                                                                            </div>
                                                                        )
                                                                    })
                                                                }
                                                            </div> : null}
                                                        </>
                                                    )
                                                })()
                                            }
                                            <div style={{
                                                display: showForms ? 'flex' : 'none',
                                                marginTop: '20px'
                                            }}>
                                                {formsList.length ? <div id='importQuestionsForms'
                                                                         className='import-question-forms'>
                                                        {formsList?.map((form, index) => {
                                                            if (form._id !== formData?._id && form?.is_valid) {
                                                                return (
                                                                    <div key={index}
                                                                         className={'form-card'}
                                                                         style={{
                                                                             background: getBackgroundURL(form.theme),
                                                                             backgroundSize: "cover",
                                                                             marginLeft: "0px",
                                                                             marginRight: "25px",
                                                                             marginTop: "0px",
                                                                             marginBottom: "20px",
                                                                             cursor: 'pointer'
                                                                         }}
                                                                         onClick={() => handleFormCardClick(form._id)}
                                                                    >
                                                                        <div
                                                                            className='bottom-container'>
                                                                            <div
                                                                                className='bottom-text-container'>
                                                                                <p
                                                                                    id="card-form-title"
                                                                                    className="card-form-title">{form.welcome_screen.title}</p>
                                                                                <p
                                                                                    id="form-modified-date"
                                                                                    className="secondary-data">{getDate(form.date_edited)}</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            }
                                                        })
                                                        }
                                                    </div> :
                                                    <div style={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'center',
                                                        width: '-webkit-fill-available',
                                                        height: '-webkit-fill-available'
                                                    }}>
                                                        <p>{formData?.is_quiz ? t(translationKeys.no_quiz_available) : t(translationKeys.no_form_available)}</p>
                                                    </div>}
                                            </div>
                                        </>
                                    )
                                } else {
                                    return (
                                        <>
                                            <CurrentFormHeader/>
                                            <CurrentFormBody/>
                                        </>
                                    )
                                }
                            })()
                        }
                    </div>
                </>
                <div className='feature-spotlight-footer'>
                    &nbsp;
                </div>
            </div>
        </div>
    )
}

export default SearchAndFormsList