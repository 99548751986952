import React from 'react'
import '../css/grid-style.css'
import {convertFirstLetterToUpper} from "../../../shared/utils/helper"
import {QUESTION_TYPE} from "../../../shared/utils/constants"
import {isMobileOnly} from "react-device-detect";

export default function GridResultsContainer(props) {
    const optionBars = getResultOptionGridBars(props.questionSummaryData, props.answeredCount, props.showAllValues)
    return (
        <div id='result-container-descendingOptionBars' className='result-container'>
            {optionBars}
        </div>
    );
}

export function getResultOptionGridBars(questionSummaryData, answeredCount, showAllValues) {
    const getBackGroundColor = (percentage) => {
        const colorsArray = ["#EAF5FF", "#EAF5FF", "#DCEEFF", "#D0E8FF", "#C0DFFF", "#AFD7FF", "#A0D0FF", "#91C8FF", "#80C0FF", "#71B9FF", "#61B0FF"]
        return colorsArray[Math.round(percentage / 10)]
    }
    return <div style={{display: "flex", justifyContent: "center"}}>
        <div className={"row_labels"} key={"row_labels"}>
            {questionSummaryData.responseAnalyzeData.rowGraphLabels?.map((label, index) => {
                if (showAllValues || index < (isMobileOnly ? 4 : 3)) {
                    return <div className='grid-chart-label'
                                style={isMobileOnly ? {margin: "10px 0", padding: "5px 0"} : {
                                    margin: "10px",
                                    padding: "15px"
                                }}
                                key={`${index}`}>{convertFirstLetterToUpper(label)}</div>
                }
            })}
        </div>
        <div className={"grid_columns"} key={"grid_columns"} style={showAllValues ? {overflow: "auto"} : null}>
            {questionSummaryData.responseAnalyzeData.rowsIdList?.map((row, rowIndex) => {
                if (questionSummaryData.type === QUESTION_TYPE.MULTIPLE_CHOICE_GRID.key) {
                    let selectedRow = questionSummaryData.grid_choices?.find(gc => gc.row === row)

                    return <div style={{display: "flex"}} key={`${rowIndex}`}>
                        {questionSummaryData.responseAnalyzeData.columnGraphLabels.map((column, columnIndex) => {
                            let currentColumnId = questionSummaryData.responseAnalyzeData.columnsIdList[columnIndex]
                            let selectedColumn = questionSummaryData.grid_choices?.find(gc => gc.column === currentColumnId)

                            if (showAllValues || (columnIndex < 4 && rowIndex < (isMobileOnly ? 4 : 3))) {
                                const percentage = (() => {
                                    if (selectedRow) {
                                        let numerator = questionSummaryData.grid_choices.filter(gc => gc.column === currentColumnId && gc.row === selectedRow.row).length

                                        let denominator = questionSummaryData.grid_choices.filter(gc => gc.row === selectedRow.row).length

                                        return (selectedColumn ? (numerator / denominator) : 0) * 100
                                    }

                                    return 0
                                })()

                                const percentageAnswered = percentage.toFixed(isMobileOnly ? 0 : 2) + '%'

                                return <div style={{display: "grid", width: isMobileOnly ? "33%" : "100%"}}
                                            key={`${rowIndex}-${columnIndex}`}>
                                    {rowIndex === 0 ? <span
                                        className='grid-chart-label'>{convertFirstLetterToUpper(column)}</span> : null}
                                    <div className={"overview_item"}
                                         style={{backgroundColor: getBackGroundColor(percentage)}}
                                         key={`${rowIndex}-${columnIndex}`}
                                    >
                                        {percentageAnswered}
                                    </div>
                                </div>
                            }
                        })}
                    </div>
                } else if (questionSummaryData.type === QUESTION_TYPE.CHECKBOX_GRID.key) {
                    let selectedRow = questionSummaryData.checkbox_grid_choices?.find(gc => gc.row === row)

                    return <div style={{display: "flex"}} key={`${rowIndex}`}>
                        {questionSummaryData.responseAnalyzeData.columnGraphLabels.map((column, columnIndex) => {
                            let currentColumnId = questionSummaryData.responseAnalyzeData.columnsIdList[columnIndex]

                            if (showAllValues || (columnIndex < 4 && rowIndex < (isMobileOnly ? 4 : 3))) {
                                const percentage = (() => {
                                    if (selectedRow) {
                                        let numerator = selectedRow.columns.filter(col => col === currentColumnId).length

                                        return (numerator / answeredCount) * 100
                                    }

                                    return 0
                                })()

                                const percentageAnswered = percentage.toFixed(isMobileOnly ? 0 : 2) + '%'

                                return <div style={{display: "grid", width: "100%"}} key={`${rowIndex}-${columnIndex}`}>
                                    {rowIndex === 0 ? <span
                                        className='grid-chart-label'>{convertFirstLetterToUpper(column)}</span> : null}
                                    <div className={"overview_item"}
                                         style={{backgroundColor: getBackGroundColor(percentage)}}
                                         key={`${rowIndex}-${columnIndex}`}
                                    >
                                        {percentageAnswered}
                                    </div>
                                </div>
                            }
                        })}
                    </div>
                }
            })}
        </div>
    </div>
}