import React, {useEffect, useRef, useState} from "react"
import MaterialTextField from "../../../shared/components/MaterialTextField";
import {translationKeys} from "../../../localizations/translationKeys-localization";
import {t} from "i18next";
import useChoiceMgr from "../../hooks/useChoiceMgr";
import MaterialSwitch from "../../../shared/components/MaterialSwitch";
import {QUESTION_TYPE} from "../../../shared/utils/constants";
import {BUILDER_ACTION_TYPE} from "../../reducers/builderReducer";
import continueButton from "../../../../public/images/icons/builder/undo_button.svg";
import submitButton from "../../../../public/images/icons/builder/submit_gray_tick_mark.svg";
import useQuestionMgr from "../../hooks/useQuestionMgr";
import useLogicJumpMgr from "../../hooks/useLogicJumpMgr";
import {isDesktop} from "react-device-detect";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import {useDispatch, useSelector} from "react-redux";
import sectionIcon from "../../../../public/images/icons/builder/section_black_icon.svg";
import {PremiumJSON} from "../../../shared/utils/helper";
import {PREMIUM_ACTION_TYPE} from "../../reducers/premiumReducer";


const StarBody = React.memo((props) => {

    const formData = useSelector(state => state.appReducer.formData);
    const showShared = useSelector(state => state.appDashboardReducer.showShared)
    const subscriptionData = useSelector(state => state.userReducer?.subscriptionData)
    const showLogicJumpBasedOnUser = showShared ? PremiumJSON[subscriptionData?.subscription_plan]?.logic_jump && PremiumJSON[formData?.subscription_data?.current_subscription?.plan]?.logic_jump : PremiumJSON[subscriptionData?.subscription_plan]?.logic_jump
    const reduxDispatch = useDispatch();
    const [logicEnabled, setLogicEnabled] = useState(false);
    const [selectedQuestions, setSelectedQuestions] = useState([]);
    const {
        questionRequiredChanged,
        questionOtherOptionChanged,
        questionOtherOptionDisabled
    } = useQuestionMgr()
    const {addLogicJumpToQuestion, addTargetQuestionId, generateUniqueString, addLogicQuestionId, selectTargetForTextQuestion, removeLogic} = useLogicJumpMgr()

    useEffect(() => {
        setLogicEnabled(props.question.is_logic_enabled);
    }, [props.question.is_logic_enabled]);

    const addLogicForChoiceQuestion = (index, checkedStatus) => {
        questionRequiredChanged(index, checkedStatus)
        questionOtherOptionChanged(index, checkedStatus)
        questionOtherOptionDisabled(index, checkedStatus)
        addLogicJumpToQuestion(index, checkedStatus)
        setLogicEnabled(checkedStatus)
        if (!checkedStatus) {
            selectTargetForTextQuestion(index, '')
            removeLogic(index)
            setSelectedQuestions([])
        }
    }
    const disabledSwitchClicked = () => {
        if (!showLogicJumpBasedOnUser) {
            if (showShared && !PremiumJSON[formData?.subscription_data?.current_subscription?.plan]?.logic_jump) {
                reduxDispatch({type: PREMIUM_ACTION_TYPE.SET_SHOW_FREE_OWNER_POP_UP, payload: true})
            } else {
                reduxDispatch({type: PREMIUM_ACTION_TYPE.SET_SHOW_UPGRADE_PLAN_POP_UP, payload: true})
            }
        } else if (formData.pages[0].questions.length <= 1 || formData.pages[0].questions.length - 1 === props.index) {
            reduxDispatch({type: BUILDER_ACTION_TYPE.SET_BUILDER_TOAST_MESSAGE, payload: true})
        }
    }

    const handleJumpToQuestion = (choiceIndex, targetQuestionIndex) => {
        const newSelectedValues = [...selectedQuestions];
        newSelectedValues[choiceIndex] = targetQuestionIndex;
        setSelectedQuestions(newSelectedValues);
        if (targetQuestionIndex === "0" || targetQuestionIndex === "-1") {
            addLogicQuestionId(props.index, choiceIndex, targetQuestionIndex)
        } else if (props.formData?.pages[0]?.questions[targetQuestionIndex]?.logic_question_id) {
            addLogicQuestionId(props.index, choiceIndex, props.formData.pages[0].questions[targetQuestionIndex].logic_question_id)
        } else {
            const uniqueString = generateUniqueString();
            addTargetQuestionId(targetQuestionIndex, uniqueString);
            addLogicQuestionId(props.index, choiceIndex, uniqueString);
        }
    }

    const {updateOptionValue} = useChoiceMgr()
    return (
        <div id={'star-body-container-starBody'} className="star-body-container" style={{height: "auto"}}>
            {
                (() => {
                    let values = props.question.choices.map((choices, starIndex) => (
                        (() => {
                            let questionNumber = 1;
                            const starInputRef = useRef(null);
                            const handleChange = (starIndex, value) => {
                                updateOptionValue(props.index, starIndex, value)
                            }
                            const handleBlur = (starIndex, value) => {
                                if (value === '') {
                                    switch (starIndex) {
                                        case 0:
                                            starInputRef.current.value = t(translationKeys.worst)
                                            break
                                        case 1:
                                            starInputRef.current.value = t(translationKeys.not_good)
                                            break
                                        case 2:
                                            starInputRef.current.value = t(translationKeys.neutral)
                                            break
                                        case 3:
                                            starInputRef.current.value = t(translationKeys.good)
                                            break
                                        case 4:
                                            starInputRef.current.value = t(translationKeys.very_good)
                                            break
                                    }
                                }
                                updateOptionValue(props.index, starIndex, starInputRef.current.value)
                            }
                            return (
                                <div key={starIndex} className="star-line">
                                    <div style={{
                                        display: "flex",
                                        flexBasis: (logicEnabled && isDesktop) ? "50%" : "100%"
                                    }}>
                                        {
                                            (() => {
                                                let starNumber = ''

                                                switch (starIndex) {
                                                    case 0:
                                                        starNumber = "1"
                                                        break
                                                    case 1:
                                                        starNumber = "2"
                                                        break
                                                    case 2:
                                                        starNumber = "3"
                                                        break
                                                    case 3:
                                                        starNumber = "4"
                                                        break
                                                    case 4:
                                                        starNumber = "5"
                                                        break
                                                }

                                                return (
                                                    <img
                                                        src={`../../../images/Star_${starNumber}.svg`}
                                                        alt="Star Rating" className="star-line-img"
                                                        style={{width: '100px'}}/>
                                                )
                                            })()
                                        }
                                        <div className='sh-text-field' style={{marginBottom: '15px'}}>
                                            <MaterialTextField
                                                id={`star-question-text-input-${props.index}-${starIndex}`}
                                                data={{
                                                    defaultValue: choices.label
                                                }}
                                                inputRef={starInputRef}
                                                onChange={value => handleChange(starIndex, value)}
                                                onBlur={value => handleBlur(starIndex, value)}
                                                maxLength={100}
                                                noLabel={true}
                                                hideHelpLine={true}
                                            />
                                        </div>
                                    </div>
                                    {(logicEnabled && showLogicJumpBasedOnUser && isDesktop) &&
                                        <div className={"logic-jump-to-question-container"}>
                                            <p>Jump to</p>
                                            <div className={'choice-body-add-logic-dropdown-container'}
                                                 key={`star-question-helper-${starIndex}`}>
                                                <FormControl variant="standard"
                                                             sx={{m: 1, minWidth: 220, margin: 0, width: '100%'}}>
                                                    <Select
                                                        labelId={`select-question-helper-label-${starIndex}`}
                                                        id={`select-question-helper-${starIndex}`}
                                                        label="Select Question"
                                                        MenuProps={{
                                                            style: {width: '280px'}, //to Set the width of the dropdown menu
                                                        }}
                                                        value={selectedQuestions[starIndex] || (props?.question?.choices[starIndex]?.target_question_id !== ("0" || "-1") ? props?.formData?.pages[0]?.questions.findIndex((question) => props?.question?.choices[starIndex]?.target_question_id === question.logic_question_id) : props?.question?.choices[starIndex]?.target_question_id)}
                                                        onChange={(e) => handleJumpToQuestion(starIndex, e.target.value)}
                                                    >
                                                        <MenuItem value="0"><span
                                                            className={'logic-jump-question-to-select'}
                                                            key={`logic-jump-question-to-select-${starIndex}`}><img
                                                            src={continueButton}
                                                            alt={'continue to next question icon'}/> {t(translationKeys.continue_to_next_question)}</span></MenuItem>
                                                        <MenuItem value="-1"><span
                                                            className={'logic-jump-question-to-select'}
                                                            key={`logic-jump-question-to-select-${starIndex}`}><img
                                                            src={submitButton}
                                                            alt={'submit form  icon'}/> {t(translationKeys.submit_form)}</span></MenuItem>
                                                        {props.formData.pages[0].questions.map((object, index) => {
                                                            if (object.type === 'SECTION') {
                                                                return (
                                                                    index > props.index &&
                                                                    (<MenuItem value={index}> <span
                                                                        className={'logic-jump-question-to-select'}><img
                                                                        src={sectionIcon}
                                                                        alt={'section icon'}/> {object.title}</span></MenuItem>))
                                                            } else {
                                                                const questionContent = `${questionNumber}. ${object.title}`;
                                                                questionNumber++;
                                                                return (
                                                                    index > props.index &&
                                                                    (<MenuItem value={index}> <span
                                                                        className={'logic-jump-question-to-select'}>{questionContent}</span></MenuItem>))
                                                            }
                                                        })}
                                                    </Select>
                                                </FormControl>
                                            </div>
                                        </div>}
                                </div>
                            )
                        })()
                    ))

                    return (
                        <>
                            {values}
                        </>
                    )
                })()
            }
            <div style={{display: "flex", flexGrow: "1", paddingTop: '20px'}}>
                <div id={'choice-body-add-logic-option'} className="choice-body-add-logic-jump-option">
                    {t(translationKeys.add_logic)}
                </div>
                <div onClick={disabledSwitchClicked} style={{cursor: "pointer"}}>
                    <MaterialSwitch
                        index={props.index}
                        initialCheckedState={props.question.is_logic_enabled}
                        switchChanged={addLogicForChoiceQuestion}
                        customStyle={(props.formData?.pages[0].questions.length <= 1 || props.formData?.pages[0].questions.length - 1 === props.index) || (QUESTION_TYPE[props.question.type] === QUESTION_TYPE.CHECKBOX_CHOICE) ? {cursor: 'not-allowed'} : null}
                        isSwitchDisabled={(props.formData?.pages[0].questions.length <= 1 || props.formData?.pages[0].questions.length - 1 === props.index) || (QUESTION_TYPE[props.question.type] === QUESTION_TYPE.CHECKBOX_CHOICE)}
                    />
                </div>
            </div>
        </div>
    )
})

export default StarBody