import React, {useState} from 'react'
import MaterialCheckBox from "../../../shared/components/MaterialCheckBox";
import {QUIZ_SETTINGS} from "./QuizBuilderContainer";
import {translationKeys} from "../../../localizations/translationKeys-localization";
import {useSelector} from "react-redux";
import {t} from "i18next";

const SettingsQuizResultContainer = props => {
    const formData = useSelector(state => state.appReducer.formData)
    const [correctAnswersOptionIsVisible, setCorrectAnswersOptionIsVisible] = useState(formData ? formData?.setting.allow_summary_view : props.settingValues.allow_summary_view)
    const [showCorrectAnswers, setShowCorrectAnswers] = useState(formData?.setting.is_show_correct_answer)

    const handleShowQuizResultsChanged = (bool) => {
        if (!bool) {
            setShowCorrectAnswers(false)
            props.updateSetting(QUIZ_SETTINGS.SHOW_CORRECT_ANSWERS, false)
        }

        setCorrectAnswersOptionIsVisible(bool)
        props.updateSetting(QUIZ_SETTINGS.ALLOW_SUMMARY_VIEW, bool)
    }

    const handleShowCorrectAnswersChanged = (bool) => {
        setShowCorrectAnswers(bool)
        props.updateSetting(QUIZ_SETTINGS.SHOW_CORRECT_ANSWERS, bool)
    }

    return (
        <div id={'settings-quiz-result'} className="settings-quiz-result-AND-questions-wrapper">
            {/* Row 1 */}
            <div id={'settings-allow-summary-view-checkbox'} className="settings-quiz-result-AND-questions-box1">
                <MaterialCheckBox isChecked={formData?.setting.allow_summary_view}
                                  removeWidth={true}
                                  onChange={handleShowQuizResultsChanged}

                />
            </div>
            <div className="settings-quiz-result-AND-questions-box2">
                <img src="../../images/donut.svg" alt="Graph"/>
                <div id={'show-quiz-results'} className="settings-bold-text">{t(translationKeys.show_results)}</div>
            </div>
            {/* Row 2 */}
            <div className="settings-quiz-result-AND-questions-box3">
                <span id={'allow-users-to-view-results'}
                    className='settings-normal-text'>{t(translationKeys.allow_result_view_quiz_alert)}</span>
            </div>


            {
                (() => {
                        if (correctAnswersOptionIsVisible)
                            return (
                                <React.Fragment>
                                    {/* Row 3 */}
                                    <div id={'settings-show-correct-answers-checkbox'} className="settings-quiz-result-AND-questions-box4">
                                        <MaterialCheckBox isChecked={showCorrectAnswers}
                                                          removeWidth={true}
                                                          onChange={handleShowCorrectAnswersChanged}

                                        />
                                    </div>
                                    <div className="settings-quiz-result-AND-questions-box5">
                                        <img src="../../images/done_blue.svg" alt="Check"/>
                                        <div id={'show-correct-answers'} className="settings-bold-text">{t(translationKeys.show_correct_answer)}</div>
                                    </div>
                                    {/* Row 4 */}
                                    <div className="settings-quiz-result-AND-questions-box6">
                                        <span id={'show-correct-answers-to-users'} className='settings-normal-text'>{t(translationKeys.do_you_want_to_show_all_correct_answers_to_users)}</span>
                                    </div>
                                </React.Fragment>
                            )
                    }
                )()
            }
        </div>
    )
}

export default SettingsQuizResultContainer
