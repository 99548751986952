import {QUESTION_TYPE} from "../../../shared/utils/constants"
import QuestionTypeMenuIconInfo from "./QuestionTypeMenuIconInfo"

/**
 * @param {number} key - Unique Key
 * @param {QuestionTypeMenuIconInfo} iconInfo - The Path to the Icon
 * @param {QUESTION_TYPE} questionTypeValue - Value from QuestionTypeEnum
 * @param {boolean} displayNewImage - Show 'New' Image next to 'text'
 */
class QuestionTypeMenuItem {
    constructor(key, iconInfo, questionTypeValue, displayNewImage = false) {
        this.Key = key
        this.ImgPath = iconInfo
        this.QuestionTypeValue = questionTypeValue
        this.DisplayNewImage = displayNewImage
    }

    getKey() {
        return this.Key
    }

    getText() {
        return QUESTION_TYPE[this.QuestionTypeValue.key].displayText
    }

    getIconInfo() {
        return this.ImgPath
    }

    getQuestionType() {
        return this.QuestionTypeValue
    }

    getDisplayNewImage() {
        return this.DisplayNewImage
    }
}

export default QuestionTypeMenuItem