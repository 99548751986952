import React, {useEffect, useRef} from "react";
import styles from '../../css/star-rating-question.module.css'
import {PREVIEW_TYPE} from "../../../shared/utils/constants";
import {FORM_DATA_ACTION_TYPE} from "../../reducers/formDataReducer";
import {useDispatch, useSelector} from "react-redux";
import {ACTION_TYPE} from "../../utils/utils";
import {answeredQuestionsCount, updateResponses} from "../../utils/form-response";
import useLogicQuestionMgr from "../../hooks/useLogicQuestionMgr";

const starRatingColors = ["#e53935", "#ef9a9a", "#757575", "#81c784", "#2e7d32"];
export const StarRatingChoiceQuestion = (props) => {
    const {updateFormQuestionsBasedOnSelection} = useLogicQuestionMgr()
    const responses = useSelector(state => state.formDataReducer.formResponses)
    const editResponseId = useSelector(state => state.formDataReducer.editResponseId)
    const fileUploadAnsweredCount = useSelector(state => state.answerCountReducer?.fileUploadAnswerCount)
    const starRatingRef = useRef(undefined);
    const ratingDescriptionTextRef = useRef(undefined);
    const dispatch = useDispatch()

    useEffect(() => {
        responses.map((response) => {
            if (response.question_id === props.questionData._id) {
                if (response?.choice?.length === 0) {
                    let starElements = starRatingRef.current.getElementsByTagName("img");
                    ratingDescriptionTextRef.current.innerText = "";
                    for (let i = 0; i < starElements.length; i++) {
                        starElements[i].src = "/images/star_empty.svg"
                    }
                } else if (response.choice) {
                    props.questionData.choices.map((choice, index) => {
                        if (response.choice === choice._id) {
                            if (editResponseId) {
                                updateFormQuestionsBasedOnSelection(choice, props.questionData, false)
                            }
                            let starElements = starRatingRef.current.getElementsByTagName("img");
                            ratingDescriptionTextRef.current.innerText = props.questionData.choices[index].label;
                            ratingDescriptionTextRef.current.style.color = starRatingColors[index];
                            for (let i = 0; i < starElements.length; i++) {
                                if (i <= index) {
                                    starElements[i].src = "/images/star_filled.svg"
                                } else {
                                    starElements[i].src = "/images/star_empty.svg"
                                }
                            }
                        }
                    })
                }
            }
        })
    }, [responses])

    function handleOnClickStar(index, value) {
        if (starRatingRef.current) {
            let starElements = starRatingRef.current.getElementsByTagName("img");
            let inputElements = starRatingRef.current.getElementsByTagName("input");
            ratingDescriptionTextRef.current.innerText = props.questionData.choices[index].label;
            ratingDescriptionTextRef.current.style.color = starRatingColors[index];
            inputElements[index].checked = true;
            for (let i = 0; i < starElements.length; i++) {
                if (i <= index) {
                    starElements[i].src = "/images/star_filled.svg"
                } else {
                    starElements[i].src = "/images/star_empty.svg"
                }
            }
            props.validateRequired(value)
        }
        dispatch({type: FORM_DATA_ACTION_TYPE.SET_FORM_RESPONSES, payload: updateResponses(responses, props.questionData, value)})
        updateFormQuestionsBasedOnSelection(props.questionData.choices[index], props.questionData, true)
        dispatch({type: ACTION_TYPE.SET_ANSWER_COUNT, payload: answeredQuestionsCount(responses) + fileUploadAnsweredCount})
    }

    function getPreviewStarSize() {
        if (props.previewType) {
            let height;
            let width;
            switch (props.previewType) {
                case PREVIEW_TYPE.PHONE:
                    height = "25px";
                    width = "25px";
                    break;
                case PREVIEW_TYPE.TABLET:
                    height = "30px";
                    width = "30px";
                    break;
                case PREVIEW_TYPE.DESKTOP:
                    height = "50px";
                    width = "50px";
                    break;
            }
            return {
                height: height,
                width: width
            }
        } else {
            return null;
        }
    }

    return (
        <div className={styles.star_rating_container} ref={starRatingRef}>
            <div className={styles.stars_container}>
                {props.questionData.choices.map((choice, index) => {
                    return (
                        <div onClick={() => {
                            handleOnClickStar(index, choice._id)
                        }} key={choice._id}>
                            <input type="radio" name={props.questionData._id} defaultValue={choice._id}/>
                            <img src={"/images/star_empty.svg"} alt={`Star ${index + 1}`} style={getPreviewStarSize()}/>
                        </div>
                    );
                })
                }
            </div>
            <span id="rating-description-text" ref={ratingDescriptionTextRef}/>
        </div>
    );
}
