import React from 'react';
import {TextQuestion} from "./questions/TextQuestion";
import {getQuestionCategory, QUESTION_CATEGORY} from "../../shared/utils/constants";
import ChoiceQuestion from "./questions/ChoiceQuestion";
import {FileUploadQuestion} from "./questions/FileUploadQuestion"

export default function FormQuestionBody(props) {
    if (getQuestionCategory(props.questionData.type) === QUESTION_CATEGORY.TEXT_TYPE) {
        return (<TextQuestion {...props}/>)
    } else if (getQuestionCategory(props.questionData.type) === QUESTION_CATEGORY.CHOICE_TYPE || getQuestionCategory(props.questionData.type) === QUESTION_CATEGORY.GRID_TYPE) {
        // Although questionData.type === QUESTION_CATEGORY.GRID_TYPE, for filling responses,
        // 'ChoiceQuestion' is used.
        return (<ChoiceQuestion {...props}/>)
    } else if (getQuestionCategory(props.questionData.type) === QUESTION_CATEGORY.FILE_UPLOAD_TYPE) {
        return (<FileUploadQuestion color={props.color} {...props}/>)
    }
}
