import React, {useState} from "react";
import {getDateWithTime} from "../../../shared/utils/get-date-with-time";
import SummaryDataSet from "./SummaryDataSetContext";
import {deleteResponse} from "../../../shared/utils/sash-v2-api";
import MaterialButton from "../../../shared/components/MaterialButton";
import Loader from "../../../shared/components/Loader";
import {Pages} from "../../utils/pages";
import {deleteQuizAnswerService, QUIZ_BASE_URL} from "../../../shared/utils/quiz-api-services";
import {isMobileOnly} from "react-device-detect";
import AnswerSheet from "../../../answer-sheet/components/AnswerSheet";
import {getAnswerSheetDataFromQuizData} from "../../../answer-sheet/utils/answer-sheet-data";
import {removeResponseInSummaryDataSet} from "../utils/summary-data";
import updateMarkInFormResponsesData from "../../../answer-sheet/utils/update-mark-in-form-responses-data";
import calculateQuizV2Result from "../utils/calculate-quiz-result";
import ModalDialog from "../../../shared/components/ModalDialog";
import ShareCard from "../../../shared/components/ShareCard";
import MaterialFloatingActionButton from "../../../shared/components/MaterialFab";
import StickyTopNavBar from "./StickyTopNavBar";
import {getRole} from "../../../shared/utils/helper";
import {COLLABORATOR_ROLES} from "../../builder/enums/collaboratorsRoles";
import {useDispatch, useSelector} from "react-redux";
import {translationKeys} from "../../../localizations/translationKeys-localization";
import {APP_ACTION_TYPE} from "../../reducers/appReducer";
import {RESPONSES_ACTION_TYPE} from "../../reducers/responsesReducer";
import {t} from "i18next";
import close from "../../../../public/images/close_grey.svg";
import {APP_DASHBOARD_ACTION_TYPE} from "../../reducers/appDashboardReducer";

const IndividualResponseContent = (props) => {
    const formData = useSelector(state => state.appReducer?.formData)
    const responseCount = useSelector(state => state.responsesReducer.responseCount)
    const formResponsesData = useSelector(state => state.responsesReducer.formResponsesData)
    const selectedLanguage = useSelector(state => state.languageReducer.selectedLanguage)
    const originalSummaryDataSet = useSelector(state => state.responsesReducer.originalSummaryDataSet)
    const [showDeleteAlert, setShowDeleteAlert] = useState(false)
    const [isMarkUpdated, setIsMarkUpdated] = useState(false)
    const [screenHeight, setScreenHeight] = useState(window.innerHeight)
    const [isLoading, setIsLoading] = useState(false)
    const [showAnswerSheetShare, setShowAnswerSheetShare] = useState(false)
    const [toastMessage, setToastMessage] = useState(<></>)
    const responsesData = formResponsesData?.formResponses

    const userId = useSelector(state => state.userReducer?.userId)

    const dispatch = useDispatch()

    document.body.click()
    document.onkeydown = (e) => {
        if (e.key === "Escape") {
            handleDeletePopUp(false)
            dispatch({type: APP_DASHBOARD_ACTION_TYPE.SET_SHOW_SHORTCUTS, payload: false})
        }
        if (e.key === "ArrowRight") {
            if (props.currentResponseNumber < (responsesData?.respondents ? responsesData.respondents.length : 1)) {
                props.setCurrentResponseNumber(props.currentResponseNumber ? props.currentResponseNumber + 1 : 1)
            }
        }
        if (e.key === "ArrowLeft") {
            if (currentResponseNumber > 1) {
                props.setCurrentResponseNumber(currentResponseNumber - 1)
            }
        }
        if (window.event.ctrlKey && window.event.shiftKey && e.key === "?") {
            dispatch({type: APP_DASHBOARD_ACTION_TYPE.SET_SHOW_SHORTCUTS, payload: true})

        }
    }

    // 'originalFormRespondents' is needed for QUESTION_TYPE.CHECKBOX_GRID.key only.
    const originalFormRespondents = useSelector(state => state.responsesReducer.originalFormRespondents)
    let originalQuizRespondents;

    if (formResponsesData.formData.is_quiz) {
        originalQuizRespondents = [...originalFormRespondents].reverse();
    }
    let intervalId;

    const handleDeletePopUp = (boolean) => {
        setShowDeleteAlert(boolean);
    }
    const handleResponseDelete = (contextState) => {
        setShowDeleteAlert(false)
        setIsLoading(true)
        let summaryDataSet = contextState.summaryDataSet;
        let responseId = formResponsesData.formResponses.respondents[props.currentResponseNumber - 1]._id;
        const handleUndoButtonClick = () => {
            localStorage.setItem('deletedResponse', '');
            clearInterval(intervalId);
            setToastMessage(<></>)
            const updatedFormResponsesData = {...formResponsesData}
            if (formResponsesData.formData.is_quiz) {
                props.addResponse(responseId, originalQuizRespondents);
                dispatch({
                    type: RESPONSES_ACTION_TYPE.SET_ORIGINAL_FORM_RESPONDENTS,
                    payload: JSON.parse(JSON.stringify(originalQuizRespondents))
                })
                updatedFormResponsesData.formResponses.respondents = originalQuizRespondents;
            } else {
                props.addResponse(responseId, originalFormRespondents);
                dispatch({
                    type: RESPONSES_ACTION_TYPE.SET_ORIGINAL_FORM_RESPONDENTS,
                    payload: JSON.parse(JSON.stringify(originalFormRespondents))
                })
                updatedFormResponsesData.formResponses.respondents = originalFormRespondents;
            }
            currentResponseNumber === responseCount ? props.setCurrentResponseNumber(props.currentResponseNumber + 1) : null
            contextState.updateSummaryDataSet(JSON.parse(JSON.stringify(originalSummaryDataSet)));
            dispatch({
                type: RESPONSES_ACTION_TYPE.SUMMARY_DATA_SET,
                payload: JSON.parse(JSON.stringify(originalSummaryDataSet))
            })
            dispatch({
                type: RESPONSES_ACTION_TYPE.SET_FORM_RESPONSES_DATA,
                payload: updatedFormResponsesData
            })
            props.refreshStorageTotals();
            if (responseCount >= props.maxCount) {
                dispatch({type: RESPONSES_ACTION_TYPE.SET_RESPONSE_COUNT, payload: props.maxCount})
            } else {
                dispatch({type: RESPONSES_ACTION_TYPE.SET_RESPONSE_COUNT, payload: responseCount + 1})
            }
            if (props.currentResponseNumber >= props.maxCount) {
                props.setCurrentResponseNumber(props.maxCount ? props.maxCount : 1)
            }
            const updatedFormData = formData
            updatedFormData.response_count = formData.response_count + 1
            dispatch({type: APP_ACTION_TYPE.SET_FORM_DATA, payload: updatedFormData})
        }
        const handleCloseButton = () => {
            clearInterval(intervalId);
            setToastMessage(<></>);
            localStorage.setItem('deletedResponse', '');
            if (formResponsesData.formData.is_quiz) {
                deleteQuizAnswerService(formResponsesData.formData._id, responseId, apiSuccessFunction, failureFunction)
            } else {
                deleteResponse(formResponsesData.formData._id, responseId, apiSuccessFunction, failureFunction);
            }
        }
        const successFunction = res => {
            const updatedFormData = formData
            updatedFormData.response_count = formData.response_count - 1
            dispatch({type: APP_ACTION_TYPE.SET_FORM_DATA, payload: updatedFormData})
            setIsLoading(false)
            if (responseCount === 1) {
                props.removeResponse(responseId);
                dispatch({type: RESPONSES_ACTION_TYPE.SET_RESPONSE_COUNT, payload: responseCount - 1})
                dispatch({type: APP_ACTION_TYPE.SET_CURRENT_PAGE, payload: Pages.dashboard})
                dispatch({type: APP_ACTION_TYPE.SET_IS_QUIZ, payload: summaryDataSet.isQuiz})
            } else {
                let deletedResponses = formResponsesData.formResponses?.respondents[props.currentResponseNumber - 1]?.responses

                props.removeResponse(responseId);

                contextState.updateSummaryDataSet(removeResponseInSummaryDataSet(responseId, summaryDataSet, deletedResponses, originalFormRespondents))
                let currentResponseNumber = props.currentResponseNumber;
                currentResponseNumber === responseCount ? props.setCurrentResponseNumber(props.currentResponseNumber - 1) : null

                dispatch({type: RESPONSES_ACTION_TYPE.SET_RESPONSE_COUNT, payload: responseCount - 1})

                let updatedFormRespondents = originalFormRespondents.filter(respondent => respondent._id !== responseId)

                dispatch({
                    type: RESPONSES_ACTION_TYPE.SET_ORIGINAL_FORM_RESPONDENTS,
                    payload: JSON.parse(JSON.stringify(updatedFormRespondents))
                })
            }
            props.refreshStorageTotals()
        }
        const failureFunction = err => {
            localStorage.setItem('deletedResponse', '');
            console.error('API failed', err);
        }
        const apiSuccessFunction = () => {
            clearInterval(intervalId);
            setIsLoading(false)
            setToastMessage(<></>);
            localStorage.setItem('deletedResponse', '');
        }
        if (formResponsesData.formData.is_quiz) {
            const deleteResponseFunction = () => {
                localStorage.setItem('deletedResponse', '');
                deleteQuizAnswerService(formResponsesData.formData._id, responseId, apiSuccessFunction, failureFunction)
                clearInterval(intervalId);
            }
            successFunction();
            const deletedResponse = {formId: formResponsesData.formData._id, responseId: responseId}
            localStorage.setItem('deletedResponse', JSON.stringify(deletedResponse));
            setToastMessage(
                <div className={"response-undo-toast-container"}>
                    <p className={"response-undo-message"}>1 {t(translationKeys.answer_deleted)}</p>
                    <div className={"response-undo-buttons-container"}>
                        <a href='#' onClick={handleUndoButtonClick}
                           className={"response-undo-button"}>{t(translationKeys.undo)}</a>
                        <img className="response-undo-close-icon" src={close} alt="close icon"
                             onClick={handleCloseButton}/>
                    </div>
                </div>)
            intervalId = setInterval(deleteResponseFunction, 5000);
        } else {
            const deleteResponseFunction = () => {
                localStorage.setItem('deletedResponse', '');
                deleteResponse(formResponsesData.formData._id, responseId, apiSuccessFunction, failureFunction)
                clearInterval(intervalId);
            }
            successFunction();
            const deletedResponse = {formId: formResponsesData.formData._id, responseId: responseId}
            localStorage.setItem('deletedResponse', JSON.stringify(deletedResponse));
            setToastMessage(
                <div className={"response-undo-toast-container"}>
                    <p className={"response-undo-message"}>1 {t(translationKeys.response_deleted)}</p>
                    <div className={"response-undo-buttons-container"}>
                        <a href='#' onClick={handleUndoButtonClick}
                           className={"response-undo-button"}>{t(translationKeys.undo)}</a>
                        <img className="response-undo-close-icon" src={close} alt="close icon"
                             onClick={handleCloseButton}/>
                    </div>
                </div>)
            intervalId = setInterval(deleteResponseFunction, 5000);
        }
    }


    if (isMobileOnly) {
        document.body.onresize = () => {
            setScreenHeight(window.innerHeight)
        }
    }
    const currentResponseNumber = props.currentResponseNumber;
    const showDeletePopUp = () => {
        currentResponseNumber && currentResponseNumber > 0 && (currentResponseNumber <= responseCount) ?
            setShowDeleteAlert(true) : null
    }

    return (
        <SummaryDataSet.Consumer>
            {contextState => {
                let formResponses = formResponsesData;
                const formData = formResponses?.formData;
                let answerData = formResponses?.formResponses.respondents[currentResponseNumber - 1]
                let inputData = getAnswerSheetDataFromQuizData(answerData, formData?.pages[0].questions);

                const updateMarksInQuestionsData = (marks, questionIndex) => {
                    formResponses = updateMarkInFormResponsesData(answerData._id, inputData.individualResponderQuestionWiseData[questionIndex].questionId, marks, formResponses);
                    formResponses.formResponses.respondents[currentResponseNumber - 1].quiz_data.results = calculateQuizV2Result(formData.pages[0].questions, formResponses.formResponses.respondents[currentResponseNumber - 1].responses);
                    formResponses.formData.is_publish = false;
                    dispatch({type: RESPONSES_ACTION_TYPE.SET_FORM_RESPONSES_DATA, payload: formResponses})
                    answerData = formResponsesData.formResponses.respondents[currentResponseNumber - 1]
                    inputData = getAnswerSheetDataFromQuizData(answerData, formData?.pages[0].questions);
                    setIsMarkUpdated(!isMarkUpdated)
                }
                const popUpStyle = {
                    backgroundColor: 'rgba(0, 0, 0, 0.3)',
                    height: '100%',
                    width: '100%',
                    zIndex: isMobileOnly ? 11 : 7,
                    top: '0',
                    left: '0',
                    position: 'fixed',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    margin: '0',
                    padding: '0'
                }
                const leftStickyNavBarElements = () => {
                    return <span
                        className='responded-time'
                        dir={"ltr"}>{answerData ? getDateWithTime(answerData.submit_time) : '--:--'}</span>
                }
                const rightStickyNavBarElements = () => {
                    return <MaterialButtonsContainer showDeleteFunc={showDeletePopUp}
                                                     isQuiz={formData?.is_quiz}
                                                     role={getRole(formData?.collaborators, userId)}
                                                     setShowAnswerSheetShare={setShowAnswerSheetShare}/>
                }
                return (
                    <React.Fragment>
                        <IndividualHeader responsesData={formResponses}
                                          showDeletePopUp={showDeletePopUp}
                                          showDeleteAlert={showDeleteAlert}
                                          contextState={contextState}
                                          handleDeletePopUp={handleDeletePopUp}
                                          showAnswerSheetShare={showAnswerSheetShare}
                                          setShowAnswerSheetShare={setShowAnswerSheetShare}
                                          handleResponseDelete={handleResponseDelete}
                                          currentResponseNumber={currentResponseNumber}/>
                        {isLoading ? <Loader width={"100%"} height={"100%"}/> : null}
                        <AnswerSheet isQuizEdit={true}
                                     isQuiz={formData?.is_quiz}
                                     questions={formData?.pages[0]?.questions}
                                     collaborationData={formData?.collaborators}
                                     responseCount={formData?.response_count}
                                     isQuizV2={formData?.is_quizzory_v2}
                                     updateMarksInQuestionsData={updateMarksInQuestionsData}
                                     individualResponderQuestionWiseData={inputData ? inputData.individualResponderQuestionWiseData : null}
                                     quizResults={inputData ? inputData.quizResults : null}
                                     isPublish={formData?.is_publish}
                                     ownerId={formData?.user_id}
                                     userId={userId}
                                     questionsData={formData?.pages[0]?.questions}
                                     screenHeight={screenHeight}
                                     answerData={answerData}
                                     isEvaluating={formData?.is_evaluating}
                                     showQuizCorrectAnswer={false}
                                     quizId={props.quizId}
                        />
                        {!isMobileOnly ?
                            <StickyTopNavBar bodyContainerElementRef={props.bodyContainerElementRef}
                                             leftStickyNavBarElements={leftStickyNavBarElements()}
                                             rightStickyNavBarElements={rightStickyNavBarElements()}
                                             stickyCenterTitle={formData ? formData.welcome_screen.title : ""}/> : null}
                        {isMobileOnly ?
                            <div className='answer-sheet-share-FAB'
                                 style={(selectedLanguage === 'fa' || selectedLanguage === 'ar') ? {right: '315px'} : null}>
                                <MaterialFloatingActionButton iconName={'share'}
                                                              handleClick={() => {
                                                                  if (navigator.share) {
                                                                      navigator.share({url: formData?.is_quiz ? (QUIZ_BASE_URL + "/answer-sheet/" + answerData._id) : "/view-response/" + answerData._id})
                                                                  }
                                                              }
                                                              }/>
                            </div>
                            : null}
                        {showAnswerSheetShare ? <div style={popUpStyle}>
                            <ShareCard isQuiz={formData?.is_quiz}
                                       formId={answerData._id}
                                       isAnswerSheetLink={true}
                                       isShowSharePopUp={setShowAnswerSheetShare}
                                       passcode={formData?.setting?.passcode}
                            />
                        </div> : null}
                        {toastMessage}
                    </React.Fragment>
                )
            }}
        </SummaryDataSet.Consumer>
    )

}

export default IndividualResponseContent

const MaterialButtonsContainer = props => {
    return (
        <div className='individual-buttons-container'>
            {!isMobileOnly ?
                <MaterialButton data={{
                    title: isMobileOnly ? '' : t(translationKeys.share), imageFileName: 'share_icon_white.svg',
                    bg_color: '#004ba0',
                    customButtonContainerStyle: {
                        minWidth: '0',
                        padding: '0 10px 0 15px'
                    }, customImageStyle: {
                        marginRight: (isMobileOnly ? '0' : '8px')
                    }
                }} handleClick={() => props.setShowAnswerSheetShare(true)}/> : null}
            <div style={{padding: isMobileOnly ? '0 5px' : '0 15px'}}>
                <MaterialButton data={{
                    title: isMobileOnly ? '' : t(translationKeys.print), imageFileName: 'print_icon.svg',
                    bg_color: '#004ba0',
                    customButtonContainerStyle: {
                        minWidth: '0',
                        padding: '0 10px 0 15px'
                    }, customImageStyle: {
                        marginRight: (isMobileOnly ? '0' : '8px')
                    }
                }} handleClick={() => window.print()}/>
            </div>
            {props.role === COLLABORATOR_ROLES.VIEWER || props.role === COLLABORATOR_ROLES.EDITOR || !props.showDeleteButton ? null :
                <div>
                    <MaterialButton data={{
                        title: t(translationKeys.delete), bg_color: '#004ba0', imageFileName: 'delete_white.svg',
                        customButtonContainerStyle: {
                            padding: '0 10px'
                        }
                    }} handleClick={() => props.showDeleteFunc()}
                    />
                </div>}
        </div>
    )
}

const IndividualHeader = props => {
    const formData = props.responsesData?.formData;
    const currentResponseData = props.responsesData?.formResponses.respondents[props.currentResponseNumber - 1];
    const currentResponderInfo = currentResponseData && currentResponseData.quiz_data ? currentResponseData.quiz_data.user_info : currentResponseData?.form_user_data ? currentResponseData.form_user_data : null;
    const quizUserInfo = formData?.quiz_data ? formData?.quiz_data.user_info : formData?.user_info_form ? formData?.user_info_form : null;
    const userId = useSelector(state => state.userReducer?.userId)
    const selectedLanguage = useSelector(state => state.languageReducer.selectedLanguage)
    const collaboratorsList = useSelector(state => state.collaboratorsData?.collaborators)

    return <div className='individual-response-header' style={formData?.is_quiz ? {height: 'auto'} : null}>
        <div className='response-count-container' style={{display: "block"}}>
            {currentResponseData && (formData?.is_quiz || formData?.user_info_form || formData?.setting?.is_email) ?
                <div className='individual-quiz-header' style={{width: '100%'}}>
                    {formData?.setting?.is_email ?
                        <div style={{width: '100%', display: 'flex'}}>
                            <span style={{maxWidth: '40%'}} className='user-info-title'>
                                {t(translationKeys.email)}
                            </span>
                            <span style={{maxWidth: '60%'}} className='user-info-title'>
                                {':  ' + currentResponseData.responder_email_id}
                            </span>
                        </div> : null}
                    {quizUserInfo?.user_info_1 ?
                        <div style={{width: '100%', display: 'flex'}}>
                            <span style={{maxWidth: '40%'}} className='user-info-title'>
                                {quizUserInfo.user_info_1}
                            </span>
                            <span style={{maxWidth: '60%'}} className='user-info-title'>
                                {':  ' + (currentResponderInfo?.user_info_1 ? currentResponderInfo.user_info_1 : ' -')}
                            </span>
                        </div> : null}
                    {quizUserInfo?.user_info_2 ?
                        <div style={{width: '100%', display: 'flex'}}>
                            <span style={{maxWidth: '40%'}} className='user-info-title'>
                                {quizUserInfo.user_info_2}
                            </span>
                            <span style={{maxWidth: '60%'}} className='user-info-title'>
                                {':  ' + (currentResponderInfo.user_info_2 ? currentResponderInfo.user_info_2 : ' -')}
                            </span>
                        </div> : null}
                    {quizUserInfo?.user_info_3 ?
                        <div style={{width: '100%', display: 'flex'}}>
                            <span style={{maxWidth: '40%'}} className='user-info-title'>
                                {quizUserInfo.user_info_3}
                            </span>
                            <span style={{maxWidth: '60%'}} className='user-info-title'>
                                {':  ' + (currentResponderInfo.user_info_3 ? currentResponderInfo.user_info_3 : ' -')}
                            </span>
                        </div> : null}
                </div>
                : null
            }
            {!formData?.is_quiz ? <span className='responded-time'
                                        dir={(selectedLanguage === 'fa' || selectedLanguage === 'ar') ? "ltr" : null}>
                    {currentResponseData ? getDateWithTime(currentResponseData.submit_time) : '--:--'}
                </span> : null}
        </div>
        <div className='right-aligned-container'
             style={formData?.is_quiz ? {alignItems: 'flex-end', justifyContent: 'space-between'} : null}>
            <MaterialButtonsContainer showDeleteFunc={props.showDeletePopUp} isQuiz={formData?.is_quiz}
                                      showDeleteButton={formData.response_count === props.responsesData?.formResponses?.respondents?.length}
                                      role={getRole(collaboratorsList, userId)}
                                      setShowAnswerSheetShare={props.setShowAnswerSheetShare}/>
            {formData?.is_quiz ?
                <span className='responded-time'
                      dir={(selectedLanguage === 'fa' || selectedLanguage === 'ar') ? "ltr" : null}>
                    {currentResponseData ? getDateWithTime(currentResponseData.submit_time) : '--:--'}
                </span> : null}
            {props.showDeleteAlert ?
                <ModalDialog
                    header={t(translationKeys.delete) + ' ' + (formData?.is_quiz ? t(translationKeys.answer_s) : t(translationKeys.response))}
                    body={[(formData?.is_quiz ? t(translationKeys.delete_answer_alert) : t(translationKeys.delete_response_alert))]}
                    buttons={
                        [
                            {
                                text: t(translationKeys.cancel).toUpperCase(),
                                action: () => props.handleDeletePopUp(false)
                            },
                            {
                                text: t(translationKeys.delete).toUpperCase(),
                                action: () => {
                                    props.handleResponseDelete(props.contextState)
                                }
                            }
                        ]
                    }
                /> : null}
        </div>
    </div>
}
