import React, {useRef} from "react";
import {createBarChart, getBgColors} from '../utils/graph'
import {isMobileOnly} from "react-device-detect";
import '../css/graph-legend.css'

const BarChartContainer = (props) => {
    const refCanvas = useRef(null)

    const callChartLib = (canvasElement, trimmedGraphLabels) => {
        if (canvasElement) {
            createBarChart(trimmedGraphLabels,
                props.graphData, canvasElement, refCanvas.current);
        }
    }

    let graphData = props.graphData;
    let height = !isMobileOnly ? (graphData.length > 3) ?
            (graphData.length * 70) + "px" : "210px" :
        (graphData.length > 3) ?
            (graphData.length * 60) + "px" : "180px"
    let trimmedGraphLabels = props.graphLabels.map(label => {
        if (isMobileOnly) {
            if (label.length > 9) {
                label = label.substring(0, 8) + '...';
                return label
            } else return label;
        } else {
            if (label.length > 15) {
                label = label.substring(0, 14) + '...';
                return label
            } else return label;
        }
    });
    const bgColors = getBgColors(trimmedGraphLabels.length)

    return (
        <div className='chart-holder'>
            <div className='legend-container'>
                {trimmedGraphLabels.map((label, i) => {
                    return <LegendLabel key={i} labelText={label} legendColor={bgColors[i]}/>
                })}
            </div>
            <div className='bar-chart-scroll' onScroll={e => e.preventDefault()}
                 style={height ? {height: height} : null} ref={e => {
                callChartLib(e, trimmedGraphLabels)
            }}>
                <canvas style={{width: '100%', height: '100%'}} ref={refCanvas}/>
            </div>
        </div>
    );
}

export default BarChartContainer

const LegendLabel = (props) => {
    return (
        <div className='individual-label-container'>
            <div className='legend-box' style={{backgroundColor: props.legendColor}}/>
            <span className='label-text'>{props.labelText}</span>
        </div>

    )
}