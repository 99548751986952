import React, {useEffect, useRef, useState} from "react"
import smile_worst_filled_small from "../../../../public/images/smile_worst_filled_small.svg"
import smile_dislike_filled_small from "../../../../public/images/smile_dislike_filled_small.svg"
import smile_neutral_filled_small from "../../../../public/images/smile_neutral_filled_small.svg"
import smile_like_filled_small from "../../../../public/images/smile_like_filled_small.svg"
import smile_best_filled_small from "../../../../public/images/smile_best_filled_small.svg"
import continueButton from "../../../../public/images/icons/builder/undo_button.svg";
import submitButton from "../../../../public/images/icons/builder/submit_gray_tick_mark.svg";
import MaterialTextField from "../../../shared/components/MaterialTextField"
import {t} from "i18next"
import {translationKeys} from "../../../localizations/translationKeys-localization"
import {APP_ACTION_TYPE} from "../../reducers/appReducer"
import {useDispatch, useSelector} from "react-redux"
import MaterialSwitch from "../../../shared/components/MaterialSwitch";
import {QUESTION_TYPE} from "../../../shared/utils/constants";
import useQuestionMgr from "../../hooks/useQuestionMgr";
import useLogicJumpMgr from "../../hooks/useLogicJumpMgr";
import {BUILDER_ACTION_TYPE} from "../../reducers/builderReducer";
import {isDesktop} from "react-device-detect";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import sectionIcon from "../../../../public/images/icons/builder/section_black_icon.svg";
import {PremiumJSON} from "../../../shared/utils/helper";
import {PREMIUM_ACTION_TYPE} from "../../reducers/premiumReducer";


// NOTE: The SmileBody Component is used for Mobile And Desktop Views. Therefore, we don't have a 'useSmileMgr' custom hook.
const SmileBody = (props) => {
    const reduxDispatch = useDispatch()
    const formData = useSelector(state => state.appReducer.formData)
    const question = useSelector(state => state.appReducer.formData?.pages?.[0]?.questions?.[props.index])
    const subscriptionData = useSelector(state => state.userReducer?.subscriptionData)
    const showShared = useSelector(state => state.appDashboardReducer.showShared)
    const showLogicJumpBasedOnUser = showShared ? PremiumJSON[subscriptionData?.subscription_plan]?.logic_jump && PremiumJSON[formData?.subscription_data?.current_subscription?.plan]?.logic_jump : PremiumJSON[subscriptionData?.subscription_plan]?.logic_jump
    const [logicEnabled, setLogicEnabled] = useState(false);
    const [selectedQuestions, setSelectedQuestions] = useState([]);
    const {questionRequiredChanged, questionOtherOptionChanged, questionOtherOptionDisabled} = useQuestionMgr()
    const {addLogicJumpToQuestion, addTargetQuestionId, generateUniqueString, addLogicQuestionId} = useLogicJumpMgr()


    useEffect(() => {
        setLogicEnabled(props.question.is_logic_enabled);
    }, [props.question.is_logic_enabled]);
    const handleChange = (smileIndex, value) => {
        formData.pages[0].questions[props.index].choices[smileIndex].label = value
        reduxDispatch({type: APP_ACTION_TYPE.SET_FORM_DATA, payload: formData})
    }
    const handleBlur = (smileIndex, value, smileInputRef) => {
        if (value === '') {
            let defaultValue

            switch (smileIndex) {
                case 0:
                    defaultValue = t(translationKeys.worst)
                    break
                case 1:
                    defaultValue = t(translationKeys.dislike)
                    break
                case 2:
                    defaultValue = t(translationKeys.neutral)
                    break
                case 3:
                    defaultValue = t(translationKeys.like)
                    break
                case 4:
                    defaultValue = t(translationKeys.best)
                    break
            }

            smileInputRef.current.value = defaultValue
            formData.pages[0].questions[props.index].choices[smileIndex].label = defaultValue
            reduxDispatch({type: APP_ACTION_TYPE.SET_FORM_DATA, payload: formData})
        }
    }

    const handleJumpToQuestion = (choiceIndex, targetQuestionIndex) => {
        const newSelectedValues = [...selectedQuestions];
        newSelectedValues[choiceIndex] = targetQuestionIndex;
        setSelectedQuestions(newSelectedValues);
        if (targetQuestionIndex === "0" || targetQuestionIndex === "-1") {
            addLogicQuestionId(props.index, choiceIndex, targetQuestionIndex)
        }
        else if (props.formData?.pages[0]?.questions[targetQuestionIndex]?.logic_question_id) {
            addLogicQuestionId(props.index, choiceIndex, props.formData.pages[0].questions[targetQuestionIndex].logic_question_id)
        }
        else {
            const uniqueString = generateUniqueString();
            addTargetQuestionId(targetQuestionIndex, uniqueString);
            addLogicQuestionId(props.index, choiceIndex, uniqueString)
        }
    }

    const smileRow = (index) => {
        let imageToDisplay
        const label = question?.choices[index].label
        const smileInputRef = useRef(null);

        switch (index) {
            case 0:
                imageToDisplay = smile_worst_filled_small
                break
            case 1:
                imageToDisplay = smile_dislike_filled_small
                break
            case 2:
                imageToDisplay = smile_neutral_filled_small
                break
            case 3:
                imageToDisplay = smile_like_filled_small
                break
            case 4:
                imageToDisplay = smile_best_filled_small
                break
        }

        return <div style={{display: "flex", flexBasis: "50%"}} key={index}>
            <img key={index} alt={`Smile ${index + 1}`} src={imageToDisplay}
                 style={{paddingRight: '5px'}}/>
            <div className='sh-text-field' style={{marginBottom: '5px'}}>
                <MaterialTextField
                    id={`smile-question-text-input-${props.index}-${index}`}
                    data={{
                        defaultValue: label
                    }}
                    inputRef={smileInputRef}
                    onChange={value => handleChange(index, value)}
                    onBlur={value => handleBlur(index, value, smileInputRef)}
                    maxLength={100}
                    noLabel={true}
                    hideHelpLine={true}
                />
            </div>
        </div>
    }

    const addLogicForChoiceQuestion = (index, checkedStatus) => {
        questionRequiredChanged(index, checkedStatus)
        questionOtherOptionChanged(index, checkedStatus)
        questionOtherOptionDisabled(index, checkedStatus)
        addLogicJumpToQuestion(index, checkedStatus)
        setLogicEnabled(checkedStatus)
    }

    const disabledSwitchClicked = () => {
        if (!showLogicJumpBasedOnUser) {
            if (showShared && !PremiumJSON[formData?.subscription_data?.current_subscription?.plan]?.logic_jump) {
                reduxDispatch({type: PREMIUM_ACTION_TYPE.SET_SHOW_FREE_OWNER_POP_UP, payload: true})
            } else {
                reduxDispatch({type: PREMIUM_ACTION_TYPE.SET_SHOW_UPGRADE_PLAN_POP_UP, payload: true})
            }
        } else if (formData.pages[0].questions.length <= 1 || formData.pages[0].questions.length - 1 === props.index) {
            reduxDispatch({type: BUILDER_ACTION_TYPE.SET_BUILDER_MESSAGE, payload: 'Action cannot be performed'});
            reduxDispatch({type: BUILDER_ACTION_TYPE.SET_BUILDER_TOAST_MESSAGE, payload: true})
        }
    }

    return (
        <>
            <div style={{display: "flex", flexDirection: "column"}}>
                {
                    [0, 1, 2, 3, 4].map((choiceIndex) => (
                        (() => {
                            let questionNumber = 1;
                            return (
                                <div className={`${logicEnabled ? 'smile-body-row-container' : ''}`}>
                                    {smileRow(choiceIndex)}
                                    {(logicEnabled && showLogicJumpBasedOnUser && isDesktop) && <div className={"logic-jump-to-question-container"}>
                                        <p>Jump to</p>
                                        <div className={'choice-body-add-logic-dropdown-container'}>
                                            <FormControl variant="standard"
                                                         sx={{m: 1, minWidth: 220, margin: 0, width: '100%'}}>
                                                <Select
                                                    labelId={`select-question-helper-label-${choiceIndex}`}
                                                    id={`select-question-helper-${choiceIndex}`}
                                                    label="Select Question"
                                                    MenuProps={{
                                                        style: {width: '280px'}, // to Set the width of the dropdown menu
                                                    }}
                                                    value={selectedQuestions[choiceIndex] || (props?.question?.choices[choiceIndex]?.target_question_id !== ("0" || "-1") ? props?.formData?.pages[0]?.questions.findIndex((question) => props?.question?.choices[choiceIndex]?.target_question_id === question.logic_question_id)  : props?.question?.choices[choiceIndex]?.target_question_id) }
                                                    onChange={(e) => handleJumpToQuestion(choiceIndex, e.target.value)}
                                                >
                                                    <MenuItem value="0"><span
                                                        className={'logic-jump-question-to-select'}><img
                                                        src={continueButton} alt={'continue to next question icon'}/> {t(translationKeys.continue_to_next_question)}</span></MenuItem>
                                                    <MenuItem value="-1"><span
                                                        className={'logic-jump-question-to-select'}><img
                                                        src={submitButton}
                                                        alt={'submit form  icon'}/> {t(translationKeys.submit_form)}</span></MenuItem>
                                                    {props.formData.pages[0].questions.map((object, index) => {
                                                        if (object.type === 'SECTION') {
                                                            return (
                                                                index > props.index &&
                                                                (<MenuItem value={index}>
                                                                    <span className={'logic-jump-question-to-select'}><img
                                                                    src={sectionIcon}
                                                                    alt={'section icon'}/> {object.title}</span>
                                                                </MenuItem>))
                                                        } else {
                                                            const questionContent = `${questionNumber}. ${object.title}`;
                                                            questionNumber++;
                                                            return (
                                                                index > props.index &&
                                                                (<MenuItem value={index}> <span
                                                                    className={'logic-jump-question-to-select'}>{questionContent}</span></MenuItem>))
                                                        }
                                                    })}
                                                </Select>
                                            </FormControl>
                                        </div>
                                    </div>}
                                </div>)
                            })()
                    ))
                }
            </div>
            <div style={{display: "flex", flexGrow: "1", paddingTop: '20px'}}>
                <div id={'choice-body-add-logic-option'} className="choice-body-add-logic-jump-option">
                    {t(translationKeys.add_logic)}
                </div>
                <div onClick={disabledSwitchClicked} style={{cursor: "pointer"}}>
                    <MaterialSwitch
                        index={props.index}
                        initialCheckedState={props.question.is_logic_enabled}
                        switchChanged={addLogicForChoiceQuestion}
                        customStyle={(props.formData?.pages[0].questions.length <= 1 || props.formData?.pages[0].questions.length - 1 === props.index) || (QUESTION_TYPE[props.question.type] === QUESTION_TYPE.CHECKBOX_CHOICE) ? {cursor: 'not-allowed'} : null}
                        isSwitchDisabled={(props.formData?.pages[0].questions.length <= 1 || props.formData?.pages[0].questions.length - 1 === props.index) || (QUESTION_TYPE[props.question.type] === QUESTION_TYPE.CHECKBOX_CHOICE)}
                    />
                </div>
            </div>
        </>
    )
}

export default SmileBody