export const isAllQuestionsEvaluated = (responses) => {
    let isAllQuestionEvaluated = true;
    for (let i = 0; i < responses.length; i++) {
        if (responses[i].marks === undefined) {
            isAllQuestionEvaluated = false
        }
    }
    return isAllQuestionEvaluated
}

export const getPendingEvaluationRespondentsIndex = respondentsData => {
    let pendingRespondersIndex = [];
    for (let i = 0; i < respondentsData.length; i++) {
        if (isAllQuestionsEvaluated(respondentsData[i].responses) === false) {
            pendingRespondersIndex.push(i)
        }
    }
    return pendingRespondersIndex
}