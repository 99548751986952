import React, {useEffect, useRef, useState} from 'react';
import '../../shared/css/material-card.css'
import styles from '../css/question-component.module.css'
import {THEME_TYPE} from "../utils/utils";
import QuestionAttachmentComponent from "./questions/QuestionAttachmentComponent";
import FormQuestionBody from "./FormQuestionBody";
import {isDarkTheme, isImageTheme} from "../utils/theme-color-helper";
import {QUESTION_TYPE} from "../../shared/utils/constants";
import {isMobileOnly} from "react-device-detect";
import {useDispatch, useSelector} from "react-redux";
import {QUESTIONS_ACTION_TYPE} from "../reducers/questionsReducer";
import {FORM_DATA_ACTION_TYPE} from "../reducers/formDataReducer";
import ImageViewerComponent from "./questions/ImageViewerComponent";

export const QuestionComponent = props => {
    const formData = useSelector(state => state.formDataReducer?.formData)
    const themeColor = useSelector(state => state.themeReducer?.themeColor)
    const questions = useSelector(state => state.questionsReducer?.questions)
    const imageViewer = useSelector(state => state.screensReducer?.imageViewerAttachment)
    const questionComponentList = useSelector(state => state.questionsReducer.questionComponentList)
    const [showFullDesc, setShowFullDesc] = useState(false)
    const [showOptions, setShowOptions] = useState(false)
    const [infoTag, setInfoTag] = useState({
        is_show: props.questionData.is_required,
        text: "Required",
        textColor: isDarkTheme(formData?.theme) ? '#fff' : '#212121',
        backgroundColor: isDarkTheme(formData?.theme) ? '#000' : '#e8e8e8'
    });
    const questionComponentRef = useRef();
    const dispatch = useDispatch()

    useEffect(() => {
        if (questionComponentRef) {
            if (props.questionData.is_required) {
                const updatedQuestionComponentList = questionComponentList
                updatedQuestionComponentList.push({
                    question_id: props.questionData._id,
                    questionComponentRef: questionComponentRef,
                    setInfoTag: setInfoTag
                });
                dispatch({
                    type: QUESTIONS_ACTION_TYPE.SET_QUESTION_COMPONENT_LIST,
                    payload: updatedQuestionComponentList
                })
            }
        }
    }, [props.questionData.is_required]);

    useEffect(() => {
        if (props.index === questions.length - 1) {
            dispatch({type: FORM_DATA_ACTION_TYPE.SET_LOADING, payload: false})
        }
    }, [])

    const questionComponentColor = (() => {
        let titleColor = "#fff";
        let cardBackgroundColor = "#fff";
        if (formData?.theme === THEME_TYPE.CLASSIC) {
            titleColor = "#000"
        }
        if (isDarkTheme(formData?.theme)) {
            cardBackgroundColor = "#212121"
        }
        let titleBackgroundColor = isImageTheme(formData?.theme) ? themeColor?.secondaryColor : 'transparent';
        let titlePadding = isImageTheme(formData?.theme) ? null : '24px 10px 0 24px';
        return {
            titleColor: titleColor,
            titleBackgroundColor: titleBackgroundColor,
            cardBackgroundColor: cardBackgroundColor,
            titlePadding: titlePadding
        };
    })()

    return (
        <div id={'question-card-' + props.index} ref={questionComponentRef} className="mdc-card"
             onMouseLeave={() => setShowOptions(false)}
             style={{
                 backgroundColor: questionComponentColor.cardBackgroundColor,
                 boxShadow: (formData?.theme !== THEME_TYPE.DARK)
                     ? 'rgba(0, 0, 0, 0.2) 0px 8px 10px 1px' : '',
                 margin: '8px 16px 16px',
                 borderRadius: '0'
             }}>
            <div id={'card-content-' + props.index} className={styles.card_content}
                 style={{
                     backgroundColor: questionComponentColor.titleBackgroundColor,
                     padding: questionComponentColor.titlePadding,
                     display: props.previewType ? 'flex' : 'grid',
                     flexDirection: 'column',
                     justifyContent: 'space-between'
                 }}>
                <span className={styles.card_title}
                      style={{color: questionComponentColor.titleColor, minWidth: props.previewType ? "auto" : isMobileOnly ? '80vw' : '725px'}}>
                    {props.questionIndex ? props.questionIndex + '. ' + props.questionData.title : props.questionData.title}
                </span>
                <span className={styles.card_description}
                      style={{color: questionComponentColor.titleColor, minWidth: props.previewType ? "auto" : isMobileOnly ? '80vw' : '725px'}}>
                    {props.questionData.description.length > (isMobileOnly ? 120 : 330) && !showFullDesc ? props.questionData.description.substring(0, (isMobileOnly ? 120 : 330)) + '...' : props.questionData.description}
                    {props.questionData.description.length > (isMobileOnly ? 120 : 330) ? <span style={{color: "#1976D2", cursor: "pointer"}}
                                                                         onClick={() => setShowFullDesc(!showFullDesc)}>{showFullDesc ? " Show less" : " Show more"}</span> : null}
                </span>
                {formData?.setting.show_question_marks ? <span style={{
                    color: questionComponentColor.titleColor,
                    fontSize: '15px',
                    paddingTop: '2px',
                    textAlign: 'right',
                    width: props.previewType ? '100%' : null,
                    display: props.previewType ? 'inline-block' : null
                }}>({props.questionData.marks}{props.questionData.marks > 1 ? ' Marks' : ' Mark'})</span> : null}
            </div>
            <div className={styles.card_action}
                 style={{padding: ((props.questionData.type === QUESTION_TYPE.LINEAR_SCALE.key) && props.previewType) ? "12px 5px 24px 5px" : null}}
                 id={"card-action-" + props.index}>
                <QuestionAttachmentComponent attachment={props.questionData.attachment}
                                             backgroundColorProvided={true}
                                             previewType={props.previewType}/>
                <FormQuestionBody {...props}
                                  color={isDarkTheme(formData?.theme) ? 'black' : isImageTheme(formData?.theme) ? questionComponentColor.titleBackgroundColor : null}
                                  showOptions={showOptions} setShowOptions={setShowOptions} setInfoTag={setInfoTag}/>
                {(() => {
                    if (props.questionData.is_required) {
                        return (
                            <div className={styles.chip} style={{
                                backgroundColor: infoTag.backgroundColor,
                                color: infoTag.textColor,
                                display: 'inline-block'
                            }}> {infoTag.text}</div>
                        )
                    }
                })()}
                {(() => {
                    if (!props.questionData.is_required && (props.questionData.type === QUESTION_TYPE.EMAIL.key) && infoTag.text === 'Invalid email address') {
                        return (
                            <div className={styles.chip} style={{
                                backgroundColor: infoTag.backgroundColor,
                                color: infoTag.textColor,
                                display: 'inline-block'
                            }}> {infoTag.text}</div>
                        )
                    }
                })()}
            </div>
            {imageViewer ? <ImageViewerComponent/> : null}
        </div>
    );
}

