import React from 'react';
import styles from "../css/form-theme-image.module.css"
import {isImageTheme} from "../utils/theme-color-helper";
import {useSelector} from "react-redux";
import {FORM_SCREEN} from "../utils/utils";

export default function FormTheme(props) {
    const formData = useSelector(state => state.formDataReducer.formData)
    const formScreen = useSelector(state => state.screensReducer.formScreen)
    const themeImageBlur = useSelector(state => state.themeReducer.themeImageBlur)
    if (isImageTheme(formData?.theme)) {
        return (
            <div id={'form-theme'} className={styles.formsapp_bg_image} style={{
                zIndex: !props.previewType ? -1 : null,
                filter: formScreen === FORM_SCREEN.FORM_EDIT_RESPONSE || formScreen === FORM_SCREEN.COLLECT_EMAIL_PAGE ? "brightness(0.5)" : null
            }}>
                <img src={props.formThemeImageSrc} style={{
                    filter: `blur(${themeImageBlur}px)`,
                    WebkitFilter: `blur(${themeImageBlur}px)`
                }} alt={'theme'}/>
            </div>
        );
    }
    return null;
}
