import React from 'react';
import {isMobileOnly} from "react-device-detect";
import {getDateWithTime} from "../../../shared/utils/get-date-with-time";

export default function ResponsesFormTitleContainer (props) {
    return (
        <div className='form-title-container' style={isMobileOnly ? {position: 'relative', height: '30px'} : null}>
            <span id='response-form-title' style={{margin: '0 10px'}}>{props.title ? props.title : ""}</span>
            <span id='response-form-title-date'  className='printing-title'> - {getDateWithTime((new Date()).getTime())}</span>
        </div>
    )
}