import React, {useRef} from "react";
import {
    getColumnDataSetsForStackedBarChartForCheckboxGrid,
    getColumnDataSetsForStackedBarChartForMultipleChoiceGrid, displayStackedBarChart,
    prepareStackedBarChart
} from '../utils/graph'
import '../css/graph-legend.css'
import {QUESTION_TYPE} from "../../../shared/utils/constants"
import {isMobileOnly} from "react-device-detect";

const StackedBarChartContainer = (props) => {
    const refCanvas = useRef(null)
    const originalFormRespondents = props.originalFormRespondents
    let graphData = props.graphData;

    const callChartLib = (canvasElement) => {
        if (canvasElement) {
            if (props.graphData.type === QUESTION_TYPE.MULTIPLE_CHOICE_GRID.key) {
                let {stackedBarChartElement, columnDataSets} = prepareStackedBarChart(refCanvas.current, graphData)
                columnDataSets = graphData.grid_choices ? getColumnDataSetsForStackedBarChartForMultipleChoiceGrid(graphData, columnDataSets) : null
                displayStackedBarChart(graphData, columnDataSets, stackedBarChartElement)

            } else if (props.graphData.type === QUESTION_TYPE.CHECKBOX_GRID.key) {
                let {stackedBarChartElement, columnDataSets} = prepareStackedBarChart(refCanvas.current, graphData)
                columnDataSets = getColumnDataSetsForStackedBarChartForCheckboxGrid(graphData, columnDataSets, originalFormRespondents)
                displayStackedBarChart(graphData, columnDataSets, stackedBarChartElement)
            }
        }
    }

    let height = (graphData.responseAnalyzeData.rowGraphLabels?.length * (isMobileOnly ? 50 : 100)) + "px"

    return (
        <div className='chart-holder'>
            <div className='bar-chart-scroll' onScroll={e => e.preventDefault()}
                 style={height ? {minHeight: height} : null} ref={e => {
                callChartLib(e)
            }}>
                <canvas style={{width: '100%', height: '100%'}} ref={refCanvas}/>
            </div>
        </div>
    );
}

export default StackedBarChartContainer