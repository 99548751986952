import {isAllQuestionsEvaluated} from "./evaluation-helper";
import {translationKeys} from "../../../localizations/translationKeys-localization";
import {t} from "i18next";

export const RESPONDENT_SORTING_FIELD = {
    ALPHABET: t(translationKeys.alphabet),
    SUBMIT_TIME: t(translationKeys.submit_time),
    NOT_EVALUATED: t(translationKeys.not_evaluated),
    EVALUATED: t(translationKeys.evaluated),
    PERCENTAGE_ASCENDING: t(translationKeys.percentage_ascending),
    PERCENTAGE_DESCENDING: t(translationKeys.percentage_descending),
}

export function sortRespondents(respondentList, sortOrder) {
    if (respondentList && sortOrder) {
        switch (sortOrder) {
            case RESPONDENT_SORTING_FIELD.ALPHABET:
                respondentList.sort(ascUserNamePropComparer);
                break;
            case RESPONDENT_SORTING_FIELD.SUBMIT_TIME:
                respondentList.sort(descPropComparer('submit_time'));
                break;
            case RESPONDENT_SORTING_FIELD.NOT_EVALUATED:
                respondentList.sort(ascUserNamePropComparer);
                respondentList.sort(prioritizeUnEvaluatedResponders);
                break;
            case RESPONDENT_SORTING_FIELD.EVALUATED:
                respondentList.sort(ascUserNamePropComparer);
                respondentList.sort(prioritizeEvaluatedResponders);
                break;
            case RESPONDENT_SORTING_FIELD.PERCENTAGE_ASCENDING:
                respondentList.sort(ascPercentagePropComparer);
                break;
            case RESPONDENT_SORTING_FIELD.PERCENTAGE_DESCENDING:
                respondentList.sort(descPercentagePropComparer);
                break;
        }
    }
    return respondentList;
}

const descPropComparer = (propName) => (a, b) => a[propName] === b[propName] ? 0 : a[propName] < b[propName] ? 1 : -1;

function ascUserNamePropComparer(a, b) {
    const a1 = a.quiz_data.user_info['user_info_1'].trim();
    const b1 = b.quiz_data.user_info['user_info_1'].trim();
    return ('' + a1).localeCompare(b1);
}

const prioritizeUnEvaluatedResponders = (a, b) => {
    const a1 = isAllQuestionsEvaluated(a.responses);
    const b1 = isAllQuestionsEvaluated(b.responses);
    return a1 === b1 ? 0 : a1 > b1 ? 1 : -1;
}

const prioritizeEvaluatedResponders = (a, b) => {
    const a1 = !isAllQuestionsEvaluated(a.responses);
    const b1 = !isAllQuestionsEvaluated(b.responses);
    return a1 === b1 ? 0 : a1 > b1 ? 1 : -1;
}

const ascPercentagePropComparer = (a, b) => {
    let a1 = parseFloat(a.quiz_data.results['correct_percentage']);
    let b1 = parseFloat(b.quiz_data.results['correct_percentage']);
    return a1 === b1 ? 0 : a1 > b1 ? 1 : -1
}

const descPercentagePropComparer = (a, b) => {
    let a1 = parseFloat(a.quiz_data.results['correct_percentage']);
    let b1 = parseFloat(b.quiz_data.results['correct_percentage']);
    return a1 === b1 ? 0 : a1 < b1 ? 1 : -1
}

