import React, {useState} from 'react'
import down from '../../../../public/images/down_gray.svg'
import up from '../../../../public/images/up_gray.svg'
import SettingsControlQuestionContainer from "./SettingsControlQuestionsContainer";
import SettingsQuizControlResultContainer from "./SettingsQuizControlResultContainer";
import SettingsQuizControlGeneralContainer from "./SettingsQuizControlGeneralContainer";
import {translationKeys} from "../../../localizations/translationKeys-localization";
import {useSelector} from "react-redux";
import {t} from "i18next";
import {isMobileOnly} from "react-device-detect";
import SettingsQuizControlContainer from "./SettingsQuizControlContainer";
import SettingsQuizResultContainer from "./SettingsQuizResultContainer";
import SettingsQuizQuestionsContainer from "./SettingsQuizQuestionsContainer";
import SettingScheduleForqStatus from "./SettingsScheduleForqStatus";
import SettingMobileScheduleForqStatus from "./SettingsMobileScheduleForqStatus";

const CLICK_TYPE = {
    GENERAL: 'general',
    RESULTS: 'results',
    QUESTIONS: 'questions',
    SCHEDULE_FORM_STATUS: 'schedule_form_status'
}

const SettingsMobileQuizControlContainer = (props) => {
    const formData = useSelector(state => state.appReducer.formData)
    const formId = useSelector(state => state.appReducer.formData?._id)
    const [showGeneral, setShowGeneral] = useState(formId ? !formId : !props.formId)
    const [showResults, setShowResults] = useState(formId ? !formId : !props.formId)
    const [showQuestions, setShowQuestions] = useState(formId ? !formId : !props.formId)
    const [showScheduleFormStatus, setShowScheduleFormStatus] = useState(formId ? !formId : !props.formId)

    const handleClick = (type) => {
        if (type === CLICK_TYPE.QUESTIONS) {
            setShowQuestions(!showQuestions)
        } else if (type === CLICK_TYPE.RESULTS) {
            setShowResults(!showResults)
        } else if (type === CLICK_TYPE.GENERAL) {
            setShowGeneral(!showGeneral)
        } else if (type === CLICK_TYPE.SCHEDULE_FORM_STATUS) {
            setShowScheduleFormStatus(!showScheduleFormStatus)
        }
    }

    return (
        <>
            <div id={'settings-mobile-quiz-control'} className={showGeneral ? null : "hover-controls"}
                 style={{margin: '0 20px'}}
                 onClick={() => {
                     if (!showGeneral) {
                         handleClick(CLICK_TYPE.GENERAL)
                     }
                 }}>
                <div id={'settings-general'} className={'row-title'}
                     style={{display: 'flex', justifyContent: 'space-between'}}>
                    <h3 style={{
                        color: '#004BA0',
                        fontSize: '17px',
                        margin: '15px 15px 10px 0px'
                    }}>{t(translationKeys.general)}</h3>
                    <img alt={'dropdown'} src={showGeneral ? up : down} style={{cursor: "pointer"}}
                         onClick={() => handleClick(CLICK_TYPE.GENERAL)}/>
                </div>
                {!showGeneral ? <div className={'row-description'}>
                    <span id={'settings-user-info-duration'}
                          style={{fontSize: '14px'}}>{t(translationKeys.user_info)}, {t(translationKeys.duration)}, {t(translationKeys.allow_multiple_attempts)}, {t(translationKeys.collect_email)}</span>
                </div> : <>
                    {isMobileOnly ? <SettingsQuizControlGeneralContainer {...props}/> :
                        <SettingsQuizControlContainer key='SettingsQuizControlContainer' {...props}/>}
                </>
                }
            </div>
            <div className={showResults ? null : "hover-controls"} style={{margin: '0 20px'}}
                 onClick={() => {
                     if (!showResults) {
                         handleClick(CLICK_TYPE.RESULTS)
                     }
                 }}>
                <div className={'row-title'} style={{display: 'flex', justifyContent: 'space-between'}}>
                    <h3 style={{
                        color: '#004BA0',
                        fontSize: '17px',
                        margin: '15px 15px 10px 0px'
                    }}>{t(translationKeys.results)}</h3>
                    <img alt={'dropdown'} src={showResults ? up : down} style={{cursor: "pointer"}}
                         onClick={() => handleClick(CLICK_TYPE.RESULTS)}/>
                </div>
                {!showResults ? <div id={'settings-quiz-results-answers'} className={'row-description'}>
                        <span
                            style={{fontSize: '14px'}}>{t(translationKeys.show_results)}, {t(translationKeys.show_correct_answer)}</span>
                </div> : <>
                    {isMobileOnly ? <SettingsQuizControlResultContainer {...props}/> :
                        <SettingsQuizResultContainer key='SettingsQuizResultContainer' {...props}/>}
                </>
                }
            </div>
            <div className={showQuestions ? null : "hover-controls"} style={{margin: '0 20px'}}
                 onClick={() => {
                     if (!showQuestions) {
                         handleClick(CLICK_TYPE.QUESTIONS)
                     }
                 }}>
                <div className={'row-title'} style={{display: 'flex', justifyContent: 'space-between'}}>
                    <h3 style={{
                        color: '#004BA0',
                        fontSize: '17px',
                        margin: '15px 15px 10px 0px'
                    }}>{t(translationKeys.questions)}</h3>
                    <img alt={'dropdown'} src={showQuestions ? up : down} style={{cursor: "pointer"}}
                         onClick={() => handleClick(CLICK_TYPE.QUESTIONS)}/>
                </div>
                {!showQuestions ? <div id={'settings-question-no-shuffle'} className={'row-description'}>
                    <span style={{fontSize: '14px'}}>{t(translationKeys.question_number_shuffle)}</span>
                </div> : <>
                    {isMobileOnly ? <SettingsControlQuestionContainer {...props}/> :
                        <SettingsQuizQuestionsContainer key='SettingsQuizQuestionsContainer' {...props}/>}
                </>
                }
            </div>
            <div className={showScheduleFormStatus ? null : "hover-controls"} style={{margin: '0 20px'}}
                 onClick={() => {
                     if (!showScheduleFormStatus) {
                         handleClick(CLICK_TYPE.SCHEDULE_FORM_STATUS)
                     }
                 }}>
                <div id={'settings-control-questions'} className={'row-title'}
                     style={{display: 'flex', justifyContent: 'space-between'}}>
                    <h3 style={{
                        color: '#004BA0',
                        fontSize: '17px',
                        margin: '15px 15px 10px 0px'
                    }}>{formData?.is_quiz ? t(translationKeys.schedule_quiz_status) : t(translationKeys.schedule_form_status)}</h3>
                    <img alt={'dropdown'} src={showScheduleFormStatus ? up : down} style={{cursor: "pointer"}}
                         onClick={() => handleClick(CLICK_TYPE.SCHEDULE_FORM_STATUS)}/>
                </div>
                {!showScheduleFormStatus ? <div className={'row-description'}>
                    <span style={{fontSize: '14px'}}>{t(translationKeys.opening_date)}, {t(translationKeys.closing_date)}</span>
                </div> : <>
                    {isMobileOnly ? <SettingMobileScheduleForqStatus {...props}/> :
                        <SettingScheduleForqStatus {...props}/>}
                </>
                }
            </div>
        </>
    )
}

export default SettingsMobileQuizControlContainer