import React, {useState} from 'react';
import styles from '../css/form-header.module.css'
import {isMinWidth600, THEME_TYPE} from "../utils/utils";
import {AWS_FOLDER_NAMES, MEDIA_BASE_URL} from "../../app/builder/utils/aws";
import {PREVIEW_TYPE} from "../../shared/utils/constants";
import {isMobileOnly} from "react-device-detect";
import {estimatedTime, questionCount} from "../../shared/utils/helper";
import {useSelector} from "react-redux";
import MaterialButton, {ButtonTypes} from "../../shared/components/MaterialButton";
import {isImageTheme} from "../utils/theme-color-helper";
import peopleImage from "../../../public/images/people.svg";
import ModalDialog from "../../shared/components/ModalDialog";

export default function FormHeaderComponent(props) {
    const preview = useSelector(state => state.screensReducer.preview)
    const [showChangePopUp, setShowChangePopUp] = useState(false)
    const formData = useSelector(state => state.formDataReducer.formData)
    const themeColor = useSelector(state => state.themeReducer.themeColor)
    const responderEmailId = useSelector(state => state.formLoginReducer.responderEmailId)

    function handleImageError(event) {
        event.target.style.display = 'none';
    }

    function getLogoUrl(userId) {
        return `${MEDIA_BASE_URL}/${AWS_FOLDER_NAMES.LOGOS_FOLDER}/${userId}_logo.png?r=${Math.random()}`;
    }

    let formTitleColor = "#fff";
    let formTitleTextShadow = "none";
    if (formData?.theme === THEME_TYPE.CLASSIC) {
        formTitleColor = "#212121"
    } else if (formData?.theme !== THEME_TYPE.CLASSIC
        && formData?.theme !== THEME_TYPE.DARK) {
        formTitleTextShadow = "1px 1px rgba(0, 0, 0, 0.4)";
    }
    const formLogoStyle = (!props.previewType || props.previewType === PREVIEW_TYPE.DESKTOP) && isMinWidth600() ? {
        maxWidth: '30%',
        maxHeight: '100px'
    } : null;
    const formTitleStyle = (!props.previewType || props.previewType === PREVIEW_TYPE.DESKTOP) && isMinWidth600() ? {
        maxWidth: '50%',
        fontSize: '32px'
    } : null;
    const getTotalMarks = () => {
        let totalMarks = 0
        formData?.pages[0].questions?.map((question) => {
            if (question.marks) {
                totalMarks = totalMarks + question.marks
            }
        })
        return totalMarks
    }
    const handleChangeClick = () => {
        setShowChangePopUp(true)
    }

    return (
        <div id={'form-header'} className={styles.fixed_navigation_top}>
            <img className={styles.form_logo}
                 alt={"logo"}
                 src={getLogoUrl(formData?.user_id)}
                 style={{
                     ...{display: formData?.setting.is_show_logo ? "block" : "none"},
                     ...formLogoStyle
                 }}
                 onError={(event) => {
                     handleImageError(event);
                 }}/>
            <h5 id={'form-header-title'} className={styles.form_title}
                style={{
                    ...{color: formTitleColor, textShadow: formTitleTextShadow},
                    wordWrap: isMobileOnly || props.previewType ? "break-word" : null,
                    ...formTitleStyle
                }}>
                {formData?.welcome_screen.title}
            </h5>
            <p id={'form-header-desc'} className={styles.form_desc}
               style={{
                   ...{color: formTitleColor, textShadow: formTitleTextShadow},
                   wordWrap: isMobileOnly || props.previewType ? "break-word" : null,
               }}>
                {formData?.welcome_screen.description}
            </p>
            {(() => {
                if ((formData?.setting.is_email && !props.previewType && !preview) ||
                    formData?.setting.show_question_marks ||
                    formData?.setting.show_question_count ||
                    (formData?.setting.show_estimated_time && !formData?.is_quiz)) {
                    if (isMobileOnly) {
                        return (
                            <div className={styles.form_header_data}>
                                <div style={{display: "flex", margin: "5px 0"}}>
                                    {formData?.setting.show_question_marks ?
                                        <p>Total Marks <br/>
                                            <span>{getTotalMarks()}</span></p> : null}
                                    {formData?.setting.show_estimated_time && !formData?.is_quiz ?
                                        <p>Estimated Time <br/>
                                            <span>{estimatedTime(formData?.pages[0].questions)} Mins</span></p> : null}
                                    {formData?.setting.show_question_count ?
                                        <p style={{borderLeft: formData?.setting.show_question_marks || formData?.setting.show_estimated_time ? "1px solid" : null}}>Total
                                            Questions <br/>
                                            <span>{questionCount(formData?.pages[0].questions)}</span></p> : null}
                                </div>
                                {formData?.setting.is_email && !props.previewType && !preview ? <div style={{
                                    display: "flex",
                                    color: "#ffffff",
                                    alignItems: "center",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    width: isMobileOnly ? "100%" : "50%",
                                    margin: isMobileOnly ? "5px 0" : null
                                }}>
                                    <span style={{fontWeight: "bold"}}>{responderEmailId}</span>
                                    <MaterialButton handleClick={handleChangeClick}
                                                    data={{
                                                        title: "Change",
                                                        buttonType: ButtonTypes.CONTAINED,
                                                        bg_color: isImageTheme(formData?.theme) ? themeColor?.secondaryColor : '#212121',
                                                    }}/>
                                </div> : null}
                            </div>
                        )
                    } else {
                        return (
                            <div className={styles.form_header_data}>
                                {formData?.setting.show_question_marks ?
                                    <p style={{borderRight: formData?.setting.is_email ? "1px solid" : null}}>Total
                                        Marks <br/>
                                        <span>{getTotalMarks()}</span>
                                    </p> : null}
                                {formData?.setting.show_estimated_time && !formData?.is_quiz ?
                                    <p style={{borderRight: formData?.setting.is_email ? "1px solid" : null}}>Estimated
                                        Time <br/>
                                        <span>{estimatedTime(formData?.pages[0].questions)} Mins</span></p> : null}
                                {formData?.setting.is_email && !props.previewType ? <div style={{
                                    width: "100%",
                                    display: "flex",
                                    color: "#ffffff",
                                    alignItems: "center",
                                    flexDirection: "column",
                                    justifyContent: "center"
                                }}>
                                    <span style={{fontWeight: "bold"}}>{responderEmailId}</span>
                                    <MaterialButton handleClick={handleChangeClick}
                                                    data={{
                                                        title: "Change",
                                                        buttonType: ButtonTypes.CONTAINED,
                                                        bg_color: isImageTheme(formData?.theme) ? themeColor?.secondaryColor : '#212121',
                                                    }}/>
                                </div> : null}
                                {formData?.setting.show_question_count ?
                                    <p style={{borderLeft: formData?.setting.is_email || formData?.setting.show_question_marks || formData?.setting.show_estimated_time ? "1px solid" : null}}>Total
                                        Questions <br/>
                                        <span>{questionCount(formData?.pages[0].questions)}</span></p> : null}
                            </div>
                        )
                    }
                }
            })()}
            {showChangePopUp ? <ModalDialog header={'Switch Account'}
                                            imageIcon={peopleImage}
                                            color={isImageTheme(formData?.theme) ? themeColor?.secondaryColor : '#212121'}
                                            body={["Do you want to change the account?", "Make sure your data will be erased and you will be start from beginning"]}
                                            buttons={[
                                                {
                                                    text: 'CANCEL',
                                                    buttonTextColor: isImageTheme(formData?.theme) ? themeColor?.secondaryColor : '#212121',
                                                    action: () => {
                                                        setShowChangePopUp(false)
                                                    }
                                                },
                                                {
                                                    text: "SIGN OUT",
                                                    buttonTextColor: isImageTheme(formData?.theme) ? themeColor?.secondaryColor : '#212121',
                                                    action: () => {
                                                        window?.localStorage?.removeItem("responder_token")
                                                        window.location.reload()
                                                    }
                                                }
                                            ]}/> : null}
        </div>
    );
}
