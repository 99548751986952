import React, {useEffect, useRef, useState} from 'react'
import MaterialButton from "../../../shared/components/MaterialButton"
import {isMobileOnly} from "react-device-detect"
import PopUpContainer from "../../../shared/components/PopUpContainer"
import {AWS_UPLOAD_TYPE} from "../../../app/builder/utils/aws"
import SelectedFileComponent from "../SelectedFileComponent"
import {isDarkTheme} from "../../utils/theme-color-helper"
import ModalDialog from "../../../shared/components/ModalDialog"
import {ACTION_TO_TAKE_BASED_ON_USER_STORAGE_LEVEL} from "../../../shared/utils/constants"
import '../../../shared/css/modal-dialog.css'
import {formatBytes} from "../../../shared/utils/helper"
import {useDispatch, useSelector} from "react-redux";
import {ACTION_TYPE} from "../../utils/utils";
import {registerGAEvent} from "../../../shared/utils/analytics";
import {QUESTIONS_ACTION_TYPE} from "../../reducers/questionsReducer";

export const FileUploadQuestion = (props) => {
    const formData = useSelector(state => state.formDataReducer.formData)
    const responses = useSelector(state => state.formDataReducer.formResponses)
    const filesToUpload = useSelector(state => state.questionsReducer.filesToUpload)
    const filesToDelete = useSelector(state => state.questionsReducer.filesToDelete)
    const [showFileUploadButton, setShowFileUploadButton] = useState(true)
    const [showFileUploadDialog, setShowFileUploadDialog] = useState(false)
    const [showFileLink, setShowFileLink] = useState(false)
    const [showOutOfSpaceModalDialog, setShowOutOfSpaceModalDialog] = useState(false)

    const refUploadedFileName = useRef('')
    const refUploadedFileSize = useRef('')

    const answerCountState = useSelector(state => state.answerCountReducer?.answerCount);
    const fileUploadAnsweredCount = useSelector(state => state.answerCountReducer?.fileUploadAnswerCount)
    const actionToTakeBasedOnUserStorageLevel = useSelector(state => state.screensReducer.actionToTakeBasedOnUserStorageLevel)
    const dispatch = useDispatch();

    const fileUploadButtonData = {
        isButton: true,
        imageFileName: 'cloud_upload.svg',
        title: "Upload",
        customButtonTextStyle: {
            fontFamily: 'Nunito Sans, Sans-serif, serif'
        }
    }

    useEffect(() => {
        responses.map((response) => {
            if (response.question_id === props.questionData._id) {
                if (filesToUpload?.find(fileInfo => fileInfo.questionId === (props.questionData._id))?.file === null) {
                    setShowFileUploadButton(true)
                    setShowFileLink(false)
                }
                if (response.text) {
                    setShowFileUploadButton(false)
                    refUploadedFileName.current = response.text.split("/").pop()
                    refUploadedFileSize.current = formatBytes(response.fileSize, 5, true)
                    setShowFileLink(true)
                }
            }
        })
    }, [responses])

    function handleFileUploadClick() {
        if (actionToTakeBasedOnUserStorageLevel === ACTION_TO_TAKE_BASED_ON_USER_STORAGE_LEVEL.ALLOW_RESPONSES_TO_NON_FILE_UPLOAD_QUESTIONS) {
            setShowOutOfSpaceModalDialog(true)
        } else {
            setShowFileUploadDialog(true)
        }
    }

    const shouldFileBrowserBeVisible = (value) => {
        if (!value) {
            setShowFileUploadDialog(false)
        }
    }

    const onPreviewCompleted = (file, size) => {
        filesToUpload.find(fileInfo => fileInfo.questionId === props.questionData._id).file = file
        refUploadedFileName.current = file.name
        refUploadedFileSize.current = formatBytes(size, 5, true)
        setShowFileUploadButton(false)
        registerGAEvent('Form', 'Question', 'file-uploaded');
        setShowFileLink(true)
        validateRequired()
        dispatch({type: ACTION_TYPE.SET_FILE_UPLOAD_ANSWER_COUNT, payload: fileUploadAnsweredCount + 1})
        dispatch({type: ACTION_TYPE.SET_ANSWER_COUNT, payload: answerCountState + 1})
    }

    const showBottomBarWhenImagesContainerClosed = () => {
        isMobileOnly && props.hideBottomBar ? props.hideBottomBar(false) : null
    }

    const handleClose = () => {
        if (filesToUpload.find(fileInfo => fileInfo.questionId === (props.questionData._id)).file) {
            setShowFileUploadButton(true)
            setShowFileLink(false)
            filesToUpload.find(fileInfo => fileInfo.questionId === (props.questionData._id)).file = null
            validateRequired()
            dispatch({type: ACTION_TYPE.SET_FILE_UPLOAD_ANSWER_COUNT, payload: fileUploadAnsweredCount - 1})
            dispatch({type: ACTION_TYPE.SET_ANSWER_COUNT, payload: answerCountState - 1})
        } else {
            const updatedFilesToDelete = [...filesToDelete]
            updatedFilesToDelete.push({
                questionId: props.questionData._id,
                fileSize: refUploadedFileSize.current,
                fileName: refUploadedFileName.current
            })
            setShowFileUploadButton(true)
            setShowFileLink(false)
            validateRequired()
            dispatch({type: QUESTIONS_ACTION_TYPE.SET_FILES_TO_DELETE, payload: updatedFilesToDelete})
            dispatch({type: ACTION_TYPE.SET_FILE_UPLOAD_ANSWER_COUNT, payload: fileUploadAnsweredCount - 1})
            dispatch({type: ACTION_TYPE.SET_ANSWER_COUNT, payload: answerCountState - 1})
        }
    }

    const validateRequired = () => {
        if (props.questionData.is_required) {
            if (!showFileLink) {
                props.setInfoTag({
                    is_show: true,
                    text: "Required",
                    textColor: isDarkTheme(formData.theme) ? '#fff' : '#212121',
                    backgroundColor: isDarkTheme(formData.theme) ? '#000' : '#e8e8e8'
                });
            } else {
                props.setInfoTag({
                    is_show: true,
                    text: "This question requires an answer",
                    textColor: 'rgb(255, 255, 255)',
                    backgroundColor: 'rgb(244, 67, 54)'
                });
            }
        }
    }

    return (
        <>
            {
                (() => {
                    if (showFileUploadButton)
                        return (
                            <>
                                <MaterialButton color={props.color} data={fileUploadButtonData} handleClick={() =>
                                    handleFileUploadClick()
                                }/>
                                <div id='storage-size-text' style={{
                                    fontFamily: "Nunito Sans",
                                    fontSize: "13px",
                                    color: isDarkTheme(formData?.theme) ? 'white' : null
                                }}>* Maximum allowed file size is <b>50MB</b>.
                                </div>
                            </>
                        )
                })()
            }
            {
                (() => {
                    if (showFileUploadDialog)
                        return (
                            <PopUpContainer zIndex={11}
                                            isImageUploader={true}
                                            shouldFileBrowserBeVisible={() => {
                                                shouldFileBrowserBeVisible(false)
                                                isMobileOnly ? showBottomBarWhenImagesContainerClosed() : null
                                            }}
                                            previewCompleted={onPreviewCompleted}
                                            uploadType={AWS_UPLOAD_TYPE.NONE}
                                            userId={props.userId}
                                            maxFileUploadSize={5e7}
                                            isFiller={true}
                                            isFileUpload={true}
                            />
                        )
                })()
            }
            {
                (() => {
                    if (showOutOfSpaceModalDialog)
                        return (
                            <div>
                                <ModalDialog
                                    header={'Storage Capacity Exceeded'}
                                    body={[`Kindly inform the ${formData.is_quiz ? 'Quiz' : 'Form'} Builder that`, 'their storage capacity for File Uploads', 'has been exceeded.']}
                                    buttons={[{
                                        text: 'OK',
                                        action: () => {
                                            setShowOutOfSpaceModalDialog(false)
                                        }
                                    }]}
                                />
                            </div>
                        )
                })()
            }
            {
                (() => {
                    if (showFileLink)
                        return (
                            <SelectedFileComponent text={refUploadedFileName.current} size={refUploadedFileSize.current}
                                                   showClose={true} clickHandler={() => {
                                handleClose()
                            }}/>
                        )
                })()
            }
        </>
    )
}
