import React from 'react'
import {PREVIEW_TYPE} from "../../../shared/utils/constants";
import {isMobileOnly} from "react-device-detect";
import FormComponent from "../../../form/components/FormComponent";
import {createFormDataTempId} from "../../../form/utils/utils";
import {savePreviewPreference} from "../../utils/local-storage";
import {registerGAEvent} from "../../../shared/utils/analytics";
import {Provider, useDispatch, useSelector} from "react-redux";
import forqStore from "../../../form/store/forqStore";
import {BUILDER_ACTION_TYPE} from "../../reducers/builderReducer";
import {APP_ACTION_TYPE} from "../../reducers/appReducer";
import MaterialButton, {ButtonTypes} from "../../../shared/components/MaterialButton";
import {t} from "i18next";
import {translationKeys} from "../../../localizations/translationKeys-localization";
import refresh from "../../../../public/images/refresh.svg";

const PreviewPane = props => {
    const formData = useSelector(state => state.appReducer.formData)
    const showRefreshPreview = useSelector(state => state.appReducer.showRefreshPreview)
    const dispatch = useDispatch()
    const handleClose = () => {
        props.closePreviewPane()
    }

    const handlePreviewImageClick = previewType => {
        registerGAEvent('Builder', 'preview', previewType);
        savePreviewPreference(previewType)
        props.setPreviewType(previewType);
    }

    return (
        <div id={'builder-preview-pane-wrapper'} className='builder-preview-pane-wrapper'>
            {!isMobileOnly ?
                <>
                    <div className='builder-preview-pane-header'>
                        <PreviewHeaderImageContainer imageType={PREVIEW_TYPE.PHONE}
                                                     isSelected={props.previewType === PREVIEW_TYPE.PHONE}
                                                     handleImageClick={handlePreviewImageClick}/>
                        <PreviewHeaderImageContainer imageType={PREVIEW_TYPE.TABLET}
                                                     isSelected={props.previewType === PREVIEW_TYPE.TABLET}
                                                     handleImageClick={handlePreviewImageClick}/>
                        <PreviewHeaderImageContainer imageType={PREVIEW_TYPE.DESKTOP}
                                                     isSelected={props.previewType === PREVIEW_TYPE.DESKTOP}
                                                     handleImageClick={handlePreviewImageClick}/>
                        <div id={'close_button'} className="builder-preview-header-close-button center"
                             onClick={handleClose}>
                            <img src="../../../images/close_black.svg"
                                 alt="images/close_black.svg"
                            />
                        </div>
                    </div>
                    <div className='builder-preview-pane-body'>
                        {showRefreshPreview ? <div style={{
                            zIndex: "5",
                            color: "white",
                            display: "flex",
                            fontSize: "18px",
                            fontWeight: "bolder",
                            alignItems: "center",
                            position: "absolute",
                            flexDirection: "column",
                            background: "#000000b3",
                            justifyContent: "center",
                            width: "-webkit-fill-available",
                            height: "-webkit-fill-available"
                        }}>
                            <div style={{width: "50%", textAlign: "center"}}>{t(translationKeys.refresh_preview_description)}</div>
                            <MaterialButton data={{
                                base64ImageData: refresh,
                                buttonType: ButtonTypes.CONTAINED,
                                title: t(translationKeys.refresh),
                                customButtonContainerStyle: {
                                    color: '#424242',
                                    background: 'white',
                                    borderRadius: '35px'
                                }
                            }} handleClick={() => {
                                dispatch({type: APP_ACTION_TYPE.UPDATE_PREVIEW_DATA, payload: JSON.parse(JSON.stringify(formData))})
                                dispatch({type: APP_ACTION_TYPE.SET_SHOW_REFRESH_PREVIEW, payload: false})
                            }}/>
                        </div> : null}
                        {/*Mobile View*/}
                        {props.previewType === PREVIEW_TYPE.PHONE ?
                            <div className='phone-preview-container'>
                                <PreviewCoreComponent previewType={props.previewType} formData={props.formData}/>
                            </div> : null}

                        {/*Tablet View*/}
                        {props.previewType === PREVIEW_TYPE.TABLET ?
                            <div className='tablet-preview-container'>
                                <PreviewCoreComponent previewType={props.previewType} formData={props.formData}/>
                            </div> : null}

                        {/*Desktop View*/}
                        {props.previewType === PREVIEW_TYPE.DESKTOP ?
                            <PreviewCoreComponent previewType={props.previewType} formData={props.formData}/> : null}

                        {props.previewType !== PREVIEW_TYPE.DESKTOP ?
                            <div className='preview-skin-layer'>
                                {props.previewType === PREVIEW_TYPE.PHONE ?
                                    <img className='mobile-skin-size'
                                         src="images/preview_mobile_skin.svg"
                                         alt="Phone"
                                    />
                                    : (props.previewType === PREVIEW_TYPE.TABLET) ?
                                        <img className='tablet-skin-size'
                                             src="images/preview_tablet_skin.svg"
                                             alt="tablet"
                                        /> : null}
                            </div> : null}
                    </div>
                </> : <PreviewCoreComponent previewType={PREVIEW_TYPE.DESKTOP} formData={props.formData}/>}
        </div>
    )
}

export default PreviewPane

const PreviewCoreComponent = (props) => {
    const selectedPreviewScreen = useSelector(state => state.builderReducer.selectedPreviewScreen)
    const dispatch = useDispatch()
    if (props.formData && props.previewType) {
        props.formData.pages[0].questions = createFormDataTempId(props.formData)
    }

    return <div id={'preview-form-container'} className='preview-form-container' style={{
        borderRadius: (props.previewType === PREVIEW_TYPE.PHONE) && !isMobileOnly ? '10px' : null,
        paddingBottom: isMobileOnly ? '60px' : null
    }}>
        {props.formData ? <Provider store={forqStore}>
            <FormComponent setSelectedPreviewScreen={(selectedPreviewScreen) => dispatch({
                type: BUILDER_ACTION_TYPE.SET_SELECTED_PREVIEW_SCREEN,
                payload: selectedPreviewScreen
            })}
                           selectedPreviewScreen={selectedPreviewScreen} formData={props.formData}
                           dispatchCurrentScreen={(payload) => dispatch({
                               type: APP_ACTION_TYPE.SET_CURRENT_PAGE,
                               payload: payload
                           })}
                           previewType={props.previewType}/>
        </Provider> : null}
    </div>
}

const PreviewHeaderImageContainer = props => {
    return <div id={'image_container_' + props.imageType} className='builder-preview-header-image-container'>
        <div className='image-container'
             style={props.isSelected ? {backgroundColor: 'rgba(25, 118, 210, 0.1)'} : null}
             onClick={() => props.handleImageClick(props.imageType)}>
            <img className='builder-preview-header-image'
                 src={getSrcName(props.imageType, props.isSelected)}
                 alt={props.imageType}
            />
        </div>
    </div>
}

const getSrcName = (imageType, isSelected) => {
    return (imageType === PREVIEW_TYPE.PHONE) ? isSelected ? 'images/preview_phone_blue.svg' : "images/phone_android_24px.svg" : (imageType === PREVIEW_TYPE.TABLET) ? isSelected ? 'images/preview_tablet_blue.svg' : "images/preview_tablet_grey.svg" : (imageType === PREVIEW_TYPE.DESKTOP) ? isSelected ? "images/preview_desktop_blue.svg" : "images/preview_desktop_grey.svg" : null
}
