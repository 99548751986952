import {formQuestionGroups, quizQuestionGroups} from "../../../public/js/forq_question_groups.js"
import importImage from '../../../public/images/icons/builder/import.svg'
import blueNewImage from '../../../public/images/icons/builder/blue_new.svg'
import {translationKeys} from "../../localizations/translationKeys-localization"
import {t} from "i18next"
import newImage from '../../../public/images/new.svg'

const useQuestionTypeMgr = () => {
    const formQuestionGroup = formQuestionGroups
    const quizQuestionGroup = quizQuestionGroups

    const newText = t(translationKeys.new)

    const getQuestionGroups = (isQuiz) => {
        if (isQuiz) {
            return quizQuestionGroup.map(qg => qg)
        } else {
            return formQuestionGroup.map(qg => qg)
        }
    }

    const getImage = (questionType) => {
        let index
        formQuestionGroup.find(qg => qg.questionTypes.find((qt, i) => qt.type === questionType ? index = i : null))
        return formQuestionGroup.find(qg => qg.questionTypes.find(qt => qt.type === questionType)).questionTypes[index].image
    }

    const getTitle = (questionType) => {
        let index
        formQuestionGroup.find(qg => qg.questionTypes.find((qt, i) => qt.type === questionType ? index = i : null))
        return formQuestionGroup.find(qg => qg.questionTypes.find(qt => qt.type === questionType)).questionTypes[index].title
    }

    return {
        blueNewImage,
        getTitle,
        getImage,
        getQuestionGroups,
        importImage,
        newText,
        newImage
    }
}

export default useQuestionTypeMgr