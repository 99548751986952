import React from "react"
import {useDispatch, useSelector} from "react-redux";
import SurveyHeart from "../../../public/images/surveyheart1.png"
import {FORM_LOGIN_ACTION_TYPE} from "../reducers/formLoginReducer";
import {SCREENS_ACTION_TYPE} from "../reducers/screensReducer";
import {FORM_SCREEN} from "../utils/utils";
import GoogleLoginResponder from "../../shared/components/GoogleLoginResponder";
import {getResponsesOfResponder, quizResponderSignIn, responderSignIn} from "../../shared/utils/forq-api-services";
import * as jsonWebToken from "jsonwebtoken";
import {FORM_DATA_ACTION_TYPE} from "../reducers/formDataReducer";

const FormPageLoginContainer = () => {
    const formData = useSelector(state => state.formDataReducer.formData)
    const dispatch = useDispatch();

    const handleGoogleSuccessResponse = (credential) => {
        if (credential) {
            const successFunction = (res) => {
                if (res?.data?.result) {
                    if (res?.data?.token) {
                        window?.localStorage?.setItem("responder_token", res.data.token)
                    }
                    dispatch({
                        type: FORM_LOGIN_ACTION_TYPE.SET_RESPONDER_EMAIL,
                        payload: jsonWebToken.decode(res.data.token).responder_email_id
                    })
                    const successFunction = (res) => {
                        if (res.responder_responses.length > 0) {
                            dispatch({type: SCREENS_ACTION_TYPE.SET_FORM_SCREEN, payload: FORM_SCREEN.FORM_EDIT_RESPONSE})
                            dispatch({type: FORM_DATA_ACTION_TYPE.SET_RESPONDER_RESPONSES, payload: res.responder_responses})
                        } else {
                            if (formData.welcome_screen.show_screen) {
                                dispatch({type: SCREENS_ACTION_TYPE.SET_FORM_SCREEN, payload: FORM_SCREEN.WELCOME_PAGE})
                                return
                            }
                            if (formData.abuse_scan_result) {
                                dispatch({type: SCREENS_ACTION_TYPE.SET_FORM_SCREEN, payload: FORM_SCREEN.ABUSE_WARNING})
                                return
                            } else if (formData.is_quiz) {
                                dispatch({type: SCREENS_ACTION_TYPE.SET_FORM_SCREEN, payload: FORM_SCREEN.QUIZ_USER_INFO})
                                return
                            }
                            if (!formData.is_quiz && formData?.user_info_form?.user_info_1 && !formData.setting?.is_email) {
                                dispatch({type: SCREENS_ACTION_TYPE.SET_FORM_SCREEN, payload: FORM_SCREEN.FORM_USER_INFO})
                            } else {
                                dispatch({type: SCREENS_ACTION_TYPE.SET_FORM_SCREEN, payload: FORM_SCREEN.FORM_PAGE})
                            }
                        }
                    }
                    const failureFunction = (err) => {
                        console.log(err)
                    }
                    if (formData?.setting.allow_response_edit) {
                        getResponsesOfResponder(formData._id, successFunction, failureFunction)
                    } else {
                        if (formData.welcome_screen.show_screen) {
                            dispatch({type: SCREENS_ACTION_TYPE.SET_FORM_SCREEN, payload: FORM_SCREEN.WELCOME_PAGE})
                            return
                        }
                        if (formData.abuse_scan_result) {
                            dispatch({type: SCREENS_ACTION_TYPE.SET_FORM_SCREEN, payload: FORM_SCREEN.ABUSE_WARNING})
                            return
                        } else if (formData.is_quiz) {
                            dispatch({type: SCREENS_ACTION_TYPE.SET_FORM_SCREEN, payload: FORM_SCREEN.QUIZ_USER_INFO})
                            return
                        }
                        if (!formData.is_quiz && formData?.user_info_form?.user_info_1 && !formData.setting?.is_email) {
                            dispatch({type: SCREENS_ACTION_TYPE.SET_FORM_SCREEN, payload: FORM_SCREEN.FORM_USER_INFO})
                        } else {
                            dispatch({type: SCREENS_ACTION_TYPE.SET_FORM_SCREEN, payload: FORM_SCREEN.FORM_PAGE})
                        }
                    }
                }
            }
            const failureFunction = (err) => {
                console.log(err)
            }
            if (formData?.is_quiz) {
                quizResponderSignIn(credential, successFunction, failureFunction)
            } else {
                responderSignIn(credential, successFunction, failureFunction)
            }
        }
    }

    const handleGoogleFailedResponse = (err) => {
        console.log(err)
    }

    return (
        <div style={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            borderRight: "2px solid #eeeeee"
        }}>
            <div id='login-info-container' className='login-info-container' style={{
                justifyContent: "center",
                display: "flex",
                flexFlow: "column",
                alignItems: "center",
            }}>
                <img src={SurveyHeart} alt={"SurveyHeart"}
                     style={{width: "300px"}}/>
                <div style={{margin: "15px"}}>
                    Sign in to fill the {formData?.is_quiz ? "Quiz" : "Form"}
                </div>
                <GoogleLoginResponder onSuccess={handleGoogleSuccessResponse}
                                      onFailure={handleGoogleFailedResponse}
                                      isSignIn={true}/>
            </div>
        </div>
    )
}

export default FormPageLoginContainer
