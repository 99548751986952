import WELCOME_SCREEN_PROPS from "../enums/form/WELCOME_SCREEN_PROPS"
import {QUESTION_TYPE} from "../../../shared/utils/constants.js"
import {translationKeys} from "../../../localizations/translationKeys-localization";
import {t} from "i18next";

let formMap = new Map()
let welcomeScreenMap = new Map()
let welcomeScreenPropsMap = new Map()

// Note: this class is used to create Forms AND Quizzes.
class Form {
    constructor(title, desc) {
        welcomeScreenPropsMap.set(WELCOME_SCREEN_PROPS.title, title)
        welcomeScreenPropsMap.set(WELCOME_SCREEN_PROPS.description, desc)
        welcomeScreenPropsMap.set(WELCOME_SCREEN_PROPS.show_button, true)
        welcomeScreenPropsMap.set(WELCOME_SCREEN_PROPS.show_screen, true)
        welcomeScreenPropsMap.set(WELCOME_SCREEN_PROPS.button_text, '')
        welcomeScreenMap.set('welcome_screen', this.mapToObj(welcomeScreenPropsMap))
        formMap.set('formsAppData', this.mapToObj(welcomeScreenMap))

        this.questions = []
    }

    getJSON() {
        return JSON.stringify(this.mapToObj(formMap))
    }

    updateDesc(desc) {

    }

    /**
     *
     * @param form {object}
     * @param {QUESTION_TYPE}
     */
    static addQuestion(questionTypeKey) {
        let newQuestion = this.getDefaultQuestionElements(questionTypeKey)

        switch (QUESTION_TYPE[questionTypeKey]) {
            case QUESTION_TYPE.SHORT_TEXT:
                newQuestion.max_value = 100
                break
            case QUESTION_TYPE.LONG_TEXT:
                newQuestion.max_value = 10000
                break
            case QUESTION_TYPE.CHECKBOX_CHOICE:
            case QUESTION_TYPE.DROPDOWN_CHOICE:
            case QUESTION_TYPE.MULTIPLE_CHOICE:
                newQuestion.is_others_allowed = false
                newQuestion.is_options_shuffled = false
                newQuestion.choices = [
                    {
                        label: ""
                    }
                ]
                break
            case QUESTION_TYPE.LINEAR_SCALE:
                newQuestion.choices = [
                    {
                        label: '1'
                    },
                    {
                        label: '2'
                    },
                    {
                        label: '3'
                    },
                    {
                        label: '4'
                    },
                    {
                        label: '5'
                    },
                    {
                        label: '6'
                    },
                    {
                        label: '7'
                    },
                    {
                        label: '8'
                    },
                    {
                        label: '9'
                    },
                    {
                        label: '10'
                    }
                ]
                newQuestion.start_label = ''
                newQuestion.end_label = ''
                newQuestion.center_label = ''
                break
            case QUESTION_TYPE.CHECKBOX_GRID:
            case QUESTION_TYPE.MULTIPLE_CHOICE_GRID:
                newQuestion.rows = [
                    {
                        label: `${t(translationKeys.row)} 1`
                    }
                ]
                newQuestion.columns = [
                    {
                        label: `${t(translationKeys.column)} 1`
                    }
                ]
                break
            case QUESTION_TYPE.STAR_RATING_SCALE:
                newQuestion.choices = [
                    {
                        label: t(translationKeys.worst)
                    },
                    {
                        label: t(translationKeys.not_good)
                    },
                    {
                        label: t(translationKeys.neutral)
                    },
                    {
                        label: t(translationKeys.good)
                    },
                    {
                        label: t(translationKeys.very_good)
                    }
                ]
                break
            case QUESTION_TYPE.SMILEY:
                newQuestion.choices = [
                    {
                        label: t(translationKeys.worst)
                    },
                    {
                        label: t(translationKeys.dislike)
                    },
                    {
                        label: t(translationKeys.neutral)
                    },
                    {
                        label: t(translationKeys.like)
                    },
                    {
                        label: t(translationKeys.best)
                    }
                ]
                break
        }

        return newQuestion
    }

    static getDefaultQuestionElements(questionType) {
        let defaultQuestionNode = {
            type: questionType,
            title: "",
            description: ""
        }

        if (questionType !== QUESTION_TYPE.SECTION.key) {
            defaultQuestionNode.is_required = false
            /* 'tempAttachmentValue' is needed to handle the "onChange" function call when entering the attachment link. IT IS REMOVED BEFORE UPLOADING TO THE DB. */
            defaultQuestionNode.tempAttachmentValue = ""
        }

        return defaultQuestionNode
    }

    static saveQuestion(questions, currentIndex) {
        let newQuestion = questions[currentIndex]
        let updatedQuestions = JSON.parse(JSON.stringify(questions))

        updatedQuestions.splice(currentIndex + 1, 0, newQuestion)

        return updatedQuestions
    }

    static copyQuestion(questionType, title, description, max_value, is_required) {
        return {
            "type": questionType,
            "title": title,
            "description": description,
            "max_value": max_value,
            "is_required": is_required
        }
    }

    getQuestions() {
        return this.questions
    }

    /**
     *
     * @param {number} index - The index of the Question to remove
     */
    static deleteQuestion(form, currentIndex) {
        let updatedForm = JSON.parse(JSON.stringify(form))

        updatedForm.pages[0].questions.splice(currentIndex, 1)

        return updatedForm
    }

    static updateQuestionTitle(form, currentIndex, value) {
        let updatedQuestions = JSON.parse(JSON.stringify(form))

        updatedQuestions[currentIndex].title = value;

        return updatedQuestions
    }

    mapToObj(m) {
        return Array.from(m).reduce((obj, [key, value]) => {
            obj[key] = value
            return obj
        }, {})
    }
}

export default Form
