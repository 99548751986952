import React from 'react';
import close from '../../../public/images/close_black.svg'
import quizzory from '../../../public/images/quizzory_black.svg'
import style from '../css/surveyheart-content-popup.module.css'
import {isMobileOnly} from "react-device-detect";

export default function QuizzoryContentPopup(props) {
    function hideAboutContent() {
        props.contentContainerRef.current ? props.contentContainerRef.current.style.display = "none" : null;
    }

    return (
        <div ref={props.contentContainerRef} style={{
            height: '100vh',
            width: '100vw',
            position: 'fixed',
            zIndex: '20',
            backgroundColor: 'rgba(0, 0, 0, 0.8)',
            display: 'none',
            alignItems: 'center',
            justifyContent: 'center',
            flexFlow: 'column'
        }}>
            <div className={style.about_content_popup_container} style={{
                display: 'flex',
                flexFlow: 'column'
            }}>
                <div style={{width: '100%'}}>
                    <img
                        style={{
                            width: '35px',
                            height: '35px',
                            margin: '10px 10px 0 0',
                            position: 'relative',
                            cssFloat: 'right',
                            cursor: 'pointer'
                        }}
                        onClick={() => {
                            hideAboutContent()
                        }}
                        alt={'close'}
                        src={close}/>
                    <div className={style.about_content_popup_image_banner}>
                        <img alt={'quizzory icon'} style={{
                            width: '100%',
                            maxWidth: '300px'
                        }} id="app-logo"
                             src={quizzory}/>
                    </div>
                </div>
                <div className={style.about_content_popup_body}>
                    <div style={{
                        padding: '15px',
                        textAlign: 'justify'
                    }}>

                        <div className={style.content_text_container}>
                            <h2 id='quiz-builder-title'>Quiz Builder</h2>
                            <p id='quiz-builder-description'>Create quizzes/tests/exams with various types of questions which are commonly used for
                                conducting quizzes. In our quiz builder you can control your quiz access level to the
                                respondents like whether they can view their test results or not, are they allowed to make
                                multiple attempts or not, whether questions of your quiz gets shuffled or not.</p>
                        </div>
                        <div className={style.content_text_container}>
                            <h2 id='respondent-id-info-title'>Easy Collection of Respondent ID Info</h2>
                            <p id='respondent-id-info-description'>We have made provision in the quiz for you to collect up to three types of customized user
                                information (such as name, roll number) before they start the quiz. It helps in identifying
                                and organizing your quiz results seamlessly.</p>
                        </div>
                        <div className={style.content_text_container}>
                            <h2 id='duration-title'>Duration</h2>
                            <p id='duration-description'>You can set the duration by which the respondent has to answer the exam/quiz. This helps you
                                in having a more accurate view of the respondent’s knowledge on the subject being
                                tested. </p>
                        </div>
                        <div className={style.content_text_container}>
                            <h2 id='preview-quiz-title'>Preview Quiz</h2>
                            <p id='preview-quiz-description'>Before publishing, you can view your quiz exactly as it will appear to the respondents when you
                                share
                                it with them. This view will help you make any changes if required and create error free
                                quizzes.
                            </p>
                        </div>
                        <div className={style.content_text_container}>
                            <h2 id='offline-quiz-builder-title'>Offline Quiz builder</h2>
                            <p id='offline-quiz-builder-description'>Native quiz builder allows you to build your quizzes without internet and save it in offline
                                quizzes. When you access the internet you can submit your quiz with just one click to be
                                saved as a quiz.</p>
                        </div>
                        <div className={style.content_text_container}>
                            <h2 id='instant-notification-quiz-title'> Instant Notifications</h2>
                            <p id='instant-notification-quiz-description'>Get instantly notified of completed quizzes the moment your respondent clicks the submit
                                button. Live notifications enable you to be updated in real time. You can also view your
                                respondents results in a summary format instantly with all newly arrived responses.
                            </p>
                        </div>
                        <div className={style.content_text_container}>
                            <h2 id='summarised-results-title'>Summarised Results</h2>
                            <p id='summarised-results-description'>Summary of your respondent’s results will be shown on a real time basis. Get error free
                                corrected results instantly for your quizzes.</p>
                        </div>
                        <div className={style.content_text_container}>
                            <h2 id='export-record-title'>Export your record</h2>
                            <p id='export-record-description'>Your respondent’s quiz answers can be exported for filing and recording purposes. You have
                                the option to export it as pdf, excel sheet(.xlsx) or .csv files. </p>
                        </div>
                        <div className={style.content_text_container}>
                            <h2 id='quiz-themes-title'>Themes</h2>
                            <p id='quiz-themes-description'>Your forms should be readable and attractive to the respondents, it increases your
                                respondent’s enthusiasm and interest in attempting the quiz. You can choose themes related
                                to your quiz content.</p>
                        </div>
                        <div className={style.about_content_popup_image_banner} style={isMobileOnly ? {flexFlow: "column"} : null}>
                            <img alt={'playstore'} style={{
                                width: '100%',
                                marginTop: '20px',
                                maxWidth: '300px',
                                cursor: 'pointer'
                            }} id="download-image"
                                 onClick={() => {
                                     window.open("https://play.google.com/store/apps/details?id=com.surveyheart&hl=en")
                                 }}
                                 src="https://play.google.com/intl/en_us/badges/images/generic/en_badge_web_generic.png"/>
                            <img alt={'appstore'} style={{
                                width: '100%',
                                marginTop: '20px',
                                maxWidth: isMobileOnly ? '265px' : '245px',
                                cursor: 'pointer'
                            }} id="download-image"
                                 onClick={() => {
                                     window.open("https://apps.apple.com/us/app/surveyheart-online-form-maker/id6443449067?itsct=apps_box_badge&amp;itscg=30200%22")
                                 }}
                                 src="https://apple-resources.s3.amazonaws.com/media-badges/download-on-the-app-store/black/en-us.svg"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}