import React, {useEffect, useState} from 'react'
import '../css/themes.css'
import {setAwsUserJson} from "../utils/prepare-aws-user-json";

import ImagesHeader from "./ImagesHeader";
import AwsThemeContainer from "./AwsThemeContainer";
import {AWS_FOLDER_NAMES, AWS_UPLOAD_TYPE, MEDIA_BASE_URL} from "../utils/aws";
import {getDataFromUrl} from "../../../shared/utils/sash-v2-api";
import {useSelector} from "react-redux";
import {PremiumJSON} from "../../../shared/utils/helper";

const ImagesContainer = (props) => {
    const [images, setImages] = useState([])
    const subscriptionData = useSelector(state => state.userReducer?.subscriptionData)
    const maxQuestionAttachmentLimit = PremiumJSON[subscriptionData?.subscription_plan]?.question_attachments

    useEffect(() => {
        const successFunction = res => {
            setAwsUserJson(res.data)
            setImages(res.data.form_images)
        }
        const failureFunction = err => {
            console.log(err)
        }
        const requestUrl = `${MEDIA_BASE_URL}/${AWS_FOLDER_NAMES.USERS_JSON_FOLDER}/${props.userId}.json?id=${new Date().getTime()}`;
        getDataFromUrl(requestUrl, successFunction, failureFunction)
    }, [])

    return (
        <React.Fragment>
            <div id={'attachment-theme-container'} className='attachment-theme-container'>
                <ImagesHeader hideMyImages={props.hideMyImages}/>
                <AwsThemeContainer
                    maxLimit={maxQuestionAttachmentLimit}
                    doThemeLimitCheck={props.doThemeLimitCheck}
                    themeImages={images}
                    showBottomBarWhenImagesContainerClosed={props.showBottomBarWhenImagesContainerClosed}
                    openUploadPopUp={props.showAddImage}
                    AWSUploadType={AWS_UPLOAD_TYPE.FORM}
                    userId={props.userId}
                    hideCheck={true}
                    updateChosenTheme={props.updateChosenTheme}
                />
            </div>
        </React.Fragment>

    )
}

export default ImagesContainer