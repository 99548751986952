import {getQuestionCategory, QUESTION_CATEGORY, QUESTION_TYPE} from "../../shared/utils/constants";
import * as mongoObjectID from "bson-objectid"

export const answeredQuestionsCount = (responses) => {
    let answeredCount = 0
    for (let i = 0; i < responses.length; i++) {
        if (responses[i].text?.length > 0 || responses[i].choice?.length > 0 || responses[i].choices?.length > 0 || responses[i].grid_choices?.length > 0 || responses[i].checkbox_grid_choices?.length > 0 || responses[i].others?.length > 0) {
            answeredCount = answeredCount + 1
        }
    }
    return answeredCount
}

export const updateResponses = (responses, questionData, responseValue, other, isChecked) => {
    const updatedResponses = responses
    for (let response of updatedResponses) {
        if (response.question_id === questionData._id) {
            if (getQuestionCategory(questionData.type) === QUESTION_CATEGORY.TEXT_TYPE) {
                response.text = responseValue
                break
            } else if (questionData.type === QUESTION_TYPE.MULTIPLE_CHOICE.key) {
                if (other) {
                    if (response.choice) {
                        delete response.choice
                    }
                    response.others = responseValue
                } else {
                    if (response.others) {
                        delete response.others
                    }
                    response.choice = responseValue
                }
            } else if (questionData.type === QUESTION_TYPE.DROPDOWN_CHOICE.key || questionData.type === QUESTION_TYPE.STAR_RATING_SCALE.key || questionData.type === QUESTION_TYPE.LINEAR_SCALE.key) {
                response.choice = responseValue
            } else if (questionData.type === QUESTION_TYPE.CHECKBOX_CHOICE.key) {
                if (other) {
                    response.others = responseValue
                } else if (isChecked) {
                    response.choices.push(responseValue)
                } else {
                    response.choices = response.choices.filter(item => item !== responseValue)
                }
                if (responseValue === "") {
                    response.choices = []
                }
            } else if (questionData.type === QUESTION_TYPE.MULTIPLE_CHOICE_GRID.key) {
                let addNewResponse = true
                response.grid_choices.map((choice, index) => {
                    if (choice.row === responseValue.row) {
                        response.grid_choices[index].column = responseValue.column
                        addNewResponse = false
                    }
                })
                if (addNewResponse) {
                    response.grid_choices.push({
                        row: responseValue.row,
                        column: responseValue.column
                    })
                }
                if (responseValue === "") {
                    response.grid_choices = []
                }
            } else if (questionData.type === QUESTION_TYPE.CHECKBOX_GRID.key) {
                if (isChecked) {
                    let isRow = false
                    response.checkbox_grid_choices.map((choice, index) => {
                        if (choice.row === responseValue.row) {
                            isRow = true
                            response.checkbox_grid_choices[index].columns.push(responseValue.column)
                        }
                    })
                    if (!isRow) {
                        response.checkbox_grid_choices.push({
                            row: responseValue.row,
                            columns: [responseValue.column]
                        })
                    }
                } else {
                    response.checkbox_grid_choices.map((choice, index) => {
                        if (choice.row === responseValue.row && choice.columns.length === 1 && choice.columns[0] === responseValue.column) {
                            response.checkbox_grid_choices.splice(index, 1)
                        } else if (choice.columns.length > 1 && choice.row === responseValue.row) {
                            response.checkbox_grid_choices[index].columns = response.checkbox_grid_choices[index].columns.filter((column) => column !== responseValue.column)
                        }
                    })
                }
                if (responseValue === "") {
                    response.checkbox_grid_choices = []
                }
            } else if (questionData.type === QUESTION_TYPE.SMILEY.key) {
                response.choice = responseValue
            }
        }
    }
    return updatedResponses
}

export const createFormResponsesArray = (formData) => {
    const responses = []

    formData?.pages[0].questions.map((question) => {
        if (question.type !== QUESTION_TYPE.SECTION.key) {
            responses.push(createFormResponse(question._id, question.type, '', 0))
        }
    })
    return responses
}

export function createFormResponse(questionId, questionType, responseData, fileSize) {

    let formResponse = {
        question_id: questionId,
        type: questionType
    }

    if (getQuestionCategory(formResponse.type) === QUESTION_CATEGORY.TEXT_TYPE) {
        formResponse.text = responseData;
    } else if (formResponse.type === QUESTION_TYPE.DROPDOWN_CHOICE.key
        || formResponse.type === QUESTION_TYPE.MULTIPLE_CHOICE.key
        || formResponse.type === QUESTION_TYPE.STAR_RATING_SCALE.key
        || formResponse.type === QUESTION_TYPE.LINEAR_SCALE.key) {
        if (formResponse.type === QUESTION_TYPE.MULTIPLE_CHOICE.key
            && !mongoObjectID.isValid(responseData)) {
            if (responseData) {
                if (responseData.length > 0) {
                    formResponse.others = responseData;
                }
            }
        } else {
            formResponse.choice = responseData;
        }
    } else if (formResponse.type === QUESTION_TYPE.CHECKBOX_CHOICE.key) {
        formResponse.choices = [];
        for (let i = 0; i < responseData.length; i++) {
            if (mongoObjectID.isValid(responseData[i])) {
                formResponse.choices.push(responseData[i])
            } else {
                formResponse.others = responseData[i];
            }
        }
    } else if (formResponse.type === QUESTION_TYPE.FILE_UPLOAD.key) {
        formResponse.text = responseData;
        formResponse.fileSize = fileSize;
    } else if (formResponse.type === QUESTION_TYPE.MULTIPLE_CHOICE_GRID.key) {
        formResponse.grid_choices = []
    } else if (formResponse.type === QUESTION_TYPE.CHECKBOX_GRID.key) {
        formResponse.checkbox_grid_choices = []
    }
    return formResponse
}

