import React from "react"
import {useTranslation} from "react-i18next";
import {translationKeys} from "../../../localizations/translationKeys-localization";

const PercentageContainer = (props) => {
    const {t} = useTranslation()
    return (
        <>
            <div style={{display: "flex", fontWeight: "bold"}}>
                <div style={{width: "50%"}}>{t(translationKeys.options)}</div>
                <div style={{width: "25%", textAlign: "center"}}>%</div>
                <div style={{width: "25%", textAlign: "center"}}>{t(translationKeys.count)}</div>
            </div>
            {props.graphLabels?.map((label, index) => {
                return <div style={{display: "flex", padding: "10px 0"}}>
                    <div style={{width: "50%", color: "#004BA0"}}>{label}</div>
                    <div style={{
                        width: "25%",
                        textAlign: "center",
                        color: "#004BA0"
                    }}>{props.graphData[index] ? ((props.graphData[index] / (props.answeredCount - props.skippedAnswerCount)) * 100).toFixed(1) : "0.0"}</div>
                    <div style={{width: "25%", textAlign: "center", color: "#004BA0"}}>{props.graphData[index]}</div>
                </div>
            })}
            <div style={{display: "flex", padding: "10px 0"}}>
                <div style={{width: "50%", color: "#FF3D00"}}>{t(translationKeys.no_answer)}</div>
                <div style={{width: "25%", textAlign: "center", color: "#004BA0"}}>-</div>
                <div style={{width: "25%", textAlign: "center", color: "#004BA0"}}>{props.skippedAnswerCount}</div>
            </div>
        </>
    )
}

export default PercentageContainer