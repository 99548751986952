import React, {useState} from 'react'
import {getDateWithTime} from "../../../shared/utils/get-date-with-time";
import {translationKeys} from "../../../localizations/translationKeys-localization";
import {t} from "i18next";
import {AWS_FOLDER_NAMES, MEDIA_BASE_URL} from "../../builder/utils/aws";

const MobileSummaryTableRow = (props) => {
    const [rowHeight, setRowHeight] = useState(undefined);

    const handleClick = () => {
        if (props.responseTextCell > 45) {
            setRowHeight(props.responseTextCell);
        } else {
            setRowHeight(45);
        }
    }

    const isDescOrder = props.sortOrder;
    const textResponse = props.textResponse;

    return (
        <table>
        <tbody>
        <tr className='table-row' onMouseOver={handleClick}
            onMouseLeave={() => setRowHeight(undefined)}
            style={(props.index % 2) === 0 ? {
                    backgroundColor: '#f8fBff',
                    height: rowHeight ? rowHeight : "45px"
                }
                : {backgroundColor: '#fff', height: rowHeight ? rowHeight : '45px'}}>
            <td className='table-body-cell'
                style={{width: '50px'}}>
                    <span id='table-body-cell-mobile-summary' style={{minWidth: '0', padding:'5px'}}
                          className='table-body-cell-span'>{isDescOrder ? (props.responsesCount - props.index) : props.index + 1}</span>
            </td>
            <td className='table-body-cell' style={{
                width: '180px'
            }}>
                    <span style={{fontSize: '14px', minWidth: '0'}}
                          className='response-time-printing table-body-cell-span'>{getDateWithTime(props.responseTime)}</span>
            </td>
            <td  className='table-body-cell' style={{
                width: '200px', justifyContent: 'flex-start'
            }}>< span id='table-body-cell-mobile-summary-answer' className='table-body-cell-span'
                     ref={props.responseTextCell} onClick={
                textResponse && textResponse.toString().startsWith(`${MEDIA_BASE_URL}/${AWS_FOLDER_NAMES.FILES_FOLDER}`) ? (e) => {
                    e.stopPropagation();
                    open(textResponse)
                } : null
            }>{textResponse && textResponse.length > 0 ? textResponse : t(translationKeys.no_answer)}</span>
            </td>
        </tr>
        </tbody>
        </table>
    );
}

export default MobileSummaryTableRow;
