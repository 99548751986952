// This file contains Functions common to Forms and Quizzes

import {
    BUILDER_CREATION_SOURCE, QUESTION_TYPE,
    WEB_APP_VERSION
} from "../../../../shared/utils/constants";
import webFirstFeatures from "../../utils/web-first-features.json"
import {translationKeys} from "../../../../localizations/translationKeys-localization";
import {t} from "i18next";
import {PremiumJSON} from "../../../../shared/utils/helper";

export const WEB_FIRST_FEATURES_BUILDER_TYPE = {
    FORM:
        {
            key: "FORM"
        },
    QUIZ:
        {
            key: "QUIZ"
        },
    BOTH: {
        key: "BOTH"
    }
}

/**
 * @param parentData
 * @param isDuplicate
 */
export const getResponseCount = (isDuplicate, parentData) => {
    if (isDuplicate) {
        return 0
    }

    return parentData === null ? 0 : parentData.response_count ? parentData.response_count : 0
}

/**
 *
 * @param {[]} questions
 * @param subscriptionData
 * @returns {boolean}
 */
export const maxNumberOfFileUploadOTsHasBeenReached = (questions, subscriptionData) => {
    // Note: QUESTION_TYPE.FILE_UPLOAD.key === QUIZ_QUESTION_TYPE.FILE_UPLOAD.key.
    // So, we don't need to check for Form or Quiz Question Type.
    // 'QUESTION_TYPE.FILE_UPLOAD.key' (which corresponds to Form) is used.
    const maxFileUploadLimit = PremiumJSON[subscriptionData?.subscription_plan]?.file_upload_questions_per_form
    return questions.filter(q => q.type === QUESTION_TYPE.FILE_UPLOAD.key).length === maxFileUploadLimit
}

/**
 * @param {object} obj - JSON object that has the property to be removed.
 * @param {string} propToRemove -The property to remove from the JSON
 */
export const removeProp = (obj, propToRemove) => {
    // This is a recursive function to remove a property.
    for (let property in obj) {
        if (obj.hasOwnProperty(property)) {
            if (typeof obj[property] == "object") {
                removeProp(obj[property], propToRemove);
            } else {
                if (property === propToRemove) {
                    delete obj[property];
                }
            }
        }
    }
}

/**
 * @param {object} obj - JSON object that has the property to be removed.
 * @param {string} propToRemove -The property to remove from the JSON
 * @param {string} data - The data which include in object property
 */
export const removePropWRTData = (obj, propToRemove, data) => {
    // This is a recursive function to remove a property w.r.t data.
    for (let property in obj) {
        if (obj.hasOwnProperty(property)) {
            if (typeof obj[property] == "object") {
                removePropWRTData(obj[property], propToRemove, data);
            } else {
                if (property === propToRemove && obj[property].includes(data)) {
                    delete obj[property];
                }
            }
        }
    }
}

/**
 *
 * @param builderCreationSource
 * @param params
 * @returns {{response_count: number, pages: [{questions: *[], title: string}], date_edited: null, user_id: (*|string), date_created: null, theme: string, web_app_version: number, welcome_screen: {show_screen: boolean, show_button: boolean, description: string, button_text: string, title: string}, setting: {show_question_number: boolean, allow_summary_view: boolean, is_email: boolean, is_active: boolean, is_shuffled: boolean, allow_multiple_submit: boolean, allow_response_edit: boolean, is_show_logo: boolean, show_app_ad: boolean, notifications: boolean}}}
 */
export const getFormBuilderSkeleton = (builderCreationSource, params) => {
    let title = ''
    let desc = ''
    let welcomeScreenSettings = {
        show_button: true,
        show_screen: true,
        button_text: t(translationKeys.start)
    }
    let questions = []
    let theme = 'classic_theme'
    let responseCount = 0
    let userID = params.userID
    let setting = {
        is_active: true,
        is_public: false,
        show_estimated_time: false,
        show_question_count: false,
        allow_multiple_submit: false,
        allow_response_edit: false,
        allow_summary_view: false,
        is_shuffled: false,
        is_show_logo: false,
        show_question_number: true,
        show_app_ad: true,
        show_progress: true,
        is_email: false,
        notifications: true,
        passcode: '',
    }
    let dateCreated = null
    let dateEdited = null
    let dateFavoured = null

    switch (builderCreationSource) {
        case BUILDER_CREATION_SOURCE.NEW:
            // Do Nothing - Default values above will be used
            break
        case BUILDER_CREATION_SOURCE.TEMPLATE:
            title = params.title
            desc = params.desc
            theme = params.theme
            welcomeScreenSettings = params.welcomeScreenSettings
            questions = params.templateQuestions
            break
    }

    let builderSkeleton = {
        web_app_version: WEB_APP_VERSION,
        setting: setting,
        date_created: dateCreated,
        date_edited: dateEdited,
        date_favoured: dateFavoured,
        welcome_screen: {
            title: title,
            description: desc,
            show_button: welcomeScreenSettings.show_button,
            show_screen: welcomeScreenSettings.show_screen,
            button_text: welcomeScreenSettings.button_text
        },
        pages: [
            {
                title: "Page 1",
                questions: questions
            }
        ],
        user_id: userID,
        theme: theme,
        response_count: responseCount
    }

    return builderSkeleton
}

/**
 *
 * @param builderCreationSource
 * @param params
 * @returns {{is_quizzory_v2: boolean, is_quiz: boolean, quiz_data: {duration: {is_enabled: boolean}, user_info: {user_info_1: string}}, response_count: number, pages: [{questions: *[], title: string}], date_edited: null, user_id: (*|string), date_created: null, theme: string, web_app_version: number, welcome_screen: {show_screen: boolean, show_button: boolean, description: string, button_text: string, title: string}, setting: {show_question_number: boolean, is_email: boolean, allow_summary_view: boolean, is_active: boolean, is_shuffled: boolean, allow_multiple_submit: boolean, is_show_correct_answer: boolean, is_show_logo: boolean, notifications: boolean}}}
 */
export const getQuizBuilderSkeleton = (builderCreationSource, params) => {
    let title = ''
    let desc = ''
    let welcomeScreenSettings = {
        show_button: true,
        show_screen: true,
        button_text: t(translationKeys.start)
    }
    let questions = []
    let theme = 'classic_theme'
    let responseCount = 0
    let userID = params.userID
    let setting = {
        is_active: true,
        is_email: false,
        is_public: false,
        allow_multiple_submit: false,
        allow_response_edit: false,
        is_shuffled: false,
        show_question_number: true,
        allow_summary_view: true,
        is_show_logo: false,
        notifications: true,
        show_app_ad: true,
        show_progress: true,
        is_show_correct_answer: false,
        show_question_count: false,
        show_question_marks: false,
        passcode: '',
    }
    let dateCreated = null
    let dateEdited = null
    let dateFavoured = null

    switch (builderCreationSource) {
        case BUILDER_CREATION_SOURCE.NEW:
            // Do Nothing - Default values above will be used
            break
        case BUILDER_CREATION_SOURCE.TEMPLATE:
            title = params.title
            desc = params.desc
            theme = params.theme
            welcomeScreenSettings = params.welcomeScreenSettings
            questions = params.templateQuestions
            break
    }

    let builderSkeleton = {
        web_app_version: WEB_APP_VERSION,
        setting: setting,
        date_created: dateCreated,
        date_edited: dateEdited,
        date_favoured: dateFavoured,
        welcome_screen: {
            title: title,
            description: desc,
            show_button: welcomeScreenSettings.show_button,
            show_screen: welcomeScreenSettings.show_screen,
            button_text: welcomeScreenSettings.button_text
        },
        pages: [
            {
                title: "Page 1",
                questions: questions
            }
        ],
        user_id: userID,
        theme: theme,
        is_quiz: true,
        is_quizzory_v2: true,
        quiz_data: {
            user_info: {
                user_info_1: t(translationKeys.name_s)
            },
            duration: {
                'is_enabled': false
            }
        },
        response_count: responseCount
    }

    return builderSkeleton
}

let propFound = ''
const findKeyAndValueInJSON = (json, key, value) => {
    for (let property in json) {
        if (json.hasOwnProperty(property)) {
            if (typeof json[property] === "object") {
                findKeyAndValueInJSON(json[property], key, value);
            } else {
                /* if value is true then it will check for the existence of key and value */
                if (value === true && property === key && json[property].length > 0) {
                    propFound = property
                    return
                }
                if (property === key && json[property] === value) {
                    propFound = property
                    return
                }
            }
        }
    }
}
export const getWebAppVersionInfo = (builderType, jsonInput) => {
    let webAppVersion = WEB_APP_VERSION
    let featuresInWebAppOnly = []

    for (let i = 0; i < webFirstFeatures.length; i++) {
        let currentFeature = webFirstFeatures[i]

        if (WEB_FIRST_FEATURES_BUILDER_TYPE[currentFeature.type] === WEB_FIRST_FEATURES_BUILDER_TYPE.BOTH || WEB_FIRST_FEATURES_BUILDER_TYPE[currentFeature.type].key === builderType.key) {
            propFound = null
            findKeyAndValueInJSON(jsonInput, currentFeature.json_signature.key, currentFeature.json_signature.value)

            if (propFound !== null) {
                featuresInWebAppOnly.push(currentFeature.feature)

                if (currentFeature.web_version > webAppVersion) {
                    webAppVersion = currentFeature.web_version
                }
            }
        }
    }

    return {version: webAppVersion, featuresInWebAppOnly: featuresInWebAppOnly}
}