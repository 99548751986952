export function getScoreColor(percentage) {
    let scoreColor;
    if (percentage < 25) {
        scoreColor = "#f44336"
    } else if (percentage >= 25 && percentage < 40) {
        scoreColor = "#ff9800"
    } else if (percentage >= 40 && percentage < 60) {
        scoreColor = "#ffc107"
    } else if (percentage >= 60 && percentage < 80) {
        scoreColor = "#ffeb3b"
    } else if (percentage >= 80 && percentage < 90) {
        scoreColor = "#8bc34a"
    } else if (percentage >= 90 && percentage <= 100) {
        scoreColor = "#4caf50"
    } else {
        scoreColor = "#212121"
    }
    return scoreColor;
}
