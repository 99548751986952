import React, {useState} from 'react'
import MaterialCheckBox from "../../../shared/components/MaterialCheckBox";
import {FORM_SETTINGS} from "./FormBuilderContainer";
import {translationKeys} from "../../../localizations/translationKeys-localization";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {BUILDER_ACTION_TYPE} from "../../reducers/builderReducer";
import ModalDialog from "../../../shared/components/ModalDialog";
import {PremiumJSON} from "../../../shared/utils/helper";
import premiumCrown from "../../../../public/images/premium_crown.svg";
import {PREMIUM_ACTION_TYPE} from "../../reducers/premiumReducer";

const SettingsResponsesContainer = props => {
    const {t} = useTranslation()
    const shared = useSelector(state => state.appReducer.shared)
    const formData = useSelector(state => state.appReducer.formData)
    const subscriptionData = useSelector(state => state.userReducer?.subscriptionData)
    const [showUserInfoPopUp, setShowUserInfoPopUp] = useState(false)
    const [collectEmailAlert, setCollectEmailAlert] = useState(false)
    const [isEmailEnabled, setIsEmailEnabled] = useState(formData?.setting?.is_email)
    const [isResponseEdit, setIsResponseEdit] = useState(formData?.setting?.allow_response_edit)
    const showResponseEditBasedOnUser = shared ? PremiumJSON[subscriptionData?.subscription_plan]?.response_edit && PremiumJSON[formData?.subscription_data?.current_subscription?.plan]?.response_edit : PremiumJSON[subscriptionData?.subscription_plan]?.response_edit

    const dispatch = useDispatch()

    const handleAllowMultipleResponsesChanged = (e) => {
        props.updateSetting(FORM_SETTINGS.ALLOW_MULTIPLE_SUBMIT, e)
    }

    const handlepublicummaryOfAllResponsesChanged = (e) => {
        props.updateSetting(FORM_SETTINGS.ALLOW_SUMMARY_VIEW, e)
    }

    const handleDisabledCheckboxClick = () => {
        if (formData?.is_logic_enabled) {
            dispatch({type: BUILDER_ACTION_TYPE.SET_BUILDER_MESSAGE, payload: 'Action cannot be performed'});
            dispatch({type: BUILDER_ACTION_TYPE.SET_BUILDER_TOAST_MESSAGE, payload: true})
        }
    }

    const handleCollectEmailChange = () => {
        if (!isEmailEnabled) {
            setCollectEmailAlert(true)
        } else {
            if (isResponseEdit) {
                setIsResponseEdit(false)
                props.updateSetting(FORM_SETTINGS.ALLOW_RESPONSE_EDIT, false)
            }
            setIsEmailEnabled(false)
            props.updateSetting(FORM_SETTINGS.COLLECT_EMAIL, false)
        }
    }

    const handleAllowResponseEdit = () => {
        if (!isResponseEdit) {
            setIsResponseEdit(true)
            setIsEmailEnabled(true)
            props.updateSetting(FORM_SETTINGS.COLLECT_EMAIL, true)
            props.updateSetting(FORM_SETTINGS.ALLOW_RESPONSE_EDIT, true)
        } else {
            setIsResponseEdit(false)
            props.updateSetting(FORM_SETTINGS.ALLOW_RESPONSE_EDIT, false)
        }
    }

    return (
        <div id={'settings-responses-and-questions-wrapper'} className="settings-responses-and-questions-wrapper">
            {/* Row 4 */}
            <div id={'settings-responses-box5'}
                 className="settings-responses-box5">
                <MaterialCheckBox isChecked={formData?.setting.allow_multiple_submit}
                                  removeWidth={true}
                                  onChange={handleAllowMultipleResponsesChanged}
                />
            </div>
            <div id={'settings-allow-multiple-responses'}
                 className="settings-responses-box6">
                <img src="../../images/library_books.svg" alt="Library Books"/>
                <div className="settings-bold-text">{t(translationKeys.allow_multiple_responses)}</div>
            </div>
            {/* Row 2 */}
            <div id={'settings-allow-user-respond-more'}
                 className="settings-responses-box8">
                <span
                    className='settings-normal-text'>{t(translationKeys.allow_more_response_alert)}</span>
            </div>
            {/*Row 3*/}
            <div id={'settings-responses-box11'}
                 className="settings-responses-box11" onClick={handleDisabledCheckboxClick}>
                <MaterialCheckBox isChecked={formData?.setting.allow_summary_view}
                                  removeWidth={true}
                                  onChange={handlepublicummaryOfAllResponsesChanged}
                                  isDisabled={formData?.is_logic_enabled}
                />
            </div>
            <div id={'settings-responses-and-questions-box12'}
                 className="settings-responses-and-questions-box12">
                <img src="../../images/donut.svg" alt="Graph"/>
                <div className="settings-bold-text">{t(translationKeys.show_summary)}</div>
            </div>
            {/* Row 4 */}
            <div id={'settings-responses-box13'}
                 className="settings-responses-box13" style={{whiteSpace: "break-spaces"}}>
                <span className='settings-normal-text'>{t(translationKeys.allow_responders_view_summary_alert)}</span>
            </div>
            {/*collect email*/}
            <div id={'settings-responses-box14'}
                 className="settings-responses-box14">
                <MaterialCheckBox isChecked={isEmailEnabled || formData?.setting.is_email}
                                  removeWidth={true}
                                  onChange={handleCollectEmailChange}
                />
            </div>
            <div id={'settings-responses-and-questions-box15'}
                 className="settings-responses-and-questions-box15">
                <img src="../../images/email_blue.svg" alt="email icon"/>
                <div className="settings-bold-text">{t(translationKeys.collect_email)}</div>
            </div>
            <div id={'settings-responses-box16'}
                 className="settings-responses-box16" style={{whiteSpace: "break-spaces"}}>
                <span className='settings-normal-text'>
                    {t(translationKeys.collect_email_description)}
                </span>
            </div>

            {/*response edit form*/}
            <div className={"custom-thank-you"}
                 style={showResponseEditBasedOnUser ? {gridColumnEnd: 3} : {
                     cursor: "pointer",
                     gridColumnEnd: 3
                 }}
                 onClick={() => {
                     if (!showResponseEditBasedOnUser) {
                         if (shared && !PremiumJSON[formData?.subscription_data?.current_subscription?.plan]?.response_edit) {
                             dispatch({type: PREMIUM_ACTION_TYPE.SET_SHOW_FREE_OWNER_POP_UP, payload: true})
                         } else {
                             dispatch({type: PREMIUM_ACTION_TYPE.SET_SHOW_UPGRADE_PLAN_POP_UP, payload: true})
                         }
                     }
                 }}>
                <div id={'settings-responses-box17'}
                     className="settings-responses-box17">
                    <MaterialCheckBox isChecked={isResponseEdit || formData?.setting?.allow_response_edit}
                                      removeWidth={true}
                                      isDisabled={!showResponseEditBasedOnUser}
                                      customStyle={{checkboxBorderColor: !showResponseEditBasedOnUser ? "#9e9e9e" : null}}
                                      onChange={showResponseEditBasedOnUser ? handleAllowResponseEdit : null}
                    />
                </div>
                <div id={'settings-responses-and-questions-box18'}
                     className="settings-responses-and-questions-box18">
                    <img src="../../images/response_edit_blue_icon.svg" alt="edit icon"/>
                    <div className="settings-bold-text">{t(translationKeys.allow_response_edit)}
                        {showResponseEditBasedOnUser ? null : <img src={premiumCrown} alt={"Premium"}/>}
                    </div>
                </div>
                <div id={'settings-responses-box19'}
                     className="settings-responses-box19" style={{whiteSpace: "break-spaces"}}>
                <span className='settings-normal-text'>
                    {t(translationKeys.allow_response_edit_description)}
                </span>
                </div>
            </div>

            {showUserInfoPopUp ? <div style={{whiteSpace: "normal"}}>
                <ModalDialog header={t(translationKeys.user_info_for_file_upload)}
                             body={[t(translationKeys.user_info_for_file_upload_desc1), t(translationKeys.user_info_for_file_upload_desc2)]}
                             buttons={[{
                                 text: t(translationKeys.ok),
                                 action: () => {
                                     setShowUserInfoPopUp(false)
                                 }
                             }]}/>
            </div> : null}
            {collectEmailAlert ? <div style={{whiteSpace: "normal"}}>
                <ModalDialog header={t(translationKeys.collect_email_header)}
                             body={[formData.is_quiz ? t(translationKeys.collect_email_description_quiz) : t(translationKeys.collect_email_description_form), t(translationKeys.collect_email_alert_note)]}
                             buttons={[
                                 {
                                     text: t(translationKeys.cancel).toUpperCase(),
                                     action:
                                         () => {
                                             setCollectEmailAlert(false)
                                         }
                                 },
                                 {
                                     text: t(translationKeys.continue).toUpperCase(),
                                     action:
                                         () => {
                                             setCollectEmailAlert(false)
                                             setIsEmailEnabled(true)
                                             props.updateSetting(FORM_SETTINGS.COLLECT_EMAIL, true)
                                         }
                                 }
                             ]}
                />
            </div> : null}
        </div>
    )
}

export default SettingsResponsesContainer
