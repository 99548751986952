/**
 *
 * @param responseId
 * @param formResponses = res object which is got from the server.
 */

const removeResponse = (responseId, formResponses) => {
    for (let i = 0; i < formResponses.formResponses.respondents.length; i++) {
        if (responseId === formResponses.formResponses.respondents[i]._id) {
            formResponses.formResponses.respondents.splice(i, 1);
            formResponses.formData.response_count = (formResponses.formData.response_count - 1)
            break;
        }
    }
    return formResponses;
}

export default removeResponse