import React, {useEffect, useState} from 'react';
import style from '../css/quiz-timer.module.css'
import {useDispatch, useSelector} from "react-redux";
import {QUESTIONS_ACTION_TYPE} from "../reducers/questionsReducer";

export const FormTimer = (props) => {
    const formData = useSelector(state => state.formDataReducer.formData)
    const dispatch = useDispatch()

    const [timeData, setTimeData] = useState({hours: 0, minutes: 0, seconds: 0});
    const [position, setPosition] = useState('relative');

    useEffect(() => {
        if (formData?.setting?.auto_close_time) {
            const autoCloseTimeInterval = setInterval(function () {
                let now = new Date().getTime();
                let timeDifference = formData?.setting?.auto_close_time - now;
                let hours = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                let minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
                let seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

                setTimeData({
                    hours: hours,
                    minutes: minutes,
                    seconds: seconds
                })

                if (timeDifference < 0) {
                    clearInterval(autoCloseTimeInterval);

                    if (props.setSelectedPreviewScreen) {
                        dispatch({type: QUESTIONS_ACTION_TYPE.SET_FILE_UPLOAD_COMPLETE, payload: true})
                    } else {
                        document.activeElement.blur()
                        props.saveFileUploadsInAWS()
                    }
                }
            }, 1000);
        }

        props.formContainerRef?.current?.addEventListener('scroll', () => {
            if (props.formContainerRef?.current?.scrollTop >= 60) {
                setPosition("absolute")
            } else if (props.formContainerRef?.current?.scrollTop < 60) {
                setPosition("relative")
            }
        });
    }, [])

    if (!formData.setting?.auto_close_time) {
        return null;
    } else {
        return (
            <div id="fixed-quiz-timer" className={style.fixed_quiz_timer} style={{position: position}}>
                <div className={style.quiz_time_holder}>
                <span className={style.quiz_time_value}>
                    {timeData.hours < 10 ? "0" + timeData.hours : timeData.hours}
                </span>
                    <span id="quiz-time-hours" className={style.quiz_time_text}>Hours</span>
                </div>
                <span className={style.quiz_timer_colon_text}>:</span>
                <div className={style.quiz_time_holder}>
                <span className={style.quiz_time_value}>
                    {timeData.minutes < 10 ? "0" + timeData.minutes : timeData.minutes}
                </span>
                    <span id="quiz-time-minutes" className={style.quiz_time_text}>Minutes</span>
                </div>
                <span className={style.quiz_timer_colon_text}>:</span>
                <div className={style.quiz_time_holder}>
                <span id="quiz-time-second" className={style.quiz_time_value}>
                    {timeData.seconds < 10 ? "0" + timeData.seconds : timeData.seconds}
                </span>
                    <span id="quiz-time-seconds" className={style.quiz_time_text}>Seconds</span>
                </div>
            </div>
        )
    }
}
