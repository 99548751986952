import React, {useState} from 'react'
import "../css/import-question-mobile-screen.css"
import back from "../../../../public/images/back_white.svg";
import search from '../../../../public/images/search_white.svg'
import searchByQT from "../../../../public/images/search_by_question_title.svg"
import {IMPORT_QUESTIONS_ACTION_TYPE} from "../../reducers/importQuestionsReducer";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {translationKeys} from "../../../localizations/translationKeys-localization";
import {getDate} from "../../dashboard/utils/get-date";
import useFormImportManager from "../../hooks/useFormImportManager";
import CurrentFormHeader from "../../../shared/components/import-questions/CurrentFormHeader";
import CurrentFormBody from "../../../shared/components/import-questions/CurrentFormBody";
import {BUILDER_ACTION_TYPE} from "../../reducers/builderReducer";
import QuestionsToBeImported from "../../../shared/components/import-questions/QuestionsToBeImported";
import closeImage from "../../../../public/images/close_white.svg";

const ImportQuestionMobileScreen = () => {
    const {t} = useTranslation()
    const [showSearchInput, setShowSearchInput] = useState(false)
    const {
        showForms,
        searchText,
        currentForm,
        showFormsList,
        doSearchImage,
        searchGreyImage,
        clearSearchInput,
        getBackgroundURL,
        searchTextReadonly,
        searchInputReadOnly,
        showNoSearchResults,
        handleFormCardClick,
        searchForTextInForms,
        noSearchResultsFound,
        showSearchAndFormsList,
        handleSearchTextChange
    } = useFormImportManager()
    const isQuiz = useSelector(state => state.appReducer.isQuiz)
    const formData = useSelector(state => state.appReducer.formData)
    const formsList = isQuiz ? useSelector(state => state.appDashboardReducer.quizzesList) : useSelector(state => state.appDashboardReducer?.formsList)
    const questionSearchResults = useSelector(state => state.importQuestionsReducer.questionSearchResults)
    const searchMatches = useSelector(state => state.autocompleteReducer?.matches);
    const dispatch = useDispatch()

    const handleBackClick = () => {
        clearSearchInput()
        if (showSearchInput) {
            setShowSearchInput(false)
        } else if (showSearchAndFormsList) {
            dispatch({type: BUILDER_ACTION_TYPE.SET_NEW_QUESTION, payload: false})
            dispatch({type: IMPORT_QUESTIONS_ACTION_TYPE.SET_QUESTIONS_SEARCH_RESULTS, payload: []})
            dispatch({type: IMPORT_QUESTIONS_ACTION_TYPE.SET_SHOW_MOBILE_IMPORT_QUESTIONS_SCREEN, payload: false})
        } else {
            showFormsList(true)
        }
    }

    return (
        <>
            <div className={"import-question-header"}>
                <img src={back} alt={'back icon'} onClick={handleBackClick}
                     style={{width: '20px', height: '15px', margin: '10px'}}/>
                {showSearchInput ? <>
                        <input className='mobile-search-input' placeholder={t(translationKeys.search)}
                               style={{width: "80%"}}
                               autoFocus={true}
                               value={searchText}
                               onKeyDown={(value) => {
                                   if (value.key === 'Enter' && searchText.trim().length > 3) {
                                       searchForTextInForms()
                                   }
                               }}
                               onChange={(event) => {
                                   handleSearchTextChange(event.target.value)
                               }}/>
                        <img src={closeImage}
                             alt={t(translationKeys.clear)}
                             style={{
                                 width: "25px",
                                 visibility: searchText.length > 0 ? 'visible' : 'hidden'
                             }}
                             onClick={() => clearSearchInput()}
                        />
                    </> :
                    <div className={'import-question-header-title'}>
                        {showSearchAndFormsList ? t(translationKeys.import_questions) : currentForm?.welcome_screen?.title}
                    </div>}
                {showSearchAndFormsList && !showSearchInput ?
                    <img className={"search-icon"} src={search} alt={"search"}
                         onClick={() => {
                             setShowSearchInput(true)
                             dispatch({type: IMPORT_QUESTIONS_ACTION_TYPE.SET_QUESTIONS_SEARCH_RESULTS, payload: []})
                         }}/> : null}
            </div>
            {
                (() => {
                    if (showSearchInput) {
                        if (showNoSearchResults) return (
                            <div style={{
                                height: "85%",
                                display: "flex",
                                flexFlow: "column",
                                alignItems: "center",
                                justifyContent: "center",
                                width: "-webkit-fill-available"
                            }}>
                                <img src={noSearchResultsFound}
                                     alt={t(translationKeys.no_results_found)}
                                     style={{marginBottom: '10px'}}
                                />
                                {t(translationKeys.no_results_found)}
                            </div>
                        )

                        if (questionSearchResults.length > 0) {
                            return (
                                <div style={{marginTop: "15%", width: "100%"}}>
                                    <QuestionsToBeImported availableQuestions={questionSearchResults}/>
                                </div>
                            )
                        } else if (questionSearchResults.length === 0) {
                            return (
                                <div style={{
                                    display: "flex",
                                    marginTop: "15%",
                                    height: "fit-content",
                                    flexDirection: "column",
                                    width: "-webkit-fill-available"
                                }}>
                                    <div
                                        className={'import-questions-input import-questions-input-read-only'}
                                        style={{
                                            height: "fit-content",
                                            width: "-webkit-fill-available",
                                            display: showForms ? 'none' : 'flex'
                                        }}
                                        onClick={() => searchForTextInForms()}
                                    >
                                        <img src={doSearchImage}
                                             alt={t(translationKeys.do_search)}/>
                                        <input type="text" id={searchInputReadOnly}
                                               name={searchInputReadOnly}
                                               value={searchTextReadonly}
                                               readOnly={true}
                                               placeholder={''}
                                               maxLength='75'
                                               style={{
                                                   border: 'none',
                                                   outline: 'none'
                                               }}
                                        />
                                    </div>
                                    {searchMatches.length > 0 ? <div style={{zIndex: 2}}>
                                        {
                                            searchMatches?.map((match, index) => {
                                                return (
                                                    <div
                                                        className={'import-questions-input import-questions-input-read-only'}
                                                        style={{
                                                            width: "-webkit-fill-available",
                                                            padding: '0 14px',
                                                            color: 'black'
                                                        }}
                                                        key={index}
                                                        onClick={() => {
                                                            searchForTextInForms()
                                                            handleSearchTextChange(match)
                                                        }}
                                                    >
                                                        <img
                                                            src={searchGreyImage}
                                                            alt={t(translationKeys.search)}/>
                                                        <div style={{
                                                            padding: '10px 14px',
                                                            width: "-webkit-fill-available",
                                                            borderTop: "1px solid #E1E1E1"
                                                        }}>{match}</div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div> : <div style={{
                                        width: "100%",
                                        height: "80vh",
                                        display: "flex",
                                        alignItems: "center",
                                        flexDirection: "column",
                                        justifyContent: "center"
                                    }}>
                                        <img src={searchByQT} alt={"search-by-question-title"}/>
                                        <div>{isQuiz ? t(translationKeys.search_by_question_title_from_quizzes) : t(translationKeys.search_by_question_title_from_forms)}</div>
                                    </div>}
                                </div>
                            )
                        }
                        return (
                            <div
                                className={'import-questions-input import-questions-input-read-only'}
                                style={{display: showForms ? 'none' : 'flex', marginTop: '15%', height: "fit-content"}}
                                onClick={() => searchForTextInForms()}
                            >
                                <img src={doSearchImage}
                                     alt={t(translationKeys.do_search)}/>
                                <input type="text" id={searchInputReadOnly}
                                       name={searchInputReadOnly}
                                       value={searchTextReadonly}
                                       readOnly={true}
                                       placeholder={''}
                                       maxLength='75'
                                       style={{
                                           border: 'none',
                                           outline: 'none'
                                       }}
                                />
                            </div>
                        )
                    }
                })()
            }
            {!showSearchInput ?
                <div className={"mobile-import-question-forms"}>
                    {formsList?.length ? null :
                        <div style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: '-webkit-fill-available',
                            height: '-webkit-fill-available'
                        }}>
                            <p>{isQuiz ? t(translationKeys.no_quiz_available) : t(translationKeys.no_form_available)}</p>
                        </div>}
                    {showSearchAndFormsList ? React.Children.toArray(formsList?.map((form) => {
                        if (form._id !== formData?._id && form?.is_valid) {
                            return (
                                <div className={'form-card'}
                                     style={{
                                         background: getBackgroundURL(form.theme),
                                         backgroundSize: "100% 250px",
                                         margin: "10px auto",
                                         cursor: 'pointer'
                                     }}
                                     onClick={() => handleFormCardClick(form._id)}
                                >
                                    <div
                                        className='bottom-container'>
                                        <div
                                            className='bottom-text-container'>
                                            <p
                                                id="card-form-title"
                                                className="card-form-title">{form.welcome_screen.title}</p>
                                            <p
                                                id="form-modified-date"
                                                className="secondary-data">{getDate(form.date_edited)}</p>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                    })) : <>
                        <CurrentFormHeader/>
                        <CurrentFormBody/>
                    </>}
                </div> : null}
        </>
    )
}

export default ImportQuestionMobileScreen
