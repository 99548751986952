// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2IjOvrJa60Tj4mwTVWhStt {\n    display: flex;\n    position: fixed;\n    height: 48px;\n    bottom: 0;\n    width: 100%;\n    z-index: 10;\n    align-items: center;\n    justify-content: center;\n    background-color: #FFFFFF;\n}\n\n._2oi964Awb8xnbugVtfoQlC {\n    display: flex;\n    justify-content: center;\n}\n\n._2oi964Awb8xnbugVtfoQlC span{\n    margin-right: 5px;\n}\n\n._3OBJI3IxKf53u7Dc72U40P {\n    display: inline-block;\n    font-size: 12px;\n    font-weight: 500;\n    color: #212121;\n    padding: 4px 12px;\n    border-radius: 8px;\n    background-color: #e8e8e8;\n    margin: 5px 0 0;\n}\n\n._3_3Rth3UiC2VqzdMVAZj_I {\n    margin-left: 8px;\n}\n._2fQgfl7dknLIsNsg5-8F1m {\n    display: none;\n}\n\n@media only screen and (max-width: 600px) {\n    ._2fQgfl7dknLIsNsg5-8F1m {\n        display: initial;\n    }\n    ._2IjOvrJa60Tj4mwTVWhStt {\n        width: 110%;\n    }\n}\n", ""]);
// Exports
exports.locals = {
	"fixed_navigation_bottom": "_2IjOvrJa60Tj4mwTVWhStt",
	"answered_count_container": "_2oi964Awb8xnbugVtfoQlC",
	"chip": "_3OBJI3IxKf53u7Dc72U40P",
	"submit_fixed_button": "_3_3Rth3UiC2VqzdMVAZj_I",
	"submit_fixed_button_container": "_2fQgfl7dknLIsNsg5-8F1m"
};
module.exports = exports;
