import React from "react"
import {removeProp} from "../builder/components/helpers/BuilderHelper";
import {SET_FOCUS} from "../../shared/utils/constants";
import {APP_ACTION_TYPE} from "../reducers/appReducer";
import {useDispatch, useSelector} from "react-redux";
import {isMobileOnly} from "react-device-detect";

const useChoiceMgr = () => {
    const formData = useSelector(state => state.appReducer.formData)
    const dispatch = useDispatch()

    const addOptionValue = (questionIndex, optionIndex) => {
        removeProp([...formData.pages[0].questions], SET_FOCUS)

        if (optionIndex === formData.pages[0].questions[questionIndex].choices.length - 1) {
            // Add Option at the end
            let updatedData = {...formData}
            let updatedQs = [...updatedData.pages[0].questions]

            let choiceQuestion = updatedQs[questionIndex]

            choiceQuestion.choices.push({label: '', setFocus: true})
            choiceQuestion.refreshOptions = true

            updatedQs[questionIndex] = choiceQuestion
            updatedData.pages[0].questions = updatedQs
            dispatch({type: APP_ACTION_TYPE.SET_FORM_DATA, payload: updatedData})
        } else {
            // Add Option somewhere in the middle
            let updatedData = {...formData}
            let choiceQuestion = updatedData.pages[0].questions[questionIndex]

            choiceQuestion.refreshOptions = true
            choiceQuestion.choices.splice(optionIndex + 1, 0, {label: '', setFocus: true})
            updatedData.pages[0].questions[questionIndex] = choiceQuestion
            dispatch({type: APP_ACTION_TYPE.SET_FORM_DATA, payload: updatedData})
        }
    }

    const updateOptionValue = (questionIndex, optionIndex, newValue) => {
        let updatedData = {...formData}

        let optionQuestion = updatedData.pages[0].questions[questionIndex]
        let choice = optionQuestion.choices[optionIndex]

        choice.label = newValue
        optionQuestion.choices[optionIndex] = choice
        updatedData.pages[0].questions[questionIndex] = optionQuestion
        dispatch({type: APP_ACTION_TYPE.SET_FORM_DATA, payload: updatedData})
    }

    const removeOptionValue = (questionIndex, currentIndex) => {
        removeProp([...formData.pages[0].questions], SET_FOCUS)

        if (!isMobileOnly && formData.pages[0].questions[questionIndex].choices.length === 1) {
            // There is only one choice.
            // Clear the text but don't delete it because there must be at least one option present.
            const updatedData = {...formData}

            let choiceQuestion = updatedData.pages[0].questions[questionIndex]

            choiceQuestion.refreshOptions = true
            choiceQuestion.choices[0].label = ""
            choiceQuestion.choices[0].setFocus = true

            updatedData.pages[0].questions[questionIndex] = choiceQuestion

            dispatch({type: APP_ACTION_TYPE.SET_FORM_DATA, payload: updatedData})
        } else {
            const updatedData = {...formData}
            let choiceQuestion = updatedData.pages[0].questions[questionIndex]

            choiceQuestion.refreshOptions = true
            choiceQuestion.choices.splice(currentIndex, 1)

            if (choiceQuestion.choices[currentIndex - 1]) {
                if (updatedData.pages[0].questions[questionIndex].choices.length === currentIndex) {
                    choiceQuestion.choices[currentIndex - 1].setFocus = true
                } else {
                    choiceQuestion.choices[currentIndex].setFocus = true
                }
            }

            updatedData.pages[0].questions[questionIndex] = choiceQuestion

            dispatch({type: APP_ACTION_TYPE.SET_FORM_DATA, payload: updatedData})
        }
    }

    const addOtherOptionForChoiceQuestion = (questionIndex, othersAllowedValue) => {
        formData.pages[0].questions[questionIndex].is_others_allowed = othersAllowedValue
        const updatedData = {...formData}

        updatedData.pages[0].questions[questionIndex].is_others_allowed = othersAllowedValue

        dispatch({type: APP_ACTION_TYPE.SET_FORM_DATA, payload: updatedData})
    }

    const addShufflerOptionsForChoiceQuestion = (questionIndex, shuffleOptionsValue) => {
        formData.pages[0].questions[questionIndex].is_options_shuffled = shuffleOptionsValue
        const updatedData = {...formData}

        updatedData.pages[0].questions[questionIndex].is_options_shuffled = shuffleOptionsValue

        dispatch({type: APP_ACTION_TYPE.SET_FORM_DATA, payload: updatedData})
    }

    const setOptionAttachment = (questionIndex, optionIndex, value) => {
        let updatedData = {...formData}

        updatedData.pages[0].questions[questionIndex].choices[optionIndex].image_attachment = value
        dispatch({type: APP_ACTION_TYPE.SET_FORM_DATA, payload: updatedData})
    }

    return {
        addOptionValue,
        updateOptionValue,
        removeOptionValue,
        setOptionAttachment,
        addOtherOptionForChoiceQuestion,
        addShufflerOptionsForChoiceQuestion
    }
}

export default useChoiceMgr