import React from 'react';
import styles from "../../css/question-attachment.module.css";
import {isMobile, isMobileOnly} from "react-device-detect";
import {isDarkTheme} from "../../utils/theme-color-helper";
import {ATTACHMENT_TYPE, PREVIEW_TYPE} from "../../../shared/utils/constants"
import {useDispatch, useSelector} from "react-redux";
import {SCREENS_ACTION_TYPE} from "../../reducers/screensReducer";

export default function QuestionAttachmentComponent(props) {
    const formData = useSelector(state => state.formDataReducer?.formData)
    const dispatch = useDispatch()

    if (props.attachment) {
        const openAttachmentLink = () => {
            if (props.attachment.url) {
                window.open(props.attachment.url)
            }
            if (props.attachment.file_type === ATTACHMENT_TYPE.IMAGE.key) {
                if (!props.userId) {
                    window.location.hash = "image-viewer"
                }
                dispatch({type: SCREENS_ACTION_TYPE.SET_IMAGE_VIEWER, payload: props.attachment})
            }
        }

        const attachmentContainerBackgroundColor = (() => {
            if (isDarkTheme(formData?.theme)) {
                return '#424242';
            } else {
                return '#eeeeee';
            }
        })();
        const handleImageError = (element) => {
            element.src = "/images/default_image.svg"
        }

        return (
            <div className={styles.question_attachment_container}
                 style={{
                     width: "-webkit-fill-available",
                     display: props.option || props.attachment.file_type !== ATTACHMENT_TYPE.IMAGE.key ? 'block' : 'inline-flex',
                     backgroundColor: props.attachment.file_type !== ATTACHMENT_TYPE.IMAGE.key && props.backgroundColorProvided ? attachmentContainerBackgroundColor : null
                 }}
                 onClick={openAttachmentLink}>
                <div className={styles.question_attachment}
                     style={{
                         display: (props.attachment.file_type
                             === ATTACHMENT_TYPE.WEBSITE.key) ? 'none' : 'flex',
                         justifyContent: props.option ? "flex-start" : null,
                         width: props.option ? "fit-content" : null,
                         margin: props.option ? "0 30px" : null,
                         height: props.option || (props.previewType && !isMobileOnly) ? "100px" : isMobileOnly ? null : '300px',
                         maxHeight: isMobileOnly ? "20vh" : null
                     }}>
                    <div className={styles.question_attachment_video_icon}
                         style={{
                             display: (props.attachment.file_type
                                 === ATTACHMENT_TYPE.VIDEO.key) ? 'flex' : 'none',
                             backgroundColor: 'rgba(0, 0, 0, 0.5)'
                         }}>
                        <i className="material-icons">play_arrow</i>
                    </div>
                    <img src={props.attachment.image_url}
                         alt={'image_Url'}
                         onError={e => handleImageError(e.target)}
                         style={{
                             display: (props.attachment.file_type
                                 === ATTACHMENT_TYPE.WEBSITE.key) ? 'none' : 'flex',
                             width: props.option ? "180px" : (props.attachment.file_type
                                 === ATTACHMENT_TYPE.IMAGE.key) ? isMobileOnly ? '100%' : null : '100%',
                             height: props.imageHeight ? props.imageHeight : isMobile ? '150px' : null,
                             objectFit: 'cover'
                         }}/>
                    <div className={styles.question_attachment_close} style={{
                        display: (props.onCloseClick) ? 'flex' : 'none',
                        width: props.option ? "20px" : null,
                        margin: props.option ? "2px" : null
                    }}
                    >
                        <img style={{width: isMobileOnly ? '22px' : null}}
                             src="images/icons/builder/close_attachment.svg"
                             onClick={(e) => {
                                 props.onCloseClick(e);
                                 e.stopPropagation()
                             }} alt={'Close'}/>
                    </div>
                </div>
                <div className={styles.question_attachment_web_container} style={{
                    display: (props.attachment.file_type === ATTACHMENT_TYPE.IMAGE.key) ? 'none' : 'flex',
                    backgroundColor: isDarkTheme(formData?.theme) ? 'rgba(0, 0, 0, 0.5)' : '#f5f5f5'
                }}>
                    <img style={{
                        display: props.isMobileQuestionPopUp && props.attachment.file_type === ATTACHMENT_TYPE.VIDEO.key ? 'none' : 'flex',
                        width: '30%'
                    }}
                         alt={'image'}
                         onError={e => e.target.src = '/images/link.svg'}
                         src={props.attachment.image_url ? props.attachment.image_url : '/images/link.svg'}/>
                    <div className={styles.question_attachment_text_container}
                         style={{
                             display: (props.attachment.file_type
                                 === ATTACHMENT_TYPE.IMAGE.key) ? 'none' : 'flex',
                             width: props.previewType && (props.previewType === PREVIEW_TYPE.PHONE) ? '70%' : isMobileOnly ? props.isMobileQuestionPopUp ? '80%' : '70%' : '90%'
                         }}>
                        <div className={styles.question_attachment_web_close} style={{
                            display: (props.onCloseClick && (props.attachment.file_type
                                === ATTACHMENT_TYPE.WEBSITE.key)) ? 'flex' : 'none'
                        }}
                        >
                            <img src="images/icons/builder/close_attachment.svg"
                                 style={{width: isMobileOnly ? '22px' : null}}
                                 onClick={(e) => {
                                     props.onCloseClick(e);
                                     e.stopPropagation()
                                 }} alt={'Close'}/>
                        </div>
                        <p className={styles.question_attachment_title}
                           style={{
                               color: isDarkTheme(formData?.theme) ? '#ffffff' : '#212121'
                           }}>
                            {props.attachment.title}
                        </p>
                        <p className={styles.question_attachment_description}>
                            {props.attachment.description}
                        </p>
                        <p id={props.id ? props.id : null} className={styles.question_attachment_url}
                           style={{
                               color: isDarkTheme(formData?.theme) ? '#bdbdbd' : '#0000ee'
                           }}>
                            {props.attachment.url}
                        </p>
                    </div>
                </div>
            </div>
        );
    }
    return null;
}
