import React, {useEffect, useState} from 'react'

const GOOGLE_CLIENT_ID = process.env.REACT_APP_ENV_GOOGLE_CLIENT_ID;
const GOOGLE_SCRIPT_ID = "google-client-script";
const GOOGLE_SCRIPT_SRC = "https://accounts.google.com/gsi/client";

const GoogleLoginButton = (props) => {
    const [scriptLoaded, setScriptLoaded] = useState(false)

    useEffect(() => {
        const script = document.createElement("script")
        script.src = GOOGLE_SCRIPT_SRC;
        script.onload = () => {
            window.google?.accounts.id.initialize({
                client_id: GOOGLE_CLIENT_ID,
                callback: (data) => {
                    handleGoogleResponse(data)
                }
            })
            setScriptLoaded(true)
        }
        script.id = GOOGLE_SCRIPT_ID
        document.querySelector("body")?.appendChild(script)
        return () => {
            window.google?.accounts.id.cancel()
            document.getElementById("google-client-script")?.remove()
        }
    })

    useEffect(() => {
        setScriptLoaded(false)
    }, [props.isSignIn])


    function handleGoogleResponse(response) {
        if (!response.clientId || !response.credential) {
            props.onFailure()
        } else {
            props.onSuccess(response.credential)
        }
    }

    return scriptLoaded ? (
        <div className="g_id_signin"
             data-type="standard"
             data-shape="pill"
             data-theme="filled_blue"
             data-text={"signin_with"}
             data-size="large"
             data-logo_alignment="left"
             dir={"ltr"}>
        </div>
    ) : null
}
export default GoogleLoginButton;
