// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1QxANiMISEqFVO3LtfdV5y {\n    display: flex;\n    justify-content: center;\n}\n\n._7-n7rs2cX53eGlzI0Z_RA {\n    margin: 5px;\n    cursor: pointer;\n}\n\n.fh3KdP0EjZ4OnTRdBQNVx {\n    text-align: center;\n    font-size: 18px;\n}\n\n._2AEdv5WrvsjScMvn7OjEHN {\n    visibility: hidden;\n}", ""]);
// Exports
exports.locals = {
	"smiley_container": "_1QxANiMISEqFVO3LtfdV5y",
	"smiley_image": "_7-n7rs2cX53eGlzI0Z_RA",
	"smiley_visible": "fh3KdP0EjZ4OnTRdBQNVx",
	"smiley_hidden": "_2AEdv5WrvsjScMvn7OjEHN"
};
module.exports = exports;
