import React from 'react'
import SurveyheartIcon from '../../../public/images/surveyheart_new.png'
import '../css/promotional-component.css'

const PromotionalComponent = () => {
    const userAgent = navigator.userAgent || navigator.vendor

    const gotoStore = () => {
        if (/iPad|iPhone|iPod/.test(userAgent)) {
            window.location = "https://apps.apple.com/app/surveyheart-online-form-maker/id6443449067"
        } else {
            window.location = "https://play.google.com/store/apps/details?id=com.surveyheart"
        }
    }

    return (
        <>
            <div className="install-promo" onClick={gotoStore}>
                <img alt={'surveyheart_icon'} src={SurveyheartIcon}/>
                    <div style={{display: 'flex', width: 'auto', flexWrap: 'wrap'}}><label
                        className="install-promo-title">SurveyHeart</label>
                        <label className="install-promo-description">Get the free App to create forms</label></div>
                    <label className="install-promo-button" onClick={gotoStore}>Install</label>
            </div>
        </>
    )
}

export default PromotionalComponent