import React, {useEffect, useRef} from 'react'
import MaterialSelectMenu from "../../../shared/components/MaterialSelectMenu";
import {TimePeriod} from "./FilterPopUpContainer";
import CalendarInputsContainer from "./CalendarInputsContainer";
import * as responseFilter from "../utils/response-filter";
import {translationKeys} from "../../../localizations/translationKeys-localization";
import {t} from "i18next";
import {useSelector} from "react-redux";

function FilterByTimeSection(props) {
    const InputsContainerElement = useRef();
    const FromDateElement = useRef();
    const ToDateElement = useRef();
    const selectedLanguage = useSelector(state => state.languageReducer.selectedLanguage)
    let currentDate = new Date();
    let timeFilterObject = props.timeFilterObjectDataRef.current ? props.timeFilterObjectDataRef.current :{
        model: responseFilter.FILTER_MODEL.TIME,
        tagText: props.selectedTimePeriodOptionRef.current,
        time: {
            selectedTimePeriod:  props.selectedTimePeriodOptionRef.current,
            from: props.summaryDataSet?.responsesInfo[0]?.respondedTime,
            to: currentDate.getTime()
        }
    };

    useEffect(() => {
        props.timeFilterObjectDataRef.current = timeFilterObject;
    }, [])

    const showDateInputsContainer = (timePeriodOption) => timePeriodOption === TimePeriod.CUSTOM_DATE ?
        InputsContainerElement.current.style.display = 'flex'
        : InputsContainerElement.current.style.display = 'none'

    const setTimeFilterObject = timePeriodOption => {
        if (timePeriodOption !== TimePeriod.CUSTOM_DATE) {
            timeFilterObject.time.to = currentDate.getTime() + 86399999 ;
        }
        switch (timePeriodOption) {
            case TimePeriod.CUSTOM_DATE:
                timeFilterObject.time.selectedTimePeriod = TimePeriod.CUSTOM_DATE;
                if (FromDateElement.current.value && ToDateElement.current.value) {
                    timeFilterObject.time.from = new Date(FromDateElement.current.value).getTime();
                    timeFilterObject.time.to = new Date(ToDateElement.current.value ).getTime() + 86399999;
                    if (timeFilterObject.time.from < timeFilterObject.time.to) {
                        timeFilterObject.tagText = FromDateElement.current.value + ' ' + 'to' + ' ' + ToDateElement.current.value;
                        props.timeTagTextElementRef.current.innerText = FromDateElement.current.value + ' ' + 'to' + ' ' + ToDateElement.current.value;
                        props.timeTagTextElementRef.current.style.color = '#212121';
                    } else {
                        props.timeTagTextElementRef.current.innerText = t(translationKeys.invalid_date);
                        props.timeTagTextElementRef.current.style.color = 'red';
                    }
                } else {
                    props.timeTagTextElementRef.current.innerText = FromDateElement?.current.value ? t(translationKeys.to_date_required) : t(translationKeys.from_date_required);
                    props.timeTagTextElementRef.current.style.color = 'red';
                    timeFilterObject.time.from = FromDateElement?.current.value ? new Date(FromDateElement.current.value).getTime() : null;
                    timeFilterObject.time.to = ToDateElement?.current.value ? new Date(ToDateElement.current.value).getTime() + 86399999 : null;
                    timeFilterObject.tagText = 'Date Required';
                }
                break;
            case TimePeriod.LAST_DAY:
                timeFilterObject.time.selectedTimePeriod = TimePeriod.LAST_DAY
                showDateInputsContainer(TimePeriod.LAST_DAY)
                timeFilterObject.time.from = (currentDate.getTime() - (24 * 60 * 60 * 1000));
                timeFilterObject.tagText = TimePeriod.LAST_DAY;
                props.timeTagTextElementRef.current.innerText = TimePeriod.LAST_DAY;
                props.timeTagTextElementRef.current.style.color = '#212121';
                break;
            case TimePeriod.LAST_WEEK:
                timeFilterObject.time.selectedTimePeriod = TimePeriod.LAST_WEEK;
                showDateInputsContainer(TimePeriod.LAST_WEEK);
                timeFilterObject.time.from = (currentDate.getTime() - (7 * 24 * 60 * 60 * 1000));
                timeFilterObject.tagText = TimePeriod.LAST_WEEK;
                props.timeTagTextElementRef.current.innerText = TimePeriod.LAST_WEEK;
                props.timeTagTextElementRef.current.style.color = '#212121';
                break;
            case TimePeriod.LAST_MONTH:
                timeFilterObject.time.selectedTimePeriod = TimePeriod.LAST_MONTH;
                showDateInputsContainer(TimePeriod.LAST_MONTH);
                timeFilterObject.time.from = (currentDate.getTime() - (30 * 24 * 60 * 60 * 1000));
                timeFilterObject.tagText = TimePeriod.LAST_MONTH;
                props.timeTagTextElementRef.current.innerText = TimePeriod.LAST_MONTH;
                props.timeTagTextElementRef.current.style.color = '#212121';
                break;
            case TimePeriod.LAST_YEAR:
                timeFilterObject.time.selectedTimePeriod = TimePeriod.LAST_YEAR;
                showDateInputsContainer(TimePeriod.LAST_YEAR);
                timeFilterObject.time.from = (currentDate.getTime() - (365 * 24 * 60 * 60 * 1000));
                timeFilterObject.tagText = TimePeriod.LAST_YEAR;
                props.timeTagTextElementRef.current.innerText = TimePeriod.LAST_YEAR;
                props.timeTagTextElementRef.current.style.color = '#212121';
                break;
            case TimePeriod.LIFE_TIME:
                timeFilterObject.time.selectedTimePeriod = TimePeriod.LIFE_TIME;
                showDateInputsContainer(TimePeriod.LIFE_TIME);
                timeFilterObject.time.from = (props.summaryDataSet.responsesInfo[0].respondedTime);
                timeFilterObject.tagText = TimePeriod.LIFE_TIME;
                props.timeTagTextElementRef.current.innerText = TimePeriod.LIFE_TIME;
                props.timeTagTextElementRef.current.style.color = '#212121';
                break;
        }
        props.timeFilterObjectDataRef.current = timeFilterObject;
        let tempQuestionFilterObject = props.questionFilterObjectRef.current.length > 0 ? props.questionFilterObjectRef.current.slice(0) : null
        let tempFilterObject;
        if (props.questionFilterObjectRef.current.length > 0) {
            tempFilterObject = tempQuestionFilterObject;
            tempFilterObject.unshift(timeFilterObject)
        } else {
            tempFilterObject = [timeFilterObject];
        }
        if (timeFilterObject.time.from && timeFilterObject.time.to) {
            props.filteredArrayRef.current = responseFilter.getFilteredResponses(tempFilterObject, props.summaryDataSet);
        } else {
            props.filteredArrayRef.current = [];
        }
        props.setIsFilteredArrayChanged(!props.isFilteredArrayChanged)
    }

    const handleTimePeriodSelection = (index, timePeriodOption) => {
        if (timePeriodOption === TimePeriod.CUSTOM_DATE) {
            showDateInputsContainer(TimePeriod.CUSTOM_DATE)
            setTimeFilterObject(timePeriodOption)
        } else {
            setTimeFilterObject(timePeriodOption)
        }
        props.selectedTimePeriodOptionRef.current = timePeriodOption;
    };
    return (
        <React.Fragment>
            <MaterialSelectMenu index={0} initialValueToSelect={props.selectedTimePeriodOptionRef.current}
                                selectedLanguage={selectedLanguage}
                                selectValues={[{
                                    key: TimePeriod.CUSTOM_DATE,
                                    displayText: TimePeriod.CUSTOM_DATE
                                }, {
                                    key: TimePeriod.LAST_DAY,
                                    displayText: TimePeriod.LAST_DAY
                                }, {
                                    key: TimePeriod.LAST_WEEK,
                                    displayText: TimePeriod.LAST_WEEK
                                }, {
                                    key: TimePeriod.LAST_MONTH,
                                    displayText: TimePeriod.LAST_MONTH,
                                }, {
                                    key: TimePeriod.LAST_YEAR,
                                    displayText: TimePeriod.LAST_YEAR
                                }, {
                                    key: TimePeriod.LIFE_TIME,
                                    displayText: TimePeriod.LIFE_TIME
                                }]}
                                customStyle={{height: '35px', width: '98%'}}
                                selectionHandler={handleTimePeriodSelection}/>
            <CalendarInputsContainer FromDate={FromDateElement} ToDate={ToDateElement}
                                     InputsContainerElement={InputsContainerElement}
                                     onChangeFunction={timePeriodOption => setTimeFilterObject(timePeriodOption)}/>
        </React.Fragment>
    )
}

export default FilterByTimeSection