import React from 'react'
import OptionBarContainer from "./OptionBarContainer";
import OtherOptionBar from "./OtherOptionBar";
export default function ResultsContainer(props) {
    const optionBars = getResultOptionBars(props.questionSummaryData, props.answeredCount, props.skippedAnswerCount);
    const descendingOptionBars = optionBars.sort((a, b) => {
        let graphDataA = a.props.response ? a.props.response.graphData[a.props.index] : a.props.otherResponses.length
        let graphDataB = b.props.response ? b.props.response.graphData[b.props.index] : b.props.otherResponses.length
        return (graphDataA === graphDataB) ? 0 : (graphDataA < graphDataB) ? 1 : -1;
    }).slice(0, 4);
    return (
        <div id='result-container-descendingOptionBars' className='result-container'>
            {descendingOptionBars}
        </div>
    );
}
export function getResultOptionBars(questionSummaryData, answeredCount, skippedAnswerCount) {
    let optionBars = []

    if (questionSummaryData.responseAnalyzeData.choiceIdList) {
        optionBars = questionSummaryData.responseAnalyzeData.choiceIdList.map((optionId, i) => {
            return <OptionBarContainer key={optionId} index={i} response={questionSummaryData.responseAnalyzeData}
                                       skippedAnswerCount={skippedAnswerCount}
                                       answeredCount={answeredCount}/>
        })
    }

    const otherOptionBar = questionSummaryData.responseTextAnswers && questionSummaryData.responseTextAnswers.length ?
        <OtherOptionBar key='other' otherResponses={questionSummaryData.responseTextAnswers}
                        skippedAnswerCount={skippedAnswerCount}
                        answeredCount={answeredCount}/> : null;
    otherOptionBar ? optionBars.push(otherOptionBar) : null
    return optionBars;
}