import React, {useEffect, useRef, useState} from "react";
import TextResponsesTable from "./TextResponsesTable";
import QuestionTitleBar from "./QuestionTitleBar";
import {getResultOptionBars} from "./ResultsContainer";
import BarChartContainer from "./BarChartContainer";
import MaterialButton from "../../../shared/components/MaterialButton";
import PoweredBySH from "../../../shared/components/PoweredBySurveyheart";
import {getDateWithTime} from "../../../shared/utils/get-date-with-time";
import {CHART_TYPE} from "./SummaryQuestionCard";
import {RESPONSE_VIEW} from "./ResponseContainer";
import close_black from '../../../../public/images/close_black.svg'
import {translationKeys} from "../../../localizations/translationKeys-localization";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {getBgColors} from "../utils/graph";
import {DonutChart} from "./DonutChart";
import GridResultsContainer from "./GridResultsContainer";
import GroupedBarChartContainer from "./GroupedBarChartContainer";
import StackedBarChartContainer from "./StackedBarChartContainer";
import two_columns_three_rows_active from "../../../../public/images/two_columns_three_rows_active.svg";
import two_columns_three_rows_inactive from "../../../../public/images/two_columns_three_rows_inactive.svg";
import three_columns_active from "../../../../public/images/three_columns_active.svg";
import three_columns_inactive from "../../../../public/images/three_columns_inactive.svg";
import slider_rows_active from "../../../../public/images/slider_rows_active.svg";
import slider_rows_inactive from "../../../../public/images/slider_rows_inactive.svg";

function MobileQuestionDetailedView(props) {
    const {t} = useTranslation()
    const selected = useSelector(state => state.languageReducer?.selectedLanguage)
    const isChoiceType = props.isChoiceType;
    const questionSummaryData = props.questionSummaryData;
    const donutChartRef = useRef()
    let responsesCount;
    let answerCardToShow;
    let chartTitle = ''
    const [chartType, setChartType] = useState(props.chartType);
    if (isChoiceType) {
        responsesCount = questionSummaryData.choiceTextAnswers.length;
        switch (chartType) {
            case CHART_TYPE.RESULT_CARD:
                //result card
                answerCardToShow = getResultOptionBars(questionSummaryData, props.answeredCount, props.skippedAnswerCount);
                break;
            case CHART_TYPE.PIE_CHART:
                //pie chart
                let pieChartLabels = questionSummaryData.responseAnalyzeData.graphLabels.slice(0);
                let pieChartData = questionSummaryData.responseAnalyzeData.graphData.slice(0);
                if (questionSummaryData.responseTextAnswers && questionSummaryData.responseTextAnswers.length > 0) {
                    pieChartLabels.push('Others');
                    pieChartData.push(questionSummaryData.responseTextAnswers.length);
                }
                const colorsArray = getBgColors(pieChartLabels.length)
                answerCardToShow = <>
                    {/*commented skipped percentage because we are not including skipped count in total count. (we are not adding skipped count in calculation of percentage) */}
                    {/*<div style={{margin: "5%", textAlign: "center"}}>{t(translationKeys.no_answer)}: {((props.skippedAnswerCount/responsesCount) * 100).toFixed(2)}%</div>*/}
                    <div style={{height: "50%", overflow: "auto"}}>
                        {React.Children.toArray(pieChartLabels.map((label, index) => {
                            return <li style={{display: "inline-flex"}}>
                                <div style={{background: colorsArray[index], width: 0, padding: "12px", borderRadius: "100%", webkitPrintColorAdjust: "exact"}}></div>
                                <span style={{margin: "0 5px"}}>{label}</span>
                            </li>
                        }))}
                    </div>
                </>
                break;
            case CHART_TYPE.BAR_CHART:
                //bar chart
                let barChartLabels = questionSummaryData.responseAnalyzeData.graphLabels.slice(0);
                let barChartData = questionSummaryData.responseAnalyzeData.graphData.slice(0);
                if (questionSummaryData.responseTextAnswers && questionSummaryData.responseTextAnswers.length > 0) {
                    barChartLabels.push('Others');
                    barChartData.push(questionSummaryData.responseTextAnswers.length);
                }
                answerCardToShow = <BarChartContainer graphLabels={barChartLabels} graphData={barChartData}/>
                break;
            case CHART_TYPE.TABULAR_CHART:
                responsesCount = props.respondersInfo.length
                chartTitle = t(translationKeys.tabular_chart)
                answerCardToShow = <GridResultsContainer questionSummaryData={questionSummaryData}
                                                         answeredCount={questionSummaryData.responseCount}
                                                         showAllValues={true}
                />
                break
            case CHART_TYPE.GROUPED_BAR_CHART:
                responsesCount = props.respondersInfo.length
                chartTitle = t(translationKeys.grouped_bar_chart)
                answerCardToShow = <GroupedBarChartContainer graphData={questionSummaryData}/>
                break
            case CHART_TYPE.STACKED_BAR_CHART:
                responsesCount = props.respondersInfo.length
                chartTitle = t(translationKeys.stacked_bar_chart)
                answerCardToShow = <StackedBarChartContainer graphData={questionSummaryData}
                                                             originalFormRespondents={props.respondentsDataRef.current}
                />
                break
        }
    } else {
        const textResponses = questionSummaryData.responseTextAnswers
        responsesCount = textResponses.length;
        answerCardToShow = <TextResponsesTable responses={textResponses} respondersInfo={props.respondersInfo}/>
    }

    useEffect(() => {
        if (donutChartRef.current) {
            const donutData = questionSummaryData.responseAnalyzeData.graphData.slice(0)
            donutData.push(questionSummaryData.responseTextAnswers.length)
            DonutChart(donutChartRef.current, donutData, responsesCount - props.skippedAnswerCount);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [donutChartRef, chartType]);

    return (
        <div className='detailed-summary-card'>
            <div className='summary-form-title-container printing-title'>
                                    <span id='summary-card-date-with-time' style={{
                                        margin: '15px 10px',
                                        textAlign: 'center'
                                    }}>{props.formTitle ? props.formTitle : ""} - {getDateWithTime((new Date()).getTime())}</span>
            </div>
            <div style={{width: '90%' }}>
                <QuestionTitleBar isChoiceType={isChoiceType} questionSNo={props.questionSNo}
                                  detailedView={true}
                                  showChartIcons={props.showChartIcons}
                                  questionTitle={questionSummaryData.questionTitle} chartType={chartType}
                                  handleClick={setChartType}/>
            </div>
            <div className='sub-header'>
                <div className='response-count-container'>
                    <span id='response-count-container-response' style={{color: '#212121', fontSize: '14px', margin: '0'}}>
                        {responsesCount && responsesCount > 1 ? t(translationKeys.responses) : t(translationKeys.response)}
                    </span>
                    <div className='box' style={{border: '1px solid #1976D2'}}>
                        {responsesCount ? responsesCount : 0}
                    </div>
                </div>
                {props.formFiller ? null :
                    <div className='summary-detailed-right-aligned-container'>
                        <MaterialButton data={{title: t(translationKeys.tabular),
                            customButtonContainerStyle: {
                                minWidth: '0',
                            },
                            fontSize:   '14px' , imageFileName: 'table_icon.svg'}}
                                        handleClick={() => {
                                            props.showDetailedPopUp(false);
                                            props.changeResponseView(RESPONSE_VIEW.TABULAR);
                                        }}/>
                        <div style={isChoiceType ? {paddingLeft:  '10px' , marginRight: '4%'} : {paddingLeft: '10px' }}>
                            <MaterialButton data={{title:  '' , imageFileName: 'print_icon.svg',
                                customButtonContainerStyle: {
                                    minWidth: '0',
                                    padding: '0 10px 0 15px'
                                }, customImageStyle: {
                                    marginRight: '0'
                                }}}
                                            handleClick={() => window.print()}/>
                        </div>
                    </div>
                }
            </div>
            {props.showChartIcons && isChoiceType ?
                <div style={{display: 'flex', justifyContent: 'center', marginTop: '20px'}}>
                    <img className='chart-icons' alt='results' onScroll={e => e.stopPropagation()}
                         src={(chartType === CHART_TYPE.RESULT_CARD) ? '../../../images/summary_result_active.svg' : '../../../images/summary_result_inactive.svg'}
                         onClick={() => setChartType(CHART_TYPE.RESULT_CARD)}
                    />
                    <img className='chart-icons' alt='pie-chart' onScroll={e => e.stopPropagation()}
                         src={ (chartType === CHART_TYPE.PIE_CHART) ? '../../../images/pie_chart_active.svg' : '../../../images/pie_chart_inactive.svg' }
                         onClick={() => setChartType(CHART_TYPE.PIE_CHART)}
                    />
                    <img className='chart-icons' alt='bar-chart' onScroll={e => e.stopPropagation()}
                         src={ (chartType === CHART_TYPE.BAR_CHART) ? '../../../images/bar_chart_active.svg' : '../../../images/bar_chart_inactive.svg'}
                         onClick={() => setChartType(CHART_TYPE.BAR_CHART)}
                    />
                </div> : null}
            {chartType === CHART_TYPE.PIE_CHART ? <div id='answers-container-cardToShow' className='answers-container' style={{overflow: "hidden"}} onScroll={e => e.stopPropagation()}
                                                       ref={donutChartRef}/> : null}
            <div className='answers-container' style={{height: chartType !== CHART_TYPE.PIE_CHART ? "75%" : null, maxHeight: chartType !== CHART_TYPE.PIE_CHART ? "75%" : null}} onScroll={e => e.stopPropagation()}>
                {props.isGridType ? <div style={{display: "flex", justifyContent: "center"}}>
                    <img className='chart-icons' alt='results' onScroll={e => e.stopPropagation()}
                         src={(chartType === CHART_TYPE.TABULAR_CHART) ? two_columns_three_rows_active : two_columns_three_rows_inactive}
                         onClick={() => setChartType(CHART_TYPE.TABULAR_CHART)}
                    />
                    <img className='chart-icons' alt='pie-chart' onScroll={e => e.stopPropagation()}
                         src={(chartType === CHART_TYPE.GROUPED_BAR_CHART) ? three_columns_active : three_columns_inactive}
                         onClick={() => setChartType(CHART_TYPE.GROUPED_BAR_CHART)}
                    />
                    <img className='chart-icons' alt='bar-chart' onScroll={e => e.stopPropagation()}
                         src={(chartType === CHART_TYPE.STACKED_BAR_CHART) ? slider_rows_active : slider_rows_inactive}
                         onClick={() => setChartType(CHART_TYPE.STACKED_BAR_CHART)}
                    />
                </div> : null}
                {answerCardToShow}
                <PoweredBySH/>
            </div>
            <img className='close-button' style={(selected === 'fa' || selected === 'ar') ? {right: '85%'} : null}
                 alt='close-button'
                 src={close_black}
                 onClick={() => props.showDetailedPopUp(false)}
            />
        </div>
    );
}

export default MobileQuestionDetailedView
