import {QUESTION_TYPE} from "../../shared/utils/constants";

export const shuffleQuestions = (questions) => {
    let shuffledQuestions = [];
    let chunkedQuestionListBySection = chunkQuestionsBySection();
    for (let i = 0; i < chunkedQuestionListBySection.length; i++) {
        let currentSection = chunkedQuestionListBySection[i];
        if (currentSection.questions && currentSection.questions.length > 0) {
            chunkedQuestionListBySection[i].questions = shuffleArray(currentSection.questions);
        }
        if (currentSection.sectionContent) {
            shuffledQuestions.push(currentSection.sectionContent);
        }
        for (let j = 0; j < currentSection.questions.length; j++) {
            shuffledQuestions.push(currentSection.questions[j]);
        }
    }
    return shuffledQuestions;

    function chunkQuestionsBySection() {
        let chunkedQuestionList = [];
        let sectionData;
        let sectionQuestions = [];
        for (let i = 0; i < questions.length; i++) {
            let currentQuestion = questions[i];
            if (currentQuestion.type === QUESTION_TYPE.SECTION.key) {
                if (sectionData) {
                    sectionData.questions = sectionQuestions;
                } else {
                    sectionData = {
                        questions: sectionQuestions
                    }
                }
                chunkedQuestionList.push(sectionData);
                sectionData = null;
                sectionQuestions = [];
                sectionData = {
                    sectionContent: currentQuestion
                }
            } else {
                sectionQuestions.push(currentQuestion)
            }
            if (i === (questions.length - 1)) {
                if (sectionData) {
                    sectionData.questions = (sectionQuestions.length > 0) ? sectionQuestions : [];
                } else {
                    sectionData = {questions: (sectionQuestions.length > 0) ? sectionQuestions : []};
                }
                chunkedQuestionList.push(sectionData);
            }
        }
        return chunkedQuestionList;
    }
}

export function shuffleArray(array) {
    let j, x, i;
    for (i = array.length - 1; i > 0; i--) {
        j = Math.floor(Math.random() * (i + 1));
        x = array[i];
        array[i] = array[j];
        array[j] = x;
    }
    return array;
}
