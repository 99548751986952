// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._19VvBWgl2vTeN1TP4lyem3 {\n    width: 90%;\n    height: 85%;\n    background-color: #ffffff;\n}\n\n._1M3AE4Zr1nDlJ6UZfFiYo2 {\n    width: 100%;\n    padding: 0;\n    margin: 0;\n    align-items: center;\n    justify-content: center;\n    display: flex\n}\n\n._2dKOXAy1sfvOyKSBZSbBz2 {\n    height: 80%;\n    overflow: auto;\n}\n\n._2dKOXAy1sfvOyKSBZSbBz2::-webkit-scrollbar {\n    width: 5px;\n    height: 0;\n}\n\n._2dKOXAy1sfvOyKSBZSbBz2::-webkit-scrollbar-track {\n    background: #eeeeee;\n}\n\n._2dKOXAy1sfvOyKSBZSbBz2::-webkit-scrollbar-thumb {\n    background: #757575;\n}\n\n._1-GrkZ6B7rdAUnKQ529wm_ {\n    padding-left: 10px;\n    padding-right: 10px;\n}\n\n._1-GrkZ6B7rdAUnKQ529wm_ p {\n    margin: 0;\n    font-size: 20px;\n}\n\n._1-GrkZ6B7rdAUnKQ529wm_ h2 {\n    margin-top: 25px;\n    margin-bottom: 5px;\n}\n\n@media only screen and (min-width: 600px) {\n    ._19VvBWgl2vTeN1TP4lyem3 {\n        width: 60%;\n        height: 75%;\n    }\n\n    ._1-GrkZ6B7rdAUnKQ529wm_ {\n        padding-left: 20px;\n        padding-right: 20px;\n    }\n}\n", ""]);
// Exports
exports.locals = {
	"about_content_popup_container": "_19VvBWgl2vTeN1TP4lyem3",
	"about_content_popup_image_banner": "_1M3AE4Zr1nDlJ6UZfFiYo2",
	"about_content_popup_body": "_2dKOXAy1sfvOyKSBZSbBz2",
	"content_text_container": "_1-GrkZ6B7rdAUnKQ529wm_"
};
module.exports = exports;
