import React from 'react'
import ReactPaginate from 'react-paginate';
import '../css/paginator.css';
import {isMobileOnly} from "react-device-detect";
import {useTranslation} from "react-i18next";
import {translationKeys} from "../../localizations/translationKeys-localization";

const Paginator = (props) => {
    const {t} = useTranslation()
    return  <ReactPaginate
        previousLabel={t(translationKeys.previous)}
        nextLabel={t(translationKeys.next)}
        breakLabel={'...'}
        initialPage={props.initialPage}
        pageCount={props.totalPageCount}
        pageRangeDisplayed={isMobileOnly ? 1 : 3}
        onPageChange={props.handlePageChange}
        containerClassName={'pagination-container'}
        pageClassName={'each-page'}
        activeClassName={'active'}
        nextClassName={'next-button'}
        previousClassName={'previous-button'}
        breakClassName={'each-page'}/>
}

export default Paginator