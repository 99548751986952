import React, {useEffect, useRef} from 'react'
import '../css/material-select-menu.css'
import {MDCSelect} from "@material/select/index"

const MaterialSelectMenu = (props) => {
    const selectedLanguage = props.selectedLanguage;
    const mdcSelectMenuRef = useRef(null);
    const mdcAnchorIconRef = useRef(null);
    const mdcAnchorText = useRef(null);

    useEffect(() => {
        if (props.index === undefined) {
            console.error("MaterialSelectMenu.js: props.index={number} is required in Parent component.")
        }

        if (props.selectValues === undefined) {
            console.error("MaterialSelectMenu.js: props.selectValues={JSON Data} is required in Parent component.")
        }

        if (props.selectionHandler === undefined) {
            console.error("MaterialSelectMenu.js: props.selectionHandler={targetFunction} is required in Parent component.")
        }

        if (props.refSelectMenu) {
            props.refSelectMenu.current = mdcSelectMenuRef.current
        }

        let select = new MDCSelect(mdcSelectMenuRef.current)
        if (props.initialValueToSelect !== undefined) {
            select.value = props.initialValueToSelect
        }

        updateImageInfo(props.initialImage, props.initialImageAltText)
    }, [])

    useEffect(() => {
        let select = new MDCSelect(mdcSelectMenuRef.current)

        if (props.initialValueToSelect !== undefined) {
            select.value = props.initialValueToSelect

            let imgInfo = props.selectValues.filter(value => value.key === select.value)[0]

            if (imgInfo) {
                updateImageInfo(imgInfo.imgPath, imgInfo.altText);
            }
        }
    }, [props.initialValueToSelect])

    function handleMDCSelect(selectedValue, displayingText) {
        let imgInfo = props.selectValues.filter(value => value.key === selectedValue)[0]

        updateImageInfo(imgInfo.imgPath, imgInfo.altText);

        props.selectionHandler(props.index, selectedValue, displayingText)
    }

    function updateImageInfo(imgPath, altText) {
        if (imgPath === undefined) {
            mdcAnchorIconRef.current.style.display = "none"
            if ((selectedLanguage === 'fa' || selectedLanguage === 'ar')) {
                mdcAnchorText.current.style.paddingRight = "15px"
            } else {
                mdcAnchorText.current.style.paddingLeft = "15px"
            }
        } else {
            mdcAnchorIconRef.current.src = imgPath
        }

        if (altText !== undefined) {
            mdcAnchorIconRef.current.alt = altText
        }
    }

    const selectMenuWidth = props.customStyle && props.customStyle.width ? props.customStyle.width : undefined;
    const selectMenuDropDownWidth = props.customStyle && props.customStyle.dropDownWidth ? props.customStyle.dropDownWidth : props.customStyle && props.customStyle.width ? props.customStyle.width : undefined;
    const selectMenuHeight = props.customStyle && props.customStyle.height ? props.customStyle.height : undefined;
    return (
        <div id={'dropdown' + props.index} className={props.disabled ? "mdc-select mdc-select--outlined mdc-select--with-leading-icon mdc-select--disabled" : "mdc-select mdc-select--outlined mdc-select--with-leading-icon"}
             style={{
                 width: selectMenuWidth, height: selectMenuHeight,
                 "--mdc-theme-primary": props.customStyle ? props.customStyle.themeColor : '#212121',
                 backgroundColor: props.customStyle ? props.customStyle.backgroundColor : null,
             }}
             ref={mdcSelectMenuRef}
        >
            <div id={'material-select-menu'} className="mdc-select__anchor" aria-labelledby="outlined-select-label"
                 style={{width: selectMenuWidth, height: selectMenuHeight}}
            >
                <img alt='select' className="mdc-select__icon" ref={mdcAnchorIconRef}/>
                <span className="mdc-select__selected-text" ref={mdcAnchorText}
                      style={{
                          color: props.customStyle ? props.customStyle.selectedTextColor : null,
                        //  minWidth: props.setMinWidth ? 'max-content' : null
                      }}/>
                <span className="mdc-select__dropdown-icon"
                      style={{fill: props.customStyle ? props.customStyle.selectedTextColor : null,}}>
                    <svg
                        className="mdc-select__dropdown-icon-graphic"
                        viewBox="7 10 10 5">
                        <polygon
                            className="mdc-select__dropdown-icon-inactive"
                            stroke="none"
                            fillRule="evenodd"
                            points="7 10 12 15 17 10">
                        </polygon>
                        <polygon
                            className="mdc-select__dropdown-icon-active"
                            stroke="none"
                            fillRule="evenodd"
                            points="7 15 12 10 17 15">
                        </polygon>
                    </svg>
                </span>
                <span className="mdc-notched-outline">
                    <span className="mdc-notched-outline__leading"
                          style={{borderColor: props.customStyle ? props.customStyle.selectedTextColor : null}}/>
                    <span className="mdc-notched-outline__notch"
                          style={{borderColor: props.customStyle ? props.customStyle.selectedTextColor : null}}/>
                    <span className="mdc-notched-outline__trailing"
                          style={{borderColor: props.customStyle ? props.customStyle.selectedTextColor : null}}/>
                </span>
            </div>
            <div className="mdc-select__menu mdc-menu mdc-menu-surface mdc-menu-surface--fullwidth"
                 style={{width: selectMenuDropDownWidth}} role="listbox">
                <ul className="mdc-list">
                    {
                        (() => {
                            return (
                                props.selectValues.map((value, index) => (
                                        <div id={'mdc_list_' + index} style={{
                                            display: 'flex',
                                            borderBottom: props.selectOnOptionCardClick && ((index + 1) !== props.selectValues.length) ? '1px solid #e0e0e0' : null
                                        }} key={index} onClick={() => {
                                            handleMDCSelect(value.key, value.displayText ? value.displayText : undefined)
                                        }}>
                                            <img className="mdc-list-image"
                                                 style={{display: value.imgPath === undefined ? "none" : "block"}}
                                                 src={value.imgPath}
                                                 alt={value.altText}
                                            />
                                            <li id={'mdc_list_item_' + index} className="mdc-list-item mdc-list-item-override"
                                                style={{height: selectMenuHeight}}
                                                data-value={value.key}
                                                role="option">
                                                <span className="mdc-list-item__text"
                                                      style={{paddingLeft: value.imgPath === undefined ? "15px" : ""}}>{value.displayText}</span>
                                            </li>
                                        </div>
                                    )
                                ))
                        })()
                    }
                </ul>
            </div>
        </div>
    )
}

export default MaterialSelectMenu
