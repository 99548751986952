import React, {useState} from "react"
import {useDispatch, useSelector} from "react-redux"
import cancelBlack from "../../../../public/images/icons/builder/cancel_black.svg"
import GridBodyRows from "./GridBodyRows"
import GridBodyColumns from "./GridBodyColumns"
import useGridMgr from "../../hooks/useGridMgr"
import MaterialButton, {ButtonTypes} from "../../../shared/components/MaterialButton"
import {translationKeys} from "../../../localizations/translationKeys-localization"
import {t} from "i18next"
import {APP_ACTION_TYPE} from "../../reducers/appReducer"
import {BUILDER_ACTION_TYPE} from "../../reducers/builderReducer";

const MobileGridBody = (props) => {
    const reduxDispatch = useDispatch()

    const [toggleRefresh, setToggleRefresh] = useState(false)
    const [showRemoveRowButton, setShowRemoveRowButton] = useState(true)
    const [showRemoveColumnButton, setShowRemoveColumnButton] = useState(true)

    const {addItem, COMPONENT_TYPE, removeItem} = useGridMgr()
    const formData = useSelector(state => state.appReducer.formData)
    const columnsLength = useSelector(state => state.appReducer.formData?.pages?.[0]?.questions?.[props.index]?.columns?.length)
    const rowsLength = useSelector(state => state.appReducer.formData?.pages?.[0]?.questions?.[props.index]?.rows?.length)

    const colButtons = () => {
        return (
            (() => {
                if (columnsLength > 1 && showRemoveColumnButton) {
                    return (
                        <img src={cancelBlack}
                             alt={"Delete Line"}
                             className="option-line-end-img"
                             onClick={(e) => {
                                 if (formData.response_count > 0 && props.refSavedData?.current?.pages[0]?.questions?.filter(question => question._id === props.question._id).length) {
                                     reduxDispatch({
                                         type: BUILDER_ACTION_TYPE.SET_SHOW_OPTION_DELETE_POP_UP,
                                         payload: true
                                     })
                                     reduxDispatch({
                                         type: BUILDER_ACTION_TYPE.SET_OPTION_DELETE_ACTION, payload: () => {
                                             removeItem(props.index, COMPONENT_TYPE.COLUMN, parseInt(e.target.parentElement.id.split("-").pop()), setShowRemoveColumnButton)
                                             setToggleRefresh(!toggleRefresh)
                                         }
                                     })
                                 } else {
                                     setShowRemoveColumnButton(false)
                                     removeItem(props.index, COMPONENT_TYPE.COLUMN, parseInt(e.target.parentElement.id.split("-").pop()), setShowRemoveColumnButton)
                                     setToggleRefresh(!toggleRefresh)
                                 }
                             }}
                        />
                    )
                }
            })()
        )
    }

    const rowButtons = () => {
        return (
            (() => {
                if (rowsLength > 1 && showRemoveRowButton) {
                    return (
                        <img src={cancelBlack}
                             alt={"Delete Line"}
                             className="option-line-end-img"
                             onClick={(e) => {
                                 if (formData.response_count > 0 && props.refSavedData?.current?.pages[0]?.questions?.filter(question => question._id === props.question._id).length) {
                                     reduxDispatch({
                                         type: BUILDER_ACTION_TYPE.SET_SHOW_OPTION_DELETE_POP_UP,
                                         payload: true
                                     })
                                     reduxDispatch({
                                         type: BUILDER_ACTION_TYPE.SET_OPTION_DELETE_ACTION, payload: () => {
                                             removeItem(props.index, COMPONENT_TYPE.ROW, parseInt(e.target.parentElement.id.split("-").pop()), setShowRemoveRowButton)
                                             setToggleRefresh(!toggleRefresh)
                                         }
                                     })
                                 } else {
                                     setShowRemoveRowButton(false)
                                     removeItem(props.index, COMPONENT_TYPE.ROW, parseInt(e.target.parentElement.id.split("-").pop()), setShowRemoveRowButton)
                                     setToggleRefresh(!toggleRefresh)
                                 }
                             }}
                        />
                    )
                }
            })()
        )
    }

    return (
        <>
            <GridBodyRows index={props.index} buttons={rowButtons()}/>
            <div style={{display: "flex"}}>
                <MaterialButton
                    data={{
                        buttonType: ButtonTypes.CONTAINED,
                        title: t(translationKeys.add_row),
                        customButtonContainerStyle: {
                            borderRadius: '35px'
                        },
                        customButtonTextStyle: {
                            fontFamily: 'Nunito Sans, Sans-serif, serif'
                        }
                    }}
                    handleClick={() => {
                        if (rowsLength >= 20) {
                            reduxDispatch({
                                type: APP_ACTION_TYPE.SET_SHOW_COLUMN_OR_ROW_LIMIT_REACHED_POP_UP,
                                payload: true
                            })
                        } else {
                            addItem(props.index, COMPONENT_TYPE.ROW)
                        }
                    }
                    }
                />
            </div>
            <div style={{height: "15px", visibility: "hidden"}}>spacer</div>
            <GridBodyColumns index={props.index} buttons={colButtons()}/>
            <div style={{display: "flex"}}>
                <MaterialButton
                    data={{
                        buttonType: ButtonTypes.CONTAINED,
                        title: t(translationKeys.add_column),
                        customButtonContainerStyle: {
                            borderRadius: '35px'
                        },
                        customButtonTextStyle: {
                            fontFamily: 'Nunito Sans, Sans-serif, serif'
                        }
                    }}
                    handleClick={() => {
                        if (columnsLength >= 20) {
                            reduxDispatch({
                                type: APP_ACTION_TYPE.SET_SHOW_COLUMN_OR_ROW_LIMIT_REACHED_POP_UP,
                                payload: true
                            })
                        } else {
                            addItem(props.index, COMPONENT_TYPE.COLUMN)
                        }
                    }
                    }
                />
            </div>
        </>
    )
}

export default MobileGridBody