import axios from "axios";

const token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySW5kZXhJZCI6InZpZ25lc2hAc3VydmV5aGVhcnQuY29tIiwiaWF0IjoxNjA5NDI0MjU1fQ.GGWevFODDtSlUVfkL8e0SnM3VGVJ3pvOoDnzP_CiLzQ';
export const makeServerRequest = (requestURL, requestMethod, data, successCallBackFunction, failureCallBackFunction) => {
    const requestConfiguration = {
        method: requestMethod,
        url: requestURL,
        data: data,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'authorization': 'Bearer ' + token
        }
    };
    axios(requestConfiguration)
        .then(
            function (serverResponse) {
                successCallBackFunction(serverResponse.data, serverResponse.status)
            }
        )
        .catch(function (err) {
            console.log(err)
            failureCallBackFunction(err.response)
        })
}
