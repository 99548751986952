import React, {useRef, useState, useEffect} from "react"
import QuestionHeader from "./QuestionHeader"
import QuestionBody from "./QuestionBody"
import QuestionFooter from "./QuestionFooter"
import {
    QUESTION_GROUPS,
    QUESTION_TYPE,
    getQuestionGroupV2, ATTACHMENT_TYPE
} from "../../../shared/utils/constants"
import '../../../shared/css/material-card.css'
import QuestionTypePopup from "../../../shared/components/QuestionTypePopup"
import {isMobile, isMobileOnly} from "react-device-detect";
import {MDCSelect} from "@material/select/index"
import {popSelectValues} from "./helpers/BuilderContainerHelper"
import {useDispatch, useSelector} from "react-redux"
import MaterialAutocompleteSuggestions from "../../../shared/components/MaterialAutocompleteSuggestions"
import {AUTOCOMPLETE_ACTION_TYPE} from "../../reducers/autocompleteReducer"
import ImagesContainer from "./ImagesContainer";
import {AWS_FOLDER_NAMES, AWS_UPLOAD_TYPE, MEDIA_BASE_URL} from "../utils/aws";
import {getDataFromUrl} from "../../../shared/utils/sash-v2-api";
import UploadLimitReachedModalDialog from "../../../shared/components/UploadLimitReachedModialDialog";
import PopUpContainer from "../../../shared/components/PopUpContainer";
import QuestionAttachmentComponent from "../../../form/components/questions/QuestionAttachmentComponent";
import useQuestionMgr from "../../hooks/useQuestionMgr";
import useChoiceMgr from "../../hooks/useChoiceMgr";
import useLogicJumpMgr from "../../hooks/useLogicJumpMgr";
import {BUILDER_ACTION_TYPE} from "../../reducers/builderReducer";
import {PremiumJSON} from "../../../shared/utils/helper";
import {PREMIUM_ACTION_TYPE} from "../../reducers/premiumReducer";

const QuestionContainer = React.memo(function QuestionContainer(props) {
    const [showImageLimitReached, setShowImageLimitReached] = useState(false)
    const [attachmentType, setAttachmentType] = useState(null)
    const [showAddImage, setShowAddImage] = useState(false)
    const [showMyImages, setShowMyImages] = useState(false)
    const [imageURL, setImageURL] = useState('')
    const [showQTMenu, setShowQTMenu] = useState(false)
    const {setQuestionTitle, removeAttachmentInfo,} = useQuestionMgr()
    const {setOptionAttachment} = useChoiceMgr()
    const [focusQuestion, setFocusQuestion] = useState(false)
    const [toggleRefresh, setToggleRefresh] = useState(false)

    const ref = useRef(null)
    const refQuestionCard = useRef(null)
    const refQuestionCardAddQuestion = useRef(null)
    const refQuestionCardDragIndicator = useRef(null)
    const refSelectMenu = useRef(null)

    const subscriptionData = useSelector(state => state.userReducer?.subscriptionData)
    const maxImageUploadLimit = PremiumJSON[subscriptionData?.subscription_plan]?.question_attachments
    const searchMatches = useSelector(state => state.autocompleteReducer?.matches);
    const questionIndex = useSelector(state => state.optionsReducer.currentQuestionIndex)
    const choiceIndex = useSelector(state => state.optionsReducer.currentOptionIndex)
    const selectedValue = useSelector(state => state.autocompleteReducer?.selectedValue)
    const currentTextBoxIndex = useSelector(state => state.autocompleteReducer?.currentTextBoxIndex)
    const reduxDispatch = useDispatch()
    const formData = useSelector(state => state.appReducer?.formData)

    const {questionRequiredChanged} = useQuestionMgr()
    const {addLogicJumpToQuestion} = useLogicJumpMgr()


    useEffect(() => {
        if (formData.pages[0].questions.length === 1 && formData.is_logic_enabled) {
            questionRequiredChanged(0, false)
            addLogicJumpToQuestion(0, false)
        }
    })

    let enter = false

    document.onkeydown = (e) => {
        if (e.key === "Enter") {
            enter = true
        }
        if (e.key === "Escape") {
            props.refreshQTMenuDisplay(-1)
        } else if (window.event.ctrlKey && e.key === "/") {
            e.preventDefault()
            handleOutSideClick()
            handleAddQuestionTypesImageClick()
        } else if (window.event.ctrlKey && (e.key === "s" || e.key === "S")) {
            e.preventDefault()
            document.getElementById("left-nav-icon-container-3").click()
        }
    }

    document.onkeyup = (e) => {
        if (e.key === "Enter") {
            enter = false
        }
    }

    const currentUserId = useSelector(state => state.userReducer?.userId)
    const encodedUserId = btoa(currentUserId);

    useEffect(() => {
        setShowQTMenu(props.question.showQTMenu)
    }, [props.question.showQTMenu])

    useEffect(() => {
        if (selectedValue !== '' && currentTextBoxIndex === props.index) {
            setQuestionTitle(props.index, selectedValue)

            setToggleRefresh(prevState => !prevState)
        }
    }, [selectedValue])

    const AddFocusToQuestionCardUI = () => {
        // Question Card - Left Border
        setFocusQuestion(true)
        refQuestionCard.current.classList.remove('builder-question-card-unfocused')
        refQuestionCard.current.classList.add('builder-question-card-focused')

        // Add Question Button
        if (isMobile) {
            if (props.totalQuestionsCount && (props.totalQuestionsCount === props.index + 1)) {
                refQuestionCardAddQuestion?.current?.classList.add('remove-visibility')
                refQuestionCardAddQuestion?.current?.classList.remove('add-visibility')
            } else {
                refQuestionCardAddQuestion?.current?.classList.remove('remove-visibility')
                refQuestionCardAddQuestion?.current?.classList.add('add-visibility')
            }
        } else {
            refQuestionCardAddQuestion?.current?.classList?.remove('remove-visibility')
            refQuestionCardAddQuestion?.current?.classList?.add('add-visibility')
        }

        // Drag Indicator
        //show drag indicator only if is logic jump is enabled
        if (!formData?.is_logic_enabled) {
            refQuestionCardDragIndicator.current.classList.remove('builder-question-card-hide')
            refQuestionCardDragIndicator.current.classList.add('builder-question-card-show-drag-indicator')
        }
    }

    const RemoveFocusFromQuestionCardUI = () => {
        // Left Border
        setFocusQuestion(false)
        refQuestionCard.current.classList.add('builder-question-card-unfocused')
        refQuestionCard.current.classList.remove('builder-question-card-focused')

        // Add Question Button
        refQuestionCardAddQuestion?.current?.classList?.remove('add-visibility')
        refQuestionCardAddQuestion?.current?.classList?.add('remove-visibility')

        // Drag Indicator
        refQuestionCardDragIndicator.current.classList.remove('builder-question-card-show-drag-indicator')
        refQuestionCardDragIndicator.current.classList.add('builder-question-card-hide')

        if (refSelectMenu.current.classList.contains('mdc-select--activated')) {
            // Close the Question Type drop down menu.
            new MDCSelect(refSelectMenu.current).selectedIndex = popSelectValues(props.question.type).findIndex(val => val.key === props.getQuestionType(props.index))
        }
    }

    const handleOutSideClick = () => {
        document.onclick = e => {
            e.stopPropagation();
            if (ref.current && !ref.current.contains(e.target)) {
                props.refreshQTMenuDisplay(-1)
                document.onclick = null
            }
        };
    }

    const handleAddQuestionTypesImageClick = (e) => {
        props.refreshQTMenuDisplay(-1)

        if (!props.question.showQTMenu) {
            props.refreshQTMenuDisplay(props.index)
        }

        e?.nativeEvent.stopImmediatePropagation()
    }

    const handleOutsideToQuestionCardClick = () => {
        document.onclick = e => {
            if (isMobile && ((refQuestionCard.current && !refQuestionCard.current.contains(e.target))
                || (e.target.getAttribute('data-attribute') === 'builder-question-card-copy-button'))) {
                RemoveFocusFromQuestionCardUI();
                props.refreshQTMenuDisplay(-1)
                document.onclick = null;
            }
        }
    }
    const handleAddQuestionClick = (e) => {
        e.stopPropagation();
        handleOutSideClick()
        handleAddQuestionTypesImageClick(e);
    }

    const showBottomBarWhenImagesContainerClosed = () => {
        isMobileOnly ? props.hideBottomBar(false) : null
    }

    const shouldFileBrowserBeVisible = (value) => {
        if (!value) {
            setShowAddImage(false)
        }
    }

    const onShowAddImage = () => {
        // Check to see if Image upload limit has been reached.
        const successFunction = res => {
            if (encodedUserId === 'dGVtcGxhdGVzQHN1cnZleWhlYXJ0LmNvbQ==') {
                setShowImageLimitReached(false)
                reduxDispatch({type: PREMIUM_ACTION_TYPE.SET_SHOW_UPGRADE_PLAN_POP_UP, payload: false})
            }
            else if (res.data.form_images.length >= maxImageUploadLimit) {
                reduxDispatch({type: PREMIUM_ACTION_TYPE.SET_SHOW_UPGRADE_PLAN_POP_UP, payload: true})
            } else {
                setShowMyImages(false)
                setShowAddImage(true)
            }
        }

        const failureFunction = err => {
            setShowMyImages(false)
            setShowAddImage(true)
            console.error(err)
        }

        const requestUrl = `${MEDIA_BASE_URL}/${AWS_FOLDER_NAMES.USERS_JSON_FOLDER}/${props.userId}.json?id=${new Date().getTime()}`
        getDataFromUrl(requestUrl, successFunction, failureFunction)
    }

    const setQuestionContainerUItoNonFocusedState = () => {
        refQuestionCard.current.style.transform = 'translate3d(0, 0, 0)'
        refQuestionCard.current.classList.remove('builder-add-image-popup-on-top')
        RemoveFocusFromQuestionCardUI()
    }

    const onImageAdded = (newImage) => {
        const fetchImageData = async () => {
            try {
                const response = await fetch(newImage);
                const data = await response.blob();
                const base64Data = await new Promise((resolve) => {
                    const reader = new FileReader();
                    reader.onloadend = () => resolve(reader.result);
                    reader.readAsDataURL(data);
                });
                setImageURL(base64Data);
            } catch (error) {
                console.error('Error fetching image data:', error);
            }
        };
        fetchImageData()
        setAttachmentType(ATTACHMENT_TYPE.IMAGE)
        setQuestionContainerUItoNonFocusedState()
        setShowMyImages(false)
        setOptionAttachment(questionIndex, choiceIndex, newImage)
        isMobileOnly ? showBottomBarWhenImagesContainerClosed() : null
    }

    const handleCloseImage = (e) => {
        removeAttachmentInfo(props.index)

        if (e) {
            e.nativeEvent.stopImmediatePropagation()
        }

        setImageURL(null)
        setAttachmentType(null)
        setShowMyImages(false)
        reduxDispatch({type: BUILDER_ACTION_TYPE.SET_IS_DRAG_AND_DROP_DISABLED, payload: false})
    }

    const onHideMyImages = () => {
        isMobileOnly ? showBottomBarWhenImagesContainerClosed() : null
        setQuestionContainerUItoNonFocusedState()
        setShowAddImage(false)
        setShowMyImages(false)
        reduxDispatch({type: BUILDER_ACTION_TYPE.SET_IS_DRAG_AND_DROP_DISABLED, payload: false})
    }

    function getQuestionAttachmentWithImage() {
        return (
            <div>
                <div className="builder-question-line">
                    {!isMobileOnly ? <div style={{visibility: 'hidden'}}>{props.number}.</div> : null}
                    <div className='builder-question-text-input'>
                        <QuestionAttachmentComponent
                            key={ATTACHMENT_TYPE.IMAGE.key}
                            attachment={{file_type: ATTACHMENT_TYPE.IMAGE.key, image_url: imageURL}}
                            theme={"classic_theme"}
                            backgroundColorProvided={false}
                            onCloseClick={handleCloseImage}
                            userId={props.userId}
                        />
                    </div>
                    {!isMobileOnly ? <img src="images/icons/builder/attach_file.svg"
                                          alt={'attach'}
                                          style={{visibility: 'hidden'}}/> : null}
                </div>
            </div>
        )
    }

    const popUpStyle = {
        backgroundColor: 'rgba(0, 0, 0, 0.3)',
        width: '100%',
        zIndex: isMobileOnly ? 11 : 7,
        top: '0',
        left: '0',
        position: 'fixed',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: '0',
        padding: '0',
        height: isMobileOnly ? null : '100%'
    }

    return (
        <>
            <div id={'question_card_' + props.index}
                 className={`mdc-card builder-question-card builder-question-card-unfocused`}
                 draggable={isMobile}
                 onFocus={() => {
                     if (isMobile) AddFocusToQuestionCardUI()
                 }}
                 onBlur={() => {
                     if (isMobile) handleOutsideToQuestionCardClick()
                 }}

                 onMouseEnter={() => {
                     if (!isMobile) AddFocusToQuestionCardUI()
                 }}
                 onMouseLeave={() => {
                     if (!isMobile) RemoveFocusFromQuestionCardUI()
                 }}
                 ref={refQuestionCard}
                 key={props.index}
                 style={{transform: 'translate3d(0, 0, 0)'}}
            >
                <div className='builder-question-card-hide' id='drag-indicator' ref={refQuestionCardDragIndicator}>
                    <img
                        src="images/icons/builder/drag_indicator.svg"
                        alt="Drag"
                        style={{cursor: 'pointer'}}
                        onMouseDown={() => {
                            reduxDispatch({type: AUTOCOMPLETE_ACTION_TYPE.SET_CURRENT_TEXT_BOX_INDEX, payload: -1})
                            props.refreshQTMenuDisplay(-1)
                        }}
                        onClick={(e) => {
                            e.stopPropagation()
                            handleOutSideClick()
                        }}
                    />
                </div>
                <QuestionHeader
                    {...props}
                    focusQuestion={focusQuestion}
                    refQuestionCard={refQuestionCard}
                    RemoveFocusFromQuestionCardUI={RemoveFocusFromQuestionCardUI}
                    AddFocusToQuestionCardUI={AddFocusToQuestionCardUI}
                    handleOutsideToQuestionCardClick={handleOutsideToQuestionCardClick}
                    removeFocusFromAllQuestionCards={props.removeFocusFromAllQuestionCards}
                    hideBottomBar={props.hideBottomBar}
                />
                {
                    (() => {
                        let questionGroup = getQuestionGroupV2(QUESTION_TYPE[props.question.type])
                        if (questionGroup === QUESTION_GROUPS.CHOICE || questionGroup === QUESTION_GROUPS.STAR || questionGroup === QUESTION_GROUPS.LINEAR_SCALE || questionGroup === QUESTION_GROUPS.GRID || (questionGroup === QUESTION_GROUPS.TEXT || questionGroup === QUESTION_GROUPS.FILE_UPLOAD || questionGroup === QUESTION_GROUPS.LINEAR_SCALE) || (props.isQuiz && questionGroup === QUESTION_GROUPS.FILE_UPLOAD) || questionGroup === QUESTION_GROUPS.SMILE) return (
                            <QuestionBody {...props} questionGroup={questionGroup} setShowMyImages={setShowMyImages}/>
                        )
                    })()
                }
                <QuestionFooter
                    {...props}
                    refSelectMenu={refSelectMenu}
                />

                {
                    !(formData.is_logic_enabled && formData?.pages[0].questions.length - 1 !== props.index) ?
                        <div className='builder-question-card-add-question remove-visibility' id='add-question-button'
                             ref={refQuestionCardAddQuestion}>
                            {props.formData?.is_quiz && props.formData?.response_count > 0 ? null : !isMobile ? <img
                                src="images/add_blue.svg"
                                alt="Add Question"
                                onClick={e => {
                                    handleAddQuestionClick(e)
                                }}
                            /> : <MaterialFloatingActionButton iconName={'add'} buttonSize={36}
                                                               iconSize={18}
                                                               handleClick={handleAddQuestionClick}/>
                            }
                        </div> : ''
                }
            </div>
            {
                (() => {
                    if (showQTMenu) return (
                        <div ref={ref}>
                            <QuestionTypePopup questionIndex={props.index} setShowMobileQTPopUpContainer={() => props.refreshQTMenuDisplay(-1)}/>
                        </div>
                    )
                })()
            }
            {
                (() => {
                    if (currentTextBoxIndex === props.index && searchMatches.length > 0) {
                        return (
                            <div
                                style={{
                                    zIndex: 2,
                                }}
                            >
                                <MaterialAutocompleteSuggestions
                                    top={refQuestionCard.current.offsetTop + 65}
                                />
                            </div>
                        )
                    }
                })()
            }
            {showMyImages ?
                <>
                    <div style={popUpStyle}>
                        <ImagesContainer
                            showBottomBarWhenImagesContainerClosed={showBottomBarWhenImagesContainerClosed}
                            showAddImage={onShowAddImage}
                            hideMyImages={onHideMyImages}
                            updateChosenTheme={onImageAdded}
                            userId={props.userId}/>
                    </div>
                </> : null}
            {
                (() => {
                    if (showAddImage) {
                        reduxDispatch({type: BUILDER_ACTION_TYPE.SET_IS_DRAG_AND_DROP_DISABLED, payload: true})
                        return (
                            <React.Fragment>
                                <PopUpContainer zIndex={11}
                                                isImageUploader={true}
                                                shouldFileBrowserBeVisible={() => {
                                                    setQuestionContainerUItoNonFocusedState()
                                                    shouldFileBrowserBeVisible(false)
                                                    isMobileOnly ? showBottomBarWhenImagesContainerClosed() : null
                                                }}
                                                addImage={onImageAdded}
                                                uploadType={AWS_UPLOAD_TYPE.FORM}
                                                userId={props.userId}
                                />
                                <div style={{display: imageURL === null ? 'none' : 'block'}}>
                                    {getQuestionAttachmentWithImage()}
                                </div>
                            </React.Fragment>
                        )
                    }
                })()
            }
            {
                (() => {
                        if (showImageLimitReached) {
                            return (
                                <UploadLimitReachedModalDialog OKAction={() => setShowImageLimitReached(false)}/>
                            )
                        }
                    }
                )()
            }
        </>
    )
})

export default QuestionContainer
