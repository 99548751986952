import React, {useEffect, useRef, useState} from 'react';
import styles from '../css/search-drop-down.module.css'
import {isDarkTheme} from "../../form/utils/theme-color-helper";
import {useDispatch, useSelector} from "react-redux";
import {ACTION_TYPE} from "../../form/utils/utils";
import {answeredQuestionsCount, updateResponses} from "../../form/utils/form-response";
import {FORM_DATA_ACTION_TYPE} from "../../form/reducers/formDataReducer";
import useLogicQuestionMgr from "../../form/hooks/useLogicQuestionMgr";

const SearchDropDown = props => {

    const {updateFormQuestionsBasedOnSelection} = useLogicQuestionMgr()
    const responses = useSelector(state => state.formDataReducer.formResponses)
    const editResponseId = useSelector(state => state.formDataReducer.editResponseId)
    const fileUploadAnsweredCount = useSelector(state => state.answerCountReducer?.fileUploadAnswerCount)
    const dispatch = useDispatch()
    const selectContainerRef = useRef(null)
    const selectedDivRef = useRef(null)
    const previouslySelectedOption = useRef(null);
    const dropdownInputRef = useRef(null);

    const handleSelection = value => {
        dispatch({
            type: FORM_DATA_ACTION_TYPE.SET_FORM_RESPONSES,
            payload: updateResponses(responses, props.questionData, value.key)
        })
        props.validateRequired(value.key)
        previouslySelectedOption.current = value
        selectedDivRef.current.innerText = value.displayText
        props.setShowOptions(false);
        if (dropdownInputRef && dropdownInputRef.current) {
            dropdownInputRef.current.value = value.key;
        }
        updateFormQuestionsBasedOnSelection(value, props.questionData, true)
        dispatch({type: ACTION_TYPE.SET_ANSWER_COUNT, payload: answeredQuestionsCount(responses) + fileUploadAnsweredCount})
    }

    useEffect(() => {
        responses.map((response) => {
            if (response.question_id === props.name) {
                if (response?.choice?.length === 0) {
                    selectedDivRef.current.innerText = 'Select'
                } else if (response.choice) {
                    props.questionData?.choices?.map((choice) => {
                        if (response.choice === choice._id) {
                            previouslySelectedOption.current = {
                                key: choice._id,
                                displayText: choice.label,
                                target_question_id: choice.target_question_id
                            }
                            selectedDivRef.current.innerText = choice.label
                            if (editResponseId) {
                                updateFormQuestionsBasedOnSelection(choice, props.questionData, false)
                            }
                        }
                    })
                }
            }
        })
    }, [responses])

    const handleOutSideClick = () => {
        document.onclick = e => {
            e.stopPropagation();
            if (selectContainerRef.current && !selectContainerRef.current.contains(e.target)) {
                props.setShowOptions(false)
                selectedDivRef.current.innerText = previouslySelectedOption.current ? previouslySelectedOption.current.displayText : 'Select'
                document.onclick = null;
            }
        }
    };

    const choiceLabels = props.questionData?.choices?.map((choice) => {
        return {
            key: choice._id,
            displayText: choice.label,
            target_question_id: choice.target_question_id
        };
    });

    return (
        <>
            <a tabIndex="0"/>
            <div id={'search-drop-down'} className={styles.custom_select} ref={selectContainerRef}>
                <SelectedDivElement handleOutSideClick={handleOutSideClick}
                                    selectedDivRef={selectedDivRef}
                                    showOptions={props.showOptions}
                                    setShowOptions={props.setShowOptions}
                                    previouslySelectedOption={previouslySelectedOption}/>
                <input ref={dropdownInputRef}
                       type="text"
                       name={props.name}
                       style={{visibility: "hidden"}}
                />
                {props.showOptions ?
                    <OptionContainer options={choiceLabels}
                                     handleSelection={handleSelection}/> : null
                }
            </div>
        </>
    )
}
export default SearchDropDown

const OptionContainer = props => {
    const formData = useSelector(state => state.formDataReducer.formData)
    const [options, setOptions] = useState(props.options)

    const handleSearch = value => {
        let searchedOptions = [];
        for (let i = 0; i < props.options.length; i++) {
            let currentOption = props.options[i];
            if (currentOption.displayText.toLowerCase().includes(value.toLowerCase())) {
                searchedOptions.push(currentOption)
            }
        }
        setOptions(searchedOptions)
    }

    return (
        <div className={styles.select_items} style={{
            backgroundColor: isDarkTheme(formData?.theme) ? '#212121' : null,
            color: isDarkTheme(formData?.theme) ? '#fff' : null,
        }}>
            <input placeholder={"Search"} className={styles.select_search_input}
                   style={{
                       color: isDarkTheme(formData?.theme) ? '#fff' : null,
                       borderBottomColor: isDarkTheme(formData?.theme) ? '#424242' : null
                   }}
                   onInput={(e) => {
                       handleSearch(e.target.value)
                   }}/>
            <div className={styles.options_container}>
                {options.length > 0 ? options.map((value, index) => {
                    const optionDivStyle = {
                        borderBottom: (index !== (options.length - 1)) ? isDarkTheme(formData?.theme) ? '1px solid #424242' : '1px solid #f5f5f5' : null,
                        color: isDarkTheme(formData?.theme) ? '#fff' : '#212121'
                    }
                    return (
                        <div
                            id={'option-' + index}
                            style={{...optionDivStyle}}
                            onClick={() => {
                                props.handleSelection(value)
                            }} key={index}>{value.displayText}</div>
                    )
                }) : <div style={{color: '#9e9e9e'}}>{"No Results Found"}</div>}
            </div>
        </div>
    )
}

const SelectedDivElement = props => {
    const formData = useSelector(state => state.formDataReducer.formData)
    const {selectedDivRef, showOptions, setShowOptions, previouslySelectedOption, handleOutSideClick} = props
    const selectSelectedActiveStyle = {
        borderColor: isDarkTheme(formData?.theme) ? 'transparent transparent #fff transparent' : 'transparent transparent #212121 transparent',
        top: '4px',
        color: isDarkTheme(formData?.theme) ? '#fff' : '#212121'
    }
    const selectedDivClickEvent = () => {
        if (showOptions) {
            setShowOptions(false)
            selectedDivRef.current.innerText = previouslySelectedOption.current ? previouslySelectedOption.current.displayText : 'Select'
            document.onclick = null
        } else {
            setShowOptions(true);
            selectedDivRef.current.innerText = 'Select';
            handleOutSideClick()
        }
    };
    const selectedDivClass = `${styles.select_selected} ${isDarkTheme(formData?.theme) ? styles.select_arrow_dark : styles.select_arrow} 
    ${showOptions ? isDarkTheme(formData?.theme) ? styles.select_arrow_active_dark : styles.select_arrow_active : null}`;

    return (
        <div ref={selectedDivRef}
             id={'drop-down-select'}
             className={selectedDivClass}
             style={showOptions ? {...selectSelectedActiveStyle, color: "#9e9e9e"} : {...selectSelectedActiveStyle}}
             onClick={selectedDivClickEvent}>
            {"Select"}
        </div>
    )
}
