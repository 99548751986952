import {makeServerRequest} from "./server-request";
import {REQUEST_METHOD} from "../../../shared/utils/helper";
import {QUIZ_BASE_URL} from "../../../shared/utils/quiz-api-services";
import {makeQuizServerRequest} from "../quiz-admin-api-services/quiz-server-request";
import {WORD_TYPE} from "../../constants";

export const getWords = (wordType, successFunction, failureFunction) => {
    const requestUrl = (wordType === WORD_TYPE.ABUSE) ? '/v2/getabusescanwords' : (wordType === WORD_TYPE.BLOCKED) ? '/v2/getblockedwords' : null
    makeServerRequest(requestUrl, REQUEST_METHOD.GET, null, successFunction, failureFunction)
}
export const getQuizWords = (wordType, successFunction, failureFunction) => {
    const requestUrl = (wordType === WORD_TYPE.ABUSE) ? QUIZ_BASE_URL + '/v2/getabusescanwords' : (wordType === WORD_TYPE.BLOCKED) ? QUIZ_BASE_URL + '/v2/getblockedwords' : null
    makeQuizServerRequest(requestUrl, REQUEST_METHOD.GET, null, successFunction, failureFunction)
}

export const addWord = (wordType, word, desc, successFunction, failureFunction) => {
    const requestUrl = (wordType === WORD_TYPE.ABUSE) ? '/v2/addabusescanword' : (wordType === WORD_TYPE.BLOCKED) ? '/v2/addblockword' : null
    const data = JSON.stringify({
        word: word,
        description: desc
    })
    makeServerRequest(requestUrl, REQUEST_METHOD.POST, data, successFunction, failureFunction)
}
export const addQuizWord = (wordType, word, desc, successFunction, failureFunction) => {
    const requestUrl = (wordType === WORD_TYPE.ABUSE) ? QUIZ_BASE_URL + '/v2/addabusescanword' : (wordType === WORD_TYPE.BLOCKED) ? QUIZ_BASE_URL + '/v2/addblockword' : null
    const data = JSON.stringify({
        word: word,
        description: desc
    })
    makeQuizServerRequest(requestUrl, REQUEST_METHOD.POST, data, successFunction, failureFunction)
}

export const updateWord = (wordType, word, desc, id, successFunction, failureFunction) => {
    const requestUrl = (wordType === WORD_TYPE.ABUSE) ? '/v2/updateabusescanword' : (wordType === WORD_TYPE.BLOCKED) ? '/v2/updateblockedword' : null
    const data = JSON.stringify({
        word: word,
        description: desc,
        _id: id
    })
    makeServerRequest(requestUrl, REQUEST_METHOD.POST, data, successFunction, failureFunction)
}
export const updateQuizWord = (wordType, word, desc, id, successFunction, failureFunction) => {
    const requestUrl = (wordType === WORD_TYPE.ABUSE) ? QUIZ_BASE_URL + '/v2/updateabusescanword' : (wordType === WORD_TYPE.BLOCKED) ? QUIZ_BASE_URL + '/v2/updateblockedword' : null
    const data = JSON.stringify({
        word: word,
        description: desc,
        _id: id
    })
    makeQuizServerRequest(requestUrl, REQUEST_METHOD.POST, data, successFunction, failureFunction)
}
export const deleteWord = (wordType, id, successFunction, failureFunction) => {
    const requestUrl = (wordType === WORD_TYPE.ABUSE) ? '/v2/deleteabusescanword' : (wordType === WORD_TYPE.BLOCKED) ? '/v2/deleteblockword' : null
    const data = JSON.stringify({_id: id})
    makeServerRequest(requestUrl, REQUEST_METHOD.DELETE, data, successFunction, failureFunction)
}
export const deleteQuizWord = (wordType, id, successFunction, failureFunction) => {
    const requestUrl = (wordType === WORD_TYPE.ABUSE) ? QUIZ_BASE_URL + '/v2/deleteabusescanword' : (wordType === WORD_TYPE.BLOCKED) ? QUIZ_BASE_URL + '/v2/deleteblockword' : null
    const data = JSON.stringify({_id: id})
    makeQuizServerRequest(requestUrl, REQUEST_METHOD.DELETE, data, successFunction, failureFunction)
}

