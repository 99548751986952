import React from 'react'
import MaterialCheckBox from "../../../shared/components/MaterialCheckBox";
import {FORM_SETTINGS} from "./FormBuilderContainer";
import {translationKeys} from "../../../localizations/translationKeys-localization";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {BUILDER_ACTION_TYPE} from "../../reducers/builderReducer";

const SettingsQuestionsContainer = props => {
    const {t} = useTranslation()
    const formData = useSelector(state => state.appReducer.formData)

    const dispatch = useDispatch()

    const handleShowQuestionNumbersChanged = (e) => {
        props.updateSetting(FORM_SETTINGS.SHOW_QUESTION_NUMBER, e)
    }

    const handleShuffleQuestionsChanged = (e) => {
        props.updateSetting(FORM_SETTINGS.IS_SHUFFLED, e)
    }

    const handleDisabledCheckboxClick = () => {
        if (formData?.is_logic_enabled) {
            dispatch({type: BUILDER_ACTION_TYPE.SET_BUILDER_MESSAGE, payload: 'Action cannot be performed'});
            dispatch({type: BUILDER_ACTION_TYPE.SET_BUILDER_TOAST_MESSAGE, payload: true})
        }
    }

    return (
        <React.Fragment>
            <div id={'settings-responses-and-questions-wrapper'}
                 className="settings-responses-and-questions-wrapper">
                {/* Row 1 */}
                <div id={'settings-responses-and-questions-box1'} className="settings-responses-and-questions-box1">
                    <MaterialCheckBox isChecked={formData?.setting.show_question_number}
                                      removeWidth={true}
                                      onChange={handleShowQuestionNumbersChanged}/>
                </div>
                <div id={'settings-show-question-number'} className="settings-responses-and-questions-box2">
                    <img src="../../images/show_numbers.svg" alt="Number One Icon"/>
                    <div className="settings-bold-text">{t(translationKeys.show_question_number)}</div>
                </div>
                {/* Row 2 */}
                <div id={'settings-show-question-number-form'} className="settings-responses-and-questions-box4">
                    <span className='settings-normal-text'>{t(translationKeys.show_question_number_in_form)}</span>
                </div>
                {/* Row 3 */}
                <div id={'settings-responses-and-questions-box5'} className="settings-responses-and-questions-box5" onClick={handleDisabledCheckboxClick}>
                    <MaterialCheckBox isChecked={formData?.setting.is_shuffled}
                                      removeWidth={true}
                                      onChange={handleShuffleQuestionsChanged}
                                      isDisabled={formData?.is_logic_enabled}/>
                </div>
                <div id={'settings-shuffle-questions'} className="settings-responses-and-questions-box6">
                    <img src="../../images/shuffle.svg" alt="Shuffle Icon"/>
                    <div className="settings-bold-text">{t(translationKeys.shuffle_questions)}</div>
                </div>
                {/* Row 4 */}
                <div id={'settings-shuffle-form-questions'} className="settings-responses-and-questions-box8">
                    <span className='settings-normal-text'>{t(translationKeys.shuffle_questions_alert)}</span>
                </div>
            </div>
        </React.Fragment>

    )
}
export default SettingsQuestionsContainer