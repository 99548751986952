import {getQuestionCategory, QUESTION_CATEGORY} from "../../../../shared/utils/constants";

export const FORM_FOCUS_TARGET = {
    NONE: 0,
    TITLE: 1
}

export const incompleteQuestionCount = (questions) => {
    let incompleteQuestions = {
        count: 0,
        atLeastOneTitleIsMissing: false,
        questionIndexToFocus: -1,
        choiceMissing: false,
        focusTarget: FORM_FOCUS_TARGET.NONE
    }

    questions.map((question, index) => {
        // Check for Missing Title
        if (question.title.trim() === '') {
            incompleteQuestions.atLeastOneTitleIsMissing = true
            incompleteQuestions.focusTarget = FORM_FOCUS_TARGET.TITLE
            incompleteQuestions.count = incompleteQuestions.count + 1

            if (incompleteQuestions.questionIndexToFocus === -1) {
                // Focus should be put on the first missing question Title.
                incompleteQuestions.questionIndexToFocus = index
            }
        } else if (getQuestionCategory(question.type) === QUESTION_CATEGORY.CHOICE_TYPE && question.choices?.length === 0) {
            incompleteQuestions.choiceMissing = true
            incompleteQuestions.count = incompleteQuestions.count + 1
        }
    })

    return incompleteQuestions
}