import React, {useEffect} from "react";
import './../css/score-card.css';
import './../css/circle-progress.css';
import {useTranslation} from "react-i18next";
import {translationKeys} from "../../localizations/translationKeys-localization";

function QuizScoreCard(props) {
    const {t} = useTranslation()
    const quizResults = props.quizResults;
    const correctPercentage = Math.round(parseFloat(quizResults.correct_percentage)) !== parseFloat(quizResults.correct_percentage) ? parseFloat(quizResults.correct_percentage).toFixed(1) : parseInt(quizResults.correct_percentage);
    const actualWrongPercentage = (((quizResults.wrong ? quizResults.wrong : 0) / (quizResults.total ? quizResults.total : 1)) * 100);
    const wrongPercentage = Math.round(actualWrongPercentage) !== actualWrongPercentage ? actualWrongPercentage.toFixed(1) : actualWrongPercentage;
    const actualSkippedPercentage = (((quizResults.skipped ? quizResults.skipped : 0) / (quizResults.total ? quizResults.total : 1)) * 100);
    const skippedPercentage = Math.round(actualSkippedPercentage) !== actualSkippedPercentage ? actualSkippedPercentage.toFixed(1) : actualSkippedPercentage;
    return quizResults ? <div className="score-card-container">
        <p className='score-card-title'>{t(translationKeys.score)}</p>
        {props.isQuizV1 ? <div className='choice-questions-alert-container'>
            <div className='partially-validated-alert-tag'>
                <span>{t(translationKeys.choice_questions_only)}</span>
            </div>
        </div> : null}
        <div className='score-summary-container' style={!props.isChoiceTypeOnlyAvailable ? {justifyContent: 'center'} : null}>
            <div className='score-left-card' style={!props.isChoiceTypeOnlyAvailable ? {display: 'none'} : null}>
                <div className='score-card-result-row'>
                    <div className='score-card-option-text'>
                        <div id={'score-card-option-circle-indicator'} className='score-card-option-circle-indicator' style={{borderColor: '#0f9d58'}}/>
                        <span>{t(translationKeys.correct)}</span>
                    </div>
                    <span id={'score-card-correct-answer-percentage'}
                        className="score-card-correct-answer-percentage">{(correctPercentage ? correctPercentage : 0) + '%'}</span>
                    <span id={'score-card-correct-answer-count'}
                        className="score-card-correct-answer-count">{(quizResults.correct ? quizResults.correct : 0)}</span>
                </div>
                <div className='score-card-option-separator'/>
                <div className='score-card-result-row'>
                    <div id={'score-card-option-circle-indicator'} className='score-card-option-text'>
                        <div className='score-card-option-circle-indicator' style={{borderColor: 'red'}}/>
                        <span>{t(translationKeys.wrong)}</span>
                    </div>
                    <span
                        className="score-card-correct-answer-percentage">{wrongPercentage + '%'}</span>
                    <span
                        className="score-card-correct-answer-count">{(quizResults.wrong ? quizResults.wrong : 0)}</span>
                </div>
                <div className='score-card-option-separator'/>
                <div className='score-card-result-row'>
                    <div id={'score-card-option-text'} className='score-card-option-text'>
                        <div className='score-card-option-circle-indicator' style={{borderColor: 'orange'}}/>
                        <span style={{width: "inherit"}}>{t(translationKeys.skipped)}</span>
                    </div>
                    <span className="score-card-correct-answer-percentage">{skippedPercentage + '%'}</span>
                    <span
                        className="score-card-correct-answer-count">{quizResults.skipped ? quizResults.skipped : 0}</span>
                </div>
            </div>
            <div id={'score-right-card'} className='score-right-card' style={!props.isChoiceTypeOnlyAvailable ? {marginBottom: '10px'} : null}>
                <div className='score_count_container'
                     style={{borderColor: getPercentageColor(quizResults.correct_percentage ? quizResults.correct_percentage : 0)}}>
                    <span>{quizResults.correct ? quizResults.correct : 0}</span>
                    <div className='score-card-circle-divider'/>
                    <span>{quizResults.total ? quizResults.total : 0}</span>
                </div>
                <PercentageCircle percentage={quizResults.correct_percentage ? quizResults.correct_percentage : 0}/>
            </div>
        </div>
        {!props.isPublish && !props.isQuizV1 && !props.isQuizEdit ? <div className='partially-validated-alert-container'>
            <div className='partially-validated-alert-tag'>
                <span>{t(translationKeys.score_is_not_final)}</span>
            </div>
        </div> : null}
    </div> : null
}

export default QuizScoreCard

function getPercentageColor(percentage) {
    let red, green, blue = 0;
    if (percentage < 50) {
        red = 255;
        green = Math.round(5.1 * percentage);
    } else {
        green = 255;
        red = Math.round(510 - 5.10 * percentage);
    }
    let hexCode = red * 0x10000 + green * 0x100 + blue;
    return '#' + ('000000' + hexCode.toString(16)).slice(-6);
}

const PercentageCircle = (props) => {
    const circleElement = React.useRef(null);
    const textElement = React.useRef(null);
    useEffect(() => {
        setPercentageCircle(circleElement.current, textElement.current, props.percentage, undefined)
    }, [props.percentage])

    return <div ref={circleElement} className="percentage-circle" style={{marginLeft: '25px'}}>
        <div className="percentage-circle-inner">
            <div ref={textElement}>0%</div>
        </div>
    </div>
}

function setPercentageCircle(circleElement, textElement, percentage, increment) {
    if (percentage < 0 || typeof percentage === 'undefined')
        percentage = 0;
    else if (percentage > 100)
        percentage = 100;
    if (typeof increment === 'undefined')
        increment = 0;
    let currentPercentage = (100 * increment) / 360;
    let percentageColor = getPercentageColor(currentPercentage);
    textElement.innerHTML = (Math.round(currentPercentage) + "%");
    if (increment <= 180) {
        circleElement.style.cssText = 'background-image: linear-gradient(' + (90 + increment) + 'deg, transparent 50%, #eeeeee 50%),linear-gradient(90deg, #eeeeee 50%, transparent 50%)';
    } else {
        circleElement.style.cssText = 'background-image: linear-gradient(' + (increment - 90) + 'deg, transparent 50%, ' + percentageColor + ' 50%),linear-gradient(90deg, #eeeeee 50%, transparent 50%)';
    }
    circleElement.style.backgroundColor = percentageColor;
    if (currentPercentage < percentage) {
        setTimeout(function () {
            setPercentageCircle(circleElement, textElement, percentage, increment + 2);
        }, 1);
    }
}

