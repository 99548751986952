import React from 'react'
import {TimePeriod} from "./FilterPopUpContainer";
import {useTranslation} from "react-i18next";
import {translationKeys} from "../../../localizations/translationKeys-localization";

function CalendarInputsContainer(props) {
    const {t} = useTranslation()
    return (
        <div ref={props.InputsContainerElement} style={{display: 'none'}}>
            <div style={{width: '45%', margin: '1%'}}>
                <span id='calender-input-from-date' style={{fontSize: '12px'}}>{t(translationKeys.from_date)}</span>
                <input ref={props.FromDate} onChange={() => props.onChangeFunction(TimePeriod.CUSTOM_DATE)} type='date'
                       style={{width: '98%', margin: '1%'}}/>
            </div>
            <div style={{width: '45%', margin: '1%'}}>
                <span id='calender-input-to-date' style={{fontSize: '12px'}}>{t(translationKeys.to_date)}</span>
                <input ref={props.ToDate} onChange={() => props.onChangeFunction(TimePeriod.CUSTOM_DATE)} type='date'
                       style={{width: '98%', margin: '1%'}}/>
            </div>
        </div>
    )
}

export default CalendarInputsContainer
