import React, {useEffect, useRef, useState} from "react"
import {isMobile, isMobileOnly} from "react-device-detect";
import {AUTOCOMPLETE_ACTION_TYPE} from "../../reducers/autocompleteReducer";
import {
    ATTACHMENT_TYPE,
    getQuestionGroupV2, getQuestionTypeText,
    QUESTION_GROUPS,
    QUESTION_TYPE
} from "../../../shared/utils/constants";
import QuestionBody from "./QuestionBody";
import PopupMenuContainer from "./PopupMenuContainer";
import MaterialAutocompleteSuggestions from "../../../shared/components/MaterialAutocompleteSuggestions";
import {useDispatch, useSelector} from "react-redux";
import {
    addQuestionToBuilder,
    isNewlyAddedQuestion,
    popSelectValues
} from "./helpers/BuilderContainerHelper";
import MaterialTextField from "../../../shared/components/MaterialTextField";
import {translationKeys} from "../../../localizations/translationKeys-localization";
import {useTranslation} from "react-i18next";
import MaterialSwitch from "../../../shared/components/MaterialSwitch";
import PopUpContainer from "../../../shared/components/PopUpContainer";
import GenericMenuItem from "../classes/GenericMenuItem";
import deleteIcon from "../../../../public/images/delete_grey.svg"
import logicIconGrey from "../../../../public/images/logic_icon_grey.svg"
import shuffleIconGrey from "../../../../public/images/mobile_shuffle_icon_grey.svg"
import shuffleIconBlue from "../../../../public/images/mobile_shuffle_icon_blue.svg"
import attachmentIconGrey from "../../../../public/images/mobile_attachment_icon_grey.svg"
import deleteIconWhite from "../../../../public/images/delete_white.svg"
import MenuIconInfo from "../../../shared/classes/MenuIconInfo";
import ImagesContainer from "./ImagesContainer";
import {AWS_FOLDER_NAMES, AWS_UPLOAD_TYPE, MEDIA_BASE_URL} from "../utils/aws";
import {getDataFromUrl} from "../../../shared/utils/sash-v2-api";
import QuestionAttachmentComponent from "../../../form/components/questions/QuestionAttachmentComponent";
import LinkPreview from "./LinkPreview";
import UploadLimitReachedModalDialog from "../../../shared/components/UploadLimitReachedModialDialog";
import {APP_ACTION_TYPE} from "../../reducers/appReducer"
import ModalDialog from "../../../shared/components/ModalDialog"
import {OPTIONS_ACTION_TYPE} from "../../reducers/optionsReducer";
import useQuestionMgr from "../../hooks/useQuestionMgr";
import {PAGE_HASH_LOCATION} from "../../utils/pages";
import useChoiceMgr from "../../hooks/useChoiceMgr";
import Message from "../../../shared/containers/ConfirmMessage";
import {RESPONSES_ACTION_TYPE} from "../../reducers/responsesReducer";
import {BUILDER_ACTION_TYPE} from "../../reducers/builderReducer";
import AddLogicMobilePopUp from "./AddLogicMobilePopUp";
import {PremiumJSON} from "../../../shared/utils/helper";
import editDescription from "../../../../public/images/edit_desc.svg";
import {PREMIUM_ACTION_TYPE} from "../../reducers/premiumReducer";

const MobileQuestionPopupContainer = (props) => {
    const {t} = useTranslation()
    const {
        addQuestion,
        setQuestionTitle,
        addAttachmentInfo,
        removeAttachmentInfo,
        questionRequiredChanged,
        setQuestionType,
        copyQuestion,
        setQuestionDescription,
        deleteQuestion
    } = useQuestionMgr()
    const {setOptionAttachment} = useChoiceMgr()
    const {addOtherOptionForChoiceQuestion, addShufflerOptionsForChoiceQuestion} = useChoiceMgr()
    const [setFocus, setSetFocus] = useState(false)
    const [showDesc, setShowDesc] = useState(false)
    const [showQTMenu, setShowQTMenu] = useState(false)
    const [imageURL, setImageURL] = useState('')
    const [showMyImages, setShowMyImages] = useState(false)
    const [shuffleOptions, setShuffleOptions] = useState(props.question.is_options_shuffled)
    const [showAddImage, setShowAddImage] = useState(false)
    const [showAttachmentMenu, setShowAttachmentMenu] = useState(false)
    const [showLogicJumpPopUp, setShowLogicJumpPopUp] = useState(false)
    const [showImageLimitReached, setShowImageLimitReached] = useState(false)
    const [isLogicDisabled, setIsLogicDisabled] = useState(false);
    const refQuestionCard = useRef(null)

    const textFieldRef = useRef(null)
    const questionTextFieldRef = useRef(null)

    const showShared = useSelector(state => state.appDashboardReducer.showShared)
    const searchMatches = useSelector(state => state.autocompleteReducer?.matches)
    const choiceIndex = useSelector(state => state.optionsReducer.currentOptionIndex)
    const subscriptionData = useSelector(state => state.userReducer?.subscriptionData)
    const autoCompleteLeftPos = useSelector(state => state.autocompleteReducer?.leftPos)
    const selectedValue = useSelector(state => state.autocompleteReducer?.selectedValue)
    const maxImageUploadLimit = PremiumJSON[subscriptionData?.subscription_plan]?.question_attachments
    const optionDeleteAction = useSelector(state => state.builderReducer.optionDeleteAction)
    const suggestionClicked = useSelector(state => state.autocompleteReducer?.suggestionClicked)
    const showAcknowledgement = useSelector(state => state.responsesReducer.showAcknowledgement)
    const showOptionDeletePopUp = useSelector(state => state.builderReducer.showOptionDeletePopUp)
    const acknowledgementMessage = useSelector(state => state.responsesReducer.acknowledgmentMessage)
    const currentTextBoxIndex = useSelector(state => state.autocompleteReducer?.currentTextBoxIndex)
    const formData = useSelector(state => state.appReducer.formData)
    const showQuizActionBlockedPopUp = useSelector(state => state.builderReducer.showQuizActionBlockedPopUp)
    const showGridQtChangeNotAllowedPopUp = useSelector(state => state.appReducer.showGridQtChangeNotAllowedPopUp)
    const showColumnOrRowLimitReachedPopUp = useSelector(state => state.appReducer.showColumnOrRowLimitReachedPopUp)
    const showLogicJumpBasedOnUser = showShared ? PremiumJSON[subscriptionData?.subscription_plan]?.logic_jump && PremiumJSON[formData?.subscription_data?.current_subscription?.plan]?.logic_jump : PremiumJSON[subscriptionData?.subscription_plan]?.logic_jump

    const reduxDispatch = useDispatch()

    useEffect(() => {
        if ((props.formData?.pages[0].questions.length <= 1
                || props.formData?.pages[0].questions.length - 1 === props.index)) {
            setIsLogicDisabled(true)
        }
        else {
            setIsLogicDisabled(false)
        }
        reduxDispatch({type: BUILDER_ACTION_TYPE.SET_IS_DRAG_AND_DROP_DISABLED, payload: true})
        return () => {
            reduxDispatch({type: BUILDER_ACTION_TYPE.SET_IS_DRAG_AND_DROP_DISABLED, payload: false})
        };
    }, []);

    useEffect(() => {
        setShowQTMenu(props.question.showQTMenu)
    }, [props.question.showQTMenu])


    useEffect(() => {
        questionTextFieldRef.current.focus()
        window.location.hash = PAGE_HASH_LOCATION.question

        if (autoCompleteLeftPos === -1) {
            const payLoad = {
                leftPos: isMobileOnly ? textFieldRef.current.offsetLeft + 14 : textFieldRef.current.offsetLeft + 20,
                width: isMobileOnly ? textFieldRef.current.offsetWidth : textFieldRef.current.offsetWidth - textFieldRef.current.offsetLeft - 3
            }
            reduxDispatch({type: AUTOCOMPLETE_ACTION_TYPE.STORE_QUESTION_AUTOCOMPLETE_COORDINATES, payload: payLoad})
        }
    }, [])

    useEffect(() => {
        if (selectedValue !== '' && currentTextBoxIndex === props.index) {
            setQuestionTitle(props.index, selectedValue)
        }
    }, [selectedValue])

    window.onhashchange = () => {
        reduxDispatch({type: BUILDER_ACTION_TYPE.SET_SHOW_QUIZ_ACTION_BLOCKED_POP_UP, payload: false})
        if (window.location.hash !== PAGE_HASH_LOCATION.question) {
            props.setShowQuestion(false)
            props.hideBottomBar(false)
        }
    }

    const GetAttachmentGroups = () => {
        let keyCtr = 0

        let ImageMenuItem = new GenericMenuItem(keyCtr++, new MenuIconInfo(ATTACHMENT_TYPE.IMAGE.displayText, `/icons/builder/image.svg`), ATTACHMENT_TYPE.IMAGE.displayText)
        let VideoMenuItem = new GenericMenuItem(keyCtr++, new MenuIconInfo(ATTACHMENT_TYPE.VIDEO.displayText, `/icons/builder/video.svg`), ATTACHMENT_TYPE.VIDEO.displayText)
        let WebsiteMenuItem = new GenericMenuItem(keyCtr++, new MenuIconInfo(ATTACHMENT_TYPE.WEBSITE.displayText, `/icons/builder/website.svg`), ATTACHMENT_TYPE.WEBSITE.displayText)

        // Items are placed in groups that are separated by bottom border lines when the menu is shown.
        let Group1 = [ImageMenuItem]
        let Group2 = [VideoMenuItem]
        let Group3 = [WebsiteMenuItem]

        return [Group1, Group2, Group3]
    }
    const prepareWebOrVideoAttachmentType = attachmentType => {
        props.question.tempAttachmentValue = ""
        setImageURL(null)
        props.setAttachmentType(attachmentType)
    }
    const menuItemClickHandler = (e) => {
        let arrInfo = (e.target.getAttribute("data-attribute")).split("-")
        setShowAttachmentMenu(false)
        if (arrInfo[0] === "1" && props.attachmentType === ATTACHMENT_TYPE.VIDEO || arrInfo[0] === "2" && props.attachmentType === ATTACHMENT_TYPE.WEBSITE) {
            // Do nothing since ATTACHMENT_TYPE hasn't changed. This logic only applies to Video and Website ATTACHMENT_TYPEs
            return
        }

        switch (arrInfo[0]) {
            case "0": /*ATTACHMENT_TYPE.IMAGE*/
                refQuestionCard.current.style.transform = 'none'
                refQuestionCard.current.classList.add('builder-add-image-popup-on-top')
                props.hideBottomBar(true)
                setShowMyImages(true)
                break
            case "1": /*ATTACHMENT_TYPE.VIDEO*/
                prepareWebOrVideoAttachmentType(ATTACHMENT_TYPE.VIDEO)
                break
            case "2": /*ATTACHMENT_TYPE.WEBSITE*/
                prepareWebOrVideoAttachmentType(ATTACHMENT_TYPE.WEBSITE)
                break
        }
    }

    const handleQuestionTitleFocus = (index) => {
        reduxDispatch({type: AUTOCOMPLETE_ACTION_TYPE.SET_CURRENT_TEXT_BOX_INDEX, payload: index})
        reduxDispatch({type: AUTOCOMPLETE_ACTION_TYPE.CLEAR_SUGGESTIONS})
    }

    const handleQuestionTitleBlur = () => {
        if (!suggestionClicked) {
            reduxDispatch({type: AUTOCOMPLETE_ACTION_TYPE.CLEAR_SUGGESTIONS})
            reduxDispatch({type: AUTOCOMPLETE_ACTION_TYPE.SET_CURRENT_TEXT_BOX_INDEX, payload: -1})
        }
    }

    const addQuestionMenuItemClickHandler = (e) => {
        addQuestionToBuilder(e, props.index, addQuestion, copyQuestion, subscriptionData)
    }

    const switchChanged = (index, checkedStatus) => {
        questionRequiredChanged(index, checkedStatus)
    }

    const handleOutsideToQuestionCardClick = () => {
        document.onclick = e => {
            if (isMobile && ((refQuestionCard.current && !refQuestionCard.current.contains(e.target))
                || (e.target.getAttribute('data-attribute') === 'builder-question-card-copy-button'))) {
                props.refreshQTMenuDisplay(-1)
                document.onclick = null;
            }
        }
    }
    const handleQuestionTitleChange = (newValue) => {
        setQuestionTitle(props.index, newValue)

        document.onclick = () => {
            reduxDispatch({type: AUTOCOMPLETE_ACTION_TYPE.CLEAR_SUGGESTIONS})
            document.onclick = null;
        }

        /* If 'document.onclick' above fires, the code below will never be reached. */
        if (newValue.length > 2) {
            reduxDispatch({type: AUTOCOMPLETE_ACTION_TYPE.SEARCH, payload: newValue})
        } else {
            reduxDispatch({type: AUTOCOMPLETE_ACTION_TYPE.CLEAR_SUGGESTIONS})
        }
    }
    const onHideMyImages = () => {
        props.hideBottomBar(false)
        refQuestionCard.current.style.transform = 'translate3d(0, 0, 0)'
        refQuestionCard.current.classList.remove('builder-add-image-popup-on-top')
        setShowAddImage(false)
        setShowMyImages(false)
    }
    const onImageAdded = (newImage) => {
        props.setAttachmentType(ATTACHMENT_TYPE.IMAGE)
        refQuestionCard.current.style.transform = 'translate3d(0, 0, 0)'
        refQuestionCard.current.classList.remove('builder-add-image-popup-on-top')
        setShowMyImages(false)
        if (choiceIndex >= 0) {
            setOptionAttachment(props.index, choiceIndex, newImage)
        } else {
            addAttachmentInfo(props.index, ATTACHMENT_TYPE.IMAGE, newImage)
        }
        props.hideBottomBar(false)
    }
    const handleFooterOutSideClick = () => {
        document.onclick = () => {
            setShowAttachmentMenu(false)
        }
    }
    const ShowAttachmentMenuItems = (e) => {
        questionTextFieldRef.current.blur()
        props.refreshQTMenuDisplay(-1)
        e.nativeEvent.stopImmediatePropagation()
        setShowAttachmentMenu(true)
        document.activeElement.blur()
        reduxDispatch({
            type: OPTIONS_ACTION_TYPE.SET_CURRENT_OPTION_VALUES, payload: {
                questionIndex: props.index,
                optionIndex: -1
            }
        })
    }
    const handleCloseImage = (e) => {
        removeAttachmentInfo(props.index)

        if (e) {
            e.nativeEvent.stopImmediatePropagation()
        }
        props.setAttachmentType(null)
        setShowMyImages(false)
    }
    const onShowAddImage = () => {
        // Check to see if Image upload limit has been reached.
        const successFunction = res => {
            if (res.data.form_images.length >= maxImageUploadLimit) {
                reduxDispatch({type: PREMIUM_ACTION_TYPE.SET_SHOW_UPGRADE_PLAN_POP_UP, payload: true})
            } else {
                setShowMyImages(false)
                setShowAddImage(true)
            }
        }

        const failureFunction = err => {
            setShowMyImages(false)
            setShowAddImage(true)
            console.log(err)
        }

        const requestUrl = `${MEDIA_BASE_URL}/${AWS_FOLDER_NAMES.USERS_JSON_FOLDER}/${props.userId}.json?id=${new Date().getTime()}`
        getDataFromUrl(requestUrl, successFunction, failureFunction)
    }

    function getQuestionAttachmentWithImage() {
        return (
            <div>
                <div className="builder-question-line">
                    <div className='builder-question-text-input'>
                        <QuestionAttachmentComponent
                            key={ATTACHMENT_TYPE.IMAGE.key}
                            attachment={{
                                file_type: ATTACHMENT_TYPE.IMAGE.key,
                                image_url: props.question.attachment?.image_url
                            }}
                            theme={"classic_theme"}
                            backgroundColorProvided={false}
                            onCloseClick={handleCloseImage}
                            userId={formData.user_id}
                        />
                    </div>
                </div>
            </div>
        )
    }

    const addShuffleOptionsForChoiceQuestion = (checkedStatus) => {
        document.activeElement.blur()
        if (props.question.choices.length > 1) {
            setShuffleOptions(checkedStatus)
            addShufflerOptionsForChoiceQuestion(props.index, checkedStatus)
            reduxDispatch({type: RESPONSES_ACTION_TYPE.SET_SHOW_ACKNOWLEDGEMENT, payload: true})
            reduxDispatch({
                type: RESPONSES_ACTION_TYPE.SET_ACKNOWLEDGEMENT_MESSAGE,
                payload: checkedStatus ? t(translationKeys.shuffle_options_enabled) : t(translationKeys.shuffle_options_disabled)
            })
        } else {
            reduxDispatch({type: RESPONSES_ACTION_TYPE.SET_SHOW_ACKNOWLEDGEMENT, payload: true})
            reduxDispatch({
                type: RESPONSES_ACTION_TYPE.SET_ACKNOWLEDGEMENT_MESSAGE,
                payload: t(translationKeys.add_options_to_enable_this_feature)
            })
        }
    }

    const handleOpenLogicPopup = () => {
        if (!showLogicJumpBasedOnUser) {
            if (showShared && !PremiumJSON[formData?.subscription_data?.current_subscription?.plan]?.logic_jump) {
                reduxDispatch({type: PREMIUM_ACTION_TYPE.SET_SHOW_FREE_OWNER_POP_UP, payload: true})
            } else {
                reduxDispatch({type: PREMIUM_ACTION_TYPE.SET_SHOW_UPGRADE_PLAN_POP_UP, payload: true})
            }
        } else if (isLogicDisabled) {
            reduxDispatch({type: RESPONSES_ACTION_TYPE.SET_SHOW_ACKNOWLEDGEMENT, payload: true})
            reduxDispatch({
                type: RESPONSES_ACTION_TYPE.SET_ACKNOWLEDGEMENT_MESSAGE,
                payload: 'Action cannot be performed'
            })
        }
        else {
            setShowLogicJumpPopUp(true)
        }
    }

    const closeOpenLogicPopup = () => {
        setShowLogicJumpPopUp(false)
    }

    const handleDeleteClick = () => {
        if (formData?.is_quiz && formData?.response_count > 0) {
            reduxDispatch({type: BUILDER_ACTION_TYPE.SET_SHOW_QUIZ_ACTION_BLOCKED_POP_UP, payload: true})
        } else {
            reduxDispatch({type: AUTOCOMPLETE_ACTION_TYPE.SET_SELECTED_VALUE, payload: ''})
            deleteQuestion(props.index, isNewlyAddedQuestion(props.refSavedData?.current?.pages[0]?.questions, props.question?._id))
        }
    }

    const handleDisabledSwitchClickMobile = () => {
        if (props.question.is_logic_enabled) {
            reduxDispatch({type: RESPONSES_ACTION_TYPE.SET_SHOW_ACKNOWLEDGEMENT, payload: true})
            reduxDispatch({
                type: RESPONSES_ACTION_TYPE.SET_ACKNOWLEDGEMENT_MESSAGE,
                payload: 'Action cannot be performed'
            })
        }
    }

    const handleQuestionDescriptionChange = (newValue) => {
        setQuestionDescription(props.index, newValue)
    }

    return (
        <div ref={props.questionRef} style={{width: "100%"}}>
            <div id={'question_card_' + props.index}
                 className={`mdc-card builder-question-card builder-question-card-unfocused`}
                 onBlur={() => {
                     handleOutsideToQuestionCardClick()
                 }}
                 ref={refQuestionCard}
                 key={props.index}
                 style={{transform: 'translate3d(0, 0, 0)', width: isMobileOnly ? "95%" : null}}
            >
                {/*Question Header*/}
                <div id={'builder-question-header'} className="builder-question-header">
                    <div id={"question-text" + props.number} className="builder-question-line">
                        <div id={"question-number" + props.number} style={{marginBottom: showDesc ? "120px" : "30px"}}>{props.number}.</div>
                        <div className='builder-question-text-input'>
                            <div className='sh-text-field' ref={textFieldRef}>
                                <MaterialTextField id={`question-text-input-${props.index}`}
                                                   index={props.index}
                                                   {...props}
                                                   data={{
                                                       defaultValue: props.question.title
                                                   }}
                                                   inputRef={questionTextFieldRef}
                                                   noLabel={true}
                                                   onChange={handleQuestionTitleChange}
                                                   errorText={{current: t(translationKeys.question_is_required)}}
                                                   required={t(translationKeys.required)}
                                                   maxLength={500}
                                                   forceUpdateRefVarFocus={setFocus}
                                                   setForceUpdateRefVarFocus={() => {
                                                       setSetFocus(false)
                                                   }}
                                                   highlightText={!!props.question.highlightText}
                                                   removeHighlightText={props.removeHighlightText}
                                                   handleFocus={handleQuestionTitleFocus}
                                                   onBlur={handleQuestionTitleBlur}
                                                   validateURL={false}
                                />
                            </div>
                            <div className={"question-description"} style={{marginLeft: "-20px"}} onClick={() => setShowDesc(!showDesc)}>
                                <span>{t(translationKeys.description)}</span>
                                <span style={{
                                    height: "inherit",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    textWrap: "nowrap",
                                    color: "#4D4D4D"
                                }}>
                                {props.question?.description?.length && !showDesc ? `: ${props.question.description}` : null}
                            </span>
                                <img src={editDescription} alt={"description"} style={{width: "20px"}}/>
                            </div>
                            {showDesc ? <div className={"description-input"} style={{marginLeft: "-20px"}}>
                            <textarea placeholder={t(translationKeys.enter_desc_here)}
                                      value={props.question.description}
                                      maxLength={1000}
                                      onChange={(e) => handleQuestionDescriptionChange(e.target.value)}/>
                            </div> : null}
                        </div>
                    </div>
                </div>
                {
                    (() => {
                        if (props.attachmentType === ATTACHMENT_TYPE.IMAGE && props.question.attachment?.image_url) {
                            return (
                                <div style={{display: 'block'}}>
                                    {getQuestionAttachmentWithImage()}
                                </div>
                            )
                        }
                    })()
                }
                {
                    (() => {
                        if (props.attachmentType === ATTACHMENT_TYPE.WEBSITE || props.attachmentType === ATTACHMENT_TYPE.VIDEO) return (
                            <LinkPreview
                                question={props.question}
                                index={props.index}
                                number={props.number}
                                attachmentType={props.attachmentType.key}
                                closeLinkPreview={() => {
                                    props.setAttachmentType(null)
                                    props.question.tempAttachmentValue = ""
                                }}
                                isMobileQuestionPopUp={true}
                                showModalDialog={props.showModalDialog}
                            />
                        )
                    })()
                }
                {
                    (() => {
                        let questionGroup = getQuestionGroupV2(QUESTION_TYPE[props.question.type])

                        if (questionGroup === QUESTION_GROUPS.CHOICE || questionGroup === QUESTION_GROUPS.SMILE || questionGroup === QUESTION_GROUPS.LINEAR_SCALE || questionGroup === QUESTION_GROUPS.STAR || questionGroup === QUESTION_GROUPS.GRID || (props.isQuiz && questionGroup === QUESTION_GROUPS.TEXT) || (props.isQuiz && questionGroup === QUESTION_GROUPS.FILE_UPLOAD)) return (
                            <QuestionBody {...props} setShowMyImages={setShowMyImages} questionGroup={questionGroup}/>
                        )
                    })()
                }
                {/*Question Footer*/}
                <div id={'builder-footer'} className='builder-footer' key={props.index}>
                    <img alt='select' src={props.initialImage} style={{
                        width: '15px',
                        filter: formData?.is_quiz && formData?.response_count > 0 ? "opacity(0.5)" : null
                    }}/>
                    <span style={{
                        margin: "auto 5px",
                        fontSize: "12px",
                        color: "#9E9E9E"
                    }}
                          onClick={(e) => {
                              if (formData?.is_quiz && formData?.response_count > 0) {
                                  reduxDispatch({
                                      type: BUILDER_ACTION_TYPE.SET_SHOW_QUIZ_ACTION_BLOCKED_POP_UP,
                                      payload: true
                                  })
                              } else {
                                  if (props.question.type !== QUESTION_TYPE.FILE_UPLOAD.key) {
                                      document.activeElement.blur()
                                      document.onclick = () => {
                                          setShowQTMenu(false)
                                      }
                                      e.nativeEvent.stopImmediatePropagation()
                                      setShowQTMenu(true)
                                  }
                              }
                          }}>
                        {getQuestionTypeText(props.question.type)}
                    </span>
                    <div className='builder-footer-right'>
                        <div id={'builder-footer-required-switch'} className='builder-footer-required-switch' onClick={handleDisabledSwitchClickMobile}>
                            <MaterialSwitch
                                index={props.index}
                                initialCheckedState={props.question.is_required}
                                isSwitchDisabled={props.question.is_logic_enabled}
                                switchChanged={switchChanged}
                            />
                        </div>
                        <div id={'builder-question-required'}
                             className='builder-footer-label builder-footer-label-required'>{t(translationKeys.required)}</div>
                        <div className='builder-footer-separator'>
                            |
                        </div>
                        {(props.question.type === QUESTION_TYPE.MULTIPLE_CHOICE.key || props.question.type === QUESTION_TYPE.CHECKBOX_CHOICE.key) && !props.isQuiz ?
                            <div style={{width: '65%', display: "flex", justifyContent: "end", alignItems: "center"}}>
                                <div id={'choice-body-add-other-option'}
                                     className='builder-footer-label builder-footer-label-required'>
                                    {t(translationKeys.add_other_option)}
                                </div>
                                <div id={'builder-footer-others-switch'} className='builder-footer-others-switch' onClick={handleDisabledSwitchClickMobile}>
                                    <MaterialSwitch
                                        index={props.index}
                                        initialCheckedState={props.question.is_others_allowed}
                                        switchChanged={addOtherOptionForChoiceQuestion}
                                        isSwitchDisabled={props.question.is_logic_enabled}
                                    />
                                </div>

                            </div> : null}
                    </div>
                </div>
                <div className={"card-bottom-bar"} style={{display: "flex", margin: "10px 0 10px 0"}}>
                    <div className={"card-bottom-bar-item"} onClick={
                        (e) => {
                            handleFooterOutSideClick()
                            ShowAttachmentMenuItems(e)
                        }
                    }>
                        <img src={attachmentIconGrey} alt={'attach'} style={{width: "25px", margin: "10px"}}/>
                        <span>{t(translationKeys.attach)}</span>
                    </div>
                    {
                        !props.isQuiz &&
                        <div className={"card-bottom-bar-item"}
                             onClick={() => {
                                 handleOpenLogicPopup()
                             }
                             }>
                            <img
                                src={props.question.is_logic_enabled ? shuffleIconBlue : logicIconGrey}
                                alt={'Logic icon'}
                                style={{width: "25px", margin: "10px"}}/>
                            <span
                                style={{color: props.question.is_logic_enabled ? "#1976d2" : isLogicDisabled ? "#9E9E9E" : null}}>{t(translationKeys.logic)}</span>
                        </div>
                    }
                    {(props.question.type === QUESTION_TYPE.MULTIPLE_CHOICE.key || props.question.type === QUESTION_TYPE.CHECKBOX_CHOICE.key) ?
                        <div className={"card-bottom-bar-item"}
                             onClick={() => addShuffleOptionsForChoiceQuestion(!shuffleOptions)}>
                            <img
                                src={shuffleOptions && props.question.choices.length > 1 ? shuffleIconBlue : shuffleIconGrey}
                                alt={'shuffle'}
                                style={{width: "25px", margin: "10px"}}/>
                            <span
                                style={{color: props.question.choices.length < 2 ? "#9E9E9E" : shuffleOptions ? "#1976d2" : null}}>{t(translationKeys.shuffle)}</span>
                        </div> : null}
                    {/*{(props.question.type === QUESTION_TYPE.MULTIPLE_CHOICE.key || props.question.type === QUESTION_TYPE.CHECKBOX_CHOICE.key) && !props.isQuiz ?
                        <div className={"card-bottom-bar-item"}>
                            <img src={logicIconGrey} alt={'logic'} style={{width: "25px", margin: "10px"}}/>
                            <span>Logic</span>
                        </div> : null}*/}
                    <div className={"card-bottom-bar-item"} style={{
                        borderRight: "none",
                        filter: formData?.is_quiz && formData?.response_count > 0 ? "opacity(0.5)" : null
                    }} onClick={handleDeleteClick}>
                        <img src={deleteIcon} alt={'delete'} style={{width: "25px", margin: "10px"}}/>
                        <span>{t(translationKeys.delete)}</span>
                    </div>
                </div>
            </div>
            {showQTMenu ? <PopUpContainer children={
                <div style={{width: '85%', backgroundColor: "#ffffff", borderRadius: "5px"}} role="listbox">
                    <ul style={{listStyle: "none", padding: "0", margin: "5px 0 0 0"}}>
                        {
                            (() => {
                                function handleSelect(selectedValue) {
                                    // let imgInfo = props.selectValues.filter(value => value.key === selectedValue)[0]

                                    // updateImageInfo(imgInfo.imgPath, imgInfo.altText);

                                    if (formData.pages[0].questions[props.index].type !== selectedValue) {
                                        if (formData.response_count > 0 && !isNewlyAddedQuestion(props.refSavedData?.current?.pages[0]?.questions, props.question?._id) && (selectedValue === QUESTION_TYPE.MULTIPLE_CHOICE_GRID.key || selectedValue === QUESTION_TYPE.CHECKBOX_GRID.key || selectedValue === QUESTION_TYPE.CHECKBOX_CHOICE.key || selectedValue === QUESTION_TYPE.MULTIPLE_CHOICE.key || selectedValue === QUESTION_TYPE.DROPDOWN_CHOICE.key)) {
                                            // Prevent QUESTION_TYPE.CHECKBOX_GRID and QUESTION_TYPE.MULTIPLE_CHOICE_GRID from being edited when the Form has responses
                                            reduxDispatch({
                                                type: APP_ACTION_TYPE.SET_SHOW_GRID_QT_CHANGE_NOT_ALLOWED_POP_UP,
                                                payload: true
                                            })
                                        } else {
                                            setQuestionType(props.index, selectedValue)
                                        }
                                    }
                                    setShowQTMenu(false)
                                }

                                return (
                                    popSelectValues(props.question.type, props.question?.is_logic_enabled).map((value, index) => (
                                            <div style={{
                                                display: 'flex',
                                                padding: '10px 15px 10px 15px'
                                            }} key={index} onClick={() => {
                                                handleSelect(value.key, value.displayText ? value.displayText : undefined)
                                            }}>
                                                <img style={{display: "block", width: "7%"}}
                                                     src={value.imgPath}
                                                     alt={value.altText}
                                                />
                                                <li style={{height: 'selectMenuHeight', margin: "0 10%"}}
                                                    data-value={value.key}
                                                    role="option">
                                                    <span
                                                        style={{paddingLeft: value.imgPath === undefined ? "15px" : ""}}>{value.displayText}</span>
                                                </li>
                                            </div>
                                        )
                                    ))
                            })()
                        }
                    </ul>
                </div>}/> : null}
            {
                (() => {
                    return (
                        <div
                            className={isMobile ? 'question-card-question-types-menu-mobile-view' : 'builder-question-card-question-types-menu'}
                            style={{
                                display: isMobile ? (props.question.showQTMenu) ? 'flex' : 'none' : null,
                                zIndex: isMobile ? null : 2,
                                top: !isMobile && refQuestionCard.current?.offsetHeight > 300 ? props.isQuiz ? (refQuestionCard.current?.offsetHeight - 200) : null : null
                            }}
                        >
                            <PopupMenuContainer display={showQTMenu}
                                                updateInputFocus={false}
                                                Groups={props.GetQTGroups()}
                                                top={refQuestionCard?.current?.offsetTop + 5}
                                                menuItemClickHandler={addQuestionMenuItemClickHandler}
                            />
                        </div>
                    )
                })()
            }
            <div className={'image-attachment-popup-container'}>
                {
                    (() => {
                        const popUpStyle = {
                            backgroundColor: 'rgba(0, 0, 0, 0.3)',
                            width: '100%',
                            zIndex: isMobileOnly ? 11 : 7,
                            top: '0',
                            left: '0',
                            position: 'fixed',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            margin: '0',
                            padding: '0',
                            height: isMobileOnly ? null : '100%'
                        }
                        if (showMyImages) {
                            document.activeElement.blur()
                            return (
                                <div style={popUpStyle}>
                                    <ImagesContainer
                                        doThemeLimitCheck={false}
                                        showBottomBarWhenImagesContainerClosed={() => props.hideBottomBar(false)}
                                        showAddImage={onShowAddImage}
                                        hideMyImages={onHideMyImages}
                                        updateChosenTheme={onImageAdded}
                                        userId={props.userId}/>
                                </div>
                            )
                        }
                        if (showAddImage)
                            return (
                                <React.Fragment>
                                    <PopUpContainer zIndex={11}
                                                    isImageUploader={true}
                                                    shouldFileBrowserBeVisible={() => {
                                                        refQuestionCard.current.style.transform = 'translate3d(0, 0, 0)'
                                                        refQuestionCard.current.classList.remove('builder-add-image-popup-on-top')
                                                        setShowAddImage(false)
                                                        props.hideBottomBar(false)
                                                    }}
                                                    addImage={onImageAdded}
                                                    uploadType={AWS_UPLOAD_TYPE.FORM}
                                                    userId={props.userId}
                                    />
                                    <div style={{display: imageURL === null ? 'none' : 'block'}}>
                                        {getQuestionAttachmentWithImage()}
                                    </div>
                                </React.Fragment>
                            )
                    })()
                }
            </div>
            {
                (() => {
                    if (showAttachmentMenu) {
                        handleFooterOutSideClick()
                        return (
                            <PopUpContainer children={
                                <div style={{
                                    position: 'absolute',
                                    width: '100%',
                                    bottom: '0',
                                    zIndex: 2
                                }}>
                                    <PopupMenuContainer display={showAttachmentMenu}
                                                        Groups={GetAttachmentGroups()}
                                                        width={'99%'}
                                                        margin={'0'}
                                                        menuItemClickHandler={menuItemClickHandler}/>
                                </div>}/>
                        )
                    }
                })()
            }
            {/* logic jump pop up for mobie*/}
            {
                (() => {
                    if (showLogicJumpPopUp) {
                        // handleFooterOutSideClick()
                        return (
                            <div style={{
                                    position: 'absolute',
                                    width: '100%',
                                    height: '100%',
                                    bottom: '0',
                                    zIndex: 20
                                }}>
                                <AddLogicMobilePopUp closeOpenLogicPopup={closeOpenLogicPopup} {...props} />
                            </div>
                        )
                    }
                })()
            }
            {
                (() => {
                    if (currentTextBoxIndex === props.index && searchMatches.length > 0) {
                        return (
                            <div
                                style={{
                                    zIndex: 2,
                                }}
                            >
                                <MaterialAutocompleteSuggestions
                                    top={refQuestionCard?.current?.offsetTop + 65}
                                />
                            </div>
                        )
                    }
                })()
            }
            {
                (() => {
                        if (showImageLimitReached) {
                            return (
                                <UploadLimitReachedModalDialog OKAction={() => setShowImageLimitReached(false)}/>
                            )
                        }
                    }
                )()
            }

            {
                (() => {
                        if (showGridQtChangeNotAllowedPopUp)
                            return (
                                <div>
                                    <ModalDialog
                                        header={t(translationKeys.not_allowed)}
                                        body={t(translationKeys.not_allowed_description)}
                                        buttons={[
                                            {
                                                text: t(translationKeys.ok),
                                                action:
                                                    () => {
                                                        reduxDispatch({
                                                            type: APP_ACTION_TYPE.SET_SHOW_GRID_QT_CHANGE_NOT_ALLOWED_POP_UP,
                                                            payload: false
                                                        })
                                                    }
                                            }
                                        ]}
                                    />
                                </div>
                            )
                    }
                )()
            }
            {
                (() => {
                        if (showColumnOrRowLimitReachedPopUp)
                            return (
                                <div>
                                    <ModalDialog
                                        header={t(translationKeys.options_limit_reached)}
                                        body={t(translationKeys.options_limit_reached_description)}
                                        buttons={[
                                            {
                                                text: t(translationKeys.ok),
                                                action:
                                                    () => {
                                                        reduxDispatch({
                                                            type: APP_ACTION_TYPE.SET_SHOW_COLUMN_OR_ROW_LIMIT_REACHED_POP_UP,
                                                            payload: false
                                                        })
                                                    }
                                            }
                                        ]}
                                    />
                                </div>
                            )
                    }
                )()
            }
            {showQuizActionBlockedPopUp ? <ModalDialog header={t(translationKeys.action_blocked).toUpperCase()}
                                                       body={[t(translationKeys.action_blocked_alert)]}
                                                       buttons={[
                                                           {
                                                               text: t(translationKeys.ok),
                                                               action:
                                                                   () => {
                                                                       reduxDispatch({
                                                                           type: BUILDER_ACTION_TYPE.SET_SHOW_QUIZ_ACTION_BLOCKED_POP_UP,
                                                                           payload: false
                                                                       })
                                                                   }
                                                           }
                                                       ]}/> : null}
            {showOptionDeletePopUp ? <ModalDialog header={t(translationKeys.delete).toUpperCase()}
                                                  imageIcon={deleteIconWhite}
                                                  body={[t(translationKeys.delete_option_alert)]}
                                                  buttons={
                                                      [
                                                          {
                                                              text: t(translationKeys.no).toUpperCase(),
                                                              action: () => {
                                                                  reduxDispatch({type: BUILDER_ACTION_TYPE.SET_SHOW_OPTION_DELETE_POP_UP, payload: false})
                                                              }
                                                          },
                                                          {
                                                              text: t(translationKeys.yes).toUpperCase(),
                                                              action: () => {
                                                                  optionDeleteAction()
                                                                  reduxDispatch({type: BUILDER_ACTION_TYPE.SET_SHOW_OPTION_DELETE_POP_UP, payload: false})
                                                                  reduxDispatch({type: BUILDER_ACTION_TYPE.SET_OPTION_DELETE_ACTION, payload: null})
                                                              }
                                                          }
                                                      ]
                                                  }
            /> : null}

            {showAcknowledgement ? <Message width={'250px'} message={acknowledgementMessage}/> : null}
        </div>
    )
}

export default MobileQuestionPopupContainer