import React, {useEffect, useState} from "react"
import optionAddGrey from "../../../../public/images/icons/builder/option_add_grey.svg"
import optionDeleteGrey from "../../../../public/images/icons/builder/option_delete_grey.svg"
import optionAddBlue from "../../../../public/images/icons/builder/option_add_blue.svg"
import optionDeleteBlue from "../../../../public/images/icons/builder/option_delete_blue.svg"
import * as PropTypes from "prop-types"
import linearScaleCircle from "../../../../public/images/icons/builder/linear_scale_circle.svg"
import {t} from "i18next"
import {translationKeys} from "../../../localizations/translationKeys-localization"
import {APP_ACTION_TYPE} from "../../reducers/appReducer"
import {useDispatch, useSelector} from "react-redux"
import useLinearScaleMgr from "../../hooks/useLinearScaleMgr"

export const LinearScaleNumbersAndCircles = props => {
    const reduxDispatch = useDispatch()
    const {doAddScaleValue, doRemoveScaleValue} = useLinearScaleMgr()

    const formData = useSelector(state => state.appReducer.formData)
    const question = formData.pages[0].questions[props.index]

    const [addShouldBeEnabled, setAddShouldBeEnabled] = useState(false)
    const [deleteShouldBeEnabled, setDeleteShouldBeEnabled] = useState(false)
    const [startAddingScaleValue, setStartAddingScaleValue] = useState(false)
    const [startRemovingScaleValue, setStartRemovingScaleValue] = useState(false)

    // 'addClicked' is needed so that animation is not shown when component is first loaded.
    const [addClicked, setAddClicked] = useState(false)

    const firstLabelOnScale = question.choices?.[0]?.label
    const linearScaleLength = question.choices?.length

    useEffect(() => {
        if (startRemovingScaleValue) {
            removeScaleValue()
        }
    }, [startRemovingScaleValue])

    useEffect(() => {
        if (startAddingScaleValue) {
            addScaleValue()
        }
    }, [startAddingScaleValue])

    useEffect(() => {
        let startFromOne = parseInt(firstLabelOnScale) === 1

        let addShouldBeEnabledFunc = () => {
            if (startFromOne) {
                return linearScaleLength < 10
            } else {
                return linearScaleLength < 11
            }
        }
        let deleteShouldBeEnabledFunc = () => {
            return linearScaleLength > (startFromOne ? 2 : 3)
        }

        setAddShouldBeEnabled(addShouldBeEnabledFunc())
        setDeleteShouldBeEnabled(deleteShouldBeEnabledFunc())
    }, [formData])

    const addScaleValue = () => {
        setAddClicked(true)
        doAddScaleValue(firstLabelOnScale, props.index)

        setTimeout(() => {
            // Give time for the new Scale Value to be added before setting the enabled state of add and delete buttons.
            setAddClicked(false)
            setAddShouldBeEnabled(() => {
                // Since a new Linear Scale Value was added, determine if adding another LS value should be disabled.
                const startFromZero = parseInt(firstLabelOnScale) === 0
                const choicesLength = question.choices?.length
                return startFromZero ? choicesLength < 11 : choicesLength < 10
            })
            setStartAddingScaleValue(false)

            setDeleteShouldBeEnabled(() => {
                // Since a Linear Scale Value was removed, determine if removing another LS value should be disabled.
                const startFromZero = parseInt(firstLabelOnScale) === 0
                const choicesLength = question.choices?.length
                return startFromZero ? choicesLength > 3 : choicesLength > 2
            })
        }, 500)
    }

    const removeScaleValue = () => {
        doRemoveScaleValue(props.index, linearScaleLength)
        const updatedFormData = {...formData}
        setTimeout(() => {
            question.choices.pop()
            reduxDispatch({type: APP_ACTION_TYPE.SET_FORM_DATA, payload: updatedFormData})

            setAddShouldBeEnabled(() => {
                // Since a new Linear Scale Value was added, determine if adding another LS value should be disabled.
                const startFromZero = parseInt(firstLabelOnScale) === 0
                const choicesLength = question.choices?.length
                return startFromZero ? choicesLength < 11 : choicesLength < 10
            })
            setStartRemovingScaleValue(false)
            setDeleteShouldBeEnabled(() => {
                // Since a Linear Scale Value was removed, determine if removing another LS value should be disabled.
                const startFromZero = parseInt(firstLabelOnScale) === 0
                const choicesLength = question.choices?.length
                return startFromZero ? choicesLength > 3 : choicesLength > 2
            })
        }, 500)
    }

    return <>
        <div style={{display: "flex", alignItems: "center"}}>
            {/*NUMBERS Start*/}
            <div className={"linear-scale-padded-row"} id={`ls-numbers-${props.index}`}>
                {
                    (() => {
                        const cols = []

                        question.choices.map((lsValue, index) => {
                            cols.push(
                                <div key={index}
                                     className={`linear-scale-col ${addClicked && index === question.choices.length - 1 ? 'show-linear-scale-fade-in-on-add' : ''}`}
                                     id={`ls-numbers-child-${props.index}-${index}`}>
                                    {parseInt(firstLabelOnScale) === 0 ? index : index + 1}
                                </div>
                            )
                        })

                        return cols
                    })()
                }
            </div>
            {/*The below Div is for proper spacing only*/}
            <div style={{
                display: "flex",
                visibility: "hidden",
                alignItems: "center",
                paddingLeft: "15px"
            }}>
                <img src={optionAddGrey} alt={"Add"}/>
                <img src={optionDeleteBlue}
                     style={{paddingLeft: "10px"}}
                     alt={"Delete"}
                />
            </div>
            {/*NUMBERS End*/}
        </div>
        <div style={{display: "flex", alignItems: "center"}}>
            <div className={"linear-scale-padded-row linear-scale-padded-row-border"}>
                {/*CIRCLES Start*/}
                {
                    (() => {
                        const cols = []

                        question.choices.map((lsValue, index) => {
                            cols.push(
                                <div key={index}
                                     className={`linear-scale-col ${addClicked && index === question.choices.length - 1 ? 'show-linear-scale-fade-in-on-add' : ''}`}
                                     id={`ls-circles-child-${props.index}-${index}`}>
                                    {<img alt={`linear-scale-circle-${props.index}-${index}`} src={linearScaleCircle}/>}
                                </div>
                            )
                        })

                        return cols
                    })()
                }
                {/*CIRCLES End*/}
            </div>
            <div style={{display: "flex", alignItems: "center", paddingLeft: "15px"}}>
                {
                    (() => {
                        if (addShouldBeEnabled && !startAddingScaleValue) return (
                            <img src={optionAddBlue} alt={t(translationKeys.add)}
                                 onClick={() => {
                                     setStartAddingScaleValue(true)
                                 }
                                 }
                                 style={{cursor: "pointer"}}
                            />
                        )

                        return (
                            <img src={optionAddGrey} alt={t(translationKeys.add)}/>
                        )
                    })()
                }
                {
                    (() => {
                            if (deleteShouldBeEnabled && !startRemovingScaleValue) return (
                                <img src={optionDeleteBlue} alt={t(translationKeys.delete)}
                                     onClick={
                                         () => {
                                             setStartRemovingScaleValue(true)
                                         }
                                     }
                                     style={{cursor: "pointer", paddingLeft: "10px"}}
                                />
                            )

                            return (
                                <img src={optionDeleteGrey} alt={t(translationKeys.delete)}
                                     style={{paddingLeft: "10px"}}
                                />
                            )
                        }
                    )()
                }
            </div>
        </div>
    </>
}

LinearScaleNumbersAndCircles.propTypes = {
    prop: PropTypes.func,
    prop1: PropTypes.func,
    prop2: PropTypes.func,
    prop3: PropTypes.func
}
