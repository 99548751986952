import React from 'react'
import {getDateWithTime} from "../../../shared/utils/get-date-with-time";
import {getScoreColor} from "../utils/get-score-color";
import {isAllQuestionsEvaluated} from "../utils/evaluation-helper";
import {useTranslation} from "react-i18next";
import {translationKeys} from "../../../localizations/translationKeys-localization";
import {useDispatch, useSelector} from "react-redux";
import {RESPONSES_ACTION_TYPE} from "../../reducers/responsesReducer";
import {isMobile, isMobileOnly} from "react-device-detect";
import multiselectImg from "../../../../public/images/check_box_tick.svg";
import {COLLABORATOR_ROLES} from "../../builder/enums/collaboratorsRoles";

export default function QuizSummaryResponderCard(props) {
    const {t} = useTranslation()
    const userId = useSelector(state => state.userReducer?.userId)
    const selectedLanguage = useSelector(state => state.languageReducer.selectedLanguage)
    const formResponses = useSelector(state => state.responsesReducer.formResponsesData)
    const isValidated = props.isQuizV2 ? isAllQuestionsEvaluated(props.responderData.responses) : null;
    const percentage = props.responderData?.quiz_data?.results?.correct_percentage ? Math.round(props.responderData.quiz_data.results.correct_percentage) : 0
    const multiSelectResponsesArray = useSelector(state => state.responsesReducer.multiSelectResponsesArray)
    const dispatch = useDispatch()
    let timer

    const handleMultipleSelect = (e, respondent) => {
        e.stopPropagation();
        let role
        formResponses.formData.collaborators?.map((collaborator) => {
            if (collaborator.user_id === userId) {
                role = collaborator.role
            }
        })
        if (role === COLLABORATOR_ROLES.EDITOR || role === COLLABORATOR_ROLES.VIEWER) {
            return;
        }
        if (multiSelectResponsesArray.indexOf(respondent._id) !== -1) {
            // Remove the clicked Form
            let multipleSelectedArray = [...multiSelectResponsesArray]

            multipleSelectedArray.splice(multipleSelectedArray.indexOf(respondent._id), 1)
            dispatch({
                type: RESPONSES_ACTION_TYPE.SET_MULTI_SELECT_RESPONSES_ARRAY,
                payload: multipleSelectedArray
            })
        } else {
            // Add the clicked Form
            dispatch({
                type: RESPONSES_ACTION_TYPE.SET_MULTI_SELECT_RESPONSES_ARRAY,
                payload: [...multiSelectResponsesArray, respondent._id]
            })
        }
    };

    const setLongPressEvent = (e, respondent) => {
        if (isMobile) {
            timer = setTimeout(() => {
                handleMultipleSelect(e, respondent);
            }, 350);
            return false;
        }
    }

    const clearLongPressEvent = () => {
        if (isMobile) {
            clearTimeout(timer);
            return false;
        }
    }

    return (
        <div key={props.index} className='mobile-summary-quiz-card'
             onTouchStart={(e) => setLongPressEvent(e, props.responderData)}
             onMouseDown={(e) => setLongPressEvent(e, props.responderData)}
             onTouchMove={clearLongPressEvent}
             onTouchEnd={clearLongPressEvent}
             onMouseUp={clearLongPressEvent}
             onClick={(e) => {
                 if (!multiSelectResponsesArray?.length) {
                     props.changeResponseView(t(translationKeys.individual), props.index + 1)
                 } else {
                     handleMultipleSelect(e, props.responderData)
                 }
             }}>
            <div id='QuizSummary-score-count-container' className='score-count-container' style={{borderColor: props.isQuizV2 && !isValidated ? '#FBC02D' :  getScoreColor(percentage), margin: (selectedLanguage === 'fa' || selectedLanguage === 'ar') ? '0 0 0 10px' : null}}>
                <span style={{fontSize: '12px', marginBottom: (props.isQuizV2 && !isValidated ? '10px' : null)}}>{props.isQuizV2 ? isValidated ? (percentage + '%') : null : (percentage + '%')}</span>
                {props.isQuizV2 && !isValidated ? <img style={{width: '24px', marginBottom:"10px"}} alt='dots' src='images/more_grey.png'/> : null}
            </div>
            <div id='quiz-summary-card-text-container' className='quiz-summary-card-text-container'>
                {multiSelectResponsesArray?.length > 0 ?
                    <div
                        className={multiSelectResponsesArray.indexOf(props.responderData._id) !== -1 ? 'multiple-select-tag-for-delete selected-for-delete' : 'multiple-select-tag-for-delete'}
                        style={isMobileOnly ? {
                            position: "relative",
                            top: "0",
                            left: (selectedLanguage === 'fa' || selectedLanguage === 'ar' || props.isQuizV2) ? "95%" : null,
                            right: (selectedLanguage === 'fa' || selectedLanguage === 'ar') ? "90%" : null
                        } : null}
                        onClick={(e) => handleMultipleSelect(e, props.responderData)}>
                        {multiSelectResponsesArray.indexOf(props.responderData._id) !== -1 ?
                            <img style={{width: '120%', height: '120%'}} alt='select'
                                 src={multiselectImg}/> : null}
                    </div> : null}
                <span className='quiz-summary-card-user-text'>{(props.responderData.quiz_data && props.responderData.quiz_data.user_info) ?
                    props.responderData.quiz_data.user_info.user_info_1 : 'No Data'}</span>
                {formResponses?.formData?.setting?.is_email ?
                    <span className='quiz-summary-card-time-text'>{props.responderData.responder_email_id ?
                        props.responderData.responder_email_id : 'No Data'}</span> : null}
                <span className='quiz-summary-card-time-text'>
                    {props.responderData ? <span dir={'ltr'}>{getDateWithTime(props.responderData.submit_time)}</span> : '--:--'}
                </span>
                {props.isQuizV2 ?
                <div  style={{display: 'flex', alignItems: 'center'}}>
                    <span id='question-validated-status' style={{fontSize:'14px', marginRight: '5px'}}>{t(translationKeys.correction_status)} </span>
                    <div className='question-validated-tag'>
                        {isValidated ?
                            <div className='validated-tick-mark-circle'>
                                <img alt='validated'
                                     src='images/done.svg'/>
                            </div> : <img alt='validated' style={{width: '12px', marginRight: '6px'}}
                                          src='images/pending_validation.svg'/>}
                        <span id='question-validation-status' style={{color: isValidated ? '#46A24A' : '#F8B913'}}>
                            {isValidated ? t(translationKeys.evaluated) : t(translationKeys.pending_evaluation)}
                        </span>
                    </div>
                </div> : null}
            </div>
        </div>
    )
}
