import React from "react"
import {Provider, useDispatch, useSelector} from "react-redux"
import '../../shared/css/autocomplete.css'
import {OPTIONS_ACTION_TYPE} from "../../app/reducers/optionsReducer"
import {registerGAEvent} from "../utils/analytics";
import appStore from "../../app/store/appStore";
import {isMobileOnly} from "react-device-detect";
import {ATTACHMENT_TYPE} from "../utils/constants";

export default function OptionAutocompleteSuggestions(props) {
    const reduxDispatch = useDispatch()
    const searchMatches = useSelector(state => state.optionsReducer?.matches)

    const updateOptionText = match => (e) => {
        registerGAEvent('Builder', 'Question', 'used-option-auto-suggestion');
        e.stopPropagation();
        props.optionFieldRef.current.style.height = "auto"
        reduxDispatch({type: OPTIONS_ACTION_TYPE.SET_SELECTED_OPTION_VALUE, payload: match})
        reduxDispatch({type: OPTIONS_ACTION_TYPE.CLEAR_SUGGESTIONS})
        reduxDispatch({type: OPTIONS_ACTION_TYPE.SUGGESTION_CLICKED, payload: false})
    }

    const difference = 60 /* difference between two options */
    const initialHeight = props.optionFieldRef?.current?.offsetHeight + 120 /* initial height of options container */

    const getTopPosition = () => {
        let attachmentHeight
        if (props.attachment?.file_type === ATTACHMENT_TYPE.IMAGE.key) {
            attachmentHeight = 310
        } else if (props.attachment?.file_type === ATTACHMENT_TYPE.WEBSITE.key) {
            attachmentHeight = 150
        } else if (props.attachment?.image_url) {
            attachmentHeight = 350
        } else if (props.attachment) {
            attachmentHeight = 50
        } else {
            attachmentHeight = 0
        }
        const top = (initialHeight - (difference * (props.choicesCount - props.choiceIndex))) + attachmentHeight /* initial top position which comes by subtracting position from height and adding attachment height */

        if (props.choicesCount - props.choiceIndex > 5) {
            return ((top + (60 * (props.choicesCount - props.choiceIndex - 5))) < 115 ? 115 : (top + (60 * (props.choicesCount - props.choiceIndex - 5))))+"px"
        } else {
            return (top < 115 ? 115 : top)+"px"
        }
    }

    return (
        <Provider store={appStore}>
            <div id={'option-auto-complete-suggestion'} className='autocomplete-dropdown autocomplete-dropdown-box-shadow'
                 style={{
                     width: isMobileOnly ? '76%' : '84%',
                     right: isMobileOnly ? '13%' : "10%",
                     top: getTopPosition(),
                     marginBottom: '5px'}}>
                {
                    searchMatches?.map((match, index) => {
                        return (
                            <div className='autocomplete-suggestion-row' key={index}
                                 onClick={updateOptionText(match)}
                                 onMouseDown={() => {
                                     reduxDispatch({type: OPTIONS_ACTION_TYPE.SUGGESTION_CLICKED, payload: true})
                                 }}>
                                {match}
                            </div>
                        )
                    })
                }
            </div>
        </Provider>
    )
}
