// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3ButxRSMgetLzHM9gRmKKB {\n    line-height: 1.75rem !important;\n    width: 100%;\n    overflow: auto;\n    border-color: transparent;\n    background-color: transparent;\n    border-bottom: 1px solid #e0e0e0;\n    outline: none;\n    resize: none;\n    max-height: 400px;\n    font-family: \"Nunito Sans\", Sans-serif, serif;\n}\n\n._3ButxRSMgetLzHM9gRmKKB:focus {\n    transition: height 0.5s ease;\n    border-bottom: 1px solid #005cb2;\n}\n\n._3ButxRSMgetLzHM9gRmKKB::-webkit-scrollbar-track-piece {\n    display: none;\n}\n\n._3ButxRSMgetLzHM9gRmKKB::-webkit-scrollbar {\n    display: none;\n}\n\n._1d496HWBWbuirCwDl-bbwX {\n    width: 100%;\n    overflow: auto;\n    font-size: 18px;\n    background-color: transparent;\n    border-color: transparent;\n    border-bottom: 1px solid #e0e0e0;\n    outline: none;\n    resize: none;\n}\n\n._1d496HWBWbuirCwDl-bbwX:focus {\n    transition: height 0.5s ease;\n    border-bottom: 1px solid #005cb2;\n}\n\n._1d496HWBWbuirCwDl-bbwX::-webkit-inner-spin-button,\n._1d496HWBWbuirCwDl-bbwX::-webkit-outer-spin-button {\n    -webkit-appearance: none;\n    -moz-appearance: textfield;\n}\n\n._1d496HWBWbuirCwDl-bbwX::placeholder {\n    color: #9e9e9e;\n    font-size: 18px;\n}\n", ""]);
// Exports
exports.locals = {
	"answer_text_field": "_3ButxRSMgetLzHM9gRmKKB",
	"answer_text_field_input": "_1d496HWBWbuirCwDl-bbwX"
};
module.exports = exports;
