import React, {useEffect, useRef} from "react";
import '../css/material-switch.css'
import {MDCSwitch} from "@material/switch/index"

const MaterialSwitch = props => {
    const switchContainerRef = useRef(null);
    const switchRef = useRef(null);

    useEffect(() => {
        if (props.index === undefined) {
            console.error("MaterialSwitch.js: props.index={number} is required from Parent component.")
        }

        if (props.switchChanged === undefined) {
            console.error("MaterialSwitch.js: props.switchChanged={targetFunction} is required from Parent component.")
        }
    }, [])

    useEffect(() => {
        const switchControl = new MDCSwitch(switchContainerRef.current);

        if (props.initialCheckedState !== undefined) {
            switchControl.checked = props.initialCheckedState
        }
    })

    function handleMDCSwitch() {
        props.switchChanged(props.index, switchRef.current.checked)
    }

    return (
        <div>
            <div id={'mdc_switch_' + props.index} className={`mdc-switch ${props.isSwitchDisabled ? 'mdc-switch--disabled': ''}`} ref={switchContainerRef} onChange={() => {
                handleMDCSwitch(props.index)
            }}>
                <div className="mdc-switch__track"/>
                <div className="mdc-switch__thumb-underlay">
                    <div className="mdc-switch__thumb"/>
                    <input type="checkbox" ref={switchRef} className="mdc-switch__native-control" role="switch"
                           aria-checked="false" style={props.customStyle ? props.customStyle : null}/>
                </div>
            </div>
            <label htmlFor="basic-switch" style={{display: "none"}}>off/on</label>
        </div>
    )
}

export default MaterialSwitch
