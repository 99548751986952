import React from 'react'
import MaterialSlider from "./MaterialSlider"
import '../../shared/css/slider.css'
import leftBlueArrow from '../../../public/images/expand_less_white.svg'
import rightBlueArrow from '../../../public/images/expand_more_white.svg'
import {useSelector} from "react-redux";

const NumberSlider = props => {
    const selectedLanguage = useSelector(state => state.languageReducer.selectedLanguage)

    const decreaseValue = () => {
        if (props.currentNumber > 0) {
            props.setCurrentNumber(props.currentNumber - 1)
        }
    }

    const increaseValue = () => {
        if (props.currentNumber < props.maxCount) {
            props.setCurrentNumber(props.currentNumber + 1)
        }
    }
    const handleTextInputChange = (e) => {
        if (e.target.value % 1 !== 0) {
            return
        }

        props.setCurrentNumber(e.target.value)
    }

    const handleTextInputBlur = (value) => {
        if (value > props.maxCount) {
            props.setCurrentNumber(props.maxCount)
        } else if (!value) {
            props.setCurrentNumber(0)
        }
    }

    return (
        <>
            <div id={'slider-label'} className='slider-label' style={{fontSize: 20}}>
                {props.label}:
                <input type='number'
                       className='slider-input'
                       aria-labelledby="my-label-id"
                       onChange={(e) => handleTextInputChange(e)}
                       onBlur={(e) => handleTextInputBlur(e.target.value)}
                       maxLength={String(props.maxCount).length}
                       max={props.maxCount}
                       min={0}
                       value={props.currentNumber}
                />
            </div>
            <div className='slider-card-2'>
                <img
                    className={'slider-left-blue-arrow'}
                    src={(selectedLanguage === 'fa' || selectedLanguage === 'ar') ? rightBlueArrow : leftBlueArrow}
                    alt="Decrease"
                    onClick={decreaseValue}
                />
                <MaterialSlider currentPosition={Math.abs(props.currentNumber)}
                                sliderWidth={'70%'}
                                margin={"auto"}
                                handleChange={(value) => props.setCurrentNumber(parseInt(value))}
                                minPosition={0}
                                maxPosition={props.maxCount}
                                primaryColor={'#0048a4'}
                />
                <img
                    className={'slider-right-blue-arrow'}
                    src={(selectedLanguage === 'fa' || selectedLanguage === 'ar') ? leftBlueArrow : rightBlueArrow}
                    alt="Increase"
                    onClick={increaseValue}
                />
            </div>
        </>
    )
}

export default NumberSlider
