import React, {useEffect, useRef} from 'react';
import styles from '../css/form-float-button-container.module.css'
import {isImageTheme} from "../utils/theme-color-helper";
import {useSelector} from "react-redux";

export const FormFloatButtonContainer = (props) => {
    const formData = useSelector(state => state.formDataReducer.formData)
    const ROTATE_180_DEGREE = 'rotate(180deg)';
    const ROTATE_0_DEGREE = 'rotate(0deg)';
    const TRANSITION_EASE_IN_OUT = '0.2s ease-in-out';
    const scrollFabImageRef = useRef(undefined);

    useEffect(() => {
        props.formContainerRef.current.onscroll = (() => {
            if (props.formContainerRef.current.scrollTop === 0) {
                scrollFabImageRef.current.style.transform = ROTATE_0_DEGREE;
            } else if (props.formContainerRef.current.offsetHeight <= props.formContainerRef.current.scrollHeight) {
                scrollFabImageRef.current.style.transform = ROTATE_180_DEGREE;
            } else if (props.formContainerRef.current.scrollTop === props.formContainerRef.current.scrollHeight) {
                scrollFabImageRef.current.style.transform = ROTATE_180_DEGREE;
            }
            scrollFabImageRef.current.style.transition = TRANSITION_EASE_IN_OUT;
        })
    }, []);

    const handleScrollFabEvent = () => {
        if (scrollFabImageRef.current.style.transform === ROTATE_0_DEGREE) {
            scrollFabImageRef.current.style.transform = ROTATE_180_DEGREE;
            props.formContainerRef.current.scrollTo({
                top: props.formContainerRef.current.scrollHeight,
                behavior: 'smooth',
            });
        } else if (scrollFabImageRef.current.style.transform === ROTATE_180_DEGREE) {
            scrollFabImageRef.current.style.transform = ROTATE_0_DEGREE;
            props.formContainerRef.current.scrollTo({
                top: 0,
                behavior: 'smooth',
            });
        }
        scrollFabImageRef.current.style.transition = TRANSITION_EASE_IN_OUT;
    }

/*
    const moveToNextQuestion = () => {
        const formData = new FormData(props.formRef.current)
        let list = createList(formData, props.formQuestions)
        props.setIndex(prevData => {
            const updatedIndex = props.index + 1
            return updatedIndex
        })
        focusing(list)
    }
*/
/*
    const focusing = (list) => {
        let allQuestionElement;
        list.list.map((Question, index) => {
            for (let i = 0; i < props.allQuestionRefList.current.length; i++) {
                allQuestionElement = props.allQuestionRefList.current[i];
                if (Question.id === allQuestionElement.question_id) {
                    if (index === props.index && allQuestionElement.allQuestionRef.current) {
                        allQuestionElement.allQuestionRef.current.scrollIntoView({behavior: 'smooth'});
                        const coreFormElement = allQuestionElement.allQuestionRef.current.querySelector('input, textarea, select');
                        coreFormElement ? coreFormElement.focus() : null;
                    }
                    if (props.index === props.allQuestionRefList.current.length-1) {
                        document.getElementById('scroll_fab_navigate').style.display = 'none'
                    }
                    break;
                }
            }
        })
    }
*/
/*
    const createList = (formData, formQuestions) => {
        let list = []
        let questionData;
        for (let i = 0; i < formQuestions.length; i++) {
            questionData = formQuestions[i];
            list.push({id: questionData._id, type: questionData.type})
        }
        return {list: list};
    }
*/

    return (
        <div className={styles.scroll_fab} style={props.previewType && (localStorage?.getItem('i18nextLng') === 'fa' || localStorage?.getItem('i18nextLng') === 'ar') ? {left: 0, right: "auto"} : null}>
            <div id="scroll_fab" className={styles.scroll_to_next}
                 style={{
                     backgroundColor: isImageTheme(formData.theme) ? '#000' : '#424242'
                 }}
                 onClick={(e) => {
                     handleScrollFabEvent(e);
                 }}>
                <img ref={scrollFabImageRef}
                     alt={'scroll'}
                     align="middle"
                     id="scroll_fab_icon"
                     src="/images/expand_more-24px.svg"
                     className={styles.image_icon}
                     style={{transform: ROTATE_0_DEGREE}}
                />
            </div>
        </div>
    );
}
