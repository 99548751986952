import {APP_ACTION_TYPE} from "../reducers/appReducer"
import {LINEAR_SCALE_LABEL_TYPES} from "../../shared/utils/constants"
import {useDispatch, useSelector} from "react-redux"

const useLinearScaleMgr = () => {
    const reduxDispatch = useDispatch()

    const formData = useSelector(state => state.appReducer.formData)

    const startFromZeroSwitchChanged = (questionIndex) => {
        const updatedFormData = {...formData}
        const firstLabelOnScale = updatedFormData.pages?.[0]?.questions?.[questionIndex]?.choices?.[0]?.label
        const startFromOne = parseInt(firstLabelOnScale) === 1

        if (startFromOne) {
            // Make the Scale Start From Zero
            updatedFormData.pages[0].questions[questionIndex].choices.unshift({label: "0"})
        } else {
            // Make the Scale Start From One

            updatedFormData.pages[0].questions[questionIndex].choices = updatedFormData.pages[0].questions[questionIndex].choices.slice(1)
        }

        reduxDispatch({type: APP_ACTION_TYPE.SET_FORM_DATA, payload: updatedFormData})
    }

    const doAddScaleValue = (firstLabelOnScale, questionIndex) => {
        const updatedFormData = {...formData}
        const startFromOne = parseInt(firstLabelOnScale) === 1

        if (startFromOne) {
            updatedFormData.pages?.[0]?.questions?.[questionIndex].choices.push({label: (updatedFormData.pages?.[0]?.questions?.[questionIndex].choices.length + 1).toString()})
        } else {
            updatedFormData.pages?.[0]?.questions?.[questionIndex].choices.push({label: (updatedFormData.pages?.[0]?.questions?.[questionIndex].choices.length).toString()})
        }

        reduxDispatch({type: APP_ACTION_TYPE.SET_FORM_DATA, payload: updatedFormData})
    }

    const doRemoveScaleValue = (questionIndex, linearScaleLength) => {
        const lastNumberDiv = document.querySelector(`#ls-numbers-child-${questionIndex}-${linearScaleLength - 1}`)
        const lastCircleDiv = document.querySelector(`#ls-circles-child-${questionIndex}-${linearScaleLength - 1}`)

        lastNumberDiv.style.transition = "opacity 1s"
        lastNumberDiv.style.opacity = "0"

        lastCircleDiv.style.transition = "opacity 1s"
        lastCircleDiv.style.opacity = "0"
    }


    const updateLabel = (questionIndex, labelToUpdate, text) => {
        const updatedFormData = {...formData}
        switch (labelToUpdate) {
            case LINEAR_SCALE_LABEL_TYPES.START:
                updatedFormData.pages[0].questions[questionIndex].start_label = text
                break
            case LINEAR_SCALE_LABEL_TYPES.CENTER:
                updatedFormData.pages[0].questions[questionIndex].center_label = text
                break
            case LINEAR_SCALE_LABEL_TYPES.END:
                updatedFormData.pages[0].questions[questionIndex].end_label = text
                break
        }

        reduxDispatch({type: APP_ACTION_TYPE.SET_FORM_DATA, payload: updatedFormData})
    }

    return {
        doAddScaleValue,
        doRemoveScaleValue,
        startFromZeroSwitchChanged,
        updateLabel
    }
}

export default useLinearScaleMgr