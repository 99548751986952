import React, {useState} from 'react';
import closePng from '../../../../public/images/close.png';
import doneGreen from "../../../../public/images/done_green.png";

const AwsThemeCard = (props) => {

            const imageFile = props.image;
            const [clickedCard, setClickedCard] = useState(false);
        //    const [showDeleteAlert, setShowDeleteAlert] = useState(false);


    if (imageFile !== null) {
        return (
            // theme card element
            <div id={'themes-card'} className='themes-card'  name={imageFile}
                 onClick={(e) => {
                     e.stopPropagation();
                     setClickedCard(true);
                     props.handleClick(imageFile);
                     props.updateChosenTheme(imageFile);
                 }}>
                {/*theme image*/}
                <img className={'theme-image'} src={imageFile} alt={imageFile}/>

                {/*delete button*/}
                <div className="delete-icon-container center"
                     onClick={(e) => {
                         e.stopPropagation();
                         props.deletePopUp(true, imageFile);
                     }}>
                    <img src={closePng} alt="/images/close.png"/>
                </div>
                <div>
                    {(() => {
                        if (!props.hideCheck) return (
                            /*theme chosen tick mark*/
                            <div className="theme-round center"
                                 style={((props.lastClickedImage === imageFile) ? {
                                     display: 'flex',
                                     top: '52px'
                                 } : {display: 'none'} )}>
                                <img className="theme-chosen-image"
                                     src={doneGreen}
                                     alt='/images/done_green.png'
                                />
                            </div>
                        )
                    }
                    )()
                    }
                </div>

            </div>

        )
    } else {
        return null
    }
}

export default AwsThemeCard;
