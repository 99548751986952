// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2TUMV7YWhzS3O_xU3KCW3S {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    flex-wrap: wrap;\n    flex-direction: column;\n}\n\n._2TUMV7YWhzS3O_xU3KCW3S:focus {\n    outline: none;\n}\n\n._15dmpi4KFSvLl9fMuJF2eN {\n    display: flex;\n}\n\n._2TUMV7YWhzS3O_xU3KCW3S input {\n    display: none;\n}\n\n._2TUMV7YWhzS3O_xU3KCW3S img {\n    height: 50px;\n    width: 50px;\n    cursor: pointer;\n    margin: 5px;\n}\n\n\n._2TUMV7YWhzS3O_xU3KCW3S span {\n    font-size: 18px;\n    font-weight: bolder;\n    margin: 0 25px;\n    letter-spacing: 0.3px;\n    text-align: center;\n    overflow: hidden;\n    width: 80%;\n    text-overflow: ellipsis;\n}\n\n@media screen and (max-width: 600px) {\n    ._2TUMV7YWhzS3O_xU3KCW3S img {\n        height: 40px;\n        width: 40px;\n        margin: 2px;\n    }\n}\n", ""]);
// Exports
exports.locals = {
	"star_rating_container": "_2TUMV7YWhzS3O_xU3KCW3S",
	"stars_container": "_15dmpi4KFSvLl9fMuJF2eN"
};
module.exports = exports;
