import React from "react"
import ModalDialog from "./ModalDialog"
import {translationKeys} from "../../localizations/translationKeys-localization";
import {useTranslation} from "react-i18next";

const UploadLimitReachedModalDialog = (props) => {
    const {t} = useTranslation()
    return (
        <div id={'upload-limit-reached-modal-dialog'} >
            <ModalDialog
                header={t(translationKeys.limit_reached)}
                body={t(translationKeys.limit_reached_description)}
                buttons={
                    [
                        {
                            text: t(translationKeys.ok),
                            action:
                                () => {
                                    props.OKAction()
                                }
                        }
                    ]
                }
            />
        </div>
    )
}

export default UploadLimitReachedModalDialog