import React, {useEffect, useRef, useState} from 'react'
import {CHART_TYPE} from "./SummaryQuestionCard";
import {useDispatch, useSelector} from "react-redux";
import {getResultOptionBars} from "./ResultsContainer";
import {getBgColors} from "../utils/graph";
import {DonutChart} from "./DonutChart";
import PoweredBySH from "../../../shared/components/PoweredBySurveyheart";
import bar_grey from "../../../../public/images/icons/bar_grey.svg";
import bar_white from "../../../../public/images/icons/bar_white.svg";
import donut_grey from "../../../../public/images/icons/donut_grey.svg";
import donut_white from "../../../../public/images/icons/donut_white.svg";
import percentage_grey from "../../../../public/images/icons/percentage_grey.svg";
import percentage_white from "../../../../public/images/icons/percentage_white.svg";
import {ATTACHMENT_TYPE, getQuestionCategory, QUESTION_CATEGORY, QUESTION_TYPE} from "../../../shared/utils/constants";
import {t} from "i18next";
import {translationKeys} from "../../../localizations/translationKeys-localization";
import PercentageContainer from "./PercentageContainer";
import {RESPONSES_ACTION_TYPE} from "../../reducers/responsesReducer";
import {getDateWithTime} from "../../../shared/utils/get-date-with-time";
import {isMobileOnly} from "react-device-detect";
import styles from "../../../form/css/question-attachment.module.css";
import {getResultOptionGridBars} from "./GridResultsContainer";
import two_columns_three_rows_active from "../../../../public/images/two_columns_three_rows_active.svg";
import two_columns_three_rows_inactive from "../../../../public/images/two_columns_three_rows_inactive.svg";
import three_columns_active from "../../../../public/images/three_columns_active.svg";
import three_columns_inactive from "../../../../public/images/three_columns_inactive.svg";
import slider_rows_active from "../../../../public/images/slider_rows_active.svg";
import slider_rows_inactive from "../../../../public/images/slider_rows_inactive.svg";
import GroupedBarChartContainer from "./GroupedBarChartContainer";
import StackedBarChartContainer from "./StackedBarChartContainer";
import {getGridAnswer} from "../utils/get-grid-type-answer"

const MobileQuestionScreen = (props) => {
    const formResponses = useSelector(state => state.responsesReducer.formResponsesData)
    const currentQuestionIndex = useSelector(state => state.responsesReducer.currentQuestionIndex)
    const questionSummaryData = currentQuestionIndex === -1 ? false : props.summaryDataSet.questionsData[currentQuestionIndex]
    const originalFormRespondent = useSelector(state => state.responsesReducer.originalFormRespondents)
    const respondent = useSelector(state => state.responsesReducer.formResponsesData.formResponses.respondents)
    const questions = useSelector(state => state.responsesReducer.formResponsesData.formData.pages[0].questions)

    const [chartType, setChartType] = useState(() => {
        // This is the first Chart displayed for the QUESTION_TYPE
        if (currentQuestionIndex === -1) {
            return CHART_TYPE.RESULT_CARD
        }
        if (props.summaryDataSet.questionsData[currentQuestionIndex].type === QUESTION_TYPE.CHECKBOX_GRID.key || props.summaryDataSet.questionsData[currentQuestionIndex].type === QUESTION_TYPE.MULTIPLE_CHOICE_GRID.key) {
            return CHART_TYPE.TABULAR_CHART
        }

        return CHART_TYPE.RESULT_CARD
    });
    const attachment = props.formData?.pages[0]?.questions[currentQuestionIndex]?.attachment
    const donutChartRef = useRef()
    const dispatch = useDispatch()

    let skippedAnswerCount = 0;
    let textAnswers = [];
    let isChoiceType = false;
    let answeredCount;

    useEffect(() => {
        if (currentQuestionIndex === -1) {
            setChartType(CHART_TYPE.RESULT_CARD)
        } else if (props.summaryDataSet.questionsData[currentQuestionIndex].type === QUESTION_TYPE.CHECKBOX_GRID.key || props.summaryDataSet.questionsData[currentQuestionIndex].type === QUESTION_TYPE.MULTIPLE_CHOICE_GRID.key) {
            setChartType(CHART_TYPE.TABULAR_CHART)
        } else {
            setChartType(CHART_TYPE.RESULT_CARD)
        }
    }, [currentQuestionIndex])

    if (getQuestionCategory(questionSummaryData.type) === QUESTION_CATEGORY.CHOICE_TYPE) {
        // getting choice type cards
        isChoiceType = true;
        answeredCount = questionSummaryData.responseCount;
        skippedAnswerCount = questionSummaryData.choiceTextAnswers.filter((answer) => answer.length === 0).length;
    } else if (getQuestionCategory(questionSummaryData.type) === QUESTION_CATEGORY.TEXT_TYPE || questionSummaryData.type === QUESTION_TYPE.FILE_UPLOAD.key) {
        // getting text and FILE_UPLOAD type answer cards
        isChoiceType = false;
        for (let j = 0; j < questionSummaryData.responseTextAnswers.length; j++) {
            let textAnswer = questionSummaryData.responseTextAnswers[j];
            if (textAnswer.length === 0) {
                skippedAnswerCount++
            }
            textAnswers.push({text: textAnswer, type: questionSummaryData.type});
        }
    } else if (getQuestionCategory(questionSummaryData.type) === QUESTION_CATEGORY.GRID_TYPE) {
        isChoiceType = true;
        answeredCount = questionSummaryData.responseCount;
        skippedAnswerCount = answeredCount - (questionSummaryData.grid_choices ? questionSummaryData.grid_choices.length : questionSummaryData.checkbox_grid_choices?.length);
    }

    let responsesCount;
    let answerCardToShow;
    if (isChoiceType) {
        responsesCount = questionSummaryData.choiceTextAnswers.length;
        switch (chartType) {
            case CHART_TYPE.RESULT_CARD:
                //result card
                answerCardToShow = getResultOptionBars(questionSummaryData, answeredCount, skippedAnswerCount);
                break;
            case CHART_TYPE.PIE_CHART:
                //pie chart
                let pieChartLabels = questionSummaryData.responseAnalyzeData.graphLabels?.slice(0);
                let pieChartData = questionSummaryData.responseAnalyzeData.graphData?.slice(0);
                if (questionSummaryData.responseTextAnswers && questionSummaryData.responseTextAnswers.length > 0) {
                    pieChartLabels.push('Others');
                    pieChartData.push(questionSummaryData.responseTextAnswers.length);
                }
                const colorsArray = getBgColors(pieChartLabels?.length)
                answerCardToShow = <>
                    <div>
                        {React.Children.toArray(pieChartLabels?.map((label, index) => {
                            return <span style={{display: "inline-block", margin: "5px"}}>
                                <span style={{
                                    background: colorsArray[index],
                                    width: 0,
                                    padding: "0 10px",
                                    webkitPrintColorAdjust: "exact"
                                }}></span>
                                <span style={{margin: "0 5px"}}>{label} - {pieChartData[index]}</span>
                            </span>
                        }))}
                    </div>
                </>
                break;
            case CHART_TYPE.BAR_CHART:
                //bar chart
                let barChartLabels = questionSummaryData.responseAnalyzeData.graphLabels?.slice(0);
                let barChartData = questionSummaryData.responseAnalyzeData.graphData?.slice(0);
                if (questionSummaryData.responseTextAnswers && questionSummaryData.responseTextAnswers.length > 0) {
                    barChartLabels.push('Others');
                    barChartData.push(questionSummaryData.responseTextAnswers.length);
                }
                answerCardToShow = <PercentageContainer graphLabels={barChartLabels}
                                                        graphData={barChartData}
                                                        answeredCount={answeredCount}
                                                        skippedAnswerCount={skippedAnswerCount}/>
                break;
            case CHART_TYPE.TABULAR_CHART:
                responsesCount = questionSummaryData.responseCount
                answerCardToShow = getResultOptionGridBars(questionSummaryData, questionSummaryData.responseCount, true)
                break;
            case CHART_TYPE.GROUPED_BAR_CHART:
                responsesCount = questionSummaryData.responseCount
                answerCardToShow = <GroupedBarChartContainer graphData={questionSummaryData}/>
                break;
            case CHART_TYPE.STACKED_BAR_CHART:
                responsesCount = questionSummaryData.responseCount
                answerCardToShow = <StackedBarChartContainer graphData={questionSummaryData}
                                                             originalFormRespondents={formResponses?.formResponses.respondents}/>
                break;
        }
    }

    const getGridResponse = (index) => {
        const questionsData = props.summaryDataSet.questionsData
        let currentQuestionData = questionsData[currentQuestionIndex]

        return getGridAnswer(index, currentQuestionData, originalFormRespondent, respondent, questions).gridTextAnswer
    }

    useEffect(() => {
        if (donutChartRef.current) {
            const donutData = questionSummaryData?.responseAnalyzeData?.graphData?.slice(0)
            donutData?.push(questionSummaryData.responseTextAnswers.length)
            DonutChart(donutChartRef.current, donutData, responsesCount - skippedAnswerCount);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [donutChartRef, chartType]);

    const responseText = (index) => {
        if (getQuestionCategory(questionSummaryData.type) === QUESTION_CATEGORY.CHOICE_TYPE) {
            if (questionSummaryData.choiceTextAnswers[props.summaryDataSet.responsesInfo.length - index - 1][0]?.length > 0) {
                if (questionSummaryData.choiceTextAnswers[props.summaryDataSet.responsesInfo.length - index - 1][1]?.length === 0) {
                    return (questionSummaryData.choiceTextAnswers[props.summaryDataSet.responsesInfo.length - index - 1][0] + ' (' + t(translationKeys.others) + ')')
                } else {
                    const answers = []
                    questionSummaryData.choiceTextAnswers[props.summaryDataSet.responsesInfo.length - index - 1].map((answer) => {
                        if (Array.isArray(answer)) {
                            answers.push(answer[0])
                        } else {
                            if (answers.length === 0) {
                                answers.push(answer)
                            }
                        }
                    })
                    if (answers.length > 2) {
                        return answers.splice(0, 2).join(", ") + '...'
                    } else if (answers.length === 0) {
                        return t(translationKeys.no_answer)
                    } else {
                        return answers.splice(0, 2).join(", ")
                    }
                }
            } else return t(translationKeys.no_answer)
        } else if (getQuestionCategory(questionSummaryData.type) === QUESTION_CATEGORY.TEXT_TYPE || questionSummaryData.type === QUESTION_TYPE.FILE_UPLOAD.key) {
            if (questionSummaryData.responseTextAnswers[props.summaryDataSet.responsesInfo.length - index - 1]?.length > 0) {
                return questionSummaryData.responseTextAnswers[props.summaryDataSet.responsesInfo.length - index - 1]
            } else return t(translationKeys.no_answer)
        } else if (getQuestionCategory(questionSummaryData.type) === QUESTION_CATEGORY.GRID_TYPE) {
            if (questionSummaryData.grid_choices) {
                if (questionSummaryData.type === QUESTION_TYPE.FILE_UPLOAD.key) {
                    return questionSummaryData.grid_choices.split('/').pop()
                } else {
                    if (questionSummaryData.type === QUESTION_TYPE.MULTIPLE_CHOICE_GRID.key || questionSummaryData.type === QUESTION_TYPE.CHECKBOX_GRID.key) {
                        return getGridResponse(index)
                    } else {
                        return questionSummaryData.grid_choices
                    }
                }
            } else if (questionSummaryData.checkbox_grid_choices) {
                if (questionSummaryData.type === QUESTION_TYPE.FILE_UPLOAD.key) {
                    return questionSummaryData.checkbox_grid_choices.split('/').pop()
                } else {
                    if (questionSummaryData.type === QUESTION_TYPE.MULTIPLE_CHOICE_GRID.key || questionSummaryData.type === QUESTION_TYPE.CHECKBOX_GRID.key) {
                        return getGridResponse(index)
                    } else {
                        return questionSummaryData.checkbox_grid_choices
                    }
                }
            } else {
                return t(translationKeys.no_answer)
            }
        }
    }

    const openAttachmentLink = () => {
        if (attachment.url) {
            window.open(props.attachment.url)
        }
    }

    return (
        <div style={{width: '100%', marginTop: '12%'}}>
            {attachment ? <div style={{
                padding: "15px"
            }}>
                {attachment && attachment.file_type === ATTACHMENT_TYPE.IMAGE.key ? <div style={{
                    justifyContent: "center",
                    display: 'flex',
                    height: isMobileOnly ? null : '300px'
                }}>
                    <img src={attachment?.image_url}
                         alt={'image_Url'}
                         style={{
                             display: 'flex',
                             width: isMobileOnly ? '100%' : null,
                             objectFit: 'cover'
                         }}/>
                </div> : null}
                {attachment && attachment.file_type !== ATTACHMENT_TYPE.IMAGE.key ?
                    <div className={styles.question_attachment_web_container} style={{
                        display: 'flex',
                        cursor: "pointer",
                        backgroundColor: '#f5f5f5'
                    }} onClick={openAttachmentLink}>
                        <img style={{
                            display: 'flex',
                            width: '30%'
                        }}
                             alt={'image'}
                             onError={e => e.target.src = '/images/link.svg'}
                             src={attachment?.image_url ? attachment?.image_url : '/images/link.svg'}/>
                        <div className={styles.question_attachment_text_container}
                             style={{
                                 display: 'flex',
                                 width: isMobileOnly ? '100%' : '90%'
                             }}>
                            <p className={styles.question_attachment_title}
                               style={{
                                   color: '#212121'
                               }}>
                                {attachment?.title}
                            </p>
                            <p className={styles.question_attachment_description}>
                                {attachment?.description}
                            </p>
                            <p id={props.id ? props.id : null} className={styles.question_attachment_url}
                               style={{
                                   color: '#0000ee'
                               }}>
                                {attachment?.url}
                            </p>
                        </div>
                    </div> : null}
            </div> : null}
            {getQuestionCategory(questionSummaryData.type) === QUESTION_CATEGORY.CHOICE_TYPE ?
                <div className={"response-question-card"}
                     style={{height: "auto", overflowX: "auto", maxHeight: "53vh"}}>
                    {isChoiceType ?
                        <div style={{
                            width: '35%',
                            margin: '5% auto',
                            display: "flex",
                            border: "solid 1px #bcbcbc",
                            borderRadius: "5px",
                            justifyContent: "space-between"
                        }}>
                            <img src={chartType === CHART_TYPE.RESULT_CARD ? bar_white : bar_grey}
                                 style={{
                                     padding: "10px",
                                     background: chartType === CHART_TYPE.RESULT_CARD ? "#004BA0" : null
                                 }}
                                 onClick={() => setChartType(CHART_TYPE.RESULT_CARD)}/>
                            <img src={chartType === CHART_TYPE.PIE_CHART ? donut_white : donut_grey}
                                 style={{
                                     padding: "10px",
                                     background: chartType === CHART_TYPE.PIE_CHART ? "#004BA0" : null
                                 }}
                                 onClick={() => setChartType(CHART_TYPE.PIE_CHART)}/>
                            <img src={chartType === CHART_TYPE.BAR_CHART ? percentage_white : percentage_grey}
                                 style={{
                                     padding: "10px",
                                     background: chartType === CHART_TYPE.BAR_CHART ? "#004BA0" : null
                                 }}
                                 onClick={() => setChartType(CHART_TYPE.BAR_CHART)}/>
                        </div> : null}
                    {answerCardToShow}
                    {chartType === CHART_TYPE.PIE_CHART ?
                        <div id='answers-container-cardToShow'
                             onScroll={e => e.stopPropagation()}
                             ref={donutChartRef}/> : null}
                    <div>
                        {skippedAnswerCount > 0 && chartType === CHART_TYPE.RESULT_CARD ?
                            <p className='skipped-count-text'>{t(translationKeys.skipped) + ':' + skippedAnswerCount}</p> : null}
                        <PoweredBySH/>
                    </div>
                </div> : null}
            {getQuestionCategory(questionSummaryData.type) === QUESTION_CATEGORY.GRID_TYPE ?
                <div style={{
                    margin: "10px auto",
                    background: "white",
                    padding: "15px 0",
                    borderRadius: "5px",
                    width: "96%",
                    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)"
                }}>
                    <div style={{display: "flex", justifyContent: "center"}}>
                        <img className='chart-icons' alt='results' onScroll={e => e.stopPropagation()}
                             src={(props.chartType === CHART_TYPE.TABULAR_CHART) ? two_columns_three_rows_active : two_columns_three_rows_inactive}
                             onClick={() => setChartType(CHART_TYPE.TABULAR_CHART)}
                        />
                        <img className='chart-icons' alt='pie-chart' onScroll={e => e.stopPropagation()}
                             src={(props.chartType === CHART_TYPE.GROUPED_BAR_CHART) ? three_columns_active : three_columns_inactive}
                             onClick={() => setChartType(CHART_TYPE.GROUPED_BAR_CHART)}
                        />
                        <img className='chart-icons' alt='bar-chart' onScroll={e => e.stopPropagation()}
                             src={(props.chartType === CHART_TYPE.STACKED_BAR_CHART) ? slider_rows_active : slider_rows_inactive}
                             onClick={() => setChartType(CHART_TYPE.STACKED_BAR_CHART)}
                        />
                    </div>
                    <div style={{margin: "10px auto", height: "300px", overflow: "auto"}}>
                        {answerCardToShow}
                    </div>
                    {skippedAnswerCount > 0 ?
                        <p className='skipped-count-text'>{t(translationKeys.skipped) + ':' + skippedAnswerCount}</p> : null}
                </div> : null}
            {React.Children.toArray(props.summaryDataSet.responsesInfo.map((response, index) => {
                return <div onClick={() => {
                    dispatch({
                        type: RESPONSES_ACTION_TYPE.SET_INDIVIDUAL_RESPONSE_NUMBER,
                        payload: props.summaryDataSet.responsesInfo.length - index
                    })
                    dispatch({type: RESPONSES_ACTION_TYPE.SET_SHOW_INDIVIDUAL, payload: true})
                }} style={{
                    display: "flex",
                    margin: "10px auto",
                    background: "white",
                    padding: "15px 0",
                    borderRadius: "5px",
                    width: "96%",
                    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)"
                }}>
                    <span style={{
                        width: "50px",
                        height: "50px",
                        color: "white",
                        display: "flex",
                        margin: "0 5px",
                        borderRadius: "100%",
                        alignItems: "center",
                        background: "#004ba0",
                        justifyContent: "center"
                    }}>{props.summaryDataSet.responsesInfo.length - index}</span>
                    <div style={{display: "grid", margin: "0 5px", width: "90%"}}>
                        <span style={{
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap"
                        }}>{currentQuestionIndex === -1 ? originalFormRespondent[originalFormRespondent.length - 1 - index].responder_email_id : responseText(index)}</span>
                        <span style={{fontSize: "12px"}}>{getDateWithTime(response.respondedTime)}</span>
                    </div>
                </div>
            }))}
        </div>
    )
}

export default MobileQuestionScreen
