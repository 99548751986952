import React, {useState} from 'react'
import down from '../../../../public/images/down_gray.svg'
import up from '../../../../public/images/up_gray.svg'
import SettingsControlResponsesContainer from "./SettingsControlResponsesContainer";
import SettingsControlQuestionContainer from "./SettingsControlQuestionsContainer";
import {translationKeys} from "../../../localizations/translationKeys-localization";
import SettingsFormControlGeneralContainer from "./SettingsFormControlGeneralContainer";
import {useSelector} from "react-redux";
import {t} from "i18next";
import {isMobileOnly} from "react-device-detect";
import SettingsQuestionsContainer from "./SettingsQuestionsContainer";
import SettingsResponsesContainer from "./SettingsResponsesContainer";
import SettingScheduleForqStatus from "./SettingsScheduleForqStatus";
import SettingMobileScheduleForqStatus from "./SettingsMobileScheduleForqStatus";

const CLICK_TYPE = {
    GENERAL: 'general',
    RESPONSES: 'responses',
    QUESTIONS: 'questions',
    SCHEDULE_FORM_STATUS: 'schedule_form_status'
}

const SettingsControlContainer = (props) => {
    const formId = useSelector(state => state.appReducer.formData?._id)
    const [showGeneral, setShowGeneral] = useState(formId ? !formId : !props.formId)
    const [showResponses, setShowResponses] = useState(formId ? !formId : !props.formId)
    const [showQuestions, setShowQuestions] = useState(formId ? !formId : !props.formId)
    const [showScheduleFormStatus, setShowScheduleFormStatus] = useState(formId ? !formId : !props.formId)

    const handleClick = (type) => {
        if (type === CLICK_TYPE.QUESTIONS) {
            setShowQuestions(!showQuestions)
        } else if (type === CLICK_TYPE.RESPONSES) {
            setShowResponses(!showResponses)
        } else if (type === CLICK_TYPE.GENERAL) {
            setShowGeneral(!showGeneral)
        } else if (type === CLICK_TYPE.SCHEDULE_FORM_STATUS) {
            setShowScheduleFormStatus(!showScheduleFormStatus)
        }
    }

    return (
        <>
            <div id={'settings-mobile-quiz-control'} className={showGeneral ? null : "hover-controls"}
                 style={{padding: '0 20px'}}
                 onClick={() => {
                     if (!showGeneral) {
                         handleClick(CLICK_TYPE.GENERAL)
                     }
                 }}>
                <div id={'settings-general'} className={'row-title'}
                     style={{display: 'flex', justifyContent: 'space-between'}}>
                    <h3 style={{
                        color: '#004BA0',
                        fontSize: '17px',
                        margin: '15px 15px 10px 0px'
                    }}>{t(translationKeys.general)}</h3>
                    <img alt={'dropdown'} src={showGeneral ? up : down} style={{cursor: "pointer"}}
                         onClick={() => handleClick(CLICK_TYPE.GENERAL)}/>
                </div>
                {!showGeneral ? <div className={'row-description'}>
                        <span id={'settings-user-info-duration'}
                              style={{fontSize: '14px'}}>{t(translationKeys.user_info)}</span>
                    </div> :
                    <SettingsFormControlGeneralContainer {...props}/>
                }
            </div>
            <div id={'settings-control-container'} className={showResponses ? null : "hover-controls"}
                 style={{padding: '0 20px'}}
                 onClick={() => {
                     if (!showResponses) {
                         handleClick(CLICK_TYPE.RESPONSES)
                     }
                 }}>
                <div id={'control-container-responses'} className={'row-title'}
                     style={{display: 'flex', justifyContent: 'space-between'}}>
                    <h3 style={{
                        color: '#004BA0',
                        fontSize: '17px',
                        margin: '15px 15px 10px 0px'
                    }}>{t(translationKeys.responses)}</h3>
                    <img alt={'dropdown'} src={showResponses ? up : down} style={{cursor: "pointer"}}
                         onClick={() => handleClick(CLICK_TYPE.RESPONSES)}/>
                </div>
                {!showResponses ? <div id={'control-container-multiple-responses'} className={'row-description'}>
                    <span style={{fontSize: '14px'}}>{t(translationKeys.multiple_responses_summary)}</span>
                </div> : <>
                    {isMobileOnly ? <SettingsControlResponsesContainer {...props}/> :
                        <SettingsResponsesContainer key='SettingsResponsesContainer' {...props}/>}
                </>
                }
            </div>
            <div className={showQuestions ? null : "hover-controls"}
                 style={{padding: '0 20px'}}
                 onClick={() => {
                     if (!showQuestions) {
                         handleClick(CLICK_TYPE.QUESTIONS)
                     }
                 }}>
                <div id={'settings-control-questions'} className={'row-title'}
                     style={{display: 'flex', justifyContent: 'space-between'}}>
                    <h3 style={{
                        color: '#004BA0',
                        fontSize: '17px',
                        margin: '15px 15px 10px 0px'
                    }}>{t(translationKeys.questions)}</h3>
                    <img alt={'dropdown'} src={showQuestions ? up : down} style={{cursor: "pointer"}}
                         onClick={() => handleClick(CLICK_TYPE.QUESTIONS)}/>
                </div>
                {!showQuestions ? <div className={'row-description'}>
                    <span style={{fontSize: '14px'}}>{t(translationKeys.question_number_shuffle)}</span>
                </div> : <>
                    {isMobileOnly ? <SettingsControlQuestionContainer {...props}/> :
                        <SettingsQuestionsContainer key='SettingsQuestionContainer' {...props}/>}
                </>
                }
            </div>
            <div className={showScheduleFormStatus ? null : "hover-controls"}
                 style={{padding: '0 20px'}}
                 onClick={() => {
                     if (!showScheduleFormStatus) {
                         handleClick(CLICK_TYPE.SCHEDULE_FORM_STATUS)
                     }
                 }}>
                <div id={'settings-control-questions'} className={'row-title'}
                     style={{display: 'flex', justifyContent: 'space-between'}}>
                    <h3 style={{
                        color: '#004BA0',
                        fontSize: '17px',
                        margin: '15px 15px 10px 0px'
                    }}>{t(translationKeys.schedule_form_status)}</h3>
                    <img alt={'dropdown'} src={showScheduleFormStatus ? up : down} style={{cursor: "pointer"}}
                         onClick={() => handleClick(CLICK_TYPE.SCHEDULE_FORM_STATUS)}/>
                </div>
                {!showScheduleFormStatus ? <div className={'row-description'}>
                    <span
                        style={{fontSize: '14px'}}>{t(translationKeys.opening_date)}, {t(translationKeys.closing_date)}</span>
                </div> : <>
                    {isMobileOnly ? <SettingMobileScheduleForqStatus {...props}/> :
                        <SettingScheduleForqStatus {...props}/>}
                </>
                }
            </div>
        </>
    )
}

export default SettingsControlContainer