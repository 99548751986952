import React from "react"
import useFormImportManager from "../../../app/hooks/useFormImportManager"
import arrowBackBlack from "../../../../public/images/arrow_back_black.svg"
import {isMobileOnly} from "react-device-detect";
import {QUESTION_TYPE} from "../../utils/constants";

const CurrentFormHeader = () => {
    const {
        backText,
        checkBoxBlack,
        checkBoxBlue,
        currentForm,
        handleSelectAllClick,
        selectAllText2,
        showFormsList
    } = useFormImportManager()

    return (
        <div style={{display: 'flex', justifyContent: isMobileOnly ? 'end' : 'space-between', marginRight: isMobileOnly ? '10px' : '25px'}}>
            {!isMobileOnly ?
                <div style={{display: 'flex', alignItems: 'center'}}>
                <img src={arrowBackBlack} alt={backText} style={{cursor: 'pointer'}} onClick={() => {
                    showFormsList(true)
                }}
                />
                &nbsp;
                {currentForm?.welcome_screen?.title}
            </div> : null}
            <div style={{display: 'flex', alignItems: 'center', color: "#1976D2", cursor: 'pointer'}} onClick={() => {
                handleSelectAllClick()
            }}>
                {selectAllText2}
                &nbsp;&nbsp;
                {
                    (() => {
                        const selectedQuestionsCount = currentForm?.pages[0].questions
                            .filter((question) => question.selected)
                            .reduce((count) => count + 1, 0)
                        let selectAllImage

                        if (selectedQuestionsCount === currentForm?.pages[0].questions.filter(q => q.type !== QUESTION_TYPE.SECTION.key).length) {
                            selectAllImage = checkBoxBlue
                        } else {
                            selectAllImage = checkBoxBlack
                        }

                        return (
                            <img src={selectAllImage} alt={selectAllText2}/>
                        )
                    })()
                }
            </div>
        </div>
    )
}

export default CurrentFormHeader
