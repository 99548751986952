import {getQuestionCategory, QUESTION_CATEGORY, QUESTION_TYPE} from "../../shared/utils/constants";

export function getAnswerSheetDataFromQuizData(quizAnswerData, questionsData) {
    const updateCorrectAnswer = (currentQuestionData, questionSummary, isQuizV2) => {
        if (getQuestionCategory(currentQuestionData.type) === QUESTION_CATEGORY.CHOICE_TYPE) {
            for (let a = 0; a < currentQuestionData.choices.length; a++) {
                if (currentQuestionData.choices[a].is_answer) {
                    !isQuizV2 ? questionSummary.correct_answer_id = currentQuestionData.choices[a]._id : null;
                    questionSummary.correct_answer = currentQuestionData.choices[a].label;
                    break;
                }
            }
        } else {
            questionSummary.correct_answer = currentQuestionData.correct_answer;
        }
        return questionSummary
    }
    if (quizAnswerData && questionsData) {
        let questionWiseData = [];
        for (let i = 0; i < questionsData.length; i++) {
            let currentQuestionData = questionsData[i];
            if (currentQuestionData.type !== QUESTION_TYPE.SECTION.key) {
                let isSkipped = true;
                let questionSummary = {
                    questionId: currentQuestionData._id,
                    type: currentQuestionData.type,
                    title: currentQuestionData.title,
                    marks: currentQuestionData.marks,
                    description: currentQuestionData.description,
                    attachment: currentQuestionData.attachment
                }
                for (let j = 0; j < quizAnswerData.responses.length; j++) {
                    if (quizAnswerData.responses[j].question_id === currentQuestionData._id) {
                        isSkipped = false;
                        if (quizAnswerData.responses[j].marks !== undefined && quizAnswerData.responses[j].marks !== null) {
                            questionSummary.evaluated_marks = quizAnswerData.responses[j].marks
                        }
                        questionSummary = updateCorrectAnswer(currentQuestionData, questionSummary, quizAnswerData.is_quizzory_v2);

                        if (getQuestionCategory(currentQuestionData.type) === QUESTION_CATEGORY.CHOICE_TYPE) {
                            let answerText = '';
                            for (let k = 0; k < currentQuestionData.choices.length; k++) {
                                if (Array.isArray(quizAnswerData.responses[j].choices)) {
                                    for (let l = 0; l < quizAnswerData.responses[j].choices.length; l++) {
                                        if (currentQuestionData.choices[k]._id === quizAnswerData.responses[j].choices[l]) {
                                            answerText = answerText.length ? answerText + ', * ' + currentQuestionData.choices[k].label : '* ' + currentQuestionData.choices[k].label;
                                            questionSummary.given_answer = answerText;
                                        }
                                    }
                                    !quizAnswerData.is_quizzory_v2 ? questionSummary.given_answer_id = currentQuestionData.choices[k]._id : null;
                                } else if (currentQuestionData.choices[k]._id === quizAnswerData.responses[j].choice) {
                                    !quizAnswerData.is_quizzory_v2 ? questionSummary.given_answer_id = currentQuestionData.choices[k]._id : null;
                                    questionSummary.given_answer = currentQuestionData.choices[k].label;
                                }
                            }
                            if ((currentQuestionData.type === QUESTION_TYPE.CHECKBOX_CHOICE.key) ||
                                (currentQuestionData.type === QUESTION_TYPE.MULTIPLE_CHOICE.key)) {
                                if (quizAnswerData.responses[j].others) {
                                    if (questionSummary.given_answer && questionSummary.given_answer !== '') {
                                        questionSummary.given_answer = questionSummary.given_answer + ', ' + quizAnswerData.responses[j].others + ' (Others)'
                                    } else questionSummary.given_answer = quizAnswerData.responses[j].others + ' (Others)'
                                }
                            }
                        } else if (currentQuestionData.type === QUESTION_TYPE.WEB_LINK.key) {
                            questionSummary.given_answer = quizAnswerData.responses[j].web_url;
                        } else if (currentQuestionData.type === QUESTION_TYPE.TIME.key) {
                            if (quizAnswerData.responses[j].text !== '') {
                                let splitTimeArray = quizAnswerData.responses[j].text.split(':')
                                let hour = parseInt(splitTimeArray[0])
                                const AmOrPm = hour >= 12 ? 'PM' : 'AM';
                                hour = (hour % 12) || 12;
                                questionSummary.given_answer = hour + ':' + splitTimeArray[1] + ' ' + AmOrPm;
                            }
                        } else if (getQuestionCategory(currentQuestionData.type) === QUESTION_CATEGORY.GRID_TYPE) {
                            if (currentQuestionData.type === QUESTION_TYPE.CHECKBOX_GRID.key) {
                                questionSummary.given_answer = quizAnswerData.responses[j].checkbox_grid_choices;
                            } else if (currentQuestionData.type === QUESTION_TYPE.MULTIPLE_CHOICE_GRID.key) {
                                questionSummary.given_answer = quizAnswerData.responses[j].grid_choices;
                            }
                        } else {
                            questionSummary.given_answer = quizAnswerData.responses[j].text;
                        }
                        break;
                    }
                }
                if (isSkipped) {
                    questionSummary.evaluated_marks = currentQuestionData.type === QUESTION_TYPE.LONG_TEXT.key ? undefined : 0
                    questionSummary = updateCorrectAnswer(currentQuestionData, questionSummary, quizAnswerData.is_quizzory_v2)
                    questionSummary.given_answer = '';
                }
                questionWiseData.push(questionSummary);
            }
        }
        return {
            quizResults: quizAnswerData.quiz_data && quizAnswerData.quiz_data.results ?
                quizAnswerData.quiz_data.results
                : undefined,
            individualResponderQuestionWiseData: questionWiseData
        }
    }
}
