import axios from "axios";
import {REQUEST_METHOD} from "../../shared/utils/helper"

export function getThankPageArticles(successCallBackFunction) {
    const requestConfiguration = {
        method: REQUEST_METHOD.GET,
        url: "https://surveyheartmedia.s3.ap-south-1.amazonaws.com/public/articles/general-articles.json",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
    };
    axios(requestConfiguration)
        .then(
            function (serverResponse) {
                successCallBackFunction(serverResponse.data, serverResponse.status)
            }
        )
        .catch(function (err) {
        })
}


