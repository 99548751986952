import React from "react"
import useQuestionTypeMgr from "../../app/hooks/useQuestionTypeMgr"
import {useDispatch, useSelector} from "react-redux"
import {IMPORT_QUESTIONS_ACTION_TYPE} from "../../app/reducers/importQuestionsReducer"
import useQuestionMgr from "../../app/hooks/useQuestionMgr";
import {isMobileOnly} from "react-device-detect";
import {BUILDER_ACTION_TYPE} from "../../app/reducers/builderReducer";
import {translationKeys} from "../../localizations/translationKeys-localization";
import {useTranslation} from "react-i18next";

const QuestionTypePopup = (props) => {
    const {t} = useTranslation()
    const {
        blueNewImage,
        getQuestionGroups,
        importImage,
        newText,
        newImage
    } = useQuestionTypeMgr()
    const {addQuestion} = useQuestionMgr()

    const isQuiz = useSelector(state => state.appReducer.isQuiz)
    const selectedLanguage = useSelector(state => state.languageReducer.selectedLanguage)
    const dispatch = useDispatch()

    const handleQTClick = type => {
        if (isMobileOnly) {
            dispatch({type: BUILDER_ACTION_TYPE.SET_NEW_QUESTION, payload: true})
        }
        if (props.setShowMobileQTPopUpContainer) {
            props.setShowMobileQTPopUpContainer(false)
        }
        addQuestion(props.questionIndex, type)
    }

    const handleImportQuestionsClick = () => {
        if (isMobileOnly) {
            dispatch({type: IMPORT_QUESTIONS_ACTION_TYPE.SET_SHOW_MOBILE_IMPORT_QUESTIONS_SCREEN, payload: true})
        } else {
            dispatch({type: IMPORT_QUESTIONS_ACTION_TYPE.SET_SHOW_IMPORT_QUESTIONS_DIALOG, payload: true})
        }
    }

    return (
        <div className="question-type-popup" id="questionTypePopup">
            <div style={{
                height: '100%',
                overflow: 'unset',
                alignItems: 'center'
            }}>
                {
                    (() => {
                            const questionGroups = getQuestionGroups(isQuiz)

                            return (
                                questionGroups.map((qg, outerIndex) => {
                                    return (
                                        <div id={`question-group-container-${outerIndex}`}
                                             style={{
                                                 display: 'flex',
                                                 flexFlow: 'column',
                                                 width: isMobileOnly ? '100%' : isQuiz ? '300px' : selectedLanguage === "ta" ? '430px' : selectedLanguage === "pt" ? "470px" : '400px'
                                             }}
                                             key={`question-group-container-${outerIndex}`}>
                                            <div key={`question-group-${outerIndex}`} style={{width: "inherit", gridTemplateColumns: isQuiz ? "auto" : null}}
                                                 className='question-type-grid-container'>
                                                {
                                                    qg.questionTypes.map((qt, innerIndex) => {
                                                        return (
                                                            <div key={innerIndex} className='question-type-grid-item'
                                                                 style={{whiteSpace: selectedLanguage === "pt" || (selectedLanguage === "ta" && isMobileOnly) ? "initial" : null}}
                                                                 onClick={() => handleQTClick(qt.type)}>
                                                                <img src={qt.image} alt={qt.title}/>
                                                                {qt.title}
                                                                {
                                                                    (() => {
                                                                        if (qt.new) {
                                                                            return (
                                                                                <span className='menu-btn-new-icon' style={{
                                                                                    textTransform: 'uppercase',
                                                                                    marginLeft: '10px'
                                                                                }}>{t(translationKeys.new)}</span>
                                                                            )
                                                                        }
                                                                    })()
                                                                }
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                            {
                                                (() => {
                                                    if (outerIndex === questionGroups.length - 1) {
                                                        return <div
                                                            key={'import-questions-button'}
                                                            style={{
                                                                display: 'flex',
                                                                justifyContent: 'center',
                                                                backgroundColor: '#004BA0',
                                                                color: 'white',
                                                                width: isMobileOnly ? '100%' : isQuiz ? '300px' : '400px',
                                                                height: '42px',
                                                                alignItems: 'center',
                                                                borderRadius: '8px',
                                                                marginTop: '15px',
                                                                cursor: 'pointer'
                                                            }}
                                                            onClick={handleImportQuestionsClick}
                                                        >
                                                            <img src={importImage} alt='Import Questions'
                                                                 style={{marginRight: '10px'}}
                                                            />
                                                            {t(translationKeys.import_questions)}
                                                            <div style={{
                                                                marginLeft: '2px',
                                                                marginTop: '7px',
                                                                height: 'inherit'
                                                            }}>
                                                                <span className='menu-btn-new-icon' style={{
                                                                    textTransform: 'uppercase',
                                                                    background: "#FFFFFF",
                                                                    color: "#1976d2"
                                                                }}>{t(translationKeys.new)}</span>
                                                            </div>
                                                        </div>
                                                    }

                                                    return <div
                                                        key={`import-questions-border-${outerIndex}`}
                                                        style={{
                                                            borderBottom: "2px solid #eeeeee",
                                                            width: '100%',
                                                            marginTop: '15px',
                                                            marginBottom: '15px'
                                                        }}/>
                                                })()
                                            }
                                        </div>
                                    )
                                })
                            )
                        }
                    )()
                }
            </div>
        </div>
    )
}

export default QuestionTypePopup