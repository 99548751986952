import React from 'react'
import style from "../css/form-page.module.css";
import close from "../../../public/images/close_white.svg";
import warningIcon from "../../../public/images/icons/warning_icon.svg";
import MaterialButton, {ButtonTypes} from "../../shared/components/MaterialButton";
import {useDispatch, useSelector} from "react-redux";
import {FORM_DATA_ACTION_TYPE} from "../reducers/formDataReducer";
import {isImageTheme} from "../utils/theme-color-helper";
import {isMobileOnly} from "react-device-detect";

const AbuseWarningComponent = () => {
    const formData = useSelector(state => state.formDataReducer.formData)
    const themeColor = useSelector(state => state.themeReducer.themeColor)
    const dispatch = useDispatch()

    function openReportAbuseForm() {
        const rootPath = window.location.origin
        if (formData?.is_quiz) {
            const id = formData?._id
            window.open('https://surveyheart.com/form/5f323faf7693da10a21c84c6' + "?reportid=" + id)
        } else {
            const id = formData?._id
            window.open(rootPath + "/form/5c6cdd029f25685f413430ef" + "?reportid=" + id)
        }
    }

    const handleCloseClick = () => {
        dispatch({type: FORM_DATA_ACTION_TYPE.SET_SHOW_ABUSE_WARNING, payload: false})
    }

    return (
        <div className={style.abuse_warning}>
            <div style={{display: "flex", width: isMobileOnly ? "100%" : "65%", justifyContent: isMobileOnly ? "space-around" : "end", margin: "auto", textAlign: "center"}}>
                <span className={style.warning_message}><img src={warningIcon} alt={"warning"}/>Please be sure that you trust the creator of this {formData.is_quiz ? "quiz" : "form"} before submitting it.</span>
                {isMobileOnly ? <img src={close} alt={"close"} style={{width: "25px", display: "flex", height: "fit-content", margin: "10px 5px"}} onClick={handleCloseClick}/> : null}
            </div>
            <div style={{display: "flex", width: isMobileOnly ? "100%" : "20%", alignItems: "center", justifyContent: isMobileOnly ? "center" : "space-around"}}>
                <MaterialButton handleClick={openReportAbuseForm}
                                data={{
                                    title: `Report ${formData.is_quiz ? "Quiz" : "Form"}`,
                                    buttonType: ButtonTypes.CONTAINED,
                                    bg_color: isImageTheme(formData?.theme) ? themeColor?.secondaryColor : '#212121',
                                    customButtonContainerStyle: {
                                        height: "40px",
                                        margin: "12px"
                                    }
                                }}/>
                {!isMobileOnly ? <img src={close} alt={"close"} style={{width: "30px", cursor: "pointer"}} onClick={handleCloseClick}/> : null}
            </div>
        </div>
    )
}

export default AbuseWarningComponent