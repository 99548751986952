import React, {useEffect, useRef, useState} from "react";
import SummaryTableRow from "./SummaryTableRow";
import MobileSummaryTableRow from "./MobileSummaryTableRow";
import {isMobileOnly} from "react-device-detect";
import up_white from '../../../../public/images/up_white.png'
import {useTranslation} from "react-i18next";
import {translationKeys} from "../../../localizations/translationKeys-localization";

let responsesWidth;
const sortOrder = {
    responseText: 0,
    submittedTime: 1
};

const TextResponsesTable = (props) => {
    const {t} =  useTranslation()
    const [lastSortedBy, setLastSortedBy] = useState(sortOrder.submittedTime)
    const [sortedDescendingOrder, setSortedDescendingOrder] = useState(true)
    const [ResponsesWidth, setResponsesWidth] = useState()
    const Header = useRef()

    useEffect(() => {
        responsesWidth = Header.current.offsetWidth - (isMobileOnly ? 237 : 287) + 'px';
        !ResponsesWidth ? setResponsesWidth(responsesWidth) : null
    })

        /* slice(0) used to duplicate the original array in order to sort the duplicated array
        instead of sorting the original */
        let textResponses = props.responses.slice(0);
        let respondersInfo = props.respondersInfo;
        let textResponsesWithTimeArray = textResponses.map((response, i) => {
            return [response, respondersInfo[i].respondedTime];
        })
        // sorting responses
        if (lastSortedBy === sortOrder.submittedTime) {
            const descPropComparer = (propIndex) => (a, b) => a[propIndex] === b[propIndex] ? 0 : a[propIndex] < b[propIndex] ? 1 : -1;
            const ascPropComparer = (propIndex) => (a, b) => a[propIndex] === b[propIndex] ? 0 : a[propIndex] > b[propIndex] ? 1 : -1;
            sortedDescendingOrder ? textResponsesWithTimeArray.sort(descPropComparer(1)) : textResponsesWithTimeArray.sort(ascPropComparer(1))
        } else if (lastSortedBy === sortOrder.responseText) {
            const titleAscComparer = (index) => (a, b) => ('' + a[index]).trim().localeCompare(b[index].trim());
            const titleDescComparer = (index) => (a, b) => ('' + b[index]).trim().localeCompare(a[index].trim());
            sortedDescendingOrder ? textResponsesWithTimeArray.sort(titleDescComparer(0)) : textResponsesWithTimeArray.sort(titleAscComparer(0))
        }

        // preparing table rows
        const tableRows = textResponsesWithTimeArray.map((response, i, textResponses) =>
            isMobileOnly ?
                <MobileSummaryTableRow key={i} textResponse={response[0]} index={i}
                                       textResponseWidth={responsesWidth}
                                       sortOrder={sortedDescendingOrder}
                                       responsesCount={textResponses.length}
                                       responseTime={response[1]}/>
                :
                <SummaryTableRow key={i} textResponse={response[0]} index={i}
                                 textResponseWidth={ResponsesWidth}
                                 sortOrder={sortedDescendingOrder}
                                 responsesCount={textResponses.length}
                                 responseTime={response[1]}/>
        );

        return (
            <table id='text-detailed-table' className='text-detailed-table'>
                {/*table header*/}
                <thead className='table-header' ref={Header}>
                <tr className='table-row'>
                    <th id='table-header-cell-sno' className='table-header-cell' style={{width: isMobileOnly ? '50px' : '70px'}}>{t(translationKeys.serial_number)}</th>
                    <th className='table-header-cell' style={{width: isMobileOnly ? '180px' : '210px'}}
                        onClick={() => {
                            if (lastSortedBy === sortOrder.submittedTime) {
                                if (sortedDescendingOrder) {
                                    setSortedDescendingOrder(false)
                                } else {
                                    setSortedDescendingOrder(true)
                                }
                            } else {
                                setLastSortedBy(sortOrder.submittedTime)
                                setSortedDescendingOrder(true)
                            }
                        }}>
                        <span id='text-responses-submit-time'>{t(translationKeys.submitted_time)} </span>
                        <div className='sorting-icon-container'>
                            {lastSortedBy === sortOrder.submittedTime ?
                                <img className={sortedDescendingOrder ? 'rotate-to-0-deg'
                                    : 'rotate-to-180-deg'}
                                     alt='sorting-option'
                                     src={up_white}/> : null
                            }
                        </div>
                    </th>
                    <th className='table-header-cell'
                        style={{
                            width: isMobileOnly ? '200px' : ResponsesWidth ? ResponsesWidth : 'auto',
                            justifyContent: 'flex-start'
                        }}
                        onClick={() => {
                            if (lastSortedBy === sortOrder.responseText) {
                                if (sortedDescendingOrder) {
                                    setSortedDescendingOrder(false)
                                } else {
                                    setSortedDescendingOrder(true)
                                }
                            } else {
                                setLastSortedBy(sortOrder.responseText)
                                setSortedDescendingOrder(true)
                            }
                        }}>
                        <span id='text-responses' style={{marginLeft: '10px'}}>{t(translationKeys.responses)}</span>
                        <div className='sorting-icon-container'>
                            {lastSortedBy === sortOrder.responseText ?
                                <img className={sortedDescendingOrder ? 'rotate-to-0-deg'
                                    : 'rotate-to-180-deg'}
                                     alt='sorting-option'
                                     src={up_white}/> : null}
                        </div>
                    </th>
                </tr>
                </thead>

                {/*table content*/}
                <tbody id='text-responses-table-body' className='table-body'>
                {tableRows}
                </tbody>
            </table>
        );
}

export default TextResponsesTable
