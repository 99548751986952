// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2scjUQE6q8ZGQIPNFZ8J31 {\n    margin: 0;\n    font-size: 20px;\n}\n\n._2kRocsUMniYpa0ahhCKf8i {\n    font-size: 14px;\n}\n\n._3OqOaUpcrp_ty3_jwNnTz- {\n    overflow-x: hidden;\n    padding: 10px 24px 10px 24px;\n    background-color: #1976d2;\n    margin: 0;\n}\n\n._2RpQtwNwtRnrwWb6tRhZvA {\n    padding: 12px 24px 24px 24px;\n}\n\n._3p6UuR5HglZs4S5xXWepGh {\n    display: inline-block;\n    font-size: 12px;\n    font-weight: 500;\n    padding: 4px 12px;\n    border-radius: 8px;\n    margin: 5px 0 0;\n}\n@media only screen and (max-width: 600px) {\n    ._2RpQtwNwtRnrwWb6tRhZvA {\n        padding: 12px 12px 24px 12px;\n    }\n}\n", ""]);
// Exports
exports.locals = {
	"card_title": "_2scjUQE6q8ZGQIPNFZ8J31",
	"card_description": "_2kRocsUMniYpa0ahhCKf8i",
	"card_content": "_3OqOaUpcrp_ty3_jwNnTz-",
	"card_action": "_2RpQtwNwtRnrwWb6tRhZvA",
	"chip": "_3p6UuR5HglZs4S5xXWepGh"
};
module.exports = exports;
