import React, {useRef} from "react";
import {createGroupedBarChart, getColumnsDataSets} from '../utils/graph'
import '../css/graph-legend.css'

const GroupedBarChartContainer = (props) => {
    const refCanvas = useRef(null)

    const callChartLib = (canvasElement) => {
        if (canvasElement) {
            const [dataSets, maxBarChartValue] = getColumnsDataSets(props.graphData)
            const labels = props.graphData.responseAnalyzeData.rowGraphLabels
            if (dataSets && maxBarChartValue) {
                createGroupedBarChart(dataSets, labels, maxBarChartValue, canvasElement, refCanvas.current);
            } else {
                createGroupedBarChart([], labels, maxBarChartValue, canvasElement, refCanvas.current);
            }
        }
    }

    return (
        <div className='chart-holder' style={{height: '100%'}}>
            <div style={{minHeight: '100%'}} className='bar-chart-scroll' onScroll={e => e.preventDefault()}
                 ref={e => {
                     callChartLib(e)
                 }}>
                <canvas style={{width: '100%', height: '100%'}} ref={refCanvas}/>
            </div>
        </div>
    );
}

export default GroupedBarChartContainer

const LegendLabel = (props) => {
    return (
        <div className='individual-label-container'>
            <div className='legend-box' style={{backgroundColor: props.legendColor}}/>
            <span className='label-text'>{props.labelText}</span>
        </div>

    )
}