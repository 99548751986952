import React, {useRef} from 'react'
import SummaryHeader from "./SummaryHeader";
import SummarizedQuestionContainer from "./SummarizedQuestionContainer";
import FormStatus from "./FormStatus";
import SummaryDataSet from "./SummaryDataSetContext";
import {isMobileOnly} from "react-device-detect";
import QuizSummaryContainer from "./QuizSummaryContainer";
import QuizMobileSummaryContainer from "./QuizMobileSummaryContainer";
import {sortRespondents} from "../utils/response-summary-sort";
import StickyTopNavBar from "./StickyTopNavBar";
import {useTranslation} from "react-i18next";
import {translationKeys} from "../../../localizations/translationKeys-localization";
import LimitReachedAlert from "./LimitReachedAlert";
import MobileResponsesScreen from "./MobileResponsesScreen";
import LimitReachedUI from "./LimitReachedUI";

const SummaryContainer = (props) => {
    const {t} = useTranslation()
    const formData = props.formResponses ? props.formResponses?.formData : undefined;
    const responsesCount = props.formResponses && props.formResponses?.formResponses && props.formResponses.formResponses.respondents ? props.formResponses.formResponses.respondents.length : 0;
    let initialRespondentsData = sortRespondents(props.formResponses?.formResponses.respondents, props.lastSortedOrder)
    const respondentsDataRef = useRef(initialRespondentsData);
    // const statusTextElement = useRef();
    // const statusImageElement = useRef();
    // const isFormActiveRef = useRef(props.formResponses.formData.setting.is_active);
    /* const updateFormDataInFormResponses = (formId, isActive) => {
         isFormActiveRef.current = isActive;
         props.updateFormStatusInFormData(formId, isActive)
     }*/
    const leftStickyNavBarElements = () => {
        return (
            <>
                <span style={{fontSize: '18px'}}>
                    {responsesCount && responsesCount > 1 ? formData?.is_quiz ? t(translationKeys.answers) : t(translationKeys.responses) : formData?.is_quiz ? t(translationKeys.answer_s) : t(translationKeys.response)}
                </span>
                <div className='box'
                     style={{color: '#212121', borderColor: '#1976D2', fontSize: '16px'}}>
                    {responsesCount ? responsesCount : 0}
                </div>
            </>
        )
    }
    return <SummaryDataSet.Consumer>
        {contextState => {
            const summaryDataSet = contextState.summaryDataSet;
            if (!props.formResponses?.formResponses?.respondents?.length && !props.formFiller) {
                return <LimitReachedUI isQuiz={formData?.is_quiz}/>
            }
            return (
                <React.Fragment>
                    {!(formData?.is_quiz && isMobileOnly) && responsesCount ?
                        <SummaryHeader respondentsDataRef={respondentsDataRef}
                                       responsesCount={respondentsDataRef.current?.length}
                            // statusImageElement={statusImageElement}
                                       respondents={respondentsDataRef.current}
                                       lastSortedOrder={props.lastSortedOrder}
                                       formFiller={props.formFiller}
                                       setLastSortedOrder={props.setLastSortedOrder}
                            // statusTextElement={statusTextElement}
                                       formData={formData}
                            // isFormActiveRef={isFormActiveRef}
                        /> : null}
                    {isMobileOnly || !responsesCount || formData?.response_count === responsesCount || props.formFiller ? null :
                        <LimitReachedAlert isQuiz={formData.is_quiz}/>}
                    {formData?.is_quiz ? isMobileOnly ? <QuizMobileSummaryContainer formData={formData}
                                                                                    lastSortedOrder={props.lastSortedOrder}
                                                                                    setLastSortedOrder={props.setLastSortedOrder}
                                                                                    respondentsDataRef={respondentsDataRef}
                                                                                    changeResponseView={props.changeResponseView}/> :
                            <QuizSummaryContainer respondentsDataRef={respondentsDataRef}
                                                  lastSortedOrder={props.lastSortedOrder}
                                                  formData={formData}
                                                  changeResponseView={props.changeResponseView}/>
                        : formData?.response_count !== responsesCount && !props.formFiller ? <MobileResponsesScreen/> :
                            <SummarizedQuestionContainer formData={formData}
                                                         respondentsDataRef={respondentsDataRef}
                                                         formFiller={props.formFiller}
                                                         summaryDataSet={summaryDataSet}
                                                         changeResponseView={props.changeResponseView}/>}
                    {!isMobileOnly && !props.formFiller ?
                        <StickyTopNavBar bodyContainerElementRef={props.bodyContainerElementRef}
                                         leftStickyNavBarElements={leftStickyNavBarElements()}
                                         rightStickyNavBarElements={props.formFiller ? null : <FormStatus/>}
                                         stickyCenterTitle={formData ? formData.welcome_screen.title : ""}/> : null}
                </React.Fragment>
            )
        }}
    </SummaryDataSet.Consumer>
}

export default SummaryContainer