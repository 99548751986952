import React, {useEffect, useRef, useState} from 'react'
import {getDateWithTime} from "../../../shared/utils/get-date-with-time";
import MaterialCheckBox from "../../../shared/components/MaterialCheckBox";
import {getQuestionCategory, QUESTION_CATEGORY, QUESTION_TYPE} from "../../../shared/utils/constants";
import getChoiceAnswer from "../utils/get-choice-type-answer";
import {isMobileOnly} from "react-device-detect";
import getPopUpElementPositionStyles from "../../../shared/utils/get-popup-element-positions";
import {RESPONSE_VIEW} from "./ResponseContainer";
import {COLLABORATOR_ROLES} from "../../builder/enums/collaboratorsRoles";
import {translationKeys} from "../../../localizations/translationKeys-localization";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {getGridAnswer} from "../utils/get-grid-type-answer"
import {GRID_DETAILS_POPUP_ACTION_TYPE} from "../../reducers/gridDetailsPopupReducer"

const TabularViewTableRow = props => {
    const selectedLanguage = useSelector(state => state.languageReducer.selectedLanguage)
    const formData = useSelector(state => state.appReducer.formData)
    const {t} = useTranslation()
    const {
        summaryDataSet,
        isChecked,
        cellWidth,
        responseIndex,
        columnQuestionIndexArray,
        setSelectedTableRows,
        selectedTableRows,
    } = props;
    const checkBoxTableCellRef = useRef(null)
    const questionsData = summaryDataSet.questionsData;
    const responderInfo = summaryDataSet.responsesInfo[responseIndex];
    const dispatch = useDispatch()

    let answerCells = [];
    let answerText = ''
    let gridAnswerColAndRowIndexes = []
    const originalFormRespondent = useSelector(state => state.responsesReducer.originalFormRespondents)
    const respondent = useSelector(state => state.responsesReducer.formResponsesData?.formResponses.respondents)
    const questions = useSelector(state => state.responsesReducer.formResponsesData?.formData.pages[0].questions)


    const AnswerCell = props => {
        let currentQuestionData = questionsData[props.questionIndex];
        const positionRef = useRef({});
        const textElementRef = useRef(null);
        const [showFullAnswerPopUp, setShowFullAnswerPopUp] = useState(false);
        const Ref = useRef();
        const viewPaddingRight = 10

        if (getQuestionCategory(currentQuestionData.type) === QUESTION_CATEGORY.CHOICE_TYPE) {
            answerText = getChoiceAnswer(responseIndex, currentQuestionData)
        } else if (getQuestionCategory(currentQuestionData.type) === QUESTION_CATEGORY.GRID_TYPE) {
            let gridAnswer= getGridAnswer(responseIndex, currentQuestionData,originalFormRespondent,respondent,questions)

            answerText = gridAnswer.gridTextAnswer
            gridAnswerColAndRowIndexes = gridAnswer.gridAnswerColAndRowIDs
        } else {
            answerText = currentQuestionData.responseTextAnswers[responseIndex]
        }

        useEffect(() => {
            for (let iCtr = 0; iCtr < questionsData.length * props.parentProps.summaryDataSet.responsesInfo.length; iCtr++) {
                const viewDiv = document.querySelectorAll('#tabular-view-table-row-grid-view-container')[iCtr]
                if (viewDiv) {
                    positionViewButtonForGridQuestion(viewDiv)
                }
            }

            document.onmouseover = null
        }, [])

        const positionViewButtonForGridQuestion = (viewDiv) => {
            const parentDiv = viewDiv.parentNode
            const textCellContainerDiv = viewDiv.previousSibling

            if ((textCellContainerDiv.offsetWidth + 10) > parentDiv.offsetWidth) {
                // Set the maxWidth so that 'VIEW' is not covered by the Grid Answer Text
                textCellContainerDiv.style.maxWidth = `${parentDiv.offsetWidth - viewDiv.offsetWidth - 15}px`
            }
        }

        return (<td id='tabular-view-table-row' className='table-row-td answer-cell-td'
                    key={questionsData[props.questionIndex].questionId}
                    style={{
                        width: isMobileOnly ? (cellWidth + 20) : (cellWidth + 40),
                    }}
                    onMouseOver={e => {
                        props.parentProps.prevShownFullTextPopUpDataRef?.current ? props.parentProps.prevShownFullTextPopUpDataRef.current.setShowFullTextDataPopUp(false) : null
                        positionRef.current = getPopUpElementPositionStyles(400, 150, e);
                        props.parentProps.prevShownFullTextPopUpDataRef.current = {
                            setShowFullTextDataPopUp: setShowFullAnswerPopUp
                        }
                        setShowFullAnswerPopUp(true);
                        document.onmouseover = (e) => {
                            if (Ref.current && !Ref.current.contains(e.target)) {
                                setShowFullAnswerPopUp(false);
                                document.onmouseover = null;
                            }
                        }
                    }}>
            <div className='table-text-cell-container' ref={Ref}>
                <p id='table-text-cell-container-p' className='table-body-cell-span' ref={textElementRef}
                   style={{maxWidth: cellWidth, margin: 0}}
                   onClick={handleCellClick(currentQuestionData.type, answerText)}>
                    {answerText && answerText.length ? answerText : t(translationKeys.no_answer)}
                </p>

                {showFullAnswerPopUp && (textElementRef.current.scrollWidth > textElementRef.current.clientWidth) ?
                    <div className='show-full-text-data' style={{
                        maxHeight: '70px',
                        right: positionRef.current && positionRef.current.right ? positionRef.current.right : null,
                        left: positionRef.current && positionRef.current.left ? positionRef.current.left : null,
                    }}>
                        <span onClick={handleCellClick(currentQuestionData.type, answerText)}>{answerText}</span>
                    </div> : null}
            </div>
            {(() => {
                if (getQuestionCategory(currentQuestionData.type) === QUESTION_CATEGORY.GRID_TYPE) return (
                    <div
                        id='tabular-view-table-row-grid-view-container'
                        style={{
                            display: 'flex', flex: '1', justifyContent: 'end', paddingRight: `${viewPaddingRight}px`
                        }}>
                        <div
                            style={{
                                display: 'flex',
                                border: "1px solid",
                                borderRadius: "4px",
                                fontSize: "12px",
                                padding: "5px",
                                color: "#1976D2"
                            }}
                            onClick={(e) => handleViewClick(e, props.questionIndex)}
                        >
                            {t(translationKeys.view).toUpperCase()}
                        </div>
                    </div>)
            })()}
        </td>)
    }

    if (columnQuestionIndexArray && columnQuestionIndexArray.length > 0) {
        for (let i = 0; i < columnQuestionIndexArray.length; i++) {
            for (let j = 0; j < questionsData.length; j++) {
                if (questionsData[j].type !== 'SECTION') {
                    if (columnQuestionIndexArray[i] === j) {
                        answerCells.push(<AnswerCell key={j} questionIndex={j} parentProps={{...props}}/>)
                        break;
                    }
                }
            }
        }
    } else {
        for (let i = 0; i < questionsData.length; i++) {
            if (questionsData[i].type !== 'SECTION') {
                answerCells.push(<AnswerCell key={i} questionIndex={i} parentProps={{...props}}/>);
            }
        }
    }

    const handleViewClick = (e, questionIndex) => {
        dispatch({
            type: GRID_DETAILS_POPUP_ACTION_TYPE.SET_POPUP_INFO,
            payload: {
                answer_text: answerText,
                grid_answer_col_and_row_indexes: gridAnswerColAndRowIndexes,
                response_analyze_data: props.summaryDataSet.questionsData[questionIndex].responseAnalyzeData,
                s_no: props.responseIndex + 1,
                title: props.summaryDataSet.questionsData[questionIndex].questionTitle,
                type: questionsData[questionIndex].type
            }
        })
        dispatch({type: GRID_DETAILS_POPUP_ACTION_TYPE.SET_SHOW_POPUP, payload: true})
        e.stopPropagation()
    }
    const handleCellClick = (type, text) => (e) => {
        if (type === QUESTION_TYPE.FILE_UPLOAD.key && text && text.length) {
            open(text)
            e.stopPropagation()
        }
    }

    const handleRowClick = (e) => {
        e.stopPropagation();
        if (!checkBoxTableCellRef?.current?.contains(e.target)) {
            props.changeResponseView(RESPONSE_VIEW.INDIVIDUAL, (responseIndex + 1), true)
        }
    }

    const handleCheckBoxChangeEvent = isChecked => {
        if (isChecked) {
            let selectedRespondents = selectedTableRows;
            if (selectedRespondents.length === 0) {
                props.setShowDeleteContainer(true);
            }
            if (selectedRespondents.indexOf(responderInfo.responseId) === -1) {
                selectedRespondents.push(responderInfo.responseId);
                setSelectedTableRows(selectedRespondents);

                /*   const allResponseIds = summaryDataSet.responsesInfo.map(responderInfo => {
                                    return responderInfo.responseId
                                });*/
                let allResponsesLength
                if (props.filteredArrayRef?.current?.length > 0) {
                    if (props.searchedArrayRef?.current?.length > 0) {
                        allResponsesLength = props.searchedArrayRef.current.length
                    } else {
                        allResponsesLength = props.filteredArrayRef.current.length
                    }
                } else if (props.searchedArrayRef?.current?.length > 0) {
                    allResponsesLength = props.searchedArrayRef.current.length
                } else {
                    allResponsesLength = summaryDataSet.responsesInfo.length
                }
                if (selectedRespondents.length === allResponsesLength) {
                    if (!props.selectAllCheckBoxRef.current.checked) {
                        props.selectAllCheckBoxRef.current.checked = true
                    }
                }
                props.SelectedCountTextRef.current ? props.SelectedCountTextRef.current.innerText = selectedRespondents.length + ' - ' + (selectedRespondents.length && selectedRespondents.length > 1 ? (summaryDataSet.isQuiz ? t(translationKeys.answers) : t(translationKeys.responses)) : (summaryDataSet.isQuiz ? t(translationKeys.enter_answer) : t(translationKeys.responses))) + " " + t(translationKeys.selected) : null;
            }
        } else {
            let selectedRespondents = selectedTableRows;
            selectedRespondents.splice(selectedRespondents.indexOf(responderInfo.responseId), 1)
            setSelectedTableRows(selectedRespondents);
            if (selectedRespondents.length === 0) {
                props.setShowDeleteContainer(false);
            } else if (props.SelectedCountTextRef.current) {
                props.SelectedCountTextRef.current.innerText = selectedRespondents.length + ' - ' + (selectedRespondents.length && selectedRespondents.length > 1 ? (summaryDataSet.isQuiz ? t(translationKeys.answers) : t(translationKeys.responses)) : (summaryDataSet.isQuiz ? t(translationKeys.enter_answer) : t(translationKeys.responses))) + " " + t(translationKeys.selected);
            }
            if (props.selectAllCheckBoxRef.current.checked) {
                props.selectAllCheckBoxRef.current.checked = false
                props.HeaderCheckboxAry.map(element => {
                    if (element.page === props.currentPageNumber) {
                        element.value = false
                    }
                })
            }
        }
    };

    return (<tr id='tabular-view-table-row-response' className='table-row' onClick={handleRowClick}
                test-id={'table-row-' + (props.responseIndex + 1)}>
        {/*response selector*/}
        {props.role === COLLABORATOR_ROLES.VIEWER || props.role === COLLABORATOR_ROLES.EDITOR ? null : <td style={{
            minWidth: isMobileOnly ? '50px' : '70px',
            borderColor: 'transparent #acacac80 #acacac80 transparent',
            justifyContent: 'center',
            alignItems: 'center'
        }} ref={checkBoxTableCellRef} className='table-row-td non-printable-element'>
            <MaterialCheckBox isChecked={isChecked}
                              test_id={'row-check-box-' + (props.responseIndex + 1)}
                              customStyle={{checkedColor: '#757575', checkboxBorderColor: '#757575'}}
                              onChange={handleCheckBoxChangeEvent}/>
        </td>}
        {/*S.No*/}
        <td id='table-row-sno' className='table-row-td'
            style={{
                minWidth: isMobileOnly && selectedLanguage === 'ml' ? '72px' : isMobileOnly ? '65px' : '90px',
                borderColor: 'transparent #acacac80 #acacac80 transparent'
            }}>
            <span className='table-body-cell-span' style={{minWidth: '0'}}>{responseIndex + 1}</span>
        </td>
        {/*Response submitted Time*/}
        <td id='table-row-response-time' className='table-row-td' style={{
            justifyContent: 'flex-start', borderColor: 'transparent #acacac80 #acacac80 transparent'
        }} dir={(selectedLanguage === 'fa' || selectedLanguage === 'ar') ? "ltr" : null}>
                <span className='table-body-cell-span '
                      style={{
                          borderColor: '#acacac80',
                          minWidth: isMobileOnly && selectedLanguage === 'ta' ? '190px' : isMobileOnly ? '160px' : '180px'
                      }}>
                    {getDateWithTime(responderInfo.respondedTime)}</span>
        </td>
        {formData?.setting?.is_email ? <td className='table-row-td'
                                           style={{
                                               width: 280,
                                               justifyContent: 'flex-start',
                                               borderColor: 'transparent #acacac80 #acacac80 transparent'
                                           }}>
            <p style={{maxWidth: 280, margin: 0}}
               className='table-body-cell-span'>{respondent[responseIndex].responder_email_id ? respondent[responseIndex].responder_email_id : t(translationKeys.no_answer)}</p>
        </td> : null}
        {props.summaryDataSet.isQuiz ? <td className='table-row-td'
                                           style={{
                                               width: 280,
                                               justifyContent: 'flex-start',
                                               borderColor: 'transparent #acacac80 #acacac80 transparent'
                                           }}>
            <p style={{maxWidth: 280, margin: 0}}
               className='table-body-cell-span'>{responderInfo.userInfo && responderInfo.userInfo.user_info_1 ? responderInfo.userInfo.user_info_1 : t(translationKeys.no_answer)}</p>
        </td> : null}
        {!props.summaryDataSet.isQuiz && formData?.user_info_form?.user_info_1 ? <td className='table-row-td'
                                           style={{
                                               width: 280,
                                               justifyContent: 'flex-start',
                                               borderColor: 'transparent #acacac80 #acacac80 transparent'
                                           }}>
            <p style={{maxWidth: 280, margin: 0}}
               className='table-body-cell-span'>{responderInfo.userInfo && responderInfo.userInfo.user_info_1 ? responderInfo.userInfo.user_info_1 : t(translationKeys.no_answer)}</p>
        </td> : null}
        {props.quizUserInfo && props.quizUserInfo.user_info_2 ? <td className='table-row-td'
                                                                    style={{
                                                                        width: 280,
                                                                        color: '#4d4d4d',
                                                                        justifyContent: 'flex-start',
                                                                        borderColor: 'transparent #acacac80 #acacac80 transparent'
                                                                    }}>
            <p style={{maxWidth: 280, margin: 0}}
               className='table-body-cell-span'>{responderInfo.userInfo.user_info_2 && responderInfo.userInfo.user_info_2.length > 0 ? responderInfo.userInfo.user_info_2 : 'No Data'}</p>
        </td> : null}
        {props.quizUserInfo && props.quizUserInfo.user_info_3 ? <td className='table-row-td'
                                                                    style={{
                                                                        width: 280,
                                                                        color: '#4d4d4d',
                                                                        justifyContent: 'flex-start',
                                                                        borderColor: 'transparent #acacac80 #acacac80 transparent'
                                                                    }}><p style={{maxWidth: 280, margin: 0}}
                                                                          className='table-body-cell-span'>{responderInfo.userInfo.user_info_3 && responderInfo.userInfo.user_info_3.length > 0 ? responderInfo.userInfo.user_info_3 : 'No Data'}</p>
        </td> : null}
        {/*response answer cells*/}
        {answerCells}
    </tr>)
}

export default TabularViewTableRow
