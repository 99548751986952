import React from 'react';
import {MultipleChoiceQuestion} from "./MultipleChoiceQuestion";
import {QUESTION_TYPE} from "../../../shared/utils/constants";
import {CheckboxChoiceQuestion} from "./CheckboxChoiceQuestion";
import {StarRatingChoiceQuestion} from "./StarRatingChoiceQuestion";
import SearchDropDown from "../../../shared/components/SearchDropDown";
import {isDarkTheme} from "../../utils/theme-color-helper";
import {useSelector} from "react-redux";
import MultipleChoiceGrid from "./MultipleChoiceGrid";
import CheckBoxGrid from "./CheckBoxGrid";
import LinearScaleChoiceQuestion from "./LinearScaleChoiceQuestion";
import SmileyQuestion from "./SmileyQuestion"

export default function ChoiceQuestion(props) {
    const formData = useSelector(state => state.formDataReducer?.formData)

    function validateRequired(value) {
        if (props.questionData.is_required) {
            if (value.length === 0) {
                props.setInfoTag({
                    is_show: true,
                    text: "This question requires an answer",
                    textColor: 'rgb(255, 255, 255)',
                    backgroundColor: 'rgb(244, 67, 54)'
                });
            } else {
                props.setInfoTag({
                    is_show: true,
                    text: "Required",
                    textColor: isDarkTheme(formData.theme) ? '#fff' : '#212121',
                    backgroundColor: isDarkTheme(formData.theme) ? '#000' : '#e8e8e8'
                });
            }
        }
    }

    const getChoiceComponent = () => {
        switch (props.questionData.type) {
            case QUESTION_TYPE.MULTIPLE_CHOICE.key:
                return (
                    <MultipleChoiceQuestion
                        isPreview={props.isPreview || props.previewType}
                        questionData={props.questionData}
                        validateRequired={validateRequired}/>
                );
            case QUESTION_TYPE.MULTIPLE_CHOICE_GRID.key:
                return (
                    <MultipleChoiceGrid
                        index={props.index-1}
                        previewType={props.previewType}
                        questionData={props.questionData}
                        validateRequired={validateRequired}/>
                );
            case QUESTION_TYPE.DROPDOWN_CHOICE.key:
                return (
                    <SearchDropDown name={props.questionData._id}
                                    showOptions={props.showOptions}
                                    questionData={props.questionData}
                                    setShowOptions={props.setShowOptions}
                                    validateRequired={validateRequired}/>
                );
            case QUESTION_TYPE.CHECKBOX_CHOICE.key:
                return (
                    <CheckboxChoiceQuestion
                        isPreview={props.isPreview || props.previewType}
                        questionData={props.questionData}
                        validateRequired={validateRequired}/>
                );
            case QUESTION_TYPE.CHECKBOX_GRID.key:
                return (
                    <CheckBoxGrid index={props.index-1}
                                  previewType={props.previewType}
                                  questionData={props.questionData}
                                  validateRequired={validateRequired}/>
                );
            case QUESTION_TYPE.STAR_RATING_SCALE.key:
                return (
                    <StarRatingChoiceQuestion
                        questionData={props.questionData}
                        validateRequired={validateRequired}
                        previewType={props.previewType}/>
                );
            case QUESTION_TYPE.LINEAR_SCALE.key:
                return (
                    <LinearScaleChoiceQuestion questionData={props.questionData}
                                               previewType={props.previewType}
                                               validateRequired={validateRequired}/>
                );
            case QUESTION_TYPE.SMILEY.key:
                return (
                    <SmileyQuestion questionData={props.questionData}
                                    validateRequired={validateRequired}
                                    previewType={props.previewType}/>
                );
        }
    }
    return getChoiceComponent();
}
