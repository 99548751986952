import React, {useEffect, useState} from "react"
import style from "../css/quiz-user-info.module.css";
import hidePasswordIcon from "../../../public/images/hide_password.svg"
import showPasswordIcon from "../../../public/images/show_passcode.svg"
import {isDarkTheme, isImageTheme} from "../utils/theme-color-helper";
import MaterialButton from "../../shared/components/MaterialButton";
import {useDispatch, useSelector} from "react-redux";
import {InputComponent} from "./InputComponent";
import {FORM_SCREEN, THEME_TYPE} from "../utils/utils";
import {SCREENS_ACTION_TYPE} from "../reducers/screensReducer";
import {THEME_ACTION_TYPE} from "../reducers/themeReducer";

const PassCodePage = (props) => {
    const formData = useSelector(state => state.formDataReducer.formData)
    const themeColor = useSelector(state => state.themeReducer.themeColor)
    const primaryColor = isImageTheme(formData?.theme) ? themeColor?.primaryColor : isDarkTheme(formData.theme) ? '#212121' : '#0D47A1';
    const [showPasscode, setShowPasscode] = useState(false);
    const [isErrorInfo, setErrorInfo] = useState(false);
    const [passcode, setPasscode] = useState('');
    const dispatch = useDispatch()
    const titleStyle = {
        width: '100%',
        textAlign: 'center',
        color: isDarkTheme(formData.theme) ? "#FFFFFF" : "#424242"
    }
    const errorStyle = {
        color: '#f44336',
        fontSize: '12px',
        margin: '0px auto auto 3%',
        display: isErrorInfo ? 'block' : 'none'
    }
    const buttonContainerStyle = {
        width: '95%',
        display: 'flex',
        justifyContent: 'flex-end'
    }
    const startButtonData = {
        title: "Enter",
        textTransform: "uppercase",
        fontSize: "14px",
        bg_color: isDarkTheme(formData.theme) ? "#FFFFFF" : formData?.theme === THEME_TYPE.CLASSIC ? '#0D47A1' : themeColor?.primaryColor,
        customButtonContainerStyle: {
            filter: localStorage?.getItem("passcode_attempts_" + formData._id) === "3" ? "opacity(0.5)" : null,
            cursor: localStorage?.getItem("passcode_attempts_" + formData._id) === "3" ? "not-allowed" : "pointer",
            color: isDarkTheme(formData.theme) ? "#000000" : '#ffffff'
        }
    }

    useEffect(() => {
        if (localStorage?.getItem("passcode_attempts_" + formData._id) === "3") {
            let countDownTime = new Date().getTime() + 900000
            if (localStorage?.getItem("last_saved_time_" + formData._id) !== new Date().getTime()) {
                if (localStorage?.getItem("time_difference_" + formData._id)) {
                    countDownTime = (new Date().getTime() + parseInt(localStorage?.getItem("time_difference_" + formData._id)) - (new Date().getTime() - localStorage?.getItem("last_saved_time_" + formData._id)))
                }
            }
            const timeInterval = setInterval(function () {
                let now = new Date().getTime();
                let timeDifference = countDownTime - now;
                let minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
                let seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

                if (timeDifference < 0) {
                    localStorage?.removeItem("last_saved_time_" + formData._id)
                    localStorage?.removeItem("passcode_attempts_" + formData._id)
                    localStorage?.removeItem("time_difference_" + formData._id)
                    setErrorInfo(false)
                    clearInterval(timeInterval);
                } else {
                    localStorage?.setItem("last_saved_time_" + formData._id, new Date().getTime())
                    localStorage?.setItem("time_difference_" + formData._id, timeDifference)
                    setErrorInfo(`Entered wrong passcode 3 times. Please wait ${(minutes < 10 ? "0" + minutes : minutes) + ":" + (seconds < 10 ? "0" + seconds : seconds)} Mins`)
                }
            }, 1000);
        }
    }, [localStorage?.getItem("passcode_attempts_" + formData._id)])

    const handleStartClickEvent = () => {
        if (passcode === formData.setting?.passcode && (parseInt(localStorage?.getItem("passcode_attempts_" + formData._id)) < 3 || localStorage?.getItem("passcode_attempts_" + formData._id) === null)) {
            localStorage?.removeItem("passcode_attempts_" + formData._id)
            if (formData.setting.is_email && !window?.localStorage?.getItem("responder_token")) {
                dispatch({type: THEME_ACTION_TYPE.SET_THEME_IMAGE_BLUR, payload: 0})
                dispatch({type: SCREENS_ACTION_TYPE.SET_FORM_SCREEN, payload: FORM_SCREEN.COLLECT_EMAIL_PAGE})
                return
            }
            if (!props.previewType && !props.preview && formData.setting.allow_response_edit && window?.localStorage?.getItem("responder_token")) {
                if ((window.localStorage?.getItem(String(formData._id))) && formData.response_count > 0) {
                    dispatch({type: SCREENS_ACTION_TYPE.SET_FORM_SCREEN, payload: FORM_SCREEN.FORM_EDIT_RESPONSE})
                    return
                }
            }
            if (formData.welcome_screen.show_screen) {
                dispatch({type: SCREENS_ACTION_TYPE.SET_FORM_SCREEN, payload: FORM_SCREEN.WELCOME_PAGE})
                return
            }
            if (formData.abuse_scan_result && !props.previewType) {
                dispatch({type: SCREENS_ACTION_TYPE.SET_FORM_SCREEN, payload: FORM_SCREEN.ABUSE_WARNING})
                return
            } else if (formData.is_quiz) {
                dispatch({type: SCREENS_ACTION_TYPE.SET_FORM_SCREEN, payload: FORM_SCREEN.QUIZ_USER_INFO})
                return
            }
            if (!formData.is_quiz && props.formData?.user_info_form?.user_info_1 && !formData.setting?.is_email) {
                dispatch({type: SCREENS_ACTION_TYPE.SET_FORM_SCREEN, payload: FORM_SCREEN.FORM_USER_INFO})
            } else {
                dispatch({type: SCREENS_ACTION_TYPE.SET_FORM_SCREEN, payload: FORM_SCREEN.FORM_PAGE})
            }
        } else {
            if (localStorage?.getItem("passcode_attempts_" + formData._id) === null) {
                localStorage?.setItem("passcode_attempts_" + formData._id, 1)
                setErrorInfo(`Invalid Passcode. Contact ${formData.is_quiz ? "Quiz" : "Form"} creator.`)
            } else if (parseInt(localStorage?.getItem("passcode_attempts_" + formData._id)) < 3) {
                setErrorInfo(`Invalid Passcode. Contact ${formData.is_quiz ? "Quiz" : "Form"} creator.`)
                localStorage?.setItem("passcode_attempts_" + formData._id, parseInt(localStorage?.getItem("passcode_attempts_" + formData._id)) + 1)
            }
        }
    }

    return (
        <>
            <div id={"quiz-user-info-container"} className={style.popup_container}>
                <div className={style.quiz_user_info_popup} style={{
                    ...isDarkTheme(formData.theme) ? {"backgroundColor": primaryColor} : null
                }}>
                    <h2 id={"quiz-user-info-title"}
                        style={titleStyle}>
                        Enter Passcode
                    </h2>
                    <div style={{display: "flex", width: "95%"}}>
                        <InputComponent
                            theme={formData.theme}
                            type={"text"}
                            placeHolder={"Passcode"}
                            customStyle={{
                                webkitTextSecurity: showPasscode ? "none" : "disc"
                            }}
                            onInput={(e) => {
                                let value = e.target.value
                                e.target.value = value.replace(/[^0-9]/g, "");
                            }}
                            onChange={(e) => {
                                setPasscode(e.target.value.slice(0, 6));
                                e.target.value = e.target.value.slice(0, 6);
                                if (e.target.value.length === 6) {
                                    setErrorInfo(false)
                                } else {
                                    setErrorInfo("Passcode should be 6 numbers")
                                }
                            }}
                            onBlur={(element) => {
                                if (element.target.value.length === 6) {
                                    setErrorInfo(false)
                                } else {
                                    setErrorInfo("Passcode should be 6 numbers")
                                }
                            }}
                        />
                        <img style={{cursor: "pointer", filter: isDarkTheme(formData.theme) ? null : "brightness(20%)"}}
                             src={showPasscode ? showPasswordIcon : hidePasswordIcon} alt={"show passcode"}
                             onClick={() => setShowPasscode(!showPasscode)}/>
                    </div>
                    <p id="quiz_user_info_error"
                       style={errorStyle}>
                        {isErrorInfo}
                    </p>
                    <div id="quiz_user_info_button"
                         style={buttonContainerStyle}>
                        <MaterialButton
                            data={startButtonData}
                            handleClick={handleStartClickEvent}/>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PassCodePage
