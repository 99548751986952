import React, {useEffect, useRef, useState} from 'react'
import {createQuiz, getQuizById, updateQuiz} from "../../../shared/utils/quiz-api-services"
import LeftNavigator from "../../../shared/components/LeftNavigator"
import ThemesContainer from "./ThemesContainer"
import PopUpContainer from "../../../shared/components/PopUpContainer"
import ShareCard from "../../../shared/components/ShareCard"
import PreviewContainer from "./PreviewContainer"
import '../css/quiz-builder.css'
import {
    doNavigation,
    doPopupAction,
    helperBuilderMenuItems,
    SurveyHeartMenuItem,
    BuilderMenuItem,
    PreviewMenuItem,
    SettingsMenuItem,
    ThemesMenuItem,
    updateContainerHelper
} from "./BuilderLeftNavigatorHelper"
import {isMobile, isMobileOnly} from "react-device-detect"
import '../../../shared/css/modal-dialog.css'
import QuizSettingsContainer from "./QuizSettingsContainer"
import {incompleteQuestionCount, QUIZ_FOCUS_TARGET} from "./helpers/QuizBuilderHelper"
import {
    addMissingChoiceOptions, dataHasChanged,
    getQuestionProps,
    getSectionProps,
    getTitleProps,
    initializeHelperVars,
    popInitialSelectMenuItems,
    questionHasBeenAdded,
    resetFocusTitle,
    getNewQuestionsForAutocomplete,
    unapprovedModalDialog,
    editRightsRemovedDialog
} from "./helpers/BuilderContainerHelper"
import {
    BUILDER_CREATION_SOURCE, BUILDER_SUGGESTION_TYPES_FOR_LOCAL_STORAGE,
    BUILDER_TYPE,
    CURRENTLY_SELECTED_MENU,
    getQuizQuestionGroup, QUESTION_GROUPS,
    QUIZ_QUESTION_TYPE,
    SUBMISSION_ERRORS,
    WEB_APP
} from "../../../shared/utils/constants";
import {registerGAEvent} from "../../../shared/utils/analytics";
import Loader from "../../../shared/components/Loader";
import TitleSectionComponent from "./TitleSectionComponent"
import QuestionContainer from "./QuestionContainer"
import {
    getQuizBuilderSkeleton,
    getWebAppVersionInfo,
    removeProp, removePropWRTData
} from "./helpers/BuilderHelper"
import ModalDialog from "../../../shared/components/ModalDialog"
import BottomBar from "../../../shared/components/BottomBar"
import {PAGE_HASH_LOCATION, Pages} from "../../utils/pages"
import PopupMenuItem from "../../../shared/classes/PopupMenuItem"
import MenuIconInfo from "../../../shared/classes/MenuIconInfo"
import warningIcon from "../../../../public/images/icons/warning_icon.svg";
import {useDispatch, useSelector} from "react-redux"
import {AUTOCOMPLETE_ACTION_TYPE} from "../../reducers/autocompleteReducer"
import {COLLABORATOR_ACTION_TYPE} from "../../utils/constants";
import {translationKeys} from "../../../localizations/translationKeys-localization";
import {APP_ACTION_TYPE} from "../../reducers/appReducer";
import {BUILDER_ACTION_TYPE} from "../../reducers/builderReducer";
import {useTranslation} from "react-i18next";
import MobileQuestionContainer from "./MobileQuestionContainer";
import {APP_DASHBOARD_ACTION_TYPE} from "../../reducers/appDashboardReducer";
import {getQuizWords} from "../../../admin/utils/admin-api-services/words-api-services";
import {WORD_TYPE} from "../../../admin/constants";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import QuestionTypePopup from "../../../shared/components/QuestionTypePopup";
import SearchAndFormsList from "../../../shared/components/import-questions/SearchAndFormsList";
import ImportQuestionMobileScreen from "./ImportQuestionMobileScreen";
import {RESPONSES_ACTION_TYPE} from "../../reducers/responsesReducer";
import Message from "../../../shared/containers/ConfirmMessage";
import {FORM_SETTINGS} from "./FormBuilderContainer";
import {IMPORT_QUESTIONS_ACTION_TYPE} from "../../reducers/importQuestionsReducer";

export const QUIZ_SETTINGS = {
    ALLOW_SUMMARY_VIEW: 'allow_summary_view',
    ALLOW_MULTIPLE_ATTEMPTS: 'allow_multiple_attempts',
    SHOW_QUESTION_NUMBER: 'show_question_number',
    IS_SHUFFLED: 'is_shuffled',
    SHOW_LOGO: 'is_show_logo',
    SHOW_WELCOME_PAGE: 'show_screen',
    START_BUTTON_TEXT: 'button_text',
    SHOW_CORRECT_ANSWERS: 'is_show_correct_answer',
    THEME: 'theme',
    QUIZ_CONTROL_USER_INFO_1_TEXT: 'user_info_1_text',
    QUIZ_CONTROL_USER_INFO_2_TEXT: 'user_info_2_text',
    QUIZ_CONTROL_USER_INFO_3_TEXT: 'user_info_3_text',
    QUIZ_CONTROL_USER_INFO_2_CHECKED: 'user_info_2_checked',
    QUIZ_CONTROL_USER_INFO_3_CHECKED: 'user_info_3_checked',
    DURATION: 'duration',
    DURATION_HOURS: 'duration_hours',
    DURATION_MINUTES: 'duration_minutes',
    SHOW_QUESTION_COUNT: 'show_question_count',
    SHOW_QUESTION_MARKS: 'show_question_marks',
    PASSCODE: 'passcode',
    COLLECT_EMAIL: 'is_email',
    CONFIRMATION_MESSAGE: "confirmation_message",
    AUTO_OPEN_TIME: "auto_open_time",
    AUTO_CLOSE_TIME: "auto_close_time",
}

export const ANSWER_EVENT = {
    UPDATE_CHOICE_CORRECT_ANSWER: 'update_choice_correct_answer',
    UPDATE_TEXT_CORRECT_ANSWER: 'update_text_correct_answer',
    UPDATE_MARKS: 'update_marks',
    SET_QUESTION_ANSWER_TO_FOCUS: 'set_question_answer_to_focus'
}

const QuizBuilderContainer = (props) => {
    const {t} = useTranslation()
    const userId = useSelector(state => state.userReducer?.userId)
    const quizData = useSelector(state => state.appReducer.formData)
    const formId = useSelector(state => state.appReducer.formData?._id)
    const previewData = useSelector(state => state.appReducer.previewData)
    const shared = useSelector(state => state.appDashboardReducer.showShared)
    const templateTheme = useSelector(state => state.appReducer.templateTheme)
    const suggestions = useSelector(state => state.autocompleteReducer?.suggestions)
    const collaborator = useSelector(state => state.collaboratorsData?.collaborators)
    const showPreviewPane = useSelector(state => state.builderReducer.showPreviewPane)
    const templateQuestions = useSelector(state => state.appReducer.templateQuestions)
    const showModalDialog = useSelector(state => state.builderReducer.showModalDialog)
    const welcomeScreen = useSelector(state => state.appReducer.formData?.welcome_screen)
    const builderCreationSource = useSelector(state => state.appReducer.builderCreationSource)
    const builderToastMessage = useSelector(state => state.builderReducer?.builderToastMessage)
    const builderMessage = useSelector(state => state.builderReducer?.builderMessage)
    const showPremiumPopUp = useSelector(state => state.builderReducer.showPremiumPopUp)
    const refreshAnswerErrors = useSelector(state => state.builderReducer.refreshAnswerErrors)
    const sectionTitleToFocus = useSelector(state => state.builderReducer.sectionTitleToFocus)
    const currentlySelectedMenuItem = useSelector(state => state.builderReducer.currentlySelectedMenuItem)
    const showQuizActionBlockedPopUp = useSelector(state => state.builderReducer.showQuizActionBlockedPopUp)
    const quizAbuseAndBlockedWordsList = useSelector(state => state.builderReducer.quizAbuseAndBlockedWordsList)
    const showImportQuestionsDialog = useSelector(state => state.importQuestionsReducer?.showImportQuestionsDialog)
    const quizSuggestionsFromLocalStorage = useSelector(state => state.autocompleteReducer?.quizSuggestionsFromLocalStorage)
    const showMobileImportQuestionScreen = useSelector(state => state.importQuestionsReducer?.showMobileImportQuestionsScreen)
    const isDragAndDropDisabled = useSelector(state => state.builderReducer.isDragAndDropDisabled)
    const [initializationComplete, setInitializationComplete] = useState(false)
    const [questionAnswerToFocus, setQuestionAnswerToFocus] = useState(-1)
    const [shareMenuIsVisible, setShareMenuIsVisible] = useState(false)
    const [showMobileQTPopUpContainer, setShowMobileQTPopUpContainer] = useState(false)
    const [currentContainerTitle, setCurrentContainerTitle] = useState(null)

    const [showUnapprovedFormDialog, setShowUnapprovedFormDialog] = useState(false)
    const [showUnableToSubmitPopUp, setShowUnableToSubmitPopUp] = useState(false)
    const [showEditRightsRemoved, setShowEditRightsRemoved] = useState(false)
    const [showQuizSubmitPopUp, setShowQuizSubmitPopUp] = useState(false)
    const [screenHeight, setScreenHeight] = useState(0)
    const [showLoader, setShowLoader] = useState(false)
    const [questionNumberInfo, setQuestionNumberInfo] = useState([])
    const [hideBottomBar, setHideBottomBar] = useState(false)
    const [showQTsMenuFromAddQuestionButton, setShowQTsMenuFromAddQuestionButton] = useState(false)

    const dispatch = useDispatch()

    const refModalDialogButtons = useRef([])
    const refCardsContainer = useRef()
    const refSubmissionError = useRef(SUBMISSION_ERRORS.NONE)
    const refModalTitle = useRef('')
    const refModalBody = useRef('')
    const refQuizID = useRef('')
    const refIncompleteQuestionInfo = useRef(null)
    const refSavedQuizData = useRef(null)
    const refPopUpContainer = useRef()
    const refBuilderCreationSource = useRef(builderCreationSource)
    const refWebAppVersionInfo = useRef(null)
    const refFeaturesInWebAppOnly = useRef([])

    let unsavedChanges = useRef(false)

    const checkForNewQuestions = quizDataToSubmit => {
        const newQuestions = getNewQuestionsForAutocomplete(quizDataToSubmit, suggestions)

        if (newQuestions.length > 0) {
            let filteredBlockedAndAbuseWords = newQuestions
            quizAbuseAndBlockedWordsList.map((words) => {
                filteredBlockedAndAbuseWords = filteredBlockedAndAbuseWords.filter((item) => !item.includes(words.word))
            })
            dispatch({
                type: AUTOCOMPLETE_ACTION_TYPE.STORE_NEW_QUESTIONS_IN_LOCAL_STORAGE,
                payload: filteredBlockedAndAbuseWords
            })
        }
    }

    document.onkeydown = (e) => {
        if (e.key === "Escape") {
            if (showModalDialog) {
                handleCloseSubmissionErrorDialog()
            } else if (showQTsMenuFromAddQuestionButton) {
                setShowQTsMenuFromAddQuestionButton(false)
            } else {
                window.location.hash = PAGE_HASH_LOCATION.dashboard
            }
        } else if (!quizData?.pages[0]?.questions?.length && window.event.ctrlKey && e.key === "/") {
            e.preventDefault()
            handleOutsideClick()
            setShowQTsMenuFromAddQuestionButton(true)
        } else if (window.event.ctrlKey && (e.key === "s" || e.key === "S")) {
            e.preventDefault()
            submitQuiz()
        }
    }

    useEffect(() => {
        if (quizAbuseAndBlockedWordsList === null) {
            const successFunction = (blocked) => {
                getQuizWords(WORD_TYPE.ABUSE, (abuse) => {
                    const abuseAndBlockedWords = abuse.concat(blocked)
                    dispatch({
                        type: BUILDER_ACTION_TYPE.SET_QUIZ_ABUSE_AND_BLOCKED_WORDS_LIST,
                        payload: abuseAndBlockedWords
                    })
                    if (quizSuggestionsFromLocalStorage) {
                        let updatedLocalStorageItems = [...quizSuggestionsFromLocalStorage]
                        abuseAndBlockedWords.map((words) => {
                            updatedLocalStorageItems = updatedLocalStorageItems.filter((item) => !item.question.includes(words.word))
                        })
                        localStorage.setItem(BUILDER_SUGGESTION_TYPES_FOR_LOCAL_STORAGE.QUIZ_QUESTION.key, JSON.stringify(updatedLocalStorageItems))
                    }
                }, failureFunction)
            }
            const failureFunction = (err) => {
                console.log(err)
            }
            getQuizWords(WORD_TYPE.BLOCKED, successFunction, failureFunction)
        }
    }, [])

    const submitQuiz = () => {
        /**
         *
         * @param {SUBMISSION_ERRORS} submissionError
         * @param {string} title
         * @param {[]} body
         * @param {object} incompleteQuestionInfo
         */
        resetFocusTitle(false)

        const populateErrorInfo = (submissionError, title, body, incompleteQuestionInfo) => {
            refSubmissionError.current = submissionError
            refModalTitle.current = title
            refModalBody.current = body
            refIncompleteQuestionInfo.current = incompleteQuestionInfo
            showSubmissionError()

            if (currentlySelectedMenuItem !== CURRENTLY_SELECTED_MENU.BUILDER) {
                updateContainer(BuilderMenuItem)
            }
        }

        if (quizData.welcome_screen.title.trim() === '') {
            populateErrorInfo(SUBMISSION_ERRORS.MISSING_TITLE, t(translationKeys.title_required), [t(translationKeys.quiz_name_alert)], null)
            return
        }

        if (quizData.pages[0].questions.filter(q => q.type !== QUIZ_QUESTION_TYPE.SECTION.key).length === 0) {
            populateErrorInfo(SUBMISSION_ERRORS.MISSING_QUESTIONS, t(translationKeys.add_question), [t(translationKeys.add_question_alert_quiz)], null)
            return
        }

        let incompleteQuestionInfo = incompleteQuestionCount(quizData.pages[0].questions)

        if (incompleteQuestionInfo.count > 0) {
            let incompleteQuestionMsgBody = []

            if (incompleteQuestionInfo.atLeastOneTitleIsMissing) {
                incompleteQuestionMsgBody[0] = '*' + t(translationKeys.title_required)

                if (incompleteQuestionInfo.atLeastOneCorrectAnswerIsMissing) {
                    incompleteQuestionMsgBody[1] = '*' + t(translationKeys.correct_answer_required)

                    if (incompleteQuestionInfo.atLeastOneMarkIsZeroOrNegative) {
                        incompleteQuestionMsgBody[2] = '*' + t(translationKeys.marks_is_required)
                    }
                } else if (incompleteQuestionInfo.atLeastOneMarkIsZeroOrNegative) {
                    incompleteQuestionMsgBody[1] = '*' + t(translationKeys.marks_is_required)
                }
            } else if (incompleteQuestionInfo.atLeastOneCorrectAnswerIsMissing) {
                incompleteQuestionMsgBody[0] = '*' + t(translationKeys.correct_answer_required)

                if (incompleteQuestionInfo.atLeastOneMarkIsZeroOrNegative) {
                    incompleteQuestionMsgBody[1] = '*' + t(translationKeys.marks_is_required)
                }
            } else {
                // If a Title and Correct Answer aren't missing, a Mark must be 0 or negative.
                incompleteQuestionMsgBody[0] = '*' + t(translationKeys.marks_is_required)
            }

            populateErrorInfo(SUBMISSION_ERRORS.INCOMPLETE_QUESTIONS, t(translationKeys.incomplete_questions) + `${incompleteQuestionInfo.count}`, incompleteQuestionMsgBody, incompleteQuestionInfo)
            return
        }

        const quizCreateOrUpdateFailureFunction = err => {
            if (err.data.result === "Question Deleted Or Added" || err.data.result === "Choice Deleted Or Added") {
                setShowUnableToSubmitPopUp(true)
            }
            setShowLoader(false);
        }

        refWebAppVersionInfo.current = getWebAppVersionInfo(BUILDER_TYPE.QUIZ, quizData)

        const prepareQuizData = (bCreate) => {
            if (bCreate) {
                quizData.date_created = new Date().getTime().toString()
            }

            quizData.date_edited = new Date().getTime().toString()

            const quizDataToSubmit = {...quizData}

            if (bCreate) {
                quizDataToSubmit.creation_source = WEB_APP
                quizDataToSubmit.collaborators = collaborator
            } else {
                quizDataToSubmit.updation_source = WEB_APP
                quizDataToSubmit.last_edited_by = userId
            }

            quizDataToSubmit.web_app_version = refWebAppVersionInfo.current.version

            if (refBuilderCreationSource.current === BUILDER_CREATION_SOURCE.DUPLICATE) {
                quizDataToSubmit.setting.is_active = true
            }

            quizDataToSubmit.welcome_screen.title = quizDataToSubmit.welcome_screen.title.trim()
            quizDataToSubmit.welcome_screen.button_text = quizDataToSubmit.welcome_screen.button_text.trim()

            if (quizDataToSubmit.quiz_data && quizDataToSubmit.quiz_data.duration && !quizDataToSubmit.quiz_data.duration.is_enabled) {
                delete quizDataToSubmit.quiz_data['duration']
            }

            if (quizDataToSubmit.quiz_data && quizDataToSubmit.quiz_data.duration && quizDataToSubmit.quiz_data.duration.minutes > 0 && !quizDataToSubmit.quiz_data.duration.hours) {
                quizDataToSubmit.quiz_data.duration.hours = 0
            }

            if (quizDataToSubmit.quiz_data.user_info.user_info_2?.trim() === '') {
                delete quizDataToSubmit.quiz_data.user_info['user_info_2']
            }

            if (quizDataToSubmit.quiz_data.user_info.user_info_3?.trim() === '') {
                delete quizDataToSubmit.quiz_data.user_info['user_info_3']
            }

            removeProp([...quizDataToSubmit.pages[0].questions], 'tempAttachmentValue')
            removePropWRTData([...quizDataToSubmit.pages[0].questions], "_id", "tempId")

            delete quizDataToSubmit['response_count']

            quizDataToSubmit.pages[0].questions.map((question, questionIndex) => {
                if (QUIZ_QUESTION_TYPE[question.type] === QUIZ_QUESTION_TYPE.DROPDOWN_CHOICE || QUIZ_QUESTION_TYPE[question.type] === QUIZ_QUESTION_TYPE.MULTIPLE_CHOICE) {
                    question.choices.map((choice, choiceIndex) => {
                            if (choice.is_answer === false) {
                                // Remove this property.
                                delete quizDataToSubmit.pages[0].questions[questionIndex].choices[choiceIndex]['is_answer']
                            }
                        }
                    )
                }

                // Trim leading and trailing spaces from Text Questions
                if (QUIZ_QUESTION_TYPE[question.type] === QUIZ_QUESTION_TYPE.LONG_TEXT || QUIZ_QUESTION_TYPE[question.type] === QUIZ_QUESTION_TYPE.SHORT_TEXT) {
                    if (question.correct_answer) {
                        question.correct_answer = question.correct_answer.trim()
                    }
                }
            })

            return quizDataToSubmit
        }

        let quizDataToSubmit

        if (quizData?.setting?.confirmation_message?.trim().length > 0) {
            quizData.setting.confirmation_message = quizData.setting.confirmation_message.trim()
        }

        if (refBuilderCreationSource.current === BUILDER_CREATION_SOURCE.SAVED) {
            if (quizData.response_count > 0 && !showQuizSubmitPopUp) {
                setShowQuizSubmitPopUp(true)
                return;
            }
            if (!unsavedChanges.current) {
                setShareMenuIsVisible(true)
                if (isMobileOnly) {
                    dispatch({type: APP_ACTION_TYPE.SET_TEMPLATE_THEME, payload: null})
                    dispatch({type: APP_ACTION_TYPE.SET_IS_ANSWER_SHEET, payload: false})
                    dispatch({type: APP_ACTION_TYPE.SET_CURRENT_PAGE, payload: Pages.share})
                }
                return
            }

            // Update existing Quiz in the DB
            const updateQuizSuccessFunction = (result) => {
                registerGAEvent('Builder', 'quiz-submit', 'success')
                unsavedChanges.current = false

                if (result.user_can_save_data) {
                    if (result.formData.is_valid) {
                        refSavedQuizData.current = result.formData
                        isMobileOnly ? window.removeEventListener('popstate', onBackButtonEvent) : null
                        refFeaturesInWebAppOnly.current = refWebAppVersionInfo.current.featuresInWebAppOnly
                        setShareMenuIsVisible(true)
                        if (isMobileOnly) {
                            dispatch({type: APP_ACTION_TYPE.SET_FORM_DATA, payload: result.formData})
                            dispatch({type: APP_ACTION_TYPE.SET_IS_ANSWER_SHEET, payload: false})
                            dispatch({type: APP_ACTION_TYPE.SET_CURRENT_PAGE, payload: Pages.share})
                            dispatch({type: APP_ACTION_TYPE.SET_IS_QUIZ, payload: !!props.isQuiz})
                        }
                    } else {
                        // Blocked Words were found.
                        dispatch({type: APP_ACTION_TYPE.SET_FORM_DATA, payload: result.formData})
                        refSavedQuizData.current = result.formData
                        dispatch({type: APP_DASHBOARD_ACTION_TYPE.SET_IS_SHOWING_MOBILE_FORM_CONTROL, payload: false})
                        setShowUnapprovedFormDialog(true)
                    }
                } else {
                    setShowEditRightsRemoved(true)
                }

                setShowLoader(false);
            }

            quizDataToSubmit = prepareQuizData()
            setShowLoader(true);
            getQuizById(refQuizID.current, (res) => {
                if (res) {
                    removeProp([...quizDataToSubmit.pages[0].questions], 'tempAttachmentValue')
                    removePropWRTData([...quizDataToSubmit.pages[0].questions], "_id", "tempId")
                    quizDataToSubmit.user_id = res.user_id
                    updateQuiz(refQuizID.current, {quizAppData: quizDataToSubmit}, updateQuizSuccessFunction, quizCreateOrUpdateFailureFunction)
                }
            }, (err) => {
                console.log(err)
            })
            checkForNewQuestions(quizDataToSubmit)
        } else if ((refBuilderCreationSource.current === BUILDER_CREATION_SOURCE.NEW || refBuilderCreationSource.current === BUILDER_CREATION_SOURCE.DUPLICATE || refBuilderCreationSource.current === BUILDER_CREATION_SOURCE.TEMPLATE) && (currentlySelectedMenuItem === CURRENTLY_SELECTED_MENU.SETTINGS)) {
            // New or Duplicate Quiz ... Save it.
            const createQuizSuccessFunction = result => {
                registerGAEvent('Builder', 'quiz-submit', 'success');
                unsavedChanges.current = false
                refSavedQuizData.current = result.formData;
                refBuilderCreationSource.current = BUILDER_CREATION_SOURCE.SAVED

                if (result.formData.is_valid) {
                    refQuizID.current = result.form_id
                    dispatch({type: APP_ACTION_TYPE.SET_FORM_DATA, payload: result.formData})
                    refFeaturesInWebAppOnly.current = refWebAppVersionInfo.current.featuresInWebAppOnly
                    setShareMenuIsVisible(true)
                    if (isMobileOnly) {
                        const updatedFormData = result.formData
                        updatedFormData._id = result.form_id
                        dispatch({type: APP_ACTION_TYPE.SET_FORM_DATA, payload: result.formData})
                        dispatch({type: APP_ACTION_TYPE.SET_IS_ANSWER_SHEET, payload: false})
                        dispatch({type: APP_ACTION_TYPE.SET_CURRENT_PAGE, payload: Pages.share})
                        dispatch({type: APP_ACTION_TYPE.SET_IS_QUIZ, payload: !!props.isQuiz})
                        dispatch({type: RESPONSES_ACTION_TYPE.SET_RESPONSE_COUNT, payload: null})
                    }
                } else {
                    // Blocked Words were found.
                    dispatch({type: APP_ACTION_TYPE.SET_FORM_DATA, payload: result.formData})
                    dispatch({type: APP_DASHBOARD_ACTION_TYPE.SET_IS_SHOWING_MOBILE_FORM_CONTROL, payload: false})
                    setShowUnapprovedFormDialog(true)
                }
                setShowLoader(false)
            }

            quizDataToSubmit = prepareQuizData(true)
            quizDataToSubmit.date_favoured = null
            quizDataToSubmit.user_id = userId
            quizDataToSubmit._id = ""
            removeProp(quizDataToSubmit, '_id')
            setShowLoader(true)
            createQuiz({quizAppData: quizDataToSubmit}, createQuizSuccessFunction, quizCreateOrUpdateFailureFunction)
            checkForNewQuestions(quizDataToSubmit)
        } else if (currentlySelectedMenuItem !== CURRENTLY_SELECTED_MENU.SETTINGS) {
            // New or Duplicate Quiz ... Show Settings.
            updateContainer(SettingsMenuItem)
        }

        addMissingOptionValuesToMCQs()
    }

    const handleCloseSubmissionErrorDialog = () => {
        dispatch({type: BUILDER_ACTION_TYPE.SET_SHOW_MODAL_DIALOG, payload: false})

        switch (refSubmissionError.current) {
            case SUBMISSION_ERRORS.MISSING_TITLE:
                resetFocusTitle(true)
                break
            case SUBMISSION_ERRORS.MISSING_QUESTIONS:
                // Do Nothing
                break
            case SUBMISSION_ERRORS.INCOMPLETE_QUESTIONS:
                if (refIncompleteQuestionInfo.current.focusTarget === QUIZ_FOCUS_TARGET.TITLE) {
                    if (QUIZ_QUESTION_TYPE[quizData.pages[0].questions[refIncompleteQuestionInfo.current.questionIndexToFocus].type] === QUIZ_QUESTION_TYPE.SECTION) {
                        dispatch({
                            type: BUILDER_ACTION_TYPE.SET_SECTION_TITLE_TO_FOCUS,
                            payload: refIncompleteQuestionInfo.current.questionIndexToFocus
                        })
                    } else {
                        const updatedQuizData = {...quizData}
                        const updatedQuestions = [...updatedQuizData.pages[0].questions]

                        updatedQuestions[refIncompleteQuestionInfo.current.questionIndexToFocus].setFocus = true
                        updatedQuizData.pages[0].questions = updatedQuestions
                        dispatch({type: APP_ACTION_TYPE.SET_FORM_DATA, payload: updatedQuizData})
                    }
                } else if (refIncompleteQuestionInfo.current.focusTarget === QUIZ_FOCUS_TARGET.ANSWER) {
                    setQuestionAnswerToFocus(refIncompleteQuestionInfo.current.questionIndexToFocus)
                }
                break
        }
    }

    const showSubmissionError = () => {
        try {
            resetFocusTitle(false)
            dispatch({type: BUILDER_ACTION_TYPE.SET_SECTION_TITLE_TO_FOCUS, payload: -1})

            refModalDialogButtons.current = [
                {
                    text: t(translationKeys.ok),
                    action:
                        () => {
                            handleCloseSubmissionErrorDialog()
                        }
                }
            ]

            if (refSubmissionError.current === SUBMISSION_ERRORS.MISSING_TITLE) {
                dispatch({type: BUILDER_ACTION_TYPE.SET_SHOW_MODAL_DIALOG, payload: true})
                return
            }

            if (refSubmissionError.current === SUBMISSION_ERRORS.MISSING_QUESTIONS) {
                dispatch({type: BUILDER_ACTION_TYPE.SET_SHOW_MODAL_DIALOG, payload: true})
                if (isMobileOnly) updateContainer(BuilderMenuItem)
                return
            }

            if (refSubmissionError.current === SUBMISSION_ERRORS.INCOMPLETE_QUESTIONS) {
                if (isMobileOnly) updateContainer(BuilderMenuItem)
                dispatch({type: BUILDER_ACTION_TYPE.SET_SHOW_MODAL_DIALOG, payload: true})
            }
        } catch
            (e) {
            console.log(e)
        }
    }

    const [builderMenuItems, setBuilderMenuItems] = useState(() => {
        if (isMobile) {
            return [...helperBuilderMenuItems(), new PopupMenuItem(3, t(translationKeys.submit), new MenuIconInfo("submit", 'submit_grey.svg'), submitQuiz)]
        } else {
            return helperBuilderMenuItems()
        }
    })

    const onBackButtonEvent = (e) => {
        if (window.location.hash !== PAGE_HASH_LOCATION.dashboard || !e) {
            /*if (!unsavedChanges.current && isMobile) {
                window.location.hash = PAGE_HASH_LOCATION.dashboard
            }*/

            return
        }

        if (isMobileOnly && builderCreationSource === BUILDER_CREATION_SOURCE.DUPLICATE) {
            const updatedFormData = {...quizData}
            updatedFormData.welcome_screen.title = updatedFormData.welcome_screen.title.replace(`(${t(translationKeys.copy)})`, "")
            dispatch({type: APP_ACTION_TYPE.SET_FORM_DATA, payload: updatedFormData})
        }

        e.preventDefault()

        if (unsavedChanges.current) {
            if (isMobileOnly) {
                if (window.location.hash === PAGE_HASH_LOCATION.quiz_builder || (window.location.hash === '#form-control') || (window.location.hash === PAGE_HASH_LOCATION.dashboard)) {
                    if (window.confirm(t(translationKeys.you_havent_submitted_your_changes_is_it_okay_to_leave_without_submitting))) {
                        window.removeEventListener('popstate', onBackButtonEvent);
                        dispatch({type: APP_DASHBOARD_ACTION_TYPE.SET_IS_SHOWING_MOBILE_FORM_CONTROL, payload: false})
                        dispatch({type: APP_ACTION_TYPE.SET_CURRENT_PAGE, payload: Pages.dashboard})
                        dispatch({type: BUILDER_ACTION_TYPE.SET_NEW_QUESTION, payload: false})
                        dispatch({type: APP_DASHBOARD_ACTION_TYPE.SET_SHOW_QUIZ})
                        window.location.hash = PAGE_HASH_LOCATION.dashboard
                    } else {
                        window.history.pushState(null, null, (window.location.pathname + PAGE_HASH_LOCATION.quiz_builder));
                    }
                } else {
                    window.removeEventListener('popstate', onBackButtonEvent)
                }
            } else {
                if (window.confirm(t(translationKeys.you_havent_submitted_your_changes_is_it_okay_to_leave_without_submitting))) {
                    dispatch({type: APP_ACTION_TYPE.SET_CURRENT_PAGE, payload: Pages.dashboard})
                    window.removeEventListener('popstate', onBackButtonEvent);
                    window.location.hash = PAGE_HASH_LOCATION.dashboard
                } else {
                    window.history.pushState(null, null, (window.location.pathname + PAGE_HASH_LOCATION.quiz_builder));
                }
            }
        } else {
            if (isMobileOnly) {
                window.location.hash = PAGE_HASH_LOCATION.dashboard
                dispatch({type: APP_ACTION_TYPE.SET_CURRENT_PAGE, payload: Pages.dashboard})
            }
        }
        dispatch({type: IMPORT_QUESTIONS_ACTION_TYPE.SET_SHOW_MOBILE_IMPORT_QUESTIONS_SCREEN, payload: false})
        dispatch({type: IMPORT_QUESTIONS_ACTION_TYPE.SET_SHOW_IMPORT_QUESTIONS_DIALOG, payload: false})
    }

    const initializeQuizData = () => {
        switch (builderCreationSource) {
            case BUILDER_CREATION_SOURCE.NEW:
                return getQuizBuilderSkeleton(BUILDER_CREATION_SOURCE.NEW, {
                    userID: userId
                })
            case BUILDER_CREATION_SOURCE.TEMPLATE:
                return getQuizBuilderSkeleton(BUILDER_CREATION_SOURCE.TEMPLATE, {
                        userID: userId,
                        title: welcomeScreen?.title,
                        desc: welcomeScreen?.description,
                        theme: templateTheme,
                        welcomeScreenSettings: {
                            show_button: welcomeScreen?.show_button,
                            show_screen: true,
                            button_text: welcomeScreen?.button_text
                        },
                        templateQuestions: templateQuestions,
                    }
                )
            // SAVED and DUPLICATED quizzes are updated when the data is retrieved in the load useEffect[].
            case BUILDER_CREATION_SOURCE.DUPLICATE:
            case BUILDER_CREATION_SOURCE.SAVED:
                return null

        }
    }

    useEffect(() => {
        if (!shared) {
            dispatch({type: APP_ACTION_TYPE.SET_FORM_DATA, payload: initializeQuizData()})
        }
        dispatch({type: BUILDER_ACTION_TYPE.SET_CURRENTLY_SELECTED_MENU_ITEM, payload: CURRENTLY_SELECTED_MENU.BUILDER})
        dispatch({type: BUILDER_ACTION_TYPE.SET_SHOW_PREVIEW_PANE, payload: true})
        if (BUILDER_CREATION_SOURCE.NEW || BUILDER_CREATION_SOURCE.DUPLICATE) {
            dispatch({type: COLLABORATOR_ACTION_TYPE.UPDATE_COLLABORATOR_DATA, payload: []})
        }
        resetFocusTitle(true)
        window.location.hash = PAGE_HASH_LOCATION.quiz_builder

        updateContainerHelper(BuilderMenuItem, currentContainerTitle, setBuilderMenuItems, setCurrentContainerTitle)

        const getDataSuccessFunction = res => {
            let retrievedQuizData = getQuizBuilderSkeleton(BUILDER_CREATION_SOURCE.NEW, {
                userID: shared ? res.user_id : userId
            })

            if (res.quiz_data.duration === undefined) {
                // Add Duration and Is_Enabled - These properties are needed in order to control the duration check box
                // in settings. If duration is NOT changed in Settings, the Duration and Property should be removed
                // when saving Settings.
                retrievedQuizData.quiz_data.duration = {}
                retrievedQuizData.quiz_data.duration.is_enabled = false
            } else {
                retrievedQuizData.quiz_data = res.quiz_data
            }

            retrievedQuizData.quiz_data.user_info = res.quiz_data.user_info

            Object.keys(retrievedQuizData.welcome_screen).map((obj) => {
                retrievedQuizData.welcome_screen[obj] = res.welcome_screen[obj]

                if (builderCreationSource === BUILDER_CREATION_SOURCE.DUPLICATE && obj === 'title') {
                    retrievedQuizData.welcome_screen[obj] = `${retrievedQuizData.welcome_screen[obj]} ` + '(' + t(translationKeys.copy) + ')'
                    retrievedQuizData.response_count = 0
                    dispatch({type: APP_ACTION_TYPE.SET_SHARED, payload: false})
                }
            })

            Object.keys(res.setting).map((obj) => {
                retrievedQuizData.setting[obj] = res.setting[obj]
            })

            retrievedQuizData.theme = res.theme
            retrievedQuizData.date_created = res.date_created
            retrievedQuizData.date_edited = res.date_edited
            retrievedQuizData.date_favoured = res.date_favoured
            retrievedQuizData.template_category = res.template_category
            retrievedQuizData.collaborators = res.collaborators

            if (res.subscription_data) {
                retrievedQuizData.subscription_data = res.subscription_data
            }

            if (builderCreationSource !== BUILDER_CREATION_SOURCE.DUPLICATE) {
                retrievedQuizData.last_edited_by = res.last_edited_by
            }

            let updatedQuestions = [...retrievedQuizData.pages[0].questions]

            res.pages[0].questions.map((q) => {
                q.tempAttachmentValue = q.attachment?.url ?? ''
                updatedQuestions.push(q)
            })

            retrievedQuizData.pages[0].questions = updatedQuestions
            if (builderCreationSource === BUILDER_CREATION_SOURCE.DUPLICATE) {
                delete retrievedQuizData.response_count
                retrievedQuizData.setting.passcode = null
            } else {
                retrievedQuizData.response_count = res.response_count
            }
            retrievedQuizData._id = res._id
            refSavedQuizData.current = JSON.parse(JSON.stringify(retrievedQuizData))
            dispatch({type: APP_ACTION_TYPE.SET_FORM_DATA, payload: retrievedQuizData})
        }
        const getDataFailureFunction = err => {
            console.log(err)
            return null
        }

        switch (refBuilderCreationSource.current) {
            case BUILDER_CREATION_SOURCE.DUPLICATE:
                setShowLoader(true)
                // formId is the Quiz that should be duplicated.
                getQuizById(formId, getDataSuccessFunction, getDataFailureFunction)
                break
            case BUILDER_CREATION_SOURCE.SAVED:
                refQuizID.current = formId
                setShowLoader(true)
                getQuizById(formId, getDataSuccessFunction, getDataFailureFunction)
                break
            case  BUILDER_CREATION_SOURCE.NEW:
            case BUILDER_CREATION_SOURCE.TEMPLATE:
                initializeHelperVars(quizData, dispatch, true, builderCreationSource === BUILDER_CREATION_SOURCE.NEW, refModalDialogButtons, refModalBody, refModalTitle, refBuilderCreationSource, refCardsContainer, isMobile, setQuestionAnswerToFocus, questionAnswerToFocus, refreshAnswerErrors)
                break
        }

        dispatch({type: AUTOCOMPLETE_ACTION_TYPE.SET_BUILDER_TYPE, payload: BUILDER_TYPE.QUIZ})
        dispatch({type: AUTOCOMPLETE_ACTION_TYPE.CLEAR_SELECTED_VALUE})
        setShowLoader(false)
        window.addEventListener('popstate', onBackButtonEvent);
        return () => {
            window.removeEventListener('popstate', onBackButtonEvent);
            unsavedChanges.current = false;
        }
    }, [])

    useEffect(() => {
        if (quizData !== null && quizData.pages) {
            if (templateTheme) {

                // Populate Template Theme
                let tempQuizData = quizData

                tempQuizData.theme = templateTheme
                tempQuizData.is_quizzory_v2 = true
                dispatch({type: APP_ACTION_TYPE.SET_FORM_DATA, payload: tempQuizData})
            }

            initializeHelperVars(quizData, dispatch, true, builderCreationSource === BUILDER_CREATION_SOURCE.NEW, refModalDialogButtons, refModalBody, refModalTitle, refBuilderCreationSource, refCardsContainer, isMobile, setQuestionAnswerToFocus, questionAnswerToFocus, refreshAnswerErrors)
            if (builderCreationSource === BUILDER_CREATION_SOURCE.TEMPLATE) {
                quizData.user_id = userId
                quizData._id = ''
            }

            setInitializationComplete(true)

            if (refBuilderCreationSource.current === BUILDER_CREATION_SOURCE.NEW && (questionHasBeenAdded(quizData)) || refBuilderCreationSource.current !== BUILDER_CREATION_SOURCE.NEW) {
                unsavedChanges.current = dataHasChanged(quizData, refSavedQuizData)
            } else {
                unsavedChanges.current = false
            }

            if (builderCreationSource === BUILDER_CREATION_SOURCE.TEMPLATE) {
                // unsavedChanges.current = true
                quizData._id = ''
            }

            if (quizData.pages[0].questions.length === 0) {
                setShowQTsMenuFromAddQuestionButton(false)
            } else {
                // Store Question Numbers by excluding QUESTION_TYPE.SECTION from Questions
                let tempQuestionNumberInfo = []

                quizData.pages[0].questions.map((question, index) => (
                        () => {
                            if (QUIZ_QUESTION_TYPE[question.type] !== QUIZ_QUESTION_TYPE.SECTION) {
                                tempQuestionNumberInfo.push({
                                    questionIndex: index,
                                    questionNumber: tempQuestionNumberInfo.length + 1
                                })
                            }
                        }
                    )()
                )

                setQuestionNumberInfo(tempQuestionNumberInfo)
            }
        }
    }, [quizData])

    useEffect(() => {
        if (unsavedChanges.current) {
            addBeforeUnloadEvent()
        } else {
            removeBeforeUnloadEvent()
        }
    }, [unsavedChanges.current]);

    useEffect(() => {
        if (sectionTitleToFocus > -1) {
            dispatch({type: BUILDER_ACTION_TYPE.SET_SECTION_TITLE_TO_FOCUS, payload: -1})
        }
    }, [sectionTitleToFocus])

    const warningText = t(translationKeys.you_have_unsaved_changes_you_sure_you_wish_leave_this_page);
    const handleWindowClose = (e) => {
        if (!unsavedChanges.current) return;
        e.preventDefault();
        return (e.returnValue = warningText);
    };

    const addBeforeUnloadEvent = () => {
        window.addEventListener('beforeunload', handleWindowClose);
    }
    const removeBeforeUnloadEvent = () => {
        window.removeEventListener('beforeunload', handleWindowClose);
    }

    const handleAppIconClick = () => {
        doNavigation(SurveyHeartMenuItem)

        if (unsavedChanges.current) {
            onBackButtonEvent()
        } else {
            dispatch({type: APP_ACTION_TYPE.SET_CURRENT_PAGE, payload: Pages.dashboard})
        }
    }

    const addMissingOptionValuesToMCQs = () => {
        if (!quizData) {
            return
        }

        // Make sure all "Choice" type questions have a value.
        let optionAdded = false
        let updatedQuizData = quizData

        let tempQuestions = updatedQuizData.pages[0].questions.map((question) => {
            if (QUIZ_QUESTION_TYPE[question.type] === QUIZ_QUESTION_TYPE.DROPDOWN_CHOICE || QUIZ_QUESTION_TYPE[question.type] === QUIZ_QUESTION_TYPE.MULTIPLE_CHOICE) {
                optionAdded = addMissingChoiceOptions(question, optionAdded)
            }

            if (optionAdded) {
                question.refreshOptions = true
            }

            return question
        })

        if (optionAdded) {
            updatedQuizData.pages[0].questions = tempQuestions
            dispatch({type: APP_ACTION_TYPE.SET_FORM_DATA, payload: {...updatedQuizData}})
        }
    }

    const updateSetting = (setting, value) => {
        let updatedQuizData = {...quizData}

        switch (setting) {
            case QUIZ_SETTINGS.ALLOW_MULTIPLE_ATTEMPTS:
                updatedQuizData.setting.allow_multiple_submit = value
                break
            case QUIZ_SETTINGS.ALLOW_SUMMARY_VIEW:
                updatedQuizData.setting.allow_summary_view = value
                break
            case QUIZ_SETTINGS.SHOW_CORRECT_ANSWERS:
                updatedQuizData.setting.is_show_correct_answer = value
                break
            case QUIZ_SETTINGS.IS_SHUFFLED:
                updatedQuizData.setting.is_shuffled = value
                break
            case QUIZ_SETTINGS.SHOW_LOGO:
                updatedQuizData.setting.is_show_logo = value
                break
            case QUIZ_SETTINGS.SHOW_QUESTION_NUMBER:
                updatedQuizData.setting.show_question_number = value
                break
            case QUIZ_SETTINGS.DURATION:
                if (!updatedQuizData.quiz_data.duration) {
                    updatedQuizData.quiz_data.duration = {}
                }

                updatedQuizData.quiz_data.duration.is_enabled = value
                break
            case QUIZ_SETTINGS.DURATION_HOURS:
                if ((value === 0) && (refSavedQuizData.current !== null) && (refSavedQuizData.current.quiz_data.duration !== undefined) && (refSavedQuizData.current.quiz_data.duration.hours === undefined)) {
                    delete updatedQuizData.quiz_data.duration.hours
                } else {
                    updatedQuizData.quiz_data.duration.hours = value
                }
                break
            case QUIZ_SETTINGS.DURATION_MINUTES:
                if ((value === 0) && (refSavedQuizData.current !== null) && (refSavedQuizData.current.quiz_data.duration !== undefined) && (refSavedQuizData.current.quiz_data.duration.minutes === undefined)) {
                    delete updatedQuizData.quiz_data.duration.minutes
                } else {
                    updatedQuizData.quiz_data.duration.minutes = value
                }
                break
            case QUIZ_SETTINGS.SHOW_WELCOME_PAGE:
                updatedQuizData.welcome_screen.show_screen = value
                break
            case QUIZ_SETTINGS.START_BUTTON_TEXT:
                updatedQuizData.welcome_screen.button_text = value
                break
            case QUIZ_SETTINGS.THEME:
                updatedQuizData.theme = value
                break
            case QUIZ_SETTINGS.QUIZ_CONTROL_USER_INFO_1_TEXT:
                updatedQuizData.quiz_data.user_info.user_info_1 = value
                break
            case QUIZ_SETTINGS.QUIZ_CONTROL_USER_INFO_2_TEXT:
                updatedQuizData.quiz_data.user_info.user_info_2 = value
                break
            case QUIZ_SETTINGS.QUIZ_CONTROL_USER_INFO_3_TEXT:
                updatedQuizData.quiz_data.user_info.user_info_3 = value
                break
            case QUIZ_SETTINGS.QUIZ_CONTROL_USER_INFO_2_CHECKED:
                if (value) {
                    updatedQuizData.quiz_data.user_info.user_info_2 = t(translationKeys.email)
                } else {
                    updatedQuizData.quiz_data.user_info.user_info_2 = ''
                }

                break
            case QUIZ_SETTINGS.QUIZ_CONTROL_USER_INFO_3_CHECKED:
                if (value) {
                    updatedQuizData.quiz_data.user_info.user_info_3 = t(translationKeys.number)
                } else {
                    updatedQuizData.quiz_data.user_info.user_info_3 = ''
                }

                break
            case QUIZ_SETTINGS.SHOW_QUESTION_COUNT:
                updatedQuizData.setting.show_question_count = value
                break
            case QUIZ_SETTINGS.SHOW_QUESTION_MARKS:
                updatedQuizData.setting.show_question_marks = value
                break
            case QUIZ_SETTINGS.PASSCODE:
                if (value === 'delete') {
                    delete updatedQuizData.setting.passcode
                } else {
                    updatedQuizData.setting.passcode = value
                }
                break
            case FORM_SETTINGS.CONFIRMATION_MESSAGE:
                updatedQuizData.setting.confirmation_message = value
                break
            case QUIZ_SETTINGS.COLLECT_EMAIL:
                updatedQuizData.setting.is_email = value
                break
            case QUIZ_SETTINGS.AUTO_OPEN_TIME:
                if (value === 'delete') {
                    delete updatedQuizData.setting.auto_open_time
                } else {
                    updatedQuizData.setting.auto_open_time = value
                }
                break
            case QUIZ_SETTINGS.AUTO_CLOSE_TIME:
                if (value === 'delete') {
                    delete updatedQuizData.setting.auto_close_time
                } else {
                    updatedQuizData.setting.auto_close_time = value
                }
                break
        }

        updatedQuizData.date_edited = Date.now().toString()
        dispatch({type: APP_ACTION_TYPE.SET_FORM_DATA, payload: updatedQuizData})
    }
    const showSettingsContainer = () => {
        updateContainerHelper(SettingsMenuItem, currentContainerTitle, setBuilderMenuItems, setCurrentContainerTitle)
    }
    const showThemesContainer = () => {
        isMobileOnly ? updateContainer(ThemesMenuItem) : setCurrentContainerTitle("ThemesContainer")
    }

    const updateContainer = clickedItem => {
        if (!showLoader) {
            if (clickedItem === SettingsMenuItem) {
                dispatch({
                    type: BUILDER_ACTION_TYPE.SET_CURRENTLY_SELECTED_MENU_ITEM,
                    payload: CURRENTLY_SELECTED_MENU.SETTINGS
                })
                addMissingOptionValuesToMCQs()
            } else if (clickedItem === BuilderMenuItem) {
                dispatch({
                    type: BUILDER_ACTION_TYPE.SET_CURRENTLY_SELECTED_MENU_ITEM,
                    payload: CURRENTLY_SELECTED_MENU.BUILDER
                })
            } else if (isMobileOnly && clickedItem === PreviewMenuItem) {
                const populateErrorInfo = (submissionError, title, body, incompleteQuestionInfo) => {
                    refSubmissionError.current = submissionError
                    refModalTitle.current = title
                    refModalBody.current = body
                    refIncompleteQuestionInfo.current = incompleteQuestionInfo
                    showSubmissionError()

                    if (currentlySelectedMenuItem !== CURRENTLY_SELECTED_MENU.BUILDER) {
                        updateContainer(BuilderMenuItem)
                    }
                }

                if (quizData.welcome_screen.title.trim() === '') {
                    populateErrorInfo(SUBMISSION_ERRORS.MISSING_TITLE, t(translationKeys.title_required), [t(translationKeys.quiz_name_alert)], null)
                    return
                }

                if (quizData.pages[0].questions.filter(q => q.type !== QUIZ_QUESTION_TYPE.SECTION.key).length === 0) {
                    populateErrorInfo(SUBMISSION_ERRORS.MISSING_QUESTIONS, t(translationKeys.add_question), [t(translationKeys.add_question_alert_quiz)], null)
                    return
                }

                let incompleteQuestionInfo = incompleteQuestionCount(quizData.pages[0].questions)

                if (incompleteQuestionInfo.count > 0) {
                    let incompleteQuestionMsgBody = []

                    if (incompleteQuestionInfo.atLeastOneTitleIsMissing) {
                        incompleteQuestionMsgBody[0] = '*' + t(translationKeys.title_required)

                        if (incompleteQuestionInfo.atLeastOneCorrectAnswerIsMissing) {
                            incompleteQuestionMsgBody[1] = '*' + t(translationKeys.correct_answer_required)

                            if (incompleteQuestionInfo.atLeastOneMarkIsZeroOrNegative) {
                                incompleteQuestionMsgBody[2] = '*' + t(translationKeys.marks_is_required)
                            }
                        } else if (incompleteQuestionInfo.atLeastOneMarkIsZeroOrNegative) {
                            incompleteQuestionMsgBody[1] = '*' + t(translationKeys.marks_is_required)
                        }
                    } else if (incompleteQuestionInfo.atLeastOneCorrectAnswerIsMissing) {
                        incompleteQuestionMsgBody[0] = '*' + t(translationKeys.correct_answer_required)

                        if (incompleteQuestionInfo.atLeastOneMarkIsZeroOrNegative) {
                            incompleteQuestionMsgBody[1] = '*' + t(translationKeys.marks_is_required)
                        }
                    } else {
                        // If a Title and Correct Answer aren't missing, a Mark must be 0 or negative.
                        incompleteQuestionMsgBody[0] = '*' + t(translationKeys.marks_is_required)
                    }

                    populateErrorInfo(SUBMISSION_ERRORS.INCOMPLETE_QUESTIONS, t(translationKeys.incomplete_questions) + `${incompleteQuestionInfo.count}`, incompleteQuestionMsgBody, incompleteQuestionInfo)
                    return
                }
                dispatch({
                    type: BUILDER_ACTION_TYPE.SET_CURRENTLY_SELECTED_MENU_ITEM,
                    payload: CURRENTLY_SELECTED_MENU.PREVIEW
                })
            }
            dispatch({type: BUILDER_ACTION_TYPE.SET_NEW_QUESTION, payload: false})
            updateContainerHelper(clickedItem, currentContainerTitle, setBuilderMenuItems, setCurrentContainerTitle)
        }
    }

    const shareScreenVisibilityHandler = () => {
        setShareMenuIsVisible(false)
        dispatch({type: APP_ACTION_TYPE.SET_CURRENT_PAGE, payload: Pages.dashboard})

        if (isMobile) {
            window.location.hash = PAGE_HASH_LOCATION.dashboard
        }
    }

    if (isMobileOnly) {
        document.body.onresize = () => {
            if (window.innerHeight < 400) {
                //for not shrink the height while keyboard opened.
                setScreenHeight(document.documentElement.clientHeight)
            } else {
                //to handle the chrome url tab shown/hidden screen adjustment in Mobile view.
                setScreenHeight(window.innerHeight)
            }
        }
    }

    const handleOutsideClick = () => {
        document.onclick = e => {
            if (refPopUpContainer.current && !refPopUpContainer.current.contains(e.target)) {
                e.stopPropagation()
                setShowMobileQTPopUpContainer(false)
                setShowQTsMenuFromAddQuestionButton(false)
                document.onclick = null
            }
        }
    }

    const ShowQuestionTypesMenuFromAddQuestion = (e) => {
        e.nativeEvent.stopImmediatePropagation()

        // Toggle showing the menu
        setShowQTsMenuFromAddQuestionButton(true)
    }

    const handleOnDragEnd = (result) => {
        if (!result.destination) return
        const updatedQuizData = {...quizData}
        const [reorderedQuestions] = updatedQuizData.pages[0].questions.splice(result.source.index, 1)
        updatedQuizData.pages[0].questions.splice(result.destination.index, 0, reorderedQuestions)
        dispatch({type: BUILDER_ACTION_TYPE.SET_NEW_QUESTION, payload: false})
        dispatch({type: APP_ACTION_TYPE.SET_FORM_DATA, payload: updatedQuizData})
    }

    const handleOnDragStart = () => {
        dispatch({type: AUTOCOMPLETE_ACTION_TYPE.SET_SELECTED_VALUE, payload: ''})
    }

    return (
        (() => {
            if (!initializationComplete) return (
                <Loader height='100%' width='100%' left='0px'/>
            )

            if (isMobileOnly) return (
                // ************************** Mobile Version Starts ************************** //
                <div className='app-container' style={screenHeight ? {height: screenHeight} : null}>
                    {showMobileImportQuestionScreen ? <ImportQuestionMobileScreen/> :
                        <div className='body-container-mobile'>
                            {
                                (() => {
                                    if (currentContainerTitle === BuilderMenuItem.getTitle()) return (
                                        <>
                                            <div className='builder-box1'>
                                                <div className='form-builder-body-container'>
                                                    <div className="builder-container" id="builder-container">
                                                        <div className="builder-question-container"
                                                             id="builder-question-container">
                                                            <TitleSectionComponent {...getTitleProps()} />
                                                            {
                                                                (() => {
                                                                    if (showMobileQTPopUpContainer && showQTsMenuFromAddQuestionButton) return (
                                                                        <div style={{
                                                                            top: 0,
                                                                            backgroundColor: 'rgba(0, 0, 0, 0.3)',
                                                                            position: 'fixed',
                                                                            width: '100%',
                                                                            height: '100%',
                                                                            zIndex: '11',
                                                                            display: 'flex',
                                                                            alignItems: 'center',
                                                                            justifyContent: 'center'
                                                                        }}
                                                                             onClick={e => handleOutsideClick(e)}
                                                                        >
                                                                            <div ref={refPopUpContainer}
                                                                                 style={{display: "contents"}}>
                                                                                <QuestionTypePopup
                                                                                    questionIndex={quizData?.pages[0]?.questions?.length}
                                                                                    setShowMobileQTPopUpContainer={setShowMobileQTPopUpContainer}/>
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                })()
                                                            }
                                                        </div>
                                                    </div>
                                                    {
                                                        (() => {
                                                            if (quizData?.pages[0]?.questions?.length > 0) return (
                                                                <div className='builder-forms-container'
                                                                     style={{marginBottom: '65px'}}>
                                                                    <DragDropContext onDragStart={handleOnDragStart}
                                                                                     onDragEnd={handleOnDragEnd}>
                                                                        <Droppable droppableId="characters">
                                                                            {(provided) => (
                                                                                <div {...provided.droppableProps}
                                                                                     ref={provided.innerRef}
                                                                                     style={{width: "100%"}}>
                                                                                    <div ref={refCardsContainer}
                                                                                         className="builder-cards-container">
                                                                                        {
                                                                                            quizData.pages[0].questions.map((question, index) => (
                                                                                                () => {
                                                                                                    let questionGroup = getQuizQuestionGroup(QUIZ_QUESTION_TYPE[question.type])

                                                                                                    if (questionGroup === QUESTION_GROUPS.SECTION) {
                                                                                                        return (
                                                                                                            <Draggable
                                                                                                                key={question._id}
                                                                                                                draggableId={question._id}
                                                                                                                index={index}
                                                                                                                isDragDisabled={isDragAndDropDisabled}>
                                                                                                                {(provided) => (
                                                                                                                    <div
                                                                                                                        ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                                                                                        <TitleSectionComponent {...getSectionProps(question, index)}/>
                                                                                                                    </div>)}
                                                                                                            </Draggable>
                                                                                                        )
                                                                                                    } else {
                                                                                                        let qnInfo = questionNumberInfo.filter(questionInfo => questionInfo.questionIndex === index)

                                                                                                        popInitialSelectMenuItems(question.type)

                                                                                                        return (
                                                                                                            <Draggable
                                                                                                                key={question._id}
                                                                                                                draggableId={question._id}
                                                                                                                index={index}
                                                                                                                isDragDisabled={isDragAndDropDisabled}>
                                                                                                                {(provided) => (
                                                                                                                    <div
                                                                                                                        ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                                                                                        <MobileQuestionContainer
                                                                                                                            key={index} {...getQuestionProps(question, index, qnInfo, userId, isMobile, setHideBottomBar, refCardsContainer)}
                                                                                                                            initialImage={popInitialSelectMenuItems(question.type)}
                                                                                                                            addMissingOptionValuesToMCQs={addMissingOptionValuesToMCQs}
                                                                                                                            refSavedData={refSavedQuizData}
                                                                                                                            formData={quizData}/>
                                                                                                                    </div>)}
                                                                                                            </Draggable>
                                                                                                        )
                                                                                                    }
                                                                                                })()
                                                                                            )
                                                                                        }
                                                                                    </div>
                                                                                </div>)}
                                                                        </Droppable>
                                                                    </DragDropContext>
                                                                </div>
                                                            )
                                                        })()
                                                    }
                                                </div>
                                            </div>
                                        </>
                                    )
                                    if (currentContainerTitle === PreviewMenuItem.getTitle()) return (
                                        <>
                                            <PreviewContainer formData={previewData}/>
                                        </>
                                    )
                                    if (currentContainerTitle === SettingsMenuItem.getTitle()) return (
                                        <div className='builder-wrapper' style={{gridTemplateColumns: '100%'}}>
                                            <QuizSettingsContainer updateContainer={updateContainer}
                                                                   showThemesContainer={showThemesContainer}
                                                                   updateSetting={updateSetting}
                                                                   submitSettings={submitQuiz}
                                                                   formData={quizData}
                                                                   currentContainerTitle={currentContainerTitle}
                                            />
                                        </div>
                                    )
                                    if (currentContainerTitle === "ThemesContainer") return (
                                        <div className='builder-wrapper'
                                             style={{gridTemplateColumns: '100%'}}>
                                            <ThemesContainer chosenTheme={quizData.theme}
                                                             changeContainer={showSettingsContainer}
                                                             updateContainer={updateContainer}
                                                             updateSetting={updateSetting}
                                                             currentContainerTitle={currentContainerTitle}
                                            />
                                        </div>
                                    )
                                })()
                            }
                            {
                                (() => {
                                    if (!hideBottomBar) return (
                                        <BottomBar BottomBarMenuItems={builderMenuItems}
                                                   isCenterFABPresent={currentContainerTitle === BuilderMenuItem.getTitle()}
                                                   disableCenterFab={quizData?.response_count > 0}
                                                   centerFABClickFunction={(e) => {
                                                       if (quizData?.response_count > 0) {
                                                           dispatch({
                                                               type: BUILDER_ACTION_TYPE.SET_SHOW_QUIZ_ACTION_BLOCKED_POP_UP,
                                                               payload: true
                                                           })
                                                       } else {
                                                           setShowMobileQTPopUpContainer(true)
                                                           handleOutsideClick()
                                                           ShowQuestionTypesMenuFromAddQuestion(e)
                                                       }
                                                   }}
                                                   doNavigation={doNavigation}
                                                   doPopupAction={submitQuiz}
                                                   updateContainer={updateContainer}
                                        />
                                    )
                                })()
                            }
                            {
                                (() => {
                                        if (showUnapprovedFormDialog) return unapprovedModalDialog(quizData, userId, BUILDER_TYPE.QUIZ, () => {
                                            setShowUnapprovedFormDialog(false)
                                            dispatch({type: APP_DASHBOARD_ACTION_TYPE.SET_SHOW_QUIZ})
                                            dispatch({type: APP_ACTION_TYPE.SET_CURRENT_PAGE, payload: Pages.dashboard})
                                        })
                                    }
                                )()
                            }
                            {
                                (() => {
                                        if (showEditRightsRemoved)
                                            return editRightsRemovedDialog(BUILDER_TYPE.QUIZ, () => {
                                                setShowEditRightsRemoved(false)
                                                dispatch({
                                                    type: APP_ACTION_TYPE.SET_CURRENT_PAGE,
                                                    payload: Pages.dashboard
                                                })
                                                doNavigation(SurveyHeartMenuItem)
                                            })
                                    }
                                )()
                            }
                        </div>}
                    {
                        (() => {
                                if (showModalDialog)
                                    return (
                                        <div>
                                            <ModalDialog
                                                header={refModalTitle.current}
                                                body={refModalBody.current}
                                                buttons={refModalDialogButtons.current}
                                            />
                                        </div>
                                    )
                            }
                        )()
                    }
                    {showQuizActionBlockedPopUp ? <ModalDialog header={t(translationKeys.action_blocked).toUpperCase()}
                                                               body={[t(translationKeys.action_blocked_alert)]}
                                                               buttons={[
                                                                   {
                                                                       text: t(translationKeys.ok),
                                                                       action:
                                                                           () => {
                                                                               dispatch({
                                                                                   type: BUILDER_ACTION_TYPE.SET_SHOW_QUIZ_ACTION_BLOCKED_POP_UP,
                                                                                   payload: false
                                                                               })
                                                                           }
                                                                   }
                                                               ]}/> : null}
                    {showQuizSubmitPopUp ? <ModalDialog header={t(translationKeys.submit).toUpperCase()}
                                                        body={[t(translationKeys.quiz_edit_submit_alert)]}
                                                        buttons={[
                                                            {
                                                                text: t(translationKeys.cancel).toUpperCase(),
                                                                action:
                                                                    () => {
                                                                        setShowQuizSubmitPopUp(false)
                                                                    }
                                                            },
                                                            {
                                                                text: t(translationKeys.continue).toUpperCase(),
                                                                action:
                                                                    () => {
                                                                        submitQuiz()
                                                                        setShowQuizSubmitPopUp(false)
                                                                    }
                                                            }
                                                        ]}/> : null}
                    {showUnableToSubmitPopUp ? <ModalDialog header={t(translationKeys.unable_to_submit).toUpperCase()}
                                                            imageIcon={warningIcon}
                                                            body={[t(translationKeys.unable_to_submit_alert_line1), t(translationKeys.unable_to_submit_alert_line2)]}
                                                            buttons={[
                                                                {
                                                                    text: t(translationKeys.cancel).toUpperCase(),
                                                                    action:
                                                                        () => {
                                                                            setShowUnableToSubmitPopUp(false)
                                                                        }
                                                                },
                                                                {
                                                                    text: t(translationKeys.exit).toUpperCase(),
                                                                    action:
                                                                        () => {
                                                                            doNavigation(SurveyHeartMenuItem)
                                                                            setShowUnableToSubmitPopUp(false)
                                                                        }
                                                                }
                                                            ]}/> : null}
                    {showPremiumPopUp ? <ModalDialog header={"PREMIUM FEATURE"}
                                                     body={["Kindly upgrade the premium version through our Android App to access this feature."]}
                                                     buttons={
                                                         [
                                                             {
                                                                 text: t(translationKeys.ok).toUpperCase(),
                                                                 action: () => {
                                                                     dispatch({
                                                                         type: BUILDER_ACTION_TYPE.SET_SHOW_PREMIUM_POP_UP,
                                                                         payload: false
                                                                     })
                                                                 }
                                                             }
                                                         ]
                                                     }
                    /> : null}
                    {showLoader ? <Loader height='100%' width='100%' left='0px'/> : null}
                </div>
            )

            // ************************** Desktop Version Starts ************************** //
            return (
                <div className='app-container'>
                    <LeftNavigator
                        LeftNavigatorMenuItems={[...builderMenuItems, new PopupMenuItem(3, t(translationKeys.submit), new MenuIconInfo("submit", 'submit_grey.svg'), submitQuiz)]}
                        doNavigation={handleAppIconClick}
                        doPopupAction={doPopupAction}
                        updateContainer={updateContainer}
                        hideStorageBand={true}
                    />
                    <div className='body-container'>
                        {
                            (() => {
                                return (
                                    <>
                                        <div className='builder-wrapper'
                                             style={{width: showPreviewPane ? "62%" : "94%"}}>
                                            <div className='builder-box1'
                                                 style={{
                                                     display: currentContainerTitle === BuilderMenuItem.getTitle() ? null : 'none'
                                                 }}
                                            >
                                                <div className='form-builder-body-container'>
                                                    <div className="builder-container" id="builder-container">
                                                        <div className="builder-question-container"
                                                             id="builder-question-container">
                                                            <TitleSectionComponent
                                                                lastEditedBy={quizData?.last_edited_by}
                                                                time={quizData?.date_edited}
                                                                {...getTitleProps()}
                                                            />
                                                            {
                                                                (() => {
                                                                    if (quizData?.pages[0].questions.length === 0) return (
                                                                        <>
                                                                            <div id='add-question-button'
                                                                                 className='builder-question-button'
                                                                                 style={quizData?.last_edited_by ? {top: "235px"} : null}
                                                                                 onClick={(e) => {
                                                                                     handleOutsideClick()
                                                                                     ShowQuestionTypesMenuFromAddQuestion(e)
                                                                                 }}>
                                                                                            <span
                                                                                                style={{
                                                                                                    fontSize: '26px',
                                                                                                    marginRight: '5px'
                                                                                                }}>+</span>
                                                                                <span>{t(translationKeys.add_question)}</span>
                                                                            </div>
                                                                            {
                                                                                (() => {
                                                                                    if (showQTsMenuFromAddQuestionButton) return (
                                                                                        <div ref={refPopUpContainer}>
                                                                                            <QuestionTypePopup
                                                                                                questionIndex={-1}/>
                                                                                        </div>
                                                                                    )
                                                                                })()
                                                                            }
                                                                        </>
                                                                    )
                                                                })()
                                                            }
                                                            {
                                                                (() => {
                                                                        if (showModalDialog)
                                                                            return (
                                                                                <div>
                                                                                    <ModalDialog
                                                                                        header={refModalTitle.current}
                                                                                        body={refModalBody.current}
                                                                                        buttons={refModalDialogButtons.current}
                                                                                    />
                                                                                </div>
                                                                            )
                                                                    }
                                                                )()
                                                            }
                                                        </div>
                                                    </div>
                                                    {
                                                        (() => {
                                                            if (quizData?.pages[0].questions.length > 0) return (
                                                                <div className='builder-forms-container'>
                                                                    <DragDropContext onDragStart={handleOnDragStart}
                                                                                     onDragEnd={handleOnDragEnd}>
                                                                        <Droppable droppableId="characters">
                                                                            {(provided) => (
                                                                                <div {...provided.droppableProps}
                                                                                     ref={provided.innerRef}
                                                                                     style={{
                                                                                         width: "100%",
                                                                                         height: "fit-content"
                                                                                     }}>
                                                                                    <div ref={refCardsContainer}
                                                                                         className="builder-cards-container">
                                                                                        {
                                                                                            quizData?.pages[0].questions.map((question, index) => (
                                                                                                () => {
                                                                                                    let questionGroup = getQuizQuestionGroup(QUIZ_QUESTION_TYPE[question.type])

                                                                                                    if (questionGroup === QUESTION_GROUPS.SECTION) {
                                                                                                        return (
                                                                                                            <div style={{marginBottom: quizData.pages[0].questions?.length - 1 === index ? "10vh" : null}}>
                                                                                                                <Draggable
                                                                                                                    key={question._id}
                                                                                                                    draggableId={question._id}
                                                                                                                    index={index}
                                                                                                                    isDragDisabled={isDragAndDropDisabled}>
                                                                                                                    {(provided) => (
                                                                                                                        <div
                                                                                                                            ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                                                                                            <TitleSectionComponent {...getSectionProps(question, index)}/>
                                                                                                                        </div>)}
                                                                                                                </Draggable>
                                                                                                            </div>
                                                                                                        )
                                                                                                    } else {
                                                                                                        let qnInfo = questionNumberInfo.filter(questionInfo => questionInfo.questionIndex === index)

                                                                                                        popInitialSelectMenuItems(question.type)

                                                                                                        return (
                                                                                                            <Draggable
                                                                                                                key={question._id}
                                                                                                                draggableId={question._id}
                                                                                                                index={index}
                                                                                                                isDragDisabled={isDragAndDropDisabled}>
                                                                                                                {(provided) => (
                                                                                                                    <div
                                                                                                                        ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                                                                                        <QuestionContainer {...getQuestionProps(question, index, qnInfo, userId, isMobile, setHideBottomBar)}
                                                                                                                                           refSavedData={refSavedQuizData}
                                                                                                                                           formData={quizData}/>
                                                                                                                    </div>)}
                                                                                                            </Draggable>
                                                                                                        )
                                                                                                    }
                                                                                                })()
                                                                                            )
                                                                                        }
                                                                                    </div>
                                                                                </div>)}
                                                                        </Droppable>
                                                                    </DragDropContext>
                                                                </div>
                                                            )
                                                        })()
                                                    }
                                                </div>
                                            </div>
                                            <QuizSettingsContainer updateContainer={updateContainer}
                                                                   showThemesContainer={showThemesContainer}
                                                                   updateSetting={updateSetting}
                                                                   submitSettings={submitQuiz}
                                                                   formData={quizData}
                                                                   currentContainerTitle={currentContainerTitle}
                                            />
                                            <ThemesContainer chosenTheme={quizData?.theme}
                                                             changeContainer={showSettingsContainer}
                                                             updateContainer={updateContainer}
                                                             updateSetting={updateSetting}
                                                             currentContainerTitle={currentContainerTitle}
                                            />
                                            {/*<div className='builder-box2'
                                                 style={{
                                                     display: currentContainerTitle === 'ThemeContainer' ? 'none' : null
                                                 }}>
                                                <PreviewContainer formData={quizData}/>
                                            </div>*/}
                                        </div>
                                    </>
                                )
                            })()
                        }
                        {
                            (() => {
                                if (shareMenuIsVisible) return (
                                    <div>
                                        <PopUpContainer children={
                                            <ShareCard isQuiz={true}
                                                       formId={refQuizID.current}
                                                       isShowSharePopUp={shareScreenVisibilityHandler}
                                                       webFirstFeatures={refFeaturesInWebAppOnly.current}
                                                       passcode={quizData?.setting?.passcode}
                                            />
                                        }/>
                                    </div>
                                )
                            })()
                        }
                        {
                            (() => {
                                    if (showUnapprovedFormDialog) return unapprovedModalDialog(quizData, userId, BUILDER_TYPE.QUIZ)
                                }
                            )()
                        }
                        {
                            (() => {
                                    if (showEditRightsRemoved)
                                        return editRightsRemovedDialog(BUILDER_TYPE.QUIZ, () => {
                                            doNavigation(SurveyHeartMenuItem)
                                        })
                                }
                            )()
                        }
                        {
                            (() => {
                                if (showImportQuestionsDialog) return (
                                    <SearchAndFormsList/>
                                )
                            })()
                        }
                    </div>
                    {showQuizActionBlockedPopUp ? <ModalDialog header={t(translationKeys.action_blocked).toUpperCase()}
                                                               body={[t(translationKeys.action_blocked_alert)]}
                                                               buttons={[
                                                                   {
                                                                       text: t(translationKeys.ok),
                                                                       action:
                                                                           () => {
                                                                               dispatch({
                                                                                   type: BUILDER_ACTION_TYPE.SET_SHOW_QUIZ_ACTION_BLOCKED_POP_UP,
                                                                                   payload: false
                                                                               })
                                                                           }
                                                                   }
                                                               ]}/> : null}
                    {showQuizSubmitPopUp ? <ModalDialog header={t(translationKeys.submit).toUpperCase()}
                                                        body={[t(translationKeys.quiz_edit_submit_alert)]}
                                                        buttons={[
                                                            {
                                                                text: t(translationKeys.cancel).toUpperCase(),
                                                                action:
                                                                    () => {
                                                                        setShowQuizSubmitPopUp(false)
                                                                    }
                                                            },
                                                            {
                                                                text: t(translationKeys.continue).toUpperCase(),
                                                                action:
                                                                    () => {
                                                                        submitQuiz()
                                                                        setShowQuizSubmitPopUp(false)
                                                                    }
                                                            }
                                                        ]}/> : null}
                    {showUnableToSubmitPopUp ? <ModalDialog header={t(translationKeys.unable_to_submit).toUpperCase()}
                                                            imageIcon={warningIcon}
                                                            body={[t(translationKeys.unable_to_submit_alert_line1), t(translationKeys.unable_to_submit_alert_line2)]}
                                                            buttons={[
                                                                {
                                                                    text: t(translationKeys.cancel).toUpperCase(),
                                                                    action:
                                                                        () => {
                                                                            setShowUnableToSubmitPopUp(false)
                                                                        }
                                                                },
                                                                {
                                                                    text: t(translationKeys.exit).toUpperCase(),
                                                                    action:
                                                                        () => {
                                                                            doNavigation(SurveyHeartMenuItem)
                                                                            setShowUnableToSubmitPopUp(false)
                                                                        }
                                                                }
                                                            ]}/> : null}
                    {showPremiumPopUp ? <ModalDialog header={"PREMIUM FEATURE"}
                                                     body={["Kindly upgrade the premium version through our Android App to access this feature."]}
                                                     buttons={
                                                         [
                                                             {
                                                                 text: t(translationKeys.ok).toUpperCase(),
                                                                 action: () => {
                                                                     dispatch({
                                                                         type: BUILDER_ACTION_TYPE.SET_SHOW_PREMIUM_POP_UP,
                                                                         payload: false
                                                                     })
                                                                 }
                                                             }
                                                         ]
                                                     }
                    /> : null}
                    {showLoader ? <Loader height='100%' width='100%' left='0px'/> : null}
                    {builderToastMessage ? <Message width={'250px'} message={builderMessage}/> : null}
                </div>
            )
        })()
    )
}

export default QuizBuilderContainer
