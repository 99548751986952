import React, {useState} from 'react'
import AwsThemeCard from "./AwsThemeCard";
import AddImageCard from "./AddThemeCard";
import UploadImageContainer from "./UploadImageContainer";
import {AWS_FOLDER_NAMES, AWS_UPLOAD_TYPE, deletePhoto} from "../utils/aws";
import {deleteImagePathInUserFile} from "../utils/prepare-aws-user-json";
import Loader from "../../../shared/components/Loader";
import PopUpContainer from "../../../shared/components/PopUpContainer";
import ModalDialog from "../../../shared/components/ModalDialog";
import {translationKeys} from "../../../localizations/translationKeys-localization";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {removeDeletedQuestionAttachment, removeDeletedTheme} from "../../../shared/utils/sash-v2-api";
import {removeDeletedQuestionAttachmentQuiz, removeDeletedThemeQuiz} from "../../../shared/utils/quiz-api-services";
import {QUESTION_TYPE} from "../../../shared/utils/constants";
import {APP_ACTION_TYPE} from "../../reducers/appReducer";
import {PREMIUM_ACTION_TYPE} from "../../reducers/premiumReducer";

const IMAGE_TYPE = {
    DEFAULT: 0,
    AWS_IMAGE: 1
};

const AwsThemeContainer = (props) => {

    const {t} = useTranslation()
    const [lastClickedImage, setLastClickedImage] = useState(props.chosenTheme)
    const [showDeleteAlert, setShowDeleteAlert] = useState(false)
    const [deletableImage, setDeletableImage] = useState('')
    const [showLoader, setShowLoader] = useState(false)
    const userId = useSelector(state => state.userReducer?.userId)
    const formData = useSelector(state => state.appReducer.formData)
    const dispatch = useDispatch()
    const currentUserId = useSelector(state => state.userReducer?.userId)
    const encodedUserId = btoa(currentUserId);


    const handleDeletePopUp = (boolean, deletableImageFile, index) => {
        if (index < props.maxLimit || !index) {
            setShowDeleteAlert(boolean)
            setDeletableImage(deletableImageFile)
        } else {
            dispatch({type: PREMIUM_ACTION_TYPE.SET_SHOW_UPGRADE_PLAN_POP_UP, payload: true})
        }
    }

    const handleDeleteImage = (imageName) => {
        handleDeletePopUp(false);
        setShowLoader(true)
        const splittedThemeUrlArray = imageName.split('/');
        const themeName = splittedThemeUrlArray[splittedThemeUrlArray.length - 1];
        if (props.AWSUploadType === AWS_UPLOAD_TYPE.THEME) {
            deletePhoto(AWS_FOLDER_NAMES.THEMES_FOLDER, themeName, () => {
                deleteImagePathInUserFile(props.AWSUploadType, userId, imageName, () => {
                    if (lastClickedImage === imageName) {
                        //set classic as selected theme.
                        props.updateChosenTheme('classic_theme');
                    }
                    setShowLoader(false)
                    removeDeletedTheme(imageName, userId, () => {
                    }, (err) => console.log(err))
                    removeDeletedThemeQuiz(imageName, () => {
                    }, (err) => console.log(err))
                });
            });
        } else if (props.AWSUploadType === AWS_UPLOAD_TYPE.FORM) {
            deletePhoto(AWS_FOLDER_NAMES.IMAGES_FOLDER, themeName, () => {
                deleteImagePathInUserFile(props.AWSUploadType, userId, imageName, () => {
                    setShowLoader(false);
                    if (formData) {
                        const updatedFormData = {...formData}
                        updatedFormData.pages[0].questions.map((question) => {
                            if (question?.attachment?.image_url === imageName) {
                                delete question.attachment
                            }
                            if (question.type === QUESTION_TYPE.MULTIPLE_CHOICE.key || question.type === QUESTION_TYPE.CHECKBOX_CHOICE.key) {
                                question.choices.map((option) => {
                                    if (option?.image_attachment === imageName) {
                                        option.image_attachment = ''
                                    }
                                })
                            }
                        })
                        dispatch({type: APP_ACTION_TYPE.SET_FORM_DATA, payload: updatedFormData})
                    }
                    removeDeletedQuestionAttachment(imageName, userId, () => {
                    }, (err) => console.log(err))
                    removeDeletedQuestionAttachmentQuiz(imageName, () => {
                    }, (err) => console.log(err))
                });
            });
        }
    }

    const handleClick = (imageFileName, index) => {
        if (index < props.maxLimit) {
            if (lastClickedImage !== imageFileName) {
                let imageType;

                if (imageFileName === 'xxxclassic_theme') {
                    imageType = IMAGE_TYPE.DEFAULT
                } else {
                    imageType = IMAGE_TYPE.AWS_IMAGE
                }
                // console.log([imageFileName, imageType]);

                //for updating the tick mark
                setLastClickedImage(imageFileName)
            }
            props.showBottomBarWhenImagesContainerClosed ? props.showBottomBarWhenImagesContainerClosed() : null;
        } else {
            dispatch({type: PREMIUM_ACTION_TYPE.SET_SHOW_UPGRADE_PLAN_POP_UP, payload: true})
        }
    }

    const awsThemeCards = props.themeImages.map(
        (imageData, index) => {
            if (index < props.maxLimit) {
                return (
                    <AwsThemeCard key={imageData}
                                  image={imageData}
                                  handleClick={(imageFile) => handleClick(imageFile, index)}
                                  lastClickedImage={lastClickedImage}
                                  updateChosenTheme={props.updateChosenTheme}
                                  deletePopUp={(boolean, deletableImageFile) => handleDeletePopUp(boolean, deletableImageFile, index)}
                                  hideCheck={props.hideCheck}
                    />
                )
            } else {
                return (
                    <div style={{filter: "opacity(0.5)"}}>
                        <AwsThemeCard key={imageData}
                                      image={imageData}
                                      handleClick={(imageFile) => handleClick(imageFile, index)}
                                      lastClickedImage={lastClickedImage}
                                      deletePopUp={(boolean, deletableImageFile) => handleDeletePopUp(boolean, deletableImageFile, index)}
                                      hideCheck={props.hideCheck}
                        />
                    </div>
                )
            }
        });
    if ((props.openUploadState
        && props.themeImages.length > 0
        && props.themeImages.length < props.maxLimit) || (props.openUploadState
        && props.themeImages.length > 0 && encodedUserId === 'dGVtcGxhdGVzQHN1cnZleWhlYXJ0LmNvbQ==')) {
        return (
            <div className='aws-theme-container'>
                <AddImageCard openUploadPopUp={props.openUploadPopUp} maxLimit={props.maxLimit}/>
                {awsThemeCards}
                <PopUpContainer>
                    <UploadImageContainer
                        showBottomBarWhenImagesContainerClosed={props.showBottomBarWhenImagesContainerClosed}
                        openUploadPopUp={props.openUploadPopUp}
                        updateThemesList={props.updateThemesList}
                        uploadType={AWS_UPLOAD_TYPE.THEME}
                    />
                </PopUpContainer>
            </div>
        )
    } else {
        return (
            <>
                <div id={'default-theme-container'} className='default-theme-container'>
                    <AddImageCard openUploadPopUp={props.openUploadPopUp}
                                  maxLimit={props.maxLimit}
                                  doThemeLimitCheck={props.doThemeLimitCheck}/>
                    {awsThemeCards}
                    {showDeleteAlert ?
                        <ModalDialog
                            header={t(translationKeys.delete_image)}
                            body={[t(translationKeys.delete_this_image_will_not_be_available_any_forms_or_quizzes_that_using)]}
                            buttons={
                                [
                                    {
                                        text: t(translationKeys.cancel),
                                        action: () => handleDeletePopUp(false)
                                    },
                                    {
                                        text: t(translationKeys.delete),
                                        action: () => {
                                            handleDeleteImage(deletableImage)
                                        }
                                    }
                                ]
                            }
                        /> : null}
                </div>
                {showLoader ? <Loader height='100%' width='100%' left='0px'/> : null}
            </>
        )
    }
}

export default AwsThemeContainer