// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Ok33O97tlEIsmLWVKIBpA {\n    width: 100%;\n    height: 100%;\n    overflow-x: auto;\n}\n\n.Ok33O97tlEIsmLWVKIBpA::-webkit-scrollbar {\n    /*display: none;*/\n}\n\n::-webkit-scrollbar {\n    width: 5px;\n}\n\n::-webkit-scrollbar-track {\n    background: #f1f1f1;\n}\n\n::-webkit-scrollbar-thumb {\n    background: #888;\n}\n\n::-webkit-scrollbar-thumb:hover {\n    background: #555;\n}\n\n._1E_8hcp2a6demKigxOGhfe {\n    width: 100%;\n    height: 8%;\n    color: #FFFFFF;\n    display: flex;\n    justify-content: space-between;\n    background-color: rgba(0, 0, 0, 0.6);\n}\n\n._15oPxh-rGYcOprFlvx7g8K {\n    display: flex;\n    font-size: 20px;\n    align-items: center;\n}\n._15oPxh-rGYcOprFlvx7g8K img {\n    margin: 15px;\n}\n\n@media screen and (max-width: 600px) {\n    .Ok33O97tlEIsmLWVKIBpA {\n        height: 100vh;\n    }\n\n    ._1E_8hcp2a6demKigxOGhfe {\n        height: 120px;\n        display: block;\n    }\n\n    ._15oPxh-rGYcOprFlvx7g8K {\n        margin: 10px 0 0 20px;\n        width: 81%;\n        height: 50px;\n        font-size: 16px;\n    }\n\n    ._15oPxh-rGYcOprFlvx7g8K img {\n        margin: 0 0 25px 0;\n        width: 20px;\n    }\n}\n", ""]);
// Exports
exports.locals = {
	"form_page_container": "Ok33O97tlEIsmLWVKIBpA",
	"abuse_warning": "_1E_8hcp2a6demKigxOGhfe",
	"warning_message": "_15oPxh-rGYcOprFlvx7g8K"
};
module.exports = exports;
