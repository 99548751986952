import React, {useRef, useState} from 'react'
import MaterialButton from "../../../shared/components/MaterialButton";
import ColumnPopUpContainer from "./ColumnPopUpContainer";
import {ButtonPopUps} from "./TabularButtonsContainer";
import {isMobileOnly} from "react-device-detect";
import {translationKeys} from "../../../localizations/translationKeys-localization";
import {t} from "i18next";

const ColumnContainer = props => {
    const [columnButtonText, setColumnButtonText] = useState(t(translationKeys.columns_all));
    const columnContainerRef = useRef(null)
    const showColumnPopUp = () => props.setShowPopUp(ButtonPopUps.COLUMN);

    const closePopUp = () => {
        props.setShowPopUp(null);
    };

    const handleOutSideClick = e => {
        if (columnContainerRef.current && !columnContainerRef.current.contains(e.target)) {
            closePopUp();
            document.onmousedown = null;
        }
    };

    //Column Button Data
    const columnButtonData = {
        title: columnButtonText,
        bg_color: '#004ba0',
        fontSize: isMobileOnly ? '14px' : '16px',
        customButtonContainerStyle: {
            height: isMobileOnly ? 'auto' : null,
            padding: isMobileOnly ? '0 6px 0 8px' : null
        },
        imageFileName: isMobileOnly ? '' : 'columns.svg'
    }
    return (
        <div style={{position: 'relative'}} ref={columnContainerRef}>
            <MaterialButton id={'column-container-button'} data={columnButtonData}
                            handleClick={showColumnPopUp}/>
            {props.showPopUp === ButtonPopUps.COLUMN ?
                <ColumnPopUpContainer questionsData={props.questionsData}
                                      questionsLength={props.questionsData.length}
                                      columnQuestionIndexArray={props.columnQuestionIndexArray}
                                      setColumnQuestionIndexArray={props.setColumnQuestionIndexArray}
                                      setShowPopUp={props.setShowPopUp}
                                      initialColumnButtonText={columnButtonText}
                                      setInitialColumnButtonText={setColumnButtonText}
                                      handleOutSideClick={handleOutSideClick}
                                      closePopUp={closePopUp}
                                      closePreviouslyOpenedCard={props.closePreviouslyOpenedCard}
                                      previouslyOpenedCardCloseFunction={props.previouslyOpenedCardCloseFunction}
                /> : null}
        </div>
    )
}

export default ColumnContainer