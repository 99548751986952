// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_AT_RULE_IMPORT_0___ = require("-!../../../node_modules/css-loader/dist/cjs.js!../../../node_modules/@material/list/dist/mdc.list.min.css");
var ___CSS_LOADER_AT_RULE_IMPORT_1___ = require("-!../../../node_modules/css-loader/dist/cjs.js!../../../node_modules/@material/menu-surface/dist/mdc.menu-surface.css");
var ___CSS_LOADER_AT_RULE_IMPORT_2___ = require("-!../../../node_modules/css-loader/dist/cjs.js!../../../node_modules/@material/menu/dist/mdc.menu.min.css");
var ___CSS_LOADER_AT_RULE_IMPORT_3___ = require("-!../../../node_modules/css-loader/dist/cjs.js!../../../node_modules/@material/select/dist/mdc.select.min.css");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_1___);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_2___);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_3___);
// Module
exports.push([module.id, ".mdc-select {\n    --mdc-theme-primary: #1976d2;\n    --mdc-theme-secondary: #FFF;\n    background-color: #FFF;\n}\n\n.mdc-list, .mdc-select__selected-text {\n    font-family: 'Nunito Sans', Sans-serif, sans-serif;\n}\n\n.mdc-select-override {\n    width: 250px;\n}\n\n.demo-width-class {\n    width: 400px;\n}\n\n.mdc-list-image {\n    float: left;\n    padding-left: 10px;\n    padding-right: 10px;\n    padding-top: 10px;\n    height: 25px;\n    width: 20px;\n}\n\n.mdc-list-item-override {\n    padding-left: 0 !important;\n}\n\n.mdc-select__anchor-override {\n    display: flex !important;\n    justify-content: center !important;\n    align-items: center !important;\n}\n\n.mdc-select-anchor-img {\n    padding-right: 10px;\n}\n\n.mdc-select--outlined .mdc-select__anchor {\n    align-items: center;\n}\n\n.mdc-select:not(.mdc-select--disabled).mdc-select--focused .mdc-floating-label {\n    color: var(--mdc-theme-primary, rgba(98, 0, 238, 0.87));\n}\n", ""]);
// Exports
module.exports = exports;
