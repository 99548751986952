import React, {useEffect, useState} from 'react'
import {LinearScaleStartFromZero} from "./shared/LinearScaleStartFromZero"
import scale_down_black from "../../../../public/images/icons/scale_down_black.svg"
import scale_down_white from "../../../../public/images/icons/scale_down_white.svg"
import scale_up_black from "../../../../public/images/icons/scale_up_black.svg"
import scale_up_white from "../../../../public/images/icons/scale_up_white.svg"
import {translationKeys} from "../../../localizations/translationKeys-localization"
import {t} from "i18next"
import MaterialTextField from "../../../shared/components/MaterialTextField"
import {LINEAR_SCALE_LABEL_TYPES} from "../../../shared/utils/constants"
import {useDispatch, useSelector} from "react-redux"
import useLinearScaleMgr from "../../hooks/useLinearScaleMgr"
import {APP_ACTION_TYPE} from "../../reducers/appReducer"
import linearScaleCircle from "../../../../public/images/icons/builder/linear_scale_circle.svg"

const LinearScaleMobilePopup = (props) => {
    const reduxDispatch = useDispatch()

    const {doAddScaleValue, doRemoveScaleValue, updateLabel} = useLinearScaleMgr()

    const formData = useSelector(state => state.appReducer.formData)
    const question = formData.pages[0].questions[props.index]

    const [addShouldBeEnabled, setAddShouldBeEnabled] = useState(false)
    const [deleteShouldBeEnabled, setDeleteShouldBeEnabled] = useState(false)
    const [startAddingScaleValue, setStartAddingScaleValue] = useState(false)

    const [addClicked, setAddClicked] = useState(false)

    const firstLabelOnScale = question.choices[0].label
    const linearScaleLength = question.choices.length

    useEffect(() => {
        let startFromOne = parseInt(firstLabelOnScale) === 1

        let addShouldBeEnabledFunc = () => {
            if (startFromOne) {
                return linearScaleLength < 10
            } else {
                return linearScaleLength < 11
            }
        }
        let deleteShouldBeEnabledFunc = () => {
            return linearScaleLength > 3
        }

        setAddShouldBeEnabled(addShouldBeEnabledFunc())
        setDeleteShouldBeEnabled(deleteShouldBeEnabledFunc())
    }, [formData])

    useEffect(() => {
        if (startAddingScaleValue) {
            addScaleValue()
        }
    }, [startAddingScaleValue])

    const addScaleValue = () => {
        document.activeElement.blur()
        setDeleteShouldBeEnabled(false)
        setAddShouldBeEnabled(false)
        setAddClicked(true)
        doAddScaleValue(firstLabelOnScale, props.index)

        setTimeout(() => {
            // Give time for the new Scale Value to be added before setting the enabled state of add and delete buttons.
            setAddClicked(false)
            setAddShouldBeEnabled(() => {
                // Since a new Linear Scale Value was added, determine if adding another LS value should be disabled.
                const startFromZero = parseInt(firstLabelOnScale) === 0
                const choicesLength = question.choices?.length
                return startFromZero ? choicesLength < 11 : choicesLength < 10
            })
            setStartAddingScaleValue(false)

            setDeleteShouldBeEnabled(() => {
                // Since a Linear Scale Value was removed, determine if removing another LS value should be disabled.
                const startFromZero = parseInt(firstLabelOnScale) === 0
                const choicesLength = question.choices?.length
                return startFromZero ? choicesLength > 3 : choicesLength > 2
            })
        }, 500);
    }
    const removeLastScaleValue = () => {
        document.activeElement.blur()
        setDeleteShouldBeEnabled(false)
        setAddShouldBeEnabled(false)
        doRemoveScaleValue(props.index, linearScaleLength)

        setTimeout(() => {
            question.choices.pop()
            reduxDispatch({type: APP_ACTION_TYPE.SET_FORM_DATA, payload: formData})

            setAddShouldBeEnabled(() => {
                // Since a new Linear Scale Value was added, determine if adding another LS value should be disabled.
                const startFromZero = parseInt(firstLabelOnScale) === 0
                const choicesLength = question.choices?.length
                return startFromZero ? choicesLength < 11 : choicesLength < 10
            })

            setDeleteShouldBeEnabled(() => {
                // Since a Linear Scale Value was removed, determine if removing another LS value should be disabled.
                const startFromZero = parseInt(firstLabelOnScale) === 0

                // Add 1 to the choices Length because a new linear scale value is ABOUT to be added later in the code
                const choicesLength = question.choices?.length
                return startFromZero ? choicesLength > 3 : choicesLength > 2
            })
        }, 500);
    }

    return (
        <div style={{marginBottom: '10px'}}>
            <LinearScaleStartFromZero index={props.index}/>
            <div style={{paddingLeft: "10px", paddingRight: "10px"}}>
                <div style={{display: "flex", alignItems: "center"}}>
                    {/*NUMBERS Start*/}
                    <div className={"linear-scale-padded-row"} id={`ls-numbers-${props.index}`}>
                        {
                            (() => {
                                const cols = []

                                question.choices.map((lsValue, index) => {
                                    cols.push(
                                        <div key={index}
                                             className={`linear-scale-col ${addClicked && index === question.choices.length - 1 ? 'show-linear-scale-fade-in-on-add' : ''}`}
                                             id={`ls-numbers-child-${props.index}-${index}`}>
                                            {parseInt(firstLabelOnScale) === 0 ? index : index + 1}
                                        </div>
                                    )
                                })

                                return cols
                            })()
                        }
                    </div>
                    {/*NUMBERS End*/}
                </div>
                <div style={{display: "flex", alignItems: "center"}}>
                    <div className={"linear-scale-padded-row linear-scale-padded-row-border"}>
                        {/*CIRCLES Start*/}
                        {
                            (() => {
                                const cols = []

                                question.choices.map((lsValue, index) => {
                                    cols.push(
                                        <div key={index}
                                             className={`linear-scale-col ${addClicked && index === question.choices.length - 1 ? 'show-linear-scale-fade-in-on-add' : ''}`}
                                             id={`ls-circles-child-${props.index}-${index}`}>
                                            {<img alt={`linear-scale-circle-${props.index}-${index}`}
                                                  src={linearScaleCircle}/>}
                                        </div>
                                    )
                                })

                                return cols
                            })()
                        }
                        {/*CIRCLES End*/}
                    </div>
                </div>
            </div>
            <div style={{display: 'flex', justifyContent: 'space-evenly', marginTop: '10px'}}>
                {
                    (() => {
                        if (deleteShouldBeEnabled) return (
                            <div style={{width: '165px', borderRadius: '10px'}}
                                 className='builder-settings-button builder-settings-header-button-2'
                                 onClick={() => removeLastScaleValue()}
                            >
                                <img
                                    src={scale_down_white}
                                    alt={t(translationKeys.scale_down)}
                                />
                                <span className='builder-settings-header-text'
                                      style={{paddingLeft: '7px'}}>{t(translationKeys.scale_down)}</span>
                            </div>
                        )

                        return (
                            <div style={{width: '165px', borderRadius: '10px'}}
                                 className='mobile-builder-settings-disabled-button'
                            >
                                <img
                                    src={scale_down_black}
                                    alt={t(translationKeys.scale_down)}
                                />
                                <span className='builder-settings-header-text'
                                      style={{paddingLeft: '7px'}}>{t(translationKeys.scale_down)}</span>
                            </div>
                        )
                    })()
                }

                {
                    (() => {
                        if (addShouldBeEnabled) return (
                            <div style={{width: '165px', borderRadius: '10px'}}
                                 className='builder-settings-button builder-settings-header-button-2'
                                 onClick={() => {
                                     setStartAddingScaleValue(true)
                                 }}>
                                <span className='builder-settings-header-text'>{t(translationKeys.scale_up)}</span>
                                <img src={scale_up_white}
                                     alt={t(translationKeys.scale_up)}
                                />
                            </div>
                        )

                        return (
                            <div style={{width: '165px', borderRadius: '10px'}}
                                 className='mobile-builder-settings-disabled-button'
                            >
                                <span className='builder-settings-header-text'>{t(translationKeys.scale_up)}</span>
                                <img src={scale_up_black}
                                     alt={t(translationKeys.scale_up)}
                                />
                            </div>
                        )
                    })()
                }

            </div>
            <div>
                <div className='sh-text-field' style={{marginBottom: '0px'}}>
                    <MaterialTextField
                        id={`linear-scale-question-label-1`}
                        data={{
                            defaultValue: question.start_label
                        }}
                        placeholder={t(translationKeys.left_label_optional)}
                        onChange={value => updateLabel(props.index, LINEAR_SCALE_LABEL_TYPES.START, value)}
                        maxLength={100}
                        noLabel={true}
                        hideHelpLine={true}
                        forceUpdateRefVarFocus={false}
                        setForceUpdateRefVarFocus={null}
                    />
                </div>
                <div className='sh-text-field' style={{
                    display: linearScaleLength >= 5 ? 'block' : 'none',
                    marginBottom: '0px'
                }}>
                    <MaterialTextField
                        id={`linear-scale-question-label-2`}
                        data={{
                            defaultValue: question.center_label
                        }}
                        placeholder={t(translationKeys.center_label_optional)}
                        onChange={value => updateLabel(props.index, LINEAR_SCALE_LABEL_TYPES.CENTER, value)}
                        maxLength={100}
                        noLabel={true}
                        hideHelpLine={true}
                        forceUpdateRefVarFocus={false}
                        setForceUpdateRefVarFocus={null}
                    />
                </div>
                <div className='sh-text-field' style={{marginBottom: '0px'}}>
                    <MaterialTextField
                        id={`linear-scale-question-label-3`}
                        data={{
                            defaultValue: question.end_label
                        }}
                        placeholder={t(translationKeys.right_label_optional)}
                        onChange={value => updateLabel(props.index, LINEAR_SCALE_LABEL_TYPES.END, value)}
                        maxLength={100}
                        noLabel={true}
                        hideHelpLine={true}
                        forceUpdateRefVarFocus={false}
                        setForceUpdateRefVarFocus={null}
                    />
                </div>
            </div>
        </div>
    )
}

export default LinearScaleMobilePopup