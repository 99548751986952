import React from 'react'
import {CHART_TYPE} from "./SummaryQuestionCard";
import {isMobileOnly} from "react-device-detect";
import summary_result_active from '../../../../public/images/summary_result_active.svg'
import summary_result_inactive from '../../../../public/images/summary_result_inactive.svg'
import pie_chart_active from '../../../../public/images/pie_chart_active.svg'
import pie_chart_inactive from '../../../../public/images/pie_chart_inactive.svg'
import bar_chart_active from '../../../../public/images/bar_chart_active.svg'
import bar_chart_inactive from '../../../../public/images/bar_chart_inactive.svg'
import two_columns_three_rows_active from '../../../../public/images/two_columns_three_rows_active.svg'
import two_columns_three_rows_inactive from '../../../../public/images/two_columns_three_rows_inactive.svg'
import three_columns_active from '../../../../public/images/three_columns_active.svg'
import three_columns_inactive from '../../../../public/images/three_columns_inactive.svg'
import slider_rows_active from '../../../../public/images/slider_rows_active.svg'
import slider_rows_inactive from '../../../../public/images/slider_rows_inactive.svg'

const QuestionTitleBar = props => {
    const isGridType = props.isGridType;
    const isChoiceType = props.isChoiceType;
    const handleClick = props.handleClick;

    const getGridIcons = () => <div style={{display: isMobileOnly ? "none" : "flex"}}>
        <img className='chart-icons' alt='results' onScroll={e => e.stopPropagation()}
             src={(props.chartType === CHART_TYPE.TABULAR_CHART) ? two_columns_three_rows_active : two_columns_three_rows_inactive}
             onClick={() => handleClick(CHART_TYPE.TABULAR_CHART)}
        />
        <img className='chart-icons' alt='pie-chart' onScroll={e => e.stopPropagation()}
             src={(props.chartType === CHART_TYPE.GROUPED_BAR_CHART) ? three_columns_active : three_columns_inactive}
             onClick={() => handleClick(CHART_TYPE.GROUPED_BAR_CHART)}
        />
        <img className='chart-icons' alt='bar-chart' onScroll={e => e.stopPropagation()}
             src={(props.chartType === CHART_TYPE.STACKED_BAR_CHART) ? slider_rows_active : slider_rows_inactive}
             onClick={() => handleClick(CHART_TYPE.STACKED_BAR_CHART)}
        />
    </div>

    const getChartIcons = () => <div style={{display: "flex"}}>
        <img className='chart-icons' alt='results' onScroll={e => e.stopPropagation()}
             src={(props.chartType === CHART_TYPE.RESULT_CARD) ? summary_result_active : summary_result_inactive}
             onClick={() => handleClick(CHART_TYPE.RESULT_CARD)}
        />
        <img className='chart-icons' alt='pie-chart' onScroll={e => e.stopPropagation()}
             src={(props.chartType === CHART_TYPE.PIE_CHART) ? pie_chart_active : pie_chart_inactive}
             onClick={() => handleClick(CHART_TYPE.PIE_CHART)}
        />
        <img className='chart-icons' alt='bar-chart' onScroll={e => e.stopPropagation()}
             src={(props.chartType === CHART_TYPE.BAR_CHART) ? bar_chart_active : bar_chart_inactive}
             onClick={() => handleClick(CHART_TYPE.BAR_CHART)}
        />
    </div>

    return (
        <div id='question-title-container' className='question-title-container' style={{flexDirection: props.showChartIcons ? "row" : null}}>
            <p className='response-question-title'
               style={props.detailedView ? props.showChartIcons ? {width: isMobileOnly ? "100%" : '60%'} : null : props.showChartIcons ? {width: isMobileOnly ? "100%" : '60%', whiteSpace: isMobileOnly ? "break-spaces" : null} : null}>
                {props.index === -1 ? "Email Addresses" : (props.questionSNo + (props.isEmail ? 0 : 1)) + '. ' + props.questionTitle}</p>
            {
                (() => {
                    if (props.detailedView) {
                        if (!isMobileOnly && props.showChartIcons) {
                            if (isGridType) {
                                return getGridIcons()
                            } else {
                                return getChartIcons()
                            }
                        }
                    } else if (isGridType) {
                        return getGridIcons()
                    } else if (isChoiceType && props.showChartIcons && !isMobileOnly) {
                        return getChartIcons()
                    } else {
                        return null
                    }
                })()
            }
        </div>)
}

export default QuestionTitleBar