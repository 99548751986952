import MaterialSelectMenu from "../../../shared/components/MaterialSelectMenu";
import MaterialButton, {ButtonTypes} from "../../../shared/components/MaterialButton";
import React, {useRef} from "react";
import * as responseFilter from "../utils/response-filter";
import {isMobileOnly} from "react-device-detect";
import {QUESTION_TYPE} from "../../../shared/utils/constants";
import {translationKeys} from "../../../localizations/translationKeys-localization";
import {t} from "i18next";
import {useSelector} from "react-redux";

export const TextFilterType = {
    CONTAINS: t(translationKeys.contains),
    EQUALS: t(translationKeys.equal_to),
    NOT_EQUALS: t(translationKeys.not_equal_to)
}

export default function FilterByQuestionSection(props) {
    const InputTextElement = useRef();
    const lastAddedQuestionFilterObject = useRef(null)
    const selectedLanguage = useSelector(state => state.languageReducer.selectedLanguage)

    const getInitialQuestionId = () => {
        if (isMobileOnly && props.tempSelectedQuestionObjectRef?.current?.questionId) {
            return props.tempSelectedQuestionObjectRef.current.questionId
        } else if (lastAddedQuestionFilterObject.current?.questionId) {
            return lastAddedQuestionFilterObject.current.questionId
        } else {
            return Array.isArray(props.questionsData) && props.questionsData.length > 0 ? (props.questionsData[0].questionId) : null
        }
    }

    const getInitialQuestionTitle = () => {
        if (isMobileOnly && props.tempSelectedQuestionObjectRef?.current?.questionTitle) {
            return props.tempSelectedQuestionObjectRef.current.questionTitle
        } else if (lastAddedQuestionFilterObject.current?.questionTitle) {
            return lastAddedQuestionFilterObject.current.questionTitle
        } else {
            return Array.isArray(props.questionsData) && props.questionsData.length > 0 ? ('1. ' + props.questionsData[0].questionTitle) : null
        }
    }

    const getInitialFilterType = () => {
        if (isMobileOnly && props.tempSelectedQuestionObjectRef?.current?.type) {
            return props.tempSelectedQuestionObjectRef.current.type
        } else if (lastAddedQuestionFilterObject.current?.type) {
            return lastAddedQuestionFilterObject.current.type
        } else {
            return responseFilter.FILTER_TYPE.CONTAIN
        }
    }
    let questionFilterObject = {
        model: responseFilter.FILTER_MODEL.QUESTION,
        type: getInitialFilterType(),
        text: "",
        questionId: getInitialQuestionId(),
        questionTitle: getInitialQuestionTitle()
    }
    const setQuestionIdInFilterObject = (questionId, questionTitle) => {
        questionFilterObject.questionId = (questionId && questionId !== 0) ? questionId : '';
        questionFilterObject.questionTitle = questionTitle ? questionTitle : '';
        isMobileOnly ?
            props.tempSelectedQuestionObjectRef.current = {
                questionId: questionId,
                type: questionFilterObject.type,
                questionTitle: questionFilterObject.questionTitle
            } : null;
    }

    const setFilterModel = modelType => {
        questionFilterObject.type = modelType ? modelType : responseFilter.FILTER_TYPE.CONTAIN;
        isMobileOnly ?
            props.tempSelectedQuestionObjectRef.current = {
                questionId: questionFilterObject.questionId,
                type: modelType ? modelType : responseFilter.FILTER_TYPE.CONTAIN,
                questionTitle: questionFilterObject.questionTitle
            } : null;
    }
    let selectValues = [];
    for (let i = 0; i < props.questionsData.length; i++) {
        const questionData = props.questionsData[i];
        if (questionData.type !== QUESTION_TYPE.SECTION.key && questionData.type !== QUESTION_TYPE.FILE_UPLOAD.key && questionData.type !== QUESTION_TYPE.MULTIPLE_CHOICE_GRID.key && questionData.type !== QUESTION_TYPE.CHECKBOX_GRID.key) {
            selectValues.push({
                key: questionData.questionId,
                displayText: (selectValues.length + 1) + '. ' + questionData.questionTitle
            })
        }
    }
    const handleAddFilterClick = () => {
        if (InputTextElement.current.value.trim().length > 0) {
            let currentQuestionFilterObjectArray = props.questionFilterObjectRef.current ? props.questionFilterObjectRef.current.splice(0) : [];
            questionFilterObject.text = InputTextElement.current.value;
            currentQuestionFilterObjectArray.push(questionFilterObject);
            lastAddedQuestionFilterObject.current = questionFilterObject
            props.questionFilterObjectRef.current = currentQuestionFilterObjectArray;
            let filterObjectList = [props.timeFilterObject, ...currentQuestionFilterObjectArray];
            props.filteredArrayRef.current = responseFilter.getFilteredResponses(filterObjectList, props.summaryDataSet);
            props.setIsFilteredArrayChanged(!props.isFilteredArrayChanged)
            InputTextElement.current.value = '';
        }
    };
    return (
        <React.Fragment>
            <span id='filter-by-questions' className='filter-card-title'>{t(translationKeys.filter_by_question)}</span>
            <MaterialSelectMenu index={1}
                                selectedLanguage={selectedLanguage}
                                customStyle={{height: '35px', width: '98%'}}
                                initialValueToSelect={getInitialQuestionId()}
                                selectValues={selectValues}
                                selectionHandler={(x, value, displayText) => {
                                    setQuestionIdInFilterObject(value, displayText)
                                }}/>
            <div style={{padding: '15px 0'}}>
                <MaterialSelectMenu index={2}
                                    selectedLanguage={selectedLanguage}
                                    customStyle={{height: '35px', width: '70%', dropDownWidth: '100%'}}
                                    initialValueToSelect={questionFilterObject.type}
                                    selectValues={[
                                        {key: responseFilter.FILTER_TYPE.CONTAIN, displayText: TextFilterType.CONTAINS},
                                        {key: responseFilter.FILTER_TYPE.EQUAL, displayText: TextFilterType.EQUALS},
                                        {
                                            key: responseFilter.FILTER_TYPE.NOT_EQUAL,
                                            displayText: TextFilterType.NOT_EQUALS
                                        }]}
                                    selectionHandler={(x, value) => {
                                        setFilterModel(value)
                                    }}/>
            </div>
            <input ref={InputTextElement} className='filter-by-question-input-filter-text'
                   id={'filter-text-input'}
                   placeholder={t(translationKeys.filter_text)}/>
            <div className='add-filter-button-container'>
                <MaterialButton data={{
                    buttonType: ButtonTypes.CONTAINED,
                    fontSize: isMobileOnly ? '14px' : '16px',
                    title: t(translationKeys.add_filter)
                }} id={'add-filter-button'} handleClick={handleAddFilterClick}/>
            </div>
        </React.Fragment>
    )
}