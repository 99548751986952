// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_AT_RULE_IMPORT_0___ = require("-!../../../node_modules/css-loader/dist/cjs.js!../../../node_modules/@material/textfield/dist/mdc.textfield.min.css");
var ___CSS_LOADER_AT_RULE_IMPORT_1___ = require("-!../../../node_modules/css-loader/dist/cjs.js!../../../node_modules/@material/card/dist/mdc.card.css");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_1___);
// Module
exports.push([module.id, ".sh-text-field {\n    margin-bottom: 35px;\n    padding-top: 5px;\n    height: 38px;\n    width: 100%;\n}\n\n.sh-text-field .material-text-field {\n    --mdc-theme-primary: #1976d2;\n    --mdc-theme-secondary: #1976d2;\n    background-color: transparent;\n    padding-left: 0px;\n    padding-right: 0px;\n    margin-bottom: 10px;\n}\n\n.sh-text-field .material-text-field .mdc-text-field--filled {\n    background-color: transparent;\n}\n\n.sh-text-field .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input {\n    color: #a9a9a8;\n}\n\n.sh-text-field .mdc-text-field-helper-line {\n    padding-left: 0px;\n}\n\n.sh-text-field .mdc-card {\n    border-radius: 0px;\n}\n\n.sh-text-field .mdc-line-ripple::before {\n    border-bottom-color: #a9a9a8 !important;\n}\n\n.sh-text-field .material-text-field {\n    height: 41px;\n    padding-top: 12px;\n}\n\n.sh-text-field .mdc-text-field__input {\n    font-size: 20px;\n    padding-left: 3px;\n    color: #2e2e2e !important;\n    caret-color: #1976d2;\n}\n\n.sh-text-field label {\n    height: 36px;\n    padding-left: 3px;\n    width: 100%;\n}\n\n.sh-text-field .material-text-field .mdc-text-field--filled {\n    height: 38px;\n}\n\n.sh-text-field .mdc-text-field--filled::before {\n    height: 25px;\n}\n\n.valid-text {\n    display: block;\n}\n\n.invalid-text {\n    display: none;\n}\n\n@media screen and (max-width: 600px) {\n    .sh-text-field .mdc-text-field__input {\n        font-size: 16px;\n    }\n}\n", ""]);
// Exports
module.exports = exports;
