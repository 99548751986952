import React, {useRef} from "react";
import './../css/slider.css'
import MaterialSlider from "./MaterialSlider";
import {useSelector} from "react-redux";

const Slider = props => {
    const currentNumberInputRef = useRef(null);
    const selected = useSelector(state => state.languageReducer.selectedLanguage)
    const setCurrentValue = (value, sliderInstance) => {
        if (sliderInstance) {
            sliderInstance.current.getValue() === 0 ? sliderInstance.current.setValue(1) :
                sliderInstance.current.setValue(Math.abs(value ? value : 1));
        }
        props.setCurrentNumber(parseInt(value) > 0 ? parseInt(value) : 1)
    }

    return (
        <div id={'slicer-card'} className='individual-slider-container'>
            <div className='slider-card' style={{marginTop: props.isDetailedView ? '5px' : null}}>
                {/*previous button*/}
                <div className='adjacent-response-button' onClick={() => {
                    if (props.currentNumber > 1) {
                        props.setCurrentNumber(props.currentNumber - 1)
                    }
                }}>
                    <img style={{transform: (selected === 'fa' || selected === 'ar') ? 'rotate(270deg)' : 'rotate(90deg)'}} alt='previous-button'
                         src='/images/expand_more_blue.svg'/>
                </div>
                <div style={{
                    display: 'flex',
                    width: '80%',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column'
                }}>
                    <div className='individual-response-count-container'>
                        <input type='number' ref={currentNumberInputRef} className='current-response-number' min='1' max={props.maxCount}
                               value={props.currentNumber} onChange={(e) => {
                            if (e.target.value <= props.maxCount) {
                                props.setCurrentNumber(e.target.value !== "" ? e.target.value !== '0' ? Math.abs(parseInt(e.target.value)) : 1 : '');
                            } else {
                                props.setCurrentNumber(props.maxCount);
                            }
                        }} onBlur={(e) => {
                            if (e.target.value === '') {
                                props.setCurrentNumber(1);
                            }
                        }}/>
                        <span style={{margin: '0 10px'}}>of</span>
                        <span style={{margin: '0 0 0 5px'}}>{props.maxCount}</span>
                    </div>
                    {/*slider*/}
                    <MaterialSlider currentPosition={Math.abs(props.currentNumber)}
                                    sliderWidth={'90%'}
                                    handleChange={setCurrentValue}
                                    minPosition={0} maxPosition={props.maxCount}/>
                    {/*<input className='slider-input' type='range' min='0' max={props.maxCount}
                           value={Math.abs(props.currentNumber)} onChange={(e) => {
                        props.setCurrentNumber(parseInt(e.target.value) > 0 ? parseInt(e.target.value) : 1);
                    }}/>*/}
                </div>
                {/*Next button*/}
                <div className='adjacent-response-button' onClick={() => {
                    if (props.currentNumber < props.maxCount) {
                        props.setCurrentNumber(props.currentNumber ? props.currentNumber  + 1 : 1)
                    }
                }}>
                    <img style={{transform: (selected === 'fa' || selected === 'ar') ? 'rotate(90deg)' : 'rotate(270deg)'}} alt='next-button'
                         src='/images/expand_more_blue.svg'/>
                </div>
            </div>
        </div>
    )
}

export default Slider