import {useTranslation} from "react-i18next";
import {translationKeys} from "../../../localizations/translationKeys-localization";

export default function getChoiceAnswer(responseIndex, questionSummaryDataSet) {
    const {t} = useTranslation()
    let chosenChoiceLabel = questionSummaryDataSet.choiceTextAnswers[responseIndex];
    let answerLabel;

    if (questionSummaryDataSet.type) {
        //handling Check box question
        if (chosenChoiceLabel && Array.isArray(chosenChoiceLabel[0])) {
            chosenChoiceLabel.forEach(choice => {
                answerLabel = choice[0]?.length ? (answerLabel && answerLabel.length) ?
                    (answerLabel + ' * ' + (choice[1]?.length > 0 ? choice[0] : (choice[0] + ' (Others)')))
                    : ' * ' + (choice[1]?.length > 0 ? choice[0] : (choice[0] + ' (Others)')) : null
            })
        } else if (chosenChoiceLabel && Array.isArray(chosenChoiceLabel)) {
            //handling Multiple Choice, Star Rating and Drop Down type Questions
            answerLabel = chosenChoiceLabel[1]?.length > 0 ? chosenChoiceLabel[0] : (chosenChoiceLabel[0]?.length > 0 ? (chosenChoiceLabel[0] + ' (Others)') : '');
        }
    }
    // handling no answer
    return answerLabel = (answerLabel && answerLabel.length) ? answerLabel : t(translationKeys.no_answer)
}