import {APP_ACTION_TYPE} from "../reducers/appReducer";
import {useDispatch, useSelector} from "react-redux";
import {BUILDER_ACTION_TYPE} from "../reducers/builderReducer";
import store from "../store/appStore";

const useLogicJumpMgr = () => {

    const formData = useSelector(state => state.appReducer?.formData)
    const dispatch = useDispatch()

    //to add logic_jump_enabled to the question
    const addLogicJumpToQuestion = (questionIndex, checked) => {
        let updatedData = {...formData}
        updatedData.pages[0].questions[questionIndex].is_logic_enabled = checked

        dispatch({type: APP_ACTION_TYPE.SET_FORM_DATA, payload: updatedData})
        addLogicEnabledAtRootLevel();
    }

    const addLogicEnabledAtRootLevel = () => {
        let updatedData = {...formData}

        // Check if any question has is_logic_enabled set to true
        let isLogicEnabled;
        isLogicEnabled = updatedData.pages.some((page) =>
            page.questions.some((question) => question.is_logic_enabled === true)
        );

        // to Add is_logic_enabled to the root level if true
        updatedData.is_logic_enabled = isLogicEnabled;
        if (isLogicEnabled) {
            updatedData.setting.allow_summary_view = false
            updatedData.setting.show_estimated_time = false
            updatedData.setting.show_question_count = false
            updatedData.setting.is_shuffled = false

            dispatch({type: BUILDER_ACTION_TYPE.SET_IS_DRAG_AND_DROP_DISABLED, payload: true});
        }

        dispatch({type: APP_ACTION_TYPE.SET_FORM_DATA, payload: updatedData})
    }

    const removeLogicEnabledAtRootLevel = () => {
        let updatedData = {...formData}

        // Check if any question has is_logic_enabled set to true
        let isLogicEnabled;
        isLogicEnabled = updatedData.pages.some((page) =>
            page.questions.some((question) => question.is_logic_enabled === true)
        );

        // to Add is_logic_enabled to the root level if true
        updatedData.is_logic_enabled = isLogicEnabled;
        if (!isLogicEnabled) {
            dispatch({type: BUILDER_ACTION_TYPE.SET_IS_DRAG_AND_DROP_DISABLED, payload: false});
        }

        dispatch({type: APP_ACTION_TYPE.SET_FORM_DATA, payload: updatedData})
    }

    const removeLogic = (questionIndex) => {
        // //to remove all the logic_question_id from options
        let updatedData = {...formData}
        updatedData.pages[0].questions[questionIndex].choices.forEach(choice => {
            // Delete the logic_question_id from each choice
            delete choice.target_question_id;
        });

        dispatch({type: APP_ACTION_TYPE.SET_FORM_DATA, payload: updatedData})
        removeLogicEnabledAtRootLevel()
    }

    const selectTargetForTextQuestion = (questionIndex, targetValue) => {
        let updatedData = {...store.getState().appReducer.formData}
        updatedData.pages[0].questions[questionIndex].target_question_id = targetValue

        dispatch({type: APP_ACTION_TYPE.SET_FORM_DATA, payload: updatedData})
    }

    const addTargetQuestionId = (questionIndex, targetValue) => {
        let updatedData = {...formData}
        updatedData.pages[0].questions[questionIndex].logic_question_id = targetValue
        dispatch({type: APP_ACTION_TYPE.SET_FORM_DATA, payload: updatedData})
    }

    // for MCQ questions
    const addLogicQuestionId = (questionIndex, choiceIndex, targetValue) => {
        let updatedData = {...formData}
        updatedData.pages[0].questions[questionIndex].choices[choiceIndex].target_question_id = targetValue
        dispatch({type: APP_ACTION_TYPE.SET_FORM_DATA, payload: updatedData})
    }


    //To generate a unique 6 digit string for target_question_id, logic_question_id
    const generateUniqueString = () => Array.from({ length: 6 }, () => 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'[Math.floor(Math.random() * 62)]).join('');

    return {
        addLogicJumpToQuestion,
        selectTargetForTextQuestion,
        generateUniqueString,
        addLogicQuestionId,
        addTargetQuestionId,
        removeLogic
    }
}

export default useLogicJumpMgr