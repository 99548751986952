import React, {useState} from 'react';
import style from '../css/quiz-user-info.module.css'
import MaterialButton from "../../shared/components/MaterialButton";
import {isDarkTheme, isImageTheme} from "../utils/theme-color-helper";
import {InputComponent} from "./InputComponent";
import {updateUserInfoData, validateUserInfoData} from "../utils/user-info-data-helper";
import {FORM_SCREEN} from "../utils/utils";
import {SCREENS_ACTION_TYPE} from "../reducers/screensReducer";
import {useDispatch, useSelector} from "react-redux";
import {THEME_ACTION_TYPE} from "../reducers/themeReducer";

export const QuizUserInfoPopup = (props) => {
    const formData = useSelector(state => state.formDataReducer.formData)
    const themeColor = useSelector(state => state.themeReducer.themeColor)
    const userInfoData = useSelector(state => state.formDataReducer.userInfoData)
    const selectedLanguage = window?.localStorage?.getItem('i18nextLng')
    const dispatch = useDispatch()
    const primaryColor = isImageTheme(formData?.theme) ? themeColor?.primaryColor
        : isDarkTheme(formData.theme) ? '#212121' : '#0D47A1';
    const [isErrorInfo, setErrorInfo] = useState(false);
    if (formData?.quiz_data.user_info) {
        function getUserInfoInputElement(userInfo, index) {
            return <>
                {userInfo ?
                    <InputComponent
                        theme={formData.theme}
                        maxLength='100'
                        type='text'
                        placeHolder={userInfo}
                        onBlur={(element) => {
                            updateUserInfoData(index, element.target.value, userInfoData)
                        }}
                    /> : null}
            </>;
        }

        const handleCancelClickEvent = () => {
            if (formData.quiz_data.user_info.user_info_1) {
                updateUserInfoData(0, '', userInfoData)
            }
            if (formData.quiz_data.user_info.user_info_2) {
                updateUserInfoData(1, '', userInfoData)
            }
            if (formData.quiz_data.user_info.user_info_3) {
                updateUserInfoData(2, '', userInfoData)
            }
            dispatch({type: SCREENS_ACTION_TYPE.SET_FORM_SCREEN, payload: FORM_SCREEN.WELCOME_PAGE})
            if (props.setSelectedPreviewScreen) {
                props.setSelectedPreviewScreen(FORM_SCREEN.WELCOME_PAGE);
            }
            dispatch({type: THEME_ACTION_TYPE.SET_THEME_IMAGE_BLUR, payload: 0})
        };

        const titleStyle = {
            width: '100%',
            textAlign: 'center',
            color: isDarkTheme(formData.theme) ? "#ffffff" : '#0D47A1'
        };
        const errorStyle = {
            color: '#f44336',
            display: isErrorInfo ? 'block' : 'none',
            marginLeft: '10px'
        };
        const cancelButtonData = {
            title: "Cancel",
            textTransform: "uppercase",
            fontSize: "14px",
            bg_color: isDarkTheme(formData.theme) ? "#000000" : '#eeeeee',
            customButtonContainerStyle: {
                color: isDarkTheme(formData.theme) ? "#ffffff" : '#0D47A1',
                marginRight: (selectedLanguage === 'fa' || selectedLanguage === 'ar') ? 0 : '20px',
                marginLeft: (selectedLanguage === 'fa' || selectedLanguage === 'ar') ? '20px' : 0
            }
        };
        const startButtonData = {
            title: "Start Quiz",
            textTransform: "uppercase",
            fontSize: "14px",
            bg_color: isDarkTheme(formData.theme) ? "#000000" : '#0D47A1',
            customButtonContainerStyle: {
                color: '#ffffff'
            }
        };

        const handleStartClickEvent = () => {
            if (validateUserInfoData(userInfoData)) {
                dispatch({type: SCREENS_ACTION_TYPE.SET_FORM_SCREEN, payload: FORM_SCREEN.FORM_PAGE})
                if (props.setSelectedPreviewScreen) {
                    props.setSelectedPreviewScreen(FORM_SCREEN.FORM_PAGE);
                }
                setErrorInfo(false)
            } else {
                setErrorInfo(true)
            }
        };

        const buttonContainerStyle = {
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end'
        };
        return (
            <>
                <div id={"quiz-user-info-container"} className={style.popup_container}>
                    <div className={style.quiz_user_info_popup} style={{
                        width: (props.previewType === "phone" || props.previewType === "tablet") ? "85%" : null,
                        padding: (props.previewType === "phone" || props.previewType === "tablet") ? "15px" : null,
                        ...isDarkTheme(formData.theme) ? {"backgroundColor": primaryColor} : null
                    }}>
                        <h2 id={"quiz-user-info-title"}
                            style={titleStyle}>
                            Enter your Details
                        </h2>
                        {getUserInfoInputElement(formData.quiz_data.user_info.user_info_1, 0)}
                        {getUserInfoInputElement(formData.quiz_data.user_info.user_info_2, 1)}
                        {getUserInfoInputElement(formData.quiz_data.user_info.user_info_3, 2)}
                        <p id="quiz_user_info_error"
                           style={errorStyle}>
                            Please enter all required information
                        </p>
                        <div id="quiz_user_info_button"
                            style={buttonContainerStyle}>
                            {
                                formData.welcome_screen.show_screen ?
                                <MaterialButton
                                    data={cancelButtonData}
                                    handleClick={handleCancelClickEvent}/> : null
                            }
                            <MaterialButton
                                data={startButtonData}
                                handleClick={handleStartClickEvent}/>
                        </div>
                    </div>
                </div>
            </>);
    }
    return null;
}
