import React, {useEffect, useRef, useState} from 'react'
import {isMobileOnly} from "react-device-detect";
import {sortRespondents} from "../utils/response-summary-sort";
import SummarySortContainer from "./SummarySort";
import {useTranslation} from "react-i18next";
// import googleSheetIcon from "../../../../public/images/icons/sheet_icon.svg"
// import googleSheetDisconnectedIcon from "../../../../public/images/icons/sheet_disconnected_icon.svg"
import {translationKeys} from "../../../localizations/translationKeys-localization";
import {useDispatch, useSelector} from "react-redux";
import {MDCSelect} from "@material/select/index";
import {updateQuizStatusService} from "../../../shared/utils/quiz-api-services";
import {changeFormStatus} from "../../../shared/utils/sash-v2-api";
import {APP_ACTION_TYPE} from "../../reducers/appReducer";
import {Pages} from "../../utils/pages";
import MaterialButton from "../../../shared/components/MaterialButton";

// import {RESPONSES_ACTION_TYPE} from "../../reducers/responsesReducer";

function SummaryHeader(props) {
    /*  const updateFormData = (formId, isActive) => {
          props.updateFormStatusInFormData(formId, isActive);
          props.isFormActiveRef.current = isActive;
      }*/
    const {t} = useTranslation()
    const dispatch = useDispatch()
    const formData = props.formData;
    const searchText = useSelector(state => state.responsesReducer?.searchText)
    const questionFilterObject = useSelector(state => state.responsesReducer?.questionFilterObject)
    // const userId = useSelector(state => state.userReducer?.userId)
    // const isRevoked = useSelector(state => state.responsesReducer?.isRevoked)
    // const spreadSheetId = useSelector(state => state.responsesReducer?.googleSheetId)
    // const isSheetConnected = useSelector(state => state.responsesReducer?.isSheetConnected)
    const responsesCount = props.responsesCount;
    // const dispatch = useDispatch()

    const sortDataList = (sortOrder) => {
        let dataList = props.respondentsDataRef.current;
        if (dataList.length > 1) {
            props.respondentsDataRef.current = sortRespondents(dataList, sortOrder);
            props.setLastSortedOrder(sortOrder);
        }
    }
    /*const handleGoogleSheetClick = () => {
        if (formData.spreadsheet_data && !formData.spreadsheet_data?.is_connected && isRevoked && userId === formData.user_id) {
            dispatch({type: RESPONSES_ACTION_TYPE.SET_SHEET_DISCONNECTED_POP_UP, payload: true})
            dispatch({type: RESPONSES_ACTION_TYPE.SET_SHOW_EXPORT, payload: false})
        } else if (isSheetConnected) {
            dispatch({
                type: RESPONSES_ACTION_TYPE.SET_GOOGLE_SHEET_ID,
                payload: spreadSheetId ? spreadSheetId : formData.spreadsheet_data.spreadsheet_id
            })
            dispatch({type: RESPONSES_ACTION_TYPE.SET_SHOW_GOOGLE_SHEET_POP_UP, payload: true})
            dispatch({type: RESPONSES_ACTION_TYPE.SET_SHOW_EXPORT, payload: false})
        }
    }*/

    const updateStatusInServer = (isActive) => {
        if (props.formData.is_quiz) {
            updateQuizStatusService(props.formData._id, isActive, res => {
                if (res.result) {
                    props.formData.setting.is_active = isActive
                    const updatedFormData = {...formData}
                    updatedFormData.setting.is_active = isActive
                    dispatch({type: APP_ACTION_TYPE.SET_FORM_DATA, payload: updatedFormData})
                }
            }, err => console.log(err))
        } else {
            changeFormStatus(props.formData._id, isActive,
                res => {
                    if (res.result) {
                        props.formData.setting.is_active = isActive
                        const updatedFormData = {...formData}
                        updatedFormData.setting.is_active = isActive
                        dispatch({type: APP_ACTION_TYPE.SET_FORM_DATA, payload: updatedFormData})
                    }
                },
                err => console.log(err)
            )
        }
    }

    const handleSelection = (value) => {
        if (value === t(translationKeys.active)) {
            updateStatusInServer(true)
        } else {
            updateStatusInServer(false)
        }
    }

    return (
        <div className={isMobileOnly && !props.formFiller ? 'response-question-card' : 'summary-header'}
             style={!props.formFiller ? {
                 margin: isMobileOnly && formData.is_quiz ? '55px 0 10px' : isMobileOnly ? '20% 0 0 0' : 'auto',
             } : {margin: "auto"}}>
            {isMobileOnly && !props.formFiller ?
                <div style={{display: "flex"}}>
                    <div style={{
                        textAlign: "center",
                        fontSize: '30px',
                        fontWeight: "bold",
                        width: "100%",
                        // marginLeft: formData.is_quiz || !formData.spreadsheet_data ? null : "20px"
                    }}>{searchText === "" ? (formData.response_count === responsesCount ? responsesCount : formData.response_count) : props.respondents?.length}</div>
                    {/*{formData.is_quiz || !formData.spreadsheet_data ? null :
                        <img alt={"sheet"}
                             onClick={handleGoogleSheetClick}
                             src={formData.spreadsheet_data && !formData.spreadsheet_data?.is_connected && isRevoked ? googleSheetDisconnectedIcon : googleSheetIcon}/>}*/}
                </div> : null}
            {props.formFiller ?
                <div className='summary-response-count-container'>
                    <span id='summary-response-count' style={{color: '#fff', fontSize: '20px'}}>
                            {responsesCount && responsesCount > 1 ? formData?.is_quiz ? t(translationKeys.answers) : t(translationKeys.responses) : formData?.is_quiz ? t(translationKeys.answers) : t(translationKeys.response)}
                    </span>
                    <div id='summary-response-count-box' className='box' style={{borderColor: '#1976D2'}}>
                        {formData.response_count === responsesCount ? responsesCount : formData.response_count}
                    </div>
                </div> :
                <div className='summary-response-count-container'>
                    <span id='summary-response-count' style={
                        isMobileOnly ? {
                            color: '#757575',
                            fontSize: '20px',
                            textAlign: "center",
                            width: '-webkit-fill-available'
                        } : {fontSize: '20px', color: '#fff'}}>
                        {responsesCount && responsesCount > 1 ? formData?.is_quiz ? t(translationKeys.answers) : t(translationKeys.responses) : formData?.is_quiz ? t(translationKeys.answers) : t(translationKeys.response)}
                        {isMobileOnly && questionFilterObject.length === 0 && formData.response_count !== responsesCount && searchText === '' ?
                            <p style={{
                                fontSize: "13px",
                                width: "90%",
                                margin: "auto"
                            }}>You have reached the free limit, kindly upgrade your plan to access all
                                responses.</p> : null}
                    </span>
                    {isMobileOnly ? null :
                        <div id='summary-response-count-box' className='box' style={{borderColor: '#1976D2'}}>
                            {responsesCount === formData.response_count ? responsesCount : formData.response_count}
                        </div>}
                </div>}
            {isMobileOnly && !props.formFiller && formData.response_count === responsesCount ?
                <div id={'mobile-form-control-status'} className='status-text-box'
                     style={formData.setting.is_active ? {
                         borderColor: 'green',
                         minWidth: "30%",
                         width: "fit-content",
                         margin: "10px auto 0"
                     } : {borderColor: 'red', width: "30%", margin: "10px auto 0"}}>
                    <DropDown handleSelection={handleSelection} isActive={formData.setting.is_active}/>
                </div> : null}
            {formData.response_count !== responsesCount && searchText === '' && questionFilterObject.length === 0 &&  isMobileOnly && !props.formFiller ?
                <div className={'status-text-box'} style={{border: "none"}}>
                    <MaterialButton data={{
                        title: "Upgrade",
                        customButtonContainerStyle: {
                            borderRadius: '5px',
                            backgroundColor: '#004BA0',
                            width: '100px',
                        },
                        customButtonTextStyle: {
                            fontFamily: 'Nunito Sans, Sans-serif, serif',
                            fontSize: '16px',
                        }
                    }} handleClick={() => {
                        dispatch({type: APP_ACTION_TYPE.SET_CURRENT_PAGE, payload: Pages.premium_plans})
                    }}/>
                </div> : null}
            <div className='summary-right-aligned-container'>
                {(responsesCount > 1) && formData?.is_quiz ?
                    <SummarySortContainer lastSortedOrder={props.lastSortedOrder} sortDataList={sortDataList}/>
                    : isMobileOnly ? null :
                        !formData?.is_quiz && !props.formFiller ? <>
                            <span id='summary-header-form-status' style={{
                                color: '#fff',
                                fontSize: '20px',
                                margin: "0 10px"
                            }}>{t(translationKeys.form_status)} :</span>
                            <DropDown handleSelection={handleSelection} isActive={formData.setting.is_active}/>
                        </> : null}
                {formData?.is_quiz && !isMobileOnly && !props.formFiller ? <>
                            <span id='summary-header-form-status' style={{
                                color: '#fff',
                                fontSize: '20px',
                                margin: "0 10px",
                                textWrap: "nowrap"
                            }}>{t(translationKeys.quiz_status)} :</span>
                    <DropDown handleSelection={handleSelection} isActive={formData.setting.is_active}/>
                </> : null}
            </div>
        </div>
    );
}

const DropDown = (props) => {
    const {t} = useTranslation()
    const [position, setPosition] = useState({})

    const mdcSelectMenuRef = useRef(null);
    useEffect(() => {
        const select = new MDCSelect(mdcSelectMenuRef.current);
        if (props.initialValueToSelect !== undefined) {
            select.value = props.initialValueToSelect
        }
    }, [props.isActive])

    const selectorStyle = {
        '--mdc-theme-primary': '#212121',
        fontSize: '14px',
        width: '100%'
    }

    const selectTextStyle = {
        color: props.isActive ? '#46A24A' : '#F52424',
        fontSize: isMobileOnly ? '14px' : '16px'
    }

    const selectValues = [t(translationKeys.active), t(translationKeys.inactive)]

    return (
        <div id={props.id} className={'mdc-select mdc-select--outlined'}
             onClick={e => {
                 if (e.clientY >= 450) {
                     setPosition({bottom: '350%'})
                 } else {
                     setPosition({bottom: '108%'})
                 }
             }}
             style={{height: "30px", borderRadius: "5px"}}
             ref={mdcSelectMenuRef}>
            <div className="mdc-select__anchor"
                 aria-labelledby="outlined-select-label"
                 style={{width: selectorStyle.width ? selectorStyle.width : null, height: "auto"}}>
                <span className="mdc-select__selected-text-container">
                    <span className="mdc-select__selected-text" style={selectTextStyle}/>
                </span>
                <span className="mdc-select__dropdown-icon" style={{margin: isMobileOnly ? '0' : null}}>
                   <svg
                       className="mdc-select__dropdown-icon-graphic"
                       viewBox="7 10 10 5" focusable="false">
                     <polygon
                         className="mdc-select__dropdown-icon-inactive"
                         stroke="none"
                         fillRule="evenodd"
                         points="7 10 12 15 17 10">
                     </polygon>
                     <polygon
                         className="mdc-select__dropdown-icon-active"
                         stroke="none"
                         fillRule="evenodd"
                         points="7 15 12 10 17 15">
                     </polygon>
                   </svg>
                </span>
            </div>
            <div style={{
                position: 'absolute',
                top: position && position.top ? position.top : null,
                bottom: position && position.bottom ? position.bottom : null,
                right: position && position.right ? position.right : null,
                left: position && position.left ? position.left : null,
            }}>
                <MaterialSelectOptionsContainer selectValues={selectValues}
                                                selectTextStyle={selectTextStyle}
                                                handleSelection={props.handleSelection}
                                                initialValueToSelect={props.isActive ? t(translationKeys.active) : t(translationKeys.inactive)}/>
            </div>
        </div>
    )
}

const MaterialSelectOptionsContainer = props => {
    const {t} = useTranslation()
    const lineStyle = {
        width: '100%',
        height: '5vh'
    }
    return (
        <div className="mdc-select__menu mdc-menu mdc-menu-surface mdc-menu-surface--fullwidth">
            <ul className="mdc-list" role="listbox" aria-label="listbox" style={{padding: 0}}>
                {
                    props.selectValues.map((value, index) => {
                        const selectedClass = (value === props.initialValueToSelect) ? 'mdc-list-item mdc-list-item--selected' : 'mdc-list-item'
                        const optionContainerStyle = {
                            display: 'flex',
                            width: '100%',
                            borderBottom: (index + 1) === props.selectValues.length ? null : '1px solid #e0e0e0'
                        }
                        const handleSelection = () => {
                            props.handleSelection(value)
                        };
                        return (
                            <div key={index} style={optionContainerStyle}
                                 onClick={handleSelection}>
                                <li className={selectedClass}
                                    aria-selected="false"
                                    data-value={value}
                                    style={lineStyle}
                                    role="option">
                                    <span className="mdc-list-item__ripple"/>
                                    <span className="mdc-list-item__text" style={{
                                        ...props.selectTextStyle,
                                        color: value === t(translationKeys.inactive) ? '#F52424' : '#46A24A'
                                    }}>
                                        {value}</span>
                                </li>
                            </div>
                        )
                    })
                }
            </ul>
        </div>
    )
}
export default SummaryHeader
