import React, {useEffect, useState} from 'react'
import DefaultThemeContainer from "./DefaultThemeContainer";
import Loader from "../../../shared/components/Loader";
import {getDataFromUrl} from "../../../shared/utils/sash-v2-api";
import {isMobileOnly} from "react-device-detect";
import {translationKeys} from "../../../localizations/translationKeys-localization";
import {t} from "i18next";
import {useSelector} from "react-redux";

let defaultThemes;
const PrepareDefaultThemes = (props) => {
    const [isLoading, setIsLoading] = useState(true)
    const selected = useSelector(state => state.languageReducer?.selectedLanguage)

    let tempThemes = "/images/images.json"

    const selectThemeImage = () => {
        switch (selected) {
            case 'en':
                return tempThemes = "/images/sh_images_en.json"
            case 'fr':
                return tempThemes = "/images/sh_images_fr.json"
            case 'in':
                return tempThemes = "/images/sh_images_in.json"
            case 'hi':
                return tempThemes = "/images/sh_images_hi.json"
            case 'pa':
                return tempThemes = "/images/sh_images_pa.json"
            case 'ml':
                return tempThemes = "/images/sh_images_ml.json"
            case 'mr':
                return tempThemes = "/images/sh_images_mr.json"
            case 'fa':
                return tempThemes = "/images/sh_images_fa.json"
            case 'pt':
                return tempThemes = "/images/sh_images_pt.json"
            case 'es':
                return tempThemes = "/images/sh_images_es.json"
            case 'ta':
                return tempThemes = "/images/sh_images_ta.json"
            case 'te':
                return tempThemes = "/images/sh_images_te.json"
            case 'ar':
                return tempThemes = "/images/sh_images_ar.json"
            default :
                return tempThemes = "/images/sh_images_en.json"
        }
    }


    useEffect(() => {
        if (!defaultThemes) {
            const successFunction = res => {
                defaultThemes = res.data;
                defaultThemes.unshift({"file": "dark.jpeg", "name": t(translationKeys.dark_mode)});
                defaultThemes.unshift({"file": "classic.jpeg", "name": t(translationKeys.default_text) });
                defaultThemes.unshift({"file": "", "name": t(translationKeys.my_themes) });
                setIsLoading(false);
            };
            const failureFunction = err => {
                console.log(err);
                setIsLoading(false);
                alert("Something went wrong!");
            };
            const requestUrl = selectThemeImage();
            getDataFromUrl(requestUrl, successFunction, failureFunction);
        } else {
            setIsLoading(false);
        }
    })

    if (isLoading) {
        return <Loader width={isMobileOnly ? '100%' : '60%'}/>
    } else if (defaultThemes && defaultThemes.length) {
        return <DefaultThemeContainer themeImagesJson={defaultThemes}
                                      changeContainer={props.changeContainer}
                                      chosenTheme={props.chosenTheme}
                                      updateChosenTheme={props.updateChosenTheme}/>
    } else {
        return <span id={'error-message'}>{t(translationKeys.something_went_wrong)}</span>
    }
}

export default PrepareDefaultThemes

