import React from 'react';
import {QUESTION_TYPE} from "../../shared/utils/constants";
import {FORM_DATA_ACTION_TYPE} from "../reducers/formDataReducer";
import {updateResponses} from "../utils/form-response";
import {QUESTIONS_ACTION_TYPE} from "../reducers/questionsReducer";
import {ACTION_TYPE} from "../utils/utils";
import {useDispatch, useSelector} from "react-redux";

const useLogicQuestionMgr = () => {
    const formData = useSelector(state => state.formDataReducer?.formData)
    const questions = useSelector(state => state.questionsReducer?.questions)
    const responses = useSelector(state => state.formDataReducer.formResponses)
    const filesToUpload = useSelector(state => state.questionsReducer.filesToUpload)
    const fileUploadAnsweredCount = useSelector(state => state.answerCountReducer?.fileUploadAnswerCount)
    const dispatch = useDispatch()

    const updateFormQuestionsBasedOnSelection = (choice, currentQuestionData, clearNextResponses) => {
        if (formData?.is_logic_enabled) {
            let startAddingQuestions = false
            const logicQuestions = []
            for (let i = 0; i < questions.length; i++) {
                if (questions[i]._id === currentQuestionData._id) {
                    logicQuestions.push(questions[i])
                    break
                } else {
                    logicQuestions.push(questions[i])
                }
            }
            for (let i = 0; i < formData?.pages[0].questions.length; i++) {
                if (currentQuestionData._id === formData?.pages[0].questions[i]._id && clearNextResponses) {
                    let responseIndex = 0
                    responses.map((response, index) => {
                        dispatch({type: FORM_DATA_ACTION_TYPE.SET_SHOW_SUBMIT_BUTTON, payload: false})
                        while (formData?.pages[0].questions[responseIndex].type === QUESTION_TYPE.SECTION.key) {
                            responseIndex = responseIndex + 1
                        }
                        if (index > i) {
                            dispatch({
                                type: FORM_DATA_ACTION_TYPE.SET_FORM_RESPONSES,
                                payload: [...updateResponses(responses, formData?.pages[0].questions[responseIndex], "", false, false)]
                            })
                            if (formData?.pages[0].questions[responseIndex].type === QUESTION_TYPE.FILE_UPLOAD.key &&
                                filesToUpload.find(fileInfo => fileInfo.questionId === (formData?.pages[0].questions[responseIndex]._id)).file) {
                                const updatedFilesToUpload = [...filesToUpload]
                                updatedFilesToUpload.find(fileInfo => fileInfo.questionId === (formData?.pages[0].questions[responseIndex]._id)).file = null
                                dispatch({type: QUESTIONS_ACTION_TYPE.SET_FILES_TO_UPLOAD, payload: updatedFilesToUpload})
                                dispatch({type: ACTION_TYPE.SET_FILE_UPLOAD_ANSWER_COUNT, payload: fileUploadAnsweredCount - 1})
                            }
                        }
                        responseIndex = responseIndex + 1
                    })
                }
                if (choice.target_question_id !== undefined && choice.target_question_id === formData?.pages[0].questions[i].logic_question_id) {
                    startAddingQuestions = true
                }
                if (startAddingQuestions) {
                    logicQuestions.push(formData?.pages[0].questions[i])
                    if (formData?.pages[0].questions[i].is_logic_enabled && formData?.pages[0].questions[i].target_question_id !== "0") {
                        if (formData?.pages[0].questions[i].target_question_id === "-1" || i === formData?.pages[0].questions.length - 1) {
                            dispatch({type: FORM_DATA_ACTION_TYPE.SET_SHOW_SUBMIT_BUTTON, payload: true})
                        }
                        break
                    }
                    if (formData?.pages[0].questions[i].target_question_id === "-1" || i === formData?.pages[0].questions.length - 1) {
                        dispatch({type: FORM_DATA_ACTION_TYPE.SET_SHOW_SUBMIT_BUTTON, payload: true})
                    }
                }
                if ((choice.target_question_id === "0" || !choice.target_question_id) && formData?.pages[0].questions[i]._id === currentQuestionData._id) {
                    startAddingQuestions = true
                    if (i === formData?.pages[0].questions.length - 1) {
                        dispatch({type: FORM_DATA_ACTION_TYPE.SET_SHOW_SUBMIT_BUTTON, payload: true})
                    }
                } else if (choice.target_question_id === "-1") {
                    dispatch({type: FORM_DATA_ACTION_TYPE.SET_SHOW_SUBMIT_BUTTON, payload: true})
                }
            }
            dispatch({type: QUESTIONS_ACTION_TYPE.SET_QUESTIONS, payload: logicQuestions})
        }
    }

    return {
        updateFormQuestionsBasedOnSelection
    }
}

export default useLogicQuestionMgr