import React from 'react'
import {useTranslation} from "react-i18next";
import {translationKeys} from "../../../localizations/translationKeys-localization";
import {useSelector} from "react-redux";

const FormStatus = () => {
    const formData = useSelector(state => state.appReducer.formData)
    const {t} = useTranslation()
    return (
        <div id='form-status-text' className='box'
             style={formData?.setting?.is_active ? {color: 'green'}
                 : {color: 'red'}}>
                <span className='current-status-text'>
                    {formData?.setting?.is_active ? t(translationKeys.active) : t(translationKeys.inactive)}
                </span>
        </div>
    )
}

export default FormStatus