import React, {useState} from "react"
import {useSelector} from "react-redux";
import "../css/response-history-page.css";
import closeIcon from "../../../public/images/close_black.svg"
import MaterialButton from "../../shared/components/MaterialButton";
import {isMobileOnly} from "react-device-detect";
import FormPageLoginContainer from "./FormPageLoginContainer";
import PopUpContainer from "../../shared/components/PopUpContainer";

const ResponseHistoryPage = () => {
    const [showLogin, setShowLogin] = useState(false)
    const formData = useSelector(state => state.formDataReducer.formData)
    const responderEmailId = useSelector(state => state.formLoginReducer.responderEmailId)
    const responderResponses = useSelector(state => state.formDataReducer.responderResponses)

    const getFormattedTime = (time) => {
        let formattedTime;
        let formSubmitDate = new Date(parseInt(time));
        let currentDate = new Date();
        const month = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        let hours = formSubmitDate.getHours() > 12 ? formSubmitDate.getHours() - 12 : formSubmitDate.getHours()
        let minutes = formSubmitDate.getMinutes()
        let amORpm = formSubmitDate.getHours() >= 12 ? ' PM' : ' AM';

        if ((currentDate.getFullYear() === formSubmitDate.getFullYear())
            && (currentDate.getMonth() === formSubmitDate.getMonth())
            && (currentDate.getDate() === formSubmitDate.getDate())) {
            formattedTime = (hours < 10 ? '0' : '') + hours + ':' + (minutes < 10 ? '0' : '') + minutes + amORpm + ", Today";
        } else {
            formattedTime = (hours < 10 ? '0' : '') + hours + ':' + (minutes < 10 ? '0' : '') + minutes + amORpm + ", " + formSubmitDate.getDate() + ' ' + month[formSubmitDate.getMonth()] + ' ' + formSubmitDate.getFullYear()
        }
        return formattedTime
    }

    const handleViewResponseClick = (responseId) => {
        window.open("/view-response/" + responseId, "_blank")
    }

    return (
        <div className={"response-history-page"}>
            <div className={"response-history-header"}>
                <h2>Response History</h2>
                <p>{formData?.welcome_screen.title}</p>
            </div>
            <div className={"responder-id"}>
                <p>{responderEmailId}</p>
                <div style={{margin: isMobileOnly ? "auto 0" : "auto 20px"}}>
                    <MaterialButton data={{
                        title: "Change",
                        fontSize: "12px",
                        bg_color: "#FFFFFF",
                        customButtonContainerStyle: {
                            margin: '5px',
                            width: '50px',
                            height: "25px",
                            fontSize: '14px',
                            padding: '0 24px',
                            textAlign: 'center',
                            borderRadius: "50px",
                            border: "1px solid #1976D2"
                        },
                        customButtonTextStyle: {
                            display: 'flex',
                            color: '#1976D2',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }
                    }} handleClick={() => {
                        setShowLogin(true)
                    }}/>
                </div>
            </div>
            <div className={"response-history-body"}>
                {responderResponses?.map((response, index) => {
                    return (
                        <div className={"response-history-row"}>
                            <div className={"sort-icons"}>
                                <div className={"sort"}/>
                                {responderResponses.length - 1 === index ? null : <span/>}
                            </div>
                            <div className={"date-time"}>
                                <p style={{fontWeight: "bold"}}>{getFormattedTime(response.submit_time)}</p>
                                <p style={{marginTop: "10px", color: '#1976D2', cursor: "pointer"}} onClick={() => handleViewResponseClick(response._id)}>View Response</p>
                            </div>
                        </div>
                    )
                })}
            </div>
            {showLogin ? <PopUpContainer children={
                <div style={{
                    height: "35%",
                    display: 'flex',
                    overflow: 'hidden',
                    flexFlow: "column",
                    borderRadius: "10px",
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: '#FFFFFF',
                    width: isMobileOnly ? "90%" : "30%",
                    boxShadow: "0px 4px 12px 0px rgba(33, 33, 33, 0.25), 0px -4px 8px 0px rgba(189, 189, 189, 0.25)"
                }}>
                    <div style={{
                        height: "25px",
                        width: "100%",
                        display: "flex",
                        cursor: "pointer",
                        justifyContent: "end"
                    }}>
                        <img src={closeIcon}
                             style={{margin: "10px", height: "20px"}}
                             alt={"images/close_black.svg"}
                             onClick={() => {
                                 setShowLogin(false)
                             }}
                        />
                    </div>
                    <FormPageLoginContainer/>
                    <div style={{
                        height: "25px",
                        width: "100%",
                        display: "flex",
                        cursor: "pointer",
                        justifyContent: "end"
                    }}/>
                </div>
            }/> : null}
        </div>
    )
}

export default ResponseHistoryPage
