// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3sn_HVY7QoWoob5Sx7pAFo {\n    background-color: rgba(0, 0, 0, 0.6);\n    position: relative;\n    height: 55px;\n    width: 100%;\n    z-index: 10;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}\n\n._2IbKkpxgbgmc4fVHdvuzBJ {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    height: 100%;\n    margin: 5px\n}\n\n.iHVwKIxi-ubzp1ZvKH3iU {\n    font-size: 28px;\n    padding: 0;\n    margin: 0;\n    color: #ffffff;\n    line-height: 1;\n}\n\n._3KTiEcGJA_xE-Da8YB8zYr {\n    font-size: 10px;\n    color: #ffffff;\n    line-height: 1.2;\n    text-transform: uppercase;\n}\n\n._9NHhVw136EgIcG2cEa90I {\n    font-size: 28px;\n    color: #fff;\n    margin-bottom: 15px;\n}\n", ""]);
// Exports
exports.locals = {
	"fixed_quiz_timer": "_3sn_HVY7QoWoob5Sx7pAFo",
	"quiz_time_holder": "_2IbKkpxgbgmc4fVHdvuzBJ",
	"quiz_time_value": "iHVwKIxi-ubzp1ZvKH3iU",
	"quiz_time_text": "_3KTiEcGJA_xE-Da8YB8zYr",
	"quiz_timer_colon_text": "_9NHhVw136EgIcG2cEa90I"
};
module.exports = exports;
