import React, {useState} from 'react';
import backIcon from "../../../../public/images/back_white.svg";
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import continueButton from "../../../../public/images/icons/builder/undo_button.svg";
import submitButton from "../../../../public/images/icons/builder/submit_gray_tick_mark.svg";
import useLogicJumpMgr from "../../hooks/useLogicJumpMgr";
import mcqIcon from '../../../../public/images/icons/builder/question_types/multiple_choice.svg';
import useQuestionMgr from "../../hooks/useQuestionMgr";
//Smile images
import smile_worst_filled_small from "../../../../public/images/smile_worst_filled_small.svg"
import smile_dislike_filled_small from "../../../../public/images/smile_dislike_filled_small.svg"
import smile_neutral_filled_small from "../../../../public/images/smile_neutral_filled_small.svg"
import smile_like_filled_small from "../../../../public/images/smile_like_filled_small.svg"
import smile_best_filled_small from "../../../../public/images/smile_best_filled_small.svg"
//linear scale images
import dropdownChoiceImage from '../../../../public/images/icons/builder/question_types/dropdown_choice.svg'
import linearScaleImage from '../../../../public/images/icons/builder/question_types/linear_scale.svg'
import sectionIcon from "../../../../public/images/icons/builder/section_black_icon.svg";
import {BUILDER_ACTION_TYPE} from "../../reducers/builderReducer";
import {useDispatch} from "react-redux";
import {translationKeys} from "../../../localizations/translationKeys-localization";
import {useTranslation} from "react-i18next";

const AddLogicMobilePopUp = (props) => {
    const {t} = useTranslation()
    const [selectedValue, setSelectedValue] = useState(props.question.target_question_id || '0');
    const [selectedQuestions, setSelectedQuestions] = useState([]);
    const dispatch = useDispatch()

    const choiceTypes = ['MULTIPLE_CHOICE', 'DROPDOWN_CHOICE', 'LINEAR_SCALE', 'SMILEY', 'STAR_RATING_SCALE'];

    const {addLogicJumpToQuestion, selectTargetForTextQuestion, addTargetQuestionId, generateUniqueString, addLogicQuestionId, removeLogic} = useLogicJumpMgr()
    const {questionRequiredChanged} = useQuestionMgr()

    const closeLogicPopup = () => {
        props.closeOpenLogicPopup();
    }

    const handleJumpToQuestionText = (event) => {
        setSelectedValue(event.target.value);
        generateUniqueString()
        selectTargetForTextQuestion(props.index, event.target.value)
    };
    const handleJumpToQuestion = (choiceIndex, targetQuestionIndex) => {
        const newSelectedValues = [...selectedQuestions];
        newSelectedValues[choiceIndex] = targetQuestionIndex;
        setSelectedQuestions(newSelectedValues);
        if (targetQuestionIndex === "0" || targetQuestionIndex === "-1") {
            addLogicQuestionId(props.index, choiceIndex, targetQuestionIndex)
        }
        else if (props.formData.pages[0].questions[targetQuestionIndex].logic_question_id) {
            addLogicQuestionId(props.index, choiceIndex, props.formData.pages[0].questions[targetQuestionIndex].logic_question_id)
        }
        else {
            const uniqueString = generateUniqueString();
            addTargetQuestionId(targetQuestionIndex, uniqueString);
            addLogicQuestionId(props.index, choiceIndex, uniqueString)
        }
    }

    const saveLogicMobile = () => {
        if (choiceTypes.includes(props.question?.type)) {
            if (selectedQuestions.length !== 0) {
                questionRequiredChanged(props.index, true)
                addLogicJumpToQuestion(props.index, true)
            }
            else if (selectedQuestions === []) {
                questionRequiredChanged(props.index, false)
                addLogicJumpToQuestion(props.index, false)
            }
        }
        else {
            if (selectedValue !== '' || props.question.target_question_id !== '') {
                questionRequiredChanged(props.index, true)
                addLogicJumpToQuestion(props.index, true)
            }
            else {
                removeLogicMobile();
            }
        }
        dispatch({type: BUILDER_ACTION_TYPE.SET_NEW_QUESTION, payload: false})
        props.closeOpenLogicPopup();
    }

    const removeLogicMobile = () => {
        questionRequiredChanged(props.index, false)
        addLogicJumpToQuestion(props.index, false)
        selectTargetForTextQuestion(props.index, '')
        if (choiceTypes.includes(props.question?.type)) {
            removeLogic(props.index)
            setSelectedQuestions([])
        }
        else {
            setSelectedValue('')
        }
    }

    const smileRow = (index) => {
        let imageToDisplay

        switch (index) {
            case 0:
                imageToDisplay = smile_worst_filled_small
                break
            case 1:
                imageToDisplay = smile_dislike_filled_small
                break
            case 2:
                imageToDisplay = smile_neutral_filled_small
                break
            case 3:
                imageToDisplay = smile_like_filled_small
                break
            case 4:
                imageToDisplay = smile_best_filled_small
                break
        }

        return <div style={{display: "flex"}} key={index}>
            <img key={index} alt={`Smile ${index + 1}`} src={imageToDisplay}
                 style={{paddingRight: '5px'}}/>
        </div>
    }

    return (
        <div className={'add-logic-mobile-popup-container'}>
            <div className={'add-logic-mobile-popup-header'}>
                <img src={backIcon} alt={'back Icon'} onClick={closeLogicPopup}></img>
                <p>{t(translationKeys.add_logic)}</p>
            </div>

                {
                    choiceTypes.includes(props.question?.type) ?
                        <div>
                            {
                                props.question.choices.map((choice, choiceIndex) => {
                                    let questionNumber = 1;
                                    return (
                                    <div className={`add-logic-question-option-container ${choiceIndex === props.question.choices.length - 1 ? 'add-logic-question-option-last-container' : ''}`}>
                                        <p>
                                            {props.question?.type === 'SMILEY' ? smileRow(choiceIndex)
                                                : props.question?.type === 'LINEAR_SCALE' ? <img src={linearScaleImage} alt={'linear scale icon'} />
                                                    : props.question?.type === 'DROPDOWN_CHOICE' ? <img src={dropdownChoiceImage} alt={'dropdown icon'} />
                                                        : props.question?.type === 'STAR_RATING_SCALE' ? <img src={`../../../images/Star_${choiceIndex+1}.svg`} alt="Star Rating" className="star-line-img" style={{width: '100px'}}/>
                                                            : <img src={mcqIcon} alt={'mcq icon'} />}

                                            {choice.label}
                                        </p>

                                        <FormControl variant="standard" sx={{ m: 1, minWidth: 220, margin: 0 }} fullWidth>
                                            <Select
                                                label="Select an option"
                                                id={`mcq-select-question-helper-${choiceIndex}`}
                                                value={selectedQuestions[choiceIndex] || (props?.question?.choices[choiceIndex]?.target_question_id !== ("0" || "-1") ? props?.formData?.pages[0]?.questions.findIndex((question) => props?.question?.choices[choiceIndex]?.target_question_id === question.logic_question_id) : props?.question?.choices[choiceIndex]?.target_question_id)}
                                                onChange={(e) => handleJumpToQuestion(choiceIndex, e.target.value)}
                                            >
                                                <MenuItem value="0"><img src={continueButton} alt={'continue to next question icon'} /> Continue to Next Question</MenuItem>
                                                <MenuItem value="-1"><img src={submitButton} alt={'submit form  icon'} /> Submit Form</MenuItem>

                                                {props.formData.pages[0].questions.map((object, index) => {
                                                    if(object.type === 'SECTION') {
                                                        return (
                                                            index > props.index &&
                                                            (<MenuItem value={index}> <span className={'logic-jump-question-to-select'}><img src={sectionIcon} alt={'section icon'} /> {object.title}</span></MenuItem>))
                                                    } else {
                                                        const questionContent = `${questionNumber}. ${object.title}`;
                                                        questionNumber++;
                                                        return (
                                                            index > props.index &&
                                                            (<MenuItem value={index}> <span className={'logic-jump-question-to-select'}>{questionContent}</span></MenuItem>))
                                                    }
                                                })}
                                            </Select>
                                        </FormControl>
                                    </div>
                                    )})
                            }
                        </div>
                        : <div className={'add-logic-question-option-container'}>
                            <p>{props.index+1}. {props.question.title}</p>

                            <div className={'text-body-add-logic-dropdown-container'}>
                                <FormControl variant="standard" sx={{ m: 1, minWidth: 220, margin: 0 }} fullWidth>
                                    <Select
                                        label="Select an option"
                                        value={selectedValue}
                                        onChange={handleJumpToQuestionText}
                                    >
                                        <MenuItem value="0"><img src={continueButton} alt={'continue to next question icon'} /> {t(translationKeys.continue_to_next_question)}</MenuItem>
                                        <MenuItem value="-1"><img src={submitButton} alt={'submit form  icon'} /> {t(translationKeys.submit_form)}</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                        </div>

                }
            <div className={'add-logic-mobile-popup-footer'}>
                <button className={'add-logic-mobile-popup-remove-logic-button'} onClick={removeLogicMobile}>{t(translationKeys.remove_logic)}</button>
                <button className={'add-logic-mobile-popup-save-logic-button'} onClick={saveLogicMobile}>{t(translationKeys.save_logic)}</button>
            </div>
        </div>
    )
}

export default AddLogicMobilePopUp