import React, {useState} from 'react'
import QuizSettingsHeader from "./QuizSettingsHeader"
import SettingsViewContainer from "./SettingsViewContainer"
import {isMobile, isMobileOnly} from "react-device-detect"
import SettingsCollaboratorContainer from "./SettingsCollaboratorContainer";
import SettingsViewContainerMobile from "./SettingsViewContainerMobile";
import SettingsMobileQuizControlContainer from "./SettingsMobileQuizControlContainer";
import {translationKeys} from "../../../localizations/translationKeys-localization";
import {t} from "i18next";
import {SettingsMenuItem} from "./BuilderLeftNavigatorHelper";
import {SETTING_TABS_FOR_NEXT_PREV_CHANGE} from "./FormSettingsContainer";
import {useSelector} from "react-redux";
import SettingsCreateTemplate from "./SettingsCreateTemplate";
import Message from "../../../shared/containers/ConfirmMessage";

const QuizSettingsContainer = (props) => {

    const currentUserId = useSelector(state => state.userReducer?.userId)
    const builderToastMessage = useSelector(state => state.builderReducer?.builderToastMessage)
    const builderMessage = useSelector(state => state.builderReducer?.builderMessage)
    const encodedUserId = btoa(currentUserId);
    const viewTab = {title: t(translationKeys.view), selected: true}
    const selectedLanguage = useSelector(state => state.languageReducer?.selectedLanguage)
    const controlTab = {title: t(translationKeys.control), selected: false}
    const quizCollaboratorTab = {title: t(translationKeys.collaboration), selected: false}
    const templateCategory= {title: t(translationKeys.templateCategory), selected: false}

    let startSwipeX, startSwipeY, distX, startTime, elapsedTime, distY;
    const allowedTime = 200;

    const [containerTabItems, setContainerTabItems] = useState(isMobileOnly ? [viewTab, controlTab, quizCollaboratorTab] : [viewTab, controlTab, quizCollaboratorTab, templateCategory])

    const updateTabContainer = clickedItem => {
        if (clickedItem.title === containerTabItems.filter(tabItem => tabItem.selected)[0].title) {
            return
        }

        // Deselect previous Tab Item and Update Current Selection
        setContainerTabItems(oldItems => {
            const newItems = [...oldItems]

            newItems.forEach((tabItem) => {
                    tabItem.selected = tabItem.title === clickedItem.title
                }
            )

            return newItems
        })
    }

    return (
        <div className={'forq-settings-container'} style={{
            display: props.currentContainerTitle === SettingsMenuItem.getTitle() ? null : 'none'
        }}>
            <div id={'settings-column'} className='settings-column'
                 style={{
                     display: props.currentContainerTitle === SettingsMenuItem.getTitle() ? null : 'none'
                 }}>
                {isMobileOnly ? null :
                    <QuizSettingsHeader {...props}
                                        updateTabContainer={updateTabContainer}
                                        containerTabItems={containerTabItems}
                                        setContainerTabItems={setContainerTabItems}
                    />
                }

                <div className='settings-box1'>
                    <div className='mobile-header-tab-row'>
                        <div style={{
                            display: 'flex',
                            justifyContent: 'space-around',
                            height: '35px',
                            width: isMobileOnly ? '100%' : null,
                            padding: isMobileOnly ? '7px 0' : null
                        }}>
                            {
                                containerTabItems.map((tabItem, index) => (
                                    () => {
                                        if (tabItem.title === 'Template Category' && encodedUserId !== 'dGVtcGxhdGVzQHN1cnZleWhlYXJ0LmNvbQ==') {
                                            return null;
                                        }
                                        else {
                                            return (
                                                <div
                                                    key={index}
                                                    className={'header-button center'}
                                                    style={{borderBottom: tabItem.selected && isMobileOnly ? '7px solid white' : null, margin: "0 20px", width: "-webkit-fill-available", minInlineSize: "fit-content"}}
                                                    onClick={() => updateTabContainer(tabItem)}>
                                        <span style={selectedLanguage === "ml" ? {fontSize: "14px"} : null}
                                              className={tabItem.selected ? 'mobile-header-tab-row-item-settings mobile-header-tab-row-item-clicked-settings' : 'mobile-header-tab-row-item-settings'}>
                                            {isMobileOnly ? tabItem.title.toUpperCase() : tabItem.title}
                                        </span>
                                                </div>
                                            )
                                        }
                                    }
                                )())
                            }
                        </div>
                    </div>
                    <div onTouchStart={(e) => {
                        if (isMobile) {
                            startSwipeX = e.changedTouches[0].pageX;
                            startSwipeY = e.changedTouches[0].pageY;
                            startTime = new Date().getTime();
                        }
                    }} onTouchEnd={e => {
                        if (isMobile) {
                            distX = e.changedTouches[0].pageX - startSwipeX;
                            distY = e.changedTouches[0].pageY - startSwipeY;
                            elapsedTime = new Date().getTime() - startTime;
                            if (elapsedTime <= allowedTime && distY < 80) {
                                const currentTab = containerTabItems.filter((item) => {
                                    return item.selected === true
                                })
                                if (distX < -120) {
                                    if (currentTab[0].title && currentTab[0].title !== SETTING_TABS_FOR_NEXT_PREV_CHANGE.COLLABORATE) {
                                        if (currentTab[0].title === SETTING_TABS_FOR_NEXT_PREV_CHANGE.VIEW) {
                                            updateTabContainer(containerTabItems[1])
                                        } else if (currentTab[0].title === SETTING_TABS_FOR_NEXT_PREV_CHANGE.CONTROL) {
                                            updateTabContainer(containerTabItems[2])
                                        }
                                    }
                                } else if (distX > 120) {
                                    if (currentTab[0].title && currentTab[0].title !== SETTING_TABS_FOR_NEXT_PREV_CHANGE.VIEW) {
                                        if (currentTab[0].title === SETTING_TABS_FOR_NEXT_PREV_CHANGE.COLLABORATE) {
                                            updateTabContainer(containerTabItems[1])
                                        } else if (currentTab[0].title === SETTING_TABS_FOR_NEXT_PREV_CHANGE.CONTROL) {
                                            updateTabContainer(containerTabItems[0])
                                        }
                                    }
                                }
                            }
                        }
                    }} style={isMobile ? {paddingBottom: '80px', height: '100%', minHeight: "80vh"} : null}>
                        {
                            (() => {
                                if (containerTabItems.filter(tabItem => tabItem.selected)[0].title === viewTab.title) return (
                                    isMobileOnly ? <SettingsViewContainerMobile key='SettingsViewContainerMobile' {...props} isQuiz={true}/> :
                                        <SettingsViewContainer key='SettingsViewContainer' {...props} isQuiz={true}/>
                                )
                                if (containerTabItems.filter(tabItem => tabItem.selected)[0].title === quizCollaboratorTab.title) return (
                                    <SettingsCollaboratorContainer key='SettingsCollaboratorContainer' builder={true}/>
                                )
                                if (containerTabItems.filter(tabItem => tabItem.selected)[0].title === controlTab.title) return (
                                    <SettingsMobileQuizControlContainer key='SettingsCollaboratorContainer' isQuiz={true} {...props}/>
                                )
                                if (containerTabItems.filter(tabItem => tabItem.selected)[0].title === templateCategory.title) return (
                                    <SettingsCreateTemplate key='SettingsCreateTemplate' {...props}/>
                                )
                            })()
                        }
                    </div>
                </div>
            </div>
            {builderToastMessage ? <Message width={'250px'} message={builderMessage}/> : null}
        </div>
    )
}

export default QuizSettingsContainer