import React, {useState, useEffect} from 'react'
import {translationKeys} from "../../../localizations/translationKeys-localization";
import {t} from "i18next";
import {BuilderMenuItem} from "./BuilderLeftNavigatorHelper";

const SETTING_TABS_FOR_NEXT_CLICK = {
    VIEW: t(translationKeys.view),
    QUIZ_CONTROL: t(translationKeys.control),
    QUIZ_RESULT: t(translationKeys.quiz_result),
    QUESTIONS: t(translationKeys.questions),
    COLLABORATE: t(translationKeys.collaboration)
}

const QuizSettingsHeader = props => {
    const [showNextTab, setShowNextTab] = useState(true)
    const [currentTab, setCurrentTab] = useState(SETTING_TABS_FOR_NEXT_CLICK.VIEW)

    useEffect(() => {
        if (props.containerTabItems.filter(tabItem => tabItem.selected)[0].title === SETTING_TABS_FOR_NEXT_CLICK.VIEW) {
            setShowNextTab(true)
            setCurrentTab(SETTING_TABS_FOR_NEXT_CLICK.VIEW)
        } else if (props.containerTabItems.filter(tabItem => tabItem.selected)[0].title === SETTING_TABS_FOR_NEXT_CLICK.QUIZ_CONTROL) {
            setShowNextTab(true)
            setCurrentTab(SETTING_TABS_FOR_NEXT_CLICK.QUIZ_CONTROL)
        } else {
            setShowNextTab(false)
        }
    }, [props.containerTabItems])

    const handleClose = () => {
        props.updateContainer(BuilderMenuItem)
    }

    const nextTab = () => {
        let clickedItem

        if (currentTab === SETTING_TABS_FOR_NEXT_CLICK.VIEW) {
            clickedItem = props.containerTabItems[1]
            setCurrentTab(SETTING_TABS_FOR_NEXT_CLICK.QUIZ_CONTROL)
        } else if (currentTab === SETTING_TABS_FOR_NEXT_CLICK.QUIZ_CONTROL) {
            clickedItem = props.containerTabItems[2]
            setCurrentTab(SETTING_TABS_FOR_NEXT_CLICK.COLLABORATE)
        }

        props.updateTabContainer(clickedItem)
    }

    const submitSettings = () => {
        props.submitSettings()
    }

    return (
        <div id={'quiz-settings-container'} className='settings-header'>
            <div id='settings-container'>
                <label className='settings-label'>{t(translationKeys.settings)}</label>
            </div>
            <div id='settings-header-next-btn' className='builder-settings-button builder-settings-header-button-1' style={{visibility: showNextTab ? 'visible' : 'hidden'}} onClick={(e) => {
                nextTab()
            }}>
                <span className='builder-settings-header-text'>{t(translationKeys.next)}</span>
                <img
                    src="images/icons/builder/right_blue.svg"
                    alt="Next Tab"
                    onClick={(e) => {
                        nextTab()
                    }}
                />
            </div>
            <div id='settings-header-submit-btn' className='builder-settings-button builder-settings-header-button-2' onClick={(e) => {
                submitSettings()
            }}>
                <span className='builder-settings-header-text'>{t(translationKeys.submit)}</span>
                <img
                    src="images/icons/builder/check_white.svg"
                    alt="Submit"
                    onClick={(e) => {
                        submitSettings(), e.stopImmediatePropagation
                    }}
                />
            </div>
            {/*close button*/}
            <div className="settings-close-button center" onClick={handleClose}>
                <img src="../../../images/close_black.svg" alt="images/close_black.svg"/>
            </div>
        </div>
    )
}

export default QuizSettingsHeader