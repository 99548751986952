import {t} from "i18next"
import {translationKeys} from "../../../localizations/translationKeys-localization"
import {QUESTION_TYPE} from "../../../shared/utils/constants"

export const getGridAnswer = (responseIndex, currentQuestionData,formRespondent,currentRespondent,questions) => {
    let gridAnswers = ''
    let gridAnswerColAndRowIDs = []
    let separator = ', '
    const originalFormRespondent = formRespondent?.sort((a, b) => a.submit_time - b.submit_time)[responseIndex]
    const respondent = currentRespondent?.sort((a, b) => a.submit_time - b.submit_time)[responseIndex]

    // Get the Question associated with the Answer.
    const currentQuestion = questions.find(q => q._id === currentQuestionData.questionId)

    if (currentQuestion.type === QUESTION_TYPE.CHECKBOX_GRID.key) {
        // Get the Response that matches the question_id
        let matchingResponse = originalFormRespondent.responses.filter(response => response.question_id === currentQuestion._id)

        matchingResponse.forEach(matchingResponse => {
            for (let iGridChoiceCtr = 0; iGridChoiceCtr < matchingResponse?.checkbox_grid_choices?.length; iGridChoiceCtr++) {
                let matchingRowIndex = currentQuestion.rows.findIndex(c => c._id === matchingResponse.checkbox_grid_choices[iGridChoiceCtr].row)
                let matchingColsIndexes = []
                let previouslyAddedColumns = []

                matchingResponse.checkbox_grid_choices[iGridChoiceCtr].columns.forEach(col => {
                        let index = currentQuestion.columns.findIndex(c => c._id === col)

                        if (index > -1 && !previouslyAddedColumns.find(column => column === index)) {
                            matchingColsIndexes.push(index)
                            previouslyAddedColumns.push(index)
                        }
                    }
                )

                if (matchingRowIndex > -1 && matchingColsIndexes.length > 0) {
                    gridAnswerColAndRowIDs.push({columns: matchingColsIndexes, row: matchingRowIndex})

                    let columnLabels = ''

                    matchingColsIndexes.forEach(colIndex =>
                        columnLabels += `${currentQuestion.columns[colIndex].label}${separator}`
                    )

                    gridAnswers += `${currentQuestion.rows[matchingRowIndex].label.trimEnd()} x ${columnLabels}${separator}`
                }

                if (gridAnswers.length > 0 && gridAnswers.endsWith(separator)) {
                    gridAnswers = gridAnswers.slice(0, -separator.length)
                }
            }
        })

        if (gridAnswers.length > 0 && gridAnswers.endsWith(separator)) {
            gridAnswers = gridAnswers.slice(0, -separator.length)
        }
    } else if (currentQuestion.type === QUESTION_TYPE.MULTIPLE_CHOICE_GRID.key) {
        // Get the Response that matches the question_id
        let matchingResponse = respondent.responses.find(r => r.question_id === currentQuestionData.questionId)

        for (let iGridChoiceCtr = 0; iGridChoiceCtr < matchingResponse?.grid_choices?.length; iGridChoiceCtr++) {
            let matchingRowIndex = currentQuestion.rows.findIndex(c => c._id === matchingResponse.grid_choices[iGridChoiceCtr].row)
            let matchingColIndex = currentQuestion.columns.findIndex(c => c._id === matchingResponse.grid_choices[iGridChoiceCtr].column)
            let matchingColsIndexes = new Array(1).fill(currentQuestion.columns.findIndex(c => c._id === matchingResponse.grid_choices[iGridChoiceCtr].column))

            if (matchingRowIndex > -1 && matchingColIndex > -1) {
                gridAnswerColAndRowIDs.push({columns: matchingColsIndexes, row: matchingRowIndex})
                gridAnswers += `${currentQuestion.rows[matchingRowIndex].label.trimEnd()} x ${currentQuestion.columns[matchingColIndex].label.trimEnd()}${separator}`
            }
        }
    }

    return gridAnswers === '' ? {
        gridTextAnswer: t(translationKeys.no_answer),
        gridAnswerColAndRowIDs: null
    } : {
        gridTextAnswer: gridAnswers.endsWith(separator) ? gridAnswers.slice(0, -separator.length) : gridAnswers,
        gridAnswerColAndRowIDs: gridAnswerColAndRowIDs
    }
}