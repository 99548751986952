import React from 'react';
import close from '../../../public/images/close_black.svg'
import surveyheart from '../../../public/images/surveyheart1.png'
import style from '../css/surveyheart-content-popup.module.css'
import {isMobileOnly} from "react-device-detect";

export default function SurveyHeartContentPopup(props) {
    function hideAboutContent() {
        props.contentContainerRef.current ? props.contentContainerRef.current.style.display = "none" : null;
    }

    return (
        <div ref={props.contentContainerRef} style={{
            height: '100vh',
            width: '100vw',
            position: 'fixed',
            zIndex: '20',
            backgroundColor: 'rgba(0, 0, 0, 0.8)',
            display: 'none',
            alignItems: 'center',
            justifyContent: 'center',
            flexFlow: 'column'
        }}>
            <div className={style.about_content_popup_container} style={{
                display: 'flex',
                flexFlow: 'column'
            }}>
                <div style={{width: '100%'}}>
                    <img
                        style={{
                            width: '35px',
                            height: '35px',
                            margin: '10px 10px 0 0',
                            position: 'relative',
                            cssFloat: 'right',
                            cursor: 'pointer'
                        }}
                        onClick={() => {
                            hideAboutContent()
                        }}
                        alt={'close'}
                        src={close}/>
                    <div className={style.about_content_popup_image_banner}>
                        <img alt={'surveyHeart icon'} style={{
                            width: '100%',
                            maxWidth: '300px'
                        }} id="app-logo"
                             src={surveyheart}/>
                    </div>
                </div>
                <div className={style.about_content_popup_body}>
                    <div style={{
                        padding: '15px',
                        textAlign: 'justify'
                    }}>

                        <div className={style.content_text_container}>
                            <h2 id='survey-builder-title' >Survey Builder</h2>
                            <p id='survey-builder-description'>Create surveys/forms with 9 different types of questions which are commonly used for
                                collecting responses. In our form builder you can control your form access level to the
                                responders like whether they can view your results or not, are they allowed to give
                                multiple answers or not, whether questions of your form gets shuffled or not.</p>
                        </div>
                        <div className={style.content_text_container}>
                            <h2 id='templates-title'>Templates</h2>
                            <p id='templates-description' >Pre-designed templates with suitable themes are available to reduce your typing work, It
                                consist of 30+ surveys in the categories of Feedbacks, Education, Health,
                                Registration, Food, Tours & Travels, Applications.</p>
                        </div>
                        <div className={style.content_text_container}>
                            <h2 id='preview-survey-title'> Preview survey</h2>
                            <p id='preview-survey-description'>Before publishing your form, you can view your form as how the responders will view your
                                form
                                when you share it to them, so that you can correct if any changes required. For error
                                free
                                forms we are providing this feature to you.</p>
                        </div>
                        <div className={style.content_text_container}>
                            <h2 id='offline-form-builder-title'>Offline form builder</h2>
                            <p id='offline-form-builder-description'>Native form builder allow you to build your forms without internet and save it in offline
                                forms. When you access the internet you can submit your form with just one click to that
                                saved
                                form.</p>
                        </div>
                        <div className={style.content_text_container}>
                            <h2 id='instant-notifications-title'> Instant Notifications</h2>
                            <p id='instant-notifications-description'> Get instantly notified for your responses the moment your responder clicks the submit
                                button.
                                Live notifications enable you to be updated in realtime. you can also get summarised
                                result
                                instantly with that newly arrived responses.</p>
                        </div>
                        <div className={style.content_text_container}>
                            <h2 id='summarised-response-title'>Summarised Responses</h2>
                            <p id='summarised-response-description'>Summary of your responses will be shown in realtime basis. Summarized charts are prepared
                                immediately after response submitted. Get an error free results instantly for your
                                surveys.</p>
                        </div>
                        <div className={style.content_text_container}>
                            <h2 id='export-your-record-title'> Export your record</h2>
                            <p id='export-your-record-description'>Your survey results can be exported for filing and recording purpose. you can export this
                                results as excels.</p>
                        </div>
                        <div className={style.content_text_container}>
                            <h2 id='themes-title'>Themes</h2>
                            <p id='themes-description'>Your forms should be readable to the responders, it increases your response rate so we
                                are
                                providing themes to your forms for good readability. You can choose themes related to
                                your
                                survey content.</p>
                        </div>
                        <div className={style.about_content_popup_image_banner} style={isMobileOnly ? {flexFlow: "column"} : null}>
                            <img alt={'playstore'} style={{
                                width: '100%',
                                marginTop: '20px',
                                maxWidth: '300px',
                                cursor: 'pointer'
                            }} id="download-image"
                                 onClick={() => {
                                     window.open("https://play.google.com/store/apps/details?id=com.surveyheart&hl=en")
                                 }}
                                 src="https://play.google.com/intl/en_us/badges/images/generic/en_badge_web_generic.png"/>
                            <img alt={'appstore'} style={{
                                width: '100%',
                                marginTop: '20px',
                                maxWidth: isMobileOnly ? '265px' : '245px',
                                cursor: 'pointer'
                            }} id="download-image"
                                 onClick={() => {
                                     window.open("https://apps.apple.com/us/app/surveyheart-online-form-maker/id6443449067?itsct=apps_box_badge&amp;itscg=30200%22")
                                 }}
                                 src="https://apple-resources.s3.amazonaws.com/media-badges/download-on-the-app-store/black/en-us.svg"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
