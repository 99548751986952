import MenuIconInfo from "../../../shared/classes/MenuIconInfo"
import NavigationMenuItem from "../../../shared/classes/NavigationMenuItem";
import ChangeContainerMenuItem from "../../../shared/classes/ChangeContainerMenuItem";
import PopupMenuItem from "../../../shared/classes/PopupMenuItem";
import {isMobileOnly} from "react-device-detect";
import {PAGE_HASH_LOCATION} from "../../utils/pages";
import {translationKeys} from "../../../localizations/translationKeys-localization";
import {t} from "i18next";

export const SurveyHeartMenuItem = new NavigationMenuItem(0, "", new MenuIconInfo("survey-heart-icon", "surveyheart_grey.svg"), '/app' + PAGE_HASH_LOCATION.dashboard)
export const BuilderMenuItem = new ChangeContainerMenuItem(1, t(translationKeys.builder), true, new MenuIconInfo("builder-icon", "builder"))
export const PreviewMenuItem = new ChangeContainerMenuItem(4, t(translationKeys.preview), false, new MenuIconInfo("preview-icon", "preview"))
export const ThemesMenuItem = new ChangeContainerMenuItem(5, t(translationKeys.themes), false, new MenuIconInfo("themes-icon", "themes"))
export const SettingsMenuItem = new ChangeContainerMenuItem(2, t(translationKeys.settings), false, new MenuIconInfo("settings-icon", "settings"))
export const PopUpMenuItem = new PopupMenuItem(3, t(translationKeys.submit), new MenuIconInfo("submit", 'submit_grey.svg'))

export const helperBuilderMenuItems = () => {
    return !isMobileOnly ? [SurveyHeartMenuItem, BuilderMenuItem, SettingsMenuItem] :
        [BuilderMenuItem, PreviewMenuItem, SettingsMenuItem]
}

export const doNavigation = clickedItem => {
    window.location.href = clickedItem.getNewLocation()
}

export const doPopupAction = clickedItem => {
    clickedItem.getAction()
}

export const updateContainerHelper = (clickedItem, currentContainerTitle, setBuilderMenuItems, setCurrentContainerTitle) => {
    if (clickedItem === currentContainerTitle) {
        return
    }

    setBuilderMenuItems(prevMenuItems => {
        let updatedMenuItems = [...prevMenuItems]
        if (isMobileOnly) {
            if (clickedItem.getTitle() !== BuilderMenuItem.getTitle()) {
                if (updatedMenuItems[2].getTitle() !== ThemesMenuItem.getTitle()) {
                    updatedMenuItems = [BuilderMenuItem, PreviewMenuItem, ThemesMenuItem, SettingsMenuItem, PopUpMenuItem]
                }
            } else {
                if (updatedMenuItems[2].getTitle() === ThemesMenuItem.getTitle()) {
                    updatedMenuItems = [BuilderMenuItem, PreviewMenuItem, SettingsMenuItem, PopUpMenuItem]
                }
            }
        }

        updatedMenuItems.forEach((menuItem) => {
                if (menuItem instanceof ChangeContainerMenuItem) {
                    if (menuItem.getTitle() === clickedItem.getTitle()) {
                        menuItem.setSelected(true)
                        setCurrentContainerTitle((menuItem.getTitle() === t(translationKeys.themes)) ? 'ThemesContainer' : menuItem.getTitle())
                    } else {
                        menuItem.setSelected(false)
                    }
                }
            }
        )

        return updatedMenuItems
    })
}
