import React, {useEffect, useRef, useState} from 'react'
import LeftNavigator from "../../../shared/components/LeftNavigator"
import FormSettingsContainer from "./FormSettingsContainer"
import ThemesContainer from "./ThemesContainer"
import PopUpContainer from "../../../shared/components/PopUpContainer"
import ShareCard from "../../../shared/components/ShareCard"
import PreviewContainer from "./PreviewContainer"
import '../css/form-builder.css'
import {
    doNavigation,
    doPopupAction,
    helperBuilderMenuItems,
    SurveyHeartMenuItem,
    BuilderMenuItem,
    PreviewMenuItem,
    SettingsMenuItem,
    ThemesMenuItem,
    updateContainerHelper
} from "./BuilderLeftNavigatorHelper"
import {isMobile, isMobileOnly} from "react-device-detect"
import '../../../shared/css/modal-dialog.css'
import {
    createNewForm,
    getSpecificFormData,
    updateExistingForm
} from "../../../shared/utils/sash-v2-api"
import {incompleteQuestionCount} from "./helpers/FormBuilderHelper"
import {
    addMissingChoiceOptions,
    dataHasChanged,
    getQuestionProps,
    getSectionProps,
    getTitleProps,
    initializeHelperVars,
    popInitialSelectMenuItems,
    questionHasBeenAdded,
    resetFocusTitle,
    getNewQuestionsForAutocomplete,
    unapprovedModalDialog,
    editRightsRemovedDialog
} from "./helpers/BuilderContainerHelper"
import {
    BUILDER_CREATION_SOURCE, BUILDER_SUGGESTION_TYPES_FOR_LOCAL_STORAGE,
    BUILDER_TYPE,
    CURRENTLY_SELECTED_MENU, getQuestionGroupV2, QUESTION_GROUPS,
    QUESTION_TYPE, SUBMISSION_ERRORS, WEB_APP
} from "../../../shared/utils/constants"
import {registerGAEvent} from "../../../shared/utils/analytics"
import Loader from "../../../shared/components/Loader"
import {
    getWebAppVersionInfo,
    getFormBuilderSkeleton,
    removeProp, removePropWRTData
} from "./helpers/BuilderHelper"
import TitleSectionComponent from "./TitleSectionComponent"
import QuestionTypePopup from "../../../shared/components/QuestionTypePopup"
import SearchAndFormsList from "../../../shared/components/import-questions/SearchAndFormsList"
import QuestionContainer from "./QuestionContainer"
import BottomBar from "../../../shared/components/BottomBar"
import {PAGE_HASH_LOCATION, Pages} from "../../utils/pages"
import PopupMenuItem from "../../../shared/classes/PopupMenuItem"
import MenuIconInfo from "../../../shared/classes/MenuIconInfo"
import deleteIcon from '../../../../public/images/delete_white.svg'
import {useDispatch, useSelector} from "react-redux"
import {AUTOCOMPLETE_ACTION_TYPE} from "../../reducers/autocompleteReducer"
import ModalDialog from "../../../shared/components/ModalDialog"
import {getFormByFormId} from "../../../admin/utils/admin-api-services/general-api-services"
import {COLLABORATOR_ACTION_TYPE, UPDATE_FORM_DATA} from "../../utils/constants"
import {useTranslation} from "react-i18next"
import {translationKeys} from "../../../localizations/translationKeys-localization"
import {APP_ACTION_TYPE} from "../../reducers/appReducer"
import {BUILDER_ACTION_TYPE} from "../../reducers/builderReducer"
import useFormImportManager from "../../hooks/useFormImportManager"
import MobileQuestionContainer from "./MobileQuestionContainer";
import store from '../../store/appStore';
import {APP_DASHBOARD_ACTION_TYPE} from "../../reducers/appDashboardReducer";
import {getWords} from "../../../admin/utils/admin-api-services/words-api-services";
import {WORD_TYPE} from "../../../admin/constants";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import ImportQuestionMobileScreen from "./ImportQuestionMobileScreen";
import {RESPONSES_ACTION_TYPE} from "../../reducers/responsesReducer";
import Message from "../../../shared/containers/ConfirmMessage";
import {IMPORT_QUESTIONS_ACTION_TYPE} from "../../reducers/importQuestionsReducer";

export const FORM_SETTINGS = {
    ALLOW_SUMMARY_VIEW: 'allow_summary_view',
    ALLOW_MULTIPLE_SUBMIT: 'allow_multiple_submit',
    SHOW_QUESTION_NUMBER: 'show_question_number',
    IS_SHUFFLED: 'is_shuffled',
    SHOW_LOGO: 'is_show_logo',
    FORM_TITLE: 'title',
    FORM_DESC: 'description',
    SHOW_WELCOME_PAGE: 'show_screen',
    START_BUTTON_TEXT: 'button_text',
    IS_ACTIVE: 'is_active',
    ALLOW_RESPONSE_EDIT: 'allow_response_edit',
    IS_EMAIL: 'is_email',
    NOTIFICATIONS: 'notifications',
    THEME: 'theme',
    ESTIMATED_TIME: 'show_estimated_time',
    SHOW_QUESTION_COUNT: 'show_question_count',
    PASSCODE: 'passcode',
    COLLECT_EMAIL: 'is_email',
    CONFIRMATION_MESSAGE: "confirmation_message",
    AUTO_OPEN_TIME: "auto_open_time",
    AUTO_CLOSE_TIME: "auto_close_time",
}

const FormBuilderContainer = () => {
    const dispatch = useDispatch()
    const {t} = useTranslation()
    const isQuiz = useSelector(state => state.appReducer.isQuiz)
    const userId = useSelector(state => state.userReducer?.userId)
    const formData = useSelector(state => state.appReducer.formData)
    const formId = useSelector(state => state.appReducer.formData?._id)
    const previewData = useSelector(state => state.appReducer.previewData)
    const shared = useSelector(state => state.appDashboardReducer.showShared)
    const templateTheme = useSelector(state => state.appReducer.templateTheme)
    const suggestions = useSelector(state => state.autocompleteReducer?.suggestions)
    const collaborator = useSelector(state => state.collaboratorsData?.collaborators)
    const showPreviewPane = useSelector(state => state.builderReducer.showPreviewPane)
    const templateQuestions = useSelector(state => state.appReducer.templateQuestions)
    const showModalDialog = useSelector(state => state.builderReducer.showModalDialog)
    const showShortCuts = useSelector(state => state.appDashboardReducer.showShortCuts)
    const formUserInfoText = useSelector(state => state.builderReducer.formUserInfoText)
    const welcomeScreen = useSelector(state => state.appReducer.formData?.welcome_screen)
    const formUserInfoCheck = useSelector(state => state.builderReducer.formUserInfoCheck)
    const builderCreationSource = useSelector(state => state.appReducer.builderCreationSource)
    const sectionTitleToFocus = useSelector(state => state.builderReducer.sectionTitleToFocus)
    const optionDeleteAction = useSelector(state => state.builderReducer.optionDeleteAction)
    const showOptionDeletePopUp = useSelector(state => state.builderReducer.showOptionDeletePopUp)
    const showPremiumPopUp = useSelector(state => state.builderReducer.showPremiumPopUp)
    const currentlySelectedMenuItem = useSelector(state => state.builderReducer.currentlySelectedMenuItem)
    const formAbuseAndBlockedWordsList = useSelector(state => state.builderReducer.formAbuseAndBlockedWordsList)
    const formSuggestionsFromLocalStorage = useSelector(state => state.autocompleteReducer?.formSuggestionsFromLocalStorage)
    const showImportQuestionsDialog = useSelector(state => state.importQuestionsReducer?.showImportQuestionsDialog)
    const showGridQtChangeNotAllowedPopUp = useSelector(state => state.appReducer.showGridQtChangeNotAllowedPopUp)
    const showColumnOrRowLimitReachedPopUp = useSelector(state => state.appReducer.showColumnOrRowLimitReachedPopUp)
    const showMobileImportQuestionScreen = useSelector(state => state.importQuestionsReducer?.showMobileImportQuestionsScreen)
    const isDragAndDropDisabled = useSelector(state => state.builderReducer.isDragAndDropDisabled)
    const builderToastMessage = useSelector(state => state.builderReducer?.builderToastMessage)
    const builderMessage = useSelector(state => state.builderReducer?.builderMessage)
    const [initializationComplete, setInitializationComplete] = useState(false)
    const [shareMenuIsVisible, setShareMenuIsVisible] = useState(false)
    const [showMobileQTPopUpContainer, setShowMobileQTPopUpContainer] = useState(false)
    const [currentContainerTitle, setCurrentContainerTitle] = useState(null)
    const [questionNumberInfo, setQuestionNumberInfo] = useState([])
    const [hideBottomBar, setHideBottomBar] = useState(false)

    const [showEditRightsRemoved, setShowEditRightsRemoved] = useState(false)
    const [showUnapprovedFormDialog, setShowUnapprovedFormDialog] = useState(false)
    const [screenHeight, setScreenHeight] = useState(0)
    const [showLoader, setShowLoader] = useState(false)
    const [showQTsMenuFromAddQuestionButton, setShowQTsMenuFromAddQuestionButton] = useState(false)

    const refModalDialogButtons = useRef([])
    const refSubmissionError = useRef(SUBMISSION_ERRORS.NONE)
    const refModalTitle = useRef('')
    const refModalBody = useRef('')
    const refFormID = useRef('')
    const refIncompleteQuestionInfo = useRef(null)
    const refSavedFormData = useRef(null)
    const refCardsContainer = useRef()
    const refPopUpContainer = useRef()
    const refBuilderCreationSource = useRef(builderCreationSource)
    const refFeaturesInWebAppOnly = useRef([])

    const {} = useFormImportManager()

    let unsavedChanges = useRef(false)

    const initializeFormData = () => {
        switch (builderCreationSource) {
            case BUILDER_CREATION_SOURCE.NEW:
                return getFormBuilderSkeleton(BUILDER_CREATION_SOURCE.NEW, {
                        userID: userId
                    }
                )
            case BUILDER_CREATION_SOURCE.TEMPLATE:
                return getFormBuilderSkeleton(BUILDER_CREATION_SOURCE.TEMPLATE, {
                        userID: userId,
                        title: welcomeScreen?.title,
                        desc: welcomeScreen?.description,
                        theme: templateTheme,
                        welcomeScreenSettings: {
                            show_button: welcomeScreen?.show_button,
                            show_screen: true,
                            button_text: welcomeScreen?.button_text
                        },
                        templateQuestions: templateQuestions,
                    }
                )
            // SAVED and DUPLICATED forms are updated when the data is retrieved in the load useEffect[].
            case BUILDER_CREATION_SOURCE.DUPLICATE:
            case BUILDER_CREATION_SOURCE.SAVED:
                return null
        }
    }

    document.onkeydown = (e) => {
        if (e.key === "Escape") {
            if (showModalDialog) {
                handleCloseSubmissionErrorDialog()
            } else if (showQTsMenuFromAddQuestionButton) {
                setShowQTsMenuFromAddQuestionButton(false)
            } else if (showShortCuts) {
                dispatch({type: APP_DASHBOARD_ACTION_TYPE.SET_SHOW_SHORTCUTS, payload: false})
            } else {
                window.location.hash = PAGE_HASH_LOCATION.dashboard
            }
        } else if (!formData?.pages[0]?.questions?.length && e.key === "/") {
            e.preventDefault()
            handleOutSideClick()
            setShowQTsMenuFromAddQuestionButton(true)
        } else if (window.event.ctrlKey && (e.key === "s" || e.key === "S")) {
            e.preventDefault()
            submitForm()
        } else if (window.event.ctrlKey && window.event.shiftKey && e.key === "?") {
            e.preventDefault()
            dispatch({type: APP_DASHBOARD_ACTION_TYPE.SET_SHOW_SHORTCUTS, payload: true})
        }
    }

    useEffect(() => {
        if (!shared) {
            dispatch({type: APP_ACTION_TYPE.SET_FORM_DATA, payload: initializeFormData()})
        }
        dispatch({type: BUILDER_ACTION_TYPE.SET_FORM_USER_INFO_CHECK, payload: false})
        dispatch({type: BUILDER_ACTION_TYPE.SET_CURRENTLY_SELECTED_MENU_ITEM, payload: CURRENTLY_SELECTED_MENU.BUILDER})
        dispatch({type: BUILDER_ACTION_TYPE.SET_SHOW_PREVIEW_PANE, payload: true})
        if (BUILDER_CREATION_SOURCE.NEW || BUILDER_CREATION_SOURCE.DUPLICATE) {
            dispatch({type: COLLABORATOR_ACTION_TYPE.UPDATE_COLLABORATOR_DATA, payload: []})
        }
        resetFocusTitle(true)
        window.location.hash = PAGE_HASH_LOCATION.form_builder

        updateContainerHelper(BuilderMenuItem, currentContainerTitle, setBuilderMenuItems, setCurrentContainerTitle)

        const getDataSuccessFunction = res => {
            let retrievedFormData = getFormBuilderSkeleton(BUILDER_CREATION_SOURCE.NEW, {
                userID: shared ? res.user_id : userId
            })

            if (res.user_info_form) {
                retrievedFormData.user_info_form = res.user_info_form
                dispatch({type: BUILDER_ACTION_TYPE.SET_FORM_USER_INFO_TEXT, payload: res.user_info_form.user_info_1})
                dispatch({type: BUILDER_ACTION_TYPE.SET_FORM_USER_INFO_CHECK, payload: true})
            } else {
                dispatch({type: BUILDER_ACTION_TYPE.SET_FORM_USER_INFO_CHECK, payload: false})
            }

            Object.keys(retrievedFormData.welcome_screen).map((obj) => {
                retrievedFormData.welcome_screen[obj] = res.welcome_screen[obj]

                if (builderCreationSource === BUILDER_CREATION_SOURCE.DUPLICATE && obj === 'title') {
                    retrievedFormData.welcome_screen[obj] = `${retrievedFormData.welcome_screen[obj]} ` + '(' + t(translationKeys.copy) + ')'
                    dispatch({type: APP_ACTION_TYPE.SET_FORM_DATA, payload: retrievedFormData})
                    dispatch({type: APP_ACTION_TYPE.SET_SHARED, payload: false})
                }
            })

            Object.keys(res.setting).map((obj) => {
                retrievedFormData.setting[obj] = res.setting[obj]
            })

            retrievedFormData.theme = res.theme
            retrievedFormData.date_created = res.date_created
            retrievedFormData.date_edited = res.date_edited
            retrievedFormData.date_favoured = res.date_favoured
            retrievedFormData.is_logic_enabled = res.is_logic_enabled
            retrievedFormData.template_category = res.template_category
            retrievedFormData.collaborators = res.collaborators

            if (res.subscription_data) {
                retrievedFormData.subscription_data = res.subscription_data
            }

            if (builderCreationSource === BUILDER_CREATION_SOURCE.DUPLICATE) {
                retrievedFormData.response_count = 0
                retrievedFormData.setting.passcode = null
            } else {
                retrievedFormData.last_edited_by = res.last_edited_by
                retrievedFormData.response_count = res.response_count
            }

            let updatedQuestions = [...retrievedFormData.pages[0].questions]

            res.pages[0].questions.map((q) => {
                q.tempAttachmentValue = q.attachment?.url ?? ''
                updatedQuestions.push(q)
            })

            retrievedFormData.pages[0].questions = updatedQuestions
            retrievedFormData._id = res._id
            refSavedFormData.current = JSON.parse(JSON.stringify(retrievedFormData))
            dispatch({type: APP_ACTION_TYPE.SET_FORM_DATA, payload: retrievedFormData})
        }
        const getDataFailureFunction = err => {
            console.log(err)
            return null
        }

        switch (refBuilderCreationSource.current) {
            case BUILDER_CREATION_SOURCE.DUPLICATE:
                setShowLoader(true)
                // formId is the Form that should be duplicated.
                getSpecificFormData(formId, getDataSuccessFunction, getDataFailureFunction)
                break
            case BUILDER_CREATION_SOURCE.SAVED:
                refFormID.current = formId
                setShowLoader(true)
                getSpecificFormData(formId, getDataSuccessFunction, getDataFailureFunction)
                break
            case  BUILDER_CREATION_SOURCE.NEW:
            case BUILDER_CREATION_SOURCE.TEMPLATE:
                initializeHelperVars(formData, dispatch, false, builderCreationSource === BUILDER_CREATION_SOURCE.NEW, refModalDialogButtons, refModalBody, refModalTitle, refBuilderCreationSource, refCardsContainer, isMobile)
                break
        }

        dispatch({type: AUTOCOMPLETE_ACTION_TYPE.SET_BUILDER_TYPE, payload: BUILDER_TYPE.FORM})
        dispatch({type: AUTOCOMPLETE_ACTION_TYPE.CLEAR_SELECTED_VALUE})
        setShowLoader(false)

        window.addEventListener('popstate', onBackButtonEvent)

        return () => {
            window.removeEventListener('popstate', onBackButtonEvent)
            unsavedChanges.current = false
        }
    }, [])

    useEffect(() => {
        if (formAbuseAndBlockedWordsList === null) {
            const successFunction = (blocked) => {
                getWords(WORD_TYPE.ABUSE, (abuse) => {
                    const abuseAndBlockedWords = abuse.concat(blocked)
                    dispatch({
                        type: BUILDER_ACTION_TYPE.SET_FORM_ABUSE_AND_BLOCKED_WORDS_LIST,
                        payload: abuseAndBlockedWords
                    })
                    if (formSuggestionsFromLocalStorage) {
                        let updatedLocalStorageItems = [...formSuggestionsFromLocalStorage]
                        abuseAndBlockedWords.map((words) => {
                            updatedLocalStorageItems = updatedLocalStorageItems.filter((item) => !item.question.includes(words.word))
                        })
                        localStorage.setItem(BUILDER_SUGGESTION_TYPES_FOR_LOCAL_STORAGE.FORM_QUESTION.key, JSON.stringify(updatedLocalStorageItems))
                    }
                }, failureFunction)
            }
            const failureFunction = (err) => {
                console.log(err)
            }
            getWords(WORD_TYPE.BLOCKED, successFunction, failureFunction)
        }
    }, [])

    const checkForNewQuestions = () => {
        const newQuestions = getNewQuestionsForAutocomplete(formData, suggestions)

        if (newQuestions.length > 0) {
            let filteredBlockedAndAbuseWords = newQuestions
            formAbuseAndBlockedWordsList.map((words) => {
                filteredBlockedAndAbuseWords = filteredBlockedAndAbuseWords.filter((item) => !item.includes(words.word))
            })
            dispatch({
                type: AUTOCOMPLETE_ACTION_TYPE.STORE_NEW_QUESTIONS_IN_LOCAL_STORAGE,
                payload: filteredBlockedAndAbuseWords
            })
        }
    }

    const submitForm = () => {
        /**
         *
         * @param {SUBMISSION_ERRORS} submissionError
         * @param {string} title
         * @param {[]} body
         * @param {object} incompleteQuestionInfo
         */
        resetFocusTitle(false)

        const populateErrorInfo = (submissionError, title, body, incompleteQuestionInfo) => {
            refSubmissionError.current = submissionError
            refModalTitle.current = title
            refModalBody.current = body
            refIncompleteQuestionInfo.current = incompleteQuestionInfo
            showSubmissionError()

            if (currentlySelectedMenuItem !== CURRENTLY_SELECTED_MENU.BUILDER) {
                updateContainer(BuilderMenuItem)
            }
        }

        if (formData.welcome_screen.title.trim() === '') {
            populateErrorInfo(SUBMISSION_ERRORS.MISSING_TITLE, t(translationKeys.title_required), [t(translationKeys.form_name_alert)], null)
            return
        }

        if (formData.pages[0].questions.filter(q => q.type !== QUESTION_TYPE.SECTION.key).length === 0) {
            populateErrorInfo(SUBMISSION_ERRORS.MISSING_QUESTIONS, t(translationKeys.add_question), [t(translationKeys.add_question_alert)], null)
            return
        }

        let incompleteQuestionInfo = incompleteQuestionCount(formData.pages[0].questions)

        if (incompleteQuestionInfo.count > 0) {
            populateErrorInfo(SUBMISSION_ERRORS.INCOMPLETE_QUESTIONS, t(translationKeys.incomplete_questions) + `${incompleteQuestionInfo.count}`, ['*' + t(translationKeys.title_required)], incompleteQuestionInfo)
            if (incompleteQuestionInfo.choiceMissing) {
                populateErrorInfo(SUBMISSION_ERRORS.INCOMPLETE_QUESTIONS, t(translationKeys.incomplete_questions) + `${incompleteQuestionInfo.count}`, ['*' + t(translationKeys.options_required)], incompleteQuestionInfo)
            }
            return
        }

        if (formUserInfoCheck) {
            formData.user_info_form = {
                user_info_1: formUserInfoText?.trim()?.length ? formUserInfoText.trim() : t(translationKeys.name_s)
            }
        } else {
            formData.user_info_form = null
        }

        if (formData?.setting?.confirmation_message?.trim().length > 0) {
            formData.setting.confirmation_message = formData.setting.confirmation_message.trim()
        }

        if (refBuilderCreationSource.current === BUILDER_CREATION_SOURCE.SAVED) {
            if (!unsavedChanges.current && !dataHasChanged(store.getState().appReducer.formData, refSavedFormData)) {
                setShareMenuIsVisible(true)
                if (isMobileOnly) {
                    dispatch({type: APP_ACTION_TYPE.SET_TEMPLATE_THEME, payload: null})
                    dispatch({type: APP_ACTION_TYPE.SET_IS_ANSWER_SHEET, payload: false})
                    dispatch({type: APP_ACTION_TYPE.SET_CURRENT_PAGE, payload: Pages.share})
                }
                return
            }

            // Update existing Form in the DB
            formData.date_edited = new Date().getTime().toString()

            let webAppVersionInfo = getWebAppVersionInfo(BUILDER_TYPE.FORM, formData)

            formData.web_app_version = webAppVersionInfo.version
            formData.updation_source = WEB_APP
            formData.last_edited_by = userId

            const updateSuccessFunction = (result) => {
                registerGAEvent('Builder', 'form-submit', 'success')
                unsavedChanges.current = false

                if (result.user_can_save_data) {
                    if (result.formData.is_valid) {
                        refSavedFormData.current = result.formData
                        refFeaturesInWebAppOnly.current = webAppVersionInfo.featuresInWebAppOnly
                        setShareMenuIsVisible(true)
                        if (isMobileOnly) {
                            dispatch({type: APP_ACTION_TYPE.SET_FORM_DATA, payload: result.formData})
                            dispatch({type: APP_ACTION_TYPE.SET_IS_ANSWER_SHEET, payload: false})
                            dispatch({type: APP_ACTION_TYPE.SET_CURRENT_PAGE, payload: Pages.share})
                            dispatch({type: APP_ACTION_TYPE.SET_IS_QUIZ, payload: !!isQuiz})
                        }
                        dispatch({type: UPDATE_FORM_DATA, payload: result.formData})
                    } else {
                        // Blocked Words were found.
                        dispatch({type: APP_ACTION_TYPE.SET_FORM_DATA, payload: result.formData})
                        refSavedFormData.current = result.formData
                        dispatch({type: APP_DASHBOARD_ACTION_TYPE.SET_IS_SHOWING_MOBILE_FORM_CONTROL, payload: false})
                        setShowUnapprovedFormDialog(true)
                    }
                } else {
                    setShowEditRightsRemoved(true)
                }

                setShowLoader(false)
            }
            const updateFailureFunction = err => {
                console.log(err)
                setShowLoader(false)
            }

            delete formData['response_count']

            setShowLoader(true)
            getFormByFormId(refFormID.current, (res) => {
                if (res) {
                    // Remove "tempAttachmentValue' from Questions
                    // Remove "tempId' from Questions
                    removeProp([...formData.pages[0].questions], 'tempAttachmentValue')
                    removePropWRTData([...formData.pages[0].questions], "_id", "tempId")
                    formData.user_id = res.user_id
                    formData.collaborators = res.collaborators
                    updateExistingForm(refFormID.current, formData, updateSuccessFunction, updateFailureFunction)
                }
            }, (err) => {
                console.log(err)
            })
            checkForNewQuestions()
        } else if ((refBuilderCreationSource.current === BUILDER_CREATION_SOURCE.NEW || refBuilderCreationSource.current === BUILDER_CREATION_SOURCE.DUPLICATE || refBuilderCreationSource.current === BUILDER_CREATION_SOURCE.TEMPLATE) && (currentlySelectedMenuItem === CURRENTLY_SELECTED_MENU.SETTINGS)) {
            formData.creation_source = WEB_APP
            removeProp(formData, '_id')

            let webAppVersionInfo = getWebAppVersionInfo(BUILDER_TYPE.FORM, formData)

            formData.web_app_version = webAppVersionInfo.version
            formData.welcome_screen.title = formData.welcome_screen.title.trim()
            formData.welcome_screen.button_text = formData.welcome_screen.button_text.trim()
            formData.date_created = new Date().getTime().toString()
            formData.date_edited = new Date().getTime().toString()
            formData.collaborators = collaborator
            formData.date_favoured = null
            formData.user_id = userId

            const newSuccessFunction = result => {
                registerGAEvent('Builder', 'form-submit', 'success')
                unsavedChanges.current = false
                refSavedFormData.current = result.formData
                refBuilderCreationSource.current = BUILDER_CREATION_SOURCE.SAVED

                if (result.formData.is_valid) {
                    refFormID.current = result.form_id
                    dispatch({type: APP_ACTION_TYPE.SET_FORM_DATA, payload: result.formData})
                    isMobileOnly ? window.removeEventListener('popstate', onBackButtonEvent) : null
                    refFeaturesInWebAppOnly.current = webAppVersionInfo.featuresInWebAppOnly
                    setShareMenuIsVisible(true)

                    const updatedFormData = result.formData
                    updatedFormData._id = result.form_id
                    dispatch({type: APP_ACTION_TYPE.SET_FORM_DATA, payload: updatedFormData})
                    dispatch({type: UPDATE_FORM_DATA, payload: result.formData})
                    if (isMobileOnly) {
                        dispatch({type: APP_ACTION_TYPE.SET_IS_ANSWER_SHEET, payload: false})
                        dispatch({type: APP_ACTION_TYPE.SET_CURRENT_PAGE, payload: Pages.share})
                        dispatch({type: APP_ACTION_TYPE.SET_IS_QUIZ, payload: !!isQuiz})
                        dispatch({type: RESPONSES_ACTION_TYPE.SET_RESPONSE_COUNT, payload: null})
                    }
                } else {
                    // Blocked Words were found.
                    dispatch({type: APP_ACTION_TYPE.SET_FORM_DATA, payload: result.formData})
                    dispatch({type: APP_DASHBOARD_ACTION_TYPE.SET_IS_SHOWING_MOBILE_FORM_CONTROL, payload: false})
                    setShowUnapprovedFormDialog(true)
                }

                setShowLoader(false)
            }
            const newFailureFunction = err => {
                console.log(err)
                setShowLoader(false)
            }

            if (refBuilderCreationSource.current === BUILDER_CREATION_SOURCE.DUPLICATE) {
                formData.setting.is_active = true
            }

            // Remove "tempAttachmentValue' from Questions
            removeProp([...formData.pages[0].questions], 'tempAttachmentValue')

            delete formData['response_count']
            delete formData['_id']

            setShowLoader(true)

            createNewForm(formData, newSuccessFunction, newFailureFunction)
            checkForNewQuestions()
        } else if (currentlySelectedMenuItem !== CURRENTLY_SELECTED_MENU.SETTINGS) {
            // New or Duplicate Form ... Show Settings.
            updateContainer(SettingsMenuItem)
        }

        addMissingOptionValuesToMCQs()
    }

    const showSubmissionError = () => {
        try {
            resetFocusTitle(false)
            dispatch({type: BUILDER_ACTION_TYPE.SET_SECTION_TITLE_TO_FOCUS, payload: -1})

            refModalDialogButtons.current = [
                {
                    text: t(translationKeys.ok),
                    action:
                        () => {
                            handleCloseSubmissionErrorDialog()
                        }
                }
            ]

            if (refSubmissionError.current === SUBMISSION_ERRORS.MISSING_TITLE) {
                dispatch({type: BUILDER_ACTION_TYPE.SET_SHOW_MODAL_DIALOG, payload: true})
                return
            }

            if (refSubmissionError.current === SUBMISSION_ERRORS.MISSING_QUESTIONS) {
                dispatch({type: BUILDER_ACTION_TYPE.SET_SHOW_MODAL_DIALOG, payload: true})
                if (isMobileOnly) updateContainer(BuilderMenuItem)
                return
            }

            if (refSubmissionError.current === SUBMISSION_ERRORS.INCOMPLETE_QUESTIONS) {
                if (isMobileOnly) updateContainer(BuilderMenuItem)
                dispatch({type: BUILDER_ACTION_TYPE.SET_SHOW_MODAL_DIALOG, payload: true})
            }
        } catch (e) {
            console.log(e)
        }
    }
    const handleCloseSubmissionErrorDialog = () => {
        dispatch({type: BUILDER_ACTION_TYPE.SET_SHOW_MODAL_DIALOG, payload: false})

        switch (refSubmissionError.current) {
            case SUBMISSION_ERRORS.MISSING_TITLE:
                resetFocusTitle(true)
                break
            case SUBMISSION_ERRORS.MISSING_QUESTIONS:
                // Do Nothing
                break
            case SUBMISSION_ERRORS.INCOMPLETE_QUESTIONS:
                if (QUESTION_TYPE[formData.pages[0].questions[refIncompleteQuestionInfo.current.questionIndexToFocus].type] === QUESTION_TYPE.SECTION) {
                    dispatch({
                        type: BUILDER_ACTION_TYPE.SET_SECTION_TITLE_TO_FOCUS,
                        payload: refIncompleteQuestionInfo.current.questionIndexToFocus
                    })
                } else {
                    const updatedFormData = {...formData}
                    updatedFormData.pages[0].questions[refIncompleteQuestionInfo.current.questionIndexToFocus].setFocus = true
                    dispatch({type: APP_ACTION_TYPE.SET_FORM_DATA, payload: updatedFormData})
                }
                break
        }
    }
    const [builderMenuItems, setBuilderMenuItems] = useState(() => {
        if (isMobile) {
            return [...helperBuilderMenuItems(), new PopupMenuItem(3, t(translationKeys.submit), new MenuIconInfo("submit", 'submit_grey.svg'), submitForm)]
        } else {
            return helperBuilderMenuItems()
        }
    })

    const onBackButtonEvent = (e) => {
        if (window.location.hash !== PAGE_HASH_LOCATION.dashboard || !e) {
            /*if (!unsavedChanges.current && isMobile) {
                window.location.hash = PAGE_HASH_LOCATION.dashboard
            }*/

            return
        }

        if (isMobileOnly && builderCreationSource === BUILDER_CREATION_SOURCE.DUPLICATE) {
            const updatedFormData = {...formData}
            updatedFormData.welcome_screen.title = updatedFormData.welcome_screen.title.replace(`(${t(translationKeys.copy)})`, "")
            dispatch({type: APP_ACTION_TYPE.SET_FORM_DATA, payload: updatedFormData})
        }

        e.preventDefault()

        if (unsavedChanges.current) {
            if (isMobileOnly) {
                if (window.location.hash === PAGE_HASH_LOCATION.form_builder || (window.location.hash === '#form-control') || (window.location.hash === PAGE_HASH_LOCATION.dashboard)) {
                    if (window.confirm(t(translationKeys.you_havent_submitted_your_changes_is_it_okay_to_leave_without_submitting))) {
                        window.removeEventListener('popstate', onBackButtonEvent)
                        dispatch({type: APP_DASHBOARD_ACTION_TYPE.SET_IS_SHOWING_MOBILE_FORM_CONTROL, payload: false})
                        dispatch({type: APP_ACTION_TYPE.SET_CURRENT_PAGE, payload: Pages.dashboard})
                        dispatch({type: BUILDER_ACTION_TYPE.SET_NEW_QUESTION, payload: false})
                        dispatch({type: APP_DASHBOARD_ACTION_TYPE.SET_SHOW_FORMS})
                        window.location.hash = PAGE_HASH_LOCATION.dashboard
                    } else {
                        window.history.pushState(null, null, (window.location.pathname + PAGE_HASH_LOCATION.form_builder))
                    }
                } else {
                    window.removeEventListener('popstate', onBackButtonEvent)
                }
            } else {
                if (window.confirm(t(translationKeys.you_havent_submitted_your_changes_is_it_okay_to_leave_without_submitting))) {
                    dispatch({type: APP_ACTION_TYPE.SET_CURRENT_PAGE, payload: Pages.dashboard})
                    window.removeEventListener('popstate', onBackButtonEvent)
                    window.location.hash = PAGE_HASH_LOCATION.dashboard
                } else {
                    window.history.pushState(null, null, (window.location.pathname + PAGE_HASH_LOCATION.form_builder))
                }
            }
        } else {
            if (isMobileOnly) {
                window.location.hash = PAGE_HASH_LOCATION.dashboard
                dispatch({type: APP_ACTION_TYPE.SET_CURRENT_PAGE, payload: Pages.dashboard})
            }
        }
        dispatch({type: IMPORT_QUESTIONS_ACTION_TYPE.SET_SHOW_MOBILE_IMPORT_QUESTIONS_SCREEN, payload: false})
        dispatch({type: IMPORT_QUESTIONS_ACTION_TYPE.SET_SHOW_IMPORT_QUESTIONS_DIALOG, payload: false})
    }

    useEffect(() => {
        if (formData !== null && formData.pages) {
            if (templateTheme) {
                // Populate Template Theme
                let tempFormData = formData

                tempFormData.theme = templateTheme
                dispatch({type: APP_ACTION_TYPE.SET_FORM_DATA, payload: tempFormData})
            }

            initializeHelperVars(formData, dispatch, false, builderCreationSource === BUILDER_CREATION_SOURCE.NEW, refModalDialogButtons, refModalBody, refModalTitle, refBuilderCreationSource, refCardsContainer, isMobile)
            if (builderCreationSource === BUILDER_CREATION_SOURCE.TEMPLATE && !unsavedChanges.current) {
                formData.welcome_screen.show_screen = true
                formData.setting.show_question_number = true
                formData.user_id = userId
                formData._id = ''
            }

            setInitializationComplete(true)

            if (refBuilderCreationSource.current === BUILDER_CREATION_SOURCE.NEW && (questionHasBeenAdded(formData)) || refBuilderCreationSource.current !== BUILDER_CREATION_SOURCE.NEW) {
                // Since 'dataHasChanged' removes the question._id property, we store 'refSavedFormData' temporarily and then add it back after the comparison so that the '_id' can be preserved.
                // 'question._id' is needed when checking if a Question is new or not.
                const savedFormDataBeforeCompare = JSON.parse(JSON.stringify(refSavedFormData.current))

                unsavedChanges.current = dataHasChanged(store.getState().appReducer.formData, refSavedFormData)

                refSavedFormData.current = savedFormDataBeforeCompare
            } else {
                unsavedChanges.current = false
            }

            if (builderCreationSource === BUILDER_CREATION_SOURCE.TEMPLATE && !unsavedChanges.current) {
                // unsavedChanges.current = true
                formData.welcome_screen.show_screen = true
                formData.setting.show_question_number = true
                formData._id = ''
            }

            if (formData.pages[0]?.questions?.length === 0) {
                setShowQTsMenuFromAddQuestionButton(false)
            } else {
                // Store Question Numbers by excluding QUESTION_TYPE.SECTION from Questions
                let tempQuestionNumberInfo = []

                formData.pages[0]?.questions?.map((question, index) => (
                        () => {
                            if (QUESTION_TYPE[question.type] !== QUESTION_TYPE.SECTION) {
                                tempQuestionNumberInfo.push({
                                    questionIndex: index,
                                    questionNumber: tempQuestionNumberInfo.length + 1
                                })
                            }
                        }
                    )()
                )

                setQuestionNumberInfo(tempQuestionNumberInfo)
            }
        }
    }, [formData])

    useEffect(() => {
        if (unsavedChanges.current) {
            addBeforeUnloadEvent()
        } else {
            removeBeforeUnloadEvent()
        }
    }, [unsavedChanges.current])

    useEffect(() => {
        if (sectionTitleToFocus > -1) {
            dispatch({type: BUILDER_ACTION_TYPE.SET_SECTION_TITLE_TO_FOCUS, payload: -1})
        }
    }, [sectionTitleToFocus])

    const warningText = t(translationKeys.you_have_unsaved_changes_you_sure_you_wish_leave_this_page)
    const handleWindowClose = (e) => {
        if (!unsavedChanges.current) return
        e.preventDefault()
        return (e.returnValue = warningText)
    }

    const addBeforeUnloadEvent = () => {
        window.addEventListener('beforeunload', handleWindowClose)
    }
    const removeBeforeUnloadEvent = () => {
        window.removeEventListener('beforeunload', handleWindowClose)
    }

    const handleAppIconClick = () => {
        doNavigation(SurveyHeartMenuItem)

        if (unsavedChanges.current) {
            onBackButtonEvent()
        } else {
            dispatch({type: APP_ACTION_TYPE.SET_CURRENT_PAGE, payload: Pages.dashboard})
        }
    }

    const addMissingOptionValuesToMCQs = () => {
        if (!formData) {
            return
        }

        // Make sure all "Choice" type questions have a value.
        let optionAdded = false
        let updatedFormData = formData

        let tempQuestions = updatedFormData.pages[0].questions.map((question) => {
            if (QUESTION_TYPE[question.type] === QUESTION_TYPE.CHECKBOX_CHOICE || QUESTION_TYPE[question.type] === QUESTION_TYPE.DROPDOWN_CHOICE || QUESTION_TYPE[question.type] === QUESTION_TYPE.MULTIPLE_CHOICE) {
                optionAdded = addMissingChoiceOptions(question, optionAdded)
            }

            if (optionAdded) {
                question.refreshOptions = true
            }

            return question
        })

        if (optionAdded) {
            updatedFormData.pages[0].questions = tempQuestions
            dispatch({type: APP_ACTION_TYPE.SET_FORM_DATA, payload: {...updatedFormData}})
        }
    }

    const updateSetting = (setting, value) => {
        let updatedFormData = {...formData}

        switch (setting) {
            case FORM_SETTINGS.FORM_TITLE:
                updatedFormData.welcome_screen.title = value
                break
            case FORM_SETTINGS.FORM_DESC:
                updatedFormData.welcome_screen.description = value
                break
            case FORM_SETTINGS.ALLOW_MULTIPLE_SUBMIT:
                updatedFormData.setting.allow_multiple_submit = value
                break
            case FORM_SETTINGS.ALLOW_SUMMARY_VIEW:
                updatedFormData.setting.allow_summary_view = value
                break
            case FORM_SETTINGS.IS_SHUFFLED:
                updatedFormData.setting.is_shuffled = value
                break
            case FORM_SETTINGS.SHOW_LOGO:
                if (value === false && (formData.setting.is_show_logo === undefined)) {
                    delete updatedFormData.setting.is_show_logo
                } else {
                    updatedFormData.setting.is_show_logo = value
                }
                break
            case FORM_SETTINGS.SHOW_QUESTION_NUMBER:
                updatedFormData.setting.show_question_number = value
                break
            case FORM_SETTINGS.SHOW_WELCOME_PAGE:
                updatedFormData.welcome_screen.show_screen = value
                break
            case FORM_SETTINGS.START_BUTTON_TEXT:
                updatedFormData.welcome_screen.button_text = value
                break
            case FORM_SETTINGS.THEME:
                updatedFormData.theme = value
                break
            case FORM_SETTINGS.ESTIMATED_TIME:
                updatedFormData.setting.show_estimated_time = value
                break
            case FORM_SETTINGS.SHOW_QUESTION_COUNT:
                updatedFormData.setting.show_question_count = value
                break
            case FORM_SETTINGS.PASSCODE:
                if (value === 'delete') {
                    delete updatedFormData.setting.passcode
                } else {
                    updatedFormData.setting.passcode = value
                }
                break
            case FORM_SETTINGS.CONFIRMATION_MESSAGE:
                updatedFormData.setting.confirmation_message = value
                break
            case FORM_SETTINGS.COLLECT_EMAIL:
                updatedFormData.setting.is_email = value
                break
            case FORM_SETTINGS.ALLOW_RESPONSE_EDIT:
                updatedFormData.setting.allow_response_edit = value
                break
            case FORM_SETTINGS.AUTO_OPEN_TIME:
                if (value === 'delete') {
                    delete updatedFormData.setting.auto_open_time
                } else {
                    updatedFormData.setting.auto_open_time = value
                }
                break
            case FORM_SETTINGS.AUTO_CLOSE_TIME:
                if (value === 'delete') {
                    delete updatedFormData.setting.auto_close_time
                } else {
                    updatedFormData.setting.auto_close_time = value
                }
                break
        }

        updatedFormData.date_edited = Date.now().toString()
        dispatch({type: APP_ACTION_TYPE.SET_FORM_DATA, payload: updatedFormData})
    }

    const showSettingsContainer = () => {
        updateContainerHelper(SettingsMenuItem, currentContainerTitle, setBuilderMenuItems, setCurrentContainerTitle)
    }

    const showThemesContainer = () => {
        isMobileOnly ? updateContainer(ThemesMenuItem) : setCurrentContainerTitle("ThemesContainer")
    }

    const updateContainer = clickedItem => {
        if (!showLoader) {
            if (clickedItem === SettingsMenuItem) {
                registerGAEvent('Builder', 'Setting', 'view')
                dispatch({
                    type: BUILDER_ACTION_TYPE.SET_CURRENTLY_SELECTED_MENU_ITEM,
                    payload: CURRENTLY_SELECTED_MENU.SETTINGS
                })
                addMissingOptionValuesToMCQs()
            } else if (clickedItem === BuilderMenuItem) {
                registerGAEvent('Builder', 'Builder', 'view')
                dispatch({
                    type: BUILDER_ACTION_TYPE.SET_CURRENTLY_SELECTED_MENU_ITEM,
                    payload: CURRENTLY_SELECTED_MENU.BUILDER
                })
            } else if (isMobileOnly && clickedItem === PreviewMenuItem) {
                const populateErrorInfo = (submissionError, title, body, incompleteQuestionInfo) => {
                    refSubmissionError.current = submissionError
                    refModalTitle.current = title
                    refModalBody.current = body
                    refIncompleteQuestionInfo.current = incompleteQuestionInfo
                    showSubmissionError()

                    if (currentlySelectedMenuItem !== CURRENTLY_SELECTED_MENU.BUILDER) {
                        updateContainer(BuilderMenuItem)
                    }
                }
                if (formData.welcome_screen.title.trim() === '') {
                    populateErrorInfo(SUBMISSION_ERRORS.MISSING_TITLE, t(translationKeys.title_required), [t(translationKeys.form_name_alert)], null)
                    return
                }

                if (formData.pages[0].questions.filter(q => q.type !== QUESTION_TYPE.SECTION.key).length === 0) {
                    populateErrorInfo(SUBMISSION_ERRORS.MISSING_QUESTIONS, t(translationKeys.add_question), [t(translationKeys.add_question_alert)], null)
                    return
                }

                let incompleteQuestionInfo = incompleteQuestionCount(formData.pages[0].questions)

                if (incompleteQuestionInfo.count > 0) {
                    populateErrorInfo(SUBMISSION_ERRORS.INCOMPLETE_QUESTIONS, t(translationKeys.incomplete_questions) + `${incompleteQuestionInfo.count}`, ['*' + t(translationKeys.title_required)], incompleteQuestionInfo)
                    if (incompleteQuestionInfo.choiceMissing) {
                        populateErrorInfo(SUBMISSION_ERRORS.INCOMPLETE_QUESTIONS, t(translationKeys.incomplete_questions) + `${incompleteQuestionInfo.count}`, ['*' + t(translationKeys.options_required)], incompleteQuestionInfo)
                    }
                    return
                }
                registerGAEvent('Builder', 'Preview', 'view')
                dispatch({
                    type: BUILDER_ACTION_TYPE.SET_CURRENTLY_SELECTED_MENU_ITEM,
                    payload: CURRENTLY_SELECTED_MENU.PREVIEW
                })
            }
            dispatch({type: BUILDER_ACTION_TYPE.SET_NEW_QUESTION, payload: false})
            updateContainerHelper(clickedItem, currentContainerTitle, setBuilderMenuItems, setCurrentContainerTitle)
        }
    }

    const shareScreenVisibilityHandler = () => {
        setShareMenuIsVisible(false)
        dispatch({type: APP_ACTION_TYPE.SET_CURRENT_PAGE, payload: Pages.dashboard})

        if (isMobile) {
            window.location.hash = PAGE_HASH_LOCATION.dashboard
        }
    }

    if (isMobileOnly) {
        document.body.onresize = () => {
            if (window.innerHeight < 400) {
                //for not shrink the height while keyboard opened.
                setScreenHeight(document.documentElement.clientHeight)
            } else {
                //to handle the chrome url tab shown/hidden screen adjustment in Mobile view.
                setScreenHeight(window.innerHeight)
            }
        }
    }

    const ShowQuestionTypesMenuFromAddQuestion = (e) => {
        e.nativeEvent.stopImmediatePropagation()

        // Toggle showing the menu
        setShowQTsMenuFromAddQuestionButton(true)
    }

    const handleOutSideClick = () => {
        document.onclick = e => {
            e.stopPropagation();
            if (refPopUpContainer.current && !refPopUpContainer.current.contains(e.target)) {
                document.onclick = null;
                setShowMobileQTPopUpContainer(false)
                setShowQTsMenuFromAddQuestionButton(false)
            }
        };
    }

    const handleOnDragEnd = (result) => {
        if (!result.destination) return
        const updatedFormData = {...formData}
        const [reorderedQuestions] = updatedFormData.pages[0].questions.splice(result.source.index, 1)
        updatedFormData.pages[0].questions.splice(result.destination.index, 0, reorderedQuestions)
        dispatch({type: BUILDER_ACTION_TYPE.SET_NEW_QUESTION, payload: false})
        dispatch({type: APP_ACTION_TYPE.SET_FORM_DATA, payload: updatedFormData})
    }

    const handleOnDragStart = () => {
        dispatch({type: AUTOCOMPLETE_ACTION_TYPE.SET_SELECTED_VALUE, payload: ''})
    }

    return (
        (() => {
            if (!initializationComplete) return (
                <Loader height='100%' width='100%' left='0px'/>
            )

            if (isMobileOnly) return (
                // ************************** Mobile Version Starts ************************** //
                <div id={'app-container'} className='app-container'
                     style={screenHeight ? {height: screenHeight} : null}>
                    {showMobileImportQuestionScreen ? <ImportQuestionMobileScreen/> :
                        <div className='body-container-mobile'>
                            {
                                (() => {
                                    if (currentContainerTitle === BuilderMenuItem.getTitle()) return (
                                        <>
                                            <div className='builder-box1'>
                                                <div className='form-builder-body-container'>
                                                    <div className="builder-container" id="builder-container">
                                                        <div className="builder-question-container"
                                                             id="builder-question-container">
                                                            <TitleSectionComponent {...getTitleProps()} />

                                                            {
                                                                (() => {
                                                                    if (showMobileQTPopUpContainer && showQTsMenuFromAddQuestionButton) return (
                                                                        <div style={{
                                                                            top: 0,
                                                                            backgroundColor: 'rgba(0, 0, 0, 0.3)',
                                                                            position: 'fixed',
                                                                            width: '100%',
                                                                            height: '100%',
                                                                            zIndex: '11',
                                                                            display: 'flex',
                                                                            alignItems: 'center',
                                                                            justifyContent: 'center'
                                                                        }}
                                                                             onClick={e => handleOutSideClick(e)}
                                                                        >
                                                                            <div ref={refPopUpContainer} style={{display: "contents"}}>
                                                                                <QuestionTypePopup
                                                                                    questionIndex={formData?.pages[0]?.questions?.length}
                                                                                    setShowMobileQTPopUpContainer={setShowMobileQTPopUpContainer}/>
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                })()
                                                            }
                                                        </div>
                                                    </div>
                                                    {
                                                        (() => {
                                                            if (formData?.pages[0].questions.length > 0) return (
                                                                <div className='builder-forms-container'
                                                                     style={{marginBottom: '65px'}}>
                                                                    <DragDropContext onDragStart={handleOnDragStart}
                                                                                     onDragEnd={handleOnDragEnd}>
                                                                        <Droppable droppableId="characters">
                                                                            {(provided) => (
                                                                                <div {...provided.droppableProps}
                                                                                     ref={provided.innerRef}
                                                                                     style={{width: "100%"}}>
                                                                                    <div ref={refCardsContainer}
                                                                                         className="builder-cards-container">
                                                                                        {formData.pages[0].questions.map((question, index) => (
                                                                                            () => {
                                                                                                let questionGroup = getQuestionGroupV2(QUESTION_TYPE[question.type])

                                                                                                if (questionGroup === QUESTION_GROUPS.SECTION) {
                                                                                                    return (
                                                                                                        <>
                                                                                                            {formData?.is_logic_enabled ?
                                                                                                                <div>
                                                                                                                    <TitleSectionComponent
                                                                                                                        index={index} {...getSectionProps(question, index)}/>
                                                                                                                </div> :
                                                                                                                <Draggable
                                                                                                                    key={question._id}
                                                                                                                    draggableId={question._id}
                                                                                                                    index={index}
                                                                                                                    isDragDisabled={true}>
                                                                                                                    {(provided) => (
                                                                                                                        <div
                                                                                                                            ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                                                                                            <TitleSectionComponent
                                                                                                                                index={index} {...getSectionProps(question, index)}/>
                                                                                                                        </div>)}
                                                                                                                </Draggable>
                                                                                                            }
                                                                                                        </>
                                                                                                    )
                                                                                                } else {
                                                                                                    let qnInfo = questionNumberInfo.filter(questionInfo => questionInfo.questionIndex === index)

                                                                                                    popInitialSelectMenuItems(question.type)

                                                                                                    return (
                                                                                                        <>
                                                                                                            {formData?.is_logic_enabled ?
                                                                                                                <div>
                                                                                                                    <MobileQuestionContainer
                                                                                                                        index={index}
                                                                                                                        initialImage={popInitialSelectMenuItems(question.type)}
                                                                                                                        addMissingOptionValuesToMCQs={addMissingOptionValuesToMCQs}
                                                                                                                        key={index} {...getQuestionProps(question, index, qnInfo, userId, isMobile, setHideBottomBar, refCardsContainer)}
                                                                                                                        refSavedData={refSavedFormData}
                                                                                                                        formData={formData}/>
                                                                                                                </div> :
                                                                                                                <Draggable
                                                                                                                    key={question._id}
                                                                                                                    draggableId={question._id}
                                                                                                                    index={index}
                                                                                                                    isDragDisabled={isDragAndDropDisabled}>
                                                                                                                    {(provided) => (
                                                                                                                        <div
                                                                                                                            ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                                                                                            <MobileQuestionContainer
                                                                                                                                index={index}
                                                                                                                                initialImage={popInitialSelectMenuItems(question.type)}
                                                                                                                                addMissingOptionValuesToMCQs={addMissingOptionValuesToMCQs}
                                                                                                                                key={index} {...getQuestionProps(question, index, qnInfo, userId, isMobile, setHideBottomBar, refCardsContainer)}
                                                                                                                                refSavedData={refSavedFormData}
                                                                                                                                formData={formData}/>
                                                                                                                        </div>)}
                                                                                                                </Draggable>
                                                                                                            }
                                                                                                        </>
                                                                                                    )
                                                                                                }
                                                                                            })()
                                                                                        )
                                                                                        }
                                                                                    </div>
                                                                                </div>)}
                                                                        </Droppable>
                                                                    </DragDropContext>
                                                                </div>
                                                            )
                                                        })()
                                                    }
                                                </div>
                                            </div>
                                        </>
                                    )
                                    if (currentContainerTitle === PreviewMenuItem.getTitle()) return (
                                        <>
                                            <PreviewContainer formData={previewData}/>
                                        </>
                                    )
                                    if (currentContainerTitle === SettingsMenuItem.getTitle()) return (
                                        <div className='builder-wrapper' style={{gridTemplateColumns: '100%'}}>
                                            <FormSettingsContainer updateContainer={updateContainer}
                                                                   showThemesContainer={showThemesContainer}
                                                                   updateSetting={updateSetting}
                                                                   submitSettings={submitForm}
                                                                   formData={formData}
                                                                   currentContainerTitle={currentContainerTitle}
                                            />
                                        </div>
                                    )
                                    if (currentContainerTitle === "ThemesContainer") return (
                                        <div className='builder-wrapper'
                                             style={{gridTemplateColumns: '100%'}}>
                                            <ThemesContainer chosenTheme={formData?.theme}
                                                             changeContainer={showSettingsContainer}
                                                             updateContainer={updateContainer}
                                                             updateSetting={updateSetting}
                                                             currentContainerTitle={currentContainerTitle}
                                            />
                                        </div>
                                    )
                                })()
                            }
                            {
                                (() => {
                                    if (!hideBottomBar) return (
                                        <BottomBar BottomBarMenuItems={builderMenuItems}
                                                   isCenterFABPresent={currentContainerTitle === BuilderMenuItem.getTitle()}
                                                   centerFABClickFunction={(e) => {
                                                       setShowMobileQTPopUpContainer(true)
                                                       handleOutSideClick()
                                                       ShowQuestionTypesMenuFromAddQuestion(e)
                                                   }}
                                                   doNavigation={doNavigation}
                                                   doPopupAction={submitForm}
                                                   updateContainer={updateContainer}
                                        />
                                    )
                                })()
                            }
                            {
                                (() => {
                                        if (showUnapprovedFormDialog) return unapprovedModalDialog(formData, userId, BUILDER_TYPE.FORM, () => {
                                            setShowUnapprovedFormDialog(false)
                                            dispatch({type: APP_DASHBOARD_ACTION_TYPE.SET_SHOW_FORMS})
                                            dispatch({type: APP_ACTION_TYPE.SET_CURRENT_PAGE, payload: Pages.dashboard})
                                        })
                                    }
                                )()
                            }
                            {
                                (() => {
                                        if (showEditRightsRemoved)
                                            return editRightsRemovedDialog(BUILDER_TYPE.FORM, () => {
                                                setShowEditRightsRemoved(false)
                                                dispatch({
                                                    type: APP_ACTION_TYPE.SET_CURRENT_PAGE,
                                                    payload: Pages.dashboard
                                                })
                                                doNavigation(SurveyHeartMenuItem)
                                            })
                                    }
                                )()
                            }
                        </div>}
                    {
                        (() => {
                                if (showModalDialog)
                                    return (
                                        <div>
                                            <ModalDialog
                                                header={refModalTitle.current}
                                                body={refModalBody.current}
                                                buttons={refModalDialogButtons.current}
                                            />
                                        </div>
                                    )
                            }
                        )()
                    }
                    {showPremiumPopUp ? <ModalDialog header={"PREMIUM FEATURE"}
                                                     body={["Kindly upgrade the premium version through our Android App to access this feature."]}
                                                     buttons={
                                                         [
                                                             {
                                                                 text: t(translationKeys.ok).toUpperCase(),
                                                                 action: () => {
                                                                     dispatch({
                                                                         type: BUILDER_ACTION_TYPE.SET_SHOW_PREMIUM_POP_UP,
                                                                         payload: false
                                                                     })
                                                                 }
                                                             }
                                                         ]
                                                     }
                    /> : null}
                    {showLoader ? <Loader height='100%' width='100%' left='0px'/> : null}
                </div>
            )

            // ************************** Desktop Version Starts ************************** //
            return (
                <div id={'app-container'} className='app-container'>
                    <LeftNavigator
                        LeftNavigatorMenuItems={[...builderMenuItems, new PopupMenuItem(3, t(translationKeys.submit), new MenuIconInfo("submit", 'submit_grey.svg'), submitForm)]}
                        doNavigation={handleAppIconClick}
                        doPopupAction={doPopupAction}
                        updateContainer={updateContainer}
                        hideStorageBand={true}
                    />
                    <div className='body-container'>
                        {
                            (() => {
                                return (
                                    <>
                                        <div className='builder-wrapper'
                                             style={{width: showPreviewPane ? "62%" : "94%"}}>
                                            <div className='builder-box1'
                                                 style={{
                                                     display: currentContainerTitle === BuilderMenuItem.getTitle() ? null : 'none'
                                                 }}
                                            >
                                                <div className='form-builder-body-container'>
                                                    <div className="builder-container"
                                                         id="builder-container">
                                                        <div className="builder-question-container"
                                                             id="builder-question-container">
                                                            <TitleSectionComponent
                                                                lastEditedBy={formData?.last_edited_by}
                                                                time={formData?.date_edited}
                                                                {...getTitleProps()}
                                                            />
                                                            {
                                                                (() => {
                                                                    if (formData?.pages[0]?.questions?.length === 0) return (
                                                                        <>
                                                                            <div id='add-question-button'
                                                                                 className='builder-question-button'
                                                                                 style={formData?.last_edited_by ? {top: '235px'} : null}
                                                                                 onClick={(e) => {
                                                                                     handleOutSideClick()
                                                                                     ShowQuestionTypesMenuFromAddQuestion(e)
                                                                                 }}>
                                                                                                    <span
                                                                                                        style={{
                                                                                                            fontSize: '26px',
                                                                                                            marginRight: '5px'
                                                                                                        }}>+</span>
                                                                                <span>{t(translationKeys.add_question)}</span>
                                                                            </div>
                                                                            {
                                                                                (() => {
                                                                                    if (showQTsMenuFromAddQuestionButton) return (
                                                                                        <div ref={refPopUpContainer}>
                                                                                            <QuestionTypePopup
                                                                                                questionIndex={-1}/>
                                                                                        </div>
                                                                                    )
                                                                                })()
                                                                            }
                                                                        </>
                                                                    )
                                                                })()
                                                            }
                                                            {
                                                                (() => {
                                                                        if (showModalDialog)
                                                                            return (
                                                                                <div>
                                                                                    <ModalDialog
                                                                                        header={refModalTitle.current}
                                                                                        body={refModalBody.current}
                                                                                        buttons={refModalDialogButtons.current}
                                                                                    />
                                                                                </div>
                                                                            )
                                                                    }
                                                                )()
                                                            }

                                                            {
                                                                (() => {
                                                                        if (showGridQtChangeNotAllowedPopUp)
                                                                            return (
                                                                                <div>
                                                                                    <ModalDialog
                                                                                        header={t(translationKeys.not_allowed)}
                                                                                        body={t(translationKeys.not_allowed_description)}
                                                                                        buttons={[
                                                                                            {
                                                                                                text: t(translationKeys.ok),
                                                                                                action:
                                                                                                    () => {
                                                                                                        dispatch({
                                                                                                            type: APP_ACTION_TYPE.SET_SHOW_GRID_QT_CHANGE_NOT_ALLOWED_POP_UP,
                                                                                                            payload: false
                                                                                                        })
                                                                                                    }
                                                                                            }
                                                                                        ]}
                                                                                    />
                                                                                </div>
                                                                            )
                                                                    }
                                                                )()
                                                            }
                                                            {
                                                                (() => {
                                                                        if (showColumnOrRowLimitReachedPopUp)
                                                                            return (
                                                                                <div>
                                                                                    <ModalDialog
                                                                                        header={t(translationKeys.options_limit_reached)}
                                                                                        body={t(translationKeys.options_limit_reached_description)}
                                                                                        buttons={[
                                                                                            {
                                                                                                text: t(translationKeys.ok),
                                                                                                action:
                                                                                                    () => {
                                                                                                        dispatch({
                                                                                                            type: APP_ACTION_TYPE.SET_SHOW_COLUMN_OR_ROW_LIMIT_REACHED_POP_UP,
                                                                                                            payload: false
                                                                                                        })
                                                                                                    }
                                                                                            }
                                                                                        ]}
                                                                                    />
                                                                                </div>
                                                                            )
                                                                    }
                                                                )()
                                                            }
                                                        </div>
                                                    </div>
                                                    {
                                                        (() => {
                                                            if (formData?.pages[0]?.questions?.length > 0) return (
                                                                <div className='builder-forms-container'>
                                                                    <DragDropContext onDragStart={handleOnDragStart}
                                                                                     onDragEnd={handleOnDragEnd}>
                                                                        <Droppable droppableId="characters">
                                                                            {(provided) => (
                                                                                <div {...provided.droppableProps}
                                                                                     ref={provided.innerRef}
                                                                                     style={{
                                                                                         width: "100%",
                                                                                         height: "fit-content"
                                                                                     }}>
                                                                                    <div ref={refCardsContainer}
                                                                                         className="builder-cards-container">
                                                                                        {
                                                                                            formData.pages[0].questions?.map((question, index) => (
                                                                                                () => {
                                                                                                    let questionGroup = getQuestionGroupV2(QUESTION_TYPE[question.type])

                                                                                                    if (questionGroup === QUESTION_GROUPS.SECTION) {
                                                                                                        return (<div style={{marginBottom: formData.pages[0].questions?.length - 1 === index ? "10vh" : null}}>
                                                                                                                {formData?.is_logic_enabled ?
                                                                                                                    <div>
                                                                                                                        <TitleSectionComponent {...getSectionProps(question, index)}/>
                                                                                                                    </div> :
                                                                                                                    <Draggable
                                                                                                                        key={question._id}
                                                                                                                        draggableId={question._id}
                                                                                                                        index={index}
                                                                                                                        isDragDisabled={isDragAndDropDisabled}>
                                                                                                                        {(provided) => (
                                                                                                                            <div
                                                                                                                                ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                                                                                                <TitleSectionComponent {...getSectionProps(question, index)}/>
                                                                                                                            </div>)}
                                                                                                                    </Draggable>}
                                                                                                            </div>
                                                                                                        )
                                                                                                    } else {
                                                                                                        let qnInfo = questionNumberInfo.filter(questionInfo => questionInfo.questionIndex === index)

                                                                                                        popInitialSelectMenuItems(question.type)

                                                                                                        return (
                                                                                                            <>
                                                                                                                {formData?.is_logic_enabled ?
                                                                                                                    <div>
                                                                                                                        <QuestionContainer
                                                                                                                            {...getQuestionProps(question, index, qnInfo, userId, isMobile, setHideBottomBar)}
                                                                                                                            refSavedData={refSavedFormData}
                                                                                                                            formData={formData}/>
                                                                                                                    </div> :
                                                                                                                    <Draggable
                                                                                                                        key={question._id}
                                                                                                                        draggableId={question._id}
                                                                                                                        index={index}
                                                                                                                        isDragDisabled={isDragAndDropDisabled}>
                                                                                                                        {(provided) => (
                                                                                                                            <div
                                                                                                                                ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                                                                                                <QuestionContainer
                                                                                                                                    {...getQuestionProps(question, index, qnInfo, userId, isMobile, setHideBottomBar)}
                                                                                                                                    refSavedData={refSavedFormData}
                                                                                                                                    formData={formData}/>
                                                                                                                            </div>)}
                                                                                                                    </Draggable>}
                                                                                                            </>
                                                                                                        )
                                                                                                    }
                                                                                                })()
                                                                                            )
                                                                                        }
                                                                                    </div>
                                                                                </div>)}
                                                                        </Droppable>
                                                                    </DragDropContext>
                                                                </div>
                                                            )
                                                        })()
                                                    }
                                                </div>
                                            </div>
                                            <FormSettingsContainer updateContainer={updateContainer}
                                                                   showThemesContainer={showThemesContainer}
                                                                   updateSetting={updateSetting}
                                                                   submitSettings={submitForm}
                                                                   formData={formData}
                                                                   currentContainerTitle={currentContainerTitle}
                                            />
                                            <ThemesContainer
                                                chosenTheme={formData?.theme}
                                                changeContainer={showSettingsContainer}
                                                updateContainer={updateContainer}
                                                updateSetting={updateSetting}
                                                currentContainerTitle={currentContainerTitle}
                                            />
                                            {/*<div className='builder-box2'
                                                 style={{
                                                     display: currentContainerTitle === 'ThemeContainer' ? 'none' : null
                                                 }}>
                                                <PreviewContainer formData={formData}/>
                                            </div>*/}
                                        </div>
                                    </>
                                )
                            })()
                        }
                        {
                            (() => {
                                    if (shareMenuIsVisible) return (
                                        <div className={'image-attachment-popup-container'}>
                                            <PopUpContainer children={
                                                <ShareCard isQuiz={false} formId={refFormID.current}
                                                           isShowSharePopUp={shareScreenVisibilityHandler}
                                                           webFirstFeatures={refFeaturesInWebAppOnly.current}
                                                           passcode={formData?.setting?.passcode}
                                                />
                                            }/>
                                        </div>
                                    )
                                }
                            )()
                        }
                        {
                            (() => {
                                    if (showUnapprovedFormDialog) return unapprovedModalDialog(formData, userId, BUILDER_TYPE.FORM)
                                }
                            )()
                        }
                        {
                            (() => {
                                    if (showEditRightsRemoved)
                                        return editRightsRemovedDialog(BUILDER_TYPE.FORM, () => {
                                            doNavigation(SurveyHeartMenuItem)
                                        })
                                }
                            )()
                        }
                        {
                            (() => {
                                if (showImportQuestionsDialog) return (
                                    <SearchAndFormsList/>
                                )
                            })()
                        }
                        {showOptionDeletePopUp ? <ModalDialog header={t(translationKeys.delete).toUpperCase()}
                                                              imageIcon={deleteIcon}
                                                              body={[t(translationKeys.delete_option_alert)]}
                                                              buttons={
                                                                  [
                                                                      {
                                                                          text: t(translationKeys.no).toUpperCase(),
                                                                          action: () => {
                                                                              dispatch({
                                                                                  type: BUILDER_ACTION_TYPE.SET_SHOW_OPTION_DELETE_POP_UP,
                                                                                  payload: false
                                                                              })
                                                                          }
                                                                      },
                                                                      {
                                                                          text: t(translationKeys.yes).toUpperCase(),
                                                                          action: () => {
                                                                              optionDeleteAction()
                                                                              dispatch({
                                                                                  type: BUILDER_ACTION_TYPE.SET_SHOW_OPTION_DELETE_POP_UP,
                                                                                  payload: false
                                                                              })
                                                                              dispatch({
                                                                                  type: BUILDER_ACTION_TYPE.SET_OPTION_DELETE_ACTION,
                                                                                  payload: null
                                                                              })
                                                                          }
                                                                      }
                                                                  ]
                                                              }
                        /> : null}
                        {showPremiumPopUp ? <ModalDialog header={"PREMIUM FEATURE"}
                                                         body={["Kindly upgrade the premium version through our Android App to access this feature."]}
                                                         buttons={
                                                             [
                                                                 {
                                                                     text: t(translationKeys.ok).toUpperCase(),
                                                                     action: () => {
                                                                         dispatch({
                                                                             type: BUILDER_ACTION_TYPE.SET_SHOW_PREMIUM_POP_UP,
                                                                             payload: false
                                                                         })
                                                                     }
                                                                 }
                                                             ]
                                                         }
                        /> : null}
                    </div>
                    {showLoader ? <Loader height='100%' width='100%' left='0px'/> : null}
                    {/*** Toast message ***/}
                    {builderToastMessage ? <Message width={'250px'} message={builderMessage}/> : null}
                </div>
            )
        })()
    )
}

export default FormBuilderContainer