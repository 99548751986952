import React, {useEffect, useRef} from "react";
import '../css/material-slider.css'
import {MDCSlider} from '@material/slider';

/**
 *
 * @param props = {maxPosition, minPosition, currentPosition, handleChange(value, sliderInstance)}
 * @returns {JSX.Element}
 * @constructor
 */
function MaterialSlider(props) {
    const sliderRef = useRef(null);
    const sliderInstance = useRef(null)
    useEffect(() => {
        if(props.currentPosition <= props.maxPosition) {
            const slider = new MDCSlider(sliderRef.current);
            sliderInstance.current = slider;
            slider.root.addEventListener('MDCSlider:change', e => props.handleChange(e.detail.value, sliderInstance));
        }
    }, [props.maxPosition])

    useEffect(() => {
        sliderInstance?.current?.setValue(props.currentPosition);
    }, [props.currentPosition])

    return (
        <div id={'material-slider'} className="mdc-slider mdc-slider--discrete" ref={sliderRef} style={{'--mdc-theme-primary': props.primaryColor ? props.primaryColor :'#fff', width: props.sliderWidth ? props.sliderWidth : null, margin: props.margin ? props.margin : null}}>
            <input className="mdc-slider__input" type="range" min={props.minPosition ? props.minPosition : 0} max={props.maxPosition}
                   value={props.currentPosition} onChange={(e) => console.log(e.target.value)} name="volume" step="1"
                   aria-label="Discrete slider demo"/>
            <div className="mdc-slider__track">
                <div className="mdc-slider__track--inactive" style={{position: 'relative', top: '-1px'}}/>
                <div className="mdc-slider__track--active">
                    <div className="mdc-slider__track--active_fill"/>
                </div>
            </div>
            <div className="mdc-slider__thumb">
                <div style={{display: 'none'}} className="mdc-slider__value-indicator-container">
                    <div className="mdc-slider__value-indicator">
                        <span className="mdc-slider__value-indicator-text">0</span>
                    </div>
                </div>
                <div className="mdc-slider__thumb-knob"/>
            </div>
        </div>
    );
}

export default MaterialSlider
