import React, {useEffect, useState} from 'react'

const StickyTopNavBar = (props) => {
    const [stickFormTitleCard, setStickFormTitleCard] = useState(false);

    useEffect(() => {
        if (props.bodyContainerElementRef.current) {
            props.bodyContainerElementRef.current.addEventListener('scroll', (e) => {
                e.stopPropagation();
                e.target.scrollTop > 250 ? setStickFormTitleCard(true) : setStickFormTitleCard(false)
            })
        }
    }, [props.bodyContainerElementRef.current])

    return (
        <div className={stickFormTitleCard ? 'onScroll-title-card sticky' : 'onScroll-title-card'}>
            <div style={{width: '25%', display: 'flex', marginLeft: '1%'}}>
                {props.leftStickyNavBarElements ? props.leftStickyNavBarElements : null}
            </div>
            <span id='sticky-center-title' style={{
                margin: '10px',
                textAlign: 'center',
                width: '50%',
                fontSize: '22px',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis'
            }}>{props.stickyCenterTitle}</span>
            <div id='sticky-center-title-card' style={{
                width: '25%',
                display: 'flex',
                justifyContent: 'flex-end',
                paddingRight: '20px'
            }}>
                {stickFormTitleCard ?
                    props.rightStickyNavBarElements ? props.rightStickyNavBarElements : null : null}
            </div>
        </div>)
}

export default StickyTopNavBar