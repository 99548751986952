import React, {useEffect, useRef, useState} from 'react'
import {getDateTimeWithFormat} from "../../../shared/utils/get-date-time-with-format";
import {DATE_FORMATS} from "../../../admin/constants";
import {getQuestionCategory, QUESTION_CATEGORY, QUESTION_TYPE} from "../../../shared/utils/constants";
import {useTranslation} from "react-i18next";
import {translationKeys} from "../../../localizations/translationKeys-localization";
import {RESPONSES_ACTION_TYPE} from "../../reducers/responsesReducer";
import {useDispatch, useSelector} from "react-redux";
import FilterPopUpContainer from "./FilterPopUpContainer";
import {RESPONDENT_SORTING_FIELD, sortRespondents} from "../utils/response-summary-sort";
import {isMobile, isMobileOnly} from "react-device-detect";
import multiselectImg from "../../../../public/images/check_box_tick.svg";
import {COLLABORATOR_ROLES} from "../../builder/enums/collaboratorsRoles";
import SummaryHeader from "./SummaryHeader";
import LimitReachedUI from "./LimitReachedUI";

const MobileResponsesScreen = () => {
    const {t} = useTranslation()
    const userId = useSelector(state => state.userReducer?.userId)
    const showFilter = useSelector(state => state.responsesReducer?.showFilter)
    const searchText = useSelector(state => state.responsesReducer?.searchText)
    const summaryDataSet = useSelector(state => state.responsesReducer?.summaryDataSet)
    const formResponses = useSelector(state => state.responsesReducer?.formResponsesData)
    const selectedLanguage = useSelector(state => state.languageReducer?.selectedLanguage)
    const questionFilterObject = useSelector(state => state.responsesReducer?.questionFilterObject)
    const multiSelectResponsesArray = useSelector(state => state.responsesReducer?.multiSelectResponsesArray)
    const [respondents, setRespondents] = useState(sortRespondents(formResponses?.formResponses?.respondents, RESPONDENT_SORTING_FIELD.SUBMIT_TIME))
    const [isFilteredArrayChanged, setIsFilteredArrayChanged] = useState(false);
    const filterObjectCountRef = useRef(0)
    const searchCloseButtonRef = useRef();
    const timeFilterObjectRef = useRef(null);
    const filteredArrayRef = useRef(null);
    const searchedArrayRef = useRef(null);
    const dispatch = useDispatch()
    let timer

    useEffect(() => {
        timeFilterObjectRef.current = null
    }, []);

    useEffect(() => {
        setRespondents(sortRespondents(formResponses?.formResponses?.respondents, RESPONDENT_SORTING_FIELD.SUBMIT_TIME))
    }, [formResponses?.formResponses?.respondents]);

    useEffect(() => {
        const filteredResults = []
        if (filteredArrayRef?.current) {
            filteredArrayRef.current.map((indexValue) => {
                filteredResults.push(sortRespondents(formResponses?.formResponses?.respondents, RESPONDENT_SORTING_FIELD.SUBMIT_TIME).reverse()[indexValue])
            })
            const updatedRespondents = {formResponses}
            updatedRespondents.formResponses.respondents = filteredResults
            setRespondents(updatedRespondents.formResponses.respondents)
        }
    }, [filteredArrayRef.current]);

    useEffect(() => {
        const searchedRespondents = []
        if (searchText?.length > 0) {
            formResponses?.formResponses?.respondents.map((respondent) => {
                respondent.responses.map((response) => {
                    if (response.type !== QUESTION_TYPE.FILE_UPLOAD.key && response.type !== QUESTION_TYPE.MULTIPLE_CHOICE_GRID.key && response.type !== QUESTION_TYPE.CHECKBOX_GRID.key) {
                        if (getResponseValue(response).toLowerCase().includes(searchText.toLowerCase()) && !searchedRespondents.includes(respondent)) {
                            searchedRespondents.push(respondent)
                        }
                    }
                })
            })
            const updatedRespondents = {formResponses}
            updatedRespondents.formResponses.respondents = searchedRespondents
            setRespondents(updatedRespondents.formResponses.respondents)
        } else if (searchText === '') {
            setRespondents(formResponses.formResponses.respondents)
        }
    }, [searchText])

    const getResponseValue = (response) => {
        let responseValue = ""
        if (response.text) {
            responseValue = response.text
            if (response.type === QUESTION_TYPE.TIME.key) {
                let splitTimeArray = response.text.split(':')
                let hour = parseInt(splitTimeArray[0])
                const AmOrPm = hour >= 12 ? 'PM' : 'AM';
                hour = (hour % 12) || 12;
                responseValue = hour + ':' + splitTimeArray[1] + ' ' + AmOrPm;
            }
        } else {
            formResponses.formData.pages[0].questions.map((question) => {
                if (question._id === response.question_id) {
                    if (getQuestionCategory(response.type) === QUESTION_CATEGORY.CHOICE_TYPE) {
                        question.choices.map((choice) => {
                            if (response.type === QUESTION_TYPE.CHECKBOX_CHOICE.key) {
                                response.choices.map((c) => {
                                    if (c === choice._id) {
                                        responseValue = responseValue?.length > 0 ? responseValue + ', ' + choice.label : choice.label
                                    }
                                })
                            } else if (choice._id === response.choice) {
                                responseValue = choice.label
                            }
                        })
                        if (response.others) {
                            responseValue = (responseValue?.length > 0 ? responseValue + ', ' + response.others : response.others) + ` (${t(translationKeys.others)})`
                        }
                    } else if (getQuestionCategory(response.type) === QUESTION_CATEGORY.GRID_TYPE) {
                        // Check for match between Question row and column and Response row and column
                        if (question.type === QUESTION_TYPE.MULTIPLE_CHOICE_GRID.key) {
                            responseValue = ''

                            response.grid_choices.map((choice) => {
                                let rowLabel = question.rows.find(r => r._id === choice.row)?.label
                                let colLabel = ''

                                if (rowLabel) {
                                    colLabel = question.columns.find(r => r._id === choice.column)?.label

                                    if (colLabel) {
                                        if (responseValue?.length === 0) {
                                            responseValue = `${rowLabel} x ${colLabel}`
                                        } else {
                                            responseValue += `, ${rowLabel} x ${colLabel}`
                                        }
                                    }
                                }
                            })
                        } else if (question.type === QUESTION_TYPE.CHECKBOX_GRID.key) {
                            responseValue = ''

                            response.checkbox_grid_choices.map((choice) => {
                                let rowLabel = question.rows.find(r => r._id === choice.row)?.label

                                if (rowLabel) {
                                    let allColumnLabels = ''
                                    let colLabel = ''
                                    let processedColumns = []

                                    choice.columns.map((col) => {
                                        if (!processedColumns.includes(col)) {
                                            colLabel = question.columns.find(qc => qc._id === col)?.label

                                            if (colLabel) {
                                                if (allColumnLabels?.length === 0) {
                                                    allColumnLabels = `${colLabel}`
                                                } else {
                                                    allColumnLabels += `,${colLabel}`
                                                }
                                            }

                                            processedColumns.push(col)
                                        }
                                    })

                                    if (allColumnLabels?.length > 0) {
                                        if (responseValue?.length === 0) {
                                            responseValue = `${rowLabel} x ${allColumnLabels}`
                                        } else {
                                            responseValue += `, ${rowLabel} x ${allColumnLabels}`
                                        }
                                    }
                                }
                            })
                        }
                    }
                }
            })
        }
        return responseValue?.length > 0 ? responseValue : t(translationKeys.no_answer)
    }

    const getQuestionTitle = (response) => {
        let questionTitle
        formResponses.formData.pages[0].questions.map((question) => {
            if (question._id === response.question_id) {
                questionTitle = question.title
            }
        })
        return questionTitle
    }

    const getIndex = (responderData) => {
        let clickedIndex = 0
        formResponses.formResponses.respondents.reverse().map((element, index) => {
            if (element === responderData) {
                clickedIndex = index + 1
            }
        })
        return clickedIndex
    }

    const handleMultipleSelect = (e, respondent) => {
        e.stopPropagation();
        let role
        formResponses.formData.collaborators?.map((collaborator) => {
            if (collaborator.user_id === userId) {
                role = collaborator.role
            }
        })
        if (role === COLLABORATOR_ROLES.EDITOR || role === COLLABORATOR_ROLES.VIEWER) {
            return;
        }
        if (multiSelectResponsesArray.indexOf(respondent._id) !== -1) {
            // Remove the clicked Form
            let multipleSelectedArray = [...multiSelectResponsesArray]

            multipleSelectedArray.splice(multipleSelectedArray.indexOf(respondent._id), 1)
            dispatch({
                type: RESPONSES_ACTION_TYPE.SET_MULTI_SELECT_RESPONSES_ARRAY,
                payload: multipleSelectedArray
            })
        } else {
            // Add the clicked Form
            dispatch({
                type: RESPONSES_ACTION_TYPE.SET_MULTI_SELECT_RESPONSES_ARRAY,
                payload: [...multiSelectResponsesArray, respondent._id]
            })
        }
    };

    const setLongPressEvent = (e, respondent) => {
        if (isMobile) {
            timer = setTimeout(() => {
                if (formResponses?.formResponses?.respondents?.length === formResponses?.formData?.response_count) {
                    handleMultipleSelect(e, respondent);
                }
            }, 350);
            return false;
        }
    }

    const clearLongPressEvent = () => {
        if (isMobile) {
            clearTimeout(timer);
            return false;
        }
    }

    return (
        <>
            {questionFilterObject?.length === 0 && searchText === '' && respondents?.length > 0 && respondents?.length !== formResponses?.formData?.response_count && isMobileOnly ?
                <SummaryHeader formData={formResponses?.formData} respondents={respondents}/> : null}
            {respondents?.length > 0 ? React.Children.toArray(respondents?.map((respondent, index) => {
                return (
                    <div className={'response-question-card'}
                         style={{
                             margin: index === 0 && formResponses?.formResponses?.respondents?.length === formResponses?.formData?.response_count ? '15% 0 0 0' : !isMobileOnly ? "10px 0" : null,
                             height: '10%',
                             marginBottom: index === respondents?.length - 1 ? '20%' : null,
                             width: isMobileOnly ? null : "71%",
                             cursor: "pointer"
                         }}
                         onTouchStart={(e) => setLongPressEvent(e, respondent)}
                         onMouseDown={(e) => setLongPressEvent(e, respondent)}
                         onTouchMove={clearLongPressEvent}
                         onTouchEnd={clearLongPressEvent}
                         onMouseUp={clearLongPressEvent}
                         onClick={(e) => {
                             if (!multiSelectResponsesArray?.length) {
                                 dispatch({type: RESPONSES_ACTION_TYPE.SET_QUESTION_FILTER_OBJECT, payload: []})
                                 dispatch({type: RESPONSES_ACTION_TYPE.SET_SHOW_SEARCH_INPUT, payload: false})
                                 dispatch({type: RESPONSES_ACTION_TYPE.SET_SEARCH_TEXT, payload: ''})
                                 dispatch({
                                     type: RESPONSES_ACTION_TYPE.SET_INDIVIDUAL_RESPONSE_NUMBER,
                                     payload: getIndex(respondent)
                                 })
                                 dispatch({type: RESPONSES_ACTION_TYPE.SET_SHOW_INDIVIDUAL, payload: true})
                                 if (!isMobileOnly) {
                                     dispatch({type: RESPONSES_ACTION_TYPE.SET_SHOW_SUMMARY, payload: false})
                                 }
                             } else {
                                 handleMultipleSelect(e, respondent)
                             }
                         }}>
                        <div style={{display: "flex"}}>
                            <span style={{
                                fontWeight: "bold",
                                margin: "0 5px 0 0"
                            }}>{formResponses?.formResponses?.respondents?.length - index}.</span>
                            <div>{getDateTimeWithFormat(respondent.submit_time, DATE_FORMATS.DD_MM_YYYYCOMATIME)}</div>
                            {multiSelectResponsesArray?.length > 0 ?
                                <div
                                    className={multiSelectResponsesArray.indexOf(respondent._id) !== -1 ? 'multiple-select-tag-for-delete selected-for-delete' : 'multiple-select-tag-for-delete'}
                                    style={isMobileOnly ? {
                                        position: "relative",
                                        top: "0",
                                        left: (selectedLanguage === 'fa' || selectedLanguage === 'ar') ? null : "40%",
                                        right: (selectedLanguage === 'fa' || selectedLanguage === 'ar') ? "40%" : null
                                    } : null}
                                    onClick={(e) => handleMultipleSelect(e, respondent)}>
                                    {multiSelectResponsesArray.indexOf(respondent._id) !== -1 ?
                                        <img style={{width: '120%', height: '120%'}} alt='select'
                                             src={multiselectImg}/> : null}
                                </div> : null}
                        </div>
                        <div>
                            {React.Children.toArray(respondent.responses.slice(0, 2).map((response) => {
                                return (
                                    <div style={{display: "flex", justifyContent: "space-between", margin: "5px 0"}}>
                                        <span style={{
                                            color: "#757575",
                                            width: "50%",
                                            textOverflow: "ellipsis",
                                            whiteSpace: "nowrap",
                                            overflow: "hidden",
                                            marginRight: "5px",
                                            fontSize: "15px"
                                        }}>
                                            {getQuestionTitle(response)}
                                        </span>
                                        <span style={{
                                            width: "50%",
                                            textOverflow: "ellipsis",
                                            whiteSpace: "nowrap",
                                            overflow: "hidden",
                                            textAlign: "end",
                                            fontSize: "15px"
                                        }}>
                                            {getResponseValue(response)}
                                        </span>
                                    </div>
                                )
                            }))}
                        </div>
                    </div>
                )
            })) : formResponses?.formResponses?.respondents?.length !== formResponses?.formData?.response_count ?
                <LimitReachedUI isQuiz={formResponses?.formData?.is_quiz}/> :
                <div style={{display: "flex", height: "100vh", justifyContent: "center", alignItems: "center"}}>
                    {t(translationKeys.no_matches_found)}
                </div>}
            {showFilter ? <FilterPopUpContainer questionsData={summaryDataSet.questionsData}
                                                setShowPopUp={(showPopUp) => dispatch({
                                                    type: RESPONSES_ACTION_TYPE.SET_SHOW_FILTER,
                                                    payload: showPopUp
                                                })}
                                                timeFilterObjectRef={timeFilterObjectRef}
                                                questionFilterObject={questionFilterObject}
                                                filteredArrayRef={filteredArrayRef}
                                                searchedArrayRef={searchedArrayRef}
                                                isFilteredArrayChanged={isFilteredArrayChanged}
                                                setIsFilteredArrayChanged={setIsFilteredArrayChanged}
                                                searchCloseButtonRef={searchCloseButtonRef}
                                                summaryDataSet={summaryDataSet}
                                                filterObjectCountRef={filterObjectCountRef}
            /> : null}
        </>
    )
}

export default MobileResponsesScreen
