import React, {useEffect, useRef, useState} from "react"
import GenericMenuItem from "../classes/GenericMenuItem"
import MenuIconInfo from "../../../shared/classes/MenuIconInfo"
import editDescription from "../../../../public/images/edit_desc.svg"
import PopupMenuContainer from "./PopupMenuContainer"
import {ATTACHMENT_TYPE} from "../../../shared/utils/constants"
import QuestionAttachmentComponent from "../../../form/components/questions/QuestionAttachmentComponent"
import LinkPreview from "./LinkPreview"
import PopUpContainer from "../../../shared/components/PopUpContainer"
import {AWS_FOLDER_NAMES, AWS_UPLOAD_TYPE, MEDIA_BASE_URL} from "../utils/aws"
import ImagesContainer from "./ImagesContainer"
import {isMobile, isMobileOnly} from "react-device-detect"
import {getDataFromUrl} from "../../../shared/utils/sash-v2-api"
import UploadLimitReachedModalDialog from "../../../shared/components/UploadLimitReachedModialDialog"
import MaterialTextField from "../../../shared/components/MaterialTextField"
import '../../../shared/css/sh-text-field.css'
import {useDispatch, useSelector} from "react-redux"
import {AUTOCOMPLETE_ACTION_TYPE} from "../../reducers/autocompleteReducer"
import {useTranslation} from "react-i18next";
import {translationKeys} from "../../../localizations/translationKeys-localization";
import {BUILDER_ACTION_TYPE} from "../../reducers/builderReducer";
import {APP_ACTION_TYPE} from "../../reducers/appReducer"
import useQuestionMgr from "../../hooks/useQuestionMgr";
import {EMAIL_ACTION_TYPE} from "../../reducers/collabAutocompleteReducer";
import {PremiumJSON} from "../../../shared/utils/helper";
import {PREMIUM_ACTION_TYPE} from "../../reducers/premiumReducer";

const QuestionHeader = React.memo(function QuestionHeader(props) {
    const {t} = useTranslation()
    const {setQuestionTitle, setQuestionDescription, addAttachmentInfo, removeAttachmentInfo} = useQuestionMgr()
    const [attachmentType, setAttachmentType] = useState(null)
    const [showDesc, setShowDesc] = useState(false)
    const [imageURL, setImageURL] = useState('')
    const [showAttachmentMenu, setShowAttachmentMenu] = useState(false)
    const [leftPosition, setLeftPosition] = useState(0)
    const [showMyImages, setShowMyImages] = useState(false)
    const [showAddImage, setShowAddImage] = useState(false)
    const [showImageLimitReached, setShowImageLimitReached] = useState(false)
    const [setFocus, setSetFocus] = useState(false)
    const reduxDispatch = useDispatch()
    const textFieldRef = useRef(null)
    const subscriptionData = useSelector(state => state.userReducer?.subscriptionData)
    const maxQuestionAttachmentUploadLimit = PremiumJSON[subscriptionData?.subscription_plan]?.question_attachments
    const autoCompleteLeftPos = useSelector(state => state.autocompleteReducer?.leftPos)
    const selectedLanguage = useSelector(state => state.languageReducer.selectedLanguage)
    const suggestionClicked = useSelector(state => state.autocompleteReducer?.suggestionClicked)
    const questionIndexToFocus = useSelector(state => state.appReducer?.questionIndexToFocus)
    const currentUserId = useSelector(state => state.userReducer?.userId)
    const encodedUserId = btoa(currentUserId);

    const shouldFileBrowserBeVisible = (value) => {
        if (!value) {
            setShowAddImage(false)
        }
    }

    useEffect(() => {
        processAttachments()

        if (autoCompleteLeftPos === -1) {
            const payLoad = {
                leftPos: isMobileOnly ? textFieldRef.current.offsetLeft + 14 : textFieldRef.current.offsetLeft + 20,
                width: isMobileOnly ? textFieldRef.current.offsetWidth : textFieldRef.current.offsetWidth - textFieldRef.current.offsetLeft - 3
            }
            reduxDispatch({type: AUTOCOMPLETE_ACTION_TYPE.STORE_QUESTION_AUTOCOMPLETE_COORDINATES, payload: payLoad})
        }
    }, [])

    useEffect(() => {
        if (!props.focusQuestion) {
            setShowAttachmentMenu(false)
        }
    }, [props.focusQuestion])

    useEffect(() => {
        processAttachments()
    }, [props.question.attachment])

    useEffect(() => {
        setShowAttachmentMenu(false)
    }, [props.question.showQTMenu])

    useEffect(() => {
        if (props.questionIndexToShowQTMenuFor === props.index) {
            setShowAttachmentMenu(false)
        }
    }, [props.questionIndexToShowQTMenuFor])

    // useEffect(() => {
    //     setSetFocus(props.question.setFocus)
    //
    //     if (props.question.setFocus) {
    //         props.removeSetFocus()
    //     }
    // }, [props.question.setFocus])

    useEffect(() => {
        if (props.index === questionIndexToFocus) {
            reduxDispatch({type: APP_ACTION_TYPE.SET_QUESTION_INDEX_TO_FOCUS, payload: -1})
        }
    }, [questionIndexToFocus])

    const processAttachments = () => {
        // Process Attachment Types
        if (props.question.attachment && props.question.attachment.file_type !== undefined) {
            switch (props.question.attachment.file_type) {
                case ATTACHMENT_TYPE.IMAGE.key:
                    setImageURL(props.question.attachment.image_url)
                    setAttachmentType(ATTACHMENT_TYPE.IMAGE)
                    break
                case ATTACHMENT_TYPE.VIDEO.key:
                    setAttachmentType(ATTACHMENT_TYPE.VIDEO)
                    break
                case ATTACHMENT_TYPE.WEBSITE.key:
                    setAttachmentType(ATTACHMENT_TYPE.WEBSITE)
                    break
            }
        } else {
            /*This is just a quick fix for bug SH-2761, i checked at my end it's not showing any regression issue but still i am not confident that's why writing this line*/
            // handleCloseImage(null)
        }
    }

    const handleQuestionTitleBlur = () => {
        if (!suggestionClicked) {
            reduxDispatch({type: AUTOCOMPLETE_ACTION_TYPE.CLEAR_SUGGESTIONS})
            reduxDispatch({type: AUTOCOMPLETE_ACTION_TYPE.SET_CURRENT_TEXT_BOX_INDEX, payload: -1})
        }
        reduxDispatch({type: AUTOCOMPLETE_ACTION_TYPE.SET_SELECTED_VALUE, payload: ''})
    }

    const handleQuestionTitleFocus = (index) => {
        reduxDispatch({type: AUTOCOMPLETE_ACTION_TYPE.SET_CURRENT_TEXT_BOX_INDEX, payload: index})
    }

    const handleQuestionTitleChange = (newValue) => {
        setQuestionTitle(props.index, newValue)

        document.onclick = () => {
            reduxDispatch({type: AUTOCOMPLETE_ACTION_TYPE.CLEAR_SUGGESTIONS})
            document.onclick = null;
        }

        /* If 'document.onclick' above fires, the code below will never be reached. */
        if (newValue.length > 2) {
            reduxDispatch({type: AUTOCOMPLETE_ACTION_TYPE.SEARCH, payload: newValue})
        } else {
            reduxDispatch({type: AUTOCOMPLETE_ACTION_TYPE.CLEAR_SUGGESTIONS})
        }
    }

    const handleQuestionDescriptionChange = (newValue) => {
        setQuestionDescription(props.index, newValue)
    }

    const handleCloseImage = (e) => {
        removeAttachmentInfo(props.index)

        if (e) {
            e.nativeEvent.stopImmediatePropagation()
        }

        setImageURL(null)
        setAttachmentType(null)
        setShowMyImages(false)
    }

    const setQuestionContainerUItoNonFocusedState = () => {
        props.refQuestionCard.current.style.transform = 'translate3d(0, 0, 0)'
        props.refQuestionCard.current.classList.remove('builder-add-image-popup-on-top')
        props.RemoveFocusFromQuestionCardUI()
    }

    const onImageAdded = (newImage) => {
        setImageURL(newImage)
        setAttachmentType(ATTACHMENT_TYPE.IMAGE)
        setQuestionContainerUItoNonFocusedState()
        setShowMyImages(false)
        addAttachmentInfo(props.index, ATTACHMENT_TYPE.IMAGE, newImage)
        isMobileOnly ? showBottomBarWhenImagesContainerClosed() : null
    }

    const handleOutSideClick = () => {
        document.onclick = (e) => {
            if (isMobile && ((props.refQuestionCard.current && !props.refQuestionCard.current.contains(e.target))
                || (e.target.getAttribute('data-attribute') === 'builder-question-card-copy-button'))) {
                props.RemoveFocusFromQuestionCardUI()
                document.onclick = null
            }
            setShowAttachmentMenu(false)
        }
    }

    const hideBottomBarWhenImagesContainerSelected = () => {
        isMobileOnly ? props.hideBottomBar(true) : null
    }

    const showBottomBarWhenImagesContainerClosed = () => {
        isMobileOnly ? props.hideBottomBar(false) : null
    }

    const ShowAttachmentMenuItems = (e) => {
        reduxDispatch({type: EMAIL_ACTION_TYPE.CLEAR_SUGGESTIONS})
        props.refreshQTMenuDisplay(-1)
        if (isMobile) {
            props.removeFocusFromAllQuestionCards()
            props.AddFocusToQuestionCardUI()
        }
        if (selectedLanguage === 'fa' || selectedLanguage === 'ar') {
            setLeftPosition(50)
        } else {
            setLeftPosition(e.clientX - 500)
        }
        e.nativeEvent.stopImmediatePropagation()
        setShowAttachmentMenu(true)
    }
    const GetAttachmentGroups = () => {
        let keyCtr = 0

        let ImageMenuItem = new GenericMenuItem(keyCtr++, new MenuIconInfo(ATTACHMENT_TYPE.IMAGE.displayText, `/icons/builder/image.svg`), ATTACHMENT_TYPE.IMAGE.displayText)
        let VideoMenuItem = new GenericMenuItem(keyCtr++, new MenuIconInfo(ATTACHMENT_TYPE.VIDEO.displayText, `/icons/builder/video.svg`), ATTACHMENT_TYPE.VIDEO.displayText)
        let WebsiteMenuItem = new GenericMenuItem(keyCtr++, new MenuIconInfo(ATTACHMENT_TYPE.WEBSITE.displayText, `/icons/builder/website.svg`), ATTACHMENT_TYPE.WEBSITE.displayText)

        // Items are placed in groups that are separated by bottom border lines when the menu is shown.
        let Group1 = [ImageMenuItem]
        let Group2 = [VideoMenuItem]
        let Group3 = [WebsiteMenuItem]

        return [Group1, Group2, Group3]
    }
    const prepareWebOrVideoAttachmentType = attachmentType => {
        props.question.tempAttachmentValue = " "
        setImageURL(null)
        setAttachmentType(attachmentType)
    }
    const menuItemClickHandler = (e) => {
        let arrInfo = (e.target.getAttribute("data-attribute")).split("-")

        // if (arrInfo[0] === "1" && attachmentType === ATTACHMENT_TYPE.VIDEO || arrInfo[0] === "2" && attachmentType === ATTACHMENT_TYPE.WEBSITE) {
        //     // Do nothing since ATTACHMENT_TYPE hasn't changed. This logic only applies to Video and Website ATTACHMENT_TYPEs
        //     return
        // }

        switch (arrInfo[0]) {
            case "0": /*ATTACHMENT_TYPE.IMAGE*/
                props.refQuestionCard.current.style.transform = 'none'
                props.refQuestionCard.current.classList.add('builder-add-image-popup-on-top')
                isMobileOnly ? hideBottomBarWhenImagesContainerSelected() : null
                reduxDispatch({type: BUILDER_ACTION_TYPE.SET_QUESTION_IMAGES, payload: true})
                setShowMyImages(true)
                break
            case "1": /*ATTACHMENT_TYPE.VIDEO*/
                prepareWebOrVideoAttachmentType(ATTACHMENT_TYPE.VIDEO)
                break
            case "2": /*ATTACHMENT_TYPE.WEBSITE*/
                prepareWebOrVideoAttachmentType(ATTACHMENT_TYPE.WEBSITE)
                break
        }
    }

    function getQuestionAttachmentWithImage() {
        return (
            <div>
                <div className="builder-question-line">
                    {!isMobileOnly ? <div style={{visibility: 'hidden'}}>{props.number}.</div> : null}
                    <div className='builder-question-text-input'>
                        <QuestionAttachmentComponent
                            key={ATTACHMENT_TYPE.IMAGE.key}
                            attachment={{file_type: ATTACHMENT_TYPE.IMAGE.key, image_url: imageURL}}
                            theme={"classic_theme"}
                            backgroundColorProvided={false}
                            onCloseClick={handleCloseImage}
                            userId={props.userId}
                        />
                    </div>
                    {!isMobileOnly ? <img src="images/icons/builder/attach_file.svg"
                                          alt={'attach'}
                                          style={{visibility: 'hidden'}}/> : null}
                </div>
            </div>
        )
    }

    const onHideMyImages = () => {
        isMobileOnly ? showBottomBarWhenImagesContainerClosed() : null
        setQuestionContainerUItoNonFocusedState()
        setShowAddImage(false)
        setShowMyImages(false)
    }

    const onShowAddImage = () => {
        // Check to see if Image upload limit has been reached.
        const successFunction = res => {
            if (encodedUserId === 'dGVtcGxhdGVzQHN1cnZleWhlYXJ0LmNvbQ==') {
                setShowImageLimitReached(false)
                setShowMyImages(false)
                setShowAddImage(true)
            } else if (res.data.form_images.length >= maxQuestionAttachmentUploadLimit) {
                reduxDispatch({type: PREMIUM_ACTION_TYPE.SET_SHOW_UPGRADE_PLAN_POP_UP, payload: true})
            } else {
                setShowMyImages(false)
                setShowAddImage(true)
            }
        }

        const failureFunction = err => {
            setShowMyImages(false)
            setShowAddImage(true)
            console.log(err)
        }

        const requestUrl = `${MEDIA_BASE_URL}/${AWS_FOLDER_NAMES.USERS_JSON_FOLDER}/${props.userId}.json?id=${new Date().getTime()}`
        getDataFromUrl(requestUrl, successFunction, failureFunction)
    }

    const closeLinkPreview = () => {
        setAttachmentType(null)
    }

    const handleKeyDown = (e) => {
        if (window.event.ctrlKey && (e.key === "ArrowDown")) {
            e.preventDefault()
            document.getElementById(`question-text-input-${props.index + 1}`).focus()
        } else if (window.event.ctrlKey && (e.key === "ArrowUp")) {
            e.preventDefault()
            document.getElementById(`question-text-input-${props.index - 1}`).focus()
        }
    }

    return (
        <>
            <div id={'builder-question-header'} className="builder-question-header">
                <div id={"question-text" + props.number} className="builder-question-line">
                    <div id={"question-number" + props.number}
                         style={{marginBottom: showDesc ? "120px" : "30px"}}>{props.number}.
                    </div>
                    <div className='builder-question-text-input'>
                        <div className='sh-text-field' ref={textFieldRef} style={{whiteSpace: "nowrap"}}>
                            <MaterialTextField id={`question-text-input-${props.index}`}
                                               index={props.index}
                                               {...props}
                                               data={{
                                                   defaultValue: props.question.title
                                               }}
                                               noLabel={true}
                                               onChange={handleQuestionTitleChange}
                                               errorText={{current: t(translationKeys.question_is_required)}}
                                               required={t(translationKeys.required)}
                                               maxLength={500}
                                               forceUpdateRefVarFocus={props.index === questionIndexToFocus}
                                               setForceUpdateRefVarFocus={() => {
                                                   setSetFocus(false)
                                               }}
                                               onKeyDown={handleKeyDown}
                                               highlightText={!!props.question.highlightText}
                                               removeHighlightText={props.removeHighlightText}
                                               handleFocus={handleQuestionTitleFocus}
                                               onBlur={handleQuestionTitleBlur}
                                               validateURL={false}
                            />
                        </div>
                        <div className={"question-description"} onClick={() => {
                            document.activeElement.blur()
                            setShowDesc(!showDesc)
                        }}>
                            <span>{t(translationKeys.description)}</span>
                            <span style={{
                                height: "inherit",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                textWrap: "nowrap",
                                color: "#4D4D4D"
                            }}>
                                {props.question?.description?.length && !showDesc ? `: ${props.question.description}` : null}
                            </span>
                            <img src={editDescription} alt={"description"} style={{width: "20px"}}/>
                        </div>
                        {showDesc ? <div className={"description-input"}>
                            <textarea placeholder={t(translationKeys.enter_desc_here)}
                                      value={props.question.description}
                                      maxLength={1000}
                                      onChange={(e) => handleQuestionDescriptionChange(e.target.value)}/>
                        </div> : null}
                    </div>
                    <img src="images/icons/builder/attach_file.svg" className='builder-attach-file-icon'
                         alt={'attach'}
                         style={{marginBottom: showDesc ? "120px" : "30px"}}
                         onClick={
                             (e) => {
                                 handleOutSideClick()
                                 ShowAttachmentMenuItems(e)
                                 document.activeElement.blur()
                             }
                         }
                    />
                </div>
            </div>
            {
                (() => {
                    if (props.question.tempAttachmentValue && (attachmentType === ATTACHMENT_TYPE.WEBSITE || attachmentType === ATTACHMENT_TYPE.VIDEO)) return (
                        <LinkPreview
                            question={props.question}
                            index={props.index}
                            number={props.number}
                            attachmentType={attachmentType.key}
                            closeLinkPreview={closeLinkPreview}
                            showModalDialog={props.showModalDialog}
                        />
                    )
                })()
            }
            <div>
                {
                    (() => {
                        const popUpStyle = {
                            backgroundColor: 'rgba(0, 0, 0, 0.3)',
                            width: '100%',
                            zIndex: isMobileOnly ? 11 : 7,
                            top: '0',
                            left: '0',
                            position: 'fixed',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            margin: '0',
                            padding: '0',
                            height: isMobileOnly ? null : '100%'
                        }
                        if (showMyImages)
                            return (
                                <div style={popUpStyle}>
                                    <ImagesContainer
                                        showBottomBarWhenImagesContainerClosed={showBottomBarWhenImagesContainerClosed}
                                        showAddImage={onShowAddImage}
                                        hideMyImages={onHideMyImages}
                                        updateChosenTheme={onImageAdded}
                                        userId={props.userId}/>
                                </div>
                            )
                        if (showAddImage)
                            return (
                                <React.Fragment>
                                    <PopUpContainer zIndex={11}
                                                    isImageUploader={true}
                                                    shouldFileBrowserBeVisible={() => {
                                                        setQuestionContainerUItoNonFocusedState()
                                                        shouldFileBrowserBeVisible(false)
                                                        isMobileOnly ? showBottomBarWhenImagesContainerClosed() : null
                                                    }}
                                                    addImage={onImageAdded}
                                                    uploadType={AWS_UPLOAD_TYPE.FORM}
                                                    userId={props.userId}
                                    />
                                    <div style={{display: imageURL === null ? 'none' : 'block'}}>
                                        {getQuestionAttachmentWithImage()}
                                    </div>
                                </React.Fragment>
                            )
                        if (props.question.attachment && attachmentType === ATTACHMENT_TYPE.IMAGE && imageURL !== null) return getQuestionAttachmentWithImage()
                    })()
                }
            </div>
            {
                (() => {
                    if (showAttachmentMenu) return (
                        <div style={isMobile ? {
                            paddingBottom: '50px',
                            marginBottom: '10px',
                            position: 'absolute',
                            top: '60px',
                            right: 0,
                            zIndex: 2
                        } : null}>
                            <PopupMenuContainer display={showAttachmentMenu}
                                                Groups={GetAttachmentGroups()}
                                                top={50}
                                                right={isMobileOnly ? (selectedLanguage === 'fa' || selectedLanguage === 'ar') ? '175px' : 0 : null}
                                                left={leftPosition}
                                                menuItemClickHandler={menuItemClickHandler}/>
                        </div>
                    )
                })()
            }
            {
                (() => {
                        if (showImageLimitReached) {
                            return (
                                <UploadLimitReachedModalDialog OKAction={() => setShowImageLimitReached(false)}/>
                            )
                        }
                    }
                )()
            }
        </>
    )
})

export default QuestionHeader
