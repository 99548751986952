import React, {useEffect, useRef, useState} from 'react'
import sortIcon from "../../../../public/images/sort_icon.svg"
import '../css/mobile-quiz.css';
import './../css/sort-container.css'
import {RESPONDENT_SORTING_FIELD, sortRespondents} from "../utils/response-summary-sort";
import QuizSummaryResponderCard from "./QuizSummaryResponderCard";
import {useTranslation} from "react-i18next";
import {translationKeys} from "../../../localizations/translationKeys-localization";
import SummaryHeader from "./SummaryHeader";
import {useDispatch, useSelector} from "react-redux";
import {RESPONSES_ACTION_TYPE} from "../../reducers/responsesReducer";
import filter from "../../../../public/images/filter_white.svg";
import search from "../../../../public/images/search-white.svg";
import {getQuestionCategory, QUESTION_CATEGORY, QUESTION_TYPE} from "../../../shared/utils/constants";
import FilterPopUpContainer from "./FilterPopUpContainer";
import CircularCheckBox from "../../../shared/components/CircularCheckBox";
import deleteIcon from "../../../../public/images/delete_white.svg";
import ModalDialog from "../../../shared/components/ModalDialog";
import {deleteMultipleAnswersInQuiz} from "../../../shared/utils/quiz-api-services";
import LimitReachedUI from "./LimitReachedUI";
import {APP_ACTION_TYPE} from "../../reducers/appReducer";
import addResponse from "../utils/add-response";
import {UPDATE_FORM_DATA} from "../../utils/constants";
import removeResponse from "../utils/remove-response";
import {Pages} from "../../utils/pages";

function QuizMobileSummaryContainer(props) {
    const {t} = useTranslation()
    const [showSortOptions, setShowSortOptions] = useState(false)
    const selectedLanguage = useSelector(state => state.languageReducer.selectedLanguage)
    const showSearchInput = useSelector(state => state.responsesReducer.showSearchInput)
    const formResponsesData = useSelector(state => state.responsesReducer.formResponsesData)
    const summaryDataSet = useSelector(state => state.responsesReducer.summaryDataSet)
    const responseCount = useSelector(state => state.responsesReducer.responseCount)
    const searchText = useSelector(state => state.responsesReducer.searchText)
    const showFilter = useSelector(state => state.responsesReducer.showFilter)
    const multiSelectResponsesArray = useSelector(state => state.responsesReducer.multiSelectResponsesArray)
    let initialRespondentsData = sortRespondents(formResponsesData?.formResponses.respondents, props.lastSortedOrder)
    const [respondentsData, setRespondentsData] = useState(initialRespondentsData)
    const [showDeletePopUp, setShowDeletePopUp] = useState(false)
    const [isFilteredArrayChanged, setIsFilteredArrayChanged] = useState(false);
    const searchCloseButtonRef = useRef();
    const filterObjectCountRef = useRef(0)
    const Ref = useRef();
    const timeFilterObjectRef = useRef(null);
    const questionFilterObject = useSelector(state => state.responsesReducer.questionFilterObject)
    const filteredArrayRef = useRef(null);
    const searchedArrayRef = useRef(null);
    const dispatch = useDispatch();
    let originalFormRespondents = useSelector(state => state.responsesReducer.originalFormRespondents);
    const [toastMessage, setToastMessage] = useState(<></>);

    const formData = props.formData;

    useEffect(() => {
        timeFilterObjectRef.current = null
    }, []);

    useEffect(() => {
        setRespondentsData(initialRespondentsData)
    }, [formResponsesData?.formResponses?.respondents]);

    useEffect(() => {
        const filteredResults = []
        if (filteredArrayRef?.current) {
            filteredArrayRef.current.map((indexValue) => {
                filteredResults.push(sortRespondents(formResponsesData?.formResponses?.respondents, props.lastSortedOrder).reverse()[indexValue])
            })
            const updatedRespondents = {formResponses: formResponsesData}
            updatedRespondents.formResponses.respondents = filteredResults
            setRespondentsData(updatedRespondents.formResponses.respondents)
        }
    }, [filteredArrayRef.current]);

    useEffect(() => {
        const searchedItems = []
        if (searchText.length > 0) {
            props.respondentsDataRef.current.map((element) => {
                if (element.quiz_data.user_info.user_info_1.toLowerCase() === searchText.trim().toLowerCase() || element.quiz_data.user_info.user_info_2?.toLowerCase() === searchText.trim().toLowerCase() || element.quiz_data.user_info.user_info_3?.toLowerCase() === searchText.trim().toLowerCase()) {
                    searchedItems.push(element)
                } else {
                    element.responses.map((response) => {
                        if (getResponseValue(response).includes(searchText)) {
                            searchedItems.push(element)
                        }
                    })
                }
            })
            setRespondentsData(searchedItems)
        } else if (searchText === '') {
            setRespondentsData(initialRespondentsData)
        }
    }, [searchText])

    const getResponseValue = (response) => {
        let responseValue = ""
        if (response.text) {
            responseValue = response.text
        } else {
            formResponsesData.formData.pages[0].questions.map((question) => {
                if (question._id === response.question_id) {
                    if (getQuestionCategory(response.type) === QUESTION_CATEGORY.CHOICE_TYPE) {
                        question.choices.map((choice) => {
                            if (response.type === QUESTION_TYPE.CHECKBOX_CHOICE.key) {
                                response.choices.map((c) => {
                                    if (c === choice._id) {
                                        responseValue = responseValue.length > 0 ? responseValue + ', ' + choice.label : choice.label
                                    }
                                })
                            } else if (choice._id === response.choice) {
                                responseValue = choice.label
                            }
                        })
                        if (response.others) {
                            responseValue = (responseValue.length > 0 ? responseValue + ', ' + response.others : response.others) + ` (${t(translationKeys.others)})`
                        }
                    }
                }
            })
        }
        return responseValue.length > 0 ? responseValue : ''
    }

    const handleSortButtonClick = () => {
        if (showSortOptions) {
            setShowSortOptions(false);
            document.onmousedown = null;
        } else {
            setShowSortOptions(true);
            document.onmousedown = e => {
                if (Ref.current && !Ref.current.contains(e.target)) {
                    setShowSortOptions(false);
                    document.onmousedown = null;
                }
            }
        }
    };
    const handleSortSelection = (sortOrder) => {
        let dataList = respondentsData
        if (dataList.length > 1) {
            setRespondentsData(sortRespondents(dataList, sortOrder))
            props.setLastSortedOrder(sortOrder);
        }
        setShowSortOptions(false);
    };
    const getIndex = (responderData) => {
        let clickedIndex = 0
        props.respondentsDataRef.current.map((element, index) => {
            if (element === responderData) {
                clickedIndex = index
            }
        })
        return clickedIndex
    }
    const addResponseInFormResponses = (responseId, indexNumber) => {
        let formResponses = formResponsesData;
        if (Array.isArray(responseId)) {
            for (let i = 0; i < responseId.length; i++) {
                formResponses = addResponse(responseId[i], formResponses);
            }
            dispatch({type: UPDATE_FORM_DATA, payload: formResponses.formData})
        } else {
            formResponses = addResponse(responseId, formResponses, indexNumber);
            dispatch({type: UPDATE_FORM_DATA, payload: formResponses.formData})
        }
    }
    const deleteResponseInFormResponses = (responseId) => {
        let formResponses = formResponsesData;
        if (Array.isArray(responseId)) {
            for (let i = 0; i < responseId.length; i++) {
                formResponses = removeResponse(responseId[i], formResponses);
            }
            dispatch({type: UPDATE_FORM_DATA, payload: formResponses.formData})
        } else {
            formResponses = removeResponse(responseId, formResponses);
            dispatch({type: UPDATE_FORM_DATA, payload: formResponses.formData})
        }
        if (formResponses.formResponses.respondents.length === 0) {
            dispatch({type: APP_ACTION_TYPE.SET_CURRENT_PAGE, payload: Pages.dashboard})
        }
    }
    return (
        <React.Fragment>
            {((!showSearchInput || multiSelectResponsesArray.length > 0) && formResponsesData?.formResponses?.respondents?.length === formData?.response_count) ?
                <div id='quiz-title-action-container' className='quiz-title-action-container'
                     style={{
                         zIndex: 3,
                         width: "30%",
                         margin: (selectedLanguage === 'fa' || selectedLanguage === 'ar') ? "0 65% 0 0" : "0 0 0 65%",
                         justifyContent: "end",
                         height: "55px",
                         position: "fixed"
                     }}>
                    {!multiSelectResponsesArray?.length ?
                        <div style={{width: "30%", display: "flex"}}
                             onClick={() => {
                                 dispatch({type: RESPONSES_ACTION_TYPE.SET_SHOW_FILTER, payload: true})
                             }}>
                            <div style={{display: "flex"}}>
                                <img src={filter} alt={'filter icon'}/>
                                {questionFilterObject.length ? <span className={"filter-count"}
                                                                     style={{width: "15px"}}>{questionFilterObject.length}</span> : null}
                            </div>
                        </div> : null}
                    {!multiSelectResponsesArray?.length ?
                        <div style={{width: "30%", display: "flex"}}
                             onClick={() => {
                                 dispatch({
                                     type: RESPONSES_ACTION_TYPE.SET_SHOW_SEARCH_INPUT,
                                     payload: true
                                 })
                             }}>
                            <img src={search} alt={'search icon'}/>
                        </div> : null}
                    {!multiSelectResponsesArray?.length ?
                        <SortContainer showSortOptions={showSortOptions}
                                       Ref={Ref}
                                       lastSortedOrder={props.lastSortedOrder}
                                       handleSortSelection={handleSortSelection}
                                       handleSortButtonClick={handleSortButtonClick}/> : null}
                    {multiSelectResponsesArray.length > 0 ?
                        <div style={{width: "40%", textAlign: "center"}}
                             onClick={() => {
                                 setShowDeletePopUp(true)
                             }}>
                            <img width={"70%"} src={deleteIcon} alt={'delete icon'}/>
                        </div> : null}
                </div> : null}
            <>
                <div className='quiz-summary-body-container'>
                    {respondentsData?.length > 0 ? <SummaryHeader respondentsDataRef={props.respondentsDataRef}
                                                                  responsesCount={respondentsData?.length}
                                                                  respondents={respondentsData}
                                                                  lastSortedOrder={props.lastSortedOrder}
                                                                  setLastSortedOrder={props.setLastSortedOrder}
                                                                  formData={formData}/> : null}
                    {respondentsData?.length > 0 ? respondentsData?.map((responderData, index) => {
                        return (<QuizSummaryResponderCard key={index}
                                                          isQuizV2={formData.is_quizzory_v2}
                                                          index={getIndex(responderData)}
                                                          changeResponseView={(page, index) => {
                                                              dispatch({
                                                                  type: RESPONSES_ACTION_TYPE.SET_QUESTION_FILTER_OBJECT,
                                                                  payload: []
                                                              })
                                                              dispatch({
                                                                  type: RESPONSES_ACTION_TYPE.SET_SHOW_SEARCH_INPUT,
                                                                  payload: false
                                                              })
                                                              dispatch({
                                                                  type: RESPONSES_ACTION_TYPE.SET_SEARCH_TEXT,
                                                                  payload: ''
                                                              })
                                                              props.changeResponseView(page, index)
                                                          }}
                                                          responderData={responderData}/>)
                    }) : formResponsesData?.formResponses?.respondents?.length !== formData?.response_count ? <LimitReachedUI/> :
                        <div style={{display: "flex", height: "60vh", justifyContent: "center", alignItems: "center"}}>
                            {t(translationKeys.no_matches_found)}
                        </div>}
                </div>
                {toastMessage}
            </>
            {showFilter ? <FilterPopUpContainer questionsData={summaryDataSet.questionsData}
                                                setShowPopUp={(showPopUp) => dispatch({
                                                    type: RESPONSES_ACTION_TYPE.SET_SHOW_FILTER,
                                                    payload: showPopUp
                                                })}
                                                timeFilterObjectRef={timeFilterObjectRef}
                                                questionFilterObject={questionFilterObject}
                                                filteredArrayRef={filteredArrayRef}
                                                searchedArrayRef={searchedArrayRef}
                                                isFilteredArrayChanged={isFilteredArrayChanged}
                                                setIsFilteredArrayChanged={setIsFilteredArrayChanged}
                                                searchCloseButtonRef={searchCloseButtonRef}
                                                summaryDataSet={summaryDataSet}
                                                filterObjectCountRef={filterObjectCountRef}
            /> : null}
            {multiSelectResponsesArray.length > 0 && showDeletePopUp ?
                <ModalDialog
                    header={t(translationKeys.delete) + ' ' + (formData?.is_quiz ? t(translationKeys.answer_s) : t(translationKeys.response))}
                    body={[(formData?.is_quiz ? t(translationKeys.delete_answer_alert) : t(translationKeys.delete_response_alert))]}
                    buttons={
                        [
                            {
                                //cancel button
                                text: t(translationKeys.cancel).toUpperCase(),
                                action: () => setShowDeletePopUp(false)
                            },
                            {
                                text: t(translationKeys.delete).toUpperCase(),
                                action: () => {
                                    //Delete button
                                    const deletedIds = []
                                    const deleteResponseFunction = () => {
                                        localStorage.setItem('deletedResponse', '');
                                        setToastMessage(<></>);
                                        deleteMultipleAnswersInQuiz(formData._id, multiSelectResponsesArray, apiSuccess, failureFunction)
                                        clearInterval(intervalId);
                                    }
                                    const apiSuccess = (res) => {
                                        if (res.result) {
                                            localStorage.setItem('deletedResponse', '');
                                            setToastMessage(<></>);
                                            for (let i = 0; i < multiSelectResponsesArray.length; i++) {
                                                originalFormRespondents = originalFormRespondents.filter(respondent => respondent._id !== multiSelectResponsesArray[i])
                                            }
                                            dispatch({
                                                type: RESPONSES_ACTION_TYPE.SET_ORIGINAL_QUIZ_ANSWERS,
                                                payload: JSON.parse(JSON.stringify(originalFormRespondents))
                                            })
                                        }
                                    }
                                    const handleUndoButtonClick = () => {
                                        clearInterval(intervalId);
                                        localStorage.setItem('deletedResponse', '');
                                        setToastMessage(<></>);
                                        const updatedFormResponsesData = {...formResponsesData}
                                        updatedFormResponsesData.formResponses.respondents = originalFormRespondents;
                                        deletedIds.map(id => {
                                            addResponseInFormResponses(id, originalFormRespondents)
                                        })
                                        dispatch({type: APP_ACTION_TYPE.SET_FORM_DATA, payload: formResponsesData.formData})
                                        dispatch({
                                            type: RESPONSES_ACTION_TYPE.SET_ORIGINAL_FORM_RESPONDENTS,
                                            payload: JSON.parse(JSON.stringify(originalFormRespondents))
                                        })
                                        dispatch({
                                            type: RESPONSES_ACTION_TYPE.SET_MULTI_SELECT_RESPONSES_ARRAY,
                                            payload: []
                                        })
                                        dispatch({type: RESPONSES_ACTION_TYPE.SET_RESPONSE_COUNT, payload: responseCount + deletedIds.length})
                                        dispatch({
                                            type: RESPONSES_ACTION_TYPE.SET_FORM_RESPONSES_DATA,
                                            payload: updatedFormResponsesData
                                        })
                                        setShowDeletePopUp(false)
                                    }

                                    const successFunction = () => {
                                        setToastMessage(<></>);
                                        const updatedFormResponsesData = {...formResponsesData}
                                        multiSelectResponsesArray.map((id) => {
                                            deletedIds.push(id)
                                            deleteResponseInFormResponses(id)
                                            updatedFormResponsesData.formResponses.respondents = updatedFormResponsesData.formResponses.respondents.filter((respondent) => respondent._id !== id)
                                        })
                                        dispatch({type: APP_ACTION_TYPE.SET_FORM_DATA, payload: formResponsesData.formData})
                                        dispatch({
                                            type: RESPONSES_ACTION_TYPE.SET_RESPONSE_COUNT,
                                            payload: responseCount - multiSelectResponsesArray.length
                                        })
                                        dispatch({
                                            type: RESPONSES_ACTION_TYPE.SET_FORM_RESPONSES_DATA,
                                            payload: updatedFormResponsesData
                                        })
                                        dispatch({
                                            type: RESPONSES_ACTION_TYPE.SET_MULTI_SELECT_RESPONSES_ARRAY,
                                            payload: []
                                        })
                                        dispatch({type: RESPONSES_ACTION_TYPE.SET_SEARCH_TEXT, payload: ""})
                                        dispatch({type: RESPONSES_ACTION_TYPE.SET_SHOW_SEARCH_INPUT, payload: false})
                                        setShowDeletePopUp(false)
                                    }
                                    const failureFunction = (err) => {
                                        console.log(err)
                                    }
                                    successFunction();

                                    const deletedResponse = {
                                        formId: formData._id,
                                        responseId: multiSelectResponsesArray
                                    }
                                    localStorage.setItem('deletedResponse', JSON.stringify(deletedResponse));

                                    setToastMessage(<div className={"response-undo-toast-container"}
                                                         style={{position: "fixed", width: "calc(100% - 30px)"}}>
                                        <p className={"response-undo-message"}>{multiSelectResponsesArray.length} {multiSelectResponsesArray.length > 1 ? t(translationKeys.answers_deleted) : t(translationKeys.answer_deleted)} </p>
                                        <div className={"response-undo-buttons-container"}>
                                            <a href='#' onClick={handleUndoButtonClick}
                                               className={"response-undo-button"}>{t(translationKeys.undo)}</a>
                                        </div>
                                    </div>)
                                    const intervalId = setInterval(deleteResponseFunction, 5000);
                                }
                            }
                        ]
                    }
                /> : null}
        </React.Fragment>
    )
}

export default QuizMobileSummaryContainer

const SortContainer = (props) => {
    const {t} = useTranslation()
    const selectedLanguage = useSelector(state => state.languageReducer.selectedLanguage)
    const popUpStyle = {
        backgroundColor: 'rgba(0, 0, 0, 0.1)',
        height: '100%',
        width: '100%',
        zIndex: '7',
        top: '0',
        left: '0',
        position: 'fixed',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: '0',
        padding: '0'
    }
    return <div className="sort-container">
        <img src={sortIcon} onClick={props.handleSortButtonClick} alt={'sort'}/>
        {props.showSortOptions ?
            <div style={popUpStyle}>
                <div id={'form-sort-options-container-mobile'} className="form-sort-options-container-mobile"
                     ref={props.Ref}
                     style={(selectedLanguage === 'fa' || selectedLanguage === 'ar') ? {right: '-110px'} : null}>
                    <div className="form-sort-header-mobile" style={{border: "none"}}>
                        {t(translationKeys.sort_by)}
                    </div>
                    <div id={'form-sort-Modified-Date'} className="form-sort-each-option-mobile"
                         onClick={() => props.handleSortSelection(RESPONDENT_SORTING_FIELD.ALPHABET)}>
                        <CircularCheckBox isChecked={props.lastSortedOrder === RESPONDENT_SORTING_FIELD.ALPHABET}/>
                        {t(translationKeys.alphabet)}
                    </div>
                    <div id={'form-sort-Created-Date'} className="form-sort-each-option-mobile"
                         onClick={() => props.handleSortSelection(RESPONDENT_SORTING_FIELD.SUBMIT_TIME)}>
                        <CircularCheckBox isChecked={props.lastSortedOrder === RESPONDENT_SORTING_FIELD.SUBMIT_TIME}/>
                        {t(translationKeys.submit_time)}
                    </div>
                    <div id={'form-sort-Title'} className="form-sort-each-option-mobile"
                         style={{borderBottomLeftRadius: '5px', borderBottomRightRadius: '5px'}}
                         onClick={() => props.handleSortSelection(RESPONDENT_SORTING_FIELD.NOT_EVALUATED)}>
                        <CircularCheckBox isChecked={props.lastSortedOrder === RESPONDENT_SORTING_FIELD.NOT_EVALUATED}/>
                        {t(translationKeys.not_evaluated)}
                    </div>
                    <div id={'form-sort-Title'} className="form-sort-each-option-mobile"
                         style={{borderBottomLeftRadius: '5px', borderBottomRightRadius: '5px'}}
                         onClick={() => props.handleSortSelection(RESPONDENT_SORTING_FIELD.EVALUATED)}>
                        <CircularCheckBox isChecked={props.lastSortedOrder === RESPONDENT_SORTING_FIELD.EVALUATED}/>
                        {t(translationKeys.evaluated)}
                    </div>
                    <div id={'form-sort-Title'} className="form-sort-each-option-mobile"
                         style={{borderBottomLeftRadius: '5px', borderBottomRightRadius: '5px'}}
                         onClick={() => props.handleSortSelection(RESPONDENT_SORTING_FIELD.PERCENTAGE_ASCENDING)}>
                        <CircularCheckBox
                            isChecked={props.lastSortedOrder === RESPONDENT_SORTING_FIELD.PERCENTAGE_ASCENDING}/>
                        {t(translationKeys.percentage_ascending)}
                    </div>
                    <div id={'form-sort-Title'} className="form-sort-each-option-mobile"
                         style={{borderBottomLeftRadius: '5px', borderBottomRightRadius: '5px'}}
                         onClick={() => props.handleSortSelection(RESPONDENT_SORTING_FIELD.PERCENTAGE_DESCENDING)}>
                        <CircularCheckBox
                            isChecked={props.lastSortedOrder === RESPONDENT_SORTING_FIELD.PERCENTAGE_DESCENDING}/>
                        {t(translationKeys.percentage_descending)}
                    </div>
                </div>
            </div> : null}
    </div>
}

