import React, {useEffect, useRef, useState} from "react"
import {QUIZ_QUESTION_TYPE} from "../../../shared/utils/constants";
import {ANSWER_EVENT} from "./QuizBuilderContainer"
import MaterialTextField, {TEXT_FIELD_TYPES} from "../../../shared/components/MaterialTextField";
import {useTranslation} from "react-i18next";
import {translationKeys} from "../../../localizations/translationKeys-localization";
import {useSelector} from "react-redux";

const TextQuestionCorrectAnswerAndMarks = (props) => {
    const {t} = useTranslation()
    const errorText = useRef('')
    const answerRef = useRef(null)
    const [forceUpdateRefVarFocus, setForceUpdateRefVarFocus] = useState(false)
    const selectedLanguage = useSelector(state => state.languageReducer?.selectedLanguage)

    const answerInputCustomStyleObject = {
        '--mdc-theme-primary': '#9e9e9e',
        width: '100%',
        textAlign: 'left',
    };

    const markInputCustomStyleObject = {
        '--mdc-theme-primary': '#9e9e9e',
        width: selectedLanguage === 'ta' || selectedLanguage === 'ml' ? '120px' : selectedLanguage === 'te' ? '90px' : '75px',
        textAlign: 'center'
    };

    useEffect(() => {
        if (props.refreshAnswerErrors) {
            if (props.question.correct_answer && props.question.correct_answer.trim() === '') {
                errorText.current = 'Correct Answer is required.'
            } else {
                errorText.current = ''
            }
        }
    }, [props.refreshAnswerErrors])

    useEffect(() => {
        if (props.questionAnswerToFocus !== -1 && props.index === props.questionAnswerToFocus) {
            // Note: normally 'answerRef.current.focus()' would be used here, but there were issues after deleting a question with a different
            // Question Type (e.g., Made Question 2, which is a Long Answer question blank, and then deleting Question 1) or
            // Changing the Question Type (e.g., Change question type from Long Answer to Short Answer and vice versa).
            setForceUpdateRefVarFocus(true)

            props.updateAnswers(ANSWER_EVENT.SET_QUESTION_ANSWER_TO_FOCUS, {questionIndex: -1})
        }
    }, [props.questionAnswerToFocus])

    const handleAnswerChange = (newValue) => {
        props.updateAnswers(ANSWER_EVENT.UPDATE_TEXT_CORRECT_ANSWER, {questionIndex: props.index, newValue: newValue})
    }

    const handleMarksChange = (newValue) => {
        if (newValue.length < 4) {
            props.updateAnswers(ANSWER_EVENT.UPDATE_MARKS, {questionIndex: props.index, newValue: newValue})
        }
    }

    return (
        <div id={'quiz-text-answer-marks'} className="quiz-text-answer-marks-wrapper">
            <div id={'quiz-text-answer'} className="quiz-text-answer-marks-box1">
                <MaterialTextField
                    data={{
                        hint: t(translationKeys.enter_answer),
                        defaultValue: props.question.correct_answer,
                        textArea: props.question.type === QUIZ_QUESTION_TYPE.LONG_TEXT.key
                    }}
                    onChange={handleAnswerChange}
                    textFieldType={TEXT_FIELD_TYPES.OUTLINED}
                    required={t(translationKeys.required)}
                    customStyleObject={answerInputCustomStyleObject}
                    index={props.index}
                    errorText={errorText}
                    inputRef={answerRef}
                    forceUpdateRefVarFocus={forceUpdateRefVarFocus}
                    setForceUpdateRefVarFocus={setForceUpdateRefVarFocus}
                    maxLength={props.question.type === QUIZ_QUESTION_TYPE.LONG_TEXT.key ? 10000 : 100}
                />
            </div>
            <div id={'quiz-text-marks'} className="quiz-text-answer-marks-box2">
                <div style={{marginRight: '5px'}}>
                    <MaterialTextField
                        data={{
                            hint: t(translationKeys.marks),
                            inputType: 'number',
                            defaultValue: props.question.marks === undefined ? String(1) : String(props.question.marks)
                        }}
                        onChange={handleMarksChange}
                        textFieldType={TEXT_FIELD_TYPES.OUTLINED}
                        customStyleObject={markInputCustomStyleObject}
                        index={props.index}
                        maxLength={3}
                    />
                </div>
            </div>
        </div>
    )
}

export default TextQuestionCorrectAnswerAndMarks