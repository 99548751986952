import React from 'react';
import {AWS_BUCKET_MIDDLE_PATH, AWS_FOLDER_NAMES, MEDIA_BASE_URL} from "../utils/aws";
import {translationKeys} from "../../../localizations/translationKeys-localization";
import {t} from "i18next";

export default function DefaultThemeCard(props) {

    let imageFile = props.imageData?.file;
    const imageName = props.imageData?.name;
    let name;
    if (imageFile === "dark.jpeg") {
        name = "dark_theme";
    } else if (imageFile === "classic.jpeg") {
        imageFile = 'classic_new.jpeg'
        name = "classic_theme";
    } else {
        name = imageFile;
    }

    const AWS_THUMBNAIL_WEB_PATH = MEDIA_BASE_URL + AWS_BUCKET_MIDDLE_PATH + AWS_FOLDER_NAMES.THUMBNAILWEB_FOLDER;

    return (
        // theme card element
        <div id={'theme_card_' + props.themeIndex} className='themes-card' name={name}
             onClick={() => {
                 if (imageName === t(translationKeys.my_themes) ) {
                     props.changeContainer('myThemes');
                 } else {
                     if (props.hideCheck) {
                         // Show Themes Container
                         props.handleClick(name);
                     } else {
                         // Update Chosen Theme
                         props.updateChosenTheme(name);
                     }
                 }
             }}>
            <img className={imageName !== t(translationKeys.my_themes)  ? 'theme-image'
                : (props.lastClickedImage?.startsWith(MEDIA_BASE_URL)
                    ? 'theme-image' : 'hide-element')}
                 src={imageName !== t(translationKeys.my_themes) ? AWS_THUMBNAIL_WEB_PATH + "/" + imageFile
                     : (props.lastClickedImage?.startsWith(MEDIA_BASE_URL)
                         ? props.lastClickedImage : null)}
                 alt={imageName !== t(translationKeys.my_themes)  ? imageFile : null}
            />

            <div className="theme-name-container  center">
                <span style={{color: 'white'}}>{imageName}</span>
            </div>

            <div>
                {(() => {
                    {/*theme chosen tick mark*/}
                    if (!props.hideCheck) return (
                        /*theme chosen tick mark*/
                        <div className="theme-round center"
                             style={(imageName !== t(translationKeys.my_themes)  ?
                                 ((props.lastClickedImage === name) ? {display: 'flex'} : {display: 'none'})
                                 : (props.lastClickedImage?.startsWith(MEDIA_BASE_URL)
                                     ? {display: 'flex'} : {
                                         display: 'flex',
                                         backgroundColor: 'rgba(0, 0, 0, 0.4)'
                                     }))}>
                            <img className="theme-chosen-image"
                                 src={imageName !== t(translationKeys.my_themes)  ? "images/done_green.png"
                                     : (props.lastClickedImage?.startsWith(MEDIA_BASE_URL)
                                         ? "images/done_green.png" : "images/add_white.png")}
                                 alt='images/add_white.png'
                            />
                        </div>
                    )
                })()
                }
            </div>
        </div>
    )
}