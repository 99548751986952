import shortTextImage from '../images/icons/builder/question_types/short_text.svg'
import longTextImage from '../images/icons/builder/question_types/long_text.svg'
import emailImage from '../images/icons/builder/question_types/email.svg'
import numberImage from '../images/icons/builder/question_types/number.svg'
import multipleChoiceImage from '../images/icons/builder/question_types/multiple_choice.svg'
import multipleChoiceGridImage from '../images/icons/builder/question_types/multiple_choice_grid.svg'
import checkboxChoiceImage from '../images/icons/builder/question_types/checkbox_choice.svg'
import checkboxGridImage from '../images/icons/builder/question_types/checkbox_grid.svg'
import dropdownChoiceImage from '../images/icons/builder/question_types/dropdown_choice.svg'
import linearScaleImage from '../images/icons/builder/question_types/linear_scale.svg'
import starRatingScaleImage from '../images/icons/builder/question_types/star_rating_scale.svg'
import smileImage from '../images/icons/builder/question_types/smiley.svg'
import dateImage from '../images/icons/builder/question_types/date.svg'
import timeImage from '../images/icons/builder/question_types/time.svg'
import fileUploadImage from '../images/icons/builder/question_types/file_upload.svg'
import sectionImage from '../images/icons/builder/question_types/section.svg'
import {translationKeys} from "../../src/localizations/translationKeys-localization";
import {t} from "i18next";

export const formQuestionGroups = [
    {
        "group": "Text",
        "questionTypes": [
            {
                "title": t(translationKeys.short_answer),
                "image": shortTextImage,
                "type": "SHORT_TEXT"
            },
            {
                "title": t(translationKeys.email),
                "image": emailImage,
                "type": "EMAIL"
            },
            {
                "title": t(translationKeys.long_answer),
                "image": longTextImage,
                "type": "LONG_TEXT"
            },
            {
                "title": t(translationKeys.number),
                "image": numberImage,
                "type": "NUMBER"
            }
        ]
    },
    {
        "group": "Choice",
        "questionTypes": [
            {
                "title": t(translationKeys.multiple_choice),
                "image": multipleChoiceImage,
                "type": "MULTIPLE_CHOICE"
            },
            {
                "title": t(translationKeys.multiple_choice_grid),
                "image": multipleChoiceGridImage,
                "type": "MULTIPLE_CHOICE_GRID",
                "new": true
            },
            {
                "title": t(translationKeys.check_box),
                "image": checkboxChoiceImage,
                "type": "CHECKBOX_CHOICE"
            },
            {
                "title": t(translationKeys.checkboxes_grid),
                "image": checkboxGridImage,
                "type": "CHECKBOX_GRID",
                "new": true
            },
            {
                "title": t(translationKeys.dropdown),
                "image": dropdownChoiceImage,
                "type": "DROPDOWN_CHOICE"
            },
            {
                "title": t(translationKeys.linear_scale),
                "image": linearScaleImage,
                "type": "LINEAR_SCALE",
                "new": true
            }
        ]
    },
    {
        "group": "Rating",
        "questionTypes": [
            {
                "title": t(translationKeys.star_rating),
                "image": starRatingScaleImage,
                "type": "STAR_RATING_SCALE"
            },
            {
                "title": t(translationKeys.smile),
                "image": smileImage,
                "type": "SMILEY",
                "new": true
            }
        ]
    },
    {
        "group": "DateTime",
        "questionTypes": [
            {
                "title": t(translationKeys.date_text),
                "image": dateImage,
                "type": "DATE"
            },
            {
                "title": t(translationKeys.time_text),
                "image": timeImage,
                "type": "TIME"
            }
        ]
    },
    {
        "group": "Other",
        "questionTypes": [
            {
                "title": t(translationKeys.file_upload),
                "image": fileUploadImage,
                "type": "FILE_UPLOAD"
            },
            {
                "title": t(translationKeys.section),
                "image": sectionImage,
                "type": "SECTION"
            }
        ]
    }
]

export const quizQuestionGroups = [
    {
        "group": "Text",
        "questionTypes": [
            {
                "title": t(translationKeys.short_answer),
                "image": shortTextImage,
                "type": "SHORT_TEXT"
            },
            {
                "title": t(translationKeys.long_answer),
                "image": longTextImage,
                "type": "LONG_TEXT"
            }
        ]
    },
    {
        "group": "Choice",
        "questionTypes": [
            {
                "title": t(translationKeys.multiple_choice),
                "image": multipleChoiceImage,
                "type": "MULTIPLE_CHOICE"
            },
            {
                "title": t(translationKeys.dropdown),
                "image": dropdownChoiceImage,
                "type": "DROPDOWN_CHOICE"
            }
        ]
    },
    {
        "group": "Other",
        "questionTypes": [
            {
                "title": t(translationKeys.file_upload),
                "image": fileUploadImage,
                "type": "FILE_UPLOAD"
            },
            {
                "title": t(translationKeys.section),
                "image": sectionImage,
                "type": "SECTION"
            }
        ]
    }
]