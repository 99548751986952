import React from 'react'
import '../../../shared/css/delete-alert.css'
import ModalDialog from "../../../shared/components/ModalDialog";
import {translationKeys} from "../../../localizations/translationKeys-localization";
import {useTranslation} from "react-i18next";

const QuizPublishAlert = props => {
    const {t} = useTranslation()
    return (
        <ModalDialog id='quiz-publish-alert'
            header={props.unEvaluatedCount ? ( t(translationKeys.pending_evaluation) + ' - ' + props.unEvaluatedCount) : t(translationKeys.publish)}
            body={props.unEvaluatedCount ? [t(translationKeys.pending_evaluation_alert_message)]
                : [t(translationKeys.publish_description), t(translationKeys.publish_description_note)]}
            buttons={
                [
                    {
                        text: t(translationKeys.cancel),
                        action: () => props.handlePopUp(false)
                    },
                    {
                        text: props.unEvaluatedCount ? t(translationKeys.show_unevaluated) : t(translationKeys.publish),
                        action: () => {
                            props.handlePopUp(false)
                            props.positiveButtonFunction(props.unEvaluatedCount);
                        }
                    }
                ]
            }
        />
    )
}

export default QuizPublishAlert