import React, {useEffect, useRef, useState} from 'react'
import '../css/responses.css'
import LeftNavigator from "../../../shared/components/LeftNavigator";
import ContentContainer from "./ContentContainer";
import {checkGoogleSheetConnectionStatus, getFormResponses} from "../../../shared/utils/sash-v2-api";
import ErrorPage from "../../../shared/components/ErrorPage";
import ChangeContainerMenuItem from "../../../shared/classes/ChangeContainerMenuItem";
import MenuIconInfo from "../../../shared/classes/MenuIconInfo";
import {getSummaryDataSet} from "../utils/summary-data";
import PopupMenuItem from "../../../shared/classes/PopupMenuItem";
import {getQuizAnswers} from "../../../shared/utils/quiz-api-services";
import NavigationMenuItem from "../../../shared/classes/NavigationMenuItem";
import {isMobileOnly} from "react-device-detect";
import ResponsesFormTitleContainer from "./ResponsesFormTitleContainer";
import BottomBar from "../../../shared/components/BottomBar";
import updateFormResponsesData from "../utils/update-form-responses-data";
import removeResponse from "../utils/remove-response";
import addResponse from "../utils/add-response";
import {Pages} from "../../utils/pages";
import {registerGAEvent} from "../../../shared/utils/analytics";
import {UPDATE_FORM_DATA} from "../../utils/constants";
import {useDispatch, useSelector} from "react-redux";
import {getRole} from "../../../shared/utils/helper";
import {translationKeys} from "../../../localizations/translationKeys-localization";
import {t} from "i18next";
import {APP_ACTION_TYPE} from "../../reducers/appReducer";
import {RESPONSES_ACTION_TYPE} from "../../reducers/responsesReducer";

export const RESPONSE_VIEW = {
    OVERVIEW: t(translationKeys.overview),
    RESPONSES: t(translationKeys.responses),
    ANSWERS: t(translationKeys.answers),
    INDIVIDUAL: t(translationKeys.individual),
    TABULAR: t(translationKeys.tabular)
}

const ResponseContainer = (props) => {

    const isQuiz = useSelector(state => state.appReducer.isQuiz)
    const userId = useSelector(state => state.userReducer?.userId)
    const formData = useSelector(state => state.appReducer.formData)
    const showTable = useSelector(state => state.responsesReducer.showTable)
    const showSummary = useSelector(state => state.responsesReducer.showSummary)
    const isShowExport = useSelector(state => state.responsesReducer.showExport)
    const showIndividual = useSelector(state => state.responsesReducer.showIndividual)
    const formResponsesData = useSelector(state => state.responsesReducer.formResponsesData)
    const isShowPublishAlert = useSelector(state => state.responsesReducer.isShowPublishAlert)
    const [isLoading, setIsLoading] = useState(true)
    const [error, setError] = useState()
    const originalFormResponses = {...formResponsesData}
    const dispatch = useDispatch()

    const bodyContainerElementRef = useRef()
    const responseBodyContainerRef = useRef()

    useEffect(() => {
        dispatch({type: RESPONSES_ACTION_TYPE.SET_SHOW_SUMMARY, payload: true})
        dispatch({type: RESPONSES_ACTION_TYPE.SET_SHOW_INDIVIDUAL, payload: false})
        dispatch({type: RESPONSES_ACTION_TYPE.SET_SHOW_TABLE, payload: false})
        dispatch({type: RESPONSES_ACTION_TYPE.SET_SHOW_EXPORT, payload: false})
        dispatch({type: RESPONSES_ACTION_TYPE.SET_IS_SHOW_PUBLISH_ALERT, payload: false})
        if (!formResponsesData || formResponsesData.formData._id !== formData?._id || formResponsesData.formResponses.respondents.length === 0) {
            const failureFunction = err => {
                if (err) {
                    if (err.status === 400 && !err.ok) {
                        setIsLoading(false)
                        setError(true)
                    } else {
                        setIsLoading(false)
                        alert('Something went wrong!');
                    }
                } else {
                    setIsLoading(false)
                    setError(true)
                }
            }
            if (isQuiz) {
                getQuizAnswers(formData?._id, formResponses => {
                    dispatch({
                        type: RESPONSES_ACTION_TYPE.SET_ORIGINAL_FORM_RESPONDENTS,
                        payload: JSON.parse(JSON.stringify(formResponses.formResponses.respondents))
                    })
                    dispatch({
                        type: RESPONSES_ACTION_TYPE.SET_FORM_RESPONSES_DATA,
                        payload: formResponses.formData.is_quizzory_v2 ? updateFormResponsesData(formResponses) : formResponses
                    })
                    dispatch({
                        type: RESPONSES_ACTION_TYPE.SUMMARY_DATA_SET,
                        payload: JSON.parse(JSON.stringify(getSummaryDataSet(formResponses.formResponses, formResponses.formData)))
                    })
                    dispatch({
                        type: RESPONSES_ACTION_TYPE.SET_ORIGINAL_SUMMARY_DATA_SET,
                        payload: JSON.parse(JSON.stringify(getSummaryDataSet(formResponses.formResponses, formResponses.formData)))
                    })

                    setIsLoading(false)
                }, err => {
                    failureFunction(err)
                })
            } else {
                getFormResponses(formData?._id, (formResponses) => {
                    dispatch({type: RESPONSES_ACTION_TYPE.SET_FORM_RESPONSES_DATA, payload: formResponses})
                    dispatch({
                        type: RESPONSES_ACTION_TYPE.SET_ORIGINAL_FORM_RESPONDENTS,
                        payload: JSON.parse(JSON.stringify(formResponses.formResponses.respondents))
                    })
                    dispatch({
                        type: RESPONSES_ACTION_TYPE.SUMMARY_DATA_SET,
                        payload: JSON.parse(JSON.stringify(getSummaryDataSet(formResponses.formResponses, formResponses.formData)))
                    })
                    dispatch({
                        type: RESPONSES_ACTION_TYPE.SET_ORIGINAL_SUMMARY_DATA_SET,
                        payload: JSON.parse(JSON.stringify(getSummaryDataSet(formResponses.formResponses, formResponses.formData)))
                    })
                    /*const successFunction = (res) => {
                        if (res.result === "Spreadsheet is connected") {
                            dispatch({type: RESPONSES_ACTION_TYPE.SET_IS_SHEET_CONNECTED, payload: true})
                            dispatch({type: RESPONSES_ACTION_TYPE.SET_IS_REVOKED, payload: false})
                        }
                    }
                    const failureFunction = (err) => {
                        if (err.data.result === "Spreadsheet access revoked") {
                            dispatch({type: RESPONSES_ACTION_TYPE.SET_IS_SHEET_CONNECTED, payload: false})
                            dispatch({type: RESPONSES_ACTION_TYPE.SET_IS_REVOKED, payload: true})
                        }
                    }
                    checkGoogleSheetConnectionStatus(formData._id, successFunction, failureFunction)*/
                    setIsLoading(false)
                }, (err) => {
                    failureFunction(err)
                })
            }
        } else {
            setIsLoading(false)
        }
    }, [])

    const deleteResponseInFormResponses = (responseId) => {
        let formResponses = formResponsesData;
        if (Array.isArray(responseId)) {
            for (let i = 0; i < responseId.length; i++) {
                formResponses = removeResponse(responseId[i], formResponses);
            }
            dispatch({type: UPDATE_FORM_DATA, payload: formResponses.formData})
        } else {
            formResponses = removeResponse(responseId, formResponses);
            dispatch({type: UPDATE_FORM_DATA, payload: formResponses.formData})
        }
    }
    const addResponseInFormResponses = (responseId,indexNumber) => {
        let formResponses = formResponsesData;
        if (Array.isArray(responseId)) {
            for (let i = 0; i < responseId.length; i++) {
                formResponses = addResponse(responseId[i], formResponses);
            }
            dispatch({type: UPDATE_FORM_DATA, payload: formResponses.formData})
        } else {
            formResponses = addResponse(responseId, formResponses, indexNumber);
            dispatch({type: UPDATE_FORM_DATA, payload: formResponses.formData})
        }
    }
    const doPopupAction = (clickedItem) => {
        if (clickedItem.Title === t(translationKeys.download)) {
            if (formData.response_count === formResponsesData.formResponses.respondents.length) {
                dispatch({type: RESPONSES_ACTION_TYPE.SET_SHOW_EXPORT, payload: !isShowExport})
            }
        } else if (clickedItem.Title === t(translationKeys.publish)) {
            dispatch({type: RESPONSES_ACTION_TYPE.SET_IS_SHOW_PUBLISH_ALERT, payload: !isShowPublishAlert})
        }
    }

    const showPage = (pageName, individualResponseNumber, quizSourceIsTabular = null) => {
        let pageNameToCheck;
        if (pageName instanceof ChangeContainerMenuItem) {
            pageNameToCheck = pageName.getTitle();
        }
        pageNameToCheck = pageNameToCheck ? pageNameToCheck : pageName;
        switch (pageNameToCheck) {
            case '':
                break;
            case RESPONSE_VIEW.OVERVIEW:
                registerGAEvent('Responses', 'Summary', 'view');
                bodyContainerElementRef.current.scrollTop = 0
                dispatch({type: RESPONSES_ACTION_TYPE.SET_SHOW_SUMMARY, payload: true})
                dispatch({type: RESPONSES_ACTION_TYPE.SET_SHOW_INDIVIDUAL, payload: false})
                dispatch({type: RESPONSES_ACTION_TYPE.SET_SHOW_TABLE, payload: false})
                dispatch({type: RESPONSES_ACTION_TYPE.SET_SHOW_EXPORT, payload: false})
                dispatch({type: RESPONSES_ACTION_TYPE.SET_IS_SHOW_PUBLISH_ALERT, payload: false})
                break;
            case RESPONSE_VIEW.RESPONSES:
                registerGAEvent('Responses', 'Summary', 'view');
                bodyContainerElementRef.current.scrollTop = 0
                dispatch({type: RESPONSES_ACTION_TYPE.SET_SHOW_SUMMARY, payload: true})
                dispatch({type: RESPONSES_ACTION_TYPE.SET_SHOW_INDIVIDUAL, payload: false})
                dispatch({type: RESPONSES_ACTION_TYPE.SET_SHOW_TABLE, payload: false})
                dispatch({type: RESPONSES_ACTION_TYPE.SET_SHOW_EXPORT, payload: false})
                dispatch({type: RESPONSES_ACTION_TYPE.SET_IS_SHOW_PUBLISH_ALERT, payload: false})
                break;
            case RESPONSE_VIEW.ANSWERS:
                registerGAEvent('Responses', 'Summary', 'view');
                bodyContainerElementRef.current.scrollTop = 0
                dispatch({type: RESPONSES_ACTION_TYPE.SET_SHOW_SUMMARY, payload: true})
                dispatch({type: RESPONSES_ACTION_TYPE.SET_SHOW_INDIVIDUAL, payload: false})
                dispatch({type: RESPONSES_ACTION_TYPE.SET_SHOW_TABLE, payload: false})
                dispatch({type: RESPONSES_ACTION_TYPE.SET_SHOW_EXPORT, payload: false})
                dispatch({type: RESPONSES_ACTION_TYPE.SET_IS_SHOW_PUBLISH_ALERT, payload: false})
                break;
            case RESPONSE_VIEW.INDIVIDUAL:
                registerGAEvent('Responses', 'Individual', 'view');
                bodyContainerElementRef.current.scrollTop = 0
                dispatch({type: RESPONSES_ACTION_TYPE.SET_SHOW_SUMMARY, payload: false})
                dispatch({type: RESPONSES_ACTION_TYPE.SET_SHOW_INDIVIDUAL, payload: true})
                dispatch({type: RESPONSES_ACTION_TYPE.SET_SHOW_TABLE, payload: false})
                dispatch({type: RESPONSES_ACTION_TYPE.SET_SHOW_EXPORT, payload: false})
                dispatch({type: RESPONSES_ACTION_TYPE.SET_IS_SHOW_PUBLISH_ALERT, payload: false})
                dispatch({
                    type: RESPONSES_ACTION_TYPE.SET_INDIVIDUAL_RESPONSE_NUMBER,
                    payload: individualResponseNumber
                })
                dispatch({type: RESPONSES_ACTION_TYPE.SET_QUIZ_SOURCE_IS_TABULAR, payload: quizSourceIsTabular})
                break;
            case RESPONSE_VIEW.TABULAR:
                registerGAEvent('Responses', 'Tabular', 'view');
                dispatch({type: RESPONSES_ACTION_TYPE.SET_SHOW_SUMMARY, payload: false})
                dispatch({type: RESPONSES_ACTION_TYPE.SET_SHOW_INDIVIDUAL, payload: false})
                dispatch({type: RESPONSES_ACTION_TYPE.SET_SHOW_TABLE, payload: true})
                dispatch({type: RESPONSES_ACTION_TYPE.SET_SHOW_EXPORT, payload: false})
                dispatch({type: RESPONSES_ACTION_TYPE.SET_IS_SHOW_PUBLISH_ALERT, payload: false})
                break;
        }
    }

    let keyCtr = 0;
    const allowDownload = formData?.response_count === formResponsesData?.formResponses?.respondents?.length
    const SurveyHeartMenuItem = new NavigationMenuItem(keyCtr++, "", new MenuIconInfo("survey-heart-icon", "surveyheart_grey.svg"), '/app')
    const SummaryMenuItem = new ChangeContainerMenuItem(keyCtr++, allowDownload && !isQuiz ? t(translationKeys.overview) : isQuiz ? t(translationKeys.answers) : t(translationKeys.responses), showSummary, new MenuIconInfo("summary-responses", "summary"))
    const IndividualMenuItem = new ChangeContainerMenuItem(keyCtr++, t(translationKeys.individual), showIndividual, new MenuIconInfo("individual_responses", "individual_responses"))
    const TabularMenuItem = new ChangeContainerMenuItem(keyCtr++, t(translationKeys.tabular), showTable, new MenuIconInfo("table_responses", "table_responses"))
    const ExportMenuItem = new PopupMenuItem(keyCtr++, t(translationKeys.download), new MenuIconInfo("download", "export_grey.svg"))
    const PublishMenuItem = new PopupMenuItem(keyCtr++, t(translationKeys.publish), new MenuIconInfo("publish", "publish_grey.svg"))
    const responsePageMenuItems = isQuiz ? [SurveyHeartMenuItem, SummaryMenuItem, IndividualMenuItem, allowDownload ? TabularMenuItem : null, allowDownload ? ExportMenuItem : null, getRole(formResponsesData?.formData?.collaborators, userId) === 0 || getRole(formResponsesData?.formData?.collaborators, userId) === 1 || !allowDownload ? null : PublishMenuItem]
        : [SurveyHeartMenuItem, SummaryMenuItem, IndividualMenuItem, allowDownload ? TabularMenuItem : null, allowDownload ? ExportMenuItem : null];
    location.hash = '#responses';

    const doNavigation = () => {
        dispatch({type: APP_ACTION_TYPE.SET_CURRENT_PAGE, payload: Pages.dashboard})
        dispatch({type: APP_ACTION_TYPE.SET_IS_QUIZ, payload: isQuiz})
    }

    return (
        navigator.onLine ?
            <>
            <div className='app-container'>
                {isMobileOnly ?
                    (() => {
                        const BottomBarMenuItems = isQuiz ? [SummaryMenuItem, IndividualMenuItem, TabularMenuItem, ExportMenuItem, getRole(formResponsesData?.formData?.collaborators, userId) === 0 || getRole(formResponsesData?.formData?.collaborators, userId) === 1 ? null : PublishMenuItem]
                            : [SummaryMenuItem, IndividualMenuItem, TabularMenuItem, ExportMenuItem];
                        return <BottomBar BottomBarMenuItems={BottomBarMenuItems}
                                          doPopupAction={doPopupAction}
                                          isCenterFABPresent={false}
                                          updateContainer={showPage}/>
                    })()
                    :
                    <LeftNavigator LeftNavigatorMenuItems={responsePageMenuItems} doPopupAction={doPopupAction}
                                   doNavigation={doNavigation}
                                   updateContainer={showPage}
                    />}
                <div id='response-container-date' style={showSummary ? {overflow: 'auto'} : null}
                     className={(showIndividual || showTable) ? isMobileOnly ? 'body-container-mobile over-flow' : 'body-container over-flow' : isMobileOnly ? 'body-container-mobile' : 'body-container'}
                     ref={bodyContainerElementRef}>
                    <div className='background-blue-wall'
                         style={{
                             height: showSummary ? '30%' : showIndividual ? '40%' : '35%',
                             display: (isMobileOnly && isQuiz && showSummary) ? 'none' : null
                         }}/>
                    {!error ?
                        <div className='response-body-container' ref={responseBodyContainerRef}
                             style={showTable || isLoading ? {height: '100%'} : null}>
                            {(isQuiz && isMobileOnly && showSummary) ? null :
                                <ResponsesFormTitleContainer
                                    title={formResponsesData ? formResponsesData.formData.welcome_screen.title : ''}/>}
                            <ContentContainer isLoading={isLoading}
                                              changeResponseView={showPage}
                                              removeResponse={deleteResponseInFormResponses}
                                              addResponse={addResponseInFormResponses}
                                              bodyContainerElementRef={bodyContainerElementRef}
                                              refreshStorageTotals={props.refreshStorageTotals}
                                              originalFormResponses={originalFormResponses}
                            />
                        </div>
                        : <ErrorPage/>}
                </div>
            </div>

            </>:
            <div className='app-container'>
                <div style={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexFlow: 'column'
                }}>
                    <p style={{textAlign: 'center'}}>{t('no_Internet')}</p><span
                    style={{textAlign: 'center'}}>{t('check_your_network_availability')}</span>
                </div>
            </div>
    );
}

export default ResponseContainer
