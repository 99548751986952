import {QUIZ_QUESTION_TYPE} from '../../../../shared/utils/constants';

export const QUIZ_FOCUS_TARGET = {
    NONE: 0,
    TITLE: 1,
    ANSWER: 2
}

export const incompleteQuestionCount = (questions) => {
    let incompleteQuestions = {
        count: 0,
        atLeastOneCorrectAnswerIsMissing: false,
        atLeastOneTitleIsMissing: false,
        questionIndexToFocus: -1,
        focusTarget: QUIZ_FOCUS_TARGET.NONE,
        atLeastOneMarkIsZeroOrNegative: false
    }

    questions.map((question, index) => {
        // This is needed because missing correct answer and question title have to be checked.
        let incorrectCountPreviouslyIncrementedForThisQuestion = false
        let questionIsInvalid = false

        if (QUIZ_QUESTION_TYPE[question.type].key === QUIZ_QUESTION_TYPE.SHORT_TEXT.key || QUIZ_QUESTION_TYPE[question.type].key === QUIZ_QUESTION_TYPE.LONG_TEXT.key) {
            // Check for Missing Correct Answer
            if (!question.hasOwnProperty('correct_answer') || question.correct_answer.trim() === '') {
                incompleteQuestions.count = incompleteQuestions.count + 1
                incompleteQuestions.atLeastOneCorrectAnswerIsMissing = true

                if (incompleteQuestions.questionIndexToFocus === -1) {
                    incompleteQuestions.focusTarget = QUIZ_FOCUS_TARGET.ANSWER
                }

                incorrectCountPreviouslyIncrementedForThisQuestion = true
                questionIsInvalid = true
            }

            // Check for Missing Title
            if (question.title.trim() === '') {
                incompleteQuestions.atLeastOneTitleIsMissing = true
                questionIsInvalid = true

                if (incompleteQuestions.questionIndexToFocus === -1) {
                    // Focus should be put on the first missing question Title.
                    incompleteQuestions.focusTarget = QUIZ_FOCUS_TARGET.TITLE
                }

                if (!incorrectCountPreviouslyIncrementedForThisQuestion) {
                    incompleteQuestions.count = incompleteQuestions.count + 1
                }
            }

            if (!question.marks || question.marks < 1) {
                incompleteQuestions.atLeastOneMarkIsZeroOrNegative = true
                questionIsInvalid = true

                if (incompleteQuestions.questionIndexToFocus === -1) {
                    // Focus should be put on the Title of the Question with the missing or negative marks.
                    incompleteQuestions.focusTarget = QUIZ_FOCUS_TARGET.TITLE
                }

                if (!incorrectCountPreviouslyIncrementedForThisQuestion) {
                    incompleteQuestions.count = incompleteQuestions.count + 1
                }
            }

            if (questionIsInvalid) {
                if (incompleteQuestions.questionIndexToFocus === -1) {
                    incompleteQuestions.questionIndexToFocus = index
                }
            }
        } else if (QUIZ_QUESTION_TYPE[question.type] === QUIZ_QUESTION_TYPE.MULTIPLE_CHOICE || QUIZ_QUESTION_TYPE[question.type] === QUIZ_QUESTION_TYPE.DROPDOWN_CHOICE) {
            // NOTE: Choice Questions are not given the focus if no Correct Answer, no Marks or Negative marks are specified because they don't have a text input for the Correct Answer.
            // In this situation, the Question Title is given the focus.
            let correctAnswerPresent = false

            question.choices.map((choice) => {
                if (!correctAnswerPresent && choice.hasOwnProperty('is_answer') && choice.is_answer) {
                    correctAnswerPresent = true
                }
            })

            if (!correctAnswerPresent) {
                incompleteQuestions.count = incompleteQuestions.count + 1
                incompleteQuestions.atLeastOneCorrectAnswerIsMissing = true
                incorrectCountPreviouslyIncrementedForThisQuestion = true
                questionIsInvalid = true
            }

            // Check for Missing Title
            if (question.title.trim() === '') {
                incompleteQuestions.atLeastOneTitleIsMissing = true
                questionIsInvalid = true

                if (!incorrectCountPreviouslyIncrementedForThisQuestion) {
                    incompleteQuestions.count = incompleteQuestions.count + 1
                }
            }

            if (!question.marks || question.marks < 1) {
                incompleteQuestions.atLeastOneMarkIsZeroOrNegative = true
                questionIsInvalid = true

                if (incompleteQuestions.questionIndexToFocus === -1) {
                    // Focus should be put on the Title of the Question with the missing or negative marks.
                    incompleteQuestions.focusTarget = QUIZ_FOCUS_TARGET.TITLE
                }

                if (!incorrectCountPreviouslyIncrementedForThisQuestion) {
                    incompleteQuestions.count = incompleteQuestions.count + 1
                }
            }

            if (questionIsInvalid) {
                if (incompleteQuestions.questionIndexToFocus === -1) {
                    incompleteQuestions.focusTarget = QUIZ_FOCUS_TARGET.TITLE
                    incompleteQuestions.questionIndexToFocus = index
                }
            }
        } else if (QUIZ_QUESTION_TYPE[question.type] === QUIZ_QUESTION_TYPE.FILE_UPLOAD) {
            // Check for Missing Title
            if (question.title.trim() === '') {
                incompleteQuestions.atLeastOneTitleIsMissing = true
                questionIsInvalid = true

                if (incompleteQuestions.questionIndexToFocus === -1) {
                    // Focus should be put on the first missing question Title.
                    incompleteQuestions.focusTarget = QUIZ_FOCUS_TARGET.TITLE
                }

                if (!incorrectCountPreviouslyIncrementedForThisQuestion) {
                    incorrectCountPreviouslyIncrementedForThisQuestion = true
                    incompleteQuestions.count = incompleteQuestions.count + 1
                }
            }

            if (!question.marks || question.marks < 1) {
                incompleteQuestions.atLeastOneMarkIsZeroOrNegative = true
                questionIsInvalid = true

                if (incompleteQuestions.questionIndexToFocus === -1) {
                    // Focus should be put on the Title of the Question with the missing or negative marks.
                    incompleteQuestions.focusTarget = QUIZ_FOCUS_TARGET.TITLE
                }

                if (!incorrectCountPreviouslyIncrementedForThisQuestion) {
                    incompleteQuestions.count = incompleteQuestions.count + 1
                }
            }

            if (questionIsInvalid) {
                if (incompleteQuestions.questionIndexToFocus === -1) {
                    incompleteQuestions.questionIndexToFocus = index
                }
            }
        } else if (QUIZ_QUESTION_TYPE[question.type] === QUIZ_QUESTION_TYPE.SECTION) {
            // Check for Missing Title
            if (question.title.trim() === '') {
                incompleteQuestions.atLeastOneTitleIsMissing = true
                incompleteQuestions.focusTarget = QUIZ_FOCUS_TARGET.TITLE
                incompleteQuestions.count = incompleteQuestions.count + 1

                if (incompleteQuestions.questionIndexToFocus === -1) {
                    // Focus should be put on the first missing question Title.
                    incompleteQuestions.questionIndexToFocus = index
                }
            }
        }
    })

    return incompleteQuestions
}