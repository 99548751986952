import React, {useRef} from "react"
import {t} from "i18next"
import {translationKeys} from "../../localizations/translationKeys-localization"
import {APP_ACTION_TYPE} from "../reducers/appReducer"
import {useDispatch, useSelector} from "react-redux"
import {isMobile} from "react-device-detect"

const useGridMgr = () => {
    const reduxDispatch = useDispatch()
    const formData = useSelector(state => state.appReducer.formData)
    const COMPONENT_TYPE = {
        COLUMN: 'col',
        ROW: 'row'
    }

    const refRemoveItemShouldBeEnabled = useRef(true)

    const addAnimationStyles = (questionIndex, componentType, childDiv, refAnimationInitialized, length) => {
        const parentDiv = document.querySelector(`#mcg-${componentType}s-${questionIndex}`);

        if (isMobile) {
            parentDiv.style.maxHeight = '120px';
            parentDiv.style.overflowY = 'auto'
            parentDiv.style.transition = "max-height " + .5 + "s ease";
        } else {
            const childDivHeight = childDiv.offsetHeight

            parentDiv.style.maxHeight = `${childDivHeight * length}px`;
            parentDiv.style.overflowY = 'hidden'
            parentDiv.style.transition = "max-height " + .5 + "s ease";
        }

        refAnimationInitialized.current = true
    }
    const addItem = (questionIndex, componentType, e) => {
        let itemIndex
        let newLabel

        const childDiv = document.querySelector(`#${componentType}-text-${questionIndex}-0`);
        const parentDiv = document.querySelector(`#mcg-${componentType}s-${questionIndex}`);
        const childDivHeight = childDiv.offsetHeight
        const updatedFormData = {...formData}
        const items = componentType === COMPONENT_TYPE.COLUMN ? formData?.pages[0].questions[questionIndex].columns : formData?.pages[0].questions[questionIndex].rows

        if (e) {
            // When e exists, the plus icon has been clicked
            itemIndex = parseInt(e.target.parentElement.parentElement.id.split("-").pop())
            newLabel = `${componentType === COMPONENT_TYPE.COLUMN ? t(translationKeys.column) : t(translationKeys.row)} ${itemIndex + 2}`
        } else {
            // When e does not exist, 'Add Row or Column' button in Mobile View has been clicked
            itemIndex = -1
            newLabel = `${componentType === COMPONENT_TYPE.COLUMN ? t(translationKeys.column) : t(translationKeys.row)} ${items.length + 1}`
        }

        if (items.length < 5) {
            // Set the (max)Height for the Parent Div based on the number of Items that have been added previously.
            parentDiv.style.maxHeight = `${childDivHeight * items.length}px`;
            parentDiv.style.overflowY = 'hidden'
        } else {
            // Show Scroll Bar
            parentDiv.style.overflowY = 'auto'
        }

        if (itemIndex === -1 || (itemIndex === 0 && items.length === 1)) {
            // If there is only one Item or no itemIndex, add an Item at the bottom.
            items.push({label: newLabel})
            if (componentType === COMPONENT_TYPE.COLUMN) {
                updatedFormData.pages[0].questions[questionIndex].columns = items
            } else {
                updatedFormData.pages[0].questions[questionIndex].rows = items
            }
        } else if (itemIndex <= items.length) {
            // Insert the new Item based on the index
            items.splice(itemIndex + 1, 0, {label: newLabel})
            if (componentType === COMPONENT_TYPE.COLUMN) {
                updatedFormData.pages[0].questions[questionIndex].columns = items
            } else {
                updatedFormData.pages[0].questions[questionIndex].rows = items
            }
        }

        reduxDispatch({type: APP_ACTION_TYPE.SET_FORM_DATA, payload: updatedFormData})

        setTimeout(() => {
            if (isMobile) {
                if (items.length > 1) {
                    parentDiv.style.maxHeight = '120px';
                    parentDiv.style.overflowY = 'auto'
                } else {
                    console.log(3)
                    parentDiv.style.transition = "max-height " + .5 + "s ease";
                    parentDiv.style.maxHeight = `${childDivHeight * items.length}px`;
                }
            } else {
                if (items.length < 6) {
                    // Increase the (max)Height for the Parent Div after the new Item has been added to it.
                    // This is done in setTimeout in order for the animation to work correctly.
                    parentDiv.style.transition = "max-height " + .5 + "s ease";
                    parentDiv.style.maxHeight = `${childDivHeight * items.length}px`;
                } else {
                    parentDiv.style.transition = "max-height " + .5 + "s ease";
                    document.querySelector(`#${componentType}-text-${questionIndex}-${items.length - 1}`)?.querySelector('input')?.focus()
                }
            }
        }, 0);

        if (isMobile) {
            setTimeout(() => {
                // After .5 seconds, put the focus on the newly added Item.
                if (itemIndex > -1) {
                    document.querySelector(`#${componentType}-text-${questionIndex}-${itemIndex + 1}`).querySelector('input').focus()
                } else {
                    document.querySelector(`#${componentType}-text-${questionIndex}-${items.length - 1}`).querySelector('input').focus()
                }
            }, 500);
        } else {
            if (items.length < 6) {
                setTimeout(() => {
                    // After .5 seconds, put the focus on the newly added Item.
                    if (itemIndex > -1) {
                        document.querySelector(`#${componentType}-text-${questionIndex}-${itemIndex + 1}`).querySelector('input').focus()
                    } else {
                        document.querySelector(`#${componentType}-text-${questionIndex}-${items.length - 1}`).querySelector('input').focus()
                    }
                }, 500);
            }
        }
    }
    const removeItem = (questionIndex, componentType, itemIndex, setShowRemoveButton) => {
        if (!refRemoveItemShouldBeEnabled.current) {
            return
        }

        refRemoveItemShouldBeEnabled.current = false

        // The parentElement ID will be in the format of 'col-text-0-1' or 'row-text-0-1'
        // The last number after the dash will be the itemIndex.

        const itemDiv = document.querySelector(`#${componentType}-text-${questionIndex}-0`);
        const parentDiv = document.querySelector(`#mcg-${componentType}s-${questionIndex}`);
        const parentDivHeight = itemDiv?.offsetHeight
        const updatedFormData = {...formData}
        const items = componentType === COMPONENT_TYPE.COLUMN ? formData?.pages[0].questions[questionIndex].columns : formData?.pages[0].questions[questionIndex].rows

        if (isMobile) {
            setTimeout(() => {
                items.splice(itemIndex, 1)
                if (componentType === COMPONENT_TYPE.COLUMN) {
                    updatedFormData.pages[0].questions[questionIndex].columns = items
                } else {
                    updatedFormData.pages[0].questions[questionIndex].rows = items
                }
                refRemoveItemShouldBeEnabled.current = true
                setShowRemoveButton(true)
                reduxDispatch({type: APP_ACTION_TYPE.SET_FORM_DATA, payload: updatedFormData})
            }, 500);
        } else {
            if (items.length < 6) {
                // Decrease the (max)Height for the Parent Div after one Items has been removed from it.
                parentDiv.style.maxHeight = `${parentDivHeight * (items.length - 1)}px`;
                parentDiv.style.overflowY = 'hidden'

                setTimeout(() => {
                    items.splice(itemIndex, 1)
                    if (componentType === COMPONENT_TYPE.COLUMN) {
                        updatedFormData.pages[0].questions[questionIndex].columns = items
                    } else {
                        updatedFormData.pages[0].questions[questionIndex].rows = items
                    }
                    refRemoveItemShouldBeEnabled.current = true
                    setShowRemoveButton(true)
                    reduxDispatch({type: APP_ACTION_TYPE.SET_FORM_DATA, payload: updatedFormData})
                }, 500);
            } else {
                items.splice(itemIndex, 1)
                if (componentType === COMPONENT_TYPE.COLUMN) {
                    updatedFormData.pages[0].questions[questionIndex].columns = items
                } else {
                    updatedFormData.pages[0].questions[questionIndex].rows = items
                }
                refRemoveItemShouldBeEnabled.current = true
                setShowRemoveButton(true)
                reduxDispatch({type: APP_ACTION_TYPE.SET_FORM_DATA, payload: updatedFormData})
            }
        }
    }
    const updateItemText = (questionIndex, componentType, itemIndex, text) => {
        const updatedFormData = {...formData}
        if (componentType === COMPONENT_TYPE.COLUMN) {
            updatedFormData.pages[0].questions[questionIndex].columns[itemIndex].label = text
        } else {
            updatedFormData.pages[0].questions[questionIndex].rows[itemIndex].label = text
        }
        reduxDispatch({type: APP_ACTION_TYPE.SET_FORM_DATA, payload: updatedFormData})
    }
    const handleItemBlur = (questionIndex, componentType, itemIndex) => {
        const updatedFormData = {...formData}
        if (formData.pages[0].questions[questionIndex]?.columns[itemIndex]?.label.trim() === '' && componentType === COMPONENT_TYPE.COLUMN) {
            updatedFormData.pages[0].questions[questionIndex].columns[itemIndex].label = `${t(translationKeys.column)} ${itemIndex + 1}`
        } else if (formData.pages[0].questions[questionIndex]?.rows[itemIndex]?.label.trim() === '' && componentType === COMPONENT_TYPE.ROW) {
            updatedFormData.pages[0].questions[questionIndex].rows[itemIndex].label = `${t(translationKeys.row)} ${itemIndex + 1}`
        }
        reduxDispatch({type: APP_ACTION_TYPE.SET_FORM_DATA, payload: updatedFormData})
    }

    const setHeightAndOverflowStatus = (questionIndex, componentType, itemDiv, items) => {
        const parentDiv = document.querySelector(`#mcg-${componentType}s-${questionIndex}`)
        const childDivHeight = itemDiv?.offsetHeight

        // Set the (max)Height for the Parent Div based on the number of Items.
        if (items.length > (isMobile ? 2 : 5)) {
            parentDiv.style.maxHeight = isMobile ? "120px" : `${childDivHeight * 5}px`
            parentDiv.style.overflowY = 'auto'
        } else {
            parentDiv.style.maxHeight = isMobile ? "120px" : `${childDivHeight * items.length}px`
            parentDiv.style.overflowY = 'hidden'
        }
    }

    return {
        addAnimationStyles,
        addItem,
        COMPONENT_TYPE,
        handleItemBlur,
        removeItem,
        setHeightAndOverflowStatus,
        updateItemText
    }
}

export default useGridMgr