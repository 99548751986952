import React from 'react'
import '../../../shared/css/delete-alert.css'
import {isMobileOnly} from "react-device-detect";
import MaterialButton, {ButtonTypes} from "../../../shared/components/MaterialButton";
import {translationKeys} from "../../../localizations/translationKeys-localization";
import {t} from "i18next";

const QuizPublishSuccessPopUp = props => {
    const customStyle = {
        card: {
            width: isMobileOnly ? '80%' : '350px',
            height: isMobileOnly ? 'auto' :'210px',
            display: 'flex',
            flexFlow: 'column',
            backgroundColor: '#fff',
            boxShadow: '0 4px 20px rgba(0, 0, 0, 0.25)',
            borderRadius: '4px'
        },
        heading: {
            width: isMobileOnly ? 'auto' :'330px',
            height: '20px',
            display: 'flex',
            padding: isMobileOnly ? '10px 0 10px 10px' :'10px 0 10px 20px',
            backgroundColor: 'green',
            borderTopLeftRadius: '4px',
            borderTopRightRadius: '4px',
            color: '#fff'
        },
        validated_tick_mark_outer_circle: {
            width: "19px",
            height: "19px",
            display: "flex",
            margin: '0 5px',
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "50%",
            backgroundColor: "#fff"
        },
        validated_tick_mark_inner_circle: {
            width: "17px",
            height: "17px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "50%",
            backgroundColor: "green"
        },
        validated_tick_img: {width: "12px", height: "12px"},
        body_container: {
            width: isMobileOnly ? 'auto' :'310px',
            height: isMobileOnly ? 'auto' :'140px',
            textAlign: isMobileOnly ? 'center' : 'left',
            padding: '15px 10px',
            borderBottomLeftRadius: '4px',
            borderBottomRightRadius: '4px'
        }
    }

    return (
        <div style={customStyle.card}>
            <div id='quiz-publish-popup-title' style={customStyle.heading}>
                <div  style={customStyle.validated_tick_mark_outer_circle}>
                <div style={customStyle.validated_tick_mark_inner_circle}>
                    <img style={customStyle.validated_tick_img} alt='validated'
                         src='images/done.svg'/>
                </div></div>
                <span>{t(translationKeys.published_successfully)}</span>
            </div>
            <div id='quiz-publish-popup-body' style={customStyle.body_container}>
                <p style={{marginTop: '0'}}>{t(translationKeys.publish_success_alert_message)}</p>
                {/*<p style={{margin: '0 0 10px 0'}}>This quiz has been automatically closed.</p>*/}
                <div style={{display: 'flex', justifyContent: 'center'}}>
                <MaterialButton id='quiz-publish-popup-button' data={{title: t(translationKeys.close), buttonType: ButtonTypes.OUTLINED, bg_color: '#1976d2'}}
                                handleClick={() => {
                                    props.handlePopUp(false);
                                }}/>
                </div>
            </div>
        </div>
    )
}

export default QuizPublishSuccessPopUp